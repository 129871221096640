import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
//import PromptContext from './context';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import LumberjackContext from '../../context';
import Tooltip from '@mui/material/Tooltip';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import { useNavigate } from "react-router-dom";

//import Plus from '@mui/icons-material/PlusOutlined';
import NoteIcon from '@mui/icons-material/StickyNote2Outlined';
import TaskListIcon from '@mui/icons-material/TaskAltOutlined';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';

//Alternative approach: https://react-material.fusetheme.com/documentation/material-ui-components/dividers


function Toolbar(props) {
  //const { currentPrompt, currentModel, setCurrentModel, setRightSidebarOpen, setShowTestShell } = useContext(PromptContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { showReferralsPanel, setShowReferralsPanel, request, setRequest, deleteRequest} = useContext(LumberjackContext);

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  
  const displayTestSubMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const testDisabled = true; // (currentPrompt?.Enabled == true)? false : true;

  

  function handleClose(){
    setAnchorEl(null);
  }

  function onDeleteReferral() {
    deleteRequest(request);
  }

  function onTest(){
    setAnchorEl(null);    
    // setShowTestShell(true);
  }
  
  
  const toggleReferralList = () => {
    setShowReferralsPanel(!showReferralsPanel);
  }

  // or: https://react-material.fusetheme.com/documentation/material-ui-components/dividers
  // https://react-material.fusetheme.com/documentation/material-ui-components/button-group
  // <ViewSidebarOutlinedIcon style={{marginLeft: '10px', marginRight: '20px', transform: 'scaleX(-1)'}}  />

  function onNew(){
    navigate(`/Lumberjack/new`);
  }
  
  return (
    <div className="flex flex-col sm:flex-row item-center sm:items-start space-y-6 sm:space-y-0 p-6 sm:p-6  w-full border-b-1 flex items-center justify-between">
      <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
       
        <ButtonGroup variant="text" aria-label="text button group" >  

        {/* Show Filter List */}
        {(showReferralsPanel)?

          <Tooltip title="Hide Referral Selector">
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              color="primary"
            >
              <ViewSidebarOutlinedIcon onClick={toggleReferralList} style={{fontSize: '2rem', marginLeft: '10px', marginRight: '10px', transform: 'scaleX(-1)'}}  />
            </Button>
        </Tooltip>
        :
        <Tooltip title="Show Referral Selector">
          <Button
            size="small"
            color="primary"
            onClick={toggleReferralList}
          >
            <FuseSvgIcon  color="primary">
                heroicons-outline:view-list
              </FuseSvgIcon>

          </Button>
        </Tooltip>

        }

        {/* Add new Referral component={NavLinkAdapter} to="http://localhost:3000/lumberjack/new/" */}
        <Tooltip title="Add New Referral ">
            <Button
              size="small"
              color="primary"
              onClick={onNew}
            >
            <FuseSvgIcon color="primary" style={{fontWeight: 400}}   >
              heroicons-outline:plus
            </FuseSvgIcon>

          </Button>
        </Tooltip>
        
        {/* Select View */}
        <Tooltip title="Select View">
          <Button 
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={displayTestSubMenu}
          > 
            <FuseSvgIcon size={22} color="primary" style={{marginLeft: '10px', marginRight: '10px'}}  > 
                heroicons-outline:view-columns
              </FuseSvgIcon>
          </Button>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={onTest} >Stacked Tabs </MenuItem>
          <MenuItem onClick={onTest} >Side-by-Side Tabs</MenuItem>
          
        </Menu>      
        
        <Tooltip title="Show Task Manager">
          <Button
            size="small"
            onClick={toggleReferralList}
            color="primary"
          >
            
            <TaskListIcon style={{fontSize: "2rem"}} />
          </Button>
        </Tooltip>

        <Tooltip title="Show Notes">
          <Button
            size="small"
            onClick={toggleReferralList}
            color="primary"
          >
            <NoteIcon style={{fontSize: "2rem"}} />

          </Button>
        </Tooltip>

        {/* Staff Chat    style={{marginLeft: '10px', marginRight: '10px'}}   */}
        <Tooltip title="Show Staff Chat">
          <Button
            size="small"
            onClick={toggleReferralList}
          >
            <FuseSvgIcon size={22} color="primary"
         
            > 
              heroicons-outline:chat-bubble-left-right
            </FuseSvgIcon>

          </Button>
        </Tooltip>

          {/* Delete Referral */}
          <Tooltip title="Delete Referral">
          <Button
            size="small"
            color="primary"
            onClick={onDeleteReferral}
          >
            
            <FuseSvgIcon size={22} color="primary"
            style={{marginLeft: '10px', marginRight: '10px'}}  
            > 
              heroicons-outline:x-mark
            </FuseSvgIcon>
          </Button>
        </Tooltip>
      </ButtonGroup>
      </div>

      <div className="flex items-center -mx-8">
        
      </div>
    </div>
  );
}

export default Toolbar;