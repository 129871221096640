
let data = {
  script: 'AutoAuth',
  name: "Auto Authenticate ",
  description: 'This tests if the current user can access Firestore.  ',
  enabled: false
}

function test(RESULT_TYPE) {

  return new Promise((resolve, reject) => {
    resolve({
      status: RESULT_TYPE.PASSED, 
      message: 'It worked!'
    })
  }) // Promise
  
}

export default {test: test, data};