import React, {lazy} from 'react';
import Module from './module';

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'datamodels',
      element: <Module 
        pageTitle='Data Models'
        pageDescription='Extract key data from uploads.'
      />
    }
  ]
}

/*
,
      children: [
        {
          path: '',
          element: <LandingPage />,
        },
        
        { path: ':id', element: <Details tabName="SUMMARY"/> },
        { path: ':id/summary',element: <Details tabName="SUMMARY" />},
        { path: ':id/clinical',element: <Details tabName="CLINICAL" />},
        { path: ':id/financial',element: <Details tabName="FINANCIAL" />},
        { path: ':id/notes',element: <Details tabName="NOTES" />},
        { path: ':id/ai', element: <Details tabName="AI" /> },
        { path: ':id/log', element: <Details tabName="LOG" /> }
        
      ]
      */

export default Routes;