import React, {useState, useEffect} from 'react';
import { createPromptLog, getPromptTest, getReferral, updatePromptTest } from './data';


function DataMap(props) {

  const [testId, setTestId] = useState();
  const [autoId, setAutoId] = useState();
  const [promptTest, setPromptTest] = useState();
  const [referral, setReferral] = useState();

  //getReferral
  const queryParams = new URLSearchParams(window.location.search)
  const PromptTestId = queryParams.get("PromptTest")
  let pageInfo = '';

  useEffect(() => {

    if(PromptTestId != null){
      if(testId != null) return;    // call only once
      else setTestId(PromptTestId);  

      
      createPromptLog(PromptTestId).then(docId => {
        setAutoId(docId);
      });
      
      getPromptTest(PromptTestId).then(doc => {

        // alert('Test having logged out of firebase. ');
        if(doc == null) {
          pageInfo += `Failed to get Prompt Test ${PromptTestId}`;
        }
        else {
          // Flag that we're now processing ("Running")
          doc.Status = 'Running';
          updatePromptTest(doc);
        }
        setPromptTest(doc);

        getReferral(doc?.ReferralId).then(docR => {
          if(docR == null) {
            pageInfo += `Failed to get Referral ${doc?.ReferralId}`;
          }
          setReferral(docR);
        })
      })
    }
  }, [PromptTestId])
  
  return (
    <div style={{padding: '10px'}}>
    <h1>DataMap AI Trigger</h1>
    <h4>This page runs Prompt Test logic.</h4>

    {/* Display instructions if not properly rendered */}
    {PromptTestId == null && 
      <div style={{paddingTop: '12px'}}>
        <div>Provide a Prompt Test query parameter to initiate. </div>
        <div>For example, /DataMap?PromptTest=4rjUYjZxggt2OXwXwTTt</div>
      </div>
    }
    <br/>
    <div style={{paddingLeft: '10px'}}>
      <div>
        <span style={styleLabel}>Prompt Id:</span> {PromptTestId}
      </div>
      <div>
        <span style={styleLabel}>Automation Id:</span> {autoId}
      </div>
      <div>
        <span style={styleLabel}>Status:</span> {promptTest?.Status}
      </div>
      <div>
        <span style={styleLabel}>SourceFile:</span> {referral?.sourceFile}
      </div>
    </div>
    <div style={{paddingLeft: '10px', paddingTop: '30px'}}>        
        <span style={styleLabel}>Runtime Details:</span>         
        <div style={{paddingLeft: '30px', paddingTop: '10px'}}>
          {(pageInfo == '')? 'No problems': pageInfo}
        </div>
      </div>
    </div>
  )
}

const styleLabel = {
  display: 'inline-block',
  width: '150px',
  padding: '2px'
}

export default DataMap;