import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

function DoveIcon(props) {

  let {size, color} = props;
  if(size == null){
    size = 84;
  }
  if(color == null){
    color = "disabled";
  }

  return (
    <FuseSvgIcon size={size} color={color}>
      heroicons-outline:dove
    </FuseSvgIcon>
  )
}

export default DoveIcon;