import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import QuizIcon from '@mui/icons-material/Quiz'; // Quiz, ContactSupport, LiveHelp
import DocChat from './docChat';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getReferenceList } from './data.js';
import { green } from '@mui/material/colors';
import AppContext from 'src/app/AppContext';
import { matchRoutes } from 'react-router-dom';

function getProps(pathname, matchedRoutes){

  if(matchedRoutes == null || Array.isArray(matchedRoutes) == false) return;

  let matchedRoute = null;
  for(let i=0; i<matchedRoutes.length;i++){
    if(matchedRoutes[i].pathname == pathname){
      matchedRoute = matchedRoutes[i];
      break;
    }
  }
  
  if(matchedRoute){
    return matchedRoute?.route?.element?.props;
  }
}

export default function ResourceList(props) {
  let { onCreateEmptyQuestions } = props;
  let [currentNamespace, setCurrentNamespace] = React.useState('');
  //let [emptyMessage, setEmptyMessage] = React.useState('');
  let [resources, setResources] = React.useState(null);
  let [loadingIndex, setLoadingIndex] = React.useState(-1);
  const { routes } = React.useContext(AppContext);
  const { pathname } = location;  
  // const timer = React.useRef();

  const matchedRoutes = matchRoutes(routes, pathname);    
  let navProps = getProps(pathname, matchedRoutes);
  /*

   if(currentNamespace == '' || currentNamespace != namespace){
    setCurrentNamespace(namespace);
    getReferenceList(namespace).then(results => {    
      setResources(results)
    });

    let msg  = getMessage(namespace);
    setEmptyMessage(msg);
  }

  */
  let namespace = navProps?.namespace;
  let chatHeader = navProps?.chatHeader;
  let title = 'BrightSource';
  
  if(currentNamespace == '' || currentNamespace != namespace){
    setCurrentNamespace(namespace);
    getReferenceList(namespace).then(results => {    
      setResources(results)
    });
  }

  if(resources ){
    if(resources.length > 0){
      title = `${title} with ${resources.length} resources`
    }
    else {
      title = 'No resources defined.'
    }
  }

  async function onItemClick(resource, idx) {
    setLoadingIndex(idx);  

    // use FileName
    if(resource?.FileName != null){
      DocChat.createQuestionsByFileName(namespace, resource.FileName).then(list => {
        setLoadingIndex(-1);
        if(onCreateEmptyQuestions && list){
          onCreateEmptyQuestions(list);
        }      
      });    
    }

    // try DocTitle
    else if(resource?.DocTitle != null){
      DocChat.createQuestionsByDocTitle(namespace, resource.DocTitle).then(list => {
        setLoadingIndex(-1);
        if(onCreateEmptyQuestions && list){
          onCreateEmptyQuestions(list);
        }      
      });    
    }

    // try Namespace
    else if(resource?.namespace != null){
      DocChat.createQuestionsByNamespace(namespace).then(list => {
        setLoadingIndex(-1);
        if(onCreateEmptyQuestions && list){
          onCreateEmptyQuestions(list);
        }      
      });    
    }
    
  }

  /*
   <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          variant="contained"
          sx={buttonSx}
          disabled={loading}
          onClick={handleButtonClick}
        >
          Accept terms
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
      */

  return (
     <div className="flex flex-col flex-1 items-center justify-center p-24">
      <div className="flex flex-row">
      <FuseSvgIcon size={84} color="disabled">
        heroicons-outline:dove
      </FuseSvgIcon>
      <Typography className="px-16 pb-24 mt-24 text-center" color="text.secondary">
        {chatHeader}
      </Typography>
      </div>
      <List
        sx={{ width: '100%', maxWidth: '62%'}}
        aria-label="contacts"
      >
        <ListItem >   
            <ListItemText 
              primary={title}
              primaryTypographyProps={{fontSize:'18px', fontWeight: 600}} 
              secondary="Click a row to display sample questions" 
              secondaryTypographyProps={{fontSize:'12px', fontWeight: 400}} 
            >
            </ListItemText>            
        </ListItem>
      </List>

      <List
        style={{overflow: 'scroll'}}
        sx={{ width: '100%', maxWidth: '60%', bgcolor: 'background.paper' }}
        aria-label="contacts"
      >  
      {resources && resources.map((resource, idx) => 
        <ListItem disablePadding key={idx}>
          
          <ListItemButton onClick={() => onItemClick(resource, idx)} >
            <ListItemText primary={resource?.Name} />
            <ListItemIcon>
              <QuizIcon />
              {loadingIndex == idx && (    

              <CircularProgress              
                size={32}
                style={{
                  position: 'absolute',                    
                  float: 'left',
                  top: '5px',
                  marginLeft: '-2px'
                }}
                className='btnSpin'
                sx={{
                  color: green[500],
                  
                }}
              />
            )}
            </ListItemIcon>
            
          </ListItemButton>
          
        </ListItem>
      )}
    </List>
    </div>
  );
}



/*
<List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      aria-label="contacts"
    >
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <QuizIcon />
          </ListItemIcon>
          <ListItemText primary="Chelsea Otakan" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText inset primary="Eric Hoffman" />
        </ListItemButton>
      </ListItem>
    </List>
*/