import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
//import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
// import RightArrowIcon from '@mui/icons-material/ArrowRightAlt'; // startIcon={<RightArrowIcon />}
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MonitorTest from './TestDetails';

import {getReferrals, getPromptRunId, createPromptRun, getPromptSettings, getDataModels, getReferralPages} from '../data';

const columns = [
  { id: 'sourceFile', label: 'UPLOADED FILE', minWidth: 100 },      
  { id: 'id', label: 'REFERRAL ID', minWidth: 100 },
  { id: 'PageCount', label: 'PAGE COUNT', minWidth: 100, align: 'center', format: (val) => val.toString() },
  { id: 'Patient', label: 'PATIENT', minWidth: 100},
  { id: 'Provider', label: 'PROVIDER', minWidth: 100 },  
];

// TODO: Improve Scrollbar: https://react-material.fusetheme.com/documentation/fuse-components/fuse-scrollbars


// ReferralSelector
// -------------------------
// Allow user to select referral to test Prompt
function ReferralSelector(props) {
  const {currentPrompt, referralToTest, setReferralToTest, setShowTestShell} = props;
  const [referrals, setReferrals] = useState();
  const [selected, setSelected] = useState([]); // https://react-material.fusetheme.com/documentation/material-ui-components/table
  const [inTest, setInTest] = useState(false);
  const [testId, setTestId] = useState();
  const [promptSettings, setPromptSettings] = useState([])
  const [gptModelId, setGptModelId] = useState();
  const [overwritePromptData, setOverwritePromptData] = useState(false);
  const [dataModels, setDataModels] = useState([])

  let scrollRef = React.useRef(null);
  const continueDisabled = (referralToTest == null)? true : false;
  
  useEffect(() => {
    if(referrals == null){
      getReferrals().then(data => {
        setReferrals(data);
      })
      getPromptSettings().then(data => {
        setPromptSettings(data);            
      })
    }
    if(dataModels.length == 0){
      getDataModels().then(data => {
        setDataModels(data);            
      })
    }

    if(gptModelId == null && currentPrompt != null && promptSettings != null && promptSettings.length > 0)
    {
      let settingId = currentPrompt.Settings;
      let match = promptSettings.filter(item => item.id == settingId);
      if(match.length > 0){
        setGptModelId(match[0].id);
      } else {
        setGptModelId(promptSettings[0].id);
      }   
    }

  }, [referrals, gptModelId, currentPrompt, promptSettings])


  

  const isSelected = (sourceFile) => selected.indexOf(sourceFile) !== -1;


  const setOverwrite = (event) => {
    setOverwritePromptData(event.target.checked);
  };

  const onRowClick = (event, sourceFile) => {
    
    const idx = referrals.findIndex(item => item.sourceFile == sourceFile);
    if(idx == -1) return;

    let referral = referrals[idx];
    setReferralToTest(referral);    
    //setCurrentModel(referral); // lift up state for toolbar
    let results = [].concat(sourceFile);    
    setSelected(results)
    // let item = rows[idx];
    // navigate(`/keys/${item.id}`);  // opens right panel

  };

  if(referrals == null){
    return (<div>Loading...</div>)
  }

  const onContinue = () => {
    // Before triggering test
    /*
      Check if test is already running for prompt & referral
      if is, open existing
      if not, generate a new one and get it's id.
      pass the id to the page
    */

      
    
    getPromptRunId(currentPrompt, referralToTest, gptModelId)
      .then(testId => {
        console.log(`In getPromptRunId with TestId: ${testId}`);

        if(testId != null){ // reload existing    
          setTestId(testId);
          setInTest(true);
        }
        else { 

          getReferralPages(referralToTest.id).then(pages => {
            createPromptRun(currentPrompt, referralToTest, overwritePromptData, gptModelId, dataModels?.length, pages.length).then(testId => {
              setTestId(testId);
              setInTest(true);
            })
          })
          
        }
      })

    /*
    if(referralToTest != null && currentPrompt != null){
      alert('We are ready to test. Check if one exists');
      alert(`PromptId: ${currentPrompt.id}, ReferralId: ${referralToTest.id}`)

    }
    */
    //alert(currentPrompt)
    // setInTest(true);
  }

  const onCloseTest = () => {
    setReferralToTest(null);
    setInTest(false);
  };

  const onCloseShell = () => {
    setReferralToTest(null);
    setInTest(false);
    setShowTestShell(false);
  };

  const onModelChange = (event) => {

    let match = promptSettings.filter(item => item.id == event.target.value)
    setGptModelId(match[0].id);
  }


  let selectReferral = (
    <div style={{display: (!inTest)? '' : 'none'}}>
      <div className="relative flex flex-row flex-auto items-center w-full  px-24 sm:px-24">
        <div className="flex items-center justify-between mt-14">
          <Typography
                component={motion.span}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                delay={500}
                className="text-20 font-dark bold"
                color="text.primary"
            >
            Select Referral to Test
          </Typography>
          <Button size="small" variant="contained" color="success" style={{marginLeft: '20px'}} disabled={continueDisabled} onClick={onContinue}>Continue</Button>
          {gptModelId != null && 
            <FormControl variant="standard" sx={{ m: 1, minWidth: 200, marginLeft: '20px' }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={gptModelId}
                onChange={onModelChange}
                label="GPT Model"
              >
                {promptSettings.map((setting, idx) => <MenuItem key={idx} value={setting.id}>{setting.Name}</MenuItem>)}              
              </Select>
            </FormControl>
          }

          <FormControlLabel control={<Switch  size="small" checked={overwritePromptData} onChange={setOverwrite} />} label={(overwritePromptData)? "Overwrite Prompt Data": "Preserve Prompt Data"} sx={{  minWidth: 200, marginLeft: '15px' }}  />

        </div>
        <div className="flex flex-1 justify-end" style={{height: '90%'}} onClick={onCloseShell}>                  
          <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
        </div>
      </div>
    <TableContainer className="m-10 p-20 pt-0" >
      <Table stickyHeader aria-label="sticky table" size="small" sx={{ maxHeight: '60%', width: '97%' }}>
        <TableHead  >
          <TableRow >
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, backgroundColor: 'transparent',  color: 'primary.main', fontWeight: 700}}
                sx={{borderBottom: .1, borderColor: '#496e7d'}}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="m-10" style={{height: '50%', overflowX: 'hidden', overflowY: 'scroll'}}>
          {referrals              
            .map((row) => {

              const isItemSelected = isSelected(row.sourceFile);

              return (
                <TableRow 
                  hover 
                  role="checkbox" 
                  tabIndex={-1} 
                  key={row.id}
                  onClick={(event) => onRowClick(event, row.sourceFile)}
                  selected={isItemSelected}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
  );

  let monitorTest = (
    <div style={{display: (inTest)? '' : 'none'}}>
      <div className="relative flex flex-row flex-auto items-center w-full px-24 sm:px-24">
      <div className="flex flex-row items-center justify-between mt-14">
      <Stack>
        <Typography
              component={motion.span}
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
              delay={500}
              className="text-20 font-dark bold"
              color="text.primary"
          >
          Test Details
        </Typography>        
        <Typography
              component={motion.span}
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
              delay={500}
              className="text-14 font-dark bold"
              color="text.secondary"
          >
          Applying prompt to <strong>{referralToTest?.sourceFile}</strong> (<i>referral</i>: {referralToTest?.id})
        </Typography>
        </Stack>
      </div>
      <div className="flex flex-1 justify-end" style={{height: '90%'}} onClick={onCloseTest}>                  
        <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
      </div>
    </div>
      <div className="px-24 sm:px-24 mt-24">
        <MonitorTest testId={testId} referral={referralToTest} />
      </div>
    </div>  
  )

  return(
    <div>
      <Fade in={!inTest}>
        {selectReferral}
      </Fade>
      <Fade in={inTest}>
        {monitorTest}
      </Fade>
    </div>
  )
} //  ReferralSelector

export default ReferralSelector;


