
import { useContext, useEffect, useRef, useState } from 'react';
import ChatBar from '../components/ChatBar';
import DirectoryList from '../components/DirectoryList';
import clsx from 'clsx';
import useChatContext from '../../v3/ChatContext';
// import DirectoryToolbar from '../components/DirectoryToolbar';

function DirectoryTab(props) {
  
  const { className } = props;

  //  <DirectoryToolbar />
  return (
    <div className="flex flex-auto h-full min-h-0 w-full">
				<div className={clsx('flex flex-1 z-10 flex-col relative', className)}>
					<div className="flex flex-1 flex-col overflow-y-auto">
         
          <DirectoryList {...props} />
          <ChatBar {...props} />      
        </div>
      </div>
    </div>
  )

}

export default DirectoryTab;