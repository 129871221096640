import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';

import { collection, getDocs } from "firebase/firestore";
import { firestore as db } from "src/app/auth/Firebase";

let VIDEO_TYPES = {
  YOUTUBE: 'YouTube', 
  USERFLOW: 'Userflow'
}
let VIDEOS = [
  {
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Tutorial", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",
    Id: "testvideoid",
    Name: "Introduction to BrightSource", 
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "YouTube", 
    TriggeredOn: "LOGIN",
    Url: "https://www.youtube.com/embed/HzqYIg0AUEE?enablejsapi=1&rel=0", 
    VideoDescription: "Discover the essentials of BrightSource by Dovaxis, our AI-powered Regulations and Policy assistant. In this short video clip, you'll learn how to navigate the platform, search for documents, and ask effective questions to get the best results. Perfect for new users looking to get started quickly!",
    thumbnailUrl: 'https://img.youtube.com/vi/HzqYIg0AUEE/0.jpg'
  }, 
  /* Training Videos */
  {
    Name: "BrightSource Tour", 
    Id: "BrightSourceTour",
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Walkthrough", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",    
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "Userflow", 
    TriggeredOn: "",
    Url: "bf09d333-04ab-40eb-b486-6bcbbd607265", 
    UrlMobile: "b3b2f664-3f41-4455-92b3-9a0690699d4b",
    VideoDescription: "Explore the essentials! Start here to take a guided tour and get acquainted with everything BrightSource has to offer!",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2FTraining%2FBrightSource%20Tour.jpg?alt=media&token=f182ba58-8a47-489a-96c9-125307657ac2'
  },
  {
    Name: "Viewing Your Documents", 
    Id: "ViewingYourDocuments",
    Url: "5258251a-28cb-4e30-85da-9d06fe051b27", 
    VideoDescription: "Discover how to effortlessly open and view your BrightSource documents.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2FTraining%2FViewing%20Your%20Documents.jpg?alt=media&token=43ff3288-e79e-4b98-88f1-f9fc53b47e4e',
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Walkthrough", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "Userflow", 
    TriggeredOn: "",    
  },
  {
    Name: "Focus Your Document Search", 
    Id: "FocusYourDocumentSearch",
    Url: "4fd05abb-2104-442f-afbc-0f24b115d063", 
    VideoDescription: "Master the art of targeted searches with this tutorial, ensuring you get the most precise answers every time.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2FTraining%2FFocus%20Your%20Document%20Search.jpg?alt=media&token=d4bb6299-d14a-42f8-813e-590766a33aca',
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Walkthrough", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "Userflow", 
    TriggeredOn: "",    
  },
  {
    Name: "Chat Feedback", 
    Id: "ChatFeedback",
    Url: "c86d8652-12d2-44ad-b0f6-456d7ef08c5f", 
    VideoDescription: "Learn how to leave feedback on BrightSource responses to improve everyone's experience!",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2FTraining%2FChat%20Feedback.jpg?alt=media&token=49eb4efa-8466-45f6-8904-26f3c9a997b2',
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Walkthrough", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "Userflow", 
    TriggeredOn: "",    
  },
  {
    Name: "Technical Support", 
    Id: "TechnicalSupport",
    Url: "fc72a98c-9857-40e8-846b-1a7457ef0904", 
    VideoDescription: "Discover how to use BrightSource's technical support options to resolve issues, ask questions, and explore features with ease.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2FTraining%2FTechnical%20Support.jpg?alt=media&token=743454ff-99ab-4b8c-add1-a0917194c780',
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Walkthrough", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "Userflow", 
    TriggeredOn: "",    
  },
  {
    Name: "Accessibility Features", 
    Id: "AccessibilityFeatures",
    Url: "13aadc13-5dee-4fa5-be98-afd78a52863d", 
    VideoDescription: "Learn about BrightSource's accessibility features that make the app inclusive and easy to use for all.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2FTraining%2FAccessibility%20Features.jpg?alt=media&token=cd5ae9fa-2148-44e2-861a-fb92aa129f1d',
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Walkthrough", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "Userflow", 
    TriggeredOn: "",    
  },
  /* 
  {
    Name: "Mastering References", 
    Id: "MasteringReferences",
    Url: "bd81511e-6ccb-4a5d-b48e-9cd96f7ed1f1", 
    VideoDescription: "Navigate BrightSource references like a pro! Learn how to make sense of citations and easily access related documents for more insights.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2FTraining%2FMastering%20References.jpg?alt=media&token=71c33db1-370e-4a59-a9ee-07c47ec8ab7e',
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Walkthrough", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "Userflow", 
    TriggeredOn: "",    
  },
  */
  {
    Name: "Asking Great Questions", 
    Id: "AskingGreatQuestions",
    Url: "6753570a-c9e8-4827-8186-f3033f5e7bc9", 
    VideoDescription: "Discover tips for asking effective and precise questions in BrightSource to receive the best possible answers from your AI assistant.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2FTraining%2FAsking%20Great%20Questsions.jpg?alt=media&token=6b8aaf10-fd3e-4b1c-b4f2-6576319ab929',
    AllowReminder: true, 
    AllowSkip: true,
    Category: "Walkthrough", 
    CreatedOn: "2024-06-10T14:00:00Z",
    DurationInMinutes: "10:00",
    Product: "BrightSource", 
    RemindInDays: 3,
    Scheduled: "2024-06-15T12:00:00Z",
    Source: "Userflow", 
    TriggeredOn: "",    
  }
];

function Training() {
  const [videolist, setvideoList] = useState(VIDEOS);
  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const handleCardClick = (video, index) => {
    

    if(video?.Source == VIDEO_TYPES.YOUTUBE){
      setSelectedCard(video);
      setOpen(true);
    }
    else if(video?.Source == VIDEO_TYPES.USERFLOW){
      
      navigate(`/`);

      setTimeout(() => {
        if(isMobile == true){
          if(video?.UrlMobile != null){
            userflow.start(video.UrlMobile)
          }
          else {
            userflow.start(video.Url)  
          }
          
        }
        else {
          userflow.start(video.Url)
        }
      }, 10);            
    }
  };

  const fetchYouTubeThumbnail = async (Url) => {
    try {
      const videoId = extractVideoId(Url);
      return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Using YouTube API to get the thumbnail
    } catch (error) {
      console.error("Error fetching YouTube thumbnail:", error);
      return "assets/images/pages/support/shifting-img.png"; // Default image in case of error
    }
  };

  const extractVideoId = (url) => {
    const regex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

   /*
  useEffect(() => {
    const fetchVideos = async () => {

      //setvideoList(VIDEOS);
      //return;

      try {
        const videosCollection = collection(db, "Videos");
        const videosSnapshot = await getDocs(videosCollection);
        const videosList = await Promise.all(
          videosSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const thumbnailUrl = await fetchYouTubeThumbnail(data.Url);
            return { ...data, thumbnailUrl };
          })
        );
        setvideoList(videosList);
      } catch (error) {
        console.error("Error fetching videos: ", error);
      }
    };

    fetchVideos();
    
  }, []);
  */

  return (
    <div
      style={{ width: "100%" }}
      className="flex flex-wrap align-start gap-10 p-10"
    >
      {videolist.map((video, index) => (
        <Card
          key={index}
          sx={{
            width: "100%",
            maxWidth: 370,
            borderRadius: 2,
            cursor: "pointer",
          }}
          onClick={() => handleCardClick(video, index)}
        >
          <CardMedia
            sx={{ height: 200 }}
            image={
              video.thumbnailUrl ||
              "assets/images/pages/support/shifting-img.png"
            }
            title={video.Name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {video.Name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {video.VideoDescription}
            </Typography>
          </CardContent>
        </Card>
      ))}

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="large"
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {selectedCard && selectedCard.Name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ my: 2 }}>
            {selectedCard && selectedCard.VideoDescription}
          </Typography>

          <div
            style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              id="youtube-player"
              type="text/html"
              width="100%"
              height="100%"
              src={selectedCard && selectedCard.Url}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>

          <div className="flex gap-5 justify-end mt-5">
            <Button
              className="rounded my-5 bg-blue-500"
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
  width: "-webkit-fill-available",
};

export default Training;
