import { firestore as db } from 'src/app/auth/Firebase';
import { collection, getDocs, getDoc, doc, setDoc, updateDoc, addDoc, query, where, onSnapshot, Timestamp} from 'firebase/firestore';

export async function saveTest(url){
  let data = {
    url: url
  };

  let docId = new Date().toString();
  await setDoc(doc(db, 'HubSpotTest', docId), data);
}
