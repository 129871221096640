
import { useContext, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';


function DocTab(props) {
  
  const { className, form } = props;

  let isWord = false;
  if(form.fileExt == '.docx' || form.fileExt == '.doc')
    isWord = true;

  if(isWord){
    return getWordViewer(form, className);
  }
  return getDefaultViewer(form, className);

} // DocTab

function getWordViewer(form, className){
 
  let fileUrl = encodeURIComponent(form.link);
  let path = `https://docs.google.com/gview?embedded=true&url=${fileUrl}`;


  return (
    <div className="flex flex-auto h-full min-h-0 w-full">
				<div className={clsx('flex flex-1 z-10 flex-col relative', className)}>
					<div
						className="flex flex-1 flex-col overflow-y-auto"
					>
            <iframe src={path} 
              width='100%' 
              height='100%' 
              title={form.title}              
              frameborder='0'>This is an embedded 
              <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by 
              <a target='_blank' href='http://office.com/webapps'>Office Online</a>.
            </iframe>

        </div>
      </div>
    </div>
  )
}

function getDefaultViewer(form, className){
  return (
    <div className="flex flex-auto h-full min-h-0 w-full">
				<div className={clsx('flex flex-1 z-10 flex-col relative', className)}>
					<div
						className="flex flex-1 flex-col overflow-y-auto"
					>
            <iframe 
              src={form.link} 
              title={form.title}
              frameborder='0'
              height="100%"
              width="100%"
            ></iframe>
        </div>
      </div>
    </div>
  )
}

export default DocTab;