import { useState } from 'react'
import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TOOLS from './Tools';

//console.log(`onMessageChange ${e.target.value} ${chatDocs}`);
function ChatAppRouterHomepage(props) {

  const [ question, setQuestion ] = useState('');
  const [ Tools, setTools ] = useState(TOOLS);
  const [ scopedTools, setScopedTools ] = useState(TOOLS);
  const [ displayResults, setDisplayResults ] = useState(false);
  const [ testResults, setTestResults ] = useState('');

  function onEditQuestion(e) {
    
    let temp = TOOLS.filter(e.target.value, false);
    let tools = temp.filter(e.target.value);
    setScopedTools(temp);    
    setTools(tools);

    //setQuestion(value);
  }

  function onTest(e) {
    setDisplayResults(true);
  }

  return (
    <Page
      header={
        <div className="p-24">
          <h2>Chat Route Tester</h2>
        </div>
      }
      content={
        <div>
          <div className='m-20'>

            <div>
              Question to test:
              <input style={{marginLeft: '10px', padding: '8px', lineHeight:'30px', width: '500px'}} onChange={onEditQuestion}></input>
              <Button size="small" className='ml-16' variant='outlined' onClick={onTest}>TEST</Button>
            </div>
          </div>

          
          <div className='m-20 mt-40'>
            <h2>Tools being evaluated</h2> 
            <div class="mt-4 mb-4">
              <h6>{`${scopedTools.length} of ${TOOLS.length} tools in scope`}</h6>
            </div>       
            {scopedTools.map((tool, index) => (
              <div key={index} style={{color: 'gray'}}>{tool.name}</div>
            ))}
          </div>
        

          {displayResults && <div className='m-20 mt-40'>
              <h2>Selected Tool </h2>
            </div>
          }
        </div>
      }
    />
);

}

const Page = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

export default ChatAppRouterHomepage;