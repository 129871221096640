export const volumeConfig = {
  name: 'volume-converter',
  description: 'Convert between different volume units',
  units: ['cups', 'tablespoons', 'teaspoons', 'fluid_ounces', 'pints', 'quarts', 'gallons', 'liters', 'milliliters'],
  aliases: {
    'tbsp': 'tablespoons',
    'tsp': 'teaspoons',
    'fl_oz': 'fluid_ounces',
    'pt': 'pints',
    'qt': 'quarts',
    'gal': 'gallons',
    'l': 'liters',
    'ml': 'milliliters'
  },
  convert: (value, fromUnit, toUnit) => {
    const factors = {
      cups: {
        tablespoons: 16,
        teaspoons: 48,
        fluid_ounces: 8,
        pints: 0.5,
        quarts: 0.25,
        gallons: 0.0625,
        liters: 0.236588,
        milliliters: 236.588
      },
      tablespoons: {
        cups: 0.0625,
        teaspoons: 3,
        fluid_ounces: 0.5,
        pints: 0.03125,
        quarts: 0.015625,
        gallons: 0.00390625,
        liters: 0.0147868,
        milliliters: 14.7868
      },
      teaspoons: {
        cups: 0.0208333,
        tablespoons: 0.333333,
        fluid_ounces: 0.166667,
        pints: 0.0104167,
        quarts: 0.00520833,
        gallons: 0.00130208,
        liters: 0.00492892,
        milliliters: 4.92892
      },
      fluid_ounces: {
        cups: 0.125,
        tablespoons: 2,
        teaspoons: 6,
        pints: 0.0625,
        quarts: 0.03125,
        gallons: 0.0078125,
        liters: 0.0295735,
        milliliters: 29.5735
      },
      pints: {
        cups: 2,
        tablespoons: 32,
        teaspoons: 96,
        fluid_ounces: 16,
        quarts: 0.5,
        gallons: 0.125,
        liters: 0.473176,
        milliliters: 473.176
      },
      quarts: {
        cups: 4,
        tablespoons: 64,
        teaspoons: 192,
        fluid_ounces: 32,
        pints: 2,
        gallons: 0.25,
        liters: 0.946353,
        milliliters: 946.353
      },
      gallons: {
        cups: 16,
        tablespoons: 256,
        teaspoons: 768,
        fluid_ounces: 128,
        pints: 8,
        quarts: 4,
        liters: 3.78541,
        milliliters: 3785.41
      },
      liters: {
        cups: 4.22675,
        tablespoons: 67.628,
        teaspoons: 202.884,
        fluid_ounces: 33.814,
        pints: 2.11338,
        quarts: 1.05669,
        gallons: 0.264172,
        milliliters: 1000
      },
      milliliters: {
        cups: 0.00422675,
        tablespoons: 0.067628,
        teaspoons: 0.202884,
        fluid_ounces: 0.033814,
        pints: 0.00211338,
        quarts: 0.00105669,
        gallons: 0.000264172,
        liters: 0.001
      }
    };

    if (fromUnit === toUnit) {
      return {
        convertedValue: Number(value.toFixed(2)),
        formula: `${value} ${fromUnit} = ${value} ${toUnit}`
      };
    }

    const factor = factors[fromUnit][toUnit];
    const result = value * factor;

    return {
      convertedValue: Number(result.toFixed(2)),
      formula: `${value} ${fromUnit} × ${factor} = ${result.toFixed(2)} ${toUnit}`
    };
  }
};
