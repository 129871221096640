import FuseUtils from '@fuse/utils/FuseUtils';
import { config } from "dotenv";
config();

let svcURL = process.env.REACT_APP_DOVAXIS_API_PRIMARY;
// let svcURL = process.env.REACT_APP_DOVAXIS_API_SECONDARY;
// let svcURL = 'http://localhost:8080';

export const FLOWS = {

  FreeBrightSourceTour: {
      id: '2b8283fb-3c5a-49d2-9989-dcedaf99f31d',
      name: 'FREE - BrightSource Tour'
  },
  FreeBrightSourceTourV2: {
      id: 'bf09d333-04ab-40eb-b486-6bcbbd607265',
      name: 'FREE - BrightSource Tour V2'
  }  

}

export const USERFLOW_EVENTS = {
    FLOW_ENDED: 'flowEnded'
}

class UserflowLogger  {

    constructor(){
        this.uf = null;
    }

    monitor(userflow, user){
        this.uf = userflow;
        this.user = user;
        this.uf.on(USERFLOW_EVENTS.FLOW_ENDED, this.handleFlowEnded.bind(this))
    }

    handleFlowEnded(event){

        try {
            let flowId = event.flow.id;
            let userId = this?.user?.uid || this?.user?.data?.ID || this?.user?.data?.id;
            let contactId = this?.user?.HubspotContactId;
            let email = this?.user?.email || this?.user?.data?.Email || this?.user?.data?.email;
            
            this.log(USERFLOW_EVENTS.FLOW_ENDED, flowId, contactId, email, userId);
        }
        catch(ex){
            console.log(`Userflow logger caused an error: ${ex.message}`);
        }
        
    }

    async log(eventName, flowId, contactId, email, userId){
  
      try {
        // console.log(`UserFlow Event Fired: ${eventName} (eventName) ${flowId} (flowId) ${(contactId || '')} (contactId) ${email} (email) ${userId} (userId)`);
    
        const response = await fetch(`${svcURL}/Integration/GraphQL`, {
          method: "POST",      
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            query: `
              mutation UserFlowEventFired {
                eventFired (eventName: "${eventName}", flowId: "${flowId}", userId: "${userId}", email: "${email}", contactId: "${contactId}") {
                code
                message
                successful
                }
              }`,
          }),
        });
    
        let result = await response.json();
        return result?.data?.eventFired;
      }
      catch(ex){
        console.log(`An error occured while processing UserFlowEventFired: ${ex.message}`);
        //throw new Error(`An error occured while processing UserFlowEventFired: ${ex.message}`);
      }
    }
    
}
     

export default new UserflowLogger();