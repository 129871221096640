
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import useChatContext from '../ChatContext';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import SendButton from './SendButton';


function ChatBar(props){

  // const { onQuestion } = props;
  const [ questionText, setQuestionText] = useState('');
  const { chatSession } = useChatContext();
  const [ filterDirectory, setFilterDirectory ] = useState(chatSession?.filterDirectory)


  function onMessageSubmit(e) { 
    e.preventDefault();
  }

  function onMessageChange(e) { 
    chatSession.preparingText(chatSession.namespace, e.target.value);
    setQuestionText(e.target.value);
  }

  const onKeyPress = (e) => {
    if(e.key == 'Enter'){
      postQuestion(event) ;
    }
  }
  
  function postQuestion(event) {
    event.preventDefault();
    chatSession.submitText(chatSession.namespace, questionText);
    chatSession.askQuestion(questionText);
    setQuestionText('');
  }

  function onToggleDirectoryFilter() {
    chatSession.filterDirectory = !chatSession.filterDirectory;
    setFilterDirectory(chatSession.filterDirectory);
  }

  function onClearText() {
    setQuestionText('');
    chatSession.preparingText(chatSession.namespace, '');
  }

  return (
  <Paper
    square
    component="form"
    className="absolute border-t-1 bottom-0 right-0 left-2 py-2 px-0 z-20 "    
    onSubmit={onMessageSubmit}
  >
    <div className="flex items-center relative">


      {/* Message Imput Control 
      Type or speak a question
      */}
      <InputBase
        id="ask-question"
        className="flex-1 flex grow shrink-0 h-44 mx-8 px-16 border-2 rounded-full"
        placeholder="Type your question here."
        autoComplete="off"
        autoFocus={true}
        onChange={onMessageChange}
        onKeyDown={onKeyPress}
        value={questionText}
        sx={{ backgroundColor: 'white' }}

        startAdornment={
          <InputAdornment position="start" >
            {/* 
            <Tooltip title="Your Microphone has not been configured for use.">
              <FuseSvgIcon className="" color="action"   >
                heroicons-outline:microphone
              </FuseSvgIcon>
            </Tooltip>
            */}
            {filterDirectory? <Tooltip title="Click to stop filtering directory while typing">
                <FilterAltOutlinedIcon style={{marginLeft: '4px' }} onClick={onToggleDirectoryFilter} />
              </Tooltip>
              :
              <Tooltip title="Click to filter directory while typing">
                <FilterAltOffOutlinedIcon style={{marginLeft: '4px' }} onClick={onToggleDirectoryFilter} />
              </Tooltip>
             }
            
          </InputAdornment>
        }

        endAdornment={
          <InputAdornment position="end" >
              <Tooltip title="Clear text">
                <IconButton className="" type="submit" size="small" onClick={onClearText}>
                  <ClearIcon   />
                </IconButton>
              </Tooltip>
          </InputAdornment>
        }

      />

      {/* Submit Message  Control */}
      <IconButton className="" type="submit" size="large" onClick={postQuestion}>
        <SendButton />
      </IconButton>

      {/* Readback Settings Control */}
      {/*                 
        <Tooltip title="Readback response settings">
          <IconButton  onClick={onReadbackClicked}>
            {(readback == true)?
              <FuseSvgIcon className="text-24" color="action">
                heroicons-outline:speaker-wave
              </FuseSvgIcon>
              :
              <FuseSvgIcon className="text-24" color="action">
              heroicons-outline:speaker-x-mark
              </FuseSvgIcon>
            }
          </IconButton>
        </Tooltip>
      */}

    </div>
  </Paper>)
}

export default ChatBar;