import {useContext} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DataModelContext from './context';
import { useNavigate} from 'react-router-dom';

// import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

function Toolbar(props) {
  const { currentModel, setCurrentModel } = useContext(DataModelContext);
  const navigate = useNavigate();

  function onNewModel(){
    alert('This functionality is under development.')
  }

  function onProcess(){
    navigate(`/referral/process/${currentModel.id}?file=${currentModel.name}`);
  }
  
  return (
    <div className="flex flex-col sm:flex-row item-center sm:items-start space-y-6 sm:space-y-0 p-6 sm:p-6  w-full border-b-1 flex items-center justify-between">
      <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
       
        <ButtonGroup variant="text" aria-label="text button group" >
       {/*
        <Button onClick={onNewModel}><span style={{marginLeft: '8px', marginRight: '6px'}}>New</span></Button>
         */}
        <Button onClick={onProcess}><span style={{marginLeft: '6px', marginRight: '6px'}}>Test Selected Row</span></Button>
      </ButtonGroup>
      </div>

      <div className="flex items-center -mx-8">
        
      </div>
    </div>
  );
}

export default Toolbar;