
import { httpsCallable } from "firebase/functions";
import { functions } from "src/app/auth/Firebase";
import openai from 'src/app/auth/OpenAI';


const askQuestionPinecone = httpsCallable(functions, 'askQuestionPineconeV3');
const createQuestionPinecone = httpsCallable(functions, 'createQuestionPineconeV3');

async function ask(question, namespace, vectorCount=1, language) {

  console.log(`question: ${question}`);
  console.log(`namespace: ${namespace}`);
  console.log(`vectorCount: ${vectorCount}`);

  let vectors = null;
  
  try {
    const pineConeAnswer = await askQuestionPinecone({question, namespace, vectorCount});
    vectors = pineConeAnswer.data;
  }
  catch(ex){
    console.error(`An error occured in ask.searchPinecone: ${ex.message}`);
    throw ex;
  }
  let response = {answer: '', vectors: vectors.answerVectors, requestedVectors: vectorCount, question, questionEmbedding: vectors.questionEmbedding};

  console.log(`answerVectors: ${JSON.stringify(vectors.answerVectors)}`);
  console.log(`language: ${JSON.stringify(language)}`);

  if (vectors.answerVectors.length == 0) {
    response.answer = 'No relevant results.';
  } 
  else {    
      
    let reply = null;
    
    try {
      reply = await answerQuestionWithGPT(vectors.answerVectors, question, vectorCount, language);
    }
    catch(ex){
      console.error(`An error occured in ask.answerQuestionWithGPT: ${ex.message}`);
      throw ex;
    }
    
    console.log(`answer: ${JSON.stringify(reply)}`);

    for(const prop in reply){
      response[prop] = reply[prop];
    }

  }  
  return response;
}

//
// answerQuestionWithGPT
// 
async function answerQuestionWithGPT(vectors, question, vectorCount=1, language) {

  let vectorData = '';
  for(let i=0; i<vectors.length;i++){
    if(i>vectorCount) break;
    vectorData +=  vectors[i].metadata.text + " ";
  } 
  
  console.log(`answerQuestionWithGPT Question: ${vectorData}`);

  let plusLanguage = '';
  if(language.id != 'en'){
    plusLanguage = `Return response in ${language.title}.`
    console.log(`Language ${plusLanguage}`);
  }

  // Original Prompt
  let sysPrompt = `You are an AI agent functioning as a Q&A bot within a retrieval augmented generation system. 
  Your mission is to answer the users question using only the context provided.
  ${plusLanguage}
  If the question can be answered with the context provided answer as follows:
  ContextTrue: (Insert a thorough answer to the question) and then
  (Insert a bulleted line for each answer direct quote.  Prefix section with 'References:'"  

  Otherwise respond:
  "ContextFalse: (Insert three new question of your own based only on the CONTEXT provided )" and then "Apology: (Insert an apology for not being able to find the answer and then suggest the user rephrase the question)"`;


  // New Prompt
  let sysPromptNew = `
  You are an AI agent functioning as a Q&A bot within a retrieval augmented generation system. Your mission is to answer the users question using only the context provided.

  If the question can be answered with the context provided answer as follows:

  "ContextTrue: (Insert your answer to the question) and a bullet point list of references prefixed with "References:" 
  (Insert exact quotes from the context that support the answer.  )"

  CRITICAL INFORMATION: When providing references you must exactly reproduce them as shown in the original context, even if the source is grammatically or syntactically incorrect.  References must only be at most a single sentence in length.

  Otherwise respond:
  "ContextFalse: (Insert three new question of your own based only on the CONTEXT provided )" and then "Apology: (Insert an apology for not being able to find the answer and then suggest the user rephrase the question)"
  
  CRITICAL REMINDER: When providing references you must exactly reproduce them as shown in the original context, even if the source is grammatically or syntactically incorrect. References must only be at most a single sentence in length.
  `

  console.log(`System Prompt:`);
  console.log(sysPrompt);

  const userPrompt = `Question: ${question} \n\n CONTEXT: ${vectorData}`;  
  
  console.log(`User Prompt:`);
  console.log(userPrompt);

  try {
    // gpt-4o
    // gpt-3.5-turbo-0125
    // gpt-3.5-turbo-16k
    // gpt-4-0613
    // gpt-3.5-turbo-1106
    // gpt-3.5-turbo-0125
    const completion = await openai.chat.completions.create({
      model: "gpt-4o",
      messages: [
          {role: "system", content: sysPrompt},
          {role: "user", content: userPrompt}
      ],
      temperature: .2,
      max_tokens: 3000,
      top_p: 1
    });

    console.log(`Response:`);
    console.log(completion.choices[0].message.content);

    let result = convertResponse(completion.choices[0].message.content);    
    return result;
  } 
  catch(ex) {
    console.log(`Error: ${ex.message}`);
  }
}

async function createQuestionsByDocTitle(namespace, docTitle, vectorCount=3) {
  return await createQuestionPinecone({docTitle, namespace, vectorCount});
}

async function createQuestionsByFileName(namespace, fileName, vectorCount=1) {
  return await createQuestionPinecone({fileName, namespace, vectorCount});
}

async function createQuestionsByNamespace(namespace, vectorCount=1) {
  return await createQuestionPinecone({namespace, vectorCount});
}


//
// Convert Response
// 

function parseAnswerFound(response){
  let answer = response.replace('ContextTrue:', '').replace('References:', '').trim(); 

  //let reader = new Parser();
  //let writer = new HtmlRenderer();
  //let parsed = reader.parse(answer); 
  //let html = writer.render(parsed);

  //return {answered: true, references: [], answer: (<div  dangerouslySetInnerHTML={{ __html: html }} />)}
  //return {answered: true, references: [], html}

  // add spaces for voting
  answer += "\n\n\n";
  return {answered: true, references: [], answer}
}

function parseAnswerMissing(response){
  let contextSection = 'ContextFalse:';
  let apologySection = 'Apology:';
  
  let loc = response.indexOf(apologySection);
  let apology = response.substr(loc + apologySection.length).trim();
  
  let examples = response.substr(contextSection.length, loc - contextSection.length).trim();
  
  let split = examples.split(contextSection);
  if(split.lenth > 0){
    examples = split;
  }
  else {
    examples = examples.split('\n');
    for(var i = 0; i < examples.length; i++){
      loc = examples[i].indexOf('.');
      examples[i] = examples[i].substr(loc + 1).trim();
    }
  }
  
  return {answered: false, answer: apology, examples}  
}

function convertResponse(response){
  let result = null;  

  try {
    if(response.indexOf('ContextTrue:') > -1){
      result = parseAnswerFound(response);
    }
    else {
      result = parseAnswerMissing(response);
    }
  }
  catch(ex){
    console.warn(`An error occured converting ChatGPT Response: ${ex.message}`);
    result = {error: true, answer: '', answered: false, response: {}}
  }
 
  return result;
}
export default {ask, createQuestionsByFileName, createQuestionsByNamespace, createQuestionsByDocTitle};