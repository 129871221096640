import DovIcon from './DoveIcon';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import useChatContext from '../ChatContext';

function ChatEmptyLanding(props){

  const { chatSession } = useChatContext();

  if(chatSession?.question?.current != null) {
    return (<></>);
  } else {    
    return (<div className="flex flex-col flex-1 items-left  p-24">
      <div className="flex flex-row">
        <DovIcon />
        <Typography className="px-16 pb-24 mt-24 " color="text.secondary">        
          Use the Chat Bar below to ask questions.           
          <br />        
          The gray dove indicates which resources are bright and support questions.        
        </Typography>
      </div>
      <div className="flex flex-row">
        <Typography className="px-16 pb-24 mt-24" color="text.secondary">                  
          Each resource on the directory tab with the dove depicted below is bright:
          <br />
          <img src={'/assets/images/pages/brightsource/brightsource_indicator_guide.png'} style={{width:'70%'}} />
        </Typography>
      </div>
    </div>
    )
  }
}


export default ChatEmptyLanding;