export const SholomContentX = [
  {
    name: "Policies and Procedures", type: "dir", open: false,
    "id": "3979863d-3322-4378-afa7-bb9146c108b9",
    "path": "Policies and Procedures",
    "storage": "PoliciesAndProcedures",
    data: [
    ]
  },
  {
    "name": "Regulations",
    type: "dir", open: false,
    "id": "c2020ea9-8b2a-45cf-bce7-6e86ac501c59",
    "path": "Regulations",
    data: [
      {
        "name": "Federal",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd4684117d4",
        "path": "Regulations/Federal",
        data: []
      },
      {
        "name": "State",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411bbcc",
        "path": "Regulations/State",
        data: []
      }

    ]
  }
]



export const SholomContent = [
  {
    "name": "Services",
    type: "dir", open: false,
    "id": "svc0ea9-8b2a-45cf-bce7-6e86ac501c412",
    "path": "TODO",
    data: [
    ]
  },
  
  {
    "name": "Campus",
    type: "dir", open: false,
    "id": "c2020ea9-8b2a-45cf-bce7-6e86ac661c412",
    "path": "TODO",
    data: [
      {
        "name": "Ackerberg Family Sholom West Campus",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a01k",
        "path": "TODO",
        data: []
      },
      {
        "name": "Shaller Family Sholom East Campus",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a10b",
        "path": "TODO",
        data: []
      },
    ]
  },

  
  {
    "name": "Building",
    type: "dir", open: false,
    "id": "c2020ea9-8b2a-45cf-bce7-6e86ac501c412",
    "path": "TODO",
    data: [
      {
        "name": "Bentson Family Assisted Living",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a02j",
        "path": "TODO",
        data: []
      },

      {
        "name": "David Feinberg Vitality & Aquatics Center",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a03i",
        "path": "TODO",
        data: []
      },

      {
        "name": "Harry & Jeanette Weinberg Apartments",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a04h",
        "path": "TODO",
        data: []
      },

      {
        "name": "Knollwood Place Apartments",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a05g",
        "path": "TODO",
        data: []
      },

      {
        "name": "Leo and Doris Hodroff Pavilion for Memory Care",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a06f",
        "path": "TODO",
        data: []
      },

      {
        "name": "Menorah West",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a07e",
        "path": "TODO",
        data: []
      },

      {
        "name": "Phillips Center for Memory Care",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a22d",
        "path": "TODO",
        data: []
      },

      {
        "name": "Roitenberg Family Assisted Living",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a09c",
        "path": "TODO",
        data: []
      },

      

      {
        "name": "Shirley Chapman Sholom Home East",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a11a",
        "path": "TODO",
        data: []
      },

      {
        "name": "Sholom Home West",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd468411a12z",
        "path": "TODO",
        data: []
      },
    ]
  },

  {
    "name": "Regulations",
    type: "dir", open: false,
    "id": "c2020ea9-8b2a-45cf-bce7-6e86ac501c59",
    "path": "Regulations",
    data: [
      {
        "name": "Federal",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd4684117d4",
        "path": "Regulations/Federal",
        data: [
          {
            "name": "CMS 2012 Life Safety Code",
            "type": "unknown",
            "id": "01afa0b9-4342-4d04-b112-8bafd3b93896",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F01afa0b9-4342-4d04-b112-8bafd3b93896.pdf?alt=media&token=2fc16e6d-3b30-4557-bafe-27d1c2e98ac3",
            "fileExt": ".pdf",
            "fileName": "CMS 2012 Life Safety Code.pdf"
          }
        ]
      },
      
      {
        "name": "State",
        type: "dir", open: false,
        "id": "6b655419-8432-4173-a54e-cdd4684117d2",
        "path": "Regulations/State",
        data: [
          {
            "name": "MN",
            type: "dir", open: false,
            "id": "92188dd2-085a-4add-8797-9fa3c2bce66e",
            "path": "Regulations/MN",
            data: [
              {
                "name": "Life Safety",
                type: "dir", open: false,
                "id": "509bc6cf-c6c9-4d6a-8422-74ac29f2c604",
                "path": "Regulations/MN/Life Safety",
                data: [
                  {
                    "name": "State Ops Manual Appendix 1 - Life Safety",
                    "type": "unknown",
                    "id": "5f35b40d-f3ca-4751-b571-9ba6c12f39e0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5f35b40d-f3ca-4751-b571-9ba6c12f39e0.pdf?alt=media&token=dfea6242-e8a5-4b71-9871-b0f720e8071c",
                    "fileExt": ".pdf",
                    "fileName": "State Ops Manual Appendix 1 - Life Safety.pdf"
                  }
                ]
              },
              {
                "name": "MN 144A",
                type: "dir", open: false,
                "id": "2d9026f5-77bb-47cc-b3bb-fa92b99b2079",
                "path": "Regulations/MN/MN 144A",
                data: [
                  {
                    "name": "Board of Executives for Long Term Services and Supports",
                    type: "dir", open: false,
                    "id": "2785b3cc-72a4-406a-adde-8e9682b5779e",
                    "path": "Regulations/MN/MN 144A/Board of Executives for Long Term Services and Supports",
                    data: [
                      {
                        "name": "144A.19",
                        "type": "unknown",
                        "id": "51ac0760-372e-410d-80e0-10acbaab7542",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F51ac0760-372e-410d-80e0-10acbaab7542.pdf?alt=media&token=72556349-6582-418e-8b7d-862a28dc603e",
                        "fileExt": ".pdf",
                        "fileName": "144A.19.pdf"
                      },
                      {
                        "name": "144A.20",
                        "type": "unknown",
                        "id": "02fdb8d7-517d-4df3-ba64-a060278c4339",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F02fdb8d7-517d-4df3-ba64-a060278c4339.pdf?alt=media&token=25958503-e468-414b-aef2-c9c8cd14959a",
                        "fileExt": ".pdf",
                        "fileName": "144A.20.pdf"
                      },
                      {
                        "name": "144A.21",
                        "type": "unknown",
                        "id": "b869cbb5-e5ed-4aea-b0fd-0f8dd0c703e1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb869cbb5-e5ed-4aea-b0fd-0f8dd0c703e1.pdf?alt=media&token=be6c0ad0-6a75-4b2b-b443-8d9ec0de5ff4",
                        "fileExt": ".pdf",
                        "fileName": "144A.21.pdf"
                      },
                      {
                        "name": "144A.22",
                        "type": "unknown",
                        "id": "bbe06df4-0819-406a-9386-9b93eedbd3a7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbbe06df4-0819-406a-9386-9b93eedbd3a7.pdf?alt=media&token=e9f15d02-93cc-4096-940c-72116e2c9aed",
                        "fileExt": ".pdf",
                        "fileName": "144A.22.pdf"
                      },
                      {
                        "name": "144A.23",
                        "type": "unknown",
                        "id": "588359a8-8b74-4e7c-bdd0-d378c9778d3a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F588359a8-8b74-4e7c-bdd0-d378c9778d3a.pdf?alt=media&token=cd3cddac-3176-416e-9ec6-822027bfafe2",
                        "fileExt": ".pdf",
                        "fileName": "144A.23.pdf"
                      },
                      {
                        "name": "144A.24",
                        "type": "unknown",
                        "id": "c44b64f6-449a-4050-83b4-f03df49fb12f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc44b64f6-449a-4050-83b4-f03df49fb12f.pdf?alt=media&token=b34ed1f4-8742-4828-b745-13f14ccde133",
                        "fileExt": ".pdf",
                        "fileName": "144A.24.pdf"
                      },
                      {
                        "name": "144A.251",
                        "type": "unknown",
                        "id": "b380347f-80d9-4147-943b-82d548d3f76b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb380347f-80d9-4147-943b-82d548d3f76b.pdf?alt=media&token=338354f4-638e-4a36-8a61-c27b06b43e1b",
                        "fileExt": ".pdf",
                        "fileName": "144A.251.pdf"
                      },
                      {
                        "name": "144A.2511",
                        "type": "unknown",
                        "id": "31a4332f-a0de-4554-90b6-8f170b3fc0b0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F31a4332f-a0de-4554-90b6-8f170b3fc0b0.pdf?alt=media&token=48a6ec2c-f39d-44c2-8763-fecae7260d7a",
                        "fileExt": ".pdf",
                        "fileName": "144A.2511.pdf"
                      },
                      {
                        "name": "144A.252",
                        "type": "unknown",
                        "id": "02ab62c2-af80-4835-84da-45e5a12bb637",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F02ab62c2-af80-4835-84da-45e5a12bb637.pdf?alt=media&token=8ec11918-2688-4406-ae2f-fd499d900aee",
                        "fileExt": ".pdf",
                        "fileName": "144A.252.pdf"
                      },
                      {
                        "name": "144A.26",
                        "type": "unknown",
                        "id": "b6011aea-29e8-4466-8834-cc4115e43296",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb6011aea-29e8-4466-8834-cc4115e43296.pdf?alt=media&token=e7222900-0f07-4407-9884-5b3c491fa79c",
                        "fileExt": ".pdf",
                        "fileName": "144A.26.pdf"
                      },
                      {
                        "name": "144A.27",
                        "type": "unknown",
                        "id": "8c0e4765-ca01-40fe-9916-040a28b0950f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8c0e4765-ca01-40fe-9916-040a28b0950f.pdf?alt=media&token=05aa900f-457f-467d-a74e-faa35de0637b",
                        "fileExt": ".pdf",
                        "fileName": "144A.27.pdf"
                      },
                      {
                        "name": "144A.28",
                        "type": "unknown",
                        "id": "931a9131-9e52-4ed6-aac2-910721bdbc95",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F931a9131-9e52-4ed6-aac2-910721bdbc95.pdf?alt=media&token=2e2852a0-cca0-48ca-a637-a52d839631ec",
                        "fileExt": ".pdf",
                        "fileName": "144A.28.pdf"
                      },
                      {
                        "name": "144A.291",
                        "type": "unknown",
                        "id": "e2be3ca1-e92e-4f82-9249-e3ee54b2c0d2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe2be3ca1-e92e-4f82-9249-e3ee54b2c0d2.pdf?alt=media&token=68a643a8-da84-49d1-8e92-18cef0a3f652",
                        "fileExt": ".pdf",
                        "fileName": "144A.291.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Health Care Facility Grievances",
                    type: "dir", open: false,
                    "id": "a6d443d0-aa68-4a6d-9791-4ceb14b6ea07",
                    "path": "Regulations/MN/MN 144A/Health Care Facility Grievances",
                    data: [
                      {
                        "name": "144A.51",
                        "type": "unknown",
                        "id": "d0888ffd-c8cf-4d24-8dcc-3f40d62b52c9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd0888ffd-c8cf-4d24-8dcc-3f40d62b52c9.pdf?alt=media&token=da50aacc-9cdc-40b9-b01f-860c2998f725",
                        "fileExt": ".pdf",
                        "fileName": "144A.51.pdf"
                      },
                      {
                        "name": "144A.52",
                        "type": "unknown",
                        "id": "4c289542-8be7-4e96-8367-c0f73c3ef986",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4c289542-8be7-4e96-8367-c0f73c3ef986.pdf?alt=media&token=0c84dbf0-38f4-4d8f-a094-cc348df24ae4",
                        "fileExt": ".pdf",
                        "fileName": "144A.52.pdf"
                      },
                      {
                        "name": "144A.53",
                        "type": "unknown",
                        "id": "7dc8a55a-18d4-4486-bdd4-1fe2de8d1235",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7dc8a55a-18d4-4486-bdd4-1fe2de8d1235.pdf?alt=media&token=5d45d449-4911-4373-9b23-e199328b48f3",
                        "fileExt": ".pdf",
                        "fileName": "144A.53.pdf"
                      },
                      {
                        "name": "144A.54",
                        "type": "unknown",
                        "id": "d2cc7f85-6733-474f-b92e-fcdc242fba55",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd2cc7f85-6733-474f-b92e-fcdc242fba55.pdf?alt=media&token=a91bfa85-ace2-479d-85fb-c1a9812ef9c5",
                        "fileExt": ".pdf",
                        "fileName": "144A.54.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Home and Community-Based Services Designation",
                    type: "dir", open: false,
                    "id": "4c19ee9b-3c48-457e-b1ab-01e74c5ad790",
                    "path": "Regulations/MN/MN 144A/Home and Community-Based Services Designation",
                    data: [
                      {
                        "name": "144A.484",
                        "type": "unknown",
                        "id": "3e4150cb-a97d-4958-81e6-8e045be27d34",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3e4150cb-a97d-4958-81e6-8e045be27d34.pdf?alt=media&token=cc4cdfa3-d72a-47ec-beca-4b4324c65e2f",
                        "fileExt": ".pdf",
                        "fileName": "144A.484.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Home Care Licensing",
                    type: "dir", open: false,
                    "id": "1c33136f-2a30-41c4-a3c6-a3859c396682",
                    "path": "Regulations/MN/MN 144A/Home Care Licensing",
                    data: [
                      {
                        "name": "144A.471",
                        "type": "unknown",
                        "id": "ffd8fcef-bfa0-445b-8e1b-59644f7c5d40",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fffd8fcef-bfa0-445b-8e1b-59644f7c5d40.pdf?alt=media&token=2e64d6ac-ecbc-4afd-ad85-920fa75fd0ad",
                        "fileExt": ".pdf",
                        "fileName": "144A.471.pdf"
                      },
                      {
                        "name": "144A.472",
                        "type": "unknown",
                        "id": "130024c9-2c26-4172-819f-1681859d1d83",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F130024c9-2c26-4172-819f-1681859d1d83.pdf?alt=media&token=fc5a0eef-ed53-4263-9417-cffb200ad602",
                        "fileExt": ".pdf",
                        "fileName": "144A.472.pdf"
                      },
                      {
                        "name": "144A.473",
                        "type": "unknown",
                        "id": "d151be2e-7913-4f05-992c-b0046a82800a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd151be2e-7913-4f05-992c-b0046a82800a.pdf?alt=media&token=d6a91acc-1801-44e3-871a-26f6ab313786",
                        "fileExt": ".pdf",
                        "fileName": "144A.473.pdf"
                      },
                      {
                        "name": "144A.474",
                        "type": "unknown",
                        "id": "58cc464f-1f89-42a0-91fa-5973bb914602",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F58cc464f-1f89-42a0-91fa-5973bb914602.pdf?alt=media&token=0cc24cba-80a3-4f10-bc94-29b781eada35",
                        "fileExt": ".pdf",
                        "fileName": "144A.474.pdf"
                      },
                      {
                        "name": "144A.475",
                        "type": "unknown",
                        "id": "803c776f-f013-42ea-9320-2567dd74b49d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F803c776f-f013-42ea-9320-2567dd74b49d.pdf?alt=media&token=854b34f6-8053-4543-b652-97f89ca270eb",
                        "fileExt": ".pdf",
                        "fileName": "144A.475.pdf"
                      },
                      {
                        "name": "144A.476",
                        "type": "unknown",
                        "id": "73cf2988-455a-49ed-a047-4dbab72ec381",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F73cf2988-455a-49ed-a047-4dbab72ec381.pdf?alt=media&token=56c0c3e5-f734-4dc3-a6e6-4a8f18a68a6d",
                        "fileExt": ".pdf",
                        "fileName": "144A.476.pdf"
                      },
                      {
                        "name": "144A.477",
                        "type": "unknown",
                        "id": "af56c69c-ea32-45e5-b500-4bd2edb5dac7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Faf56c69c-ea32-45e5-b500-4bd2edb5dac7.pdf?alt=media&token=af814018-fe51-4d85-9fba-0a393ca0f88b",
                        "fileExt": ".pdf",
                        "fileName": "144A.477.pdf"
                      },
                      {
                        "name": "144A.478",
                        "type": "unknown",
                        "id": "956e1131-014c-44dc-9096-673f0f97a913",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F956e1131-014c-44dc-9096-673f0f97a913.pdf?alt=media&token=6242b101-aa74-48b9-bdff-6918b0428095",
                        "fileExt": ".pdf",
                        "fileName": "144A.478.pdf"
                      },
                      {
                        "name": "144A.479",
                        "type": "unknown",
                        "id": "ccad3e0a-0ef2-4595-8e15-69d9623c26df",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fccad3e0a-0ef2-4595-8e15-69d9623c26df.pdf?alt=media&token=fb624c94-c5b5-45e1-8c5b-51608d5b6bee",
                        "fileExt": ".pdf",
                        "fileName": "144A.479.pdf"
                      },
                      {
                        "name": "144A.4791",
                        "type": "unknown",
                        "id": "506ea49d-df38-43ff-96a7-5bae714990af",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F506ea49d-df38-43ff-96a7-5bae714990af.pdf?alt=media&token=4e8d810b-0aec-4fde-acfc-3fc23bc5ab6e",
                        "fileExt": ".pdf",
                        "fileName": "144A.4791.pdf"
                      },
                      {
                        "name": "144A.4792",
                        "type": "unknown",
                        "id": "951028fc-4a23-4314-a6b5-d9b99fc14f76",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F951028fc-4a23-4314-a6b5-d9b99fc14f76.pdf?alt=media&token=c7debcb3-641c-4bae-a51a-57f452abaf41",
                        "fileExt": ".pdf",
                        "fileName": "144A.4792.pdf"
                      },
                      {
                        "name": "144A.4793",
                        "type": "unknown",
                        "id": "c88476f8-8fe9-487c-a4d1-18de1529d8c1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc88476f8-8fe9-487c-a4d1-18de1529d8c1.pdf?alt=media&token=d9f196f2-f7fc-4b6d-8452-5f555008e666",
                        "fileExt": ".pdf",
                        "fileName": "144A.4793.pdf"
                      },
                      {
                        "name": "144A.4794",
                        "type": "unknown",
                        "id": "b33238f2-94c6-45e1-afbd-e2e402456a52",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb33238f2-94c6-45e1-afbd-e2e402456a52.pdf?alt=media&token=ba821aa0-5499-445f-a7a7-0b284c4939d6",
                        "fileExt": ".pdf",
                        "fileName": "144A.4794.pdf"
                      },
                      {
                        "name": "144A.4795",
                        "type": "unknown",
                        "id": "32890bb3-2967-43fe-aca4-75099db3250e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F32890bb3-2967-43fe-aca4-75099db3250e.pdf?alt=media&token=47ca1a6d-35b6-4fc8-b415-b102f6c2482e",
                        "fileExt": ".pdf",
                        "fileName": "144A.4795.pdf"
                      },
                      {
                        "name": "144A.4796",
                        "type": "unknown",
                        "id": "5618cf33-a7f5-4603-aa52-e0b5a316d4e4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5618cf33-a7f5-4603-aa52-e0b5a316d4e4.pdf?alt=media&token=64e81c35-aa95-417a-a251-c7bc4027b1dd",
                        "fileExt": ".pdf",
                        "fileName": "144A.4796.pdf"
                      },
                      {
                        "name": "144A.4797",
                        "type": "unknown",
                        "id": "cfa80533-064d-49e2-91f5-24e5eeac951a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcfa80533-064d-49e2-91f5-24e5eeac951a.pdf?alt=media&token=66678e6f-7a44-4d7f-b310-80f92fc4cc85",
                        "fileExt": ".pdf",
                        "fileName": "144A.4797.pdf"
                      },
                      {
                        "name": "144A.4798",
                        "type": "unknown",
                        "id": "721a9c5c-0921-43ad-b7f1-8e1e41255f1d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F721a9c5c-0921-43ad-b7f1-8e1e41255f1d.pdf?alt=media&token=9147fa86-8389-41f6-a658-7229e3f6dacd",
                        "fileExt": ".pdf",
                        "fileName": "144A.4798.pdf"
                      },
                      {
                        "name": "144A.4799",
                        "type": "unknown",
                        "id": "46169546-dd25-4ea1-b18a-9f227ee52d3a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F46169546-dd25-4ea1-b18a-9f227ee52d3a.pdf?alt=media&token=dec2dc65-07dc-44a5-b239-75c9bc4650be",
                        "fileExt": ".pdf",
                        "fileName": "144A.4799.pdf"
                      },
                      {
                        "name": "144A.482",
                        "type": "unknown",
                        "id": "c51cf09a-d155-447d-bb8d-9a86a1f2befc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc51cf09a-d155-447d-bb8d-9a86a1f2befc.pdf?alt=media&token=0b504640-e00d-4c47-a92b-8668b1811a7c",
                        "fileExt": ".pdf",
                        "fileName": "144A.482.pdf"
                      },
                      {
                        "name": "144A.483",
                        "type": "unknown",
                        "id": "f572ef22-b371-462e-8951-4aa4369f0204",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff572ef22-b371-462e-8951-4aa4369f0204.pdf?alt=media&token=b176f8a2-fd9a-4a57-8e81-6725720c521b",
                        "fileExt": ".pdf",
                        "fileName": "144A.483.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Home Care Program",
                    type: "dir", open: false,
                    "id": "94d35b4d-b1fe-4f14-8754-5a1cf5f279ef",
                    "path": "Regulations/MN/MN 144A/Home Care Program",
                    data: [
                      {
                        "name": "144A.43",
                        "type": "unknown",
                        "id": "7ee224f9-7c4c-43bd-9d70-8e5789d1b5e7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7ee224f9-7c4c-43bd-9d70-8e5789d1b5e7.pdf?alt=media&token=be5dbfcd-bf17-405f-9531-09d7b5f8ce38",
                        "fileExt": ".pdf",
                        "fileName": "144A.43.pdf"
                      },
                      {
                        "name": "144A.44",
                        "type": "unknown",
                        "id": "53eb0fc9-85cd-487d-b83f-e7ec155ad807",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F53eb0fc9-85cd-487d-b83f-e7ec155ad807.pdf?alt=media&token=5e93f6e7-1f12-4281-b7da-f066b80cc39d",
                        "fileExt": ".pdf",
                        "fileName": "144A.44.pdf"
                      },
                      {
                        "name": "144A.441",
                        "type": "unknown",
                        "id": "ecc30761-10ea-4367-8f91-9454224b092d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fecc30761-10ea-4367-8f91-9454224b092d.pdf?alt=media&token=d515697e-5e87-4843-af5b-0e15e0ddf799",
                        "fileExt": ".pdf",
                        "fileName": "144A.441.pdf"
                      },
                      {
                        "name": "144A.442",
                        "type": "unknown",
                        "id": "04b7ea96-41c1-4f4e-b582-fd12460be1a7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F04b7ea96-41c1-4f4e-b582-fd12460be1a7.pdf?alt=media&token=ead1f741-f16c-4eb2-9e48-207c4fba388b",
                        "fileExt": ".pdf",
                        "fileName": "144A.442.pdf"
                      },
                      {
                        "name": "144A.45",
                        "type": "unknown",
                        "id": "baa1315d-7508-4330-b41d-a02b32a415b8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbaa1315d-7508-4330-b41d-a02b32a415b8.pdf?alt=media&token=ab0b3028-6c19-4ca5-966b-fa63294488d7",
                        "fileExt": ".pdf",
                        "fileName": "144A.45.pdf"
                      },
                      {
                        "name": "144A.47",
                        "type": "unknown",
                        "id": "299fd46c-9244-4964-b1f4-71d84382afff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F299fd46c-9244-4964-b1f4-71d84382afff.pdf?alt=media&token=d5e6f805-45f1-4cd4-b922-6e9cb7dc8e8b",
                        "fileExt": ".pdf",
                        "fileName": "144A.47.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Hospice Care Licensing",
                    type: "dir", open: false,
                    "id": "71885070-818b-4196-a85b-f4b33e3c6a25",
                    "path": "Regulations/MN/MN 144A/Hospice Care Licensing",
                    data: [
                      {
                        "name": "144A.75",
                        "type": "unknown",
                        "id": "5fca4e97-3ff8-457a-80ee-eec2a64723c4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5fca4e97-3ff8-457a-80ee-eec2a64723c4.pdf?alt=media&token=0f03b68d-3b72-4a01-be0e-4229493adfd1",
                        "fileExt": ".pdf",
                        "fileName": "144A.75.pdf"
                      },
                      {
                        "name": "144A.751",
                        "type": "unknown",
                        "id": "e0c11b4e-8f7d-44ab-a27d-9e90bff5b9c8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe0c11b4e-8f7d-44ab-a27d-9e90bff5b9c8.pdf?alt=media&token=77a3e0c6-903d-4afe-bd0e-b1dfcd76c2dc",
                        "fileExt": ".pdf",
                        "fileName": "144A.751.pdf"
                      },
                      {
                        "name": "144A.752",
                        "type": "unknown",
                        "id": "b00fc346-d908-47f3-9597-6cbb4ffa9f40",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb00fc346-d908-47f3-9597-6cbb4ffa9f40.pdf?alt=media&token=4de51e0e-c850-4725-b28c-9003f42cd128",
                        "fileExt": ".pdf",
                        "fileName": "144A.752.pdf"
                      },
                      {
                        "name": "144A.753",
                        "type": "unknown",
                        "id": "1ddb3a9d-d12e-4813-827d-ccf916f93363",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1ddb3a9d-d12e-4813-827d-ccf916f93363.pdf?alt=media&token=52f0a59f-4626-48f8-a77b-0111c79c1f69",
                        "fileExt": ".pdf",
                        "fileName": "144A.753.pdf"
                      },
                      {
                        "name": "144A.754",
                        "type": "unknown",
                        "id": "03934426-e1b8-48ba-addb-a67207127588",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F03934426-e1b8-48ba-addb-a67207127588.pdf?alt=media&token=b985bbe4-f4b1-4dd3-8e66-14b58ef51ee8",
                        "fileExt": ".pdf",
                        "fileName": "144A.754.pdf"
                      },
                      {
                        "name": "144A.755",
                        "type": "unknown",
                        "id": "9c40a660-57f0-484b-8339-58b4d6cf1526",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9c40a660-57f0-484b-8339-58b4d6cf1526.pdf?alt=media&token=980fdba1-be26-4c78-a78f-dffd0cfd7d60",
                        "fileExt": ".pdf",
                        "fileName": "144A.755.pdf"
                      },
                      {
                        "name": "144A.756",
                        "type": "unknown",
                        "id": "5245cd6d-d7c9-40a3-892c-f4775e958ff9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5245cd6d-d7c9-40a3-892c-f4775e958ff9.pdf?alt=media&token=3bf31d42-deb4-457e-9279-7396ed7b02f9",
                        "fileExt": ".pdf",
                        "fileName": "144A.756.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Nursing Assistants",
                    type: "dir", open: false,
                    "id": "2897e2af-05c0-45a4-bfbd-531863ec9a81",
                    "path": "Regulations/MN/MN 144A/Nursing Assistants",
                    data: [
                      {
                        "name": "144A.61",
                        "type": "unknown",
                        "id": "63054662-38ea-41bc-83e7-891988e5e43c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F63054662-38ea-41bc-83e7-891988e5e43c.pdf?alt=media&token=f918e543-bc67-4ae1-99be-e33dc94c7598",
                        "fileExt": ".pdf",
                        "fileName": "144A.61.pdf"
                      },
                      {
                        "name": "144A.611",
                        "type": "unknown",
                        "id": "f7ebced1-a8f9-4f58-9d12-4a7d1b0ea716",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff7ebced1-a8f9-4f58-9d12-4a7d1b0ea716.pdf?alt=media&token=7f336535-779d-48e5-93d4-207d83fa7d41",
                        "fileExt": ".pdf",
                        "fileName": "144A.611.pdf"
                      },
                      {
                        "name": "144A.62",
                        "type": "unknown",
                        "id": "01f3da6b-a978-457a-9bfb-4b5aef0101b4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F01f3da6b-a978-457a-9bfb-4b5aef0101b4.pdf?alt=media&token=b13fb00f-d7cd-4d34-b11d-6995131003c4",
                        "fileExt": ".pdf",
                        "fileName": "144A.62.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Nursing Homes and Home Care",
                    type: "dir", open: false,
                    "id": "c5161f8a-c9a2-4c16-a24a-3caa5a48de9f",
                    "path": "Regulations/MN/MN 144A/Nursing Homes and Home Care",
                    data: [
                      {
                        "name": "144A.01",
                        "type": "unknown",
                        "id": "e5c560a0-8d5e-4ca9-a04c-a005dedb8ee5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe5c560a0-8d5e-4ca9-a04c-a005dedb8ee5.pdf?alt=media&token=1258e192-38f3-4aac-be18-6c9750b4a1b4",
                        "fileExt": ".pdf",
                        "fileName": "144A.01.pdf"
                      },
                      {
                        "name": "144A.02",
                        "type": "unknown",
                        "id": "a1303577-c6fa-4933-bd5e-14c98e9a4f8a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa1303577-c6fa-4933-bd5e-14c98e9a4f8a.pdf?alt=media&token=47ee2c9c-7b71-4253-b196-dcc349313a87",
                        "fileExt": ".pdf",
                        "fileName": "144A.02.pdf"
                      },
                      {
                        "name": "144A.03",
                        "type": "unknown",
                        "id": "dd434f84-8d95-40a4-afa6-b2d8d56f7228",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdd434f84-8d95-40a4-afa6-b2d8d56f7228.pdf?alt=media&token=eda05e1d-0958-4b2b-aac2-b6b5b8b3cb69",
                        "fileExt": ".pdf",
                        "fileName": "144A.03.pdf"
                      },
                      {
                        "name": "144A.031",
                        "type": "unknown",
                        "id": "7db671a9-1d58-4a8e-943e-5ac01f16a5fb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7db671a9-1d58-4a8e-943e-5ac01f16a5fb.pdf?alt=media&token=a706b7c3-602b-48be-8d95-58aa0b9d5315",
                        "fileExt": ".pdf",
                        "fileName": "144A.031.pdf"
                      },
                      {
                        "name": "144A.04",
                        "type": "unknown",
                        "id": "d1b8d972-bde0-4de3-8508-71f6dc9bfe73",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd1b8d972-bde0-4de3-8508-71f6dc9bfe73.pdf?alt=media&token=483a716c-fbfe-4ff7-9050-5459acae96c7",
                        "fileExt": ".pdf",
                        "fileName": "144A.04.pdf"
                      },
                      {
                        "name": "144A.05",
                        "type": "unknown",
                        "id": "a4cd3d77-1786-42b8-a8b6-58889f40eb2b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa4cd3d77-1786-42b8-a8b6-58889f40eb2b.pdf?alt=media&token=2cba3b38-b62b-4627-9b55-b6ba283ea029",
                        "fileExt": ".pdf",
                        "fileName": "144A.05.pdf"
                      },
                      {
                        "name": "144A.06",
                        "type": "unknown",
                        "id": "7382dbe8-ffa5-459e-8563-b1166870d1d3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7382dbe8-ffa5-459e-8563-b1166870d1d3.pdf?alt=media&token=765cb39f-fb16-4af9-8c79-79a44270aa1b",
                        "fileExt": ".pdf",
                        "fileName": "144A.06.pdf"
                      },
                      {
                        "name": "144A.07",
                        "type": "unknown",
                        "id": "3993c26e-b4ed-4fcf-b4e0-0a3e9cd87ac1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3993c26e-b4ed-4fcf-b4e0-0a3e9cd87ac1.pdf?alt=media&token=13a40ec7-ec93-4284-a678-99258f62ad27",
                        "fileExt": ".pdf",
                        "fileName": "144A.07.pdf"
                      },
                      {
                        "name": "144A.071",
                        "type": "unknown",
                        "id": "76232752-2790-4297-b8de-b9107fc72855",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F76232752-2790-4297-b8de-b9107fc72855.pdf?alt=media&token=0e5ffa6e-7287-40aa-acd1-249f8845e635",
                        "fileExt": ".pdf",
                        "fileName": "144A.071.pdf"
                      },
                      {
                        "name": "144A.073",
                        "type": "unknown",
                        "id": "80b6b0ea-2804-42fa-b95f-8b0ec8c09c83",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F80b6b0ea-2804-42fa-b95f-8b0ec8c09c83.pdf?alt=media&token=b4f4b83e-78dc-4a65-8e46-89cdebc7eafc",
                        "fileExt": ".pdf",
                        "fileName": "144A.073.pdf"
                      },
                      {
                        "name": "144A.08",
                        "type": "unknown",
                        "id": "2608694f-bbf6-406f-a6a8-011fe72eabf2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2608694f-bbf6-406f-a6a8-011fe72eabf2.pdf?alt=media&token=06757ebf-a0d0-4df3-b266-e2030bbe08d0",
                        "fileExt": ".pdf",
                        "fileName": "144A.08.pdf"
                      },
                      {
                        "name": "144A.09",
                        "type": "unknown",
                        "id": "8eaae42a-5807-4e2d-87c8-73dd8bd9fb42",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8eaae42a-5807-4e2d-87c8-73dd8bd9fb42.pdf?alt=media&token=1e9320f5-f687-446e-b549-d98be542a882",
                        "fileExt": ".pdf",
                        "fileName": "144A.09.pdf"
                      },
                      {
                        "name": "144A.10",
                        "type": "unknown",
                        "id": "0c851c32-5650-4924-ba79-0bbd0cdaef4d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0c851c32-5650-4924-ba79-0bbd0cdaef4d.pdf?alt=media&token=14966353-ee56-4920-9d83-ccd5ee85b5d6",
                        "fileExt": ".pdf",
                        "fileName": "144A.10.pdf"
                      },
                      {
                        "name": "144A.101",
                        "type": "unknown",
                        "id": "f8a36fec-7518-45f3-92de-47ab484e056a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff8a36fec-7518-45f3-92de-47ab484e056a.pdf?alt=media&token=0b33cce5-0dc6-4fba-87c5-c93c58aec894",
                        "fileExt": ".pdf",
                        "fileName": "144A.101.pdf"
                      },
                      {
                        "name": "144A.102",
                        "type": "unknown",
                        "id": "ef3ca07a-f310-4f3a-9314-4eb9e3d4e310",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fef3ca07a-f310-4f3a-9314-4eb9e3d4e310.pdf?alt=media&token=b97d7653-dea6-47a3-beb4-3dd891657cfa",
                        "fileExt": ".pdf",
                        "fileName": "144A.102.pdf"
                      },
                      {
                        "name": "144A.105",
                        "type": "unknown",
                        "id": "e8b49296-b756-4687-b28a-b7212e2d8cb0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe8b49296-b756-4687-b28a-b7212e2d8cb0.pdf?alt=media&token=b4fa279d-00f7-4474-a14f-6e8b8a799b11",
                        "fileExt": ".pdf",
                        "fileName": "144A.105.pdf"
                      },
                      {
                        "name": "144A.11",
                        "type": "unknown",
                        "id": "1917a70b-42e3-452e-bf59-00909730f7fe",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1917a70b-42e3-452e-bf59-00909730f7fe.pdf?alt=media&token=431a9341-1691-486b-8e16-edf551b9c810",
                        "fileExt": ".pdf",
                        "fileName": "144A.11.pdf"
                      },
                      {
                        "name": "144A.115",
                        "type": "unknown",
                        "id": "43cd0c36-10e4-4e06-b463-da02caedd2e3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F43cd0c36-10e4-4e06-b463-da02caedd2e3.pdf?alt=media&token=619ddd88-4595-4cf2-8474-03984236c4b0",
                        "fileExt": ".pdf",
                        "fileName": "144A.115.pdf"
                      },
                      {
                        "name": "144A.12",
                        "type": "unknown",
                        "id": "42c666c9-79f0-485c-82fb-9c9b78fe283c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F42c666c9-79f0-485c-82fb-9c9b78fe283c.pdf?alt=media&token=9556fdd0-1199-4d49-b00f-128749427ffb",
                        "fileExt": ".pdf",
                        "fileName": "144A.12.pdf"
                      },
                      {
                        "name": "144A.13",
                        "type": "unknown",
                        "id": "7a298387-bd60-4637-8ae8-2c5085b60a53",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7a298387-bd60-4637-8ae8-2c5085b60a53.pdf?alt=media&token=4dfd8863-a98c-4600-bbef-1a59a169985f",
                        "fileExt": ".pdf",
                        "fileName": "144A.13.pdf"
                      },
                      {
                        "name": "144A.135",
                        "type": "unknown",
                        "id": "a398c0fb-49b2-46b7-a72f-8ca4ec3f717a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa398c0fb-49b2-46b7-a72f-8ca4ec3f717a.pdf?alt=media&token=7eea74fb-2107-42dc-9b64-5764e47d629d",
                        "fileExt": ".pdf",
                        "fileName": "144A.135.pdf"
                      },
                      {
                        "name": "144A.15",
                        "type": "unknown",
                        "id": "80c47bb0-82d3-4c7b-9a99-6b909ddaece9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F80c47bb0-82d3-4c7b-9a99-6b909ddaece9.pdf?alt=media&token=43abd0af-af58-4628-95fc-cc6db98e0433",
                        "fileExt": ".pdf",
                        "fileName": "144A.15.pdf"
                      },
                      {
                        "name": "144A.154",
                        "type": "unknown",
                        "id": "4f55901a-fb01-4f78-bba9-a6ff73ea6ff7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4f55901a-fb01-4f78-bba9-a6ff73ea6ff7.pdf?alt=media&token=3f371727-b086-444d-a203-1bd9f048ae8a",
                        "fileExt": ".pdf",
                        "fileName": "144A.154.pdf"
                      },
                      {
                        "name": "144A.155",
                        "type": "unknown",
                        "id": "8fed1ff8-47cf-4871-8e47-49be5172cf40",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8fed1ff8-47cf-4871-8e47-49be5172cf40.pdf?alt=media&token=e1a71b0e-8fe5-4a7d-a251-af3fa3878c5d",
                        "fileExt": ".pdf",
                        "fileName": "144A.155.pdf"
                      },
                      {
                        "name": "144A.161",
                        "type": "unknown",
                        "id": "38812e90-7909-453b-b810-3d319fd418c1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F38812e90-7909-453b-b810-3d319fd418c1.pdf?alt=media&token=97fa0b3b-9f26-4ef7-a201-f0c93d09ed54",
                        "fileExt": ".pdf",
                        "fileName": "144A.161.pdf"
                      },
                      {
                        "name": "144A.162",
                        "type": "unknown",
                        "id": "bbdd30dc-bdb2-494f-92f1-eca0ba32848e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbbdd30dc-bdb2-494f-92f1-eca0ba32848e.pdf?alt=media&token=84d8f801-11b9-4044-9f64-0e22ea659ddf",
                        "fileExt": ".pdf",
                        "fileName": "144A.162.pdf"
                      },
                      {
                        "name": "144A.18",
                        "type": "unknown",
                        "id": "140bdc2c-a99d-489c-89b5-bd555d33b0e7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F140bdc2c-a99d-489c-89b5-bd555d33b0e7.pdf?alt=media&token=3f1c9bb2-41b1-45b5-9914-b1281bfc4fc7",
                        "fileExt": ".pdf",
                        "fileName": "144A.18.pdf"
                      },
                      {
                        "name": "144A.1888",
                        "type": "unknown",
                        "id": "ac63eecb-1fe5-4703-ba9b-9c3b3ccef027",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fac63eecb-1fe5-4703-ba9b-9c3b3ccef027.pdf?alt=media&token=2c405e38-5371-482f-a7f2-f10b158f8ff8",
                        "fileExt": ".pdf",
                        "fileName": "144A.1888.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Nursing Homes; Long Term Care Services Report",
                    type: "dir", open: false,
                    "id": "6d0b3254-63f6-47e2-a429-fb88e7f8ce38",
                    "path": "Regulations/MN/MN 144A/Nursing Homes; Long Term Care Services Report",
                    data: [
                      {
                        "name": "144A.30",
                        "type": "unknown",
                        "id": "2412c204-0ec2-4e72-8102-665646450702",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2412c204-0ec2-4e72-8102-665646450702.pdf?alt=media&token=40b8dbfd-3264-4bd2-91b4-0d14676d825e",
                        "fileExt": ".pdf",
                        "fileName": "144A.30.pdf"
                      },
                      {
                        "name": "144A.33",
                        "type": "unknown",
                        "id": "15ea08ec-726c-4f2f-b749-cc19211b1978",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F15ea08ec-726c-4f2f-b749-cc19211b1978.pdf?alt=media&token=b87462a6-6421-4585-a143-d355c31458aa",
                        "fileExt": ".pdf",
                        "fileName": "144A.33.pdf"
                      },
                      {
                        "name": "144A.351",
                        "type": "unknown",
                        "id": "160f1614-966a-4a31-93fd-9ff40e0df486",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F160f1614-966a-4a31-93fd-9ff40e0df486.pdf?alt=media&token=28e4235b-8e3d-4b40-969a-d6da9cb39d59",
                        "fileExt": ".pdf",
                        "fileName": "144A.351.pdf"
                      },
                      {
                        "name": "144A.37",
                        "type": "unknown",
                        "id": "88bc3a57-54a4-494c-9ebb-04be4360bb5d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F88bc3a57-54a4-494c-9ebb-04be4360bb5d.pdf?alt=media&token=44b6093b-e8e0-4b13-942b-3f7147a0053a",
                        "fileExt": ".pdf",
                        "fileName": "144A.37.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Supplemental Nursing Services Agency",
                    type: "dir", open: false,
                    "id": "a045eaa4-2ba5-4311-8d37-fe8a8767e32f",
                    "path": "Regulations/MN/MN 144A/Supplemental Nursing Services Agency",
                    data: [
                      {
                        "name": "144A.70",
                        "type": "unknown",
                        "id": "db28947b-f893-4707-96f7-e15b248ae8a1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdb28947b-f893-4707-96f7-e15b248ae8a1.pdf?alt=media&token=47022e33-f4dc-4642-aaf7-29cfa30d69fc",
                        "fileExt": ".pdf",
                        "fileName": "144A.70.pdf"
                      },
                      {
                        "name": "144A.71",
                        "type": "unknown",
                        "id": "9782cb5f-d31f-4400-b481-205f36c98c0e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9782cb5f-d31f-4400-b481-205f36c98c0e.pdf?alt=media&token=01ec0c95-5be6-434c-83f0-4c3a6e475da7",
                        "fileExt": ".pdf",
                        "fileName": "144A.71.pdf"
                      },
                      {
                        "name": "144A.72",
                        "type": "unknown",
                        "id": "94a5200c-0cf3-4e60-8925-feb07bba0be3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F94a5200c-0cf3-4e60-8925-feb07bba0be3.pdf?alt=media&token=6615deed-2c39-478b-83bd-949a2ac42c4d",
                        "fileExt": ".pdf",
                        "fileName": "144A.72.pdf"
                      },
                      {
                        "name": "144A.73",
                        "type": "unknown",
                        "id": "7db5756d-978f-4a29-9d61-443882c8d962",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7db5756d-978f-4a29-9d61-443882c8d962.pdf?alt=media&token=abe90c72-af37-4429-abe4-5fb597b30dec",
                        "fileExt": ".pdf",
                        "fileName": "144A.73.pdf"
                      },
                      {
                        "name": "144A.74",
                        "type": "unknown",
                        "id": "3520c19a-6a9a-4b79-8a4c-64ab77edc90d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3520c19a-6a9a-4b79-8a4c-64ab77edc90d.pdf?alt=media&token=50333c8f-11ec-4266-8f19-8b25860d5d9d",
                        "fileExt": ".pdf",
                        "fileName": "144A.74.pdf"
                      }
                    ]
                  }
                ]
              },
              {
                "name": "MN Chapter 4658",
                type: "dir", open: false,
                "id": "95d40734-7b13-4bdd-bdfe-5aa2f46dd16e",
                "path": "Regulations/MN/MN Chapter 4658",
                data: [
                  {
                    "name": "Administration and Operations",
                    type: "dir", open: false,
                    "id": "9953894a-891d-4376-9655-59cdd53e5c20",
                    "path": "Regulations/MN/MN Chapter 4658/Administration and Operations",
                    data: [
                      {
                        "name": "4658.0050",
                        "type": "unknown",
                        "id": "3e6ec3e5-86dc-482d-9916-0c0d5abe3505",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3e6ec3e5-86dc-482d-9916-0c0d5abe3505.pdf?alt=media&token=caf1a04c-1229-41ad-8146-19d777597a1f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0050.pdf"
                      },
                      {
                        "name": "4658.0055",
                        "type": "unknown",
                        "id": "1637c3bc-5fdb-4b75-9597-ee21f18eaf26",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1637c3bc-5fdb-4b75-9597-ee21f18eaf26.pdf?alt=media&token=5c5a7c58-6eb1-488b-b699-38f6722d804c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0055.pdf"
                      },
                      {
                        "name": "4658.0060",
                        "type": "unknown",
                        "id": "5bd29b6c-8b94-4103-9bf0-13f394ff9a52",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5bd29b6c-8b94-4103-9bf0-13f394ff9a52.pdf?alt=media&token=fe10223c-236b-4961-81d4-208b2a8d7808",
                        "fileExt": ".pdf",
                        "fileName": "4658.0060.pdf"
                      },
                      {
                        "name": "4658.0065",
                        "type": "unknown",
                        "id": "9e551356-8f37-46ce-a2be-68bc4f00159d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9e551356-8f37-46ce-a2be-68bc4f00159d.pdf?alt=media&token=be597583-93fe-4d5c-af1c-f9fd5161a152",
                        "fileExt": ".pdf",
                        "fileName": "4658.0065.pdf"
                      },
                      {
                        "name": "4658.0070",
                        "type": "unknown",
                        "id": "8325589c-b2a8-4efc-9706-5efc11efa16d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8325589c-b2a8-4efc-9706-5efc11efa16d.pdf?alt=media&token=5414a3f3-541a-4983-b0d5-a98b03754283",
                        "fileExt": ".pdf",
                        "fileName": "4658.0070.pdf"
                      },
                      {
                        "name": "4658.0075",
                        "type": "unknown",
                        "id": "636d596c-9912-4b20-997f-010f1d54beff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F636d596c-9912-4b20-997f-010f1d54beff.pdf?alt=media&token=f83b82f4-4c53-4183-8254-e64bbfd00c32",
                        "fileExt": ".pdf",
                        "fileName": "4658.0075.pdf"
                      },
                      {
                        "name": "4658.0085",
                        "type": "unknown",
                        "id": "39fb0cc7-ae9b-43b3-859b-ffec684614a8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F39fb0cc7-ae9b-43b3-859b-ffec684614a8.pdf?alt=media&token=959e8871-2f16-45fa-8b00-f808961a8430",
                        "fileExt": ".pdf",
                        "fileName": "4658.0085.pdf"
                      },
                      {
                        "name": "4658.0090",
                        "type": "unknown",
                        "id": "59578681-9dd9-43c7-aec6-b4083b33ebc3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F59578681-9dd9-43c7-aec6-b4083b33ebc3.pdf?alt=media&token=ff7f8173-e7d3-4aa0-b1bb-7874136322de",
                        "fileExt": ".pdf",
                        "fileName": "4658.0090.pdf"
                      },
                      {
                        "name": "4658.0095",
                        "type": "unknown",
                        "id": "23dd376b-1acf-450c-80ea-67d25f15a27a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F23dd376b-1acf-450c-80ea-67d25f15a27a.pdf?alt=media&token=ac0671cd-181f-45c4-9b07-835ea45af817",
                        "fileExt": ".pdf",
                        "fileName": "4658.0095.pdf"
                      },
                      {
                        "name": "4658.0100",
                        "type": "unknown",
                        "id": "c86d785c-9e10-4de9-b4f8-b405e6f81144",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc86d785c-9e10-4de9-b4f8-b405e6f81144.pdf?alt=media&token=2fd412d3-0ee9-458f-83af-f9c4f5c59d93",
                        "fileExt": ".pdf",
                        "fileName": "4658.0100.pdf"
                      },
                      {
                        "name": "4658.0105",
                        "type": "unknown",
                        "id": "d3e2ea43-a0f3-4c0b-8753-f0f55b4b11eb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd3e2ea43-a0f3-4c0b-8753-f0f55b4b11eb.pdf?alt=media&token=43fff65f-9478-4b52-a1ba-279e9e9a2662",
                        "fileExt": ".pdf",
                        "fileName": "4658.0105.pdf"
                      },
                      {
                        "name": "4658.0110",
                        "type": "unknown",
                        "id": "7040e17f-2c98-43cd-bd5f-2a2fbd099f78",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7040e17f-2c98-43cd-bd5f-2a2fbd099f78.pdf?alt=media&token=defa85f5-381f-4870-822d-9f60c0a05db3",
                        "fileExt": ".pdf",
                        "fileName": "4658.0110.pdf"
                      },
                      {
                        "name": "4658.0115",
                        "type": "unknown",
                        "id": "9e66808c-6f1a-4083-8f41-1585c4794ea2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9e66808c-6f1a-4083-8f41-1585c4794ea2.pdf?alt=media&token=82bb13cc-c357-484e-b51d-b6a6ec5d6cde",
                        "fileExt": ".pdf",
                        "fileName": "4658.0115.pdf"
                      },
                      {
                        "name": "4658.0120",
                        "type": "unknown",
                        "id": "1f7ee865-de93-4bde-9902-8b1d2dc3a48d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1f7ee865-de93-4bde-9902-8b1d2dc3a48d.pdf?alt=media&token=bb059ba8-01c0-4d9f-b01d-ed06a9acffcb",
                        "fileExt": ".pdf",
                        "fileName": "4658.0120.pdf"
                      },
                      {
                        "name": "4658.0125",
                        "type": "unknown",
                        "id": "00b3e7c5-9dc0-4f43-9c3f-7456da49b8f8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F00b3e7c5-9dc0-4f43-9c3f-7456da49b8f8.pdf?alt=media&token=92348d9a-cc50-4f9d-a9e1-5820a47f8d1f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0125.pdf"
                      },
                      {
                        "name": "4658.0130",
                        "type": "unknown",
                        "id": "20730155-29e4-4ae0-84f8-58f48e133fe1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F20730155-29e4-4ae0-84f8-58f48e133fe1.pdf?alt=media&token=d8440db5-6515-4a0c-8f1e-4ee7aa608454",
                        "fileExt": ".pdf",
                        "fileName": "4658.0130.pdf"
                      },
                      {
                        "name": "4658.0135",
                        "type": "unknown",
                        "id": "ca36288f-8669-459b-b53b-8bb1556b9ab3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fca36288f-8669-459b-b53b-8bb1556b9ab3.pdf?alt=media&token=593a3bdc-1fb8-4db8-baee-a587db5d5b37",
                        "fileExt": ".pdf",
                        "fileName": "4658.0135.pdf"
                      },
                      {
                        "name": "4658.0140",
                        "type": "unknown",
                        "id": "c2b8fde6-8fe1-4582-b3b0-68315591ec08",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc2b8fde6-8fe1-4582-b3b0-68315591ec08.pdf?alt=media&token=7ee75f52-b28e-4d4b-a522-ea0fea37b746",
                        "fileExt": ".pdf",
                        "fileName": "4658.0140.pdf"
                      },
                      {
                        "name": "4658.0145",
                        "type": "unknown",
                        "id": "17f1f604-f104-4b7e-b7a8-5af6f7afb410",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F17f1f604-f104-4b7e-b7a8-5af6f7afb410.pdf?alt=media&token=8ddfe153-68cb-4c30-adc1-91fee0df48fa",
                        "fileExt": ".pdf",
                        "fileName": "4658.0145.pdf"
                      },
                      {
                        "name": "4658.0150",
                        "type": "unknown",
                        "id": "63de52fa-a213-4da3-9ac4-49dd97ab5582",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F63de52fa-a213-4da3-9ac4-49dd97ab5582.pdf?alt=media&token=3091a87c-efa6-4d4e-bda3-0bd265a6aa41",
                        "fileExt": ".pdf",
                        "fileName": "4658.0150.pdf"
                      },
                      {
                        "name": "4658.0155",
                        "type": "unknown",
                        "id": "4fb3f37a-d83e-4212-b73b-b917bfe770e2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4fb3f37a-d83e-4212-b73b-b917bfe770e2.pdf?alt=media&token=8229a3be-9563-476c-a900-782f56cd03d2",
                        "fileExt": ".pdf",
                        "fileName": "4658.0155.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Barber and Beauty Shop Services",
                    type: "dir", open: false,
                    "id": "22b7c6e3-f8e3-439b-af20-ea30c9d93908",
                    "path": "Regulations/MN/MN Chapter 4658/Barber and Beauty Shop Services",
                    data: [
                      {
                        "name": "4658.1100",
                        "type": "unknown",
                        "id": "018646bd-1868-4dca-b565-90e464b33f49",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F018646bd-1868-4dca-b565-90e464b33f49.pdf?alt=media&token=463f7c1a-7946-493b-aa56-1c5b03fe2c36",
                        "fileExt": ".pdf",
                        "fileName": "4658.1100.pdf"
                      },
                      {
                        "name": "4658.1190",
                        "type": "unknown",
                        "id": "f9860250-2ecb-4a14-ba1f-2e81f99a44a1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff9860250-2ecb-4a14-ba1f-2e81f99a44a1.pdf?alt=media&token=f2afaa9b-ff45-4367-b385-8d5bb3481810",
                        "fileExt": ".pdf",
                        "fileName": "4658.1190.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Clinical Records",
                    type: "dir", open: false,
                    "id": "29fe97ab-f394-4aad-bf30-69f43dc6e51f",
                    "path": "Regulations/MN/MN Chapter 4658/Clinical Records",
                    data: [
                      {
                        "name": "4658.0430",
                        "type": "unknown",
                        "id": "cd18a307-a9e4-4249-b889-6bf4b671d12f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcd18a307-a9e4-4249-b889-6bf4b671d12f.pdf?alt=media&token=5b0815a7-3f91-42b0-a02c-861c2e5c75e7",
                        "fileExt": ".pdf",
                        "fileName": "4658.0430.pdf"
                      },
                      {
                        "name": "4658.0435",
                        "type": "unknown",
                        "id": "edb30bcb-1366-42c0-a42b-e2c3c4c39405",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fedb30bcb-1366-42c0-a42b-e2c3c4c39405.pdf?alt=media&token=baa00893-e4f3-409e-b313-74a07e1c68ad",
                        "fileExt": ".pdf",
                        "fileName": "4658.0435.pdf"
                      },
                      {
                        "name": "4658.0440",
                        "type": "unknown",
                        "id": "d69f787f-0798-4b97-86ee-1b12b20cc820",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd69f787f-0798-4b97-86ee-1b12b20cc820.pdf?alt=media&token=aa81d24b-a6f4-4088-b55d-92f8c3b47319",
                        "fileExt": ".pdf",
                        "fileName": "4658.0440.pdf"
                      },
                      {
                        "name": "4658.0445",
                        "type": "unknown",
                        "id": "fc8303ed-3dd6-4102-88d9-8327531d85e9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffc8303ed-3dd6-4102-88d9-8327531d85e9.pdf?alt=media&token=b8ada3e2-e649-4c6f-b8eb-d6e7f69d0d0e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0445.pdf"
                      },
                      {
                        "name": "4658.0450",
                        "type": "unknown",
                        "id": "9e6f1e4a-6032-4246-9ab0-7986b127d57b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9e6f1e4a-6032-4246-9ab0-7986b127d57b.pdf?alt=media&token=9f771d57-f902-4e08-a770-e7f087a63c9d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0450.pdf"
                      },
                      {
                        "name": "4658.0455",
                        "type": "unknown",
                        "id": "89b081b8-7ecb-4e1e-9669-37d7886210fe",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F89b081b8-7ecb-4e1e-9669-37d7886210fe.pdf?alt=media&token=ca42bcd9-9cca-4efd-9e25-a1126869448d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0455.pdf"
                      },
                      {
                        "name": "4658.0460",
                        "type": "unknown",
                        "id": "94109d29-8502-42a6-bd64-0f49541c6a3a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F94109d29-8502-42a6-bd64-0f49541c6a3a.pdf?alt=media&token=3c00857c-547a-4e51-b1aa-c77aecb899c5",
                        "fileExt": ".pdf",
                        "fileName": "4658.0460.pdf"
                      },
                      {
                        "name": "4658.0465",
                        "type": "unknown",
                        "id": "05fa2f45-1255-4324-8c54-577808f7a912",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F05fa2f45-1255-4324-8c54-577808f7a912.pdf?alt=media&token=2a1b7005-19e3-4a1d-a88e-2f96d7e132d2",
                        "fileExt": ".pdf",
                        "fileName": "4658.0465.pdf"
                      },
                      {
                        "name": "4658.0470",
                        "type": "unknown",
                        "id": "d397ea20-d0a7-4863-b788-1a9d4b843b02",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd397ea20-d0a7-4863-b788-1a9d4b843b02.pdf?alt=media&token=2b6ae512-9378-4300-aeb4-6663923b516e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0470.pdf"
                      },
                      {
                        "name": "4658.0475",
                        "type": "unknown",
                        "id": "fd0e2fd9-08c5-4240-8f32-419b34b01bf3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffd0e2fd9-08c5-4240-8f32-419b34b01bf3.pdf?alt=media&token=511826b9-2ca2-48c9-b0e4-0cb20fa16222",
                        "fileExt": ".pdf",
                        "fileName": "4658.0475.pdf"
                      },
                      {
                        "name": "4658.0490",
                        "type": "unknown",
                        "id": "16b15391-5459-4525-9210-896c60aed422",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F16b15391-5459-4525-9210-896c60aed422.pdf?alt=media&token=0216c46f-dbd4-4379-82af-b48f86eab66d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0490.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Construction Details",
                    type: "dir", open: false,
                    "id": "d4c92e08-9eb6-44bf-9f85-71745193d78f",
                    "path": "Regulations/MN/MN Chapter 4658/Construction Details",
                    data: [
                      {
                        "name": "4658.4400",
                        "type": "unknown",
                        "id": "4f171fbd-7823-49ee-944d-9852b3ebf605",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4f171fbd-7823-49ee-944d-9852b3ebf605.pdf?alt=media&token=d3b7d5a5-a705-420b-a840-cd5edeaecdb7",
                        "fileExt": ".pdf",
                        "fileName": "4658.4400.pdf"
                      },
                      {
                        "name": "4658.4405",
                        "type": "unknown",
                        "id": "3f2dc3dd-6b86-4f32-9c1a-103a8628a874",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3f2dc3dd-6b86-4f32-9c1a-103a8628a874.pdf?alt=media&token=4fde3f40-bdc4-43f3-93fe-482dddf8d7f6",
                        "fileExt": ".pdf",
                        "fileName": "4658.4405.pdf"
                      },
                      {
                        "name": "4658.4410",
                        "type": "unknown",
                        "id": "84e5e9a1-b57a-4a85-ab4f-282ee397172a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F84e5e9a1-b57a-4a85-ab4f-282ee397172a.pdf?alt=media&token=9c1f16bd-a63c-4664-ad04-2851d9f6e594",
                        "fileExt": ".pdf",
                        "fileName": "4658.4410.pdf"
                      },
                      {
                        "name": "4658.4415",
                        "type": "unknown",
                        "id": "71344725-3f91-469f-a75c-c6b98e22b25e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F71344725-3f91-469f-a75c-c6b98e22b25e.pdf?alt=media&token=74e809fe-3b66-4167-a628-9eb251edf424",
                        "fileExt": ".pdf",
                        "fileName": "4658.4415.pdf"
                      },
                      {
                        "name": "4658.4420",
                        "type": "unknown",
                        "id": "e0104cb8-6940-40f1-a772-2f5e44c05532",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe0104cb8-6940-40f1-a772-2f5e44c05532.pdf?alt=media&token=291149c7-672a-4e2a-8533-476814a3e990",
                        "fileExt": ".pdf",
                        "fileName": "4658.4420.pdf"
                      },
                      {
                        "name": "4658.4425",
                        "type": "unknown",
                        "id": "75b1e612-748f-4ab0-8062-7cdccccd78fa",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F75b1e612-748f-4ab0-8062-7cdccccd78fa.pdf?alt=media&token=664c482b-0d17-4ab3-9806-af169914f039",
                        "fileExt": ".pdf",
                        "fileName": "4658.4425.pdf"
                      },
                      {
                        "name": "4658.4430",
                        "type": "unknown",
                        "id": "21c2a105-9821-482b-8bd0-c19f8df2e66b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F21c2a105-9821-482b-8bd0-c19f8df2e66b.pdf?alt=media&token=3e6a0a02-d5e4-46b1-bf44-678525194b3b",
                        "fileExt": ".pdf",
                        "fileName": "4658.4430.pdf"
                      },
                      {
                        "name": "4658.4435",
                        "type": "unknown",
                        "id": "47c71a56-8fc5-41ec-969a-9acd3a84688c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F47c71a56-8fc5-41ec-969a-9acd3a84688c.pdf?alt=media&token=6ec90d9d-6ed6-44bb-8058-91c48233713d",
                        "fileExt": ".pdf",
                        "fileName": "4658.4435.pdf"
                      },
                      {
                        "name": "4658.4440",
                        "type": "unknown",
                        "id": "a5483225-6911-4cb4-91a3-d6bebf1e1523",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa5483225-6911-4cb4-91a3-d6bebf1e1523.pdf?alt=media&token=e0639ee4-6689-46aa-b46e-c47772a013fc",
                        "fileExt": ".pdf",
                        "fileName": "4658.4440.pdf"
                      },
                      {
                        "name": "4658.4445",
                        "type": "unknown",
                        "id": "9afbbbf5-bc7c-48a6-bdd4-a5c6972a55c3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9afbbbf5-bc7c-48a6-bdd4-a5c6972a55c3.pdf?alt=media&token=7273d6cf-3b8c-4859-887e-5e66959b73da",
                        "fileExt": ".pdf",
                        "fileName": "4658.4445.pdf"
                      },
                      {
                        "name": "4658.4450",
                        "type": "unknown",
                        "id": "31bb9bce-d64e-4d81-a965-77d41549f66b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F31bb9bce-d64e-4d81-a965-77d41549f66b.pdf?alt=media&token=36cf4f34-2954-4e99-b943-83326d3e9436",
                        "fileExt": ".pdf",
                        "fileName": "4658.4450.pdf"
                      },
                      {
                        "name": "4658.4455",
                        "type": "unknown",
                        "id": "7c8088f6-aa50-40cf-b668-b786d8a84737",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7c8088f6-aa50-40cf-b668-b786d8a84737.pdf?alt=media&token=37420f45-82b6-4a7b-b854-4d17977bf164",
                        "fileExt": ".pdf",
                        "fileName": "4658.4455.pdf"
                      },
                      {
                        "name": "4658.4460",
                        "type": "unknown",
                        "id": "69b7e262-d55a-43e9-8fcb-9d97b85afc4d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F69b7e262-d55a-43e9-8fcb-9d97b85afc4d.pdf?alt=media&token=d266e419-6be3-4d1f-8e91-3ff68534655f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4460.pdf"
                      },
                      {
                        "name": "4658.4490",
                        "type": "unknown",
                        "id": "da493600-2149-4db0-ada4-5490bea4b36d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fda493600-2149-4db0-ada4-5490bea4b36d.pdf?alt=media&token=f89d1640-0f24-47cf-be65-64173685bf63",
                        "fileExt": ".pdf",
                        "fileName": "4658.4490.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Construction Details - Existing Construction",
                    type: "dir", open: false,
                    "id": "708fac6d-155a-4dc7-82e5-98ea4fa3b67e",
                    "path": "Regulations/MN/MN Chapter 4658/Construction Details - Existing Construction",
                    data: [
                      {
                        "name": "4658.5300",
                        "type": "unknown",
                        "id": "e801a620-e873-4e9a-a6fd-72b5bb36ae63",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe801a620-e873-4e9a-a6fd-72b5bb36ae63.pdf?alt=media&token=d9183ac3-d3d6-4549-ade2-d8a8dadf487a",
                        "fileExt": ".pdf",
                        "fileName": "4658.5300.pdf"
                      },
                      {
                        "name": "4658.5305",
                        "type": "unknown",
                        "id": "109a19ff-e6f5-4313-a044-c818ecf40126",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F109a19ff-e6f5-4313-a044-c818ecf40126.pdf?alt=media&token=6f88527e-88c4-4f81-9e5e-070034f2e8f3",
                        "fileExt": ".pdf",
                        "fileName": "4658.5305.pdf"
                      },
                      {
                        "name": "4658.5310",
                        "type": "unknown",
                        "id": "13fcd2f3-bced-4398-8af3-e2c0fd9d997d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F13fcd2f3-bced-4398-8af3-e2c0fd9d997d.pdf?alt=media&token=ec4a8eba-1128-4cf9-b321-53024bd36e32",
                        "fileExt": ".pdf",
                        "fileName": "4658.5310.pdf"
                      },
                      {
                        "name": "4658.5315",
                        "type": "unknown",
                        "id": "003cd678-6faf-4857-9974-0dcf50989c6e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F003cd678-6faf-4857-9974-0dcf50989c6e.pdf?alt=media&token=d681a13e-d63b-499b-a598-11938802e7e8",
                        "fileExt": ".pdf",
                        "fileName": "4658.5315.pdf"
                      },
                      {
                        "name": "4658.5390",
                        "type": "unknown",
                        "id": "83cd7c30-73f4-4735-98a0-15bd806ffad4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F83cd7c30-73f4-4735-98a0-15bd806ffad4.pdf?alt=media&token=37c4f6a8-be66-4ef3-ae2c-a7ab19141be0",
                        "fileExt": ".pdf",
                        "fileName": "4658.5390.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Dietary Laundry and Other Facilities - Existing Construction",
                    type: "dir", open: false,
                    "id": "ebe77f18-e15f-4727-b477-0d24435a07f7",
                    "path": "Regulations/MN/MN Chapter 4658/Dietary Laundry and Other Facilities - Existing Construction",
                    data: [
                      {
                        "name": "4658.5200",
                        "type": "unknown",
                        "id": "01036e2f-0b50-43d0-979d-fc93a3beb455",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F01036e2f-0b50-43d0-979d-fc93a3beb455.pdf?alt=media&token=6d3002f9-a7f1-4d7f-9a49-597a56dc77fe",
                        "fileExt": ".pdf",
                        "fileName": "4658.5200.pdf"
                      },
                      {
                        "name": "4658.5205",
                        "type": "unknown",
                        "id": "5618ccbe-92d0-44a9-babc-198400c294e3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5618ccbe-92d0-44a9-babc-198400c294e3.pdf?alt=media&token=fa953e35-5e04-43df-b18b-44a136cd09bf",
                        "fileExt": ".pdf",
                        "fileName": "4658.5205.pdf"
                      },
                      {
                        "name": "4658.5210",
                        "type": "unknown",
                        "id": "00e96a65-7483-488a-869b-a80caffced0f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F00e96a65-7483-488a-869b-a80caffced0f.pdf?alt=media&token=25fc8278-028b-4807-a04d-a980d0f89734",
                        "fileExt": ".pdf",
                        "fileName": "4658.5210.pdf"
                      },
                      {
                        "name": "4658.5215",
                        "type": "unknown",
                        "id": "84f6ac32-f5df-4624-b95f-b01cadbd515a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F84f6ac32-f5df-4624-b95f-b01cadbd515a.pdf?alt=media&token=53d1b16e-edaf-43bb-b983-8f7da095ae89",
                        "fileExt": ".pdf",
                        "fileName": "4658.5215.pdf"
                      },
                      {
                        "name": "4658.5220",
                        "type": "unknown",
                        "id": "fa387103-2ff0-4546-80ff-7d7dc1bf2cf6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffa387103-2ff0-4546-80ff-7d7dc1bf2cf6.pdf?alt=media&token=de986a97-3418-4dc4-8aa7-e0008c26f4a5",
                        "fileExt": ".pdf",
                        "fileName": "4658.5220.pdf"
                      },
                      {
                        "name": "4658.5225",
                        "type": "unknown",
                        "id": "b36b5e70-0dc2-4ef2-97b9-679a36c9b270",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb36b5e70-0dc2-4ef2-97b9-679a36c9b270.pdf?alt=media&token=60d5a9db-7425-4365-858d-6c523549bedd",
                        "fileExt": ".pdf",
                        "fileName": "4658.5225.pdf"
                      },
                      {
                        "name": "4658.5230",
                        "type": "unknown",
                        "id": "5bcc228e-42d3-452f-b134-e4633ac66871",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5bcc228e-42d3-452f-b134-e4633ac66871.pdf?alt=media&token=ff347d3d-200b-4917-b764-22f07095eeb9",
                        "fileExt": ".pdf",
                        "fileName": "4658.5230.pdf"
                      },
                      {
                        "name": "4658.5235",
                        "type": "unknown",
                        "id": "64964a50-432c-44f3-8f25-f44aaacc6d9e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F64964a50-432c-44f3-8f25-f44aaacc6d9e.pdf?alt=media&token=09f81459-7d82-4ac6-a2a5-717be5573097",
                        "fileExt": ".pdf",
                        "fileName": "4658.5235.pdf"
                      },
                      {
                        "name": "4658.5240",
                        "type": "unknown",
                        "id": "cbff4615-9501-4969-858b-669a17216bab",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcbff4615-9501-4969-858b-669a17216bab.pdf?alt=media&token=38221d55-4872-47e7-8380-19ba153a5dda",
                        "fileExt": ".pdf",
                        "fileName": "4658.5240.pdf"
                      },
                      {
                        "name": "4658.5245",
                        "type": "unknown",
                        "id": "f76a1204-539e-4a8e-b8a4-2ee00de34364",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff76a1204-539e-4a8e-b8a4-2ee00de34364.pdf?alt=media&token=b7d53fe3-3384-4f7d-8034-89c19fa1addb",
                        "fileExt": ".pdf",
                        "fileName": "4658.5245.pdf"
                      },
                      {
                        "name": "4658.5290",
                        "type": "unknown",
                        "id": "621fd5a8-3800-4365-9c76-a6f4926b02b8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F621fd5a8-3800-4365-9c76-a6f4926b02b8.pdf?alt=media&token=77dcd36e-f410-48e3-b238-2c2283d87c96",
                        "fileExt": ".pdf",
                        "fileName": "4658.5290.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Dietary Laundry and Other Facilities - New Construction",
                    type: "dir", open: false,
                    "id": "c501b345-8760-41da-8d99-a15a0cf12503",
                    "path": "Regulations/MN/MN Chapter 4658/Dietary Laundry and Other Facilities - New Construction",
                    data: [
                      {
                        "name": "4658.4300",
                        "type": "unknown",
                        "id": "6ae53682-ca1c-483f-811d-2891d5e3a625",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6ae53682-ca1c-483f-811d-2891d5e3a625.pdf?alt=media&token=7662dd2e-52e1-4c61-8e17-5e6bc5d25687",
                        "fileExt": ".pdf",
                        "fileName": "4658.4300.pdf"
                      },
                      {
                        "name": "4658.4305",
                        "type": "unknown",
                        "id": "531d25c7-02b2-4bf8-b561-d84d4be1c52a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F531d25c7-02b2-4bf8-b561-d84d4be1c52a.pdf?alt=media&token=785abbb5-fad8-4ee9-902b-4adf5e17c727",
                        "fileExt": ".pdf",
                        "fileName": "4658.4305.pdf"
                      },
                      {
                        "name": "4658.4310",
                        "type": "unknown",
                        "id": "e9953645-071e-4fec-9bd2-83fa34209ef0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe9953645-071e-4fec-9bd2-83fa34209ef0.pdf?alt=media&token=0204fc8f-32d6-4fa8-a50e-4e7d03f2211a",
                        "fileExt": ".pdf",
                        "fileName": "4658.4310.pdf"
                      },
                      {
                        "name": "4658.4315",
                        "type": "unknown",
                        "id": "e1bf4f13-701a-4315-9fe2-3dd26e66389f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe1bf4f13-701a-4315-9fe2-3dd26e66389f.pdf?alt=media&token=8d1a1b62-7324-4b1a-84fb-ff0e5526888a",
                        "fileExt": ".pdf",
                        "fileName": "4658.4315.pdf"
                      },
                      {
                        "name": "4658.4320",
                        "type": "unknown",
                        "id": "64c192d7-8655-4c51-ae01-578c8287eb2d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F64c192d7-8655-4c51-ae01-578c8287eb2d.pdf?alt=media&token=7d4f6e1a-9ca2-4e48-b263-488a21be4b3e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4320.pdf"
                      },
                      {
                        "name": "4658.4325",
                        "type": "unknown",
                        "id": "8698d50d-550f-4535-afd1-fd8f1154fa80",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8698d50d-550f-4535-afd1-fd8f1154fa80.pdf?alt=media&token=45101964-4e94-49fd-b734-c786c40d31b9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4325.pdf"
                      },
                      {
                        "name": "4658.4330",
                        "type": "unknown",
                        "id": "c60cbc86-8c81-4f07-9ce1-4d426948000e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc60cbc86-8c81-4f07-9ce1-4d426948000e.pdf?alt=media&token=9a7626ed-59eb-40ac-87f7-983e9b441f03",
                        "fileExt": ".pdf",
                        "fileName": "4658.4330.pdf"
                      },
                      {
                        "name": "4658.4335",
                        "type": "unknown",
                        "id": "a63352b7-d0f7-430c-8ac9-2bd95365b8ad",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa63352b7-d0f7-430c-8ac9-2bd95365b8ad.pdf?alt=media&token=341ebf33-99e6-40dd-8222-a439be902655",
                        "fileExt": ".pdf",
                        "fileName": "4658.4335.pdf"
                      },
                      {
                        "name": "4658.4340",
                        "type": "unknown",
                        "id": "76e6f7f3-f78a-44ef-8154-4237ba77f12d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F76e6f7f3-f78a-44ef-8154-4237ba77f12d.pdf?alt=media&token=f4fc6698-fc4f-401e-8ab3-61b78b09415a",
                        "fileExt": ".pdf",
                        "fileName": "4658.4340.pdf"
                      },
                      {
                        "name": "4658.4345",
                        "type": "unknown",
                        "id": "0eab3612-0bd5-428a-9b97-485a7c3add49",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0eab3612-0bd5-428a-9b97-485a7c3add49.pdf?alt=media&token=8346870c-ab98-4a9b-ac61-c4c37be4d906",
                        "fileExt": ".pdf",
                        "fileName": "4658.4345.pdf"
                      },
                      {
                        "name": "4658.4350",
                        "type": "unknown",
                        "id": "411ab72d-887e-4a0c-960e-6343ff094cb3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F411ab72d-887e-4a0c-960e-6343ff094cb3.pdf?alt=media&token=a01661dd-f0b8-4f2f-b3f0-b1c96f81c8d5",
                        "fileExt": ".pdf",
                        "fileName": "4658.4350.pdf"
                      },
                      {
                        "name": "4658.4355",
                        "type": "unknown",
                        "id": "083959f5-7c8f-4af3-9ce5-dbace86fdb9c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F083959f5-7c8f-4af3-9ce5-dbace86fdb9c.pdf?alt=media&token=09ba7074-5e56-4594-91a4-51bc1915751d",
                        "fileExt": ".pdf",
                        "fileName": "4658.4355.pdf"
                      },
                      {
                        "name": "4658.4360",
                        "type": "unknown",
                        "id": "5f1654b9-26d8-4203-a405-af977f47a8a2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5f1654b9-26d8-4203-a405-af977f47a8a2.pdf?alt=media&token=abe807ac-0c6c-4966-bf6e-71a8fca606c4",
                        "fileExt": ".pdf",
                        "fileName": "4658.4360.pdf"
                      },
                      {
                        "name": "4658.4365",
                        "type": "unknown",
                        "id": "a999fe64-68b5-4aca-8783-e99ed78bff7b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa999fe64-68b5-4aca-8783-e99ed78bff7b.pdf?alt=media&token=2cf164d9-ebdc-4f72-a656-501ada1cbddb",
                        "fileExt": ".pdf",
                        "fileName": "4658.4365.pdf"
                      },
                      {
                        "name": "4658.4370",
                        "type": "unknown",
                        "id": "c4f0bcf0-142d-4223-8467-e40c15798d7a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc4f0bcf0-142d-4223-8467-e40c15798d7a.pdf?alt=media&token=d2b0e85e-353f-4aed-8b47-a7c06e4909a0",
                        "fileExt": ".pdf",
                        "fileName": "4658.4370.pdf"
                      },
                      {
                        "name": "4658.4375",
                        "type": "unknown",
                        "id": "e038316e-f00e-4741-bafd-b994ef3e140d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe038316e-f00e-4741-bafd-b994ef3e140d.pdf?alt=media&token=3d61dd96-0c0c-4281-8f39-efddae964072",
                        "fileExt": ".pdf",
                        "fileName": "4658.4375.pdf"
                      },
                      {
                        "name": "4658.4390",
                        "type": "unknown",
                        "id": "f4a15f13-9e72-49d2-b17e-a3894dfcaf45",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff4a15f13-9e72-49d2-b17e-a3894dfcaf45.pdf?alt=media&token=e63c3ce6-0333-4e6a-b7e5-ac06783e5fcf",
                        "fileExt": ".pdf",
                        "fileName": "4658.4390.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Dietary Service",
                    type: "dir", open: false,
                    "id": "0eb2cc89-cedc-4e75-ba5a-4e4b64958caf",
                    "path": "Regulations/MN/MN Chapter 4658/Dietary Service",
                    data: [
                      {
                        "name": "4658.0600",
                        "type": "unknown",
                        "id": "caa6a3b8-e0f0-43f0-a15e-cbe99979c256",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcaa6a3b8-e0f0-43f0-a15e-cbe99979c256.pdf?alt=media&token=94dc17db-32c0-41e1-96a5-7351908c8728",
                        "fileExt": ".pdf",
                        "fileName": "4658.0600.pdf"
                      },
                      {
                        "name": "4658.0605",
                        "type": "unknown",
                        "id": "fc4cb571-d53c-4d24-be17-52139dca3623",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffc4cb571-d53c-4d24-be17-52139dca3623.pdf?alt=media&token=0d3a011a-aafb-4583-8253-ac5169b1d169",
                        "fileExt": ".pdf",
                        "fileName": "4658.0605.pdf"
                      },
                      {
                        "name": "4658.0610",
                        "type": "unknown",
                        "id": "673c5c68-723b-4f54-9c91-8ef92df9454e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F673c5c68-723b-4f54-9c91-8ef92df9454e.pdf?alt=media&token=a2ecece7-92d6-4977-934d-b901bb3229e1",
                        "fileExt": ".pdf",
                        "fileName": "4658.0610.pdf"
                      },
                      {
                        "name": "4658.0615",
                        "type": "unknown",
                        "id": "c7f23c4e-dd14-449f-877b-9c886e8437be",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc7f23c4e-dd14-449f-877b-9c886e8437be.pdf?alt=media&token=cdb12938-9cdd-476b-8c92-8602a47507ec",
                        "fileExt": ".pdf",
                        "fileName": "4658.0615.pdf"
                      },
                      {
                        "name": "4658.0620",
                        "type": "unknown",
                        "id": "f2e82115-b863-4312-adca-9c2b3defe3d7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff2e82115-b863-4312-adca-9c2b3defe3d7.pdf?alt=media&token=c38e7338-8628-4364-888c-6f382c3b45b6",
                        "fileExt": ".pdf",
                        "fileName": "4658.0620.pdf"
                      },
                      {
                        "name": "4658.0625",
                        "type": "unknown",
                        "id": "8df03223-3e48-4956-9bd9-36da3954adf8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8df03223-3e48-4956-9bd9-36da3954adf8.pdf?alt=media&token=676978d3-e72a-4535-b69f-8e6132a9586e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0625.pdf"
                      },
                      {
                        "name": "4658.0630",
                        "type": "unknown",
                        "id": "e6c41458-edd8-4008-ba60-3cf449a4e285",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe6c41458-edd8-4008-ba60-3cf449a4e285.pdf?alt=media&token=feb18917-5acf-47b6-b3ea-ae0bce9b582c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0630.pdf"
                      },
                      {
                        "name": "4658.0635",
                        "type": "unknown",
                        "id": "1513b30a-9738-4261-9694-158129a1a0be",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1513b30a-9738-4261-9694-158129a1a0be.pdf?alt=media&token=e6a3f029-b546-442a-a404-10dcfea03486",
                        "fileExt": ".pdf",
                        "fileName": "4658.0635.pdf"
                      },
                      {
                        "name": "4658.0640",
                        "type": "unknown",
                        "id": "b4ea9279-9fcd-4e9f-b26f-e696bc9c8ec0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb4ea9279-9fcd-4e9f-b26f-e696bc9c8ec0.pdf?alt=media&token=c95c148b-afab-4129-8d99-ba6e64927f0e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0640.pdf"
                      },
                      {
                        "name": "4658.0645",
                        "type": "unknown",
                        "id": "27166410-f22d-4b67-b5ff-d0fd7ef2994b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F27166410-f22d-4b67-b5ff-d0fd7ef2994b.pdf?alt=media&token=a7335f99-8f1f-4612-9c1f-f4d48ca8b91a",
                        "fileExt": ".pdf",
                        "fileName": "4658.0645.pdf"
                      },
                      {
                        "name": "4658.0650",
                        "type": "unknown",
                        "id": "70c2b4f8-e330-4513-bdb5-0b225f8291c5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F70c2b4f8-e330-4513-bdb5-0b225f8291c5.pdf?alt=media&token=4c99ce91-9091-4b17-aa09-ad036bba0679",
                        "fileExt": ".pdf",
                        "fileName": "4658.0650.pdf"
                      },
                      {
                        "name": "4658.0655",
                        "type": "unknown",
                        "id": "ccc17eb4-d5f9-4b53-90f0-ba524ce62dc0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fccc17eb4-d5f9-4b53-90f0-ba524ce62dc0.pdf?alt=media&token=63e0f72a-8495-4c90-ba19-68b3b14b79e2",
                        "fileExt": ".pdf",
                        "fileName": "4658.0655.pdf"
                      },
                      {
                        "name": "4658.0660",
                        "type": "unknown",
                        "id": "a8b5d390-ff95-4dd7-bd10-270f68c4b25d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa8b5d390-ff95-4dd7-bd10-270f68c4b25d.pdf?alt=media&token=b868611f-d7eb-454b-bbe9-c22581c3a205",
                        "fileExt": ".pdf",
                        "fileName": "4658.0660.pdf"
                      },
                      {
                        "name": "4658.0665",
                        "type": "unknown",
                        "id": "bf4a3359-fca7-46e9-9ed5-12394319589b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbf4a3359-fca7-46e9-9ed5-12394319589b.pdf?alt=media&token=f14c396f-cfbd-4700-a0fc-4276c49e1aee",
                        "fileExt": ".pdf",
                        "fileName": "4658.0665.pdf"
                      },
                      {
                        "name": "4658.0670",
                        "type": "unknown",
                        "id": "faad964b-632c-4735-b7d2-dd89e6d43e25",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffaad964b-632c-4735-b7d2-dd89e6d43e25.pdf?alt=media&token=dfe58052-8443-4384-8fbf-cca975b85d3f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0670.pdf"
                      },
                      {
                        "name": "4658.0675",
                        "type": "unknown",
                        "id": "6bd912ab-6b65-4f14-92df-b1ef13a7d030",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6bd912ab-6b65-4f14-92df-b1ef13a7d030.pdf?alt=media&token=59433624-9a28-4750-8e83-82f637110ebc",
                        "fileExt": ".pdf",
                        "fileName": "4658.0675.pdf"
                      },
                      {
                        "name": "4658.0680",
                        "type": "unknown",
                        "id": "9c7220cd-38e9-4aa6-b330-0957484fda25",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9c7220cd-38e9-4aa6-b330-0957484fda25.pdf?alt=media&token=c5a37389-53a2-4911-a08c-5a4f3e533bdf",
                        "fileExt": ".pdf",
                        "fileName": "4658.0680.pdf"
                      },
                      {
                        "name": "4658.0685",
                        "type": "unknown",
                        "id": "35c169f2-b2b7-4f34-b461-f93b25752429",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F35c169f2-b2b7-4f34-b461-f93b25752429.pdf?alt=media&token=dd6326ea-d49c-44ce-a601-090d07749b7c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0685.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Electrical Systems - Existing Construction",
                    type: "dir", open: false,
                    "id": "54e65545-40f0-4fe8-ba96-7af62c85e612",
                    "path": "Regulations/MN/MN Chapter 4658/Electrical Systems - Existing Construction",
                    data: [
                      {
                        "name": "4658.5500",
                        "type": "unknown",
                        "id": "d7814c88-59b1-46ca-8034-7320b3aa5b6a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd7814c88-59b1-46ca-8034-7320b3aa5b6a.pdf?alt=media&token=e98dfe66-6e65-494e-905b-5611f4ab8b71",
                        "fileExt": ".pdf",
                        "fileName": "4658.5500.pdf"
                      },
                      {
                        "name": "4658.5505",
                        "type": "unknown",
                        "id": "0b0b1790-cbf8-4c77-82dd-4f7d9b8a1416",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0b0b1790-cbf8-4c77-82dd-4f7d9b8a1416.pdf?alt=media&token=c6f09652-bed9-4f4d-a6a0-2371ade835a4",
                        "fileExt": ".pdf",
                        "fileName": "4658.5505.pdf"
                      },
                      {
                        "name": "4658.5510",
                        "type": "unknown",
                        "id": "5bf3d6a4-22d0-420b-af79-d7357e932512",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5bf3d6a4-22d0-420b-af79-d7357e932512.pdf?alt=media&token=b79241f4-866e-4111-9c55-39a5be78fbbd",
                        "fileExt": ".pdf",
                        "fileName": "4658.5510.pdf"
                      },
                      {
                        "name": "4658.5515",
                        "type": "unknown",
                        "id": "435f6946-5d36-469c-be1a-92bd7ca94440",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F435f6946-5d36-469c-be1a-92bd7ca94440.pdf?alt=media&token=b1b03a20-335c-4a2e-b73f-e462bfbf1a4f",
                        "fileExt": ".pdf",
                        "fileName": "4658.5515.pdf"
                      },
                      {
                        "name": "4658.5520",
                        "type": "unknown",
                        "id": "dc7d7096-6ab0-41f8-ab54-3c2b50c16472",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdc7d7096-6ab0-41f8-ab54-3c2b50c16472.pdf?alt=media&token=026750b8-1504-4604-bb50-43b05cf917a7",
                        "fileExt": ".pdf",
                        "fileName": "4658.5520.pdf"
                      },
                      {
                        "name": "4658.5590",
                        "type": "unknown",
                        "id": "161a8d16-916c-46cc-887c-a95492c3a80a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F161a8d16-916c-46cc-887c-a95492c3a80a.pdf?alt=media&token=34898f69-119f-40b9-bb5f-9efedbc4722c",
                        "fileExt": ".pdf",
                        "fileName": "4658.5590.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Electrical Systems - New Construction",
                    type: "dir", open: false,
                    "id": "17da6d88-9513-4f5d-aae3-0bb73ddb3e06",
                    "path": "Regulations/MN/MN Chapter 4658/Electrical Systems - New Construction",
                    data: [
                      {
                        "name": "4658.4600",
                        "type": "unknown",
                        "id": "548f5d1e-f872-4343-a5ea-7199e36ad8b6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F548f5d1e-f872-4343-a5ea-7199e36ad8b6.pdf?alt=media&token=985e637f-9beb-4f4c-a9c0-db49f39e86ec",
                        "fileExt": ".pdf",
                        "fileName": "4658.4600.pdf"
                      },
                      {
                        "name": "4658.4605",
                        "type": "unknown",
                        "id": "0e5bd68c-e414-42e8-97c1-29b8a6e955d5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0e5bd68c-e414-42e8-97c1-29b8a6e955d5.pdf?alt=media&token=c47f7ce8-36f5-4b5b-96df-6ba429a88228",
                        "fileExt": ".pdf",
                        "fileName": "4658.4605.pdf"
                      },
                      {
                        "name": "4658.4610",
                        "type": "unknown",
                        "id": "2bc160f8-8ab1-4fa3-9515-abb11c340a10",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2bc160f8-8ab1-4fa3-9515-abb11c340a10.pdf?alt=media&token=ae957447-df03-466c-9e4f-e72c0d708e28",
                        "fileExt": ".pdf",
                        "fileName": "4658.4610.pdf"
                      },
                      {
                        "name": "4658.4615",
                        "type": "unknown",
                        "id": "904e6e18-99c8-452b-94d2-93250ec2beff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F904e6e18-99c8-452b-94d2-93250ec2beff.pdf?alt=media&token=093c7f2b-41a6-454e-b48e-936dee629909",
                        "fileExt": ".pdf",
                        "fileName": "4658.4615.pdf"
                      },
                      {
                        "name": "4658.4620",
                        "type": "unknown",
                        "id": "063d024d-6a2e-4c15-83fe-45449e1ae251",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F063d024d-6a2e-4c15-83fe-45449e1ae251.pdf?alt=media&token=79835713-59ad-4f23-a86d-6dcf98ca5c32",
                        "fileExt": ".pdf",
                        "fileName": "4658.4620.pdf"
                      },
                      {
                        "name": "4658.4625",
                        "type": "unknown",
                        "id": "cc2e9b7e-7b1a-44b7-9d7e-5c7d6868f3d7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcc2e9b7e-7b1a-44b7-9d7e-5c7d6868f3d7.pdf?alt=media&token=92cbbfe5-8013-4e8c-9363-776418f2df5e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4625.pdf"
                      },
                      {
                        "name": "4658.4630",
                        "type": "unknown",
                        "id": "c868e408-177b-41c9-81bb-74913505fc48",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc868e408-177b-41c9-81bb-74913505fc48.pdf?alt=media&token=103f37f4-84fb-40f3-9613-b7e4cabfaf0e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4630.pdf"
                      },
                      {
                        "name": "4658.4635",
                        "type": "unknown",
                        "id": "0880e234-7810-4d53-82eb-2ce1763e39b5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0880e234-7810-4d53-82eb-2ce1763e39b5.pdf?alt=media&token=85d8e884-014a-468d-ad81-78cd99581e3f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4635.pdf"
                      },
                      {
                        "name": "4658.4640",
                        "type": "unknown",
                        "id": "9c3ed846-98fe-43a7-9c21-f72bc2950186",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9c3ed846-98fe-43a7-9c21-f72bc2950186.pdf?alt=media&token=f60da8da-0dc7-4867-80f9-f1bef42c20d9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4640.pdf"
                      },
                      {
                        "name": "4658.4690",
                        "type": "unknown",
                        "id": "09e9cda5-4e91-4086-a49b-49da0e811dae",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F09e9cda5-4e91-4086-a49b-49da0e811dae.pdf?alt=media&token=678cd684-64ee-44f0-a2b6-f2d3ac88411f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4690.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Environmental Services",
                    type: "dir", open: false,
                    "id": "47eb365b-863b-49ea-b882-26969ac8e4ba",
                    "path": "Regulations/MN/MN Chapter 4658/Environmental Services",
                    data: [
                      {
                        "name": "4658.1400",
                        "type": "unknown",
                        "id": "cca74ebc-0942-4cd2-b0ff-d3c32e8b7a78",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcca74ebc-0942-4cd2-b0ff-d3c32e8b7a78.pdf?alt=media&token=646a3a6d-4bc8-4b70-83cd-d9e8dd3216da",
                        "fileExt": ".pdf",
                        "fileName": "4658.1400.pdf"
                      },
                      {
                        "name": "4658.1405",
                        "type": "unknown",
                        "id": "989d5855-5177-4c1d-8770-2c053a528e35",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F989d5855-5177-4c1d-8770-2c053a528e35.pdf?alt=media&token=043acbdd-b0df-4106-bd1c-3c07e10e5a28",
                        "fileExt": ".pdf",
                        "fileName": "4658.1405.pdf"
                      },
                      {
                        "name": "4658.1410",
                        "type": "unknown",
                        "id": "73a92ecf-46bc-466a-826c-6206003f0f6d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F73a92ecf-46bc-466a-826c-6206003f0f6d.pdf?alt=media&token=354aa4d2-c89f-4ba8-90e7-d6ad87929fe8",
                        "fileExt": ".pdf",
                        "fileName": "4658.1410.pdf"
                      },
                      {
                        "name": "4658.1415",
                        "type": "unknown",
                        "id": "8231868a-0b4c-4d3d-8197-27bb56ed487e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8231868a-0b4c-4d3d-8197-27bb56ed487e.pdf?alt=media&token=f8913f4e-cdc1-4e06-a963-8c34ed3ac077",
                        "fileExt": ".pdf",
                        "fileName": "4658.1415.pdf"
                      },
                      {
                        "name": "4658.1420",
                        "type": "unknown",
                        "id": "ec438c12-f952-4d9d-8c14-5bfb5bea9c5b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fec438c12-f952-4d9d-8c14-5bfb5bea9c5b.pdf?alt=media&token=bffaa8f4-9cac-47f8-9b38-5a607138ce16",
                        "fileExt": ".pdf",
                        "fileName": "4658.1420.pdf"
                      },
                      {
                        "name": "4658.1425",
                        "type": "unknown",
                        "id": "de829ddc-cf7c-4da3-ab07-5603a269bd44",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fde829ddc-cf7c-4da3-ab07-5603a269bd44.pdf?alt=media&token=9a4d869a-8335-48e6-ad71-c4720f0ec6da",
                        "fileExt": ".pdf",
                        "fileName": "4658.1425.pdf"
                      },
                      {
                        "name": "4658.1490",
                        "type": "unknown",
                        "id": "d094303a-3617-4849-b0b1-12428b8e62bf",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd094303a-3617-4849-b0b1-12428b8e62bf.pdf?alt=media&token=bcebd207-12ac-46d9-a9cc-7d8d20ec1566",
                        "fileExt": ".pdf",
                        "fileName": "4658.1490.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Infection Control",
                    type: "dir", open: false,
                    "id": "28e1aea5-7f42-43b6-873b-a93b1c163686",
                    "path": "Regulations/MN/MN Chapter 4658/Infection Control",
                    data: [
                      {
                        "name": "4658.0800",
                        "type": "unknown",
                        "id": "3ea02ff5-70ac-4486-8284-2a02cfc58fcc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3ea02ff5-70ac-4486-8284-2a02cfc58fcc.pdf?alt=media&token=32c5df91-089c-4757-894f-6e8a1029eb5b",
                        "fileExt": ".pdf",
                        "fileName": "4658.0800.pdf"
                      },
                      {
                        "name": "4658.0810",
                        "type": "unknown",
                        "id": "ba3c2ba3-79d4-4b04-ac6d-30833969e16f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fba3c2ba3-79d4-4b04-ac6d-30833969e16f.pdf?alt=media&token=622b9ca9-1f8c-4b1d-82d2-79c870e5c46b",
                        "fileExt": ".pdf",
                        "fileName": "4658.0810.pdf"
                      },
                      {
                        "name": "4658.0820",
                        "type": "unknown",
                        "id": "356e80e3-99e1-4f9f-ab90-9370c076273f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F356e80e3-99e1-4f9f-ab90-9370c076273f.pdf?alt=media&token=fcaa91b0-cce1-4e7b-ad98-ca4072a1eff2",
                        "fileExt": ".pdf",
                        "fileName": "4658.0820.pdf"
                      },
                      {
                        "name": "4658.0850",
                        "type": "unknown",
                        "id": "7aa5e5e8-65b1-4b6f-b28b-778d8344b625",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7aa5e5e8-65b1-4b6f-b28b-778d8344b625.pdf?alt=media&token=17fc25e1-e242-4ab1-aeec-24b1000cff04",
                        "fileExt": ".pdf",
                        "fileName": "4658.0850.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Licensing",
                    type: "dir", open: false,
                    "id": "3c77f08f-ebbf-4e28-92d3-8a052a5409eb",
                    "path": "Regulations/MN/MN Chapter 4658/Licensing",
                    data: [
                      {
                        "name": "4658.0010",
                        "type": "unknown",
                        "id": "a6a2341a-3058-4ea7-bb64-bd5dae88c38b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa6a2341a-3058-4ea7-bb64-bd5dae88c38b.pdf?alt=media&token=670313fd-91d1-4755-ace0-a51eb0f64254",
                        "fileExt": ".pdf",
                        "fileName": "4658.0010.pdf"
                      },
                      {
                        "name": "4658.0015",
                        "type": "unknown",
                        "id": "d50d7b84-864c-4d56-ba70-441c8ad06a52",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd50d7b84-864c-4d56-ba70-441c8ad06a52.pdf?alt=media&token=9737dcbb-3dab-4ed3-b0cf-6855b37c7269",
                        "fileExt": ".pdf",
                        "fileName": "4658.0015.pdf"
                      },
                      {
                        "name": "4658.0020",
                        "type": "unknown",
                        "id": "63312089-8db4-4c73-aa09-2f7fb0df2d51",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F63312089-8db4-4c73-aa09-2f7fb0df2d51.pdf?alt=media&token=77b9dfd0-933f-4ad0-9a83-088f120e28c3",
                        "fileExt": ".pdf",
                        "fileName": "4658.0020.pdf"
                      },
                      {
                        "name": "4658.0025",
                        "type": "unknown",
                        "id": "3847be54-e5c1-401e-84c5-74a1751655fc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3847be54-e5c1-401e-84c5-74a1751655fc.pdf?alt=media&token=1bbcba51-8600-44cd-862d-5b9f5899e0d8",
                        "fileExt": ".pdf",
                        "fileName": "4658.0025.pdf"
                      },
                      {
                        "name": "4658.0030",
                        "type": "unknown",
                        "id": "648c3eaf-ba88-4034-848e-5c189f785f4d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F648c3eaf-ba88-4034-848e-5c189f785f4d.pdf?alt=media&token=653c8df2-bce1-4160-86e9-634be97334e3",
                        "fileExt": ".pdf",
                        "fileName": "4658.0030.pdf"
                      },
                      {
                        "name": "4658.0035",
                        "type": "unknown",
                        "id": "cdc2ed47-8cdc-4c34-906e-fb2f378d6561",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcdc2ed47-8cdc-4c34-906e-fb2f378d6561.pdf?alt=media&token=ad42b09c-f82e-44f6-8ec1-3cee23fdf50e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0035.pdf"
                      },
                      {
                        "name": "4658.0040",
                        "type": "unknown",
                        "id": "b2cc70cd-b59f-40ec-8b9c-b6edf4ae0e8d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb2cc70cd-b59f-40ec-8b9c-b6edf4ae0e8d.pdf?alt=media&token=cabe21ce-7e64-4eab-a36d-f568e74e29e5",
                        "fileExt": ".pdf",
                        "fileName": "4658.0040.pdf"
                      },
                      {
                        "name": "4658.0045",
                        "type": "unknown",
                        "id": "831ee8e2-c2fa-4c10-8c7f-2183b56d3f30",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F831ee8e2-c2fa-4c10-8c7f-2183b56d3f30.pdf?alt=media&token=bd14cfed-8a49-4fd5-8e46-fecc163bbf95",
                        "fileExt": ".pdf",
                        "fileName": "4658.0045.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Mechanical Systems - Existing Construction",
                    type: "dir", open: false,
                    "id": "be6d0441-39fc-4f6f-8ad6-bd4bdc20bd38",
                    "path": "Regulations/MN/MN Chapter 4658/Mechanical Systems - Existing Construction",
                    data: [
                      {
                        "name": "4658.5400",
                        "type": "unknown",
                        "id": "85a519e2-8c65-4182-98e5-36377cec67e8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F85a519e2-8c65-4182-98e5-36377cec67e8.pdf?alt=media&token=9200f8ea-18d1-4ee4-b957-d95f0e2232dc",
                        "fileExt": ".pdf",
                        "fileName": "4658.5400.pdf"
                      },
                      {
                        "name": "4658.5405",
                        "type": "unknown",
                        "id": "d8a5f883-7b38-415c-b654-22e145ed3291",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd8a5f883-7b38-415c-b654-22e145ed3291.pdf?alt=media&token=5e44d28b-2795-4ca5-a08b-624530b518a8",
                        "fileExt": ".pdf",
                        "fileName": "4658.5405.pdf"
                      },
                      {
                        "name": "4658.5410",
                        "type": "unknown",
                        "id": "1c578ab8-8b26-4ade-ab83-3f1f251914be",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1c578ab8-8b26-4ade-ab83-3f1f251914be.pdf?alt=media&token=06a84930-957c-466f-80e1-e41bcfb80a5e",
                        "fileExt": ".pdf",
                        "fileName": "4658.5410.pdf"
                      },
                      {
                        "name": "4658.5415",
                        "type": "unknown",
                        "id": "f1272f47-147d-425d-a21e-bab2ed2de102",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff1272f47-147d-425d-a21e-bab2ed2de102.pdf?alt=media&token=5682e6d6-a02d-4319-bce6-3640f2473278",
                        "fileExt": ".pdf",
                        "fileName": "4658.5415.pdf"
                      },
                      {
                        "name": "4658.5490",
                        "type": "unknown",
                        "id": "5d94fc87-45a0-458f-a19c-f0e883bff8b8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5d94fc87-45a0-458f-a19c-f0e883bff8b8.pdf?alt=media&token=18f410dd-1a96-4cf9-b3a0-7b2029c7f079",
                        "fileExt": ".pdf",
                        "fileName": "4658.5490.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Mechanical Systems - New Construction",
                    type: "dir", open: false,
                    "id": "c0fefa84-50c6-41d8-a288-7cca53b01f49",
                    "path": "Regulations/MN/MN Chapter 4658/Mechanical Systems - New Construction",
                    data: [
                      {
                        "name": "4658.4500",
                        "type": "unknown",
                        "id": "33b2a135-9f7d-4719-9ef4-b3f823bfdcff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F33b2a135-9f7d-4719-9ef4-b3f823bfdcff.pdf?alt=media&token=02d5c5a3-d0ad-4a78-9bb1-6bbcc8287f0f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4500.pdf"
                      },
                      {
                        "name": "4658.4505",
                        "type": "unknown",
                        "id": "22945f31-456a-4ea6-b39a-01e07f307838",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F22945f31-456a-4ea6-b39a-01e07f307838.pdf?alt=media&token=9e04dfb9-c097-46f5-a89c-7f79506b3eac",
                        "fileExt": ".pdf",
                        "fileName": "4658.4505.pdf"
                      },
                      {
                        "name": "4658.4510",
                        "type": "unknown",
                        "id": "c2a7a1b1-1301-4479-b5f6-caf2990878eb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc2a7a1b1-1301-4479-b5f6-caf2990878eb.pdf?alt=media&token=cac19ea7-b5fc-4865-9985-f2cc8da734b9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4510.pdf"
                      },
                      {
                        "name": "4658.4515",
                        "type": "unknown",
                        "id": "9f8886dd-20a1-4a72-8326-6693658b01cc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9f8886dd-20a1-4a72-8326-6693658b01cc.pdf?alt=media&token=1075da9d-b5b4-429a-9e01-41a8ca3ad7d9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4515.pdf"
                      },
                      {
                        "name": "4658.4520",
                        "type": "unknown",
                        "id": "c8bf50ea-8b37-433b-bfe5-ce0f1397cb7d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc8bf50ea-8b37-433b-bfe5-ce0f1397cb7d.pdf?alt=media&token=366ce016-065d-4699-93e0-c06f55c2e778",
                        "fileExt": ".pdf",
                        "fileName": "4658.4520.pdf"
                      },
                      {
                        "name": "4658.4525",
                        "type": "unknown",
                        "id": "9ea6507f-a88e-4f26-b10a-12a9c598d8b1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9ea6507f-a88e-4f26-b10a-12a9c598d8b1.pdf?alt=media&token=948f8c86-a9fc-40d4-ab18-db1e4a42db07",
                        "fileExt": ".pdf",
                        "fileName": "4658.4525.pdf"
                      },
                      {
                        "name": "4658.4530",
                        "type": "unknown",
                        "id": "7cf10298-b9b7-407e-a531-b606966fbb6e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7cf10298-b9b7-407e-a531-b606966fbb6e.pdf?alt=media&token=50ac714c-590b-459e-93d2-90c8c636f9e9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4530.pdf"
                      },
                      {
                        "name": "4658.4535",
                        "type": "unknown",
                        "id": "66891703-f45c-40e6-ba6c-6987de72fa0d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F66891703-f45c-40e6-ba6c-6987de72fa0d.pdf?alt=media&token=5a1f032e-afe0-421e-a098-66f9aa7b7a91",
                        "fileExt": ".pdf",
                        "fileName": "4658.4535.pdf"
                      },
                      {
                        "name": "4658.4540",
                        "type": "unknown",
                        "id": "7aafc2bf-aeca-4c15-8f2b-2944149c06ff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7aafc2bf-aeca-4c15-8f2b-2944149c06ff.pdf?alt=media&token=5a3b747e-8164-44b0-b3d3-f33e75d7aaac",
                        "fileExt": ".pdf",
                        "fileName": "4658.4540.pdf"
                      },
                      {
                        "name": "4658.4545",
                        "type": "unknown",
                        "id": "6da10490-63d6-42ca-8786-37714fc667d9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6da10490-63d6-42ca-8786-37714fc667d9.pdf?alt=media&token=9e17a820-fbf9-429d-bb4e-30c4f939ee48",
                        "fileExt": ".pdf",
                        "fileName": "4658.4545.pdf"
                      },
                      {
                        "name": "4658.4550",
                        "type": "unknown",
                        "id": "78fabd61-c21f-44a5-ae06-dc9f76237ca8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F78fabd61-c21f-44a5-ae06-dc9f76237ca8.pdf?alt=media&token=eca3646a-a251-4004-b34b-22ac7db5f5d0",
                        "fileExt": ".pdf",
                        "fileName": "4658.4550.pdf"
                      },
                      {
                        "name": "4658.4590",
                        "type": "unknown",
                        "id": "858e27fb-d5ad-418c-957f-4830a25a3b99",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F858e27fb-d5ad-418c-957f-4830a25a3b99.pdf?alt=media&token=4d287e5b-209c-4358-b854-f9bdefff2370",
                        "fileExt": ".pdf",
                        "fileName": "4658.4590.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Medical and Dental Services",
                    type: "dir", open: false,
                    "id": "62beb0bd-4e87-431f-9025-cf8941910037",
                    "path": "Regulations/MN/MN Chapter 4658/Medical and Dental Services",
                    data: [
                      {
                        "name": "4658.0700",
                        "type": "unknown",
                        "id": "6ffaa34c-c21f-4f70-84cc-b03b0e7051a0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6ffaa34c-c21f-4f70-84cc-b03b0e7051a0.pdf?alt=media&token=5fadbb1e-d6e0-4fe7-8bd3-1ab5cf94244e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0700.pdf"
                      },
                      {
                        "name": "4658.0705",
                        "type": "unknown",
                        "id": "bdde2a8a-e98a-41a2-ae52-4c6789f62c0c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbdde2a8a-e98a-41a2-ae52-4c6789f62c0c.pdf?alt=media&token=de560cd1-8bc4-463c-b3ba-876d3a3da78c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0705.pdf"
                      },
                      {
                        "name": "4658.0710",
                        "type": "unknown",
                        "id": "c4d8fe8f-7c44-4922-8b14-0194af1fa68c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc4d8fe8f-7c44-4922-8b14-0194af1fa68c.pdf?alt=media&token=2cfad324-5ac1-4b20-a1d4-4567ace00fc8",
                        "fileExt": ".pdf",
                        "fileName": "4658.0710.pdf"
                      },
                      {
                        "name": "4658.0715",
                        "type": "unknown",
                        "id": "7d0033dc-7df4-4650-8be7-1dd7b3c58715",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7d0033dc-7df4-4650-8be7-1dd7b3c58715.pdf?alt=media&token=2d29cf12-ea6c-4f6f-9c78-1498c14ac012",
                        "fileExt": ".pdf",
                        "fileName": "4658.0715.pdf"
                      },
                      {
                        "name": "4658.0720",
                        "type": "unknown",
                        "id": "2675beae-8798-4a09-853f-a2527bbf0566",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2675beae-8798-4a09-853f-a2527bbf0566.pdf?alt=media&token=5c66828b-cea3-4ca8-b879-b314150c2b8c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0720.pdf"
                      },
                      {
                        "name": "4658.0725",
                        "type": "unknown",
                        "id": "e390dcf0-d8a8-4616-bff4-77d2a6cac902",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe390dcf0-d8a8-4616-bff4-77d2a6cac902.pdf?alt=media&token=f5080037-84e6-43bc-98d8-0fab7cf20e06",
                        "fileExt": ".pdf",
                        "fileName": "4658.0725.pdf"
                      },
                      {
                        "name": "4658.0730",
                        "type": "unknown",
                        "id": "4c4a0983-df0e-43ae-8116-1e7859f66717",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4c4a0983-df0e-43ae-8116-1e7859f66717.pdf?alt=media&token=1fead029-daba-4df6-9471-b288c4ee7f1e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0730.pdf"
                      },
                      {
                        "name": "4658.0750",
                        "type": "unknown",
                        "id": "d6fc0c82-82ef-47dc-b0ae-d9664a566457",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd6fc0c82-82ef-47dc-b0ae-d9664a566457.pdf?alt=media&token=a4731a2c-2002-4268-907d-d26eafd83712",
                        "fileExt": ".pdf",
                        "fileName": "4658.0750.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Medications",
                    type: "dir", open: false,
                    "id": "8203c808-a4ff-4589-8442-576e233ddecb",
                    "path": "Regulations/MN/MN Chapter 4658/Medications",
                    data: [
                      {
                        "name": "4658.1300",
                        "type": "unknown",
                        "id": "23723bb4-5d22-4444-97a7-90538f9ff97b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F23723bb4-5d22-4444-97a7-90538f9ff97b.pdf?alt=media&token=20eb3642-2ae2-4ccb-96e8-f253cdbd50a4",
                        "fileExt": ".pdf",
                        "fileName": "4658.1300.pdf"
                      },
                      {
                        "name": "4658.1305",
                        "type": "unknown",
                        "id": "b7e45da8-4468-4e70-bc39-cf73a75004c0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb7e45da8-4468-4e70-bc39-cf73a75004c0.pdf?alt=media&token=ced945c8-5cb4-4bc9-bede-7fb1a1712671",
                        "fileExt": ".pdf",
                        "fileName": "4658.1305.pdf"
                      },
                      {
                        "name": "4658.1310",
                        "type": "unknown",
                        "id": "ab3f212c-5f3a-4107-8cd5-727f17a8605d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fab3f212c-5f3a-4107-8cd5-727f17a8605d.pdf?alt=media&token=75619455-2926-41cd-baf1-372722ca955c",
                        "fileExt": ".pdf",
                        "fileName": "4658.1310.pdf"
                      },
                      {
                        "name": "4658.1315",
                        "type": "unknown",
                        "id": "196176ea-fcdb-4c13-a580-9bfe53097ac5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F196176ea-fcdb-4c13-a580-9bfe53097ac5.pdf?alt=media&token=81960104-de2b-407c-8d78-02360f3073db",
                        "fileExt": ".pdf",
                        "fileName": "4658.1315.pdf"
                      },
                      {
                        "name": "4658.1320",
                        "type": "unknown",
                        "id": "81487862-21a1-4567-9632-97da2e3b1ccc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F81487862-21a1-4567-9632-97da2e3b1ccc.pdf?alt=media&token=0e22a37e-e99f-491b-a375-9e713acf074f",
                        "fileExt": ".pdf",
                        "fileName": "4658.1320.pdf"
                      },
                      {
                        "name": "4658.1325",
                        "type": "unknown",
                        "id": "b4255e11-bc74-48cd-b22c-5ea303c3cfb4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb4255e11-bc74-48cd-b22c-5ea303c3cfb4.pdf?alt=media&token=ad283c77-cebc-4882-a72e-8e8aae4e13b8",
                        "fileExt": ".pdf",
                        "fileName": "4658.1325.pdf"
                      },
                      {
                        "name": "4658.1330",
                        "type": "unknown",
                        "id": "2d97666f-3010-47a6-b1f9-c7f61a537486",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2d97666f-3010-47a6-b1f9-c7f61a537486.pdf?alt=media&token=4344f182-196c-44eb-855a-e83d787a47bc",
                        "fileExt": ".pdf",
                        "fileName": "4658.1330.pdf"
                      },
                      {
                        "name": "4658.1335",
                        "type": "unknown",
                        "id": "1b4152de-7b7a-4ca5-9d42-82b650056add",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1b4152de-7b7a-4ca5-9d42-82b650056add.pdf?alt=media&token=311b79bf-00ef-4938-a7c5-3bfa0f7c5f26",
                        "fileExt": ".pdf",
                        "fileName": "4658.1335.pdf"
                      },
                      {
                        "name": "4658.1340",
                        "type": "unknown",
                        "id": "056947e8-bc18-4f5e-a1e3-07c1eb6ef632",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F056947e8-bc18-4f5e-a1e3-07c1eb6ef632.pdf?alt=media&token=cd539fa1-22d0-46e9-ae77-b390138da217",
                        "fileExt": ".pdf",
                        "fileName": "4658.1340.pdf"
                      },
                      {
                        "name": "4658.1345",
                        "type": "unknown",
                        "id": "35dc1646-3d38-4d16-9a9f-a5319f7e1ec4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F35dc1646-3d38-4d16-9a9f-a5319f7e1ec4.pdf?alt=media&token=27e77fcf-3f44-46af-94df-049dd372f059",
                        "fileExt": ".pdf",
                        "fileName": "4658.1345.pdf"
                      },
                      {
                        "name": "4658.1350",
                        "type": "unknown",
                        "id": "64211e97-df55-4421-b5c4-50192936c2c3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F64211e97-df55-4421-b5c4-50192936c2c3.pdf?alt=media&token=99996ed6-59ad-467c-aabe-795ca18fc688",
                        "fileExt": ".pdf",
                        "fileName": "4658.1350.pdf"
                      },
                      {
                        "name": "4658.1355",
                        "type": "unknown",
                        "id": "1cecd388-8396-4f95-b324-22f5f02cdf3b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1cecd388-8396-4f95-b324-22f5f02cdf3b.pdf?alt=media&token=76459a95-c877-46bc-aa6c-d5f966c7e090",
                        "fileExt": ".pdf",
                        "fileName": "4658.1355.pdf"
                      },
                      {
                        "name": "4658.1360",
                        "type": "unknown",
                        "id": "f120172d-7567-4888-99dd-07e36fb52ade",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff120172d-7567-4888-99dd-07e36fb52ade.pdf?alt=media&token=5156fae4-210b-4f85-96a8-58d82b66f503",
                        "fileExt": ".pdf",
                        "fileName": "4658.1360.pdf"
                      },
                      {
                        "name": "4658.1365",
                        "type": "unknown",
                        "id": "f4a34c57-a5fc-44b2-96e1-ee8a48847a46",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff4a34c57-a5fc-44b2-96e1-ee8a48847a46.pdf?alt=media&token=2c79629a-77d9-4420-857c-3611c605f3f8",
                        "fileExt": ".pdf",
                        "fileName": "4658.1365.pdf"
                      }
                    ]
                  },
                  {
                    "name": "New Construction - Plans",
                    type: "dir", open: false,
                    "id": "55519475-baae-4108-8388-a472608f7580",
                    "path": "Regulations/MN/MN Chapter 4658/New Construction - Plans",
                    data: [
                      {
                        "name": "4658.3500",
                        "type": "unknown",
                        "id": "51d4b9a6-b3c2-419d-aa4f-2d9ae58ddd17",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F51d4b9a6-b3c2-419d-aa4f-2d9ae58ddd17.pdf?alt=media&token=098ac90d-c18b-4462-8824-343260651115",
                        "fileExt": ".pdf",
                        "fileName": "4658.3500.pdf"
                      },
                      {
                        "name": "4658.3590",
                        "type": "unknown",
                        "id": "6244649d-5c7c-4a3c-8359-6ff4328d6c46",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6244649d-5c7c-4a3c-8359-6ff4328d6c46.pdf?alt=media&token=ecad3419-c86f-4fe7-9dc9-44c54239d82e",
                        "fileExt": ".pdf",
                        "fileName": "4658.3590.pdf"
                      },
                      {
                        "name": "4658.4000",
                        "type": "unknown",
                        "id": "29eb87fb-2a3b-4c30-b998-bae93a63c855",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F29eb87fb-2a3b-4c30-b998-bae93a63c855.pdf?alt=media&token=2ce00814-2597-490a-9c72-b2ae312ba37a",
                        "fileExt": ".pdf",
                        "fileName": "4658.4000.pdf"
                      },
                      {
                        "name": "4658.4005",
                        "type": "unknown",
                        "id": "af05aba9-bdf5-4313-a333-b6db288d59ea",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Faf05aba9-bdf5-4313-a333-b6db288d59ea.pdf?alt=media&token=465b447d-6db8-4a95-8792-bd4b6bd4a148",
                        "fileExt": ".pdf",
                        "fileName": "4658.4005.pdf"
                      },
                      {
                        "name": "4658.4010",
                        "type": "unknown",
                        "id": "57096373-ada4-40f9-82ef-b1bb157fa0e8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F57096373-ada4-40f9-82ef-b1bb157fa0e8.pdf?alt=media&token=2f25dd08-e67f-4c76-b11c-f381dc186bec",
                        "fileExt": ".pdf",
                        "fileName": "4658.4010.pdf"
                      },
                      {
                        "name": "4658.4015",
                        "type": "unknown",
                        "id": "c038602a-614d-46e6-bfa5-c980b72db3c2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc038602a-614d-46e6-bfa5-c980b72db3c2.pdf?alt=media&token=5f3d1df8-5a12-420d-bbea-38cf9be6a49b",
                        "fileExt": ".pdf",
                        "fileName": "4658.4015.pdf"
                      },
                      {
                        "name": "4658.4020",
                        "type": "unknown",
                        "id": "5c39c309-6c56-4c80-af3e-76365e0755d4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5c39c309-6c56-4c80-af3e-76365e0755d4.pdf?alt=media&token=8e11d8d0-d819-4848-a13b-2790536787d4",
                        "fileExt": ".pdf",
                        "fileName": "4658.4020.pdf"
                      },
                      {
                        "name": "4658.4025",
                        "type": "unknown",
                        "id": "b05e8393-9872-44af-b3be-fd4de2a501c5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb05e8393-9872-44af-b3be-fd4de2a501c5.pdf?alt=media&token=de54f812-3a38-44e3-9bbe-8c511a82489e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4025.pdf"
                      },
                      {
                        "name": "4658.4030",
                        "type": "unknown",
                        "id": "1c3c2f49-36d0-47c3-be06-5c238b3bf0c4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1c3c2f49-36d0-47c3-be06-5c238b3bf0c4.pdf?alt=media&token=6b311ac8-3b7d-4597-9f2d-3bbac712eefd",
                        "fileExt": ".pdf",
                        "fileName": "4658.4030.pdf"
                      },
                      {
                        "name": "4658.4035",
                        "type": "unknown",
                        "id": "e10bcfd4-6d1d-48c1-8ec0-a3c9df14a628",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe10bcfd4-6d1d-48c1-8ec0-a3c9df14a628.pdf?alt=media&token=bf10c30b-0785-4458-9900-b0a9e669833d",
                        "fileExt": ".pdf",
                        "fileName": "4658.4035.pdf"
                      },
                      {
                        "name": "4658.4040",
                        "type": "unknown",
                        "id": "274abacc-8a4a-46ce-a442-6163672ce991",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F274abacc-8a4a-46ce-a442-6163672ce991.pdf?alt=media&token=02715519-b99a-4da4-86fb-6e852b80dac4",
                        "fileExt": ".pdf",
                        "fileName": "4658.4040.pdf"
                      },
                      {
                        "name": "4658.4090",
                        "type": "unknown",
                        "id": "52afef14-72ea-41ca-be6f-438d598e0576",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F52afef14-72ea-41ca-be6f-438d598e0576.pdf?alt=media&token=2da6c0ed-ef66-41dc-9946-395daa3e02db",
                        "fileExt": ".pdf",
                        "fileName": "4658.4090.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Nursing Services",
                    type: "dir", open: false,
                    "id": "2d187998-eaf7-4ea1-8192-bc1ec8af9b7e",
                    "path": "Regulations/MN/MN Chapter 4658/Nursing Services",
                    data: [
                      {
                        "name": "4658.0500",
                        "type": "unknown",
                        "id": "c0188e2c-f9df-40f7-b225-80a65f45f291",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc0188e2c-f9df-40f7-b225-80a65f45f291.pdf?alt=media&token=e11e331b-fb70-49c2-886b-5d0aa6406651",
                        "fileExt": ".pdf",
                        "fileName": "4658.0500.pdf"
                      },
                      {
                        "name": "4658.0505",
                        "type": "unknown",
                        "id": "217e1280-867d-4cec-a191-3b4b1b223567",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F217e1280-867d-4cec-a191-3b4b1b223567.pdf?alt=media&token=b1395c78-6968-48f9-917d-1c8fa5c84544",
                        "fileExt": ".pdf",
                        "fileName": "4658.0505.pdf"
                      },
                      {
                        "name": "4658.0510",
                        "type": "unknown",
                        "id": "c86817cf-8e13-4b8b-9b9d-4fc911472992",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc86817cf-8e13-4b8b-9b9d-4fc911472992.pdf?alt=media&token=7b69d179-d6d4-4b46-ac2a-3bd840fb30f1",
                        "fileExt": ".pdf",
                        "fileName": "4658.0510.pdf"
                      },
                      {
                        "name": "4658.0520",
                        "type": "unknown",
                        "id": "c9a5c4cb-969f-448c-9f1b-7afefbdecf59",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc9a5c4cb-969f-448c-9f1b-7afefbdecf59.pdf?alt=media&token=2ae73ec3-46c4-468a-bad0-2ae55a672290",
                        "fileExt": ".pdf",
                        "fileName": "4658.0520.pdf"
                      },
                      {
                        "name": "4658.0525",
                        "type": "unknown",
                        "id": "826fae7e-03d7-4d11-896f-56b0c42b23f7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F826fae7e-03d7-4d11-896f-56b0c42b23f7.pdf?alt=media&token=58471f78-7f3c-4b95-917a-d1400822a998",
                        "fileExt": ".pdf",
                        "fileName": "4658.0525.pdf"
                      },
                      {
                        "name": "4658.0530",
                        "type": "unknown",
                        "id": "45c0275b-cd02-4f13-939e-7f5de922bbb3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F45c0275b-cd02-4f13-939e-7f5de922bbb3.pdf?alt=media&token=f6a58ce7-b813-402b-a18e-71f3c575bf92",
                        "fileExt": ".pdf",
                        "fileName": "4658.0530.pdf"
                      },
                      {
                        "name": "4658.0580",
                        "type": "unknown",
                        "id": "adce98fa-be7e-4017-859a-022e6dacaf05",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fadce98fa-be7e-4017-859a-022e6dacaf05.pdf?alt=media&token=f1c79684-cd01-4d79-ac59-d8ee41559948",
                        "fileExt": ".pdf",
                        "fileName": "4658.0580.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Penalties",
                    type: "dir", open: false,
                    "id": "5530d782-8f9a-4c97-a929-8eebb2f77670",
                    "path": "Regulations/MN/MN Chapter 4658/Penalties",
                    data: [
                      {
                        "name": "4658.0190",
                        "type": "unknown",
                        "id": "9b96d4cc-0967-4fe7-996f-094855174b8d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9b96d4cc-0967-4fe7-996f-094855174b8d.pdf?alt=media&token=e21923c8-9abd-4f30-b32b-4ec0bf34b736",
                        "fileExt": ".pdf",
                        "fileName": "4658.0190.pdf"
                      },
                      {
                        "name": "4658.0191",
                        "type": "unknown",
                        "id": "06f6ece2-0073-4eb7-8333-02ae700d2062",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F06f6ece2-0073-4eb7-8333-02ae700d2062.pdf?alt=media&token=2251aff2-1e5f-434b-9c4c-4aa079d88170",
                        "fileExt": ".pdf",
                        "fileName": "4658.0191.pdf"
                      },
                      {
                        "name": "4658.0192",
                        "type": "unknown",
                        "id": "b4e1b083-8e45-47bc-9c98-483784aeff1e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb4e1b083-8e45-47bc-9c98-483784aeff1e.pdf?alt=media&token=4e389d25-087b-44d6-8572-73f7dd59597a",
                        "fileExt": ".pdf",
                        "fileName": "4658.0192.pdf"
                      },
                      {
                        "name": "4658.0193",
                        "type": "unknown",
                        "id": "e9f45caf-7fe4-4fb8-ad3e-324ede9af68e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe9f45caf-7fe4-4fb8-ad3e-324ede9af68e.pdf?alt=media&token=0ee516dd-dd98-4659-bc39-34398963d323",
                        "fileExt": ".pdf",
                        "fileName": "4658.0193.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Physical Plant Licensure - Existing and New",
                    type: "dir", open: false,
                    "id": "4b2aec5a-74c6-462e-bc71-8e5dc9e2e609",
                    "path": "Regulations/MN/MN Chapter 4658/Physical Plant Licensure - Existing and New",
                    data: [
                      {
                        "name": "4658.3000",
                        "type": "unknown",
                        "id": "330ffa1d-bf03-4003-aeb6-19610357c0b3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F330ffa1d-bf03-4003-aeb6-19610357c0b3.pdf?alt=media&token=bcf4b0cf-c6bc-4766-a45f-775321b6fa0c",
                        "fileExt": ".pdf",
                        "fileName": "4658.3000.pdf"
                      },
                      {
                        "name": "4658.3005",
                        "type": "unknown",
                        "id": "97981e26-7ad0-4993-9bb5-88c6b57027d6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F97981e26-7ad0-4993-9bb5-88c6b57027d6.pdf?alt=media&token=17a004ef-ce08-47dc-b88c-62c45570bc40",
                        "fileExt": ".pdf",
                        "fileName": "4658.3005.pdf"
                      },
                      {
                        "name": "4658.3090",
                        "type": "unknown",
                        "id": "037cb5e9-47d4-44a1-aca2-8e3e5427c667",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F037cb5e9-47d4-44a1-aca2-8e3e5427c667.pdf?alt=media&token=cd322c74-e4c8-4d92-8ea3-db63a6b3edc9",
                        "fileExt": ".pdf",
                        "fileName": "4658.3090.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Recreational Program",
                    type: "dir", open: false,
                    "id": "163d6c69-cac5-47b6-8b6b-3aa684bc2b22",
                    "path": "Regulations/MN/MN Chapter 4658/Recreational Program",
                    data: [
                      {
                        "name": "4658.0900",
                        "type": "unknown",
                        "id": "efafede8-4947-40f7-a85a-4b737aea9f2e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fefafede8-4947-40f7-a85a-4b737aea9f2e.pdf?alt=media&token=826020dd-6bec-4345-a50a-630fec30600f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0900.pdf"
                      },
                      {
                        "name": "4658.0950",
                        "type": "unknown",
                        "id": "d30bab28-b274-4363-9cdc-83107fbdcdec",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd30bab28-b274-4363-9cdc-83107fbdcdec.pdf?alt=media&token=cd4ad326-0c42-4489-aaaf-ddd9b818edac",
                        "fileExt": ".pdf",
                        "fileName": "4658.0950.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Rehabilitative Services",
                    type: "dir", open: false,
                    "id": "46238016-a3a3-446e-b5fc-f2809c4030f4",
                    "path": "Regulations/MN/MN Chapter 4658/Rehabilitative Services",
                    data: [
                      {
                        "name": "4658.1200",
                        "type": "unknown",
                        "id": "cdb1b491-4179-4316-b8a7-c7208194997e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcdb1b491-4179-4316-b8a7-c7208194997e.pdf?alt=media&token=0d6e63f4-fea6-4d13-a05a-f62bd61d9c67",
                        "fileExt": ".pdf",
                        "fileName": "4658.1200.pdf"
                      },
                      {
                        "name": "4658.1900",
                        "type": "unknown",
                        "id": "99be492f-c841-48e8-8658-47801541aff8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F99be492f-c841-48e8-8658-47801541aff8.pdf?alt=media&token=01f3b978-7c4a-4573-b852-0865b2da37a8",
                        "fileExt": ".pdf",
                        "fileName": "4658.1900.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Resident Areas - Existing Construction",
                    type: "dir", open: false,
                    "id": "a674b02d-f894-4cb0-8aa2-33f599443d67",
                    "path": "Regulations/MN/MN Chapter 4658/Resident Areas - Existing Construction",
                    data: [
                      {
                        "name": "4658.5000",
                        "type": "unknown",
                        "id": "ee69a041-ce73-47ea-a3d5-1152fd99369b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fee69a041-ce73-47ea-a3d5-1152fd99369b.pdf?alt=media&token=4061ea4e-a633-4635-90a2-b7b5b3cff5e2",
                        "fileExt": ".pdf",
                        "fileName": "4658.5000.pdf"
                      },
                      {
                        "name": "4658.5005",
                        "type": "unknown",
                        "id": "8953bc91-693d-4f74-aa7b-7c06aa148aef",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8953bc91-693d-4f74-aa7b-7c06aa148aef.pdf?alt=media&token=37f20944-cabd-4f41-af0e-01467b67140c",
                        "fileExt": ".pdf",
                        "fileName": "4658.5005.pdf"
                      },
                      {
                        "name": "4658.5010",
                        "type": "unknown",
                        "id": "b3b6f5a4-c2ea-451e-8361-d9a5b03d3a1c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb3b6f5a4-c2ea-451e-8361-d9a5b03d3a1c.pdf?alt=media&token=ebfb4c05-e704-4ad6-ac0c-a1e5892f730d",
                        "fileExt": ".pdf",
                        "fileName": "4658.5010.pdf"
                      },
                      {
                        "name": "4658.5015",
                        "type": "unknown",
                        "id": "b2745c71-5604-4c1e-b1b5-f3ac262f4845",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb2745c71-5604-4c1e-b1b5-f3ac262f4845.pdf?alt=media&token=09d1f8ba-38d9-4d7e-8e1f-ac17b6fc4ade",
                        "fileExt": ".pdf",
                        "fileName": "4658.5015.pdf"
                      },
                      {
                        "name": "4658.5020",
                        "type": "unknown",
                        "id": "38992e49-b336-466f-b3cf-d195691bcc24",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F38992e49-b336-466f-b3cf-d195691bcc24.pdf?alt=media&token=19f678ed-0935-4663-b744-2d9c40aefc32",
                        "fileExt": ".pdf",
                        "fileName": "4658.5020.pdf"
                      },
                      {
                        "name": "4658.5025",
                        "type": "unknown",
                        "id": "8ff0c1c6-a98c-4f00-ae71-45dd1b6b5cbc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8ff0c1c6-a98c-4f00-ae71-45dd1b6b5cbc.pdf?alt=media&token=b4e9b7c9-f0fc-4032-8eae-abefa1291cae",
                        "fileExt": ".pdf",
                        "fileName": "4658.5025.pdf"
                      },
                      {
                        "name": "4658.5030",
                        "type": "unknown",
                        "id": "715bc7ff-8e08-4c47-ae2b-c0885ddd379f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F715bc7ff-8e08-4c47-ae2b-c0885ddd379f.pdf?alt=media&token=0b10ba93-1174-4aa5-b27c-55b9e0034470",
                        "fileExt": ".pdf",
                        "fileName": "4658.5030.pdf"
                      },
                      {
                        "name": "4658.5035",
                        "type": "unknown",
                        "id": "4cbce82e-b85b-42d8-acb8-407aff70531d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4cbce82e-b85b-42d8-acb8-407aff70531d.pdf?alt=media&token=873503ef-2706-449c-875b-bf450c41666c",
                        "fileExt": ".pdf",
                        "fileName": "4658.5035.pdf"
                      },
                      {
                        "name": "4658.5040",
                        "type": "unknown",
                        "id": "51a4c87d-684b-4338-85f3-6af8c5319a62",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F51a4c87d-684b-4338-85f3-6af8c5319a62.pdf?alt=media&token=0bf4f548-00c5-4847-bb34-950dfae3b768",
                        "fileExt": ".pdf",
                        "fileName": "4658.5040.pdf"
                      },
                      {
                        "name": "4658.5090",
                        "type": "unknown",
                        "id": "a8c457d6-c876-4df5-9edf-ed7996c503a3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa8c457d6-c876-4df5-9edf-ed7996c503a3.pdf?alt=media&token=3514fce2-d2c7-4da7-abc5-07e47f5d7e0b",
                        "fileExt": ".pdf",
                        "fileName": "4658.5090.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Resident Areas - New Construction",
                    type: "dir", open: false,
                    "id": "1c39f6d3-bb87-4cc9-997d-e7fd2af028f6",
                    "path": "Regulations/MN/MN Chapter 4658/Resident Areas - New Construction",
                    data: [
                      {
                        "name": "4658.4100",
                        "type": "unknown",
                        "id": "c60444bf-f4ee-45a0-be99-1dea15d8fbca",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc60444bf-f4ee-45a0-be99-1dea15d8fbca.pdf?alt=media&token=eb84f23e-125f-4e9d-b5dd-255b92681516",
                        "fileExt": ".pdf",
                        "fileName": "4658.4100.pdf"
                      },
                      {
                        "name": "4658.4105",
                        "type": "unknown",
                        "id": "1e31c43b-49d7-42ea-a441-302875c72653",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1e31c43b-49d7-42ea-a441-302875c72653.pdf?alt=media&token=e5f9e16b-a9eb-415f-b25f-49d3289a0f32",
                        "fileExt": ".pdf",
                        "fileName": "4658.4105.pdf"
                      },
                      {
                        "name": "4658.4110",
                        "type": "unknown",
                        "id": "867d91dd-3baa-422b-b41a-ae4d1d54d66c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F867d91dd-3baa-422b-b41a-ae4d1d54d66c.pdf?alt=media&token=3788e389-e80d-4780-a14e-2cf803fec48f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4110.pdf"
                      },
                      {
                        "name": "4658.4115",
                        "type": "unknown",
                        "id": "730cc770-61b8-4ec1-8eb8-42edab5f0f8a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F730cc770-61b8-4ec1-8eb8-42edab5f0f8a.pdf?alt=media&token=98019b9c-c8a0-4243-b104-b5c11a6c9cd5",
                        "fileExt": ".pdf",
                        "fileName": "4658.4115.pdf"
                      },
                      {
                        "name": "4658.4120",
                        "type": "unknown",
                        "id": "707366de-2bbe-4e99-95a7-055a7243a1f1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F707366de-2bbe-4e99-95a7-055a7243a1f1.pdf?alt=media&token=7536c7a5-7bf5-4be9-aab7-d6f99a612e40",
                        "fileExt": ".pdf",
                        "fileName": "4658.4120.pdf"
                      },
                      {
                        "name": "4658.4125",
                        "type": "unknown",
                        "id": "f89cb97f-26cc-4dd7-b857-31fa5fcbacc7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff89cb97f-26cc-4dd7-b857-31fa5fcbacc7.pdf?alt=media&token=7b978741-62f1-41b4-8986-9edf3c41f380",
                        "fileExt": ".pdf",
                        "fileName": "4658.4125.pdf"
                      },
                      {
                        "name": "4658.4130",
                        "type": "unknown",
                        "id": "12082761-9045-465b-88f4-c19dea0bc4de",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F12082761-9045-465b-88f4-c19dea0bc4de.pdf?alt=media&token=82693131-79c4-40f7-9531-d0c3b4cfebd4",
                        "fileExt": ".pdf",
                        "fileName": "4658.4130.pdf"
                      },
                      {
                        "name": "4658.4135",
                        "type": "unknown",
                        "id": "83b797b5-a658-476c-bb8e-4ec87e3cbb18",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F83b797b5-a658-476c-bb8e-4ec87e3cbb18.pdf?alt=media&token=e3abcf47-7b28-473f-bdde-8d735ee9f082",
                        "fileExt": ".pdf",
                        "fileName": "4658.4135.pdf"
                      },
                      {
                        "name": "4658.4140",
                        "type": "unknown",
                        "id": "dac0959a-98ae-4400-bb62-1cc5d65d6784",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdac0959a-98ae-4400-bb62-1cc5d65d6784.pdf?alt=media&token=ea69ec89-3e2c-40f0-b6a6-596a87167657",
                        "fileExt": ".pdf",
                        "fileName": "4658.4140.pdf"
                      },
                      {
                        "name": "4658.4145",
                        "type": "unknown",
                        "id": "1ee819e4-6d06-42e5-8997-4eaa488f36b1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1ee819e4-6d06-42e5-8997-4eaa488f36b1.pdf?alt=media&token=124a56d9-46c3-4d7e-ae35-a5c00bd7b7cf",
                        "fileExt": ".pdf",
                        "fileName": "4658.4145.pdf"
                      },
                      {
                        "name": "4658.4150",
                        "type": "unknown",
                        "id": "1886577d-797d-4408-a27e-9542bbccbf79",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1886577d-797d-4408-a27e-9542bbccbf79.pdf?alt=media&token=a06351af-4a3d-446d-abf3-d9f10e448c16",
                        "fileExt": ".pdf",
                        "fileName": "4658.4150.pdf"
                      },
                      {
                        "name": "4658.4155",
                        "type": "unknown",
                        "id": "c7e8f62f-8129-47fd-a307-b3fabf6d6ba3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc7e8f62f-8129-47fd-a307-b3fabf6d6ba3.pdf?alt=media&token=0a6692b6-5ad7-4f5a-8055-7fae79bfe145",
                        "fileExt": ".pdf",
                        "fileName": "4658.4155.pdf"
                      },
                      {
                        "name": "4658.4160",
                        "type": "unknown",
                        "id": "be244887-e63b-4811-9c43-44d4f6576a18",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbe244887-e63b-4811-9c43-44d4f6576a18.pdf?alt=media&token=bf8db975-2ba6-4b88-afc6-d01b682917bc",
                        "fileExt": ".pdf",
                        "fileName": "4658.4160.pdf"
                      },
                      {
                        "name": "4658.4165",
                        "type": "unknown",
                        "id": "e6461f19-6b37-46c8-a81f-437c72adfee8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe6461f19-6b37-46c8-a81f-437c72adfee8.pdf?alt=media&token=3edf189c-e891-4d47-be84-62730a9d2c89",
                        "fileExt": ".pdf",
                        "fileName": "4658.4165.pdf"
                      },
                      {
                        "name": "4658.4170",
                        "type": "unknown",
                        "id": "9cce6f83-c8c1-4a03-8a92-6ee975ebbd88",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9cce6f83-c8c1-4a03-8a92-6ee975ebbd88.pdf?alt=media&token=ab56c7d1-bfdd-4471-99e1-07e114f4e217",
                        "fileExt": ".pdf",
                        "fileName": "4658.4170.pdf"
                      },
                      {
                        "name": "4658.4175",
                        "type": "unknown",
                        "id": "04e5fd8d-c26c-4b22-9ea2-3b7fdb9ee16d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F04e5fd8d-c26c-4b22-9ea2-3b7fdb9ee16d.pdf?alt=media&token=5f105fbb-4a39-49a1-9d7d-1f36bc2027a0",
                        "fileExt": ".pdf",
                        "fileName": "4658.4175.pdf"
                      },
                      {
                        "name": "4658.4180",
                        "type": "unknown",
                        "id": "16f392f2-77c4-4685-b890-5db9790afa91",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F16f392f2-77c4-4685-b890-5db9790afa91.pdf?alt=media&token=0def00f8-8b30-4bfa-b8ac-f1385d76529e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4180.pdf"
                      },
                      {
                        "name": "4658.4190",
                        "type": "unknown",
                        "id": "d5549786-9ca0-4ccd-85f9-b3cb977077ef",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd5549786-9ca0-4ccd-85f9-b3cb977077ef.pdf?alt=media&token=bd066afc-b9d9-4d17-9134-20578f948467",
                        "fileExt": ".pdf",
                        "fileName": "4658.4190.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Resident Assessment and Plan of Care",
                    type: "dir", open: false,
                    "id": "54dbb26f-6e4f-457e-a2d7-2d052870bc3b",
                    "path": "Regulations/MN/MN Chapter 4658/Resident Assessment and Plan of Care",
                    data: [
                      {
                        "name": "4658.0400",
                        "type": "unknown",
                        "id": "6a7e79b3-e187-4be2-88c0-5667f5f245b9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6a7e79b3-e187-4be2-88c0-5667f5f245b9.pdf?alt=media&token=fbf16d46-bbe9-4afe-9935-beb657a78392",
                        "fileExt": ".pdf",
                        "fileName": "4658.0400.pdf"
                      },
                      {
                        "name": "4658.0405",
                        "type": "unknown",
                        "id": "bdd839fa-c044-45a4-b8f9-06f651f772cf",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbdd839fa-c044-45a4-b8f9-06f651f772cf.pdf?alt=media&token=20c2b387-178b-402c-98ad-ab653f69820a",
                        "fileExt": ".pdf",
                        "fileName": "4658.0405.pdf"
                      },
                      {
                        "name": "4658.0420",
                        "type": "unknown",
                        "id": "20269394-7d7b-43f7-9250-409031c32529",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F20269394-7d7b-43f7-9250-409031c32529.pdf?alt=media&token=62e733fc-0b14-4fb9-b74d-c546399e996d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0420.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Resident Personal Funds Account",
                    type: "dir", open: false,
                    "id": "ff759247-be3e-4bcf-a38e-b4c6f81ce4d5",
                    "path": "Regulations/MN/MN Chapter 4658/Resident Personal Funds Account",
                    data: [
                      {
                        "name": "4658.0250",
                        "type": "unknown",
                        "id": "d5379f20-0c88-44e2-800f-694a0a10aa57",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd5379f20-0c88-44e2-800f-694a0a10aa57.pdf?alt=media&token=f6f7d598-a52d-443f-b138-a55d1539164b",
                        "fileExt": ".pdf",
                        "fileName": "4658.0250.pdf"
                      },
                      {
                        "name": "4658.0255",
                        "type": "unknown",
                        "id": "8e376e24-85c2-456a-833d-fa77b37a5cb9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8e376e24-85c2-456a-833d-fa77b37a5cb9.pdf?alt=media&token=12ee57aa-fd1d-4f6e-b8a1-4de44a68c0ea",
                        "fileExt": ".pdf",
                        "fileName": "4658.0255.pdf"
                      },
                      {
                        "name": "4658.0260",
                        "type": "unknown",
                        "id": "f403fb0f-329c-4313-928d-84883dcf0c93",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff403fb0f-329c-4313-928d-84883dcf0c93.pdf?alt=media&token=2d9ddb53-740b-453a-a306-8ca01abfb297",
                        "fileExt": ".pdf",
                        "fileName": "4658.0260.pdf"
                      },
                      {
                        "name": "4658.0265",
                        "type": "unknown",
                        "id": "c68e26f7-7e15-45f2-bc96-110750533a68",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc68e26f7-7e15-45f2-bc96-110750533a68.pdf?alt=media&token=ed51598c-c380-4c8c-9cbc-d60a983a9b92",
                        "fileExt": ".pdf",
                        "fileName": "4658.0265.pdf"
                      },
                      {
                        "name": "4658.0270",
                        "type": "unknown",
                        "id": "70c40d8e-860a-4da6-a816-5c0ace4ffbdf",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F70c40d8e-860a-4da6-a816-5c0ace4ffbdf.pdf?alt=media&token=d3e0345b-74e1-4af0-9d51-2e2884e99def",
                        "fileExt": ".pdf",
                        "fileName": "4658.0270.pdf"
                      },
                      {
                        "name": "4658.0275",
                        "type": "unknown",
                        "id": "e1ffa5d9-c49a-4c86-b027-ea6b4a41a641",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe1ffa5d9-c49a-4c86-b027-ea6b4a41a641.pdf?alt=media&token=18f9a331-cdb4-49c5-ae2e-66bb26528482",
                        "fileExt": ".pdf",
                        "fileName": "4658.0275.pdf"
                      },
                      {
                        "name": "4658.0290",
                        "type": "unknown",
                        "id": "6871059b-e801-47c5-b7b4-3a2eac519fb3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6871059b-e801-47c5-b7b4-3a2eac519fb3.pdf?alt=media&token=3a8cd28a-9428-42f9-aef1-66896ba5db0d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0290.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Resident Rights",
                    type: "dir", open: false,
                    "id": "b413885a-8292-4ac1-8e8b-fb7a7f6be685",
                    "path": "Regulations/MN/MN Chapter 4658/Resident Rights",
                    data: [
                      {
                        "name": "4658.0200",
                        "type": "unknown",
                        "id": "e13fe841-b116-4b2a-b3af-aca7f753f1b0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe13fe841-b116-4b2a-b3af-aca7f753f1b0.pdf?alt=media&token=eba152c3-d04d-40ef-bf56-de1dc89e2c6e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0200.pdf"
                      },
                      {
                        "name": "4658.0205",
                        "type": "unknown",
                        "id": "d8da528d-71a0-42a1-a345-4bb782ed37ce",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd8da528d-71a0-42a1-a345-4bb782ed37ce.pdf?alt=media&token=b00d6e0c-dd71-41ce-8f1c-2ac3a5c2626b",
                        "fileExt": ".pdf",
                        "fileName": "4658.0205.pdf"
                      },
                      {
                        "name": "4658.0210",
                        "type": "unknown",
                        "id": "26735225-c5a3-49ef-accd-f3142ee0e51a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F26735225-c5a3-49ef-accd-f3142ee0e51a.pdf?alt=media&token=9a9bdae4-c205-4242-af81-e5336b9c2236",
                        "fileExt": ".pdf",
                        "fileName": "4658.0210.pdf"
                      },
                      {
                        "name": "4658.0215",
                        "type": "unknown",
                        "id": "d549c7a0-a2e4-4a6e-ab57-dc1821e797a2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd549c7a0-a2e4-4a6e-ab57-dc1821e797a2.pdf?alt=media&token=e9865217-98ae-4519-bf70-b95c78ef7e45",
                        "fileExt": ".pdf",
                        "fileName": "4658.0215.pdf"
                      },
                      {
                        "name": "4658.0220",
                        "type": "unknown",
                        "id": "95f06e17-2763-4329-938d-e88f4c267736",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F95f06e17-2763-4329-938d-e88f4c267736.pdf?alt=media&token=59e26d18-128f-424c-9a7b-e5b95f0dade6",
                        "fileExt": ".pdf",
                        "fileName": "4658.0220.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Restraints",
                    type: "dir", open: false,
                    "id": "21872881-c8f3-4790-874e-3933e24f8972",
                    "path": "Regulations/MN/MN Chapter 4658/Restraints",
                    data: [
                      {
                        "name": "4658.0300",
                        "type": "unknown",
                        "id": "896d0f2c-f0c0-4151-b977-f172d63a0082",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F896d0f2c-f0c0-4151-b977-f172d63a0082.pdf?alt=media&token=d1f8292c-d544-4964-99b4-46b2c9c1e3e9",
                        "fileExt": ".pdf",
                        "fileName": "4658.0300.pdf"
                      },
                      {
                        "name": "4658.0350",
                        "type": "unknown",
                        "id": "77062fbd-6a42-4e7f-9fff-7f9c244efc42",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F77062fbd-6a42-4e7f-9fff-7f9c244efc42.pdf?alt=media&token=18a0f33f-996e-43d7-af58-2302e7f0e05f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0350.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Social Services",
                    type: "dir", open: false,
                    "id": "0ce49a64-6bc7-46ee-a850-3e5ca5df5b96",
                    "path": "Regulations/MN/MN Chapter 4658/Social Services",
                    data: [
                      {
                        "name": "4658.1000",
                        "type": "unknown",
                        "id": "5dae232f-35d2-41f9-b6d7-a079fc333021",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5dae232f-35d2-41f9-b6d7-a079fc333021.pdf?alt=media&token=26dce670-bbb5-4cc7-9682-a18c0d7b14e4",
                        "fileExt": ".pdf",
                        "fileName": "4658.1000.pdf"
                      },
                      {
                        "name": "4658.1005",
                        "type": "unknown",
                        "id": "69a2fe10-41c6-4f99-a75d-ca149de3a365",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F69a2fe10-41c6-4f99-a75d-ca149de3a365.pdf?alt=media&token=24d6a975-9891-4354-be29-60ed689efd7e",
                        "fileExt": ".pdf",
                        "fileName": "4658.1005.pdf"
                      },
                      {
                        "name": "4658.1090",
                        "type": "unknown",
                        "id": "122b9afd-75b8-4dee-9024-11dba02150e7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F122b9afd-75b8-4dee-9024-11dba02150e7.pdf?alt=media&token=4c357804-b402-4ec4-a7ef-57af0616082c",
                        "fileExt": ".pdf",
                        "fileName": "4658.1090.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Specialized Units",
                    type: "dir", open: false,
                    "id": "b6374680-12a0-4cc8-a091-70b77bfc268a",
                    "path": "Regulations/MN/MN Chapter 4658/Specialized Units",
                    data: [
                      {
                        "name": "4658.2000",
                        "type": "unknown",
                        "id": "bda7935a-d5ad-4ee3-93f8-8a5f47fb8db5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbda7935a-d5ad-4ee3-93f8-8a5f47fb8db5.pdf?alt=media&token=e3d14c30-c5bb-4334-a04b-d8900f6b6669",
                        "fileExt": ".pdf",
                        "fileName": "4658.2000.pdf"
                      },
                      {
                        "name": "4658.2010",
                        "type": "unknown",
                        "id": "6c989542-40f2-4bc2-bc66-1540a64d1d5a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6c989542-40f2-4bc2-bc66-1540a64d1d5a.pdf?alt=media&token=cd2f7906-7a22-426f-832a-a257d4ffbb11",
                        "fileExt": ".pdf",
                        "fileName": "4658.2010.pdf"
                      },
                      {
                        "name": "4658.2020",
                        "type": "unknown",
                        "id": "e1e5b944-a507-4050-8e84-dd781924db03",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe1e5b944-a507-4050-8e84-dd781924db03.pdf?alt=media&token=79d5d73c-7a08-476a-82aa-e8d627882203",
                        "fileExt": ".pdf",
                        "fileName": "4658.2020.pdf"
                      },
                      {
                        "name": "4658.2030",
                        "type": "unknown",
                        "id": "332844fe-b048-4a9a-84c0-2fd4b9defa64",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F332844fe-b048-4a9a-84c0-2fd4b9defa64.pdf?alt=media&token=03174791-0806-458b-8c24-232e7fdcc23b",
                        "fileExt": ".pdf",
                        "fileName": "4658.2030.pdf"
                      },
                      {
                        "name": "4658.2090",
                        "type": "unknown",
                        "id": "7f860463-432b-49cc-8305-0d760b329f8d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7f860463-432b-49cc-8305-0d760b329f8d.pdf?alt=media&token=50acda37-e609-4d26-978d-b1e9d60a41b9",
                        "fileExt": ".pdf",
                        "fileName": "4658.2090.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Spiritual Needs",
                    type: "dir", open: false,
                    "id": "7d5b9a10-1fdb-46ad-bbe4-d4f050dbfb42",
                    "path": "Regulations/MN/MN Chapter 4658/Spiritual Needs",
                    data: [
                      {
                        "name": "4658.0960",
                        "type": "unknown",
                        "id": "ec59c2f5-8278-4cb1-b4e2-3482f376d61b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fec59c2f5-8278-4cb1-b4e2-3482f376d61b.pdf?alt=media&token=f9fc806e-f493-44b3-89a7-ad47ad2fe6a5",
                        "fileExt": ".pdf",
                        "fileName": "4658.0960.pdf"
                      },
                      {
                        "name": "4658.0990",
                        "type": "unknown",
                        "id": "5bcc7be3-cc1f-46e3-9fbd-f197d6f5fca6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5bcc7be3-cc1f-46e3-9fbd-f197d6f5fca6.pdf?alt=media&token=8233ba2b-f31c-4abe-b526-f8161dd0441e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0990.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Supportive Services - Exiting Construction",
                    type: "dir", open: false,
                    "id": "514a590f-0304-4d27-942f-c1e4d16d98c7",
                    "path": "Regulations/MN/MN Chapter 4658/Supportive Services - Exiting Construction",
                    data: [
                      {
                        "name": "4658.5100",
                        "type": "unknown",
                        "id": "d7b978a4-1075-4a08-bef0-c0b3a4bc711f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd7b978a4-1075-4a08-bef0-c0b3a4bc711f.pdf?alt=media&token=3b303c1e-4ddb-443f-98c2-726c42514375",
                        "fileExt": ".pdf",
                        "fileName": "4658.5100.pdf"
                      },
                      {
                        "name": "4658.5190",
                        "type": "unknown",
                        "id": "cac184ad-4fa8-42c3-8717-a8b578cdc83e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcac184ad-4fa8-42c3-8717-a8b578cdc83e.pdf?alt=media&token=95e48ffd-3e5b-418a-bb77-cbc444bcc03b",
                        "fileExt": ".pdf",
                        "fileName": "4658.5190.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Supportive Services - New Construction",
                    type: "dir", open: false,
                    "id": "ac7c4c46-117d-4f9f-9b60-4b6ba9a0d828",
                    "path": "Regulations/MN/MN Chapter 4658/Supportive Services - New Construction",
                    data: [
                      {
                        "name": "4658.4200",
                        "type": "unknown",
                        "id": "b7bc8841-4a3b-4e2c-a1da-3610a084c69e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb7bc8841-4a3b-4e2c-a1da-3610a084c69e.pdf?alt=media&token=d44717c6-92f0-4799-b515-83ede5187e21",
                        "fileExt": ".pdf",
                        "fileName": "4658.4200.pdf"
                      },
                      {
                        "name": "4658.4205",
                        "type": "unknown",
                        "id": "05e9eed0-9ba7-415a-b0f5-1bf62d63d13a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F05e9eed0-9ba7-415a-b0f5-1bf62d63d13a.pdf?alt=media&token=0cfb51ef-a9c4-4d38-8758-7716439da624",
                        "fileExt": ".pdf",
                        "fileName": "4658.4205.pdf"
                      },
                      {
                        "name": "4658.4210",
                        "type": "unknown",
                        "id": "0507e1fb-724e-4b99-978f-a8c85a17e610",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0507e1fb-724e-4b99-978f-a8c85a17e610.pdf?alt=media&token=a5aec936-5ee7-4eb0-a24c-8d05df5b6f76",
                        "fileExt": ".pdf",
                        "fileName": "4658.4210.pdf"
                      },
                      {
                        "name": "4658.4215",
                        "type": "unknown",
                        "id": "6268279b-df3b-42b4-ba83-5839c8322c18",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6268279b-df3b-42b4-ba83-5839c8322c18.pdf?alt=media&token=e7619327-f01c-4211-8c7b-2c55ba26284b",
                        "fileExt": ".pdf",
                        "fileName": "4658.4215.pdf"
                      },
                      {
                        "name": "4658.4290",
                        "type": "unknown",
                        "id": "cc4b00fb-7a32-48a5-8be5-fffdf3d04fc7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcc4b00fb-7a32-48a5-8be5-fffdf3d04fc7.pdf?alt=media&token=742cb452-a21f-464d-8cc9-dd4f16af5c6f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4290.pdf"
                      }
                    ]
                  }
                ]
              },
              {
                "name": "MN Chapter 6400",
                type: "dir", open: false,
                "id": "b2c6fac0-a5cf-4722-89a1-6b7824978b88",
                "path": "Regulations/MN/MN Chapter 6400",
                data: [
                  {
                    "name": "Continuing Education",
                    type: "dir", open: false,
                    "id": "447fa35f-810b-4037-a66d-8d8429d773ef",
                    "path": "Regulations/MN/MN Chapter 6400/Continuing Education",
                    data: [
                      {
                        "name": "6400.6800",
                        "type": "unknown",
                        "id": "d6ae2d93-53c3-454b-9827-8ff41e048547",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd6ae2d93-53c3-454b-9827-8ff41e048547.pdf?alt=media&token=5858a902-3183-4d61-8bb2-85823a3173b0",
                        "fileExt": ".pdf",
                        "fileName": "6400.6800.pdf"
                      },
                      {
                        "name": "6400.6850",
                        "type": "unknown",
                        "id": "baab2616-aa49-4c1a-a554-18e45c9b269b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbaab2616-aa49-4c1a-a554-18e45c9b269b.pdf?alt=media&token=290f43f2-eaed-4d3b-8426-a047dc19324a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6850.pdf"
                      },
                      {
                        "name": "6400.6870",
                        "type": "unknown",
                        "id": "71ba901c-d2da-4a79-bbaf-f3ea2fcb195e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F71ba901c-d2da-4a79-bbaf-f3ea2fcb195e.pdf?alt=media&token=c15ed893-fd8a-4829-a7eb-090e8769d096",
                        "fileExt": ".pdf",
                        "fileName": "6400.6870.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Continuing Education-2",
                    type: "dir", open: false,
                    "id": "a8dbf1cd-a1fe-4021-aa14-86ec9c834136",
                    "path": "Regulations/MN/MN Chapter 6400/Continuing Education-2",
                    data: [
                      {
                        "name": "6400.7090",
                        "type": "unknown",
                        "id": "c817633d-f6c0-4796-9634-a776f48f25c2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc817633d-f6c0-4796-9634-a776f48f25c2.pdf?alt=media&token=4c1d5036-c9bf-4205-bf52-ac15a9c3581d",
                        "fileExt": ".pdf",
                        "fileName": "6400.7090.pdf"
                      },
                      {
                        "name": "6400.7091",
                        "type": "unknown",
                        "id": "e6b98bc6-f9a3-4de1-b15c-3e21ec1c0e1a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe6b98bc6-f9a3-4de1-b15c-3e21ec1c0e1a.pdf?alt=media&token=ce6a3a73-7938-4de7-ae10-c684ca21670e",
                        "fileExt": ".pdf",
                        "fileName": "6400.7091.pdf"
                      },
                      {
                        "name": "6400.7092",
                        "type": "unknown",
                        "id": "af8337bc-58aa-48c3-a567-322e1b213531",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Faf8337bc-58aa-48c3-a567-322e1b213531.pdf?alt=media&token=2089d233-6cff-4539-86e3-a8f27137c5c0",
                        "fileExt": ".pdf",
                        "fileName": "6400.7092.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Course Requirements",
                    type: "dir", open: false,
                    "id": "0e342578-4ee7-4dd7-a91c-2ee23b8676ed",
                    "path": "Regulations/MN/MN Chapter 6400/Course Requirements",
                    data: [
                      {
                        "name": "6400.6400",
                        "type": "unknown",
                        "id": "563701f3-2728-4d86-908c-7de5ecb63a95",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F563701f3-2728-4d86-908c-7de5ecb63a95.pdf?alt=media&token=7743055e-f4d6-446e-b47c-42c263531f81",
                        "fileExt": ".pdf",
                        "fileName": "6400.6400.pdf"
                      },
                      {
                        "name": "6400.6550",
                        "type": "unknown",
                        "id": "6ac84e9e-ac65-4731-9ca1-f311463f845d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6ac84e9e-ac65-4731-9ca1-f311463f845d.pdf?alt=media&token=54cc81d1-a7dd-4f1b-8764-054c249b39c2",
                        "fileExt": ".pdf",
                        "fileName": "6400.6550.pdf"
                      },
                      {
                        "name": "6400.6560",
                        "type": "unknown",
                        "id": "7a1e01a6-9980-4fe4-b193-84fc267b72d8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7a1e01a6-9980-4fe4-b193-84fc267b72d8.pdf?alt=media&token=2b3c0c4a-aeb4-4f99-a265-0ea7b3a9172b",
                        "fileExt": ".pdf",
                        "fileName": "6400.6560.pdf"
                      },
                      {
                        "name": "6400.6570",
                        "type": "unknown",
                        "id": "15016d3a-98da-40a6-a309-1c3cba9c9fe9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F15016d3a-98da-40a6-a309-1c3cba9c9fe9.pdf?alt=media&token=176819e5-4239-4ee0-b7cc-08976e17cea9",
                        "fileExt": ".pdf",
                        "fileName": "6400.6570.pdf"
                      },
                      {
                        "name": "6400.6600",
                        "type": "unknown",
                        "id": "1f22ed09-9dd6-4b6d-ab5c-f5cc0ea50e73",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1f22ed09-9dd6-4b6d-ab5c-f5cc0ea50e73.pdf?alt=media&token=86e62e18-5df2-4fba-9b06-5789c8740520",
                        "fileExt": ".pdf",
                        "fileName": "6400.6600.pdf"
                      },
                      {
                        "name": "6400.6655",
                        "type": "unknown",
                        "id": "0636bec4-382f-4f66-9440-2eeca89e7c2c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0636bec4-382f-4f66-9440-2eeca89e7c2c.pdf?alt=media&token=baf35e3a-3cc8-4a26-8e12-853c33c5b8d8",
                        "fileExt": ".pdf",
                        "fileName": "6400.6655.pdf"
                      },
                      {
                        "name": "6400.6660",
                        "type": "unknown",
                        "id": "05f2d1ab-41c3-452e-8e2a-4bf37052a7a6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F05f2d1ab-41c3-452e-8e2a-4bf37052a7a6.pdf?alt=media&token=edaa4c0d-080f-48af-8286-404e03ade9aa",
                        "fileExt": ".pdf",
                        "fileName": "6400.6660.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Course Requirements-2",
                    type: "dir", open: false,
                    "id": "06cccd15-8813-4f83-abe3-5133461b19cb",
                    "path": "Regulations/MN/MN Chapter 6400/Course Requirements-2",
                    data: [
                      {
                        "name": "6400.7015",
                        "type": "unknown",
                        "id": "ab904d50-4766-423a-bf12-6a9e067e75f1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fab904d50-4766-423a-bf12-6a9e067e75f1.pdf?alt=media&token=54898294-02a5-425d-9cfb-af2ce6278f62",
                        "fileExt": ".pdf",
                        "fileName": "6400.7015.pdf"
                      },
                      {
                        "name": "6400.7020",
                        "type": "unknown",
                        "id": "7e1f39f3-eb63-4ef5-be20-49a4348bc8ad",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7e1f39f3-eb63-4ef5-be20-49a4348bc8ad.pdf?alt=media&token=df02a301-91cd-43dd-978d-c30cc1b6fe4c",
                        "fileExt": ".pdf",
                        "fileName": "6400.7020.pdf"
                      },
                      {
                        "name": "6400.7025",
                        "type": "unknown",
                        "id": "bc030b21-9334-4884-bc69-f178914440e4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbc030b21-9334-4884-bc69-f178914440e4.pdf?alt=media&token=fe09230a-4b60-4fae-a8f7-faf3ab3a8794",
                        "fileExt": ".pdf",
                        "fileName": "6400.7025.pdf"
                      },
                      {
                        "name": "6400.7030",
                        "type": "unknown",
                        "id": "a3b1e744-3062-4fb0-bec4-d9fc8d725e1c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa3b1e744-3062-4fb0-bec4-d9fc8d725e1c.pdf?alt=media&token=340e8e81-93e9-477b-be33-ad81cbcf4f63",
                        "fileExt": ".pdf",
                        "fileName": "6400.7030.pdf"
                      },
                      {
                        "name": "6400.7040",
                        "type": "unknown",
                        "id": "673415b6-5465-40ee-81ca-f711239d5773",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F673415b6-5465-40ee-81ca-f711239d5773.pdf?alt=media&token=c4897fab-73af-4279-9c89-52ec0b85c47c",
                        "fileExt": ".pdf",
                        "fileName": "6400.7040.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Discipline",
                    type: "dir", open: false,
                    "id": "47549337-bc4f-41ab-b647-ab15ab81d147",
                    "path": "Regulations/MN/MN Chapter 6400/Discipline",
                    data: [
                      {
                        "name": "6400.6900",
                        "type": "unknown",
                        "id": "5595f50b-597f-4504-95c0-692aab997acb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5595f50b-597f-4504-95c0-692aab997acb.pdf?alt=media&token=cf40bd01-71b0-42c8-8106-abc8017f4727",
                        "fileExt": ".pdf",
                        "fileName": "6400.6900.pdf"
                      },
                      {
                        "name": "6400.6950",
                        "type": "unknown",
                        "id": "347f8bf3-55e7-4047-b677-44ea9d66346d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F347f8bf3-55e7-4047-b677-44ea9d66346d.pdf?alt=media&token=89545fff-16c1-4a1e-948c-775f725a5e0b",
                        "fileExt": ".pdf",
                        "fileName": "6400.6950.pdf"
                      },
                      {
                        "name": "6400.7000",
                        "type": "unknown",
                        "id": "3d47e227-073a-4428-8ded-dcf5c9c93853",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3d47e227-073a-4428-8ded-dcf5c9c93853.pdf?alt=media&token=63ceb835-a4b3-4069-a549-2dfaeede4506",
                        "fileExt": ".pdf",
                        "fileName": "6400.7000.pdf"
                      },
                      {
                        "name": "6400.7005",
                        "type": "unknown",
                        "id": "c5ad9000-4049-4c17-b42d-a190a9677284",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc5ad9000-4049-4c17-b42d-a190a9677284.pdf?alt=media&token=06461e24-fc36-40d3-adbb-78e1a352f67f",
                        "fileExt": ".pdf",
                        "fileName": "6400.7005.pdf"
                      },
                      {
                        "name": "6400.7010",
                        "type": "unknown",
                        "id": "b706aca8-d6d1-4c11-b672-2ec1a6810290",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb706aca8-d6d1-4c11-b672-2ec1a6810290.pdf?alt=media&token=c9248bcb-82d9-40e1-b55b-04c4512aafb9",
                        "fileExt": ".pdf",
                        "fileName": "6400.7010.pdf"
                      }
                    ]
                  },
                  {
                    "name": "General",
                    type: "dir", open: false,
                    "id": "a2041ae0-ae2b-4a17-8a4b-b7c0dd5e4d30",
                    "path": "Regulations/MN/MN Chapter 6400/General",
                    data: [
                      {
                        "name": "6400.5000",
                        "type": "unknown",
                        "id": "f70d1ca2-5133-4799-8f6e-9516c8375cda",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff70d1ca2-5133-4799-8f6e-9516c8375cda.pdf?alt=media&token=7debdbd9-e1cd-4eea-a265-4d03825edc4d",
                        "fileExt": ".pdf",
                        "fileName": "6400.5000.pdf"
                      },
                      {
                        "name": "6400.5100",
                        "type": "unknown",
                        "id": "46d01436-b0f5-4a29-aea8-c65e4b444901",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F46d01436-b0f5-4a29-aea8-c65e4b444901.pdf?alt=media&token=4d4fa56a-ea9f-435f-ab20-26f4f09baee5",
                        "fileExt": ".pdf",
                        "fileName": "6400.5100.pdf"
                      },
                      {
                        "name": "6400.5150",
                        "type": "unknown",
                        "id": "de10ef3c-5b8a-448d-ab69-17e408873b02",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fde10ef3c-5b8a-448d-ab69-17e408873b02.pdf?alt=media&token=b906210d-1db3-4772-b137-d10181c3a187",
                        "fileExt": ".pdf",
                        "fileName": "6400.5150.pdf"
                      },
                      {
                        "name": "6400.5200",
                        "type": "unknown",
                        "id": "7b61fcf3-b12d-4d98-a958-4ae9c7ca9e2c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7b61fcf3-b12d-4d98-a958-4ae9c7ca9e2c.pdf?alt=media&token=4414a57d-459a-4c9d-910c-d14ebcba3f9a",
                        "fileExt": ".pdf",
                        "fileName": "6400.5200.pdf"
                      },
                      {
                        "name": "6400.6000",
                        "type": "unknown",
                        "id": "dc604d30-9a31-4e46-87e9-52d6fd358b70",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdc604d30-9a31-4e46-87e9-52d6fd358b70.pdf?alt=media&token=85596dd2-3391-46b5-b98b-b284caf6bb34",
                        "fileExt": ".pdf",
                        "fileName": "6400.6000.pdf"
                      },
                      {
                        "name": "6400.6100",
                        "type": "unknown",
                        "id": "c3af182c-f13d-4bab-adf7-c9cb135b2aa5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc3af182c-f13d-4bab-adf7-c9cb135b2aa5.pdf?alt=media&token=a824caf9-7981-4f2e-87b2-ce0658c824a6",
                        "fileExt": ".pdf",
                        "fileName": "6400.6100.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Licenses and Permits",
                    type: "dir", open: false,
                    "id": "d60be5d9-e0e5-43de-a0db-0364344c32d1",
                    "path": "Regulations/MN/MN Chapter 6400/Licenses and Permits",
                    data: [
                      {
                        "name": "6400.6700",
                        "type": "unknown",
                        "id": "ce3c5be0-3d26-4ee7-aef0-61f5d7ace5f1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fce3c5be0-3d26-4ee7-aef0-61f5d7ace5f1.pdf?alt=media&token=003c5e35-71db-4e52-bee5-75d351492f0a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6700.pdf"
                      },
                      {
                        "name": "6400.6710",
                        "type": "unknown",
                        "id": "05ca5dd4-814f-40b4-af3a-3b995a84104a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F05ca5dd4-814f-40b4-af3a-3b995a84104a.pdf?alt=media&token=d6f9b15e-dc2d-482e-b8ca-5586bcd1addb",
                        "fileExt": ".pdf",
                        "fileName": "6400.6710.pdf"
                      },
                      {
                        "name": "6400.6720",
                        "type": "unknown",
                        "id": "a5f134da-cb96-430d-a6db-710cff76828e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa5f134da-cb96-430d-a6db-710cff76828e.pdf?alt=media&token=9917afa0-005a-4bbe-bd4f-6377c94dfa2f",
                        "fileExt": ".pdf",
                        "fileName": "6400.6720.pdf"
                      },
                      {
                        "name": "6400.6730",
                        "type": "unknown",
                        "id": "8c5856c3-4f4c-4a9e-bdbc-9dc8b9eba394",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8c5856c3-4f4c-4a9e-bdbc-9dc8b9eba394.pdf?alt=media&token=fec2de8e-c887-4057-96dc-490d1bc40f9a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6730.pdf"
                      },
                      {
                        "name": "6400.6740",
                        "type": "unknown",
                        "id": "71634eaa-9681-44cb-ba65-3dc91b2b82c4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F71634eaa-9681-44cb-ba65-3dc91b2b82c4.pdf?alt=media&token=a922636a-4d59-48ef-95c5-ab767e87a699",
                        "fileExt": ".pdf",
                        "fileName": "6400.6740.pdf"
                      },
                      {
                        "name": "6400.6750",
                        "type": "unknown",
                        "id": "e7e7b37d-e5f7-4dd3-90d2-aa0f55dd532d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe7e7b37d-e5f7-4dd3-90d2-aa0f55dd532d.pdf?alt=media&token=e36b529c-b3dd-40a1-9566-8ed835ac400f",
                        "fileExt": ".pdf",
                        "fileName": "6400.6750.pdf"
                      },
                      {
                        "name": "6400.6760",
                        "type": "unknown",
                        "id": "6e2cda25-a683-4888-920d-6306fbe3e08c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6e2cda25-a683-4888-920d-6306fbe3e08c.pdf?alt=media&token=460dff28-c1ad-4a19-b60b-85c553406b9a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6760.pdf"
                      },
                      {
                        "name": "6400.6770",
                        "type": "unknown",
                        "id": "82d132c2-cfb8-4a11-aa0d-57aca5b33123",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F82d132c2-cfb8-4a11-aa0d-57aca5b33123.pdf?alt=media&token=38e3b1b3-f371-4ac9-aa36-840c319c778a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6770.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Licenses and Permits-2",
                    type: "dir", open: false,
                    "id": "c1bd1382-69a2-4a2b-96c5-192d3e5452cd",
                    "path": "Regulations/MN/MN Chapter 6400/Licenses and Permits-2",
                    data: [
                      {
                        "name": "6400.7045",
                        "type": "unknown",
                        "id": "49ee98ad-da2b-42f1-bfb1-5e04ceb6c81f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F49ee98ad-da2b-42f1-bfb1-5e04ceb6c81f.pdf?alt=media&token=2bdcc7fd-8edc-40b0-9283-aacc4cdb34a0",
                        "fileExt": ".pdf",
                        "fileName": "6400.7045.pdf"
                      },
                      {
                        "name": "6400.7050",
                        "type": "unknown",
                        "id": "9e935236-c791-4cb4-bd7d-551ed94a6792",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9e935236-c791-4cb4-bd7d-551ed94a6792.pdf?alt=media&token=b51085b7-4ec0-4d37-8f2f-01e83af82781",
                        "fileExt": ".pdf",
                        "fileName": "6400.7050.pdf"
                      },
                      {
                        "name": "6400.7055",
                        "type": "unknown",
                        "id": "12539fac-4568-44a0-87c5-3283e5cb1906",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F12539fac-4568-44a0-87c5-3283e5cb1906.pdf?alt=media&token=8c4c22b6-b961-42a7-9d4b-9b6c959c6508",
                        "fileExt": ".pdf",
                        "fileName": "6400.7055.pdf"
                      },
                      {
                        "name": "6400.7060",
                        "type": "unknown",
                        "id": "2a5607e7-4817-4325-bfdf-4660fe3cd751",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2a5607e7-4817-4325-bfdf-4660fe3cd751.pdf?alt=media&token=78059128-f684-4122-9f22-fcb9b51beabe",
                        "fileExt": ".pdf",
                        "fileName": "6400.7060.pdf"
                      },
                      {
                        "name": "6400.7065",
                        "type": "unknown",
                        "id": "7031c0d1-2c8a-4b65-88de-64665368c6ed",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7031c0d1-2c8a-4b65-88de-64665368c6ed.pdf?alt=media&token=18709752-b2f2-4094-aeeb-9e5e315af0d3",
                        "fileExt": ".pdf",
                        "fileName": "6400.7065.pdf"
                      },
                      {
                        "name": "6400.7070",
                        "type": "unknown",
                        "id": "4f208a8a-75ba-4401-a9de-0a0308b26475",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4f208a8a-75ba-4401-a9de-0a0308b26475.pdf?alt=media&token=306320d6-747c-4c76-a177-bd5c1e68d29d",
                        "fileExt": ".pdf",
                        "fileName": "6400.7070.pdf"
                      },
                      {
                        "name": "6400.7075",
                        "type": "unknown",
                        "id": "9d6e9fea-ae69-4a75-bb6b-ddd0cd0a33eb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9d6e9fea-ae69-4a75-bb6b-ddd0cd0a33eb.pdf?alt=media&token=0574ec6a-bc91-4f7a-9802-345868f46c96",
                        "fileExt": ".pdf",
                        "fileName": "6400.7075.pdf"
                      },
                      {
                        "name": "6400.7080",
                        "type": "unknown",
                        "id": "64afa3e1-86f4-4e8b-8701-d8973674b3e9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F64afa3e1-86f4-4e8b-8701-d8973674b3e9.pdf?alt=media&token=7e8dd6e9-6ef2-4d5e-a13a-b271edad8abe",
                        "fileExt": ".pdf",
                        "fileName": "6400.7080.pdf"
                      },
                      {
                        "name": "6400.7085",
                        "type": "unknown",
                        "id": "ea02f2ec-1302-46ab-9ca8-ceb9dcb514bc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fea02f2ec-1302-46ab-9ca8-ceb9dcb514bc.pdf?alt=media&token=2cd2161c-dc5a-49d4-bcb8-bd0acdb74799",
                        "fileExt": ".pdf",
                        "fileName": "6400.7085.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Standards of Practice",
                    type: "dir", open: false,
                    "id": "7cecec69-cb0d-458f-a0c1-73eb3da17d25",
                    "path": "Regulations/MN/MN Chapter 6400/Standards of Practice",
                    data: [
                      {
                        "name": "6400.7095",
                        "type": "unknown",
                        "id": "ef07daa1-493f-449c-90dc-afac17a86339",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fef07daa1-493f-449c-90dc-afac17a86339.pdf?alt=media&token=bc0cbf7b-a78e-4c5b-88f1-76e36705dc26",
                        "fileExt": ".pdf",
                        "fileName": "6400.7095.pdf"
                      }
                    ]
                  }
                ]
              }
            ]
          }   
        ]
      }
    ]
  }, 
  {
    name: "Policies and Procedures", type: "dir", open: false,
    "id": "3979863d-3322-4378-afa7-bb9146c108b9",
    "path": "Policies and Procedures",
    "storage": "PoliciesAndProcedures",
    data: [
      {
        "name": "Vulnerable Adult",
        type: "dir", open: false,
        "id": "7e029ca0-e66f-4817-bcab-007e195823ea",
        "path": "Policies and Procedures/Vulnerable Adult",
        data: [
          {
            "name": "Ackerberg Campus",
            type: "dir", open: false,
            "id": "57d1e5c0-5db4-417e-83fc-63a6d6203d7c",
            "path": "Policies and Procedures/Vulnerable Adult/Ackerberg Campus",
            data: [
              {
                "name": "Vulnerable Adult - Policy and Procedure - Ackerberg Campus",
                "type": "unknown",
                "id": "210708fd-7e35-41e4-bbdf-c41efb3bfa1d",
                "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F210708fd-7e35-41e4-bbdf-c41efb3bfa1d.pdf?alt=media&token=1d5541ec-10f4-4c7b-8d4b-8a9b8f27fde9",
                "fileExt": ".pdf",
                "fileName": "Vulnerable Adult - Policy and Procedure - Ackerberg Campus.pdf"
              }
            ]
          },
          {
            "name": "Challer Campus",
            type: "dir", open: false,
            "id": "bbc58a33-9047-4720-9f61-994c22ae5b2c",
            "path": "Policies and Procedures/Vulnerable Adult/Challer Campus",
            data: [
              {
                "name": "Vulnerable Adult - Policy and Procedure - Shaller Campus",
                "type": "unknown",
                "id": "d75a9448-54da-4ff1-b6a5-f66f9f61ee20",
                "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd75a9448-54da-4ff1-b6a5-f66f9f61ee20.pdf?alt=media&token=f67cd966-36b2-4e73-a8b9-707aed92f9b6",
                "fileExt": ".pdf",
                "fileName": "Vulnerable Adult - Policy and Procedure - Shaller Campus.pdf"
              }
            ]
          },
          {
            "name": "DRAFTV3 Vulnerable Adult Policy and Procedure 2017 5 23 Updated 4.8.22 Final",
            "type": "unknown",
            "id": "747d9cce-3dfe-4bd8-b1f4-26dd13db367c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F747d9cce-3dfe-4bd8-b1f4-26dd13db367c.pdf?alt=media&token=47a2b401-5d53-4f8a-9595-04f05ef821f6",
            "fileExt": ".pdf",
            "fileName": "DRAFTV3 Vulnerable Adult Policy and Procedure 2017 5 23 Updated 4.8.22 Final.pdf"
          }
        ]
      }
    ]
  },
]


export const ContentItems = [
  {name: 'Marketing & Residency', tag: 'TA', open: false,  data: [
    { name:"1.01 Admissions policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.01%20Admissions%20policy.pdf?alt=media&token=910a173b-35be-4df9-b90f-d53731f61711"  },
    { name:"1.02 Application and Deposit for Residency policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.02%20Application%20and%20Deposit%20for%20Residency%20policy.pdf?alt=media&token=7230ef00-6268-45fe-9537-e367263b542b"  },
    { name:"1.03 Community Fee policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.03%20%20Community%20Fee%20policy.pdf?alt=media&token=e9cf4be4-b9ab-488a-8941-f1581a411eb0"  },
    { name:"1.04 Uniform Disclosure of Assisted Living Services & Amenities  (UDALSA) policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.04%20%20Uniform%20Disclosure%20of%20Assisted%20Living%20Services%20%26%20Amenities%20(UDALSA)%20policy.pdf?alt=media&token=94dd8861-bb13-4184-826f-50adde58cd33"  },
    { name:"1.05 Signing an Assisted Living Contract policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.05%20%20Signing%20an%20Assisted%20Living%20Contract%20policy.pdf?alt=media&token=1ef6be43-49bb-4ee0-939e-511dd12f0749"  },
    { name:"1.08 Designated Representative policy ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.08%20Designated%20Representative%20policy%20Lake%20City.pdf?alt=media&token=2f48ac12-ba77-4dfd-8e4a-cd2eebc298ea"  },
    { name:"1.09 Designated Representative/ Emergency Contact form", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.09%20Contact%20Information%20Form%20(Designated%20Rep%20%26%20Emergency).pdf?alt=media&token=d1545d84-7e04-4213-9989-588b1c607785"  },
    { name:"1.11 Release form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.11%20Release%20Form.pdf?alt=media&token=74decd77-dcba-4832-8632-9da361666d98"  },
    { name:"1.12 Receipt for Key -FOB and Miscellaneous ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.12%20Receipt%20for%20Key-FOB%20and%20Miscellaneous.pdf?alt=media&token=2148ad76-b172-4f3c-9dcb-bf5a5b53b5ed"  },
    { name:"1.13 Move In-Out Inspection Report", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.13%20Move%20In-Out%20Inspection%20Report%20form.pdf?alt=media&token=63d53b68-9ec2-4805-86cd-378acd0896f3"  },
    { name:"1.14 Welcoming New Residents policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.14%20%20Welcoming%20New%20Residents%20policy.pdf?alt=media&token=1b054a07-4154-4a5f-a589-7f39720bfeeb"  },
    { name:"1.15 Contract Termination policy ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.15%20Contract%20Termination%20Policy.pdf?alt=media&token=bad35db4-50b6-4f17-a755-92e8c7ed1cdd"  },
    { name:"1.16 Pre-Termination Meeting Notice sample form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.16%20Pre-Termination%20Meeting%20Notice%20sample%20form.pdf?alt=media&token=163c6bec-9bd0-4024-87a5-053b97d7ddcf"  },
    { name:"1.17 Pre-Termination Meeting Summary sample form ", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.17%20Pre-Termination%20Meeting%20Summary%20sample%20form.pdf?alt=media&token=adf94de6-c609-414a-a428-3f157864c4d0"  },
    { name:"1.18 Resident Termination Notice sample form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.18%20ResidentTerminationNoticeSampleForm.pdf?alt=media&token=7294d583-ad9a-4710-9594-e123d3320767"  },
    { name:"1.19 Resident Relocation Evaluation sample form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.19%20Resident%20Relocation%20Evaluation%20sample%20form.pdf?alt=media&token=811cfa70-e3f5-4eb3-935f-5e316653bfc3"  },
    { name:"1.20 Resident Relocation Plan sample form ", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.20%20Resident%20Relocation%20Plan%20sample%20form.pdf?alt=media&token=04e36ef7-3b18-48a4-adc2-6ce7ab054702"  },
    { name:"1.21 Resident Discharge Summary sample form ", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.21%20Resident%20Discharge%20Summary%20sample%20form.pdf?alt=media&token=021d0c19-c50e-455b-921e-4bc4c9bb179e"  },
    { name:"1.22 Coordinated moves ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.22_CoordinatedMovespolicy.pdf?alt=media&token=e427068d-70c2-4d5d-9d8a-75d8feba539f"  },
    { name:"1.23 Emergency Relocation ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.23_EmergencyRelocationpolicy.pdf?alt=media&token=64a99fac-df57-4b14-9e42-f0d3be40dabe"  },
    { name:"1.24 Non-Renewal of Housing policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.24%20NonRenewalofHousingPolicy.pdf?alt=media&token=d4bc4e0d-6680-4c0f-999c-183bc186b20f"  },
    { name:"1.25 Resident Move-Out sample letter", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.25%20Resident%20Move-Out%20sample%20letter.pdf?alt=media&token=f7b51abd-1f18-4157-b8cb-358e0f176825"  },
    { name:"1.26 Resident Move-Out Reconciliation form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.26%20Resident%20Move-Out%20Reconciliation%20form.pdf?alt=media&token=a7e2daa4-508f-4365-b405-f58e6b3d2d35"  },
    { name:"1.27 Move-Out Checklist form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.27%20Move-Out%20Checklist%20form.pdf?alt=media&token=e6546401-bc94-4df2-9984-82e67140e7e2"  },
    { name:"1.28 Final Accounting - Return of Money and Property policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.28%20Final%20Accounting%20%E2%80%93%20Return%20of%20Money%20and%20Property%20policy.pdf?alt=media&token=8b0804a2-4cbe-46e4-a30a-eaadfba56f68"  },
    { name:"1.29 Marketing Plan template", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.29%20Marketing%20Plan%20template.pdf?alt=media&token=7e6ce1ed-7d08-4e64-83e9-dbe0642c51af"  },
    { name:"1.31 Inquiry sample form 1", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.31%20Inquiry%20Sample%20Form%201.pdf?alt=media&token=158f6f99-6d79-4eee-8f07-6d543c170436"  },
    { name:"1.32 Inquiry sample form 2", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.32%20Inquiry%20Sample%20Form%202.pdf?alt=media&token=e4160666-efb2-440e-a20a-301edb770f9c"  },
    { name:"1.33 Waiting List policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.33%20Waiting%20List%20policy.pdf?alt=media&token=73963a2a-30de-4b95-badd-324fec099b23"  },
    { name:"1.34 Activity Programming policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.34%20%20Activity%20Programming%20policy.pdf?alt=media&token=f9788d27-8bb6-4a33-baf9-72cea47dc0e1"  },
    { name:"1.35 Bulletin Board policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.35%20Bulletin%20Board%20policy.pdf?alt=media&token=4a69a68f-b0ed-4122-855e-9f7535995302"  },
    { name:"1.36 Handling of Resident Finances and Property policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.36%20Handling%20of%20Resident%20Finances%20and%20Property%20policy.pdf?alt=media&token=5b4b4d96-70be-47ff-8a7f-ecc86e2c31c8"  },
    { name:"1.37 Pet policy - non service animal policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.37%20Pet%20policy%20-%20non%20service%20animal%20policy.pdf?alt=media&token=3a3251f5-a592-48d3-97bd-b9b0953545aa"  },
    { name:"1.38 Service & Companion Animals policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.38%20Service%20%26%20Companion%20Animals%20policy.pdf?alt=media&token=7582762d-d723-44b6-8faa-9c00e747960c"  },
    { name:"1.39 Resident Right to Return policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.39%20%20Resident%20Right%20to%20Return%20policy.pdf?alt=media&token=55c6128a-8e5b-4666-893f-ecbc5badc834"  },
    { name:"1.40 Resident Sign In-Out policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.40%20Resident%20Sign%20In-Out%20policy.pdf?alt=media&token=629c20a3-748d-4424-b89f-195d5c5cfd59"  },
    { name:"1.41 Risk Agreement policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.41%20%20Risk%20Agreement%20policy.pdf?alt=media&token=76dfd0de-e173-4b34-bcd5-9a7580db38bc"  },
    { name:"1.43 Transfer of Resident within Facility policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.43_TransferofResidentwithinFacilitypolicy.pdf?alt=media&token=3a6c09f5-7291-4ad0-a17e-79b7b824c6a1"  },
    { name:"1.44 Use of Facility Common Areas policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.44%20%20Use%20of%20Facility%20Common%20Areas%20policy.pdf?alt=media&token=4e7f8c84-54ad-42d3-8efe-9890a0cc638f"  },
  ]},
  { name: 'General Policies', tag: 'SD', open: false, data: [
    { name:"2.01 24 Hour Emergency Response policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.01%20%2024%20Hour%20Emergency%20Response%20policy.pdf?alt=media&token=2456e03f-c4c4-4591-92e5-4b3a1516de7f"  },
    { name:"2.02 Acceptance of residents ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.02%20Acceptance%20of%20Resident%20policy.pdf?alt=media&token=4153352f-d166-47cb-b3c0-468208e4814c"  },
    { name:"2.03 Additional Service Referral policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.03%20Additional%20Service%20Referral%20policy.pdf?alt=media&token=d0ce3cf0-4dc2-41ed-a5d8-ab2a82c9e1b5"  },
    { name:"2.04 Assisted Living License and Posting policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.04%20Assisted%20Living%20%20License%20and%20Posting%20policy.pdf?alt=media&token=5cf0dd79-0984-49f6-9f63-0bf2f5818200"  },
    { name:"2.05 Bill of Rights policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.05%20Bill%20of%20Rights%20policy.pdf?alt=media&token=0a765e91-b712-4947-bc8f-1526c00e3e49"  },
    { name:"2.06 Billing policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.06%20Billing%20policy.pdf?alt=media&token=98f826fd-e9e1-465f-833a-1eeb82bbc8cf"  },
    { name:"2.07 Check Request form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.07%20Check%20Request%20form.pdf?alt=media&token=c9245585-cde2-47ed-88ea-d6cd4e499d0c"  },
    { name:"2.08 Communication Book policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.08%20Communication%20Book%20policy.pdf?alt=media&token=8ced1341-bc70-4262-80e9-1b4c3802a9e3"  },
    { name:"2.09 Complaint-Grievance policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.09%20Complaint-Grievance%20policy.pdf?alt=media&token=00ca866a-6a5f-4482-9502-2798b2364d25"  },
    { name:"2.10 Complaint-Grievance Posting Policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.10_Complaint-GrievancePostingpolicyv2.pdf?alt=media&token=26e8b49d-b039-44fb-ae67-42405a344973"  },
    { name:"2.11 Complaint-Grievance sample form ", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.11%20Complaint-Grievance%20sample%20form.pdf?alt=media&token=2eab3cc9-cc59-4eb3-969d-8498b2f0833a"  },
    { name:"2.12 Confidentiality policy ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.12%20Confidentiality%20policy.pdf?alt=media&token=e444cffb-5726-4484-8b7f-e4138697a941"  },
    { name:"2.13 CPR DNR policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.13%20%20CPR%20DNR%20policy.pdf?alt=media&token=8cbdc308-e714-4dfc-8d1b-cf6a20b31ddf"  },
    { name:"2.14 Death of a Resident policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.14%20%20Death%20of%20a%20Resident%20policy.pdf?alt=media&token=4633f99e-c2ab-4fa4-b1e1-4526664b32dc"  },
    { name:"2.15 Electronic monitoring policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.15%20%20%20Electronic%20Monitoring%20policy.pdf?alt=media&token=a6194ebc-231e-458e-83cd-150fadd46fc5"  },
    { name:"2.16 Electronic Monitoring Preparedness checklist", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.16%20Electronic%20Monitoring%20Preparedness%20checklist.pdf?alt=media&token=ad7fe288-027a-45a2-b080-daef7e3752e2"  },
    { name:"2.21 Emergency 911 policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.21%20Emergency%20911%20policy.pdf?alt=media&token=d209feba-61ce-43d1-997c-f896fbc4da01"  },
    { name:"2.22 Emergency Contact for Staff policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.22%20Emergency%20Contacts%20for%20Staff%20policy.pdf?alt=media&token=bb389794-855a-4245-9ec9-eb3771a7e4da"  },
    { name:"2.24 Facility restrictions policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.24%20%20Facility%20Restrictions%20policy.pdf?alt=media&token=65624b9f-e062-4264-95f6-fc8df9b00809"  },
    { name:"2.25 Incident Report policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.25%20%20Incident%20Report%20policy.pdf?alt=media&token=94ae3c31-2df3-4033-a31c-5e4e3e1d0cba"  },
    { name:"2.26 Incident Report general sample form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.26%20Incident%20Report%20general%20sample%20form.pdf?alt=media&token=f8494755-29a5-4bf2-8f50-8a96542607a7"  },
    { name:"2.28 Missing Resident policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.28%20Missing%20Resident%20policy.pdf?alt=media&token=20469133-ceed-4884-a5a1-8c2d9c43727b"  },
    { name:"2.29 On-Call Log form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.29%20On-Call%20Log%20form.pdf?alt=media&token=6c33c97f-45fe-4eaa-b6b7-c4c50b9d65c2"  },
    { name:"2.30 Protecting Resident Rights policy ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.30_ProtectingResidentRightspolicy.pdf?alt=media&token=36a175d4-5ef6-4237-946c-49f87a999a8b"  },
    { name:"2.31 Quality management ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.31%20Quality%20Management%20Project%20policy.pdf?alt=media&token=b8042168-7976-45d1-884f-82b3a91713a7"  },
    { name:"2.32 Petty Cash Monthly Tracking Sheet", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.32%20Petty%20Cash%20Monthly%20Tracking%20Sheet.pdf?alt=media&token=9267941b-840b-4cb4-8b8c-359c57138928"  },
    { name:"2.33 Request to Discontinue Life Sustaining Treatment policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.33%20Request%20to%20Discontinue%20Life-Sustaining%20Treatment%20policy.pdf?alt=media&token=2e6314aa-e5c3-4de1-9b05-d9ff64758907"  },
    { name:"2.34 Resident and Family Councils Policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.34%20Resident%20and%20Family%20Councils%20policy.pdf?alt=media&token=aad86fda-5aa6-45ef-b636-ab047d942397"  },
    { name:"2.35 Resident record - Access & Storage policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.35%20%20Resident%20Record%20%E2%80%93%20Access%20%26%20Storage%20policy.pdf?alt=media&token=fa305434-7282-421e-882e-77005db45d01"  },
    { name:"2.36 Resident Record - Confidentiality", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.36%20%20Resident%20Record%20%E2%80%93%20Confidentiality.pdf?alt=media&token=2e94554f-c96b-4bd2-bc94-87994bcf75f3"  },
    { name:"2.37 Resident Record - Documentation policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.37%20Resident%20Record%20-%20Documentation%20policy.pdf?alt=media&token=9e6d20bd-8ed3-4fcb-9476-b4ed39215c82"  },
    { name:"2.38 Resident Record - Information & Content policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.38%20%20Resident%20Record%20-%20Information%20and%20Content%20policy.pdf?alt=media&token=a7a9e627-b7d6-43c6-bdf6-c526f9d5e7ed"  },
    { name:"2.39 Resident Record - Retention policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.39%20Resident%20Record%20-%20Retention%20policy.pdf?alt=media&token=6e86cf04-218f-4801-bddf-654e237b2d2f"  },
    { name:"2.40 Resident Record - Tranfer policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.40%20Resident%20Record%20%E2%80%93%20Transfer%20of%20policy.pdf?alt=media&token=f9e5fdbc-b933-4370-ada6-8129a25e0dfc"  },
    { name:"2.41 Retaliation Prohibited policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.41_RetaliationProhibitedpolicy.pdf?alt=media&token=90b0c00f-82d4-48ba-82db-7209dcf85bc1"  },
    { name:"2.42 Right to Outside Service Provider policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.42%20Right%20to%20Outside%20Service%20Provider%20policy.pdf?alt=media&token=161aa16e-be72-48d1-b3bf-98f1153c6dc9"  },
    { name:"2.43 Video and Photography policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.43%20Video%20and%20Photography%20policy.pdf?alt=media&token=450dd35c-66e2-4668-98f1-5e311220252b"  },
    { name:"2.44 Vulnerable Adult Maltreatment - Prevention & Reporting policy ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.44%20Vulnerable%20Adult%20Maltreatment%20-%20Prevention%20%26%20Reporting%20policy.pdf?alt=media&token=07663768-ad62-49b5-a5c4-3c12eaf2b0e5"  },
    { name:"2.45 HIPAA Business Associates HIPAA policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.45%20%20HIPAA%20Business%20Associates%20policy.pdf?alt=media&token=e17d63a9-52af-4036-961a-8f60e7a2729f"  },
    { name:"2.47 HIPAA Notice of Privacy Practices Dissemination policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%202%2F2.47%20HIPAA%20Notice%20of%20Privacy%20Practices%20Dissemination%20policy.pdf?alt=media&token=71fddb54-1dfe-4780-a2b6-6cc33ea3a437"  },

  ]},
  { name: 'AL with Dementia Care Specific Policies', tag: 'NB', open: false, data: [
    { name:"3.00 ALDC Additional Dementia Care Required Policies ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.00%20%20ALDC%20Additional%20Dementia%20Care%20Required%20Policies.pdf?alt=media&token=78c51595-42a6-4277-a6eb-b3c599121d82"  },
    { name:"3.01 ALDC Additional Dementia Staff Training policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.01%20%20ALDC%20Additional%20Dementia%20Staff%20Training%20policy.pdf?alt=media&token=1d173f64-355b-42f2-9df9-f58c0e426178"  },
    { name:"3.02 ALDC Behavioral Symptoms, Interventions & Nonpharmacological Approaches policy ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.02%20ALDC%20Behavioral%20Symptoms%2C%20Interventions%20%26%20Nonpharmacological%20Approaches%20policy.pdf?alt=media&token=2a0aa503-58c6-4f94-b978-0dfeb1f7e142"  },
    { name:"3.03 ALDC Dementia Care Philosophy policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.03%20ALDC%20Dementia%20Care%20Philosophy%20policy.pdf?alt=media&token=7b3c3da8-edeb-4b57-b63e-6b9c38d291e5"  },
    { name:"3.04 ALDC Family Support policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.04%20ALDC%20Family%20Support%20policy.pdf?alt=media&token=b5f29d44-bb51-492a-969f-d0668bc189f0"  },
    { name:"3.05 ALDC Life Enrichment Programs, Activities & Outdoor Space policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.05%20%20ALDC%20Life%20Enrichment%20Programs%2C%20Activities%20%26%20Outdoor%20Space%20policy.pdf?alt=media&token=0ac10eb0-d3d2-4d31-bb45-ca20d4a607a1"  },
    { name:"3.06 ALDC Life Safety Code policy ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.06%20ALDC%20Life%20Safety%20Code%20policy.pdf?alt=media&token=74bc9b24-6567-47f4-9939-8437fb7404bb"  },
    { name:"3.07 ALDC Medication Management policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.07%20ALDC%20Medication%20Management%20policy.pdf?alt=media&token=7941148f-9dd0-4164-888c-c848d511ab8b"  },
    { name:"3.08 ALDC Notice of Dementia Training policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.08%20%20%20ALDC%20Notice%20of%20Dementia%20Training%20policy.pdf?alt=media&token=0f01354e-12ce-44dc-a742-083e6d4cb4c3"  },
    { name:"3.09 ALDC Physical Environment, Fire Protection & Staffing policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.09%20ALDC%20Physical%20Environment%2C%20Fire%20Protection%20%26%20Staffing%20policy.pdf?alt=media&token=2cf50c38-b9ee-4e86-92ce-1dbdc9c3dc91"  },
    { name:"3.10 ALDC Safekeeping of Resident Possesions", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.10%20ALDC%20Safekeeping%20of%20Resident%20Possessions%20policy.pdf?alt=media&token=5b1651a7-a3cf-4fec-b4d5-399dc83176a3"  },
    { name:"3.11 ALDC Transportation Coordination policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.11%20ALDC%20Transportation%20Coordination%20policy.pdf?alt=media&token=596b563e-e88e-4a2a-b39a-2f3733369e43"  },
    { name:"3.12 ALDC Use of Intercom policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.12%20ALDC%20Use%20of%20Intercom%20policy.pdf?alt=media&token=777cad68-50a9-4ab6-9be3-1cb8e08b1eca"  },
    { name:"3.13 ALDC Wandering and Elopment policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%203%2F3.13%20ALDC%20Wandering%20and%20Elopement%20policy.pdf?alt=media&token=4c92abd3-0f02-4331-8738-542ebcd60fa7"  },

  ]},
  { name: 'Employment', tag: '', open: false, data: [
    { name:"4.01 Assisted Living Director policy ", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.01%20Assisted%20Living%20Director%20policy.pdf?alt=media&token=a687d929-a510-44f1-93ce-66609ae645b4"  },
    { name:"4.02 Background Studies policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.02%20Background%20Studies%20policy.pdf?alt=media&token=dfaad803-4e3a-464d-9acb-6cbf7b2a4b7e"  },
    { name:"4.03 Kari Koskinen Manager Background Check policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.03%20Kari%20Koskinen%20Manager%20Background%20Check%20policy.pdf?alt=media&token=0eece852-c096-4f2b-9dbe-fa43d10018ca"  },
    { name:"4.05 Employee records policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.05%20Employee%20Records%20policy.pdf?alt=media&token=1d9db032-ebf5-4139-a76b-fb28443daa43"  },
    { name:"4.06 Staffing & Scheduling policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.06%20%20Staffing%20%26%20Scheduling%20policy.pdf?alt=media&token=26ad3eff-226d-4a1a-b0f5-fb9dde17d171"  },
    { name:"4.07 Temporary & Contracted Staff policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.07%20%20Temporary%20and%20Contracted%20Staff%20policy.pdf?alt=media&token=4b33d982-b9a0-4a20-a1b7-82989e196112"  },
    { name:"4.08 Volunteer and Contractor Records policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.08%20Volunteer%20and%20Contractor%20Records%20policy.pdf?alt=media&token=7beee5da-d3b2-479d-877e-e5e6427b5bce"  },
    { name:"4.10 Sample Interview Questions", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.10%20Sample%20Interview%20Questions.pdf?alt=media&token=666648e9-2d59-480b-90e5-735ce3d7a304"  },
    { name:"4.11 Reference Checks policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.11%20Reference%20Checks%20policy.pdf?alt=media&token=8356ba0d-844b-45d6-9ccf-ca05b86347aa"  },
    { name:"4.12 Reference Checks sample question", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.12%20Reference%20Checks%20sample%20questions.pdf?alt=media&token=e30f571d-71fc-4d52-9685-047cca5ab05e"  },
    { name:"4.13 Reference Checks Verification form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.13%20Reference%20Checks%20Verification%20form.pdf?alt=media&token=1bca083b-df97-4517-b17f-c9cf10f83517"  },
    { name:"4.14 Sample Letter - Interview Turndown", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.14%20Sample%20Letter%20-%20Interview%20Turndown.pdf?alt=media&token=f0da3731-de96-4d53-969c-87232e8b696e"  },
    { name:"4.15 Sample Letter - New Hire", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.15%20Sample%20Letter%20-%20New%20Hire.pdf?alt=media&token=5e96ac17-a8e4-43f5-89c7-a15fca198edd"  },
    { name:"4.16 Sample Letter - Not Interviewed", keywords: ['handbook'], type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.16%20Sample%20Letter%20-%20Not%20Interviewed.pdf?alt=media&token=98049ffe-2966-4a1e-b6d5-4b5201da08bc"  },
    { name:"4.17 Code of Conduct form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.17%20Code%20of%20Conduct%20form.pdf?alt=media&token=082624e1-ca6d-49b2-82fa-1f0bb9b87a79"  },
    { name:"4.18 Employee General Orientation policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.18%20Employee%20General%20Orientation%20policy.pdf?alt=media&token=c539db04-7b26-4b2a-b3de-a1f7b7cdee35"  },
    { name:"4.19 Employee Orientation Checklist form ", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.19%20Employee%20Orientation%20checklist%20form.pdf?alt=media&token=576ea20a-e6e2-45da-98dc-e160e1e9065c"  },
    { name:"4.20 Employee Change of Status form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.20%20Employee%20Change%20of%20Status%20Form.pdf?alt=media&token=d20e0f00-e8b7-4d2c-8e7a-550350450328"  },
    { name:"4.21 Job Description - Assistted Living Director", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.21%20Job%20Description%20-%20Assisted%20Living%20Director.pdf?alt=media&token=89d3c78f-a7aa-4fba-b210-a67c0fef3320"  },
    { name:"4.22 Job Description - Senior Housing Assistant Manager", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.22%20Job%20Description%20-%20Senior%20Housing%20Assistant%20Manager.pdf?alt=media&token=425be2ec-2442-4c1a-8cf3-ac0b65b710bc"  },
    { name:"4.25 Job Description - Care & Wellness Director", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.25%20Job%20Description%20-%20Care%20and%20Wellness%20Director.pdf?alt=media&token=3ee8bd89-7dcf-4a67-b845-91cfa50df604"  },
    { name:"4.26 Job Description - Care & Wellness Nurse", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.26%20Job%20Description%20-%20Care%20and%20Wellness%20Nurse.pdf?alt=media&token=e9573159-0bd6-487a-80f9-0fc914578c6a"  },
    { name:"4.27 Job Description - Care & Wellness Coordinator", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.27%20Job%20Description%20-%20Care%20and%20Wellness%20Coordinator.pdf?alt=media&token=83062311-328f-497e-8d3b-71d034c292d2"  },
    { name:"4.28 Job Description - Care & Wellness Assistant", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.28%20Job%20Description%20-%20Care%20and%20Wellness%20Assistant.pdf?alt=media&token=19d44757-6f9a-48d8-bba7-bf17a5bfa581"  },
    { name:"4.29 Job Description - Lifestyle Enrichment Coordinator", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.29%20Job%20Description%20-%20Lifestyle%20Enrichment%20Coordinator.pdf?alt=media&token=38d1ebe7-84e6-4c9c-b344-777cf067af33"  },
    { name:"4.30 Job Description - Building & Grounds Asssitant", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.30%20Job%20Description%20-%20Building%20and%20Grounds%20Assistant.pdf?alt=media&token=8dd8e08c-36a1-4c58-bce0-fb61672f6eac"  },
    { name:"4.31 Job Description - Housing Assistant", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.31%20Job%20Description%20-%20Housing%20Assistant.pdf?alt=media&token=881df05e-c578-4504-89d3-be0cf6fd5255"  },
    { name:"4.32 Job Description - Culinary Services Manager or Lead Culinarian", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.32%20Job%20Description-Culinary%20Services%20Manager%20or%20Lead%20Culinarian.pdf?alt=media&token=346badbf-f1d3-475c-a4c6-3ce04db0cd73"  },
    { name:"4.33 Job Description - Culinarian", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.33%20Job%20Description%20-%20Culinarian.pdf?alt=media&token=810da796-5923-4b13-9a29-3b854f5b046a"  },
    { name:"4.34a Job Description - Culinary Services Assistant", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.34a%20Job%20Description%20-%20Culinary%20Service%20Assistant.pdf?alt=media&token=ea24c61d-faca-4ae6-a307-11f0a894dd46"  },
    { name:"4.34b Job Description - Culinary Services Lead", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.34b%20Job%20Description%20-%20Culinary%20Service%20Lead.pdf?alt=media&token=38fc01c5-fbb0-4502-972c-49dee3f6ecf5"  },
    { name:"4.35 Employee Evaluation policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.35%20Employee%20Evlauation%20policy.pdf?alt=media&token=e658ba34-fc8e-42df-aa01-d2ac058c1b8f"  },
    { name:"4.43 Corrective Action policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.43%20Corrective%20Action%20policy.pdf?alt=media&token=061b0b00-92b4-4ddf-a061-77886910defd"  },
    { name:"4.44 Documentation of Performance Discussion", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.44%20Documentation%20of%20Performance%20Discussion.pdf?alt=media&token=a9114a31-5585-4ec0-93c1-5e18c1cce435"  },
    { name:"4.48 Attendance policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.48%20Attendance%20policy.pdf?alt=media&token=bd7b6e80-b456-4acd-9248-89d127c47f3b"  },
    { name:"4.49 Break and Mealtime policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.49%20Break%20%26%20Mealtime%20policy.pdf?alt=media&token=53b2ae83-0a7e-49ba-a303-aae5e72ba327"  },
    { name:"4.50 Calling-In Sick policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.50%20Calling-In%20Sick%20policy.pdf?alt=media&token=41f96ad8-a09e-4a18-8d9a-fa489d34666a"  },
    { name:"4.51 Cell Phones and Personal Calls policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.51%20Cell%20Phones%20and%20Personal%20Calls%20policy.pdf?alt=media&token=63d1cc7d-b271-4313-887d-2b4c5cc5162a"  },
    { name:"4.52 Gifts and Donations policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.52%20Gifts%20and%20Donations%20policy.pdf?alt=media&token=41d4b702-1e3a-4fc6-9c12-80750cf7833c"  },
    { name:"4.53 Holidays policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.53%20%20Holidays%20policy.pdf?alt=media&token=4b217f93-2844-493b-9cb4-8150d5a39769"  },
    { name:"4.55 Key and Property Log form", type:"form", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.55%20Key%20%26%20Property%20Log%20form.pdf?alt=media&token=4a3d7f34-8b7a-44a9-b451-a1346d4fbe0a"  },
    { name:"4.56 Non-Solicitation policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.56%20Non-Solicitation%20policy.pdf?alt=media&token=09a1cfbb-e5c2-4b5a-aaa9-fb5ffe47ac10"  },
    { name:"4.57 Overtime policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.57%20Overtime%20policy.pdf?alt=media&token=e70baae5-7255-4723-bd09-86240c896d85"  },
    { name:"4.59 Smoking-Tobacco Use policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.59%20Smoking-Tobacco%20Use%20policy.pdf?alt=media&token=ffaa26f3-8bfc-4b0f-9e7e-4e2c897a7ca1"  },
    { name:"4.60 Social Media policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.60%20Social%20Media%20policy.pdf?alt=media&token=66d3491d-14a2-46e8-9078-2e37885703fc"  },
    { name:"4.61 Time and Attendance policy", keywords: ['time off', 'pto', 'leave of absence'], filter: ['time off', 'pto', 'leave of absence'], type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.61%20Time%20%26%20Attendance%20policy.pdf?alt=media&token=c88503d1-62d1-45f7-b517-ce521705f436"  },
    { name:"4.62 Timecard Review policy", type:"policy", filter: ['time off', 'pto', 'leave of absence'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.62%20Timecards%20Review%20policy.pdf?alt=media&token=8c0d12c2-932d-4a78-a25a-61674b93a0bf"  },
    { name:"4.65 Sample Letter - Termination", type:"policy", filter: ['time off', 'pto', 'leave of absence'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.65%20Sample%20Letter%20-%20Termination.pdf?alt=media&token=1102e497-5efc-4e9b-b4d8-4ba764071adb"  },
  ]},

  { name: 'Orientation & Training', tag: '', open: false, data: [
    { name:"5.01 Orientation of Staff and Supervisiors & Content policy", filter:['training'], type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.01%20%20Orientation%20of%20Staff%20and%20Supervisors%20%26%20Content%20policy.pdf?alt=media&token=31217258-daca-4854-b6c5-27460d5b9295"  },
    { name:"5.02 Competency Training Evaluations policy", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.02%20Competency%20Training%20Evaluations%20policy.pdf?alt=media&token=0d121db2-385e-4606-bbff-b28888066bbf"  },
    { name:"5.03 Dementia Training policy", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.03%20Dementia%20Training%20policy.pdf?alt=media&token=792f91dc-9c6d-4dc1-b424-90b1ad8d5048"  },
    { name:"5.04 Assisted Living Licensed Facilities Resource Manual", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.04%20Assisted%20Living%20Licensed%20Facilities%20Resource%20Manual.pdf?alt=media&token=33eec8c3-c928-49d4-9bb9-7348b8de5f7d"  },
    { name:"5.04 Assisted Living with Dementia Care License Resource Manual", type:"policy", filter:['training'],link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.04%20Assisted%20Living%20with%20Dementia%20Care%20License%20Resource%20Manual.pdf?alt=media&token=b268b645-9098-4a40-bf62-44bf7c53a5b6"  },
    { name:"5.05 HCBS Required Training sample form", type:"form", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.05%20HCBS%20Required%20Training%20%20form.pdf?alt=media&token=a632d4b7-d52f-49ee-bf8b-39d7b0569507"  },
    { name:"5.06 Annual Required Staff Training policy", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.06%20Annual%20Required%20Staff%20Training%20policy.pdf?alt=media&token=cadbeccf-2a85-424f-8715-626c86cd39d9"  },
    { name:"5.07 Competency Retraining Evaluations policy", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.07%20Competency%20Retraining%20Evaluations%20policy.pdf?alt=media&token=be19c32f-e0c0-4500-81eb-fbdc145bbaa9"  },
    { name:"5.08 Instructor & Competency Evaluation Requirements policy", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.08%20Instructor%20%26%20Competency%20Evaluation%20Requirements%20policy.pdf?alt=media&token=1813594a-0ffe-48f3-b890-78bb65bc264a"  },
    { name:"5.09 Portability of Competency Training Records policy", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.09%20%20Portability%20of%20Competency%20Training%20Records%20policy.pdf?alt=media&token=b7b971ef-66d4-435e-9976-6e072e8aa532"  },
    { name:"5.10 Training Records policy", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.10%20%20Training%20Records%20policy.pdf?alt=media&token=4d3b665f-cf69-47c0-bae7-b9c69045a7a3"  },
    { name:"5.11 Staff Supervision", type:"policy", filter:['training'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%205%2F5.11%20Staff%20Supervision.pdf?alt=media&token=ad0617ec-2514-450d-b3f3-9e7b3615536e"  },
  ]},
  { name: 'Nursing', tag: '', open: false, data: [
    { name:"6.01 Assessments, Reviews & Monitoring policy ", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.01_AssessmentsReviewsMonitoringpolicy.pdf?alt=media&token=69a9d1f2-3639-456f-8230-e04ea6eb5653"  },
    { name:"6.02 Assessment Schedule grid", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.02%20Assessment%20Schedules%20grid.pdf?alt=media&token=b3674737-2c5a-4fcf-aadd-4717af808756"  },
    { name:"6.03 Uniform Assessment Tool policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.03%20Uniform%20Assessment%20Tool%20policy.pdf?alt=media&token=daf40f85-07f4-448f-af1f-c6bb586c3b73"  },
    { name:"6.05 Individual Abuse Prevention Plan policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.05%20Individual%20Abuse%20Prevention%20Plan%20policy.pdf?alt=media&token=4e1e31a0-cd87-4fbf-ac01-0e83003188e2"  },
    { name:"6.07 Service Plan - Temporary policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.07%20%20Service%20Plan%20%E2%80%93%20Temporary%20%20policy.pdf?alt=media&token=4aca1884-7a32-4d67-ac5f-937e891a30d9"  },
    { name:"6.08 Service Plan policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.08_ServicePlanpolicy.pdf?alt=media&token=ec590735-7efd-4107-95bb-973107b256de"  },
    { name:"6.10 Service Plan - Modifications policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.10%20Service%20Plan%20Modification%20policy.pdf?alt=media&token=da47a964-7f23-41fc-8f1b-fd3bd2f4ae93"  },
    { name:"6.12 Availability of RN for Staff policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.12%20Availability%20of%20an%20RN%20for%20Staff%20policy.pdf?alt=media&token=769c1407-7e67-479a-9294-9c92f13253e1"  },
    { name:"6.13 Clinical Nurse Supervisor policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.13%20Clinical%20Nurse%20Supervisor%20policy.pdf?alt=media&token=d41ea6c8-d83d-4898-ab2b-24c6f8384f3b"  },
    { name:"6.15 Staffing Requirments - Licensed Nurse & ULP policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.15%20Staffing%20Requirements%20%E2%80%93%20Licensed%20Nurse%20%26%20ULP%20policy.pdf?alt=media&token=37b2cc8e-67a4-4f66-9db7-b91d3261ff70"  },
    { name:"6.16 Supervision of Nurses and Licensed Health Professionals policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.16%20Supervision%20of%20Nurses%20and%20Licensed%20Health%20Professionals%20policy.pdf?alt=media&token=29034842-f633-4b4e-bb34-db0d6dc3a12f"  },
    { name:"6.17 Supervision of Staff - Delegated Services policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.17%20Supervision%20of%20Staff%20%E2%80%93%20Delegated%20Services%20policy.pdf?alt=media&token=d28561f2-939a-433c-8820-75b85662c11f"  },
    { name:"6.18 Supervision of Staff - Non Delegated Services policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.18%20Supervision%20of%20Staff%20%E2%80%93%20Non-Delegated%20Services%20policy.pdf?alt=media&token=b8163c74-74c9-4a1c-ac35-b3d93e819e5e"  },
    { name:"6.19 Resident Change in Condition or Need policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.19%20%20Resident%20Change%20in%20Condition%20or%20Need%20policy.pdf?alt=media&token=f0db373a-2fd0-4443-be29-377e7388eab1"  },
    { name:"6.21 Bathing Assistance policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.21%20Bathing%20Assistance%20policy.pdf?alt=media&token=7b73b809-f53b-4cd6-924f-d13aa7a7dc09"  },
    { name:"6.22 Catheter Care policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.22%20Catheter%20Care%20policy.pdf?alt=media&token=71d4e0e1-e977-48d2-99f4-be59eb3a6fa0"  },
    { name:"6.23 Denture Care policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.23%20Denture%20Care%20policy.pdf?alt=media&token=026a7a9a-e304-4b63-9878-d52ba472c30c"  },
    { name:"6.24 Dressing Assistance policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.24%20Dressing%20Assistance%20policy.pdf?alt=media&token=5db6be9c-ad7f-48d3-a576-1ad2d73424ae"  },
    { name:"6.25 Hospice and Dialysis policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.25%20Hospice%20and%20Dialysis%20policy.pdf?alt=media&token=5c94db0e-befb-49df-bc3d-2888aeae1f29"  },
    { name:"6.26 Mouth Care policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.26%20%20Mouth%20Care%20policy.pdf?alt=media&token=53c6b55b-aa53-43ce-b0f5-0cad141e3d56"  },
    { name:"6.27 Nail Care policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.27%20Nail%20Care%20policy.pdf?alt=media&token=40dbf209-6edb-4550-8490-2b780a5f77cf"  },
    { name:"6.28 Side rails policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.28%20Side%20rails%20policy%20updste%20march%2022.pdf?alt=media&token=b77196f2-45a5-4051-bba8-6bdf240468d8"  },
    { name:"6.29 Toileting Assistance policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.29%20Toileting%20Assistance%20policy.pdf?alt=media&token=5f22996a-8d13-4887-817c-47eea8bbad70"  },
    { name:"6.30 Support Stockings TED Hose policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%206%2F6.30%20Support%20Stockings%20TED%20Hose%20policy.pdf?alt=media&token=0002c251-2a71-423a-b0f2-b14ea2f8aa9d"  },

  ]},
  { name: 'Medications & Treatments', tag: '', open: false, data: [
    { name:"7.00 Medication Management Services Required Policies", keywords: ['test'], type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.00%20Medication%20Management%20Services%20Required%20Policies.pdf?alt=media&token=8a503225-b468-49a5-9869-7e402023ab50"  },
    { name:"7.001 Treatment & Therapy Management Services Required Policies", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.001%20%20Treatment%20%26%20Therapy%20Management%20Services%20Required%20Policies.pdf?alt=media&token=49251a65-f813-4908-8e67-2f313f38f97c"  },
    { name:"7.01 Medication Management - Assessment, Monitoring & Reassessment policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.01%20Medication%20Management%20%E2%80%93%20Assessment%2C%20Monitoring%20%26%20Reassessment%20policy.pdf?alt=media&token=f7fec476-7ba5-40f5-a01d-43d18d5dc310"  },
    { name:"7.03 Medication Management Individulalized Plan policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.03%20Medication%20Management%20Individualized%20Plan%20policy.pdf?alt=media&token=04d51331-c440-4c10-934c-1bd144c25e71"  },
    { name:"7.05 Treatment & Therapy Management Plan policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.05%20Treatment%20%26%20Therapy%20Management%20Plan%20policy.pdf?alt=media&token=778795d8-45fd-48d7-aa41-d41c43b562c2"  },
    { name:"7.07 Medication Loss or Spillage policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.07%20Medication%20Loss%20or%20Spillage%20policy.pdf?alt=media&token=8224df56-867d-43b8-aafd-49b292f75a1b"  },
    { name:"7.08 Medication Management - Administration & Setup policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.08%20Medication%20Management%20-%20Administration%20%26%20Setup%20policy.pdf?alt=media&token=f5ee68b7-fbfc-4524-ab3a-82c55d66098d"  },
    { name:"7.09 Medication Management - Dosage Box Setup policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.09%20Medication%20Management%20%E2%80%93%20Dosage%20Box%20Setup%20policy.pdf?alt=media&token=04af6576-fe32-43c8-85ba-ab9a4d0a4ee4"  },
    { name:"7.10 Medication Management - Planned & Unplanned Time Away policy", keywords: ['time off'], type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.10%20Medication%20Management%20%E2%80%93%20%20Planned%20%26%20Unplanned%20Time%20Away%20policy.pdf?alt=media&token=81ee7f98-bc75-49ea-b34a-1315f7232efa"  },
    { name:"7.11 Medication Storage policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.11%20Medication%20Storage%20policy.pdf?alt=media&token=67b56c54-a809-4e0f-9ca6-c784881ba987"  },
    { name:"7.12 Medications - Prescribed, Not Prescribed & OTC policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.12%20Medications%20%E2%80%93%20Prescribed%2C%20Not%20Prescribed%20%26%20OTC%20policy.pdf?alt=media&token=309955b0-b54a-4da6-9ac2-fb6a8677aa28"  },
    { name:"7.13 Medications - Prescription Drugs & Prohibitions policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.13%20%20Medications%20%E2%80%93%20Prescription%20Drugs%20%26%20Prohibition%20policy.pdf?alt=media&token=0e91c1d2-b7e6-42b5-96b0-880cb560effb"  },
    { name:"7.14 Medications - Provided by Resident or Others policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.14%20%20Medications%20-%20Provided%20by%20Resident%20or%20Others%20policy.pdf?alt=media&token=7d2f5d2e-f02d-427a-aed6-5e178e56f7fc"  },
    { name:"7.15 Medication & Treatment - Administration & Delegation policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.15%20Medication%20%26%20Treatment%20Administration%20%26%20Delegation%20policy.pdf?alt=media&token=523650a0-179b-4b51-9a37-a740c6c5d276"  },
    { name:"7.16 Medication & Treatment Orders - Implementing policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.16%20Medication%20%26%20Treatment%20Orders%20-%20Implementing%20policy.pdf?alt=media&token=001e020c-9ad6-4c19-8aa1-a3d74b4ff39c"  },
    { name:"7.17 Medication & Treatment Orders - Receiving policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.17%20Medication%20%26%20Treatment%20Orders%20-%20Receiving%20policy.pdf?alt=media&token=9bdd7bdb-da85-416b-854e-24d3cbf2a17f"  },
    { name:"7.18 Medication & Treatment Orders - Renewal policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.18%20Medication%20%26%20Treatment%20Orders%20-%20Renewal%20policy.pdf?alt=media&token=82efe40f-404a-4746-8679-cb66c5e4af7f"  },
    { name:"7.19 Medication & Supplies - Reordering policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.19%20Medication%20%26%20Supplies%20-%20Reordering%20policy.pdf?alt=media&token=bf5f92f8-cfe2-48c0-ab68-2827c7b6626f"  },
    { name:"7.20 Medication & Treatment Orders policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.20%20Medication%20%26%20Treatment%20Orders%20policy.pdf?alt=media&token=9014cc41-e937-46f0-bf2f-8022b720ad64"  },
    { name:"7.23 Medication Disposal policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.23%20Medication%20Disposal%20policy.pdf?alt=media&token=c96b2348-c623-4be5-acce-98d347ba58fc"  },
    { name:"7.24 Medication Error policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.24%20Medication%20Error%20policy.pdf?alt=media&token=2e72f619-765d-4a46-89e3-67c769959fe5"  },
    { name:"7.26 Narcotic Log policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.26%20Narcotic%20Log%20policy.pdf?alt=media&token=43010005-6ee8-4397-8c2d-ceac3df9ca49"  },
    { name:"7.27 PRN Medication policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.27%20PRN%20Medication%20policy.pdf?alt=media&token=c5ea82ec-f1a6-474d-af61-d0d715e6dcb6"  },
    { name:"7.30 Medical Cannabis policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.30%20Medical%20Cannabis%20policy.pdf?alt=media&token=779159fc-dc5e-45c7-b85f-d2d026cba225"  },
    { name:"7.31 Blood Sugar Testing—Single Equipment Use policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.31%20Blood%20Sugar%20Testing%20-%20Single%20Equipment%20Use%20policy.pdf?alt=media&token=502f4c91-ba28-4a46-bf01-a5232f0d05c0"  },
    { name:"7.32 Blood Sugar Testing—Shared Equipment Use policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.32%20Blood%20Sugar%20Testing%20-%20Shared%20Equipment%20Use%20policy.pdf?alt=media&token=23aeac01-74fe-4969-8498-4ae2a2ea49df"  },
    { name:"7.33 Ear Drops policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.33%20Ear%20Drops%20policy.pdf?alt=media&token=9a396518-9c91-47b0-b84f-e5083637d4a5"  },
    { name:"7.34 Eye Drops & Ointment policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.34%20%20Eye%20Drops%20%26%20Ointment%20policy.pdf?alt=media&token=1c99453f-71a7-48c7-8265-3d92e5f82682"  },
    { name:"7.35 Inhaler policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.35%20Inhaler%20policy.pdf?alt=media&token=792299ff-d220-4507-8701-361affda10c6"  },
    { name:"7.36 Insulin policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.36%20%20Insulin%20policy.pdf?alt=media&token=7b8b6d67-5137-457b-bb5c-36f64567fca1"  },
    { name:"7.37 Nebulizer Treatment policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.37%20%20Nebulizer%20Treatment%20policy.pdf?alt=media&token=7f329f07-2eca-4d62-8085-e03c48b14e7f"  },
    { name:"7.38 Nitro Patch Administration policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.38%20%20Nitro%20Patch%20Administration%20policy.pdf?alt=media&token=236c97c7-0670-4fda-a675-073f2ab7cef4"  },
    { name:"7.39 Nose Drops & Nasal Spray policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.39%20Nose%20Drops%20%26%20Nasal%20Spray%20policy.pdf?alt=media&token=a0fec459-fee2-47dd-a140-49b6502a5c12"  },
    { name:"7.40 Oxygen policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.40%20Oxygen%20policy.pdf?alt=media&token=d218ada1-3ba2-44d3-9589-3804d47adb57"  },
    { name:"7.41 Rectal Medication policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.41%20Rectal%20Medication%20policy.pdf?alt=media&token=1b7de7b3-5047-414e-881d-2f9f994f64ba"  },

  ]},

  { name: 'Infection Control', tag: '', open: false, data: [
    { name:"8.01 Infection Control policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.01%20Infection%20Control%20policy.pdf?alt=media&token=96fc1e1e-2b0d-4a39-a79c-af17ba3f6eb8"  },
    { name:"8.02 Bloodborne Pathogens policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.02%20Bloodborne%20Pathogens%20policy.pdf?alt=media&token=82b51d94-5557-4ffe-ab6d-13fe0d83375c"  },
    { name:"8.03 Cleaning of Shared Medication Equipment policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.03%20Cleaning%20of%20Shared%20Medical%20Equipment%20policy.pdf?alt=media&token=2842fbb4-8175-440c-9e94-9330548f6ddd"  },
    { name:"8.04 Communicable Diseases policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.04%20%20Communicable%20Diseases%20policy.pdf?alt=media&token=54394ed9-e176-4dcb-aa08-dca0f1bcb9bf"  },
    { name:"8.05 Disinfecting Environmental Surfaces policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.05%20%20Disinfecting%20Environmental%20Surfaces%20policy.pdf?alt=media&token=8f2e455f-6eb2-4a93-8173-3ebdcdb6ebe8"  },
    { name:"8.06 Disposal of Contaminated Materials policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.06%20Disposal%20of%20Contaminated%20Materials%20policy.pdf?alt=media&token=241fbb8c-ad33-4221-a2be-c9a477ab8058"  },
    { name:"8.07 Gloves policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.07%20Gloves%20policy.pdf?alt=media&token=53102ad1-9850-4465-9af4-26bd495b47e9"  },
    { name:"8.08 Gowns policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.08%20%20Gowns%20policy.pdf?alt=media&token=3998ad4e-7667-4e1d-b138-51a6321dd41f"  },
    { name:"8.09 Hand washing policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.09%20Hand%20washing%20policy.pdf?alt=media&token=18a42814-b403-44cf-932c-e9864bfd4604"  },
    { name:"8.10 Hepatitis B Vaccination & Post-Exposure Evaluation policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.10%20Hepatitis%20B%20Vaccination%20%26%20Post-Exposure%20Evaluation%20policy.pdf?alt=media&token=5b9dd7fa-7792-4789-a5c7-5698444fbd00"  },
    { name:"8.11 Hepatitis B Vaccination Consent sample form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.11%20Hepatitis%20B%20Vaccination%20Consent%20sample%20form.pdf?alt=media&token=14fad884-fb65-453c-a0f6-6b935ab58d56"  },
    { name:"8.12 Masks policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.12%20Masks%20policy.pdf?alt=media&token=b9537333-411d-43c4-82ef-88a964167ba5"  },
    { name:"8.13 Pathogen Exposure Control Plan policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.13%20Pathogen%20Exposure%20Control%20Plan%20policy.pdf?alt=media&token=37b942b5-cbd6-4ebe-a73e-4744661a622b"  },
    { name:"8.14 Standard Precautions policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.14%20Standard%20Precautions%20policy.pdf?alt=media&token=8223d7b2-d020-4b02-9a52-1c6503619c17"  },
    { name:"8.15 Tuberculosis Screening form", type:"form",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.15%20Tuberculosis%20Screening%20form.pdf?alt=media&token=32e14227-31ba-46c0-8290-9a0df82b2776"  },
    { name:"8.16 Tuberculosis Screening policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.16%20Tuberculosis%20Screening%20policy.pdf?alt=media&token=418342b6-c2d3-40f7-87fa-f049d625a920"  },
    { name:"8.17 Tuberculosis Skin Testing Protocol", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%208%2F8.17%20Tuberculosis%20Skin%20Testing%20Protocol.pdf?alt=media&token=033c76db-f0ab-4d7e-acaa-46367ad115cc"  },

  ]},

  { name: 'Emergency Preparedness', tag: '', open: false, data: [
    { name:"9.01 Emergency Preparedness Plan - Appendix Z Compliance policy", keyword: ['emergency'], type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.01%20Emergency%20Preparedness%20Plan%20%E2%80%93%20Appendix%20Z%20Compliance%20policy.pdf?alt=media&token=5b360016-f34e-40ef-b261-7955ee1b1014"  },
    { name:"9.02 Disaster Planning and Emergency Preparedness policy", keyword: ['emergency'], type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.02%20Disaster%20Planning%20and%20Emergency%20Preparedness%20policy.pdf?alt=media&token=375d0be7-f442-427d-8ba8-6fb0c8d486c6"  },
    { name:"9.03 Physical Plant Requirements policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.03%20Physical%20Plant%20Requirements%20policy.pdf?alt=media&token=43dedb62-9f82-4fc8-b5b1-d9a468df2ff3"  },
    { name:"9.04 Bomb Threat policy", type:"policy", keyword: ['bomb'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.04%20Bomb%20Threat%20policy.pdf?alt=media&token=b19ff2be-bf47-4fb1-9e93-908e80dc691c"  },
    { name:"9.05 FGI Residental Care Code policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.05%20%20FGI%20Residental%20Care%20Code%20policy.pdf?alt=media&token=f517e4d7-3fb6-47ed-a818-9b2264fb4e06"  },
    { name:"9.06 Fire policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.06%20Elysian%20Fire%20policy.pdf?alt=media&token=0ddbd9f1-be31-40fa-af54-58616924f275"  },
    { name:"9.07 Fire Safety, Evacuation Plan, Fire Dirlls policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.07%20Fire%20Safety%2C%20Evacuation%20Plan%2C%20Fire%20Drills%20policy.pdf?alt=media&token=4d18828c-4455-440a-8bdf-ec88724db9ba"  },
    { name:"9.08 Heat and Humidity policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.08%20Heat%20and%20Humidity%20policy.pdf?alt=media&token=534d8da0-85e5-41dd-8293-0ba136b5b912"  },
    { name:"9.09 Life safety code policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.09%20%20Life%20Safety%20Code%20policy.pdf?alt=media&token=188225b2-1013-4ba4-b087-cb494658893e"  },
    { name:"9.10 Severe Weather policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.10%20%20Severe%20Weather%20policy.pdf?alt=media&token=e2350eca-87d0-47ba-ac21-7dbdc4fc2e2e"  },
    { name:"9.11 Water Shortage policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.11%20Water%20Shortage%20policy.pdf?alt=media&token=0efb468d-ad20-4740-b0cb-7f4d713e32cf"  },
    { name:"9.12 Winter Storms policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%209%2F9.12%20Winter%20Storms%20policy.pdf?alt=media&token=73c22a67-46fe-4514-b802-755edb477c5d"  },
  ]},
  { name: 'Maintenance & Housekeeping', tag: '', open: false, data: [
    { name:"10.01 Housekeeping & Laundry Service policy", type:"policy", link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2010%2F10.01%20Elysian%20Housekeeping%20and%20Laundry%20Service%20policy.pdf?alt=media&token=2c591ee1-7db3-498b-ba80-734b3817504b" },
    
  ]},
  { name: 'OSHA', tag: '', open: false, data: [
    { name:"11.01 AWAIR policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2011%2F11.01%20AWAIR%20policy.pdf?alt=media&token=fcc63af4-0d3f-452c-bca3-26981224aaed"  },
    { name:"11.03 Employee Right to Know policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2011%2F11.03%20Elysian%20Employee%20Right%20to%20Know%20policy.pdf?alt=media&token=22bea9c6-ca15-4bb3-9d0e-6e7abe0503cb"  },
    { name:"11.04 Employee Right to Know model program MN OSHA 2016", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2011%2F11.04%20Employee%20Right%20to%20Know%20model%20program%20MN%20OSHA%202016.pdf?alt=media&token=cf3af6df-8c70-43db-b959-c16f91f56fb0"  },
    { name:"11.07 OSHA Online Resources", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2011%2F11.07%20OSHA%20Online%20Resources.pdf?alt=media&token=5874820e-edad-4390-9c13-acfabcbfff3e"  },

  ]},
  { name: 'Food Service', tag: '', open: false, data: [
    { name:"12.01 Food Service and Menu Planning policy", keywords: ['food'], type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2012%2F12.01%20Food%20Service%20%26%20Menu%20Planning%20policy.pdf?alt=media&token=d9e75015-7efc-46c1-9407-03ac0de7585e"  },
    { name:"12.02 Dining Service policy", type:"policy", keywords: ['food'],  link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2012%2F12.02%20%20Dining%20Service%20policy.pdf?alt=media&token=f433b7d3-3690-4604-94c0-848e45f12905"  },
    { name:"12.03 Guest Meal policy", type:"policy", keywords: ['food'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2012%2F12.03%20Guest%20Meal%20policy.pdf?alt=media&token=5f337373-ef8f-448f-8251-922185033656"  },
    { name:"12.04 Meal Delivery policy", type:"policy", keywords: ['food'], link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2012%2F12.04%20%20Meal%20Delivery%20policy.pdf?alt=media&token=dd6cf970-f34e-4f72-a453-4b21bb79e9e7"  },
  ]},
]


export const SholomRegulations = [
  {name: 'Life Safety', tag: 'TA', open: true,  data: [
      { name:"CMS 2012 Life Safety Code", type:"form", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fuploads%2Fbrightsource%2Ftemp%2FCMS%202012%20Life%20Safety%20Code.pdf?alt=media&token=08a8394a-142b-4681-b3fe-5aef1022013e" },
      { name:"State Ops Manual Appendix 1 - Life Safety", type:"reg", link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fuploads%2Fbrightsource%2Ftemp%2FState%20Ops%20Manual%20Appendix%201%20-%20Life%20Safety.pdf?alt=media&token=dd288ce5-c809-4d7b-a3e9-6f60ddb99078" },
    ]
  }, 
  {name: 'MN 144A', tag: 'TA', open: false,  data: [
      { name:"Board of Executives for Long Term Services and Supports", type:"dir", open: false, data: [
        { name:"144A.19 BOARD OF EXECUTIVES FOR LONG TERM SERVICES AND SUPPORTS", type:"reg", fileName:'144A.19.pdf' },
        { name:"144A.20 ADMINISTRATOR QUALIFICATIONS", type:"reg", fileName:'144A.20.pdf' },
        { name:"144A.21 ADMINISTRATOR AND DIRECTOR LICENSES", type:"reg", fileName:'144A.21.pdf' },
        { name:"144A.22 ORGANIZATION OF BOARD", type:"reg", fileName:'144A.22.pdf' },
        { name:"144A.23 JURISDICTION OF BOARD", type:"reg", fileName:'144A.23.pdf' },
        { name:"144A.24 DUTIES OF THE BOARD", type:"reg", fileName:'144A.24.pdf' },
        { name:"144A.26 RECIPROCITY WITH OTHER STATES AND EQUIVALENCY OF HEALTH SERVICES​ EXECUTIVE", type:"reg", fileName:'144A.26.pdf' },
        { name:"144A.27 ACTING ADMINISTRATORS", type:"reg", fileName:'144A.27.pdf' },
        { name:"144A.28 SEVERABILITY", type:"reg", fileName:'144A.28.pdf' },
        { name:"144A.251 MANDATORY PROCEEDINGS", type:"reg", fileName:'144A.251.pdf' },
        { name:"144A.252 IMMUNITY", type:"reg", fileName:'144A.252.pdf' },
        { name:"144A.291 FEES", type:"reg", fileName:'144A.291.pdf' },
        { name:"144A.2511 COSTS; PENALTIES", type:"reg", fileName:'144A.2511.pdf' },
      ]},
      { name:"Health Care Facility Grievances", type:"dir", open: false, data: [
        { name:"144A.51 DEFINITIONS", type:"reg" },
        { name:"144A.52 OFFICE OF HEALTH FACILITY COMPLAINTS", type:"reg" },
        { name:"144A.53 DIRECTOR; POWERS AND DUTIES", type:"reg" },
        { name:"144A.54 PUBLICATION OF RECOMMENDATIONS; REPORTS", type:"reg" },
      ]},
      { name:"Home and Community-Based Services Designation", type:"dir", open: false, data: [
        { name:"144A.484 INTEGRATED LICENSURE; HOME AND COMMUNITY-BASED SERVICES​ DESIGNATION", type:"reg" }
      ]},
      { name:"Home Care Licensing", type:"dir", open: false, data: [
        { name:"TODO", type:"reg" },
      ]},
      { name:"Home Care Program", type:"dir", open: false, data: [
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
      ]},
      { name:"Hospice Care Licensing", type:"dir", open: false, data: [
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
      ]},
      
      { name:"Nursing Assistants", type:"dir", open: false, data: [
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
      ]},
      { name:"Nursing Homes and Home Care", type:"dir", open: false, data: [
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
      ]},
      { name:"Nursing Homes; Long Term Care Services Report", type:"dir", open: false, data: [
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
      ]},
      { name:"Supplemental Nursing Services Agency", type:"dir", open: false, data: [
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
        { name:"TODO", type:"reg" },
      ]},
    ]
  }, 

  {name: 'MN Chapter 4658', tag: 'TA', open: false,  data: [
    { name:"Administration and Operations", type:"dir", open: false, data: [
      { name:"4658.0050 LICENSEE", type:"reg" },
      { name:"4658.0055 ADMINISTRATOR", type:"reg" },
      { name:"4658.0060 RESPONSIBILITIES OF ADMINISTRATOR", type:"reg" },
      { name:"4658.0065 RESIDENT SAFETY AND DISASTER PLANNING", type:"reg" },
      { name:"4658.0070 QUALITY ASSESSMENT ANDASSURANCECOMMITTEE", type:"reg" },
      { name:"4658.0075 OUTSIDE RESOURCES", type:"reg" },
      { name:"4658.0085 NOTIFICATION OF CHANGEINRESIDENTHEALTHSTATUS", type:"reg" },
      { name:"4658.0090 USE OF OXYGEN", type:"reg" },
      { name:"4658.0095 AVAILABILITY OF LICENSING RULES", type:"reg" },
      { name:"4658.0100 EMPLOYEE ORIENTATION ANDIN-SERVICEEDUCATION", type:"reg" },
      { name:"4658.0105 COMPETENCY", type:"reg" },      
      { name:"4658.0110 INCIDENT AND ACCIDENT REPORTING", type:"reg" },
      { name:"4658.0115 WORK PERIOD", type:"reg" },
      { name:"4658.0120 EMPLOYEE POLICIES", type:"reg" },
      { name:"4658.0125 PERSONAL BELONGINGS", type:"reg" },
      { name:"4658.0130 EMPLOYEES' PERSONNEL RECORDS", type:"reg" },
      { name:"4658.0135 POLICY RECORDS", type:"reg" },
      { name:"4658.0140 TYPE OF ADMISSIONS", type:"reg" },
      { name:"4658.0145 AGREEMENT AS TO RATESANDCHARGES", type:"reg" },
      { name:"4658.0145 AGREEMENT AS TO RATESANDCHARGES", type:"reg" },
      { name:"4658.0150 INSPECTION BY DEPARTMENT", type:"reg" },
      { name:"4658.0155 REPORTS TO DEPARTMENT", type:"reg" },
    ]},
    { name:"Barber and Beauty Shop Services", type:"dir", open: false, data: [
      { name:"4658.1100 BARBER AND BEAUTY SHOP SERVICES", type:"reg" },
      { name:"4658.1190 PENALTIES FOR BARBER AND BEAUTY SHOP SERVICES RULE VIOLATIONS", type:"reg" },
    ]},
    { name:"Clinical Records", type:"dir", open: false, data: [
      { name:"4658.0430 HEALTH INFORMATION MANAGEMENTSERVICE", type:"reg" },
    ]},
    { name:"Construction Details", type:"dir", open: false, data: [
      { name:"4658.4400 AREA HEAT PROTECTION; NEWCONSTRUCTION", type:"reg" },
    ]},
    { name:"Construction Details - Existing Construction", type:"dir", open: false, data: [
      { name:" 4658.5300 AREA HEAT PROTECTION; EXISTING CONSTRUCTION", type:"reg" },
    ]},
    { name:"Dietary Laundry and Other Facilities - Existing Construction", type:"dir", open: false, data: [
      { name:" 4658.5200 FOOD SERVICE EQUIPMENT; EXISTING CONSTRUCTION", type:"reg" },
    ]},
    { name:"Dietary Laundry and Other Facilities - New Construction", type:"dir", open: false, data: [
      { name:" 4658.4300 KITCHEN AREA; NEW CONSTRUCTION", type:"reg" },
    ]},
    { name:"Dietary Service", type:"dir", open: false, data: [
      { name:" 4658.0600 DIETARY SERVICE", type:"reg" },
    ]},
    { name:"Electrical Systems - Existing Construction", type:"dir", open: false, data: [
      { name:" 4658.5500 DISTRIBUTION PANEL BOARDS; EXISTING CONSTRUCTION", type:"reg" },
    ]},
    { name:"Electrical Systems - New Construction", type:"dir", open: false, data: [
      { name:" 4658.4600 DISTRIBUTION PANEL BOARDS; NEW CONSTRUCTION", type:"reg" },
    ]},
    { name:"Environmental Services", type:"dir", open: false, data: [
      { name:" 4658.1400 PHYSICAL ENVIRONMENT", type:"reg" },
    ]},
    { name:"Infection Control", type:"dir", open: false, data: [
      { name:" 4658.0800 INFECTION CONTROL", type:"reg" },
    ]},
    { name:"Licensing", type:"dir", open: false, data: [
      { name:" 4658.0010 DEFINITIONS", type:"reg" },
    ]},
    { name:"Mechanical Systems - Existing Construction", type:"dir", open: false, data: [
      { name:" 4658.5400 HEATING SYSTEM; EXISTING CONSTRUCTION", type:"reg" },
    ]},
    { name:"Mechanical Systems - New Construction", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Medical and Dental Services", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Medications", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"New Construction - Plans", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Nursing Services", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Penalties", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Physical Plant Licensure - Existing and New", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Recreational Program", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Rehabilitative Services", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Resident Areas - Existing Construction", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Resident Areas - New Construction", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Resident Assessment and Plan of Care", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Resident Personal Funds Account", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Resident Rights", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Restraints", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Social Services", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Specialized Units", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Spiritual Needs", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Supportive Services - Exiting Construction", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Supportive Services - New Construction", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]}
  ]},

  {name: 'MN Chapter 6400', tag: 'TA', open: false,  data: [
    { name:"Continuing Education", type:"dir", open: false, data: [
      { name:"6400.6800 CONTINUING EDUCATION REQUIREMENTS", type:"reg", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fuploads%2Fbrightsource%2Ftemp%2FMN%20Chapter%206400%2FContinuing%20Education%2F6400.6800.pdf?alt=media&token=e67852bf-4e6b-4043-98c0-791eca00c95c" },
      { name:"6400.6850 NUMBER OF CE CREDITS FOR ACTIVITIES", type:"reg", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fuploads%2Fbrightsource%2Ftemp%2FMN%20Chapter%206400%2FContinuing%20Education%2F6400.6850.pdf?alt=media&token=f685c824-4b67-4ac5-a547-70d87a5e5348" },
      { name:"6400.6870 SPONSORING CONTINUING EDUCATION", type:"reg", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fuploads%2Fbrightsource%2Ftemp%2FMN%20Chapter%206400%2FContinuing%20Education%2F6400.6870.pdf?alt=media&token=af86fd21-3c1d-423b-8006-097e91566902" }
    ]},
    { name:"Continuing Education-2", type:"dir", open: false, data: [
      { name:"6400.7090 CONTINUING EDUCATION REQUIREMENTS", type:"reg", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fuploads%2Fbrightsource%2Ftemp%2FMN%20Chapter%206400%2FContinuing%20Education-2%2F6400.7090.pdf?alt=media&token=849605db-afd7-41f3-9abb-55dbbb0677f7" },
      { name:"6400.7091 NUMBER OF CE CREDITS FOR ACTIVITIES", type:"reg", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fuploads%2Fbrightsource%2Ftemp%2FMN%20Chapter%206400%2FContinuing%20Education-2%2F6400.7091.pdf?alt=media&token=01d02855-a91b-4f57-a2ad-b07d12dbc8f0" },
      { name:"6400.7092 SPONSORING CONTINUING EDUCATION", type:"reg", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fuploads%2Fbrightsource%2Ftemp%2FMN%20Chapter%206400%2FContinuing%20Education-2%2F6400.7092.pdf?alt=media&token=9ab1df2c-101e-44fc-b756-2317f23b3660" },
    ]},
    { name:"Course Requirements", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Course Requirements-2", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Discipline", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"General", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Licenses and Permits", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Licenses and Permits-2", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
    { name:"Standards of Practice", type:"dir", open: false, data: [
      { name:"TODO", type:"reg" },
    ]},
  ]},
]

export const RegulationItems = [
  {name: 'Minnesota Regulations ', tag: 'TA', open: true,  data: [
      { name:"Assisted Living Statutes 144G", type:"reg" },
      { name:"Assisted Living Rules 4659", type:"reg" },
    ]
  }
]

/* KEY TERMS:
  time off ['days off', 'personal time', 'leave of absence', 'sick leave', 'vacation', 'pto'], 
  food,  training
  meal time
*/
/* KEY TERMS:
  time off ['days off', 'personal time', 'leave of absence', 'sick leave', 'vacation', 'pto'], 
   ostomy care, handbook, blood glucose,, move in, emergency, hand washing, tb, job description
*/

let searchRewrites = [
  { term: 'mealtime', varations: ['meal time', 'food'], question: 'what are the procedures for mealtime', searchDocs: true, searchAnswers: true }
]

let keyTerms = [
  {primary: 'time off', secondary: ['days off', 'personal time', 'leave of absence', 'sick leave', 'vacation', 'pto'], docs: {
    primary: [
      { name:"4.61 Time and Attendance policy", type:"policy", link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%204%2F4.61%20Time%20%26%20Attendance%20policy.pdf?alt=media&token=c88503d1-62d1-45f7-b517-ce521705f436"},      
      { name:"7.10 Medication Management - Planned & Unplanned Time Away policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%207%2F7.10%20Medication%20Management%20%E2%80%93%20%20Planned%20%26%20Unplanned%20Time%20Away%20policy.pdf?alt=media&token=81ee7f98-bc75-49ea-b34a-1315f7232efa"  },
    ],
    secondary: []}
  },
  {primary: 'food', secondary: '', docs: {
    primary: [
      { name:"12.01 Food Service and Menu Planning policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2012%2F12.01%20Food%20Service%20%26%20Menu%20Planning%20policy.pdf?alt=media&token=d9e75015-7efc-46c1-9407-03ac0de7585e"  },
      { name:"12.02 Dining Service policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2012%2F12.02%20%20Dining%20Service%20policy.pdf?alt=media&token=f433b7d3-3690-4604-94c0-848e45f12905"  },
      { name:"12.03 Guest Meal policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2012%2F12.03%20Guest%20Meal%20policy.pdf?alt=media&token=5f337373-ef8f-448f-8251-922185033656"  },
      { name:"12.04 Meal Delivery policy", type:"policy",link:"https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%2012%2F12.04%20%20Meal%20Delivery%20policy.pdf?alt=media&token=dd6cf970-f34e-4f72-a453-4b21bb79e9e7"  },      
    ], 
    secondary: []}
  },
  {primary: 'ostomy care', secondary: '', docs: {
    primary: [

    ], 
    secondary: []}
  },
  {primary: 'handbook', secondary: '', docs: {
    primary: [

    ], 
    secondary: []}},
  {primary: 'blood', secondary: '', docs: {primary: [], secondary: []}},
  {primary: 'blood glucose', secondary: '', docs: {primary: [], secondary: []}},
  {primary: 'training', secondary: '', docs: {primary: [], secondary: []}},
  {primary: 'move in', secondary: '', docs: {primary: [], secondary: []}},
  {primary: 'emergency', secondary: '', docs: {primary: [], secondary: []}},
  {primary: 'hand washing', secondary: '', docs: {primary: [], secondary: []}},
  {primary: 'job description', secondary: '', docs: {primary: [], secondary: []}},
  {primary: 'tb', secondary: 'tuberculosis', docs: {primary: [], secondary: []}},
  {primary: 'policy', secondary: 'procedure', docs: {primary: [], secondary: []}, answer: 'Please see the Directory Tab for a list of Policies, Procedures and Forms.'},

]



