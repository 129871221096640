export const lengthConfig = {
  name: 'length-converter',
  description: 'Convert between different length units',
  units: ['inches', 'centimeters', 'feet', 'meters'],
  aliases: {
    'in': 'inches',
    'cm': 'centimeters',
    'ft': 'feet',
    'm': 'meters'
  },
  convert: (value, fromUnit, toUnit) => {
    const factors = {
      inches: {
        centimeters: 2.54,
        feet: 0.0833333,
        meters: 0.0254
      },
      centimeters: {
        inches: 0.393701,
        feet: 0.0328084,
        meters: 0.01
      },
      feet: {
        inches: 12,
        centimeters: 30.48,
        meters: 0.3048
      },
      meters: {
        inches: 39.3701,
        centimeters: 100,
        feet: 3.28084
      }
    };

    if (fromUnit === toUnit) {
      return {
        convertedValue: Number(value.toFixed(2)),
        formula: `${value} ${fromUnit} = ${value} ${toUnit}`
      };
    }

    const factor = factors[fromUnit][toUnit];
    const result = value * factor;

    return {
      convertedValue: Number(result.toFixed(2)),
      formula: `${value} ${fromUnit} × ${factor} = ${result.toFixed(2)} ${toUnit}`
    };
  }
};
