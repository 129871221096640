import { firestore as db } from 'src/app/auth/Firebase';
import { collection, getDocs, getDoc, doc, setDoc, updateDoc, addDoc, query, where, onSnapshot, Timestamp} from 'firebase/firestore';
import { config } from "dotenv"; 
config();

const CONTAINER_SURVEY = 'Surveys';

export async function saveSurvey(email, surveyName, data, query) {

  let now = new Date();
  
  let prefix = now.getFullYear() + "-" + now.getMonth() + "-" +  now.getDay() + "_" + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds();
  let docId = `${prefix}_${surveyName}_${email}`;

  let docData = {
    email,
    surveyName, 
    data
  }

  docData.createdOn = new Date();
  
  if(query != null){
    docData.query = JSON.stringify(query);
  }

  try {
    await setDoc(doc(db, CONTAINER_SURVEY, docId), docData);
  }
  catch(ex){
    console.log(`Failed to save Submit Survey API Call. ${ex.message}`)
  }

  
  return;
}