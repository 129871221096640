import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { motion } from 'framer-motion';

import ContactAvatar from './panels/ContactAvatar';
import Stepper from './Stepper';
//import LumberjackContext from './context';


import {getRequest} from './db';

let SECTION_TITLE = 'Referrals';

function Header(props) {

  let selectedContact  = {};
  //const { request, showReferralsPanel, setShowReferralsPanel } = useContext(LumberjackContext);
  const [request, setRequest] = useState();
  const showReferralsPanel = false;
  let scope = '';

  let pathname = window.location.pathname.toLowerCase();
  
  useEffect(() => {

    if(pathname.indexOf('/lumberjack/new') >= 0){
      setRequest(null);
    }
  }, [pathname])

  if(pathname.indexOf('/lumberjack/new') >= 0){
    scope = 'Upload New';
    SECTION_TITLE = "Referral";    
  }
  else if(pathname.indexOf('/lumberjack/') >= 0){
    
    if(pathname.indexOf('clinical') >= 0){
      scope = 'Clinical';
      
    }
    else if(pathname.indexOf('financial') >= 0){
      scope = 'Financial';
    }
    
    pathname = pathname.replace('/lumberjack/clinical/', '').replace('/lumberjack/clinical', '');
    pathname = pathname.replace('/lumberjack/financial/', '').replace('/lumberjack/financial', '');
    
    if(pathname == ''){

    }else {      
      if(request == null){
        getRequest(pathname).then(data => {          
          setRequest(data);
        })                        
      }else {
        if(request.id != pathname){
          getRequest(pathname).then(data => {
            setRequest(data);
          })
        }
      }      
    }
    
  }

  return (
    <Toolbar className="flex flex-1 justify-left px-16 w-full">
      <div className="flex ">

        <div
          className="flex  cursor-pointer"
          onClick={() => {
            // setContactSidebarOpen(true);
          }}
          // onKeyDown={() => setContactSidebarOpen(true)}
          role="button"
          tabIndex={0}
        >
          {request?.PatientName != null && 
            <ContactAvatar className="relative mx-8" contact={request} />
          }
          <div className="pl-14">
              <div className="flex flex-col ">
                  <Typography
                      component={motion.span}
                      initial={{ x: -20 }}
                      animate={{ x: 0, transition: { delay: 0.3 } }}
                      delay={300}
                      className="text-24 md:text-24 font-bold tracking-tight leading-none"
                  >
                    {(request?.PatientName == null)? SECTION_TITLE : request?.PatientName}
                  </Typography>
              </div>
              <div className="flex flex-col">
                  <Typography
                      component={motion.span}
                      initial={{ y: -20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1, transition: { delay: 0.4 } }}
                      delay={500}
                      className="text-14 font-medium "
                      color="text.secondary"
                  >
                    {scope}
                  </Typography>
              </div>
          </div>
        </div>
      </div>
      <Box className="flex-1" style={{maxWidth : '550px', width: '65%', marginLeft: '30px'}}>
      {request?.PatientName != null && 
        <Stepper />
      }
      </Box>
    </Toolbar>
  )
}

/*
<Typography color="inherit" className="text-16 font-semibold px-4">
            {request?.PatientName}
          </Typography>
*/
export default Header;