import saveUserActivity from "./UserActivityAPI";
import ACT from "./UserActivities";
import { v4 as uuid} from 'uuid';
/*
    1.  [x] Define Event Categories
    2.  [x] Determine how to differentiate cached login vs email vs phone
    3.  [x] Update setInterval to fire every 10 seconds
    4.  [x] Get Org from API if missing
    5.  [x] Get timer to work for limit
    6.  [x] Get timer to work for time elapsed
    7.  [x] Update flushCount to 10
    8.  [x] Write log to API -> MongoDB
    9.  [x] Hook UserActivity into App State
    10. [ ] Add logging to app
    11. [x] Build Activity Log API
    12. [x] Deploy Activity Log API
    13. [] Update Reporting 
    14. [] Deploy Lumberjack integration 
    15. [x] Setup MongoDB Atlas
    16. [x] Add MongoDB Connection to Cloud Run
    17. [] Secure MongoDB Atlas
    18. [x] Connect API to MongoDB Atlas
    19. [] Handle Admin Role
    20. [x] Share Logging Events with Team
    21. [ ] Incorporate team recommended logs
    22. [ ] Add AL Regs for MN
    23. [ ] Fix createUserInOrg to sendEmail to All and create contact if it doesn't exist in HubSpot
    24. [x] Critical for long term performance: Set organization_id on Company in orgSync
    25. [x] Associate user to org in hubspot so we can see affiliation 
    26. [ ] Make sure Mongo Orgs match Firebase since you made manual updates
    27. [x] Lift User state up
    28. [x] Lift Org state up
    29. [x] Capture Browser's Address Change and Flush before
    30. [x] Capture Browser's Tab Close and Flush before
    31. [x] Capture Browser's Page Close and Flush before
    33. [x] Capture Browser Close and Flush before
    34. [ ] 
    35. 

    // Other
    1. [] Fix Duplicate FastPass User
*/

/*
    The User Activity Tracker is used to submit user activities to our API in bulk 
    to limit the number of network calls made. It send them after one of three conditions are met:
      1. The log entries equal flushAtLogCount 
      2. Time elapsed past secondsToFlush
      3. The browser or tab is closed
*/
const sessionInactivityThreshold = 60000; // in milliseconds so 60 seconds


class UserActivity {

  #log = [];
  #env = '';
  #startTime = 0;
  #elapsedTime = 0;
  #flushTimer = null;
  #sessionTimer = null;
  #elapsedTimeCheck = 10; // check Elapsed Time after (in seconds)
  #trackingSession = false;
  #lastSessionActivityTime = null;
  #sessionActive = false;

  constructor() {
    this.correlationId = uuid();
    this.env = this.#getEnv();
    this.autoFlush = true;
    this.flushAtLogCount = 3;
    this.secondsToFlush = 30; // (in seconds) 1 minute
  }

  setContext(user, org){

    this.user = user;
    this.org = org;
    this.#startSessionTracking();
  }

  #startSessionTracking() {

    // 3 ways to track
    // https://dev.to/amersikira/top-3-ways-to-easily-detect-when-a-user-leaves-a-page-using-javascript-2ce4

    // https://www.google.com/search?q=javascript+how+to+determine+if+a+page+goes+inactive&rlz=1C1RXQR_enUS1124US1125&oq=javascript+how+to+determine+if+a+page+goes+inactive&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQIRigATIHCAIQIRigATIHCAMQIRigATIHCAQQIRifBTIHCAUQIRifBTIHCAYQIRifBTIHCAcQIRiPAtIBCTEyNDIwajBqNKgCALACAA&sourceid=chrome&ie=UTF-8

    //if(this.user && this.org) {
      if(this.user) {

      if(this.#trackingSession ) return;
        this.#trackingSession = true;                

      // page close
      let activityTracker = this;
      window.onbeforeunload = (event) => {          
        // let test = activityTracker.#log;  
        activityTracker.log(ACT.SESSION.LEFT); // log session paused
        activityTracker.#flush();

        event.preventDefault(); 
        event.returnValue = 'Save changes?'; // Chrome requires returnValue to be set

      }
      
      // page innactive
      this.#lastSessionActivityTime = Date.now();
      document.addEventListener("mousemove", this.#updateSessionTracking.bind(this));
      document.addEventListener("keydown", this.#updateSessionTracking.bind(this));

      this.#sessionTimer = setInterval(() => {
        
        if (Date.now() - this.#lastSessionActivityTime > sessionInactivityThreshold) {          
          this.#pauseSessionTracker();
        }
      }, 1000); // Check every second  
      
    }
  }

  #pauseSessionTracker() {      
    if(!this.#sessionActive) {
      this.#sessionActive = true;
      this.#lastSessionActivityTime = Date.now();               
      this.log(ACT.SESSION.PAUSED); // log session paused
      this.#flush();
    }      
  }

  #restartSessionTracker() {
    if(this.#sessionActive){
      this.#sessionActive = false;
      this.#lastSessionActivityTime = Date.now();        
      this.log(ACT.SESSION.RESTART); // log session restarted
      this.#flush();
    }      
  }

  #updateSessionTracking() {
    if(this.#sessionActive) {
      this.#restartSessionTracker();
    }
    this.#lastSessionActivityTime = Date.now();
  }
    
  #getEnv() {

    if(this.#env != '') { return this.#env };

    let origin = window.location.origin;

    if(origin.indexOf('app.dovaxis.com') > -1) {
      this.#env = 'PROD';
    }
    else if(origin.indexOf('dev.dovaxis.com') > -1) {
      this.#env = 'DEV';
    }
    else if(origin.indexOf('localhost') > -1 || origin.indexOf('localhost') > -1){
      this.#env = 'LOCAL_DEV';
    }
    
  }

  log(act, actDetails={}){

    // validate
    if(act == null || act == ''){
        console.log('Missing value for required "act" param. Activity will not be recorded.');            
        return;
    }
    if(act?.name == null || act.name == ''){
        console.log('Activity must have a name. Activity will not be recorded.');            
        return;
    }
    
    if(!this.user){
        console.log(`Activity ${act.name} cannot be logged without an associated user. Activity will not be recorded.`);            
        return;
    }
    /*
    if(!this.org){
        console.log(`Activity ${act.name} cannot be logged without an associated org. Activity will not be recorded.`);            
        return;
    }
    */
    if(act?.category == null || act.category == ''){
        console.log(`Activity ${act.name}  must have a category. Activity will not be recorded.`);            
        return;
    }
    if(act?.eventName == null || act.eventName == ''){
        console.log(`Activity ${act.name}  must have a eventName. Activity will not be recorded.`);            
        return;
    }
    
    let data = {
        correlationId: this.correlationId,
        env: this.#env,
        name: act.name,
        category: act.category,
        eventName: act.eventName,
        timestamp: new Date().toISOString(),
        details: actDetails
    }    

    // set User Details
    if(this.user) {
        let userId = this.user.id;
        let userEmail = this.user.email || this.user.data.Email;
        let userDisplayName = this.user.displayName || this.user.data.DisplayName;
        if(userId) data.userId = userId;
        if(userEmail) data.userEmail = userEmail;
        if(userDisplayName) data.userDisplayName = userDisplayName; 
    }

    // set Org Details
    if(this.org){
      if(this.org?.id) data.orgId = this.org?.id;
      if(this.org?.key) data.orgKey = this.org?.key;
    }

    // cache act
    this.#log.push(data); 

    // record 
    this.#log.push(data); 
    //console.log(' ');
    //console.log(`ACTIVITY EVENT ${data.timestamp}: ${data.name}`)
    //console.log(data);

    // evaluate 
    if(this.#log.length >= this.flushAtLogCount){
      this.#flush();
    }
    else if (!this.#flushTimer){
      this.#trackTime();
    }
  }
    
  #trackTime() {
    if (!this.#flushTimer) {
      this.#startTime = Date.now() - this.#elapsedTime;

      // have we reached the time limit? Keep watching until we do
      this.#flushTimer = setInterval(() => {                
        this.#elapsedTime = Date.now() - this.#startTime;
        let seconds = Math.floor(this.#elapsedTime / 1000);
        if(seconds >= this.secondsToFlush) {
            this.#flush();
        }
      }, (this.#elapsedTimeCheck * 1000));
    }
  }

  #resetFlushTimer() {
    if (this.#flushTimer) {
      clearInterval(this.#flushTimer); // stop elapsedTimeCheck timer
      this.#flushTimer = null;
    }
    this.#elapsedTime = 0;
  }

  #resetSessionTracking() {
    
    document.removeEventListener("mousemove", this.#updateSessionTracking.bind(this), false);
    document.removeEventListener("keydown", this.#updateSessionTracking.bind(this), false);

    this.user = null;
    this.org = null;
    if (this.#sessionTimer) {
      clearInterval(this.#sessionTimer); 
      this.#sessionTimer = null;
    }

    this.#trackingSession = false;
    this.#sessionActive = false;
    this.#lastSessionActivityTime = null;
    
}

  async #flush() {        
    this.#resetFlushTimer();
    let data = this.#log.slice(); // free up array for incoming data
    this.#log = []; // reset Log
    this.#save(data);
  }

  reset() {
    this.#resetSessionTracking();
    this.#flush();
  }

  async #save(data) {

    if(data.length == 0) return;
    await saveUserActivity(data);
  }
}


export default UserActivity;