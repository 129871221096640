import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Outlet } from 'react-router-dom';
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
//import ChatApp from './ChatApp';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-wrapper': {
    backgroundColor: '#ffffff' // #fafafa  #d5eef4 #ffffff
  },
  '& .FusePageSimple-content': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    height: '100%',
    maxWidth: '100%',
    backgroundColor: '#ebebeb' // #fafafa  #d5eef4 #f5f5f5 #dbd0d0 & #e6e6e6 (light gray) #737373 (dark gray)
  },
}));


function RegulationChat(props) {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));


  return( <Root
      scroll="content"
      content={<Outlet />}
      leftSidebarContent={null}
      leftSidebarOpen={false}
      rightSidebarContent={null}
      rightSidebarOpen={false}
    />)
}

export default RegulationChat;