import { firestore as db } from 'src/app/auth/Firebase';
import { collection, getDocs, getDoc, doc, setDoc, updateDoc, addDoc, query, where, onSnapshot, Timestamp} from 'firebase/firestore';

const COL_USERS = 'Users';
const COL_ORGANIZATIONS = 'Organizations';

// const CONTAINER_ROLES = 'User Roles';
// const CONTAINER_ORGANIZATION_ROLES = 'Organization Roles';


// Organization
// -------------------
// id: QVYiOqcIxrBxGas8mnRX
// key: Elysian
// ref: 
//
// id: 0y9yA1kRGImSYOhykNhS
// key: Gov
//

// reference:            https://stackoverflow.com/questions/46568850/what-is-firebase-firestore-reference-data-type-good-for
// query array of refs:  https://stackoverflow.com/questions/53140913/querying-by-a-field-with-type-reference-in-firestore
// query map:            const q = query(col, where("orgs.id", "==", orgId)); 
// https://abhik-b.medium.com/short-guide-to-firestore-reference-data-type-9c9197e4b9ee

/* SAMPLE
https://stackoverflow.com/questions/46568850/what-is-firebase-firestore-reference-data-type-good-for

import { collection, getDocs, getDoc, query, where } from "firebase/firestore";
import { db } from "./main"; // firestore db object

let productsWithUser = []
const querySnaphot = await getDocs(collection(db, 'products'));
querySnapshot.forEach(async (doc) => {
  let newItem = {id: doc.id, ...doc.data()};
  if(newItem.userRef) {
    let userData = await getDoc(newItem.userRef);
    if(userData.exists()) {
      newItem.userData = {userID: userData.id, ...userData.data()}
    }
    productwithUser.push(newItem);
  } else {
    productwithUser.push(newItem);
  }

*/

export async function getUsersByOrgId(orgId, loadReferences=false){
  if(orgId == null || orgId == '') return;
    
  const col = collection(db, COL_USERS);
  const org = await getOrgById(orgId);
  const orgRef = doc(db, COL_ORGANIZATIONS, orgId);
  // const q = query(col, where("orgRefs", "array-contains", orgRef));
  const q = query(col, where("orgRefs", "array-contains", {id: orgId, key: org.key}));
  const querySnapshot = await getDocs(q);
  let results = [];  
  querySnapshot.forEach((doc) => {    
    let data = doc.data();
    data.orgs = [org]; // map to orgs
    if(data.orgRefs.length > 1){
      if(loadReferences){        
        console.log('TODO: Load all orgRefs');
      }      
    }
    results.push(data);
  })
  
  return results 
}

export async function getOrgByRef(orgRef){
  let doc = await getDoc(orgRef);
  if(doc.exists()) {
    return doc.data();
  }
}

export async function getOrgById(orgId){
  const docRef = doc(db, COL_ORGANIZATIONS, orgId);
  const docSnap = await getDoc(docRef);

  let org;
  if (docSnap.exists()) {
    org = docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    console.log(`No org matches id ${orgId}`);
  }
  return org;
}


