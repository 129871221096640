import React, {lazy} from 'react';
const Module = lazy(() => import('./module'));
const KeysLayout = lazy(() => import('./keys/Layout'));
const AiLayout = lazy(() => import('./ai/Layout'));
const AiProcess  = lazy(() => import('./ai/process/process'));

const ModuleRoutes = {
    settings: {
      layout: {
        config: {},
      },
    },
    routes: [
      {
        
        path: 'keys',
        children: [
          {
            path: '',
            element: <KeysLayout 
              pageTitle='Integration Key'
              pageDescription='Manage connections to integrated systems.'
            />,
          },
          {
            path: ':id',
            element: <KeysLayout 
              pageTitle='Integration Key' 
              pageDescription='Manage connections to integrated systems.'
            />,
          },
          {
            path: ':id/:type',
            element: <KeysLayout 
              pageTitle='Integration Key' 
              pageDescription='Manage connections to integrated systems.'
            />,
          },
        ],
      },
      {
        path: 'ai',            
        children: [
          {
            path: '',
            element: <AiLayout 
              pageTitle='AI / ML'
              pageDescription='Optimize your AI and Machine Learning'
            />,
          },
          {
            path: ':processId',
            element: <AiProcess 
              pageTitle='AI / ML'
              pageDescription='Optimize your AI and Machine Learning'
            />
          }
          ,
          {
            path: ':processId/:type/:stepId',
            element: <AiProcess 
              pageTitle='AI / ML'
              pageDescription='Optimize your AI and Machine Learning'
            />
          }
        ],
      },
    ]
  }
  /*
  ,
            children: [
              { path: '', element: <AiProcess /> },
              { path: ':processId/:type/:stepId', element: <TaskForm /> },
            ],

  ,
        children: [
          {
            path: '',
            element: <LandingPage />,
          },
          { path: ':id', element: <Details tabName="SUMMARY"/> },          
          { path: ':id/summary',element: <Details tabName="SUMMARY" />},
          { path: ':id/clinical',element: <Details tabName="CLINICAL" />},
          { path: ':id/financial',element: <Details tabName="FINANCIAL" />},
          { path: ':id/notes',element: <Details tabName="NOTES" />},
          { path: ':id/ai', element: <Details tabName="AI" /> },
          { path: ':id/log', element: <Details tabName="LOG" /> },
        ],
        */
  
  export default ModuleRoutes;