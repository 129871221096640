export const temperatureConfig = {
  name: 'temperature',
  units: ['celsius', 'fahrenheit'],
  aliases: {
    'c': 'celsius',
    'f': 'fahrenheit',
    '°c': 'celsius',
    '°f': 'fahrenheit'
  },
  convert: (value, fromUnit, toUnit) => {
    if (fromUnit === 'fahrenheit' && toUnit === 'celsius') {
      const result = ((value - 32) * (5/9));
      return {
        convertedValue: Number(result.toFixed(2)),
        formula: `(${value}°F - 32) × 5/9 = ${result.toFixed(2)}°C`
      };
    }
    if (fromUnit === 'celsius' && toUnit === 'fahrenheit') {
      const result = (value * (9/5) + 32);
      return {
        convertedValue: Number(result.toFixed(2)),
        formula: `(${value}°C × 9/5) + 32 = ${result.toFixed(2)}°F`
      };
    }
    return {
      convertedValue: Number(value.toFixed(2)),
      formula: `${value}${fromUnit} = ${value}${toUnit}`
    };
  }
};
