import { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { saveTest } from './data';

function HubSpot() {
  const [searchParams] = useSearchParams();
  const [ output, setOutput ] = useState('');

  useEffect(() => {

    if(output != window.location.href){
      saveTest(window.location.href);
    }
    
    //let all = searchParams.getAll();
    //setOutput(all.toString());
    setOutput(window.location.href);
    
  }, [window.location.href])

  console.log(`Test: ${window.location.href}`);
  return (<div><div>Hubspot Test</div>
    <div>{`${window.location.href}`}</div>
  </div>)
}

export default HubSpot;