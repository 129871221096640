/* eslint-disable */
import './api/auth-api';
import './api/notifications-api';
import './api/contacts-api';
import './api/chat-api';
import history from '@history';
import mock from './mock';
/* eslint-enable */

mock.onAny().passThrough();

if (module?.hot?.status() === 'apply') {
  const { pathname } = history.location;
  history.push('/loading');
  history.push({ pathname });
}
