import { useRef, useState, useEffect, useContext } from 'react';
import { Context } from "./IamContext";
import { getOrgById, getUsersByOrgId } from './data/firestore';
import FuseUtils from '@fuse/utils';
import useAuthContext from 'src/app/AuthContext';
import FuseLoading from '@fuse/core/FuseLoading';
import { sendOrgInviteNotification } from 'src/app/auth/services/firebase/data';

let elysianId = 'QVYiOqcIxrBxGas8mnRX';

function IamProvider({children}) {
  const [ orgId, setOrgId ] = useState(elysianId); // for now default to elysianId = 'QVYiOqcIxrBxGas8mnRX';
  const [ org, setOrg ] = useState(null);
  const [ selectedUser, setSelectedUser ] = useState();
  // const [ selectedUsers, setSelectedUsers ] = useState([]); // in case we offer a multi-edit
  const [ users, setUsers ] = useState();
  const [ allUsers, setAllUsers ] = useState();
  const [ userView, setUserView ] = useState('list');
  const [ filter, setFilter ] = useState('');
  const { createUserInOrg, recaptchaVerifier, signInWithPhoneNumber } = useAuthContext();

  useEffect(() => {
    getOrgById(elysianId)
      .then(data => {
        setOrg(data);
      })

    getUsersByOrgId(elysianId).then(data => {
      setUsers(data);
      setAllUsers(data);
    })

  }, [orgId])


  const filterUsers = ( newFilter ) => {
    
    setFilter(newFilter);

    if(newFilter == null || newFilter == ''){
      setUsers([...allUsers]);
    } 
    else {
      let filteredUsers = FuseUtils.filterArrayByString([...allUsers], newFilter);    
      setUsers(filteredUsers);
    }    
  }

  const addUser = async ( user, password, sendInvite ) => {
    
    console.log (`Creating user '${user.DisplayName}'`);        

    if(org == null) return;
    
    let email = user.Email || user.email;    
    // {sendVerification: false, sendInvite: sendInvite}

    let result = await createUserInOrg(
      email,
      password,
      user, 
      org
    )
    .then((user) => {      
      
      sendOrgInviteNotification(user, org, password);
      console.log(`User ${user.DisplayName} Created!`);
      setTimeout(() => {
        let newList = [user].concat(allUsers);
        setFilter('');
        setAllUsers(newList);
        setSelectedUser(newList);
        
      }, 1000);      
    })
    .catch(errors => {
      console.log(errors);
      throw errors;
    })
    
    return result;
    
    /*
    if(currentFilter != ''){
      setTimeout(() => {
        filterUsers(currentFilter);   // reapply filter
      }, 1000)
    } 
      */   

  } // addUser

  if(org == null || users == null){
    return (<FuseLoading />);
  }

  const state = ({
    users, setUsers, org, filterUsers, addUser, filter, setFilter,
    selectedUser, setSelectedUser
  })

  return (<Context.Provider value={state}>{children}</Context.Provider>)

}

/* DATA ROW TEMPLATE:
{
    isInDb: false,
    DisplayName: '', FirstName: '', LastName: '',
    Title: '', Email: '', PhoneNumber: '', LastLogin: 'July 23th at 1:32 AM', PhotoURL: '',
    groups: [{name: 'Policy Advisors', id: '567'}, {name: 'Policy Editors', id: '678'}]
  },
*/
let data = [
  { 
    uid: 1,
    isInDb: false,
    DisplayName: 'Lucy Boxrud', FirstName: 'Lucy', LastName: 'Boxrud',
    Title: 'President', 
    Email: 'lboxrud@elysianseniorhomes.com', 
    PhoneNumber: '651.327.2778', 
    LastLogin: 'Jul 01, 2024', 
    avatar: {format: 'url', url: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FLucyBoxrud.jpg?alt=media&token=1fba0bb4-f57f-4354-8fc7-b7ca191108a2', initials: ''},
    PhotoURL: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FLucyBoxrud.jpg?alt=media&token=1fba0bb4-f57f-4354-8fc7-b7ca191108a2',
    groups: [{name: 'Executive Leadership', id: '123'}, {name: 'Financials', id: '234'}]
  },
  {
    uid: 2,
    isInDb: true, isAdmin: true,
    DisplayName: 'Melissa Greene', FirstName: 'Melissa', LastName: 'Greene',
    avatar: {format: 'url', url: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FMelissaGreene.jpg?alt=media&token=6e30c72d-7d14-4572-bd79-78088ec8bbcf', initials: 'MG'},
    Title: 'Executive Vice President', Email: 'mgreene@elysianseniorhomes.com', PhoneNumber: '608.317.2355', LastLogin: '4 minutes ago', PhotoURL: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FMelissaGreene.jpg?alt=media&token=6e30c72d-7d14-4572-bd79-78088ec8bbcf',
    groups: [{name: 'Executive Leadership', id: '123'}, {name: 'Financials', id: '234'}, {name: 'Administrators', id: '2345'}]
  },
  {
    uid: 3,
    isInDb: false,
    DisplayName: 'Jeanna Sands, BSN', FirstName: 'Jeanna', LastName: 'Sands',
    Title: 'Vice President of Policy & Compliance', Email: 'jsands@elysianseniorhomes.com', PhoneNumber: '', LastLogin: '6/9/2024 1:32 AM', PhotoURL: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FJeannaSands.jpg?alt=media&token=28f9b44e-66a5-4868-b222-5eb77844faaa',
    avatar: {format: 'url', url: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FJeannaSands.jpg?alt=media&token=28f9b44e-66a5-4868-b222-5eb77844faaa', initials: 'JS'},
    groups: [{name: 'Policy Administrators', id: '345'}, {name: 'Compliance Administrators', id: '456'}]
  },
  {
    uid: 4,
    isInDb: false,
    DisplayName: 'Natalie Hoff, RN', FirstName: 'Natalie', LastName: 'Hoff',
    Title: 'Vice President of Clinical Operations', Email: 'nhoff@elysianseniorhomes.com', PhoneNumber: '', LastLogin: 'July 9th at 1:32 AM', PhotoURL: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FNatalieHoff.jpg?alt=media&token=e0756179-2a38-47fa-8e14-e84bf9dd5fc3',
    avatar: {format: 'url', url: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FNatalieHoff.jpg?alt=media&token=e0756179-2a38-47fa-8e14-e84bf9dd5fc3', initials: 'NH'},
    groups: [{name: 'Policy Advisors', id: '567'}, {name: 'Policy Editors', id: '678'}]
  },
  {
    uid: 5,
    isInDb: false,
    DisplayName: 'Emily Boxrud, LALD', FirstName: 'Emily', LastName: 'Boxrud',
    Title: 'Corporate Director of Operations', Email: '', PhoneNumber: '', LastLogin: 'July 9th at 1:32 AM', PhotoURL: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FEmilyBoxrud.jpg?alt=media&token=fabd5c4a-41c6-456b-82dd-02b4c4e4be82',
    avatar: {format: 'url', url: 'https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2FElysian%2FphotoURL%2FEmilyBoxrud.jpg?alt=media&token=fabd5c4a-41c6-456b-82dd-02b4c4e4be82', initials: 'EB'},
    groups: [{name: 'Policy Advisors', id: '567'}, {name: 'Policy Editors', id: '678'}]
  },
  {
    uid: 6,
    isInDb: true,
    DisplayName: 'Courtney Dugger', FirstName: 'Courtney', LastName: 'Dugger',
    Title: '', Email: 'cdugger@elysianseniorhomes.com', PhoneNumber: '+17152921293', LastLogin: 'July 23th at 1:32 AM', PhotoURL: '',
    avatar: {format: 'initials', url: '', initials: 'CD'},
    groups: [{name: 'Policy Advisors', id: '567'}, {name: 'Policy Editors', id: '678'}]
  },
  {
    uid: 7,
    isInDb: true,
    DisplayName: 'Tina Makela', FirstName: 'Tina', LastName: 'Makela',
    Title: '', Email: 'tmakela@elysianseniorhomes.com	', PhoneNumber: '+15072513012', LastLogin: 'July 23th at 1:32 AM', PhotoURL: '',
    avatar: {format: 'initials', url: '', initials: 'TM'},
    groups: [{name: 'Policy Advisors', id: '567'}, {name: 'Policy Editors', id: '678'}]
  },
  {
    uid: 8,
    isInDb: false,
    DisplayName: 'Sydney Bode', FirstName: 'Sydney', LastName: 'Bode',
    Title: '', Email: 'sbode@elysianseniorhomes.com', PhoneNumber: '', LastLogin: 'July 23th at 1:32 AM', PhotoURL: '',
    avatar: {format: 'initials', url: '', initials: 'SB'},
    groups: [{name: 'Policy Advisors', id: '567'}, {name: 'Policy Editors', id: '678'}]
  },
  {
    uid: 9,
    isInDb: true, isSharedAccount: true,
    DisplayName: 'Elysian	Demo Account', FirstName: 'Elysian Demo', LastName: 'Account',
    Title: '', Email: 'demo@elysianseniorhomes.com	', PhoneNumber: '', LastLogin: 'July 23th at 1:32 AM', PhotoURL: '',
    avatar: {format: 'initials', url: '', initials: 'SA1'},
    groups: [{name: 'Policy Advisors', id: '567'}, {name: 'Policy Editors', id: '678'}, {name: 'Shared Accounts', id: '6789'}]
  },
]

export default IamProvider;