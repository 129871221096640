import React, {lazy} from 'react';
import Layout from '../chat_regulation/layout';
import ChatApp from '../chat_regulation/ChatApp';
// const Layout = lazy(() => import('./layout'));

const ClientRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'brightpolicy-rx',      
      element: <Layout 
        namespace="sterling-rx-policies" 
        pageTitle='BrightPolicy RX'
        pageDescription='Pharmacy Policies Demo'
        chatHeader={<span>Ask <strong><i>BrightPolicy RX</i></strong> any question and your AI Assistant will find the answer.</span>}
      />,
      auth: ['admin', 'demo'],
      children: [
        { 
        path: '', 
        auth: ['admin', 'demo'],
        element: <ChatApp 
          namespace="sterling-rx-policies" 
          pageTitle='BrightPolicy RX'
          pageDescription='Pharmacy Policies Demo'
          chatHeader={<span>Ask <strong><i>BrightPolicy RX</i></strong> any question and your AI Assistant will find the answer.</span>}
          />
        },
      ],
    },

    {
      path: 'brightpolicy',
      element: <Layout 
        namespace="cornerstone-policy"
        pageTitle='BrightPolicy'
        pageDescription='Corporate Policies Demo'
        chatHeader={<span>Ask <strong><i>BrightPolicy</i></strong> any question and your AI Assistant will find the answer.</span>}
      />,
      auth: ['admin', 'demo'],
      children: [
        { path: '', 
          auth: ['admin', 'demo'],
          element:         
            <ChatApp 
              namespace="cornerstone-policy"
              pageTitle='BrightPolicy'
              pageDescription='Corporate Policies Demo'
              chatHeader={<span>Ask <strong><i>BrightPolicy</i></strong> any question and your AI Assistant will find the answer.</span>}
              /> 
        },
      ],
    }
      
  ]
};
  
export default ClientRoutes;
