export const weightConfig = {
  name: 'weight-converter',
  description: 'Convert between different weight units',
  units: ['pounds', 'ounces', 'grams', 'kilograms'],
  aliases: {
    'lb': 'pounds',
    'lbs': 'pounds',
    'oz': 'ounces',
    'g': 'grams',
    'kg': 'kilograms'
  },
  convert: (value, fromUnit, toUnit) => {
    const factors = {
      pounds: {
        ounces: 16,
        grams: 453.592,
        kilograms: 0.453592
      },
      ounces: {
        pounds: 0.0625,
        grams: 28.3495,
        kilograms: 0.0283495
      },
      grams: {
        pounds: 0.00220462,
        ounces: 0.035274,
        kilograms: 0.001
      },
      kilograms: {
        pounds: 2.20462,
        ounces: 35.274,
        grams: 1000
      }
    };

    if (fromUnit === toUnit) {
      return {
        convertedValue: Number(value.toFixed(2)),
        formula: `${value} ${fromUnit} = ${value} ${toUnit}`
      };
    }

    const factor = factors[fromUnit][toUnit];
    const result = value * factor;

    return {
      convertedValue: Number(result.toFixed(2)),
      formula: `${value} ${fromUnit} × ${factor} = ${result.toFixed(2)} ${toUnit}`
    };
  }
};
