import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const RegistrationDefault = lazy(() => import('./RegistrationDefault'));
const RegistrationComplete = lazy(() => import('./RegistrationComplete'));
const TestRegistration =  lazy(() => import('./test'));

const RegistrationRoutes = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'register',
      children: [
        { path: '', element: <RegistrationDefault /> },
        { path: 'test', element: <TestRegistration /> },
      ]
    },
    {
      path: 'registered',
      children: [
        { path: '', element: <RegistrationComplete /> }
      ]
    },
  ],
};

export default RegistrationRoutes;
