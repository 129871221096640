import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { useEffect, useState } from 'react';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Outlet } from "react-router-dom";
// import PageHeader from '../../shared-components/PageHeader';
import PageHeader from './components/Header';
// import DemoSidebar from '../../shared-components/DemoSidebar';
// import Typography from '@mui/material/Typography';
import './iam.css';
import IamProvider from './IamProvider';

function LayoutPage() {

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
    setRightSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <IamProvider >
      <StyledLayout
        header={
          <PageHeader
            leftSidebarToggle={(ev) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
            rightSidebarToggle={(ev) => {
              setRightSidebarOpen(!rightSidebarOpen);
            }}
          />
        }
        content={<Outlet />}
        leftSidebarOpen={leftSidebarOpen}
        leftSidebarOnClose={() => {
          setLeftSidebarOpen(false);
        }}
        //leftSidebarContent={<DemoSidebar />}
        rightSidebarOpen={rightSidebarOpen}
        rightSidebarOnClose={() => {
          setRightSidebarOpen(false);
        }}
        //rightSidebarContent={<DemoSidebar />}
        scroll="content"
      />
    </IamProvider>
  );

}


const StyledLayout = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

export default LayoutPage;