import OpenAI from "openai";
import { config } from "dotenv";
config();

// console.log(process.env.REACT_APP_OPENAI_API_KEY);

const openai = new OpenAI({ 
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  organization: process.env.REACT_APP_OPENAI_ORG_ID,
  dangerouslyAllowBrowser: true
});

export default openai;