
function hack() {

  return [
    {
      metadata: {
        text: `/n/n Abuse Prohibition-VA Prevention Plan Page 1 
 
      Sholom Policy and Procedure 
       
      Title:           Abuse Prohibition-Vulnerable Adult Protection / Abuse    
                           Prevention Plan 
       
      Department:  All 
       
      Pertains to:  Sholom Community Alliance  
       
      Date of Origin: 3/02 
       
      Revision Date: 5/25/17, 2/24/20 (adult day care addendum added only), 4/13/21, 
      4/8/22 
      Policy: 
       
      Sholom Community Alliance will meet all requirements of Minnesota and Federal 
      Statutes relating to Vulnerable Adults and predatory offenders. Residents will receive 
      competent and compassionate care and treatment at all times.  There is zero tolerance 
      for maltreatment of residents.  The purpose of this policy is to establish an integrated 
      approach that incorporates procedures and practices for prevention and detection of 
      maltreatment into all programs and quality improvement activities. 
       
      It is the responsibility of all mandated individuals including but not limited to: employees,/n/n maltreatment into all programs and quality improvement activities. 
       
      It is the responsibility of all mandated individuals including but not limited to: employees, 
      consultants, contractors, physicians, volunteers, other caregivers to promptly report any 
      incident or suspected incident of neglect or resident abuse, including injuries of 
      unknown etiology, theft or misappropriation of resident property to the appropriate 
      management personnel as identified below, immediately.    
      Care Center: Administrator 
      Licensed assisted living: Housing Director 
      Medicare Home Care and Hospice: Director of Home Care and Hospice 
      Comprehensive Home Care: Director or Comprehensive Home Care 
       
      It is the policy of Sholom that each resident will be free from “Abuse”.  Abuse can 
      include verbal, mental, sexual, or physical abuse, corporal punishment or involuntary 
      seclusion.  The resident will also be free from physical or chemical restraints imposed/n/n include verbal, mental, sexual, or physical abuse, corporal punishment or involuntary 
      seclusion.  The resident will also be free from physical or chemical restraints imposed 
      for purposes of discipline or convenience and that are not required to treat the resident’s 
      medical symptoms.  Additionally, residents will be protected from abuse, neglect, and 
      harm while they are residing at the facility or being provided services by the staff of the 
      organization.  No abuse or harm of any type will be tolerated, and residents and staff 
      will be monitored for protection.  The facility will strive to educate staff, volunteers and 
      other applicable individuals in techniques to protect all parties.  
       
      Definitions:  The following definitions are intended to assist in the immediate 
      identification and reporting of mistreatment./n/n Abuse Prohibition-VA Prevention Plan Page 2 
       
      Vulnerable Adult: Any person 18 years of age or older who:  Lives in a licensed facility, 
      receive services from a licensed facility, are in a community  setting and receives 
      services from Sholom and would not, by themselves, report abuse or neglect due 
      to impaired physical or mental function or because of emotional stress/dysfunction 
      and is unable to adequately care for self and protect self from maltreatment. 
       
      Abuse: The willful infliction of injury, unreasonable confinement, intimidation, or 
      punishment resulting in physical harm or pain, or mental anguish, or deprivation by 
      an individual, including a caretaker, of goods or services that are necessary to 
      attain or maintain physical, mental and psychosocial well-being.  Abuse includes:  
      physical, emotional, mental, sexual, or involuntary servitude including abuse 
      facilitated or enabled using technology.  Willful as used in this definition of abuse,/n/n physical, emotional, mental, sexual, or involuntary servitude including abuse 
      facilitated or enabled using technology.  Willful as used in this definition of abuse, 
      means the individual must have acted deliberately, not that the individual must 
      have intended to inflict injury or harm. Includes but is not limited to photographs or 
      recording of a resident in a manner that is used, demeans or humiliates a 
      resident(s), regardless of whether the resident provided consent and regardless of 
      the resident’s cognitive status. 
       
      Adverse Event: An Adverse event is an untoward, undesirable, and unanticipated event 
      that causes death or serious injury, or the risk there of. 
       
      Exploitation: Means taking advantage of a resident for personal gain using 
      manipulation, intimidation, threats or coercion. 
       
      Physical Abuse: Includes hitting, slapping, pinching, scratching, spitting, holding 
      roughly, kicking, etc.  It also includes controlling behavior through corporal 
      punishment./n/n Physical Abuse: Includes hitting, slapping, pinching, scratching, spitting, holding 
      roughly, kicking, etc.  It also includes controlling behavior through corporal 
      punishment. 
       
      Verbal Abuse: Verbal abuse is defined as the use of oral, written or gestured language 
      that includes disparaging and derogatory terms to residents or their families, or 
      within their hearing distance, regardless of their age, ability to comprehend, or 
      disability.  Examples of verbal abuse include, but are not limited to: Threats of 
      “harm”, saying things to frighten a resident, such as telling a resident that he/she 
      will never be able to see his/her family again. 
       
      Mental Abuse: Includes, but is not limited to, humiliation, harassment, and threats of 
      aversion, punishment, Intimidation, fear, shame, agitation, degradation, or 
      deprivation.  This would include, but is not limited to; photographs and recordings 
      using any type of equipment and keeping or distributing them through multimedia/n/n deprivation.  This would include, but is not limited to; photographs and recordings 
      using any type of equipment and keeping or distributing them through multimedia 
      messages or on social media networks of residents that contain nudity, sexual and 
      intimate relations, bathing, showering, toileting, providing perineal care, agitating a 
      resident to solicit a response, derogatory statements directed to the resident, 
      showing a body part without the resident’s face whether it is the chest, limbs, or 
      back, labeling resident’s pictures and/or providing comments in a demeaning 
      manner, directing a resident to use inappropriate language and showing a resident 
      in a compromised position. This also includes involuntary seclusion, which is/n/n Abuse Prohibition-VA Prevention Plan Page 3 
       
      defined as separation of a resident from other residents or from her/his room or 
      confinement to her/his room (with or without roommates) against the resident’s will, 
      or the will of the resident’s legal representative.  Emergency or short-term 
      monitored separation from other residents will not be considered involuntary 
      seclusion and may be permitted if used for a limited period of time as a therapeutic 
      intervention to reduce agitation until professional staff can develop a plan of care to 
      meet the resident’s needs. 
       
      Sexual Abuse: Includes, but is not limited to, humiliation, sexual harassment, coercion, 
      or assault.  Absolutely NO sexual contact may occur between employees and 
      residents at any time (consensual or non-consensual).  The only exception is if the 
      consensual sexual personal relationship existed prior to the care giving 
      relationship./n/n residents at any time (consensual or non-consensual).  The only exception is if the 
      consensual sexual personal relationship existed prior to the care giving 
      relationship. 
       
      Involuntary Servitude: Includes forcing another to perform services for the advantage of 
      another. 
       
      Involuntary seclusion: Defined as the separation of a resident from other residents or 
      her/his room or confinement to her/his room (with or without roommates) against the 
      resident’s will or the will of the resident’s legal representative. Emergency or short term 
      monitored separation from other residents will not be considered involuntary seclusion 
      and may be permitted if used for a limited period as a therapeutic intervention to reduce 
      agitation until professional staff can develop a plan of care to meet the resident’s needs. 
       
      Misappropriation of Resident’s Property: Includes, but is not limited to the deliberate 
      misplacement, exploitation or wrongful (temporary or permanent) use of a/n/n Misappropriation of Resident’s Property: Includes, but is not limited to the deliberate 
      misplacement, exploitation or wrongful (temporary or permanent) use of a 
      resident’s belongings or money without the resident’s consent. 
       
      Neglect: Failure of the facility, its employees or service providers to provide goods and 
      services necessary to avoid physical harm, mental anguish, or mental illness.  This 
      includes food, shelter, clothing, health care, supervision, and services essential to 
      the welfare or safety of the resident.  It can also include failure to report to a 
      physician. 
       
      Resident: within policy this may also refer to patient, client, and/or tenant 
       
      Resident to Resident Abuse: Resident to resident is defined as negative, unwanted, 
      aggressive (even if unintended) physical, sexual, or verbal interactions between 
      residents that in a community setting would be likely be construed as unwelcome/n/n aggressive (even if unintended) physical, sexual, or verbal interactions between 
      residents that in a community setting would be likely be construed as unwelcome 
      and have a high a high potential to cause physical or psychological harm and/or 
      distress. These events can be reportable under Federal law. 
       
      Registered Predatory Offender:  Includes, but is not limited to, individuals convicted of 
      sex offenses, murder, kidnapping, armed robbery, violent domestic assault, 
      indecent exposure, and other “crimes against the person”./n/n Abuse Prohibition-VA Prevention Plan Page 4 
       
      Mistreatment: means the inappropriate treatment or exploitation of a resident 
       
      Maltreatment (MN Statue):  Means conduct described in section 626.5572, subdivision 
      15, or the intentional and nontherapeutic infliction of physical pain or injury, or any 
      persistent course of conduct intended to produce mental or emotional distress.  
      Every patient and resident shall also be free from nontherapeutic chemical and 
      physical restraints, in fully documented emergencies, or as authorized in writing 
      after examination by a patient’s or resident’s physician for a specified and limited 
      period of time, and only when necessary to protect the resident from self-injury or 
      injury to others. 
       
      Injuries of Unknown Origin:  An injury should be classified as an injury of unknown 
      source when both of the following conditions are met: 
      i. The source of the injury was not observed by any person or the source of the/n/n source when both of the following conditions are met: 
      i. The source of the injury was not observed by any person or the source of the                     
      the injury could not be explained by the resident;  
      ii. The injury is suspicious because of the extent of the injury or the location of the 
      injury (e.g., the injury is located in an area not vulnerable to trauma) or the number 
      of injuries observed at one particular point in time or the incidence of injuries over 
      time. 
       
      OBJECTIVE OF ABUSE POLICY 
      The objective of the abuse policy is to comply with the seven-step approach to abuse 
      and neglect detection and prevention.  The abuse policy will be reviewed on an annual 
      basis or more frequently and will be integrated into the facility Quality Assurance and 
      Performance Improvement (QAPI) program.  
       
      OVERVIEW OF SEVEN COMPONENTS  
       Screening 
       Training 
       Prevention 
       Identification 
       Investigation 
       Protection 
       Reporting and Response 
       
      Components:/n/n OVERVIEW OF SEVEN COMPONENTS  
       Screening 
       Training 
       Prevention 
       Identification 
       Investigation 
       Protection 
       Reporting and Response 
       
      Components: 
       
      The following components are intended to reduce, detect, and prevent abuse, neglect, 
      and financial exploitation and serve as a vital part of facility programming and quality 
      improvement activities. 
       
      I. Detection and Prevention: The following approaches will be used to detect and 
      prevent the occurrence of abuse, neglect and financial exploitation and to review 
      specific incidents for “lesson learned” in order to affect necessary policy changes. 
      A. Prevention/n/n Abuse Prohibition-VA Prevention Plan Page 5 
       
      1. Employment practices – The mission statement, values, and goals of the 
      organization will be shared with staff during pre-employment interviews, 
      job descriptions, orientation, and throughout all activities of employment 
      thereafter. 
      2. Policies and procedures will be developed, reviewed and revised for 
      abuse prohibition, individual and family rights and responsibilities, 
      admission and discharge criteria, timely and accurate assessments, 
      individualized care planning including the resident’s choice in the 
      development of the care plan and reassessments and revisions of the 
      plan of care, and use of clinical protocols to meet industry standards. 
      3. Resident Care: 
      a. Staffing levels will be maintained to meet the needs of the residents 
      on all shifts and units 
      b. Direct care staff will be involved in planning and evaluating care 
      through individual care planning and unit planning and development./n/n on all shifts and units 
      b. Direct care staff will be involved in planning and evaluating care 
      through individual care planning and unit planning and development. 
      c. Adequate supplies and equipment will be available to meet resident’s 
      needs including assistive devices that aid mobility and self-care. 
      d. Educational programming will present opportunities for learning and 
      use of stress and conflict management techniques. 
      e. An Employee Assistance Program will be available for all employees. 
      4. The resident, participant or tenant will receive the Bill of Rights and 
      Concern/Grievance procedure as part of the admission process.  
      Residents and/or significant others will be reminded to share questions 
      or concerns with unit staff.  
      5. Physical Plant: 
      a. On a periodic basis, an environmental observation will be made. 
      b. The physical plant and environment will meet or exceed all licensing 
      and certification requirements. 
      c. Access to the facility will be limited./n/n b. The physical plant and environment will meet or exceed all licensing 
      and certification requirements. 
      c. Access to the facility will be limited.   
      d. Visitors are asked to “sign in” at the main reception area and will be 
      asked to wear a visitor badge throughout their visit. 
      e. Locked boxes and drawers are available.  Residents and families are 
      encouraged to evaluate what belongings/valuables are kept in the 
      resident’s room.  The facility safe is available to temporarily hold 
      valuables that a resident plans to return to their family.  Residents 
      are discouraged from keeping large sums of money in their room. 
      f. Fire and disaster plans are in place. 
       
      B. Detecting: Monitoring activities and indicators that monitor care and services 
      (especially those that prevent abuse, neglect or financial exploitation) will be 
      utilized. 
        Examples: 
      1. Review of individual complaints or grievances. 
      2. Resident/family staff satisfaction surveys./n/n utilized. 
        Examples: 
      1. Review of individual complaints or grievances. 
      2. Resident/family staff satisfaction surveys. 
      3. Problem identification programs for use by staff, residents, and families 
      to identify concerns, problems, or suggestions for improvement./n/n Abuse Prohibition-VA Prevention Plan Page 6 
       
      4. Staff competency to assess knowledge and skill to determine 
      effectiveness of education programs and future training needs. 
      5. Clinical outcomes, i.e. Quality Indicators/Quality Measures. 
      6. Processes, i.e. unit reports, incident reporting, medication error reporting, 
      and infection control monitoring and techniques. 
      7. Approaches could include direct observation of staff, interviews with 
      staff, residents or family members or review of medical records. 
      C. Responding: Problem solving will include review and revisions of the above 
      including monitoring activities, analysis of incidents and reports and assessing 
      for trends.  If there are any deviations, the following steps will be taken through 
      established facility meetings and task forces. 
      1. Planning for improvement by analyzing the extent of the problem. 
      2. Engaging staff to determine the cause and extent of the problem and 
      develop plans for improvement./n/n 1. Planning for improvement by analyzing the extent of the problem. 
      2. Engaging staff to determine the cause and extent of the problem and 
      develop plans for improvement. 
      3. Implementing the plan for improvement. 
      4. Evaluating the response. 
      5. Revising the plan as needed, educating staff, and continuing to monitor. 
       
      II. Screening Procedures 
      A. Employees: 
      1. A criminal background check will be done prior to hiring as required by 
      and per Minnesota statues.  Disqualification from hiring or continuing 
      employment will follow the Minnesota Department of Health 
      requirements. 
      2. Verification of licensing or registration will be completed with the 
      appropriate agency. 
      3. Reference checks with prior and current employers will be completed to 
      the greatest extent possible. 
      4. The interview process will include questions meant to evaluate 
      personality traits and views on caring for the aged or chronically ill and 
      the ability to handle stress and manage conflict./n/n 4. The interview process will include questions meant to evaluate 
      personality traits and views on caring for the aged or chronically ill and 
      the ability to handle stress and manage conflict. 
      5. Employees will not have direct contact with residents pending the 
      outcome of background checks. 
      B. Residents: 
      1. Pre-admission screening and on-going assessments will determine if the 
      facility can meet the functional, medical and special needs, i.e. infections 
      and mental health or behavioral needs. 
      2. A determination will be made regarding placement based on current 
      staffing patterns, staff knowledge, clinical resources, physical plant, or 
      equipment limitations. 
      3. The pre-admission information will serve as part of the initial care plan. 
      4. Sholom will not admit applicants that are predatory offenders or who 
      have a known history of an action of physical aggression towards others 
      including sexual offenses.  While the applicant is required by law to self-/n/n have a known history of an action of physical aggression towards others 
      including sexual offenses.  While the applicant is required by law to self-
      disclose this information, the admission process will include question(s) 
      relating to this issue./n/n Abuse Prohibition-VA Prevention Plan Page 7 
       
      5. If at a later time, the facility is notified of any predatory offense, the 
      resident will be immediately discharged. 
       
       
      III. Identification of Abuse-Neglect Risk Factors 
      A. The facility will pro-actively attempt to identify and mitigate risk factors that 
      could lead to or the predictors of abuse and neglect including: 
      1. Staffing patterns, overtime hours, scheduling needs including 
      consistency of assignments 
      2. Supervision 
      3. Wage and benefits analysis 
      4. Communication patterns between residents, families and staff including 
      differences in how primary languages and cultures are handled 
      5. Involvement of staff in internal monitoring processes. 
      6. Availability of support services both internally and externally 
      7. Information from resident, family and staff surveys 
      8. Trends with abuse and neglect 
      9. Staff performance including evaluations, education and reviews. 
      B. Resident care planning/n/n 7. Information from resident, family and staff surveys 
      8. Trends with abuse and neglect 
      9. Staff performance including evaluations, education and reviews. 
      B. Resident care planning 
      1. The criteria used to identify resident needs will also be used to assess 
      areas of susceptibility for abuse and neglect. 
      2. Concerns related to financial exploitation will be reviewed and referrals 
      made to outside agencies as indicated. 
      3. Behavior management plans will be developed and implemented to 
      assist staff in problem solving and coping with difficult behaviors. 
      4. Approaches will reflect efforts to minimize the risk of maltreatment for the 
      resident. 
      5. Social services or nursing will complete a review of vulnerability for each 
      resident on admission and at minimum annually -. A plan will be 
      developed to identify, correct, and intervene in situations in which abuse 
      or neglect is more likely to occur. 
      6. If the facility knows of a history of criminal misconduct or physical/n/n developed to identify, correct, and intervene in situations in which abuse 
      or neglect is more likely to occur. 
      6. If the facility knows of a history of criminal misconduct or physical 
      aggression from a law enforcement authority, a medical record prepared 
      by another agency or another health care provider or the facility’s on-
      going assessment of the individual, the behavior section of the care plan 
      will detail the measures to be taken to minimize the risk the person might 
      be expected to pose to others including visitors and person outside the 
      facility, if unsupervised. 
      C. Safety surveillance 
      1. The Safety Committee will meet at least -quarterly to review incidents 
      and accidents and other occurrences and to plan prevention measures. 
      2. Safety surveillance will be on-going by all staff to identify potential safety 
      problems and plan interventions to prevent incidents or accidents. 
       
      IV. Training and Development to Support Quality Care:/n/n Abuse Prohibition-VA Prevention Plan Page 8 
       
      A. All employees will be educated in orientation regarding the Vulnerable Adult 
      Act and the facility’s policy and procedures. 
      B. On-going training/materials will be available to all employees to reduce the risk 
      of abuse through appropriate interventions to deal with aggressive and/or 
      catastrophic reactions of residents, recognize signs of burnout and stress that 
      may lead to abuse and what constitutes abuse, neglect, and misappropriation 
      of property. 
      C. Staff will be trained in: 
      1. Basic communication skills. 
      2. Clinical presentations of dementia, mental illness, cognitive impairment 
      and strategies for communication. 
      3. Effect of cognitive impairment. 
      4. Successful ways of coping with maladaptive behaviors. 
      5. Knowledge of and respect for individual rights. 
      6. Staff behaviors that can elicit negative responses by residents. 
      D. Leadership training to build upon information above to include:/n/n 5. Knowledge of and respect for individual rights. 
      6. Staff behaviors that can elicit negative responses by residents. 
      D. Leadership training to build upon information above to include: 
      1. Leadership training that supports staff job satisfaction. 
      2. Organization practices that promote staff involvement in planning and 
      decision making. 
      3. Environmental considerations that promote safety and function. 
      4. Administrative role in investigating, reporting, detecting, and preventing 
      maltreatment. 
      5. Human resource practices that detect and prevent maltreatment 
      including employee screening, staffing, and mechanisms for reviewing 
      complaints and conflict management programs. 
      E. Training for maltreatment will incorporate the following: 
      1. Risk factors for maltreatment 
      2. Clinical indicators of maltreatment 
      3. Detection of maltreatment and prevention strategies 
      4. Reporting mechanisms. 
      5. Regulations and the facility’s policies and procedures./n/n 2. Clinical indicators of maltreatment 
      3. Detection of maltreatment and prevention strategies 
      4. Reporting mechanisms. 
      5. Regulations and the facility’s policies and procedures. 
      6. Insight into staff’s behavior in relationship to expected outcomes. 
      F. Resident and family education will include: 
      1. Education about their rights regarding care and treatment 
      2. What constitutes maltreatment 
      3. How to report 
      4. How they will be protected 
      5. Encouragement to participate in resident and family groups. 
       
      V. Protecting the Rights and Well-Being of Residents and Staff 
      A. Administrative strategies to include the development of an organizational 
      climate and culture that support and reinforce protection for the resident and 
      staff.  This will include at a minimum: 
      1. Adequate and competent staff 
      2. Monitoring both internally and externally through an open-door policy for 
      staff, residents, families, ombudsmen, and advocates./n/n Abuse Prohibition-VA Prevention Plan Page 9 
       
      3. Communication between departments, shifts, and outside organizations, 
      i.e., adult protection, regulators, and law enforcement. 
      4. If Volunteers have direct resident contact, a state background check will 
      be performed as required by State regulations.  Volunteers are provided 
      education on the Abuse Policy and Resident Rights.  
      5. Policies and procedures to: 
      a. Reflect the organization’s values and commitment to quality care. 
      b. Encourage staff and residents to participate in monitoring how care 
      is provided 
      c. Ensure protection of rights and reputation when individuals complain 
      of abusive or neglectful treatment. 
      d. Provide support through advocates and/or ombudsmen. 
      e. Protect staff and other reporters from retaliation. 
      B. Resident protection would include: 
      1. See components relating to Screening (II) and Training (IV). 
      2. Knowledge of resident and thorough planning of needs./n/n B. Resident protection would include: 
      1. See components relating to Screening (II) and Training (IV). 
      2. Knowledge of resident and thorough planning of needs. 
      3. Listening to, believing and supporting the resident. 
      4. Treatment plans to provide safety, security, and restoration of function 
      including follow-up counseling to minimize any long-term effects of 
      maltreatment. 
      5. Maintaining a resident’s right to privacy and confidentiality-This includes 
      the right to personal privacy of the physical body, as well as personal 
      space including accommodations, personal care and clinical records. 
      6. Referral to appropriate agencies and sources as indicated. 
      7. Assurance that the resident is kept informed of the situation. 
      C. Reporter protection would include: 
      1. Establishing and enforcing an environment where staff, residents, and 
      families who report maltreatment are protected from retaliation both 
      during and after the investigation./n/n 1. Establishing and enforcing an environment where staff, residents, and 
      families who report maltreatment are protected from retaliation both 
      during and after the investigation. 
      2. Maintaining confidentiality to every extent possible. 
      D. Alleged perpetrator protection would include: 
      1. Investigating following due process. 
      2. Avoidance of a finding of guilt until the process is completed. 
      3. Protection of reputation throughout process. 
       
      VI. Reporting and Investigating / Responding:  
      It is the policy of this organization that “abuse” allegations (abuse, neglect, 
      exploitation or mistreatment, including injuries of unknown source and 
      misappropriation of resident property) are reported per Federal and State Law.  The 
      facility will ensure that all alleged violations involving abuse, neglect, exploitation or 
      mistreatment, including injuries of unknown source and misappropriation of resident 
      property, are reported immediately, but not later than 2 hours after the allegation is/n/n mistreatment, including injuries of unknown source and misappropriation of resident 
      property, are reported immediately, but not later than 2 hours after the allegation is 
      made, if the events that caused the allegation involve a crime, abuse or resulted in 
      serious bodily injury.  The facility will ensure that alleged violations not involving 
      serious injury or a crime are reported  no later than 24 hours if the events that cause 
      the allegation do not involve abuse and do not result in serious bodily injury. Reports/n/n Abuse Prohibition-VA Prevention Plan Page 10 
       
      will be made to the appropriate individual as identified on page one. Reports will be 
      made to that individual  and to other officials (including to the State Survey Agency 
      and adult protective services where state law provides for jurisdiction in long-term 
      care facilities) in accordance with State law through established procedures.  In 
      addition, local law enforcement will be notified of any reasonable suspicion of a 
      crime against a resident in the facility. 
       
      DEFINITIONS (in accordance to the Elder Justice Act): 
       
      Covered Individual:  A “covered individual” defined as anyone who is an owner, 
      operator, employee, manager, agent or contractor of the facility. 
       
      Serious Bodily Injury: The term “serious bodily injury” defined as an injury involving 
      extreme physical pain; involving substantial risk of death; involving protracted loss or 
      impairment of the function of a bodily member, organ, or mental faculty; or requiring/n/n extreme physical pain; involving substantial risk of death; involving protracted loss or 
      impairment of the function of a bodily member, organ, or mental faculty; or requiring 
      medical intervention such as surgery, hospitalization, or physical rehabilitation 
       
      In the case of “criminal sexual abuse” which is defined in section 2011(19)(B) of the Act 
      (as added by section 6703(a)(1)(C) of the Affordable Care Act), serious bodily 
      injury/harm shall be considered to have occurred if the conduct causing the injury is 
      conduct described in section 2241 (relating to aggravated sexual abuse) or section 
      2242 (relating to sexual abuse) of Title 18, United States Code, or any similar offense 
      under State law. 
       
      Serious Bodily Injury Reporting – 2 Hour Limit: If the events that cause the 
      reasonable suspicion result in serious bodily injury to a resident, the covered individual 
      shall report the suspicion immediately, but not later than 2 hours after forming the 
      suspicion;/n/n reasonable suspicion result in serious bodily injury to a resident, the covered individual 
      shall report the suspicion immediately, but not later than 2 hours after forming the 
      suspicion;  
       
      A. Policies and procedures will be in place to support immediate reporting of 
      potential maltreatment.  This will include all aspects of training staff, residents, 
      and family members to: 
      1. Define mistreatment 
      2. Define maltreatment 
      3. Know how to report 
      4. Know what to do if they witness or become aware of maltreatment 
      5. Know whom they would report to and within what time frame 
      6. Feel protected from harassment, intimidation, or retaliation as a result of 
      reporting, i.e., an event. 
      B. Reporting by staff: 
      1. Who should observe and report: 
      a. ALL employees are mandated reporters and must observe and 
      immediately report abuse, neglect, financial exploitation or theft. 
      b. Employees who report in “good faith” are protected from retaliation 
      and are immune from liability./n/n Abuse Prohibition-VA Prevention Plan Page 11 
       
      c. There is zero tolerance for any act of retaliation against another 
      employee for reporting. 
      2. How to report: 
      a. Resident Incident Report will record events involving known resident 
      occurrences, i.e., falls, skin injuries. 
      b. Medication Error Report will record known errors in medication 
      administration. 
      c. Concern/Grievance report: will record concerns or complaints by 
      residents, families or guests 
      d. Missing Item Report will record missing personal belonging or money 
      e. All of the above written or electronic reports will be analyzed and 
      summarized for trends and patterns. 
      f. Situations not applicable to the above will be reported by either a 
      written or verbal report. 
      3. Who could abuse/neglect/exploit a resident (perpetrator): 
      a. Resident to resident 
      b. Staff to resident 
      c. Visitor to resident 
      d. Resident to self 
      e. Family to resident 
      4. Whom to report to: 
      a. Administrator 
      b. Director of Nursing 
      c. Social Worker/n/n a. Resident to resident 
      b. Staff to resident 
      c. Visitor to resident 
      d. Resident to self 
      e. Family to resident 
      4. Whom to report to: 
      a. Administrator 
      b. Director of Nursing 
      c. Social Worker 
      d. Supervisor 
      e. Department Directors 
      f. Charge Nurse 
      5. When to report: 
      a. When there is a suspicion of/or actual abuse, the employee must 
      notify a supervisor, who will then contact the Administrator 
      immediately. The employee may contact the Administrator directly. 
      6. What to report: 
      a. Any incident of abuse, neglect, or financial exploitation that an 
      employee directly witnesses. 
      b. Any situation where there is reasonable cause to believe abuse, 
      neglect, or financial exploitation has occurred. 
      c. Any incident involving keeping or distributing photographs or 
      recording through multimedia or social media network that can cause 
      mental abuse.   
      d. Any unresolved complaint or concern presented by a resident, family 
      member, physician or visitor./n/n recording through multimedia or social media network that can cause 
      mental abuse.   
      d. Any unresolved complaint or concern presented by a resident, family 
      member, physician or visitor. 
      C. The facility will have a prompt, respectful response to an incident of abuse 
      and/or neglect.  Internal investigation will include at a minimum: 
      1. Interview with the victim and family members if applicable. 
      2. Interviews with the alleged perpetrator(s) 
      3. Interviews with the reporter(s)/n/n Abuse Prohibition-VA Prevention Plan Page 12 
       
      4. Interviews with potential witnesses including other residents. 
      5. Review of the medical record. 
      6. Review of personnel files including training information. 
      7. Thorough documentation of steps and findings of investigations. 
      8. Preservation of medical evidence including photographs if indicated. 
      9. Involve business office as it relates to financial exploitation. 
      10. Comprehensive Home Care regulation:  Examination of the environment 
      surrounding the alleged incident. 
      D. Protection of the victim: 
      1. See component Protecting the Rights and Well-Being of Residents and 
      Staff (V) 
      2. Provide immediate medical treatment as indicated. 
      3. Consider long-term safety and psychological needs of the resident. 
      E. Notification: 
      1. Any known or suspected - abuse must be reported to the Administrator 
      immediately (Immediately means “as soon as possible”) then contact 
      the Director of Nursing./n/n E. Notification: 
      1. Any known or suspected - abuse must be reported to the Administrator 
      immediately (Immediately means “as soon as possible”) then contact 
      the Director of Nursing. 
      2. The Administrator and/or designee will review events with the reporter 
      and determine the need for immediate reporting to external agencies. 
      3. Effective April 14, 2008, notification is made to the Minnesota 
      Department of Health via the web to report incidents (as required at 42 
      CFR 483.13(c) (2) and for timely submission of the facility’s investigative 
      reports as required at 42 CFR 483.13(c) (4). 
      a. The incidents which are required to be reported are abuse, neglect, 
      mistreatment, maltreatment, misappropriation with resident’s 
      property, and injuries of the unknown origin. 
      b. The regulations require that facilities report incidents to the 
      Minnesota Department of Health immediately (Immediately means 
      “as soon as possible”). The facility’s investigative reports/n/n b. The regulations require that facilities report incidents to the 
      Minnesota Department of Health immediately (Immediately means 
      “as soon as possible”). The facility’s investigative reports 
      (electronically) must be completed within five working days of the 
      reported incident. 
      c. After review of a submitted report the file will be maintained by the 
      Administrator or designee. 
      d. Notification will be made to the initial reporter as to the disposition of 
      their report per regulation 
      4. The Administrator and/or his or her designee are responsible to lead 
      internal investigations in conjunction with the Director of Social Services 
      and/or the Director of Nursing. 
      5. All allegations of physical or sexual assault, mental abuse resulting from 
      the distribution of photographs or recordings using any type of 
      equipment, or suspicion of a serious crime to a resident will be 
      immediately reported to the Police Department and to the Minnesota 
      Adult Abuse Reporting Center or MAARC./n/n equipment, or suspicion of a serious crime to a resident will be 
      immediately reported to the Police Department and to the Minnesota 
      Adult Abuse Reporting Center or MAARC. 
      6. (Please refer to the Elder Justice Act Policy and Procedure for 
      requirements and guidelines for notifying the Police Department)./n/n Abuse Prohibition-VA Prevention Plan Page 13 
       
      7.  MAARC will refer the event to the appropriate agency and the Director 
      of Nursing or Designee will provide additional information as directed by 
      the agency involved. 
      F. Any employee may report externally.  The facility cannot retaliate against an 
      employee who reports in “good faith”. 
      G. Any resident or family member may report externally. 
       
      VII. Sexual Assault (This is always reportable) 
      A. Assure safety of the resident.  Offer comfort and support. 
      B. Do not bathe, shower, or wash the resident.  Do not disturb bedding or other 
      items in the area. 
      C. Notify the police if there is any question of sexual assault. 
      D. Notify the physician and family to request transfer of the resident for further 
      evaluation as indicated. 
      E. Ensure that the alleged perpetrator (if known or suspected) is removed from 
      the facility and facility scheduling until the investigation completed./n/n evaluation as indicated. 
      E. Ensure that the alleged perpetrator (if known or suspected) is removed from 
      the facility and facility scheduling until the investigation completed. 
      F. Follow the Vulnerable Adult policy and procedure plus directions from the 
      police, Adult Protection, or Minnesota Department of Health. 
       
      VIII. Responding 
      A. The facility will assure that corrective, remedial, or disciplinary action occurs in 
      accordance with applicable local, state, or federal law in response to findings 
      of investigations. 
      B. The facility will assure that: 
      1. All allegations are responded to in a prompt manner 
      2. Attention is given to the resident during and after an investigation 
      C. Findings from an investigation will be analyzed and: 
      1. Training or retraining will be done as indicated 
      2. Related policies and procedures will be reviewed and revised. 
      3. Best practices will be developed and implemented 
      D. Information during and following an investigation will be shared:/n/n 2. Related policies and procedures will be reviewed and revised. 
      3. Best practices will be developed and implemented 
      D. Information during and following an investigation will be shared: 
      1. With the victim and primary contact 
      2. With regulatory agencies 
      3. With staff with a “need to know” basis 
       
      References: 
       
      Medicare and Medicaid Programs; Reform of Requirements for Long-Term Care Facilities 10/04/16: 
       https://www.federalregister.gov/documents/2016/10/04/2016-23503/medicare-and-medicaid-
      programs-reform-of-requirements-for-long-term-care-facilities  
       
      State Operations Manual Appendix PP – Guidance to Surveyors for Long-Term Care Facilities, 06/10/16: 
       https://www.cms.gov/Regulations-and-
      Guidance/Guidance/Manuals/downloads/som107ap_pp_guidelines_ltcf.pdf  
       
      CMS Memo Ref:  S&C 17-07-NH:  Advance Copy – Revisions to State Operations Manual (SOM), 
      Appendix PP- Revised Regulations and Tags, 11/09/16:   
       https://www.cms.gov/Medicare/Provider-Enrollment-and-/n/n Appendix PP- Revised Regulations and Tags, 11/09/16:   
       https://www.cms.gov/Medicare/Provider-Enrollment-and-
      Certification/SurveyCertificationGenInfo/Downloads/Survey-and-Cert-Letter-17-07.pdf/n/n Abuse Prohibition-VA Prevention Plan Page 14 
       
       
      CMS Memo Ref:  S&C:  11-30-NH:  Reporting Reasonable Suspicion of a Crime in a Long-Term Care 
      Facility (LTC):  Section 1150B of the Social Security Act, Revised 01/20/12 (Elder Justice Act Provisions): 
      https://www.cms.gov/Medicare/Provider-Enrollment-and-
      Certification/SurveyCertificationGenInfo/Downloads/SCLetter11_30.pdf  
       
       
       
      INSTRUCTIONS FOR REPORTING INCIDENTS-Care Centers 
       
      NOTE:  This reporting process is for reporting incidents related to your facility.  
      Do not use this process if you are reporting an incident that may have occurred at 
      another facility.  Continue to report those cases to the MAARC. 
       
      Here are the steps for reporting when completing a report of potential, actual or 
      suspected abuse and/or financial exploitation and AMA discharges.  BE SURE TO 
      CALL THE ADMINISTRATOR AND THE DIRECTOR OF NURSING BEFORE FILING 
      THE REPORT. 
       
      TO MAKE AN INITIAL REPORT OF AN INCIDENT: 
       
      1. Log onto the web site at/n/n CALL THE ADMINISTRATOR AND THE DIRECTOR OF NURSING BEFORE FILING 
      THE REPORT. 
       
      TO MAKE AN INITIAL REPORT OF AN INCIDENT: 
       
      1. Log onto the web site at 
       https://apps.health.state.mn.us/nhir/ 
       
      Facilities may also access the incident reporting login page from the OHFC home 
      page at:  https://www.health.state.mn.us/divs/fpc/ohfcinfo/contohfc.htm and then 
      click on “Nursing Home Incident Reporting”. 
       
      2. Enter your user specific identification and password on the login page.  (Each 
      facility has designated staff that has login credentials, the Charge of Building will 
      know this.  In the event that this is not clear contact the Director of Nursing or 
      Administrator immediately for directions). 
       
      3. A screen appears with drop down areas at the top. Select incident reporting, 
      create report. 
       
      4. An incident report form will appear. Fill in all areas marked with an asterisk and 
      as much information as possible about the incident./n/n create report. 
       
      4. An incident report form will appear. Fill in all areas marked with an asterisk and 
      as much information as possible about the incident.  
       
      5. Once you’ve completed the form click on submit and print the form and put in 
      DON, Social Service Director or Administrator mailbox.  
       
      6. Add the copy of the OHFC report to the investigative file.  (This includes a 
      report number and is provided after the ‘submit’ is pressed.)  The second step 
      involves electronically submitting the facility’s investigative report to MDH.  Within 
      five working days (Monday through Friday, excluding holidays) of the incident, 
      complete the internal investigation, enter the information onto the web site, and 
      submit it to MDH.  If the facility fails to send an investigative report to MDH, a/n/n Abuse Prohibition-VA Prevention Plan Page 15 
       
      reminder will be sent electronically.  Once an investigative report has been 
      submitted, the facility will receive an electronic notification.  A copy of the 
      electronic notification should be retained for future reference.   
       
      Additional required tasks: 
       
      If you are also reporting a crime with or without “serious 
      bodily injury” the following protocol should be followed: 
       
      Shaller Campus 
       
      If you are also reporting a crime with or without “serious bodily injury” the following 
      protocol should be followed: 
       
      Notify the St. Paul Police Department for the Shaller Campus. 
      St. Paul Police may be called at (651)291-1111 or done on-line http://www.stpaul.gov/  
      then ‘Submit Police Report’ on the right side.   
       
      Police reports may be done on-line IF: 
       
       This is not an emergency 
       This incident occurred within the city of St. Paul 
       There were no known suspects 
       Did not occur on an Interstate Freeway 
       Reporter is age 18 or older/n/n  This is not an emergency 
       This incident occurred within the city of St. Paul 
       There were no known suspects 
       Did not occur on an Interstate Freeway 
       Reporter is age 18 or older 
       Reporter has an e-mail address 
       The crime is not motivated by bias 
      *Please note the case number assigned and provide with paperwork. 
       
      Ackerberg Campus 
       
      If you are also reporting a crime with or without “serious bodily injury” the following 
      protocol should be followed: 
       
      Notify the St Louis Park Police for the Ackerberg Campus. 
       
      St Louis Park Police may be called at (952) 924-2600  
      *Please note the case number assigned and provide with paperwork. 
       
       
      Final step: 
       
      Once all tasks have been completed slide a copy of the reports and any additional 
      supporting notes and documentation under the Administrator’s office door./n/n Abuse Prohibition-VA Prevention Plan Page 16 
       
       
                Remainder of this page intentionally left blank 
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
      Instructions for Reporting Assisted Living and Comprehensive Homecare 
      Incidents: 
       
      Addendum for Comprehensive Home Care 
       
       
        1.  Notify RN in Charge and RN Site Director before filing a report 
        2.  Notify Campus Administrator and Housing Manager before filing a report/n/n Abuse Prohibition-VA Prevention Plan Page 17 
       
        3.  MAARC report including investigation findings will be submitted no later than 24 
      hours of notification   
        4.  Log onto MAARC-mn.gov/dhs/reportadultabuse/   (insert address exactly           
             as indicated). Use drop down box area to navigate through the fields   
        5.  May call if unable to make electronic report.  # 844-880-1574 
        6.  Complete reporter/information source fields 
        7.  Complete VA field 
        8.  Complete alleged perpetrator field.  May include up to 4 persons as needed 
        9.  Complete maltreatment allegation detail field 
        10.  Complete impact/effects on VA field 
       11. Complete additional sources of info and VA support persons field 
       12. Complete safety field and move to submit report field  
       13. Follow final instructions: affirm report; obtain report ID #; submit report; and print 
       report.  Forward copies to RN Site Director and Notify the Director of     
            Comprehensive Home Care./n/n report.  Forward copies to RN Site Director and Notify the Director of     
            Comprehensive Home Care.  
      14. Utilize Internal Investigation of Suspected Maltreatment Form or  
             Therapeutic Error with Injury Documentation Form 
       15. Contact Police if a crime has been committed-upon hearing the witnesses’    
             description of the incident, if it appears a crime may have been committed,  
             the reporter will immediately contact the police if the witness has not done so.   
      16.  Utilize Written Communication with Vulnerable Adult Reporter-if needed. 
       
      Information re:  Errors in Therapeutic Conduct 
       
      If RN believes the incident was an error in therapeutic conduct, such as a med error that 
      resulted in harm or injury but should not be considered neglect, the RN should include 
      the following information in the initial and subsequent report to MAARC: 
       
      1. How the necessary physician care was provided in a timely fashion./n/n the following information in the initial and subsequent report to MAARC: 
       
      1. How the necessary physician care was provided in a timely fashion. 
      2. That the attending physician determined that after receiving cares the VA’s health 
      will be restored to the pre-existing condition. 
      3. The caregiver’s error was not part of a pattern of errors by the individual. 
      4. The caregiver immediately reported the error and it was recorded and investigated 
      internally. 
      5. The RN identified and took corrective action and implemented measures designed to 
      reduce the risk of further occurrence of this and similar errors. 
      6. All of the provider’s actions have been documented and are available for review by 
      surveyors or other authorized agencies. 
      Instructions for Reporting Medicare Certified Home Care and Hospice Incidents: 
       
      Addendum for Medicare Certified Home Care and Medicare Certified Hospice 
       
        1.  Notify Clinical Manager and Director of Home Care and Hospice before filing a/n/n Addendum for Medicare Certified Home Care and Medicare Certified Hospice 
       
        1.  Notify Clinical Manager and Director of Home Care and Hospice before filing a  
             report 
        2.  Notify Home Care and Hospice Administrator before filing a report 
        3.  Log onto MAARC-mn.gov/dhs/reportadultabuse/   (insert address exactly/n/n Abuse Prohibition-VA Prevention Plan Page 18 
       
             As indicated). Use drop down box area to navigate through the fields   
        4.  May call if unable to make electronic report.  # 844-880-1574 
        5.  Complete reporter/information source fields 
        6.  Complete VA field 
        7.  Complete alleged perpetrator field.  May include up to 4 persons as needed 
        8.  Complete maltreatment allegation detail field 
        9.  Complete impact/effects on VA field 
       10. Complete additional sources of info and VA support persons field 
       11. Complete safety field and move to submit report field 
       12. Follow final instructions: affirm report; obtain report ID #; submit report; and   
             print report.  Forward copies to Home Care and Hospice Manager and/or Director. 
       13. Utilize Internal Investigation of Suspected Maltreatment Form or  
             Therapeutic Error with Injury Documentation Form 
       14. Contact Police if a crime has been committed-upon hearing the witnesses’/n/n Therapeutic Error with Injury Documentation Form 
       14. Contact Police if a crime has been committed-upon hearing the witnesses’    
             description of the incident, if it appears a crime may have been committed,  
             the reporter will immediately contact the police if the witness has not done  
             so.    
      15.  Utilize Written Communication with Vulnerable Adult Reporter-if needed. 
       
       
       
      Information re:  Errors in Therapeutic Conduct 
       
      If RN believes the incident was an error in therapeutic conduct, such as a med error that 
      resulted in harm or injury but should not be considered neglect, the RN should include 
      the following information in the initial and subsequent report to MAARC: 
       
      1. How the necessary physician care was provided in a timely fashion. 
      2. That the attending physician determined that after receiving cares the VA’s 
      health will be restored to the pre-existing condition./n/n 2. That the attending physician determined that after receiving cares the VA’s 
      health will be restored to the pre-existing condition. 
      3. The caregiver’s error was not part of a pattern of errors by the individual. 
      4. The caregiver immediately reported the error and it was recorded and 
      investigated internally. 
      5. The RN identified and took corrective action and implemented measures 
      designed to reduce the risk of further occurrence of this and similar errors. 
      6. All of the provider’s actions have been documented and are available for review 
      by surveyors or other authorized agencies. 
       
      Instructions for Reporting Adult Day Centers Incidents:  
      Addendum for Adult Day Centers  
      1. Notify Director of Adult Day before filing a report  
      2. Notify Campus Administrator before filing a report  
      3. When internal report is received, the Director of Adult Day and Campus administrator are 
      responsible for determining if a report to Common Entry Point is required./n/n 3. When internal report is received, the Director of Adult Day and Campus administrator are 
      responsible for determining if a report to Common Entry Point is required.  
      4. Log onto MAARC-mn.gov/dhs/reportadultabuse/ (insert address exactly  
      as indicated). Use drop down box area to navigate through the fields  
      5. May call if unable to make electronic report. # 844-880-1574/n/n Abuse Prohibition-VA Prevention Plan Page 19 
       
      6. Complete reporter/information source fields  
      7. Complete VA field  
      8. Complete alleged perpetrator field. May include up to 4 persons as needed  
      9. Complete maltreatment allegation detail field  
      10. Complete impact/effects on VA field  
      11. Complete additional sources of info and VA support persons field  
      12. Complete safety field and move to submit report field  
      13. Follow final instructions: affirm report; obtain report ID #; submit report; and  
      print report. Forward copies to Director of Adult Day.  
      14. Utilize Internal Investigation of Suspected Maltreatment Form or  
      Therapeutic Error with Injury Documentation Form  
      15. Contact Police if a crime has been committed-upon hearing the witnesses’  
      description of the incident, if it appears a crime may have been committed,  
      The reporter will immediately contact the police if the witness has not done  
      so./n/n description of the incident, if it appears a crime may have been committed,  
      The reporter will immediately contact the police if the witness has not done  
      so.  
      16. Utilize Written Communication with Vulnerable Adult Reporter-if needed.  
       
      Information re: Errors in Therapeutic Conduct  
      If Adult Day Director believes the incident was an error in therapeutic conduct, such as a med 
      error that resulted in harm or injury but should not be considered neglect, the RN for Adult Day 
      should be contacted and the following information will be included in the initial and subsequent 
      report to MAARC:  
      1. How the necessary physician care was provided in a timely fashion.  
      2. That the attending physician determined that after receiving cares the VA’s health will be 
      restored to the pre-existing condition.  
      3. The caregiver’s error was not part of a pattern of errors by the individual.  
      4. The caregiver immediately reported the error and it was recorded and investigated internally./n/n 3. The caregiver’s error was not part of a pattern of errors by the individual.  
      4. The caregiver immediately reported the error and it was recorded and investigated internally.  
      5. The RN identified and took corrective action and implemented measures designed to reduce 
      the risk of further occurrence of this and similar errors.  
      6. All of the provider’s actions have been documented and are available for review by surveyors 
      or other authorized agencies.
      `
      }
    }
  ]
}

export default hack;