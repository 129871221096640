import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  return (
    <Root className="flex items-start">
      <img className="logo-icon h-36" style={{ marginLeft: '-10px', marginTop: '12px', paddingRight: '12px' }} src="assets/logo/logo_icon.svg" alt="logo icon" />
      <img
        className="logo-icon h-64"
        style={{ marginLeft: '-20px' }}
        src="assets/logo/logo_text.svg"
        alt="logo text"
      />
    </Root>
  );
}

export default Logo;
