import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import clsx from 'clsx';
import * as yup from 'yup';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useEffect } from 'react';
import { useDebounce } from '@fuse/hooks';
import KeywordsIcon from '@mui/icons-material/ManageSearchOutlined';
import _ from '@lodash';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  title: yup.string().required('You must enter a keyword title'),
});

function KeywordItem(props) {
  const { keyword, removeKeyword } = props;

  const { control, formState, handleSubmit, reset, watch } = useForm({
    mode: 'onChange',
    defaultValues: keyword,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  const form = watch();

  useEffect(() => {
    reset(keyword);
  }, [keyword, reset]);

  const handleOnChange = useDebounce((_keyword, _form) => {
    if (!_keyword) {
      return;
    }
    if (form && !_.isEqual(_form, _keyword)) {
      console.log('updateKeyword', _keyword)
    }
  }, 300);

  useEffect(() => {
    handleOnChange(keyword, form);
  }, [handleOnChange, keyword, form]);

  function handleOnRemove() {
    removeKeyword(keyword.id)
  }

  return (
    <ListItem className="p-0 mb-16" dense>
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className={clsx('flex flex-1')}
            error={!!errors.title}
            helperText={errors?.title?.message}
            placeholder="Create new keyword"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeywordsIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleOnRemove}
                    className="w-32 h-32 p-0"
                    aria-keyword="Delete"
                    size="large"
                  >
                    <FuseSvgIcon color="action" size={20}>
                      heroicons-outline:trash
                    </FuseSvgIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </ListItem>
  );
}

export default KeywordItem;
