import { firestore as db } from 'src/app/auth/Firebase';
import { collection, getDocs, getDoc, doc, setDoc, addDoc, query, where, onSnapshot, Timestamp } from 'firebase/firestore';

const CN_PROMPTS = 'Prompts';
const CN_PROMPT_RUNS = 'Prompt Runs';
const CN_REFERRALS = 'referrals';
const CN_PROMPT_SETTINGS = 'Prompt Settings';
const CN_DATAMODELS = 'DataModels';

// use 
// addDoc to generate id
// setDoc to specify id?


// Guide: https://firebase.google.com/docs/firestore/manage-data/add-data#add_a_document

export async function createPromptRun(prompt, referral, overwritePromptData, gptModelId, dataModelCount=0, pageCount) {  
  
  let docRef = await addDoc(collection(db, CN_PROMPT_RUNS),{
    Name: prompt.Name,
    PromptId: prompt.id,
    ReferralId: referral.id,    
    ReferralFile: referral.sourceFile,
    CreatedOn: Timestamp.fromDate(new Date()),
    Status: 'Pending',
    Type: 'Prompt',
    Version: prompt.Version,
    Settings: gptModelId,
    Messages: prompt.Messages,
    Functions: prompt.Functions,
    Description: prompt.Description,
    OverwriteDataCol: overwritePromptData,   
    PagesUnique: pageCount,
    PagesToProcess: pageCount * dataModelCount,   // currently we process each model against each page
    PagesProcessed: 0,
    PagesFailedToProcess: 0
  })

  console.log(`Prompt Run created (id: ${docRef.id}): Applying prompt '${prompt.Name}' to referral '${referral.sourceFile}' (${referral.id})`);
  
  return docRef.id;
}

export async function getDataModels() {
  return new Promise(async (resolve, reject) => {
    
    let models = [];
    
    try {
      let col = collection(db, CN_DATAMODELS);
      let q = query(col, where("Enabled", "==", true));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach( (doc) => {
        let data = doc.data();
        data.id = doc.id;        
        models.push(data);
      });
      
    }catch(ex){
        console.log(ex.message);
        reject(ex.message);
    }
    resolve(models);
  });  
}

export async function getPromptSettings() {
  return new Promise(async (resolve, reject) => {
    console.log('Loading Prompt Settings');
    let col = collection(db, CN_PROMPT_SETTINGS);
    let q = query(col, where("Enabled", "==", true));
    const querySnapshot = await getDocs(q);
    let settings = [];
    querySnapshot.forEach( (doc) => {
      let data = doc.data();
      data.id = doc.id;
      settings.push(data);
    });

    resolve(settings);
  })
}

export async function getPromptRunId(prompt, referral, gptSettingsId) {
  // https://firebase.google.com/docs/firestore/query-data/queries#compound_and_queries
  
  let result = null;
  let col = collection(db, CN_PROMPT_RUNS); 

  let q = query(col, where("PromptId", "==", prompt.id), where("ReferralId", "==", referral.id), where("Status", "==", 'Running'), where("Settings", "==", gptSettingsId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach( (doc) => {    
    result = doc.id;        
  });

  return result;
}

export function subscribeTest(testId, setTestState) {

  // This Tests returns an listern to monitor change 
  // More Info: https://firebase.google.com/docs/firestore/query-data/listen#web-modular-api

  try { 
    onSnapshot(doc(db, CN_PROMPT_RUNS, testId), (doc) => {        
      let data = doc.data();
      data.id = doc.id;
      const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      console.log(source, " data: ", doc.data());
      setTestState(data);
    }); 
  }
  catch(ex){
    console.log(ex.message);
  }

} // subscribeTest

export async function getPrompts() {
  let prompts = [];

  let col = collection(db, CN_PROMPTS);    
  let q = query(col);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach( (doc) => {
    let data2 = doc.data();
    data2.id = doc.id;
    prompts.push(data2);
  });

  return prompts;
}

export async function getPrompt(name) {
  let data2 = null;
  let col = collection(db, CN_PROMPTS);    
  let q = query(col, where("Name", "==", name));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach( (doc) => {
    data2 = doc.data();
    data2.id = doc.id;    
  });

  return data2;
}

export async function getReferrals() {
  let prompts = [];

  let col = collection(db, CN_REFERRALS);    
  let q = query(col);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach( (doc) => {
    let data2 = doc.data();
    data2.id = doc.id;
    prompts.push(data2);
  });

  return prompts;
}



export async function getReferralPages(referralId){
  let pages = [];
  return new Promise(async (resolve, reject) => {
    console.log(`Loading Pages for referral ${referralId}`);

    const querySnapshot = await getDocs(collection(db, `${CN_REFERRALS}/${referralId}/docai-page`));
    querySnapshot.forEach((doc) => {      
      let page = doc.data();
      page.id = doc.id;
      pages.push(page);
    });

    resolve(pages);

  })
}


let data = {
  keys: [
    {
      id: 1,
      name: "Authentication As User",
      description: '',
      script: 'test1'
    },
    {
      id: 2,
      name: "Authentication As Administrator",
      description: '',
      script: 'test2'
    },
      

  ]
}


export default data;