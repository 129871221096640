import React, {useRef, useContext, useState} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FormIcon, RegIcon, PolicyIcon, OpenDocIcon } from './DocIcons';
import Divider from '@mui/material/Divider';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print'
import EmailIcon from '@mui/icons-material/AttachEmail';
import TagIcon from '@mui/icons-material/SellOutlined';
import TagEditIcon from '@mui/icons-material/StyleOutlined';
import KeywordsIcon from '@mui/icons-material/ManageSearchOutlined';
import CopyLinkIcon from '@mui/icons-material/LinkOutlined';
// TODO: Add Download Link
import clickTrackerHook from 'src/app/shared-components/click-tracker-hook';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { saveBrightenRequest } from '../../data';
import useChatContext from '../ChatContext';
import KeywordDialog from './KeywordDialog';
import Quiz from '@mui/icons-material/Quiz';

function DocumentItem(props){
  const { item, onOpenItem } = props;
  const [contextMenu, setContextMenu] = React.useState(null);
  const [openKeywordsDialog, setOpenKeywordsDialog] = useState(false);
  const clickRef = useRef();
  const dispatch = useDispatch();
  const { currentUser, chatSession  } = useChatContext();
  
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  function toggleKeywordsDialog() {
    setOpenKeywordsDialog(true);
  }

  clickTrackerHook({
    onSingleClick: (e) => {
      handleContextMenu(e);
      
    },
    onDoubleClick: (e) => {  
      setContextMenu(null);       
      onOpenItem(item);
    },
    ref: clickRef,
    latency: 200
  })

  function createSampleQuestions() {    
    setContextMenu(null)
    chatSession.createQuestions(item.name)
  }
  
  const closeContextMenu = () => {
    setContextMenu(null);
  };

  let key = createKey();

  let fileTypeIcon = null;
  if(item?.fileExt == '.pdf'){
    fileTypeIcon = <PdfIcon item={item} />
  }
  else if(item?.fileExt != '.docx' ||  item?.fileExt != '.doc'){
    fileTypeIcon = <WordIcon item={item} />
    //fileTypeIcon = Math.random() < 0.5 ? <PdfIcon item={item}  /> : <WordIcon item={item}  />;
  }
  else {
    fileTypeIcon = <span style={{width: '30px'}}>  </span>;
  }

  function onMakeBright(item) {
    
    saveBrightenRequest(chatSession, currentUser, item)
      .then(() => {
        showMakeBrightConfirmation();
      })

    setContextMenu(null);
  }

  function showMakeBrightConfirmation() {
    dispatch(showMessage(
      { 
      message: 'Your BrightSource request has been submitted.',
      autoHideDuration: 1500,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      }})
    );
  }

  /*
  <MenuItem onClick={closeContextMenu}>
            <ListItemIcon>
              <TagIcon fontSize="small" />
            </ListItemIcon>  
            Tag
          </MenuItem>
          <MenuItem onClick={closeContextMenu}>
            <ListItemIcon>
              <TagEditIcon fontSize="small" />
            </ListItemIcon>  
            Configure Tags
          </MenuItem>
  */

  return (
    <div ref={clickRef} style={{ cursor: 'context-menu' }}>
      <ListItemButton key={`${key}-policy`} sx={{ pl: 4 }} >
        {fileTypeIcon}  
      <ListItemText primary={item.name} sx={{ pl: '4px' }} />
      </ListItemButton>
      <Menu        
          dense
          open={contextMenu !== null}
          onClose={closeContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => {onOpenItem(item); setContextMenu(null);}}>        
            <ListItemIcon>
              <OpenInNewIcon fontSize="small" />
            </ListItemIcon>  
            Open
            </MenuItem>     

          <Divider />

          <MenuItem onClick={() => { onMakeBright(item)}}>
            <ListItemIcon>
              <FuseSvgIcon size={18} >
                heroicons-outline:dove
              </FuseSvgIcon>
            </ListItemIcon> 
            Brighten
          </MenuItem>
           
          
          <MenuItem onClick={toggleKeywordsDialog}>
            <ListItemIcon>
              <KeywordsIcon fontSize="small" />
            </ListItemIcon>  
            Keywords
          </MenuItem>

          {item.isBright && (
            <MenuItem onClick={createSampleQuestions}>
              <ListItemIcon>
                <Quiz fontSize="small" />
              </ListItemIcon>  
              Sample Questions
            </MenuItem>
          )}

          <Divider />
             
          <MenuItem onClick={closeContextMenu} disabled>
            <ListItemIcon>
              <PrintIcon fontSize="small" />
            </ListItemIcon>  
            Print
          </MenuItem>
          <MenuItem onClick={closeContextMenu} disabled>
            <ListItemIcon>
              <EmailIcon fontSize="small" />
            </ListItemIcon>  
            Email
          </MenuItem>
          <MenuItem onClick={closeContextMenu} disabled>
            <ListItemIcon >
              <CopyLinkIcon fontSize="small"  />
            </ListItemIcon>  
            Copy Link
          </MenuItem>
          <Divider />
          <MenuItem onClick={closeContextMenu} disabled>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>  
            Edit
          </MenuItem>
          <MenuItem onClick={closeContextMenu} disabled>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>  
            Delete
            
          </MenuItem>
        </Menu>
        <KeywordDialog open={openKeywordsDialog} item={item} onClose={() => setOpenKeywordsDialog(false)} />
    </div>
  )
  

}


function createKey() {
  function S4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return S4() + S4();
}

// opacity: '0.8',
function PdfIcon({item}){

  let isBright = (item?.isBright == null)? false : item.isBright;

  return <div style={{display: 'inline-block', position: 'relative', marginRight: '10px'}}>      
    <img 
      alt="pdf file" 
      src='/assets/icons/Acrobat.png' style={{width:'17px',  height: '17px', position:'relative', left: '2px', alignSelf: 'center'}} 
      />
      {isBright && <FuseSvgIcon size={14} style={{position: 'absolute', top: -3, left: -13}}>
        heroicons-outline:dove
      </FuseSvgIcon>
      }
  </div>

}

function WordIcon({item}){

  let isBright = (item?.isBright == null)? false : item.isBright;
  return (
    <div style={{display: 'inline-block', position: 'relative'}}> 
    <img
      src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/word_48x1.svg"
      style={{  marginRight: '10px'}}
      width="20"
      height="20"
      alt="Word product icon"
    />
      {isBright && <FuseSvgIcon size={14} style={{position: 'absolute', top: -3, left: -13}}>
          heroicons-outline:dove
        </FuseSvgIcon>
      }
    </div>
  )
}

export default DocumentItem;