import React from "react";
import Support from "./Layout";
import SubmitTicket from "./SubmitTicket";
import Training from "./Training";
// const ReferralModule = lazy(() => import('./ReferralModule'));

//import Chat from './chat/Chat';
// auth: ['admin', 'staff', 'ahi', 'demo', 'free', 'sholom', 'elysian'],

const ModuleRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "support",
      element: (
        <Support
          pageTitle="Support"
          pageDescription="Learn, get help, or report a problem"
        />
      ),
    },
    {
      path: "support/submit-ticket",
      element: (
        <SubmitTicket
          pageTitle="Submit Ticket"
          pageDescription="Learn, get help, or report a problem"
        />
      ),
    },
    {
      path: "support/training",
      element: (
        <Training
          pageTitle="Training"
          pageDescription="Learn, get help, or report a problem"
        />
      ),
    },
  ],
};
export default ModuleRoutes;
