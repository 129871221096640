import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import _ from '@lodash';
import Statuses from './Statuses';

const StyledBadge = styled(Badge)(({ theme, ...props }) => ({
  width: 40,
  height: 40,
  fontSize: 20,
  '& .MuiAvatar-root': {
    fontSize: 'inherit',
    color: theme.palette.text.secondary,
    fontWeight: 600,
  },
  '& .MuiBadge-badge': {
    backgroundColor: props.statuscolor,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}));

function ContactAvatar({ contact, className }) {
  const status = _.find(Statuses, { value: contact?.status });
  
  let path = ''
  
  /* public, anonymous access
    https://storage.googleapis.com/lumberjack-396203.appspot.com/referrals/44300546-bd14-481a-9d64-b0682e0ebd9d/Michael.png
    https://storage.googleapis.com/lumberjack-396203.appspot.com/referrals/43300546-bd14-481a-9d64-b0682e0ebd9d/Lois.png
    https://storage.googleapis.com/lumberjack-396203.appspot.com/referrals/42400546-bd14-481a-9d64-b0682e0ebd9d/Donald.png
    https://storage.googleapis.com/lumberjack-396203.appspot.com/referrals/41400546-bd14-481a-9d64-b0682e0ebd9d/Tim.png
  */

  /* ORIGINAL
    https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/referrals%2F41400546-bd14-481a-9d64-b0682e0ebd9d%2FTim.png?alt=media&token=519aa9a3-611a-4c78-aac9-42ad6d9703b8&_gl=1*jt7gkv*_ga*Njk3MDUzMTg3LjE2OTcxMzk2MTI.*_ga_CW55HF8NVT*MTY5NzgwNzQ5OS4yMC4xLjE2OTc4MTE2MDYuNjAuMC4w
    https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/referrals%2F44300546-bd14-481a-9d64-b0682e0ebd9d%2FMichael.png?alt=media&token=2ecb765b-2401-4b07-b1c4-2773359df6a8&_gl=1*frghqj*_ga*Njk3MDUzMTg3LjE2OTcxMzk2MTI.*_ga_CW55HF8NVT*MTY5NzgwNzQ5OS4yMC4xLjE2OTc4MTE4NTQuNTkuMC4w
    https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/referrals%2F42400546-bd14-481a-9d64-b0682e0ebd9d%2FDonald.png?alt=media&token=061af9ca-55f4-4f4c-9623-4ca6593cc8bc&_gl=1*5nu1le*_ga*Njk3MDUzMTg3LjE2OTcxMzk2MTI.*_ga_CW55HF8NVT*MTY5NzgwNzQ5OS4yMC4xLjE2OTc4MTE3OTYuNTUuMC4w
    https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/referrals%2F43300546-bd14-481a-9d64-b0682e0ebd9d%2FLois.png?alt=media&token=da60a52f-7b18-40e3-9d33-6bff8eae64a7&_gl=1*l22m4m*_ga*Njk3MDUzMTg3LjE2OTcxMzk2MTI.*_ga_CW55HF8NVT*MTY5NzgwNzQ5OS4yMC4xLjE2OTc4MTE4MjEuMzAuMC4w
  */ 
  if(contact?.Avatar == 'Tim.png')
    path = 'https://storage.googleapis.com/lumberjack-396203.appspot.com/referrals/41400546-bd14-481a-9d64-b0682e0ebd9d/Tim.png';
  else if(contact?.Avatar == 'Michael.png')
    path = 'https://storage.googleapis.com/lumberjack-396203.appspot.com/referrals/44300546-bd14-481a-9d64-b0682e0ebd9d/Michael.png';
  else if(contact?.Avatar == 'Donald.png')
    path = 'https://storage.googleapis.com/lumberjack-396203.appspot.com/referrals/42400546-bd14-481a-9d64-b0682e0ebd9d/Donald.png';
  else if(contact?.Avatar == 'Lois.png')
    path = 'https://storage.googleapis.com/lumberjack-396203.appspot.com/referrals/43300546-bd14-481a-9d64-b0682e0ebd9d/Lois.png';
  
    return (
    <StyledBadge
      className={className}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      statuscolor={status?.color}
    >
      <Avatar src={path} alt={contact?.PatientName} className="w-full h-full">
        {!contact?.Avatar || contact?.Avatar === '' ? contact?.PatientName : ''}
      </Avatar>
    </StyledBadge>
  );
}

export default ContactAvatar;
