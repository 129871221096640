import Typography from '@mui/material/Typography';
import Tabs from './controls/Tabs';



// import NavigationTabs from './tabs/';

/* <NavigationTabs /> */

function PageHeader() {
  
  return (<div className="p-20 pb-0" style={{borderColor: 'white', borderWidth: 0, borderBottomWidth: 0}}> 
      <Typography id='iamPageTitle' variant="h5">Elysian Senior Homes</Typography>
      <Tabs />
    </div>
  )

}


export default PageHeader;