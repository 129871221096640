import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import MuiPhoneNumber from "mui-phone-number";
import { firestore as db, auth } from "src/app/auth/Firebase";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Snackbar from "@mui/material/Snackbar";
import { Typography } from "@mui/material";

/**
 * Form Validation Schema
 */

const schema = yup.object().shape({
  displayName: yup
    .string()
    .required("You must enter Your Name")
    .min(3, "Name should be of minimum 3 characters length")
    .matches(/^\w+\s+\w+$/, "Please enter your first and last name."),
  email: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid Email"
    )
    .required("Email is required"),
  phone: yup
    .string()
    .notRequired()
    .min(10, "Phone number should be of minimum 10 digits"),
  issue: yup.string().required("You must enter issue"),
});

const defaultValues = {
  displayName: "",
  email: "",
  password: "",
  passwordConfirm: "",
  acceptTermsConditions: false,
  phone: "",
};

function SubmitTraning() {
  const [userDetails, setUserDetails] = useState(null);
  const [emailRequired, setEmailRequired] = useState(true);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitBtnText, setsubmitBtnText] = useState("Submit");
  const [showNotification, setshowNotification] = useState(false);
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const [alertstate, setAlertState] = useState({
    vertical: "bottom",
    horizontal: "right",
  });
  const { vertical, horizontal } = alertstate;

  useEffect(() => {
    if (!userDetails) return;
    setValue("displayName", userDetails.displayName);
    setValue("email", userDetails.email);
    setValue("phone", "");
    setValue("issue", "");
  }, [userDetails]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserDetails(currentUser);
      } else {
        setUserDetails(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const getPrevPath = () => {
    let path = window.location.pathname;
    let referrer = document.referrer;
    if (referrer) {
      let referrerUrl = new URL(referrer);

      path = referrerUrl.pathname;
    }

    return path;
  };

  const onSubmit = async ({ displayName, email, phone, issue }) => {
    const jsonObject = {
      issue,
      path: getPrevPath(),
      defaultUserName: userDetails.displayName,
      defaultUserEmail: userDetails.email,
      defaultUserPhone: userDetails.phoneNumber,
      userName: displayName,
      userEmail: email,
      userPhone: phone,
    };

    console.log("JSON Object:", jsonObject);

    try {
      setsubmitBtnText("Submitting....");
      setSubmitted(true);
      const docRef = await addDoc(
        collection(db, "TicketSubmissions"),
        jsonObject
      );
      // Add document ID to the jsonObject
      await updateDoc(doc(db, "TicketSubmissions", docRef.id), {
        ...jsonObject,
        id: docRef.id, // Store the document ID in the document
      });
      console.log("Document written with ID: ", docRef.id);
      setshowNotification(true);
      setValue("displayName", "");
      setValue("email", "");
      setValue("phone", "");
      setValue("issue", "");
      setSubmitted(false);
      setsubmitBtnText("Submit");
      setTimeout(() => {
        setshowNotification(false);
      }, 2000);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <Paper className="m-auto my-24 w-full sm:w-auto md:w-1/2 rounded-md sm:shadow md:shadow-none rtl:border-l-1">
        <div
          style={{
            background: "#03787c",
            padding: 50,
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Typography variant="h4" component="h2" color={"#fff"}>
            Dovaxis Technical Support
          </Typography>
        </div>
        <div className="w-full py-8 px-16 sm:p-48 md:p-64">
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={showNotification}
            message="Ticket has been submitted"
            key={"top" + "center"}
            autoHideDuration={2000}
          />

          {userDetails != null && (
            <form
              name="submitticket"
              noValidate
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit(onSubmit, setError)}
            >
              <Controller
                name="displayName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Your Name"
                    autoComplete="name"
                    autoFocus
                    type="name"
                    error={!!errors.displayName}
                    helperText={errors?.displayName?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    autoComplete="email"
                    label="Email Address"
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    required={emailRequired}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    defaultCountry="us"
                    onlyCountries={["us"]}
                    disableDropdown
                    variant="outlined"
                    className="mb-24"
                    label="Phone"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    required={phoneRequired}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="issue"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Issue"
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.issue}
                    helperText={errors?.issue?.message}
                    required
                  />
                )}
              />

              <Button
                variant="contained"
                color="secondary"
                className="w-full mt-24"
                disabled={_.isEmpty(dirtyFields) || !isValid || submitted}
                type="submit"
                size="large"
              >
                {submitBtnText}
              </Button>
            </form>
          )}
        </div>
      </Paper>
    </>
  );
}

export default SubmitTraning;
