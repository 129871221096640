import { TOOL_TYPES, CATEGORIES } from '../../configs/toolsConfig';

const ivConfig = {
  id: 'iv-calculator',
  name: 'IV Calculator',
  description: 'Calculate IV drip rates and infusion times',
  instructions: 'Enter volume, drop factor, and infusion time.',
  type: TOOL_TYPES.CALCULATOR,
  category: CATEGORIES.MEDICAL,
  parameters: {
    volume: {
      type: 'number',
      description: 'Volume to be infused in mL'
    },
    dropFactor: {
      type: 'number',
      description: 'Drop factor in drops/mL',
      default: 20
    },
    time: {
      type: 'number',
      description: 'Infusion time'
    },
    timeUnit: {
      type: 'string',
      enum: ['min', 'hr'],
      description: 'Time unit (minutes or hours)',
      default: 'hr'
    }
  }
};

export default ivConfig;