import React, {useEffect} from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import Reference from './Reference';
import ReferenceDialog from './ReferenceDialog';
import Feedback from './Feedback';

function References(props){

  const [indicatorAnchorEl, setIndicatorAnchorEl] = React.useState(null); // popover
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentVector, setCurrent] = React.useState();
  //const [referenceArray, setReferenceArray] = React.useState([]);
  const {chat} = props;
  let vectors = [];

  if(chat && chat?.response?.answered == false) return null;

  if(chat != null && chat?.response?.vectors != null){
    vectors = chat.response.vectors;
  }

  /*
  useEffect(()=> {
     // check if vectors changed
     if(vectors.length > 0 && referenceArray.length == 0 || JSON.stringify(vectors) != JSON.stringify(referenceArray)){  // if first time or vectors changed
      let newReferences = [];
      vectors.forEach((vector, idx) => {
        let reference = new Reference(vector);
        newReferences.push(reference);
      })
      setReferenceArray(newReferences);
    }
  }, vectors)
  */

  const onOpenDialog = (idx) => {
    let vector = vectors[idx];    
    setCurrent(vector);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleIndicatorClick = (event) => {
    setIndicatorAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setIndicatorAnchorEl(null);
  };

  const openPopover = Boolean(indicatorAnchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const getAnchorOrigin = (anchorEl) => {
    if(anchorEl == null) return {}
    const { top, bottom } = anchorEl.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // If the element is closer to the top, set anchorOrigin to bottom
    if (top < windowHeight / 2 && bottom < windowHeight - top) {
      return { vertical: 'bottom', horizontal: 'right' };
    }
    

    // Otherwise, set anchorOrigin to top
    return { vertical: 'top', horizontal: 'right' };

  };

  const getTransformOrigin = (anchorOrigin) => {
    // if(anchorOrigin == {}) return {};

    let vertical = anchorOrigin?.vertical == null? 'top' : anchorOrigin.vertical;
    let horizontal = anchorOrigin?.horizontal == null? 'right' : anchorOrigin.horizontal;

    return {
      vertical: vertical === 'top' ? 'bottom' : 'top',
      horizontal: horizontal
    };
  };

  

  let anchorOrigin = getAnchorOrigin(indicatorAnchorEl);
  const transformOrigin = getTransformOrigin(anchorOrigin);
 
  let contentTitle = '';
  let uniqueDocTitles = [];
  let sortedRefs = [];
  let pageInfo = '';
  
  const removeVendorInfo = (text) => {
    if(text == null) return '';
    text = text.replace(/Cornerstone Nursing and Rehab Center/gi, 'The Facility'); // case insensitive 
    text = text.replace(/Cornerstone/gi, 'The Facility');
    text = text.replace('(CNRC)', '');
    text = text.replace(/CNRC/gi, '');
    text = text.replace(/Nursing and Rehab Center/gi, '');
    text = text.replace('  ', ' ');
    return text;
  };

  if(chat.response?.vectors != null && chat.response.vectors.length > 0){

    try {

      contentTitle = chat.response.vectors[0].metadata['content-title'];
      contentTitle = removeVendorInfo(contentTitle);
      
      pageInfo = getPageMarkup(chat.response.vectors[0].metadata['pages']);
      uniqueDocTitles = [...new Set(chat.response.vectors.map(vector => vector.metadata['title']))];
      
      if(uniqueDocTitles == null || uniqueDocTitles.length == 0){   // for backwards compatibility
        uniqueDocTitles = [...new Set(chat.response.vectors.map(vector => vector.metadata['doc-title']))]
      }

    }
    catch(ex){
      console.log('Update reference model.')
    }
    
  }
  
  uniqueDocTitles.forEach(title => {
    sortedRefs.push({title: title, pageInfo, refs: chat?.response?.references})
  })

  let referenceMessage = '';
  if(chat?.response?.references != null){
    if(chat.response.references.length == 1){
      referenceMessage = `1 reference`;
    }else {
      referenceMessage = `${chat.response.references.length} references`;
    }    
  }

  if(anchorOrigin == null){
    anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
  }
  return (
    <>
      <Feedback />
      <Typography
        onClick={handleIndicatorClick}
        color="text.secondary"
        style={{justifySelf: 'end', marginRight: '8px'}}
      >
        {referenceMessage}
      </Typography>
      
      <StyledPopover
        id={id}
        open={openPopover}
        anchorEl={indicatorAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        style={{width: '100%', marginRight: '10px'}}
      >
        
        <MenuList style={{width: '100%', marginRight: '20px'}}>
          <ListItemText style={{marginLeft: '12px'}}><strong>{contentTitle}</strong></ListItemText>              
          <Divider />
          {sortedRefs.map((sref, index) => 
            <MenuItem key={`menuId${id}`} onClick={() => onOpenDialog(index)}>
             {/*  <ListItemText>&bull; {sref.title}: {sref.pageInfo} {`(${sref.refs.length} references)`}</ListItemText> */}             
             <ListItemText>&bull; {sref.pageInfo.replace('Page Page', 'Page')} - {sref.refs.length > 1? `(${sref.refs.length } references)`: `(1 reference)`}</ListItemText>
            </MenuItem>)
          }
        </MenuList>
       
      </StyledPopover>
      <ReferenceDialog open={openDialog} setShowDialog={setOpenDialog} vector={currentVector} chat={chat} />
      
      
    </>
  )
}

function getPageMarkup(val){
  if(val.indexOf('to -1') >= -1){
    return `Page ${val.replace(' to -1', '')}`;
  }else {
    return `Pages ${val}`;
  }
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  // Add custom styles here if needed
}));






export default References;