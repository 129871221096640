export const TOOL_TYPES = {
  CALCULATOR: 'calculator',
  CONVERTER: 'converter',
  ANALYZER: 'analyzer'
};

export const CATEGORIES = {
  MEDICAL: 'medical',
  CONVERSION: 'conversion',
  STAFFING: 'staffing'
};

export const categoryConfig = {
  [CATEGORIES.MEDICAL]: {
    id: CATEGORIES.MEDICAL,
    label: 'Medical Tools'
  },
  [CATEGORIES.CONVERSION]: {
    id: CATEGORIES.CONVERSION,
    label: 'Conversion Tools'
  },
  [CATEGORIES.STAFFING]: {
    id: CATEGORIES.STAFFING,
    label: 'Staffing Tools'
  }
};

export const toolsConfig = {
  defaultSettings: {
    enableHistory: true,
    savePreferences: true
  }
};
