import React, {lazy} from 'react';
import Layout from './Layout';
const HomePage = lazy(() => import('./pages/HomePage'));
const UserPage = lazy(() => import('./pages/UsersPage'));
const PermissionsPage = lazy(() => import('./pages/PermissionsPage'));
const RolePage= lazy(() => import('./pages/RolesPage'));

const IamRoutes = {
    settings: {
      layout: {
        config: {},
      },
    },
    routes: [
      
      {
        path: 'security',
        element: <Layout />,
        children: [     
          { path: '', element: <UserPage /> },
          { 
            path: 'users',
            element: <UserPage />,
            children: [ 
              { path: '', element: <UserPage /> },
              { path: 'Add', element: <UserPage /> },
              { path: 'Edit', element: <UserPage /> },
              { path: 'BulkEdit', element: <UserPage /> },
            ]
          },
          { path: 'permissions', element: <PermissionsPage /> },
          { path: 'roles', element: <RolePage /> },
        ]
      }
    ],
  };
  
  /*
  ,
        children: [          
          { path: 'dashboard', element: <Dashboard /> },
          { path: 'clinical', element: <Clinical />, 
            children: [              
              { path: ':id', element: <Clinical /> },
            ]
          },
          { path: 'financial', element: <Financial />, 
            children: [              
              { path: ':id', element: <Clinical /> },
            ]
          }
        ]
        */

  export default IamRoutes;