import { Tooltip } from "@mui/material";

const components = {};

export function registerComponent(name, Component) {
  components[name] = Component;
}

export default function FuseNavItem(props) {
  const { type, item } = props;
  const C = components[type];

  return C ? <Tooltip title={item.title} placement="right" disableHoverListener={item.type !=='item' || item.title.length <= 25}><div><C {...props} /></div></Tooltip> : null;
}
