import { firestore as db } from "src/app/auth/Firebase";
import {getFirestore, collection, addDoc, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';

const COL_PROMPT_LOG = 'Prompt Log';
const COL_PROMPT_TESTS = 'Prompt Tests';
const COL_REFERRALS = 'referrals';

export async function createPromptLog(id) {

  
  let docRef = await addDoc(collection(db, COL_PROMPT_LOG),{
    Name: 'DataMap AI',
    CreatedOn: new Date(),
    PromptTestId: id
  })

  console.log('What happens here?');
  return docRef.id;
}

// Update Prompt Test
export async function updatePromptTest(data) {
  
  let id = data.id;
  delete data.id;
  console.log(`Updating Prompt Test ${id}`);

  data.ModifiedOn = serverTimestamp();

  
  const docRef = doc(db, COL_PROMPT_TESTS, id);
  await updateDoc(docRef, data);

  data.id = id;
  return data;  
}

// Get Prompt Test
export async function getPromptTest(id) {
  
  const docRef = doc(db, COL_PROMPT_TESTS, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let data = docSnap.data()
    data.id = docSnap.id;
    return data;
  }
  return null;
}

// Get Referral
export async function getReferral(id) {
  
  const docRef = doc(db, COL_REFERRALS, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();    
  }
  
  return null;
}