import React, { useState, useEffect } from 'react';
import '../styles/UnitConverter.css';
import unitConverterConfig from '../config';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Typography, Alert, Button } from '@mui/material';
import useAuthContext from 'src/app/AuthContext';

const UnitConverter = ({
  calculator,
  isPinned = false,
  initialConversionType,
  initialFromUnit,
  initialToUnit,
  initialValue,
  historyValues
}) => {
  const [values, setValues] = useState({
    conversionType: initialConversionType || unitConverterConfig.conversionTypes[0],
    fromUnit: initialFromUnit || '',
    toUnit: initialToUnit || '',
    value: initialValue || ''
  });
  const [currentConfig, setCurrentConfig] = useState(unitConverterConfig.configs[values.conversionType]);
  const [output, setOutput] = useState('');
  const [result, setResult] = useState('');
  const [errors, setErrors] = useState({});

  const { userActivity, ACT } = useAuthContext();
  const [hasLoggedFocus, setHasLoggedFocus] = useState(false);

  const handleInputFocus = () => {
    if (isPinned && !hasLoggedFocus) {
      userActivity.log(ACT.TOOLS.CALC_UNIT_OPEN, { source: 'pinned_calculator' });
      setHasLoggedFocus(true);
    }
  };

  useEffect(() => {
    if (historyValues) {
      const newValues = {
        conversionType: historyValues.input.conversionType || unitConverterConfig.conversionTypes[0],
        fromUnit: historyValues.input.fromUnit || '',
        toUnit: historyValues.input.toUnit || '',
        value: historyValues.input.value?.toString() || ''
      };
      setValues(newValues);
      setCurrentConfig(unitConverterConfig.configs[newValues.conversionType]);
      setOutput(calculator.formatResult(historyValues.result));
      setResult(historyValues.result.formula || '');
      setErrors({});
    }
  }, [historyValues, calculator]);

  const handleCalculate = () => {
    try {
      const params = {
        conversionType: values.conversionType,
        fromUnit: values.fromUnit,
        toUnit: values.toUnit,
        value: Number(values.value)
      };

      const calculationResult = calculator.calculate(params);

      userActivity.log(ACT.TOOLS.CALC_UNIT_RUN);

      setOutput(`${calculationResult.convertedValue} ${values.toUnit}`);
      setResult(calculationResult.formula || '');
      setErrors({});
    } catch (error) {
      const errorMessages = error.message.split(', ');
      const newErrors = {};
      errorMessages.forEach(msg => {
        if (msg.includes('value')) newErrors.value = msg;
      });
      setErrors(newErrors);
    }
  };

  const handleInputChange = (field) => (e) => {
    const value = e.target.value;

    if (field === 'conversionType') {
      const newConfig = unitConverterConfig.configs[value];
      setValues(prev => ({
        ...prev,
        conversionType: value,
        fromUnit: newConfig.units[0],
        toUnit: newConfig.units[1],
        value: ''
      }));
      setCurrentConfig(newConfig);
      setOutput('');
      setResult('');
      setErrors({});
    } else {
      setValues(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  useEffect(() => {
    if (initialValue && !isNaN(parseFloat(initialValue)) && initialFromUnit && initialToUnit) {
      handleCalculate();
    }
  }, [initialValue, initialFromUnit, initialToUnit]);

  const handleSwapUnits = () => {
    setValues(prev => ({
      ...prev,
      fromUnit: prev.toUnit,
      toUnit: prev.fromUnit,
      value: output ? output.split(' ')[0] : prev.value
    }));
  };


  const handleClear = () => {
    setValues(prev => ({
      ...prev,
      value: '',
      fromUnit: prev.fromUnit,
      toUnit: prev.toUnit
    }));
    setOutput('');
    setResult('');
    setErrors({});
  };

  return (
    <div className="w-full max-w-320 mx-auto pb-24 pr-24 pl-24 pt-12 md:p-40">
      <Box
        className="mb-24 p-24"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: 1,
          height: '120px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {Object.keys(errors).length > 0 ? (
            <Alert severity="error" sx={{ backgroundColor: 'transparent' }}>
              {Object.values(errors)[0]}
            </Alert>
          ) : output ? (
            <Typography>
              {output}
              {result && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {result}
                </Typography>
              )}
            </Typography>
          ) : (
            <Typography color="text.secondary">{calculator.config.instructions}</Typography>
          )}
        </Box>
      </Box>

      <form className="flex flex-col gap-12">
        <FormControl variant="outlined">
          <InputLabel>Converter Type</InputLabel>
          <Select
            value={values.conversionType}
            onChange={handleInputChange('conversionType')}
            label="Converter Type"
          >
            {unitConverterConfig.conversionTypes.map(type => (
              <MenuItem key={type} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box className="flex gap-16">
          <FormControl fullWidth>
            <TextField
              label="Value"
              type="number"
              value={values.value}
              onChange={handleInputChange('value')}
              onFocus={handleInputFocus}
              error={!!errors.value}
              variant="outlined"
              placeholder="Enter value"
            />
          </FormControl>

          <FormControl variant="outlined" style={{ minWidth: 120 }}>
            <InputLabel>From</InputLabel>
            <Select
              value={values.fromUnit}
              onChange={handleInputChange('fromUnit')}
              label="From"
            >
              {currentConfig.units.map(unit => (
                <MenuItem key={unit} value={unit}>{unit}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <FormControl variant="outlined">
          <InputLabel>To</InputLabel>
          <Select
            value={values.toUnit}
            onChange={handleInputChange('toUnit')}
            label="To"
          >
            {currentConfig.units.map(unit => (
              <MenuItem key={unit} value={unit}>{unit}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box className="flex gap-12 mt-16">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSwapUnits}
            fullWidth
          >
            Swap Units
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleClear}
            fullWidth
          >
            Clear
          </Button>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleCalculate}
          fullWidth
        >
          Convert
        </Button>
      </form>
    </div>
  );
};

export default UnitConverter;
