
 const Regulations = [
  {
  name: 'Minnesota Regulations ', type: 'dir', open: true,
  items: [
    ]
  }
]

const Policies = {name: 'Policies & Procedures', type:"dir", vector: '', open: false,  
"items": [
      {
        "name": "Chapter 1",
        "type": "dir",
        "id": "90fd4992-a29f-4f3d-a57d-dec42a4c6341",
        "path": "Policies and Procedures/Chapter 1",
        "items": [
          {
            "name": "1.01 Admissions policy",
            "type": "policy",
            "id": "2313abc6-695f-493a-b232-3424118201ba",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2313abc6-695f-493a-b232-3424118201ba.pdf?alt=media&token=59027a5d-897e-4fe1-b09f-640a532d23ee",
            "fileExt": ".pdf",
            "fileName": "1.01 Admissions policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.02 Application and Deposit for Residency policy",
            "type": "policy",
            "id": "9a892c58-c1d7-4a03-9d0a-f3fb5d115a8c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9a892c58-c1d7-4a03-9d0a-f3fb5d115a8c.pdf?alt=media&token=d55d2458-1769-4a94-92b0-5002f8777efb",
            "fileExt": ".pdf",
            "fileName": "1.02 Application and Deposit for Residency policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.03  Community Fee policy",
            "type": "policy",
            "id": "49f4ebca-d017-42c3-8be8-fe6c7544ea8f",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F49f4ebca-d017-42c3-8be8-fe6c7544ea8f.pdf?alt=media&token=9e1510be-f605-4079-a750-3aaaabd401ee",
            "fileExt": ".pdf",
            "fileName": "1.03  Community Fee policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.04  Uniform Disclosure of Assisted Living Services & Amenities (UDALSA) policy",
            "type": "policy",
            "id": "b553b5e1-4fdb-4da2-b52f-a133f781559e",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb553b5e1-4fdb-4da2-b52f-a133f781559e.pdf?alt=media&token=a98ca928-8a2f-4636-bd4d-73976b34742f",
            "fileExt": ".pdf",
            "fileName": "1.04  Uniform Disclosure of Assisted Living Services & Amenities (UDALSA) policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.05  Signing an Assisted Living Contract policy",
            "type": "policy",
            "id": "8fb4ed10-a611-4e0b-a091-7d12f69ddbd0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8fb4ed10-a611-4e0b-a091-7d12f69ddbd0.pdf?alt=media&token=0503db40-0037-4520-85f2-5fef250bfcc0",
            "fileExt": ".pdf",
            "fileName": "1.05  Signing an Assisted Living Contract policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.08 Designated Representative policy Lake City",
            "type": "policy",
            "id": "9a98d982-3dd4-4324-b884-6f678107d469",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9a98d982-3dd4-4324-b884-6f678107d469.pdf?alt=media&token=6654cce4-de8d-4413-800c-1a5e4b7e081c",
            "fileExt": ".pdf",
            "fileName": "1.08 Designated Representative policy Lake City.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.09 Contact Information Form (Designated Rep & Emergency)",
            "type": "form",
            "id": "fec66459-cb11-49a2-8786-98c9f44bca7b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ffec66459-cb11-49a2-8786-98c9f44bca7b.pdf?alt=media&token=93d53a1a-be23-440f-8de3-d46d0216becd",
            "fileExt": ".pdf",
            "fileName": "1.09 Contact Information Form (Designated Rep & Emergency).pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.12 Receipt for Key-FOB and Miscellaneous",
            "type": "default",
            "id": "bae2f0bf-6388-4b6f-b0c8-9a9d33a65d76",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fbae2f0bf-6388-4b6f-b0c8-9a9d33a65d76.pdf?alt=media&token=b35237b7-bafe-4ce8-9aa5-9e7deff9122d",
            "fileExt": ".pdf",
            "fileName": "1.12 Receipt for Key-FOB and Miscellaneous.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.14  Welcoming New Residents policy",
            "type": "policy",
            "id": "f0dc777f-ae45-4971-8905-9a00c08f00f0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff0dc777f-ae45-4971-8905-9a00c08f00f0.pdf?alt=media&token=9c8d63d2-d7da-46f9-b661-4ce4d944cc1f",
            "fileExt": ".pdf",
            "fileName": "1.14  Welcoming New Residents policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.15 Contract Termination Policy",
            "type": "policy",
            "id": "984362b2-c715-491c-90ca-25725b441fb5",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F984362b2-c715-491c-90ca-25725b441fb5.pdf?alt=media&token=c9661cdb-f8db-41fd-badd-c9891455c6df",
            "fileExt": ".pdf",
            "fileName": "1.15 Contract Termination Policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.22_CoordinatedMovespolicy",
            "type": "default",
            "id": "dda02596-4486-46db-833e-bbde50635764",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdda02596-4486-46db-833e-bbde50635764.pdf?alt=media&token=093008f8-3b94-44c8-9ade-b6eb6b29b006",
            "fileExt": ".pdf",
            "fileName": "1.22_CoordinatedMovespolicy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.23_EmergencyRelocationpolicy",
            "type": "default",
            "id": "b67b0a65-9653-44a5-9e36-e760faf40d2e",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb67b0a65-9653-44a5-9e36-e760faf40d2e.pdf?alt=media&token=84c173b2-ffdc-4ef3-8789-0a91ecc71f06",
            "fileExt": ".pdf",
            "fileName": "1.23_EmergencyRelocationpolicy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.24 NonRenewalofHousingPolicy",
            "type": "default",
            "id": "00e206ef-a1d2-4521-a6f6-61da78ff17a0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F00e206ef-a1d2-4521-a6f6-61da78ff17a0.pdf?alt=media&token=4d32c952-a362-409b-8cb9-01b6e9ab0de2",
            "fileExt": ".pdf",
            "fileName": "1.24 NonRenewalofHousingPolicy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.25 Resident Move-Out sample letter",
            "type": "default",
            "id": "3fe7d2ad-2613-4ff2-9ddd-5aed6600fac4",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3fe7d2ad-2613-4ff2-9ddd-5aed6600fac4.pdf?alt=media&token=c0815162-f0b8-4be7-bc39-41c279e3a5cc",
            "fileExt": ".pdf",
            "fileName": "1.25 Resident Move-Out sample letter.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.28 Final Accounting – Return of Money and Property policy",
            "type": "policy",
            "id": "02a9146f-31f9-44fd-9517-d275e576c205",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F02a9146f-31f9-44fd-9517-d275e576c205.pdf?alt=media&token=acca0b7c-d16e-443c-85ea-5c78b278b4c5",
            "fileExt": ".pdf",
            "fileName": "1.28 Final Accounting – Return of Money and Property policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.29 Marketing Plan template",
            "type": "default",
            "id": "3ebf68ff-e8d0-484d-9c9f-a757c06b63c1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3ebf68ff-e8d0-484d-9c9f-a757c06b63c1.pdf?alt=media&token=d10e65df-761a-413c-b7ea-79aafd2c2cd1",
            "fileExt": ".pdf",
            "fileName": "1.29 Marketing Plan template.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.33 Waiting List policy",
            "type": "policy",
            "id": "c51525e8-3030-4e62-b91c-5fd80be071d9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc51525e8-3030-4e62-b91c-5fd80be071d9.pdf?alt=media&token=fb0036e6-a252-41b1-ab8c-697d872f918b",
            "fileExt": ".pdf",
            "fileName": "1.33 Waiting List policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.34  Activity Programming policy",
            "type": "policy",
            "id": "ec852aef-2830-48c8-92e1-f8749cae19c1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fec852aef-2830-48c8-92e1-f8749cae19c1.pdf?alt=media&token=e889d3dd-7ff7-40e9-9c36-f12fc1725a85",
            "fileExt": ".pdf",
            "fileName": "1.34  Activity Programming policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.35 Bulletin Board policy",
            "type": "policy",
            "id": "2c167486-cd67-4ba6-aec0-8688c0765d8d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2c167486-cd67-4ba6-aec0-8688c0765d8d.pdf?alt=media&token=0ac04b2e-2961-4413-bb3d-3c67f1e35be6",
            "fileExt": ".pdf",
            "fileName": "1.35 Bulletin Board policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.36 Handling of Resident Finances and Property policy",
            "type": "policy",
            "id": "26957e6a-847b-423f-bafc-5c13c5164d38",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F26957e6a-847b-423f-bafc-5c13c5164d38.pdf?alt=media&token=21a30046-de0f-45f4-92b9-5be2273ed4ef",
            "fileExt": ".pdf",
            "fileName": "1.36 Handling of Resident Finances and Property policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.37 Pet policy - non service animal policy",
            "type": "policy",
            "id": "dabca38f-ddf9-4970-95d6-5bf3a8d14df2",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdabca38f-ddf9-4970-95d6-5bf3a8d14df2.pdf?alt=media&token=4186d9c8-aed1-49a4-9a3a-a66239c0aa39",
            "fileExt": ".pdf",
            "fileName": "1.37 Pet policy - non service animal policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.38 Service & Companion Animals policy",
            "type": "policy",
            "id": "e12f4fd3-be22-4330-8f4e-015164ecb9b3",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe12f4fd3-be22-4330-8f4e-015164ecb9b3.pdf?alt=media&token=b3fa82b5-ed0e-4987-9e90-3bb6c2e8ba75",
            "fileExt": ".pdf",
            "fileName": "1.38 Service & Companion Animals policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.39  Resident Right to Return policy",
            "type": "policy",
            "id": "d2a50cb4-2593-4a16-96fd-88917798c770",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd2a50cb4-2593-4a16-96fd-88917798c770.pdf?alt=media&token=be9739f7-c5b5-4623-ab58-2068b6f16c82",
            "fileExt": ".pdf",
            "fileName": "1.39  Resident Right to Return policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.40 Resident Sign In-Out policy",
            "type": "policy",
            "id": "093882af-588e-463f-bda0-805306fbdf5d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F093882af-588e-463f-bda0-805306fbdf5d.pdf?alt=media&token=7aff52e9-4d0d-456f-a082-2800329be58b",
            "fileExt": ".pdf",
            "fileName": "1.40 Resident Sign In-Out policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.41  Risk Agreement policy",
            "type": "policy",
            "id": "8d777d5c-b810-479f-8019-4089f6179e0d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8d777d5c-b810-479f-8019-4089f6179e0d.pdf?alt=media&token=b5dc12de-35da-4083-b144-484e65647030",
            "fileExt": ".pdf",
            "fileName": "1.41  Risk Agreement policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.43_TransferofResidentwithinFacilitypolicy",
            "type": "default",
            "id": "51ce5cff-49c2-4d21-bc13-5baa10b2b123",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F51ce5cff-49c2-4d21-bc13-5baa10b2b123.pdf?alt=media&token=9d63a69c-6417-47b5-bea5-e2854a31ac45",
            "fileExt": ".pdf",
            "fileName": "1.43_TransferofResidentwithinFacilitypolicy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.44  Use of Facility Common Areas policy",
            "type": "policy",
            "id": "7bd08087-603d-400b-9cdf-985f04e5ac2e",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7bd08087-603d-400b-9cdf-985f04e5ac2e.pdf?alt=media&token=7d8a809f-2a26-4dd8-8f19-55ed5851a066",
            "fileExt": ".pdf",
            "fileName": "1.44  Use of Facility Common Areas policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "1.11 Release Form",
            "type": "form",
            "id": "32b16bad-6f8c-4412-8527-ee84f1f28fdf",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F32b16bad-6f8c-4412-8527-ee84f1f28fdf.pdf?alt=media&token=b051ef92-b2be-4b70-982c-cf28ed85824b",
            "fileExt": ".pdf",
            "fileName": "1.11 Release Form.pdf"
          },
          {
            "name": "1.13 Move In-Out Inspection Report form",
            "type": "form",
            "id": "30cc3d0d-071c-4a99-bf94-00ee21c462b6",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F30cc3d0d-071c-4a99-bf94-00ee21c462b6.pdf?alt=media&token=9416f0d2-8f19-42f3-9458-26a0fb9067ba",
            "fileExt": ".pdf",
            "fileName": "1.13 Move In-Out Inspection Report form.pdf"
          },
          {
            "name": "1.16 Pre-Termination Meeting Notice sample form",
            "type": "form",
            "id": "631f1121-7a67-407b-93d5-b6b99e9a74f6",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F631f1121-7a67-407b-93d5-b6b99e9a74f6.pdf?alt=media&token=4447abdc-fd5b-48d9-9762-2d2fa9a2923d",
            "fileExt": ".pdf",
            "fileName": "1.16 Pre-Termination Meeting Notice sample form.pdf"
          },
          {
            "name": "1.17 Pre-Termination Meeting Summary sample form",
            "type": "form",
            "id": "253d451d-828e-47c6-bd7e-cfc56dd4f8cd",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F253d451d-828e-47c6-bd7e-cfc56dd4f8cd.pdf?alt=media&token=bdb57c12-50a3-43c9-bd4a-0c99bd4b61cc",
            "fileExt": ".pdf",
            "fileName": "1.17 Pre-Termination Meeting Summary sample form.pdf"
          },
          {
            "name": "1.18 ResidentTerminationNoticeSampleForm",
            "type": "policy",
            "id": "42a991b1-6795-4f45-9ac6-2cf84453ab8c",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F42a991b1-6795-4f45-9ac6-2cf84453ab8c.pdf?alt=media&token=e84d3162-d34a-48a1-a7ef-62c2789b8616",
            "fileExt": ".pdf",
            "fileName": "1.18 ResidentTerminationNoticeSampleForm.pdf"
          },
          {
            "name": "1.19 Resident Relocation Evaluation sample form",
            "type": "form",
            "id": "d6199518-be89-4651-a461-6c26ecc178bc",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd6199518-be89-4651-a461-6c26ecc178bc.pdf?alt=media&token=597ec566-1c48-4870-a664-58c26a200320",
            "fileExt": ".pdf",
            "fileName": "1.19 Resident Relocation Evaluation sample form.pdf"
          },
          {
            "name": "1.20 Resident Relocation Plan sample form",
            "type": "form",
            "id": "ca34b1ab-c14b-4292-a17e-aef1cc5ddcc4",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fca34b1ab-c14b-4292-a17e-aef1cc5ddcc4.pdf?alt=media&token=7d3a92e6-8369-4d41-88cb-40241ebf936c",
            "fileExt": ".pdf",
            "fileName": "1.20 Resident Relocation Plan sample form.pdf"
          },
          {
            "name": "1.21 Resident Discharge Summary sample form",
            "type": "form",
            "id": "03da3677-5eb5-4bc0-b1dc-18a227c0f464",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F03da3677-5eb5-4bc0-b1dc-18a227c0f464.pdf?alt=media&token=c7798d98-983e-4e07-b443-9c6dbcb159b1",
            "fileExt": ".pdf",
            "fileName": "1.21 Resident Discharge Summary sample form.pdf"
          },
          {
            "name": "1.26 Resident Move-Out Reconciliation form",
            "type": "form",
            "id": "3a64d4e8-3ffc-42f1-a4cc-5583ee0f1748",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3a64d4e8-3ffc-42f1-a4cc-5583ee0f1748.pdf?alt=media&token=54df48eb-5bbd-4554-830c-aa5750bbb325",
            "fileExt": ".pdf",
            "fileName": "1.26 Resident Move-Out Reconciliation form.pdf"
          },
          {
            "name": "1.27 Move-Out Checklist form",
            "type": "form",
            "id": "868d38f1-0fb7-4bc1-9ff1-e763b9e0aa50",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F868d38f1-0fb7-4bc1-9ff1-e763b9e0aa50.pdf?alt=media&token=0c64f235-beb4-4ab1-a172-4c42da752118",
            "fileExt": ".pdf",
            "fileName": "1.27 Move-Out Checklist form.pdf"
          },
          {
            "name": "1.31 Inquiry Sample Form 1",
            "type": "form",
            "id": "cd06f4ec-9e4b-4336-be1b-ddebe1112147",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fcd06f4ec-9e4b-4336-be1b-ddebe1112147.pdf?alt=media&token=65f03fa3-9229-4157-808f-34f5a485131a",
            "fileExt": ".pdf",
            "fileName": "1.31 Inquiry Sample Form 1.pdf"
          },
          {
            "name": "1.32 Inquiry Sample Form 2",
            "type": "form",
            "id": "31f7ea28-076d-4209-b6a7-676261ebb3f7",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F31f7ea28-076d-4209-b6a7-676261ebb3f7.pdf?alt=media&token=7f016b02-6951-47ba-a79e-ba8bced2b272",
            "fileExt": ".pdf",
            "fileName": "1.32 Inquiry Sample Form 2.pdf"
          }
        ]
      },
      {
        "name": "Chapter 10",
        "type": "dir",
        "id": "69dcff7e-844e-401e-909f-18c357c40590",
        "path": "Policies and Procedures/Chapter 10",
        "items": [
          {
            "name": "10.01 Elysian Housekeeping and Laundry Service policy",
            "type": "policy",
            "id": "1269dbe6-df2c-4f76-a753-be8565850b2e",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1269dbe6-df2c-4f76-a753-be8565850b2e.pdf?alt=media&token=4d7da0e8-b675-4aea-80f2-15c935aa7735",
            "fileExt": ".pdf",
            "fileName": "10.01 Elysian Housekeeping and Laundry Service policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "10.05 Maintenance Request form",
            "type": "form",
            "id": "6bbe20ef-5c4e-466a-b686-2cae62a726ec",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F6bbe20ef-5c4e-466a-b686-2cae62a726ec.pdf?alt=media&token=ffc0f432-b9e4-45a1-b724-ed8106ad1272",
            "fileExt": ".pdf",
            "fileName": "10.05 Maintenance Request form.pdf"
          },
          {
            "name": "10.06 Notice of Entrance form",
            "type": "form",
            "id": "4eddd5bd-14d6-4ad2-9f78-984377cd7882",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4eddd5bd-14d6-4ad2-9f78-984377cd7882.pdf?alt=media&token=c85e7b82-d24e-4582-bd77-928b319ea742",
            "fileExt": ".pdf",
            "fileName": "10.06 Notice of Entrance form.pdf"
          }
        ]
      },
      {
        "name": "Chapter 11",
        "type": "dir",
        "id": "f83df395-b8e1-4b39-a6ac-07c69a253772",
        "path": "Policies and Procedures/Chapter 11",
        "items": [
          {
            "name": "11.01 AWAIR policy",
            "type": "policy",
            "id": "97fb162d-c83c-45ef-8f2f-556032ffc241",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F97fb162d-c83c-45ef-8f2f-556032ffc241.pdf?alt=media&token=43df553c-418c-49ed-8204-66761170135f",
            "fileExt": ".pdf",
            "fileName": "11.01 AWAIR policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "11.03 Elysian Employee Right to Know policy",
            "type": "policy",
            "id": "0b67ec67-f58b-43f5-8a51-1b0885ca7f64",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0b67ec67-f58b-43f5-8a51-1b0885ca7f64.pdf?alt=media&token=a88c94e8-e58b-4b0f-85b1-2423f78d0e47",
            "fileExt": ".pdf",
            "fileName": "11.03 Elysian Employee Right to Know policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "11.04 Employee Right to Know model program MN OSHA 2016",
            "type": "default",
            "id": "9a6c0acf-1535-4733-b017-ec2052b7deb3",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9a6c0acf-1535-4733-b017-ec2052b7deb3.pdf?alt=media&token=5336a80e-a060-464b-a667-daccf0329cc8",
            "fileExt": ".pdf",
            "fileName": "11.04 Employee Right to Know model program MN OSHA 2016.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "11.07 OSHA Online Resources",
            "type": "default",
            "id": "1d565f68-6ca6-4744-bbec-b966e4dcb81d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1d565f68-6ca6-4744-bbec-b966e4dcb81d.pdf?alt=media&token=f0401ec6-01ba-4bf6-876b-b73f701ca8cf",
            "fileExt": ".pdf",
            "fileName": "11.07 OSHA Online Resources.pdf",
            "keywords": [],
            "isBright": true
          }
        ]
      },
      {
        "name": "Chapter 12",
        "type": "dir",
        "id": "2c2678d7-821c-477d-8c17-ecc88d578d82",
        "path": "Policies and Procedures/Chapter 12",
        "items": [
          {
            "name": "12.01 Food Service & Menu Planning policy",
            "type": "policy",
            "id": "b04da25b-a9b4-48be-b8fe-e9a86cfaacd1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb04da25b-a9b4-48be-b8fe-e9a86cfaacd1.pdf?alt=media&token=20c6d807-472b-4b7b-a0c6-c4eee9465347",
            "fileExt": ".pdf",
            "fileName": "12.01 Food Service & Menu Planning policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "12.02  Dining Service policy",
            "type": "policy",
            "id": "79747e3f-9011-4c46-bcdd-866dc3ea9eff",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F79747e3f-9011-4c46-bcdd-866dc3ea9eff.pdf?alt=media&token=bf9b6357-a86b-4ea1-a98f-77a408d3beeb",
            "fileExt": ".pdf",
            "fileName": "12.02  Dining Service policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "12.03 Guest Meal policy",
            "type": "policy",
            "id": "c42e8d94-3d31-43fd-935b-439a93a9d967",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc42e8d94-3d31-43fd-935b-439a93a9d967.pdf?alt=media&token=132b1c12-8f8b-4bf9-90fb-6f9b40057248",
            "fileExt": ".pdf",
            "fileName": "12.03 Guest Meal policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "12.04  Meal Delivery policy",
            "type": "policy",
            "id": "1989bfa2-0fe4-4543-bb4b-db69d4111520",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1989bfa2-0fe4-4543-bb4b-db69d4111520.pdf?alt=media&token=b479ed28-e3e5-409e-9220-03dcd971da77",
            "fileExt": ".pdf",
            "fileName": "12.04  Meal Delivery policy.pdf",
            "keywords": [],
            "isBright": true
          }
        ]
      },
      {
        "name": "Chapter 2",
        "type": "dir",
        "id": "4215039b-9b04-4c82-8e2e-e35c4b95b358",
        "path": "Policies and Procedures/Chapter 2",
        "items": [
          {
            "name": "2.01  24 Hour Emergency Response policy",
            "type": "policy",
            "id": "4da8e1cd-3816-45ba-953f-506e7e78f8f1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4da8e1cd-3816-45ba-953f-506e7e78f8f1.pdf?alt=media&token=be18f777-ae6a-4d15-b06f-e6bfe7cfc643",
            "fileExt": ".pdf",
            "fileName": "2.01  24 Hour Emergency Response policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.02 Acceptance of Resident policy",
            "type": "policy",
            "id": "14496ace-6298-49d8-869b-1ee8db13e2c9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F14496ace-6298-49d8-869b-1ee8db13e2c9.pdf?alt=media&token=c8f0cb4f-ba85-411d-a68b-e91582b3ee38",
            "fileExt": ".pdf",
            "fileName": "2.02 Acceptance of Resident policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.03 Additional Service Referral policy",
            "type": "policy",
            "id": "4939a887-4735-4380-a71d-3f7e0b32b166",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4939a887-4735-4380-a71d-3f7e0b32b166.pdf?alt=media&token=3b2cce13-9391-4fb4-9197-60dd4d61d97c",
            "fileExt": ".pdf",
            "fileName": "2.03 Additional Service Referral policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.04 Assisted Living  License and Posting policy",
            "type": "policy",
            "id": "21e033c7-730b-4395-97b6-5ae643b07094",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F21e033c7-730b-4395-97b6-5ae643b07094.pdf?alt=media&token=8f03c344-55f9-417f-b086-44ba45c92681",
            "fileExt": ".pdf",
            "fileName": "2.04 Assisted Living  License and Posting policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.05 Bill of Rights policy",
            "type": "policy",
            "id": "a646ef85-70a5-474b-b45f-50bed09ef783",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa646ef85-70a5-474b-b45f-50bed09ef783.pdf?alt=media&token=448d85e5-e33c-4af0-8fe8-076adb5731c2",
            "fileExt": ".pdf",
            "fileName": "2.05 Bill of Rights policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.06 Billing policy",
            "type": "policy",
            "id": "e273bf15-9b3d-4239-b5d6-3469ea9ccee3",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe273bf15-9b3d-4239-b5d6-3469ea9ccee3.pdf?alt=media&token=d0a2eb72-82b3-4481-a1c3-17ce7215aed5",
            "fileExt": ".pdf",
            "fileName": "2.06 Billing policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.08 Communication Book policy",
            "type": "policy",
            "id": "5e584e0d-c2f1-4f5e-8cfa-82565c3c17a2",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F5e584e0d-c2f1-4f5e-8cfa-82565c3c17a2.pdf?alt=media&token=acfa37a9-407e-435b-b414-3ede5f886979",
            "fileExt": ".pdf",
            "fileName": "2.08 Communication Book policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.09 Complaint-Grievance policy",
            "type": "policy",
            "id": "d49ff620-2037-4d8b-97c6-34d21742a4ef",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd49ff620-2037-4d8b-97c6-34d21742a4ef.pdf?alt=media&token=b660deb0-93f3-4d11-b925-4a890fccbcef",
            "fileExt": ".pdf",
            "fileName": "2.09 Complaint-Grievance policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.10_Complaint-GrievancePostingpolicyv2",
            "type": "default",
            "id": "b0cb065e-24fb-4730-9d3b-093473cab6ce",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb0cb065e-24fb-4730-9d3b-093473cab6ce.pdf?alt=media&token=6a24b61f-9520-4ecb-9e41-db671d3bf630",
            "fileExt": ".pdf",
            "fileName": "2.10_Complaint-GrievancePostingpolicyv2.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.12 Confidentiality policy",
            "type": "policy",
            "id": "972b2cce-2730-412d-b292-a06e20788b7b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F972b2cce-2730-412d-b292-a06e20788b7b.pdf?alt=media&token=8c1e1a7e-c58d-427a-b20a-626e86ead8a6",
            "fileExt": ".pdf",
            "fileName": "2.12 Confidentiality policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.13  CPR DNR policy",
            "type": "policy",
            "id": "f7471396-a6ce-4200-a171-2a4fb3e86f3d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff7471396-a6ce-4200-a171-2a4fb3e86f3d.pdf?alt=media&token=6fc6d8b4-2590-419a-8b0b-d2990d2c0004",
            "fileExt": ".pdf",
            "fileName": "2.13  CPR DNR policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.14  Death of a Resident policy",
            "type": "policy",
            "id": "62745199-3a3d-4f3d-9f5d-fd5c92ced3cf",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F62745199-3a3d-4f3d-9f5d-fd5c92ced3cf.pdf?alt=media&token=a04f0f6d-1f89-4ad0-bd34-0b6f5fdce6f4",
            "fileExt": ".pdf",
            "fileName": "2.14  Death of a Resident policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.15   Electronic Monitoring policy",
            "type": "policy",
            "id": "dd4809ed-0aab-4b32-ba79-4119e9a9aa35",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdd4809ed-0aab-4b32-ba79-4119e9a9aa35.pdf?alt=media&token=958fe926-13fb-4250-84f4-157352f863d9",
            "fileExt": ".pdf",
            "fileName": "2.15   Electronic Monitoring policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.16 Electronic Monitoring Preparedness checklist",
            "type": "default",
            "id": "3c527853-b18c-4b67-a46a-f6ae594000f7",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3c527853-b18c-4b67-a46a-f6ae594000f7.pdf?alt=media&token=5005dbab-164e-4fbb-a508-b8b421a00a0d",
            "fileExt": ".pdf",
            "fileName": "2.16 Electronic Monitoring Preparedness checklist.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.21 Emergency 911 policy",
            "type": "policy",
            "id": "9e274cb5-a557-40db-8a6d-f1b1c9ecc82c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9e274cb5-a557-40db-8a6d-f1b1c9ecc82c.pdf?alt=media&token=595b4f1c-f2a3-4e2a-b9b6-5d823bf586d6",
            "fileExt": ".pdf",
            "fileName": "2.21 Emergency 911 policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.22 Emergency Contacts for Staff policy",
            "type": "policy",
            "id": "647228cb-951e-46d9-874e-100bacb3527c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F647228cb-951e-46d9-874e-100bacb3527c.pdf?alt=media&token=9578d63a-f071-4c27-af97-7e8afe6e5372",
            "fileExt": ".pdf",
            "fileName": "2.22 Emergency Contacts for Staff policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.24  Facility Restrictions policy",
            "type": "policy",
            "id": "0e44963a-49c5-48ed-8dc4-5b0d67d904ad",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0e44963a-49c5-48ed-8dc4-5b0d67d904ad.pdf?alt=media&token=b200fedd-042f-41e9-8905-d03b5201e471",
            "fileExt": ".pdf",
            "fileName": "2.24  Facility Restrictions policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.25  Incident Report policy",
            "type": "policy",
            "id": "d849f7bb-9665-4271-b1f1-4107acfa7628",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd849f7bb-9665-4271-b1f1-4107acfa7628.pdf?alt=media&token=66039075-5c46-4786-811c-bf54adb5ed92",
            "fileExt": ".pdf",
            "fileName": "2.25  Incident Report policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.28 Missing Resident policy",
            "type": "policy",
            "id": "7d4429aa-7f98-42de-8943-1b9d4122aa57",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7d4429aa-7f98-42de-8943-1b9d4122aa57.pdf?alt=media&token=8760c6ea-f1b8-492f-b36b-f1032a355082",
            "fileExt": ".pdf",
            "fileName": "2.28 Missing Resident policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.30_ProtectingResidentRightspolicy",
            "type": "default",
            "id": "3c159538-91f7-4a26-b3b0-1e1534c529b4",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3c159538-91f7-4a26-b3b0-1e1534c529b4.pdf?alt=media&token=c2a695a1-1f11-495c-bd44-abe3e8c7decb",
            "fileExt": ".pdf",
            "fileName": "2.30_ProtectingResidentRightspolicy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.31 Quality Management Project policy",
            "type": "policy",
            "id": "d46c991c-ea00-4406-afce-2b0f1e971fe0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd46c991c-ea00-4406-afce-2b0f1e971fe0.pdf?alt=media&token=6e25ead4-a30a-4bbc-95ce-62a0dfb57132",
            "fileExt": ".pdf",
            "fileName": "2.31 Quality Management Project policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.32 Petty Cash Monthly Tracking Sheet",
            "type": "default",
            "id": "cf5cd9aa-8efe-4104-8e9d-25b1af0987e9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fcf5cd9aa-8efe-4104-8e9d-25b1af0987e9.pdf?alt=media&token=62f21125-b284-41a2-af36-4dcefe4b2ce3",
            "fileExt": ".pdf",
            "fileName": "2.32 Petty Cash Monthly Tracking Sheet.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.33 Request to Discontinue Life-Sustaining Treatment policy",
            "type": "policy",
            "id": "8ac62ce1-2473-4c99-b35d-24a135c23a49",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8ac62ce1-2473-4c99-b35d-24a135c23a49.pdf?alt=media&token=9bd0882c-7998-4f60-aa1c-965aaa6e0ad0",
            "fileExt": ".pdf",
            "fileName": "2.33 Request to Discontinue Life-Sustaining Treatment policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.34 Resident and Family Councils policy",
            "type": "policy",
            "id": "2ab02fb4-1756-43f2-975d-42d5c02be2f1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2ab02fb4-1756-43f2-975d-42d5c02be2f1.pdf?alt=media&token=d51c22f0-53f0-4894-ad4a-0a35f5f42c4a",
            "fileExt": ".pdf",
            "fileName": "2.34 Resident and Family Councils policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.35  Resident Record – Access & Storage policy",
            "type": "policy",
            "id": "56a5b28d-8628-469c-827c-016c6b03bda0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F56a5b28d-8628-469c-827c-016c6b03bda0.pdf?alt=media&token=0f21eebd-2f44-431e-bac2-26804384d42c",
            "fileExt": ".pdf",
            "fileName": "2.35  Resident Record – Access & Storage policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.36  Resident Record – Confidentiality",
            "type": "default",
            "id": "5306234e-ca03-478c-bb59-fcb810457a20",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F5306234e-ca03-478c-bb59-fcb810457a20.pdf?alt=media&token=c42a1641-5e7b-4720-a94b-754196dcb3ad",
            "fileExt": ".pdf",
            "fileName": "2.36  Resident Record – Confidentiality.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.37 Resident Record - Documentation policy",
            "type": "policy",
            "id": "39148e86-6491-48c8-9a25-0babd1094959",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F39148e86-6491-48c8-9a25-0babd1094959.pdf?alt=media&token=97361add-b921-4212-b856-38e0c3c3a0b3",
            "fileExt": ".pdf",
            "fileName": "2.37 Resident Record - Documentation policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.38  Resident Record - Information and Content policy",
            "type": "policy",
            "id": "0dad57a9-31b0-44e1-b2b0-fabfbbd88946",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0dad57a9-31b0-44e1-b2b0-fabfbbd88946.pdf?alt=media&token=7e75e971-abd9-4d71-b68b-d1b907293fae",
            "fileExt": ".pdf",
            "fileName": "2.38  Resident Record - Information and Content policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.39 Resident Record - Retention policy",
            "type": "policy",
            "id": "52db3461-3bf1-4ad7-8471-a693a97e0da5",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F52db3461-3bf1-4ad7-8471-a693a97e0da5.pdf?alt=media&token=2988d667-5b08-4b36-8544-9996ac62be41",
            "fileExt": ".pdf",
            "fileName": "2.39 Resident Record - Retention policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.40 Resident Record – Transfer of policy",
            "type": "policy",
            "id": "21b2fcdb-a208-410a-8f83-cbe1136316a2",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F21b2fcdb-a208-410a-8f83-cbe1136316a2.pdf?alt=media&token=585448cf-22b2-45e6-b7ba-f55de710546d",
            "fileExt": ".pdf",
            "fileName": "2.40 Resident Record – Transfer of policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.41_RetaliationProhibitedpolicy",
            "type": "default",
            "id": "907f20a6-61ee-4429-9583-f50d8f612241",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F907f20a6-61ee-4429-9583-f50d8f612241.pdf?alt=media&token=7e58b4ef-7b61-47ef-a139-edef538d0536",
            "fileExt": ".pdf",
            "fileName": "2.41_RetaliationProhibitedpolicy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.42 Right to Outside Service Provider policy",
            "type": "policy",
            "id": "e1a01e24-ce1c-481b-a3f4-9271f13c44a9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe1a01e24-ce1c-481b-a3f4-9271f13c44a9.pdf?alt=media&token=fe31b93b-32ea-46c7-a123-6e3308f84db4",
            "fileExt": ".pdf",
            "fileName": "2.42 Right to Outside Service Provider policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.43 Video and Photography policy",
            "type": "policy",
            "id": "1b13f6c9-e88c-4dd1-9b02-1d450092d32f",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1b13f6c9-e88c-4dd1-9b02-1d450092d32f.pdf?alt=media&token=52441aca-662d-4add-9137-d5c042d9c959",
            "fileExt": ".pdf",
            "fileName": "2.43 Video and Photography policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.44 Vulnerable Adult Maltreatment - Prevention & Reporting policy",
            "type": "policy",
            "id": "b3a14108-58cd-46ad-9deb-b7bd74fb7ecd",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb3a14108-58cd-46ad-9deb-b7bd74fb7ecd.pdf?alt=media&token=e74372fc-3b95-450b-a59d-e929f2b3cee3",
            "fileExt": ".pdf",
            "fileName": "2.44 Vulnerable Adult Maltreatment - Prevention & Reporting policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.45  HIPAA Business Associates policy",
            "type": "policy",
            "id": "66782998-d70d-46f2-9bb3-af74a4436979",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F66782998-d70d-46f2-9bb3-af74a4436979.pdf?alt=media&token=66046fd7-e7ad-479e-85b5-e9782d094cba",
            "fileExt": ".pdf",
            "fileName": "2.45  HIPAA Business Associates policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.47 HIPAA Notice of Privacy Practices Dissemination policy",
            "type": "policy",
            "id": "88d71bb4-d475-4eaa-9eb8-6dd554cb73fb",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F88d71bb4-d475-4eaa-9eb8-6dd554cb73fb.pdf?alt=media&token=b9d4c5e7-6056-4a9f-a413-888788dda33a",
            "fileExt": ".pdf",
            "fileName": "2.47 HIPAA Notice of Privacy Practices Dissemination policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "2.07 Check Request form",
            "type": "form",
            "id": "9a6d9e76-cbd3-4302-9c1a-494cc45f1e17",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9a6d9e76-cbd3-4302-9c1a-494cc45f1e17.pdf?alt=media&token=e894db46-d2e4-4f9f-8c4a-834aa0b05f49",
            "fileExt": ".pdf",
            "fileName": "2.07 Check Request form.pdf"
          },
          {
            "name": "2.11 Complaint-Grievance sample form",
            "type": "form",
            "id": "434b7a4b-5884-4203-9769-b02e4b9af83d",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F434b7a4b-5884-4203-9769-b02e4b9af83d.pdf?alt=media&token=5f98cb75-5471-4fb3-9122-069655b8a363",
            "fileExt": ".pdf",
            "fileName": "2.11 Complaint-Grievance sample form.pdf"
          },
          {
            "name": "2.17 Elysian Electronic Monitoring Consent - Resident form",
            "type": "form",
            "id": "f4766d2c-e409-4da6-9609-38c59449dece",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff4766d2c-e409-4da6-9609-38c59449dece.pdf?alt=media&token=f496f5ee-f686-4a55-a3cb-9b7712a633c1",
            "fileExt": ".pdf",
            "fileName": "2.17 Elysian Electronic Monitoring Consent - Resident form.pdf"
          },
          {
            "name": "2.18 Elysian Electronic Monitoring Consent - Resident Representative form",
            "type": "form",
            "id": "25110e93-de9b-44da-a8ba-10545a7b3346",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F25110e93-de9b-44da-a8ba-10545a7b3346.pdf?alt=media&token=2a9d6120-46c2-46b0-8be3-9ce6da9187bc",
            "fileExt": ".pdf",
            "fileName": "2.18 Elysian Electronic Monitoring Consent - Resident Representative form.pdf"
          },
          {
            "name": "2.19 Elysian Electronic Monitoring Consent - Roommate form",
            "type": "form",
            "id": "39e178b9-9220-466e-9c28-2e70d9848ad7",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F39e178b9-9220-466e-9c28-2e70d9848ad7.pdf?alt=media&token=8272739b-5a70-42d7-a2d5-50bef2a68ef2",
            "fileExt": ".pdf",
            "fileName": "2.19 Elysian Electronic Monitoring Consent - Roommate form.pdf"
          },
          {
            "name": "2.20 Elysian Electronic Monitoring Consent - Roommate Representative form",
            "type": "form",
            "id": "e62fee4f-92f4-4995-bfa2-dfb647112ce8",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe62fee4f-92f4-4995-bfa2-dfb647112ce8.pdf?alt=media&token=c962f0b9-5f45-4bb3-8a5e-46d38b93eab0",
            "fileExt": ".pdf",
            "fileName": "2.20 Elysian Electronic Monitoring Consent - Roommate Representative form.pdf"
          },
          {
            "name": "2.26 Incident Report general sample form",
            "type": "form",
            "id": "a9603957-6ab7-4c7a-92b5-5c15c63e78e0",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa9603957-6ab7-4c7a-92b5-5c15c63e78e0.pdf?alt=media&token=cb128c0e-486a-4c59-9895-9024aeb2b720",
            "fileExt": ".pdf",
            "fileName": "2.26 Incident Report general sample form.pdf"
          },
          {
            "name": "2.29 On-Call Log form",
            "type": "form",
            "id": "dfb41db0-2faa-48a7-a4da-b6a482de3da3",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdfb41db0-2faa-48a7-a4da-b6a482de3da3.pdf?alt=media&token=a169aebb-b65a-4b37-923b-44ed7656fc60",
            "fileExt": ".pdf",
            "fileName": "2.29 On-Call Log form.pdf"
          }
        ]
      },
      {
        "name": "Chapter 3",
        "type": "dir",
        "id": "c03d53b8-24f5-49cd-a887-89df507b8818",
        "path": "Policies and Procedures/Chapter 3",
        "items": [
          {
            "name": "3.00  ALDC Additional Dementia Care Required Policies",
            "type": "policy",
            "id": "80d8c835-cc3a-4d21-b5a8-6a27c5db455e",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F80d8c835-cc3a-4d21-b5a8-6a27c5db455e.pdf?alt=media&token=6c6ee40a-76bb-4d85-a88c-c92193a668ea",
            "fileExt": ".pdf",
            "fileName": "3.00  ALDC Additional Dementia Care Required Policies.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.01  ALDC Additional Dementia Staff Training policy",
            "type": "policy",
            "id": "72d3fbcf-3126-45c1-bc59-85a65bb93f33",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F72d3fbcf-3126-45c1-bc59-85a65bb93f33.pdf?alt=media&token=6813571e-1a8e-4cf0-bdfd-7ffa0147b671",
            "fileExt": ".pdf",
            "fileName": "3.01  ALDC Additional Dementia Staff Training policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.02 ALDC Behavioral Symptoms, Interventions & Nonpharmacological Approaches policy",
            "type": "policy",
            "id": "359e592c-5e6a-475b-bd57-b56449cb727c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F359e592c-5e6a-475b-bd57-b56449cb727c.pdf?alt=media&token=448aaf2a-3fe9-4eba-b399-6325b26660f8",
            "fileExt": ".pdf",
            "fileName": "3.02 ALDC Behavioral Symptoms, Interventions & Nonpharmacological Approaches policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.03 ALDC Dementia Care Philosophy policy",
            "type": "policy",
            "id": "31e5d6f7-a89f-4283-b3f7-1b06289ba692",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F31e5d6f7-a89f-4283-b3f7-1b06289ba692.pdf?alt=media&token=fc75db8c-5860-40bb-9597-0089cc62f4a0",
            "fileExt": ".pdf",
            "fileName": "3.03 ALDC Dementia Care Philosophy policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.04 ALDC Family Support policy",
            "type": "policy",
            "id": "4357156e-0881-42c3-8be3-820c4c5a8b39",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4357156e-0881-42c3-8be3-820c4c5a8b39.pdf?alt=media&token=d32aa746-54cf-4c22-b714-9ecc779d504a",
            "fileExt": ".pdf",
            "fileName": "3.04 ALDC Family Support policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.05  ALDC Life Enrichment Programs, Activities & Outdoor Space policy",
            "type": "policy",
            "id": "393d8fc0-197c-4296-8578-6c0132bfbdc6",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F393d8fc0-197c-4296-8578-6c0132bfbdc6.pdf?alt=media&token=ecc4462b-3b32-4638-847a-701a9950d14b",
            "fileExt": ".pdf",
            "fileName": "3.05  ALDC Life Enrichment Programs, Activities & Outdoor Space policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.06 ALDC Life Safety Code policy",
            "type": "policy",
            "id": "c70193a0-2ce4-48fc-b29f-079d5b309bfc",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc70193a0-2ce4-48fc-b29f-079d5b309bfc.pdf?alt=media&token=6a80f740-e288-40ec-9710-e2cc9b47c46d",
            "fileExt": ".pdf",
            "fileName": "3.06 ALDC Life Safety Code policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.07 ALDC Medication Management policy",
            "type": "policy",
            "id": "140ab176-599d-4dd0-8c50-6e0dc54158ab",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F140ab176-599d-4dd0-8c50-6e0dc54158ab.pdf?alt=media&token=70716f29-9b30-4d7d-abea-9d00eaa70c5e",
            "fileExt": ".pdf",
            "fileName": "3.07 ALDC Medication Management policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.08   ALDC Notice of Dementia Training policy",
            "type": "policy",
            "id": "b5560667-ade2-4bec-ba47-b9b56eec1852",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb5560667-ade2-4bec-ba47-b9b56eec1852.pdf?alt=media&token=fef99a1c-b364-44b7-8202-538099d3f683",
            "fileExt": ".pdf",
            "fileName": "3.08   ALDC Notice of Dementia Training policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.09 ALDC Physical Environment, Fire Protection & Staffing policy",
            "type": "policy",
            "id": "d4a048bd-17ee-4d65-aced-bd72f7f73566",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd4a048bd-17ee-4d65-aced-bd72f7f73566.pdf?alt=media&token=872fda22-bed1-41dd-a016-4843e4416bad",
            "fileExt": ".pdf",
            "fileName": "3.09 ALDC Physical Environment, Fire Protection & Staffing policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.10 ALDC Safekeeping of Resident Possessions policy",
            "type": "policy",
            "id": "a2121886-5757-424f-934a-0585b1e3c707",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa2121886-5757-424f-934a-0585b1e3c707.pdf?alt=media&token=52588215-15d5-4bbf-b325-0c120d0cc0e1",
            "fileExt": ".pdf",
            "fileName": "3.10 ALDC Safekeeping of Resident Possessions policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.11 ALDC Transportation Coordination policy",
            "type": "policy",
            "id": "701a5189-e43f-47c9-a97e-6d80f5365b12",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F701a5189-e43f-47c9-a97e-6d80f5365b12.pdf?alt=media&token=64bb6c0a-8a96-4cc9-96c7-93611220cf2d",
            "fileExt": ".pdf",
            "fileName": "3.11 ALDC Transportation Coordination policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.12 ALDC Use of Intercom policy",
            "type": "policy",
            "id": "457c38d4-bf82-411e-9159-d67fa635ff5b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F457c38d4-bf82-411e-9159-d67fa635ff5b.pdf?alt=media&token=cc300ec3-ee19-47f2-93b5-6bdb9b66482f",
            "fileExt": ".pdf",
            "fileName": "3.12 ALDC Use of Intercom policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "3.13 ALDC Wandering and Elopement policy",
            "type": "policy",
            "id": "02aa2066-d2d5-45c9-817f-a0566b9b85e9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F02aa2066-d2d5-45c9-817f-a0566b9b85e9.pdf?alt=media&token=116e2228-7fb1-4cb9-81f9-6bacdc66e24e",
            "fileExt": ".pdf",
            "fileName": "3.13 ALDC Wandering and Elopement policy.pdf",
            "keywords": [],
            "isBright": true
          }
        ]
      },
      {
        "name": "Chapter 4",
        "type": "dir",
        "id": "74853d54-7a96-402f-92f8-9b7126fbd633",
        "path": "Policies and Procedures/Chapter 4",
        "items": [
          {
            "name": "4.01 Assisted Living Director policy",
            "type": "policy",
            "id": "0aa54ca8-6dd9-44b2-9647-08e0e3aa7700",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0aa54ca8-6dd9-44b2-9647-08e0e3aa7700.pdf?alt=media&token=cfc84b04-d6ff-4f68-ad77-79f85fc67c8e",
            "fileExt": ".pdf",
            "fileName": "4.01 Assisted Living Director policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.02 Background Studies policy",
            "type": "policy",
            "id": "4f09bd16-0253-44a0-919c-019688791b16",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4f09bd16-0253-44a0-919c-019688791b16.pdf?alt=media&token=2ccd68c3-fb97-485f-b774-8a34153b474a",
            "fileExt": ".pdf",
            "fileName": "4.02 Background Studies policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.03 Kari Koskinen Manager Background Check policy",
            "type": "policy",
            "id": "3455f984-fddc-46f5-be57-2a5c51d6bf40",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3455f984-fddc-46f5-be57-2a5c51d6bf40.pdf?alt=media&token=26a942cd-4b2d-4e5f-a663-bdfba6bc8bf6",
            "fileExt": ".pdf",
            "fileName": "4.03 Kari Koskinen Manager Background Check policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.05 Employee Records policy",
            "type": "policy",
            "id": "7a32b6fc-71c8-44e9-aece-60daf88e5f82",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7a32b6fc-71c8-44e9-aece-60daf88e5f82.pdf?alt=media&token=7c5e3fd8-37b0-478d-b8b7-5e6bae253ca8",
            "fileExt": ".pdf",
            "fileName": "4.05 Employee Records policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.06  Staffing & Scheduling policy",
            "type": "policy",
            "id": "f5e3ff24-309f-45c1-adbb-1f548783a310",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff5e3ff24-309f-45c1-adbb-1f548783a310.pdf?alt=media&token=b87d6503-60ed-4bbd-b7c2-325bd7b72f43",
            "fileExt": ".pdf",
            "fileName": "4.06  Staffing & Scheduling policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.07  Temporary and Contracted Staff policy",
            "type": "policy",
            "id": "671d3d49-7321-4e3a-8279-df3053cd66d3",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F671d3d49-7321-4e3a-8279-df3053cd66d3.pdf?alt=media&token=a0f254f9-8bc0-438e-9b9b-cb285d47247a",
            "fileExt": ".pdf",
            "fileName": "4.07  Temporary and Contracted Staff policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.08 Volunteer and Contractor Records policy",
            "type": "policy",
            "id": "c2599c18-b64e-4d09-a268-a38650eb54d5",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc2599c18-b64e-4d09-a268-a38650eb54d5.pdf?alt=media&token=59ebc0bd-e0c4-4052-8c1a-952cf3e3fb7e",
            "fileExt": ".pdf",
            "fileName": "4.08 Volunteer and Contractor Records policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.10 Sample Interview Questions",
            "type": "default",
            "id": "8ecf1579-f3f6-4042-8cae-36b766cab886",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8ecf1579-f3f6-4042-8cae-36b766cab886.pdf?alt=media&token=9ff20e90-88d0-4bdd-bbae-2a126b51b799",
            "fileExt": ".pdf",
            "fileName": "4.10 Sample Interview Questions.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.11 Reference Checks policy",
            "type": "policy",
            "id": "7d1c6165-eb51-4877-9bcd-2f885f14ca1c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7d1c6165-eb51-4877-9bcd-2f885f14ca1c.pdf?alt=media&token=544b75c8-ac7d-407e-956e-cedf4d49ab6f",
            "fileExt": ".pdf",
            "fileName": "4.11 Reference Checks policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.12 Reference Checks sample questions",
            "type": "default",
            "id": "1617f932-3693-4076-a670-ef4d372b7b57",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1617f932-3693-4076-a670-ef4d372b7b57.pdf?alt=media&token=4a7417a0-e87d-4c62-a9a0-7ef9563cb9dd",
            "fileExt": ".pdf",
            "fileName": "4.12 Reference Checks sample questions.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.14 Sample Letter - Interview Turndown",
            "type": "default",
            "id": "737bce18-7de0-4205-800f-9095e25ab96d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F737bce18-7de0-4205-800f-9095e25ab96d.pdf?alt=media&token=9f436497-b824-45f7-936e-c1d8d8f076a2",
            "fileExt": ".pdf",
            "fileName": "4.14 Sample Letter - Interview Turndown.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.15 Sample Letter - New Hire",
            "type": "default",
            "id": "d793c89f-c3b6-4c5e-8c01-b858ec704609",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd793c89f-c3b6-4c5e-8c01-b858ec704609.pdf?alt=media&token=b5ed0560-25a9-4566-8076-812bd429f190",
            "fileExt": ".pdf",
            "fileName": "4.15 Sample Letter - New Hire.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.16 Sample Letter - Not Interviewed",
            "type": "default",
            "id": "99e5581d-d0af-4b3d-b887-53f2d1297771",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F99e5581d-d0af-4b3d-b887-53f2d1297771.pdf?alt=media&token=5214ec66-9ca3-40be-878a-0123ee91cf56",
            "fileExt": ".pdf",
            "fileName": "4.16 Sample Letter - Not Interviewed.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.18 Employee General Orientation policy",
            "type": "policy",
            "id": "09876aca-46e0-4122-b978-2001662300b4",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F09876aca-46e0-4122-b978-2001662300b4.pdf?alt=media&token=272073c9-e578-4d4e-80e2-51f07a76852d",
            "fileExt": ".pdf",
            "fileName": "4.18 Employee General Orientation policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.21 Job Description - Assisted Living Director",
            "type": "default",
            "id": "ebd95b01-44e2-4b58-9c56-179eaa8dccfb",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Febd95b01-44e2-4b58-9c56-179eaa8dccfb.pdf?alt=media&token=d70e4965-5e9e-478f-9b1c-99bbcdf5cbc5",
            "fileExt": ".pdf",
            "fileName": "4.21 Job Description - Assisted Living Director.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.22 Job Description - Senior Housing Assistant Manager",
            "type": "default",
            "id": "716051d5-1f47-4988-8847-b1870a763009",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F716051d5-1f47-4988-8847-b1870a763009.pdf?alt=media&token=edcc6f80-a785-4a6a-bfa2-4d9bcb2cb9e2",
            "fileExt": ".pdf",
            "fileName": "4.22 Job Description - Senior Housing Assistant Manager.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.25 Job Description - Care and Wellness Director",
            "type": "default",
            "id": "84a5527c-8700-42d4-9dc2-f5cf3048bd3f",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F84a5527c-8700-42d4-9dc2-f5cf3048bd3f.pdf?alt=media&token=99a4072a-bc04-40cc-9cbf-479b70e54854",
            "fileExt": ".pdf",
            "fileName": "4.25 Job Description - Care and Wellness Director.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.26 Job Description - Care and Wellness Nurse",
            "type": "default",
            "id": "c5a109da-1ee1-4733-9401-eb1e96122f05",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc5a109da-1ee1-4733-9401-eb1e96122f05.pdf?alt=media&token=0f21fb6a-fd20-40cb-9394-4c2bd5ae7981",
            "fileExt": ".pdf",
            "fileName": "4.26 Job Description - Care and Wellness Nurse.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.27 Job Description - Care and Wellness Coordinator",
            "type": "default",
            "id": "255f9a7d-076b-4c06-9ddc-a5546fd1cddd",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F255f9a7d-076b-4c06-9ddc-a5546fd1cddd.pdf?alt=media&token=85640a50-314a-466c-9f04-bdf422587b9a",
            "fileExt": ".pdf",
            "fileName": "4.27 Job Description - Care and Wellness Coordinator.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.28 Job Description - Care and Wellness Assistant",
            "type": "default",
            "id": "5d8642e4-94ab-41a1-8fb8-08e0bf695efd",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F5d8642e4-94ab-41a1-8fb8-08e0bf695efd.pdf?alt=media&token=e74ed828-b0a9-4a2b-9921-91398aa7b053",
            "fileExt": ".pdf",
            "fileName": "4.28 Job Description - Care and Wellness Assistant.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.29 Job Description - Lifestyle Enrichment Coordinator",
            "type": "default",
            "id": "1da391c4-dbd5-4df4-a2eb-30a93ece55d8",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1da391c4-dbd5-4df4-a2eb-30a93ece55d8.pdf?alt=media&token=75a994f8-fef1-4d1f-b6f0-5a1d76723b75",
            "fileExt": ".pdf",
            "fileName": "4.29 Job Description - Lifestyle Enrichment Coordinator.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.30 Job Description - Building and Grounds Assistant",
            "type": "default",
            "id": "ff3ad639-3bb2-4096-bb28-dc681685ad6b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fff3ad639-3bb2-4096-bb28-dc681685ad6b.pdf?alt=media&token=00af0d1f-1f89-46b8-a505-2f0d64c6ee48",
            "fileExt": ".pdf",
            "fileName": "4.30 Job Description - Building and Grounds Assistant.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.31 Job Description - Housing Assistant",
            "type": "default",
            "id": "38830ec9-fb08-4cf2-b115-4095cc890ff7",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F38830ec9-fb08-4cf2-b115-4095cc890ff7.pdf?alt=media&token=be2fcc18-2039-4358-9933-acc37d0f44b9",
            "fileExt": ".pdf",
            "fileName": "4.31 Job Description - Housing Assistant.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.32 Job Description-Culinary Services Manager or Lead Culinarian",
            "type": "default",
            "id": "8c7fe62e-3087-4c61-811d-bb6b17ba64ca",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8c7fe62e-3087-4c61-811d-bb6b17ba64ca.pdf?alt=media&token=2f3fc91d-d5c6-4588-9f87-a87fdcf46c2f",
            "fileExt": ".pdf",
            "fileName": "4.32 Job Description-Culinary Services Manager or Lead Culinarian.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.33 Job Description - Culinarian",
            "type": "default",
            "id": "13249ca6-1e92-4050-a155-b6397bd1a4d1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F13249ca6-1e92-4050-a155-b6397bd1a4d1.pdf?alt=media&token=e9579c82-3a50-4269-80ee-65e006537001",
            "fileExt": ".pdf",
            "fileName": "4.33 Job Description - Culinarian.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.34a Job Description - Culinary Service Assistant",
            "type": "default",
            "id": "110e66e4-12c8-4f82-9819-cc6b5ce9a784",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F110e66e4-12c8-4f82-9819-cc6b5ce9a784.pdf?alt=media&token=21e725d3-c591-4f32-a740-4dd000b5ffb9",
            "fileExt": ".pdf",
            "fileName": "4.34a Job Description - Culinary Service Assistant.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.34b Job Description - Culinary Service Lead",
            "type": "default",
            "id": "36000197-d2c5-4695-8527-cb0661a71354",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F36000197-d2c5-4695-8527-cb0661a71354.pdf?alt=media&token=f156157a-75d6-482f-bb5b-26e733e5f8c5",
            "fileExt": ".pdf",
            "fileName": "4.34b Job Description - Culinary Service Lead.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.35 Employee Evlauation policy",
            "type": "policy",
            "id": "fbf59962-e18d-4010-b81d-f79e4b12fac9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ffbf59962-e18d-4010-b81d-f79e4b12fac9.pdf?alt=media&token=d0259a34-cbee-4dd1-9c61-44154adeb7f9",
            "fileExt": ".pdf",
            "fileName": "4.35 Employee Evlauation policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.43 Corrective Action policy",
            "type": "policy",
            "id": "7eddc9cd-e3ec-4451-8eb6-adc44082a61b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7eddc9cd-e3ec-4451-8eb6-adc44082a61b.pdf?alt=media&token=d1a85fba-0829-4db0-a64f-29012ae2d473",
            "fileExt": ".pdf",
            "fileName": "4.43 Corrective Action policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.44 Documentation of Performance Discussion",
            "type": "default",
            "id": "3b0b74ea-6770-4fc1-974a-121fb37cab9d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3b0b74ea-6770-4fc1-974a-121fb37cab9d.pdf?alt=media&token=091d3e75-ca0f-489a-aa70-17bed03ff8f2",
            "fileExt": ".pdf",
            "fileName": "4.44 Documentation of Performance Discussion.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.45 Documentation of Corrective Action",
            "type": "default",
            "id": "ece4e378-7ae2-41ac-b401-1fa6fa46ebc0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fece4e378-7ae2-41ac-b401-1fa6fa46ebc0.pdf?alt=media&token=70e10ad7-30b9-4fef-a4bd-bdb3e946c5f5",
            "fileExt": ".pdf",
            "fileName": "4.45 Documentation of Corrective Action.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.48 Attendance policy",
            "type": "policy",
            "id": "b0beb81b-710f-4253-b199-f03f89c4b28b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb0beb81b-710f-4253-b199-f03f89c4b28b.pdf?alt=media&token=adab01d2-676d-4a0b-beab-18602c5a57b9",
            "fileExt": ".pdf",
            "fileName": "4.48 Attendance policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.49 Break & Mealtime policy",
            "type": "policy",
            "id": "db943c7f-b810-420b-9491-61a1bdd7ec99",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdb943c7f-b810-420b-9491-61a1bdd7ec99.pdf?alt=media&token=1c7102c9-24cf-4117-9161-8c8f04014a0d",
            "fileExt": ".pdf",
            "fileName": "4.49 Break & Mealtime policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.50 Calling-In Sick policy",
            "type": "policy",
            "id": "fd72b201-cdbb-4151-856e-b4d8967a7f00",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ffd72b201-cdbb-4151-856e-b4d8967a7f00.pdf?alt=media&token=a61a9ebd-5a3b-49ea-9a5c-08b6f5a59f32",
            "fileExt": ".pdf",
            "fileName": "4.50 Calling-In Sick policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.51 Cell Phones and Personal Calls policy",
            "type": "policy",
            "id": "6adc69ce-ef4d-4d18-9ee6-fb8587cc8b12",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F6adc69ce-ef4d-4d18-9ee6-fb8587cc8b12.pdf?alt=media&token=ca105a59-69e5-419d-8a8b-22a5451c3d1c",
            "fileExt": ".pdf",
            "fileName": "4.51 Cell Phones and Personal Calls policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.52 Gifts and Donations policy",
            "type": "policy",
            "id": "b457f6a2-655b-468c-98d9-b656d27b37c1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb457f6a2-655b-468c-98d9-b656d27b37c1.pdf?alt=media&token=ef7cfb5d-ce60-47d5-9a65-dce41e831bdc",
            "fileExt": ".pdf",
            "fileName": "4.52 Gifts and Donations policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.53  Holidays policy",
            "type": "policy",
            "id": "0367955b-6dbf-49dc-9ece-ac2d2d0fd242",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0367955b-6dbf-49dc-9ece-ac2d2d0fd242.pdf?alt=media&token=271b78fa-ddb2-4e28-816c-774ea4c9d868",
            "fileExt": ".pdf",
            "fileName": "4.53  Holidays policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.56 Non-Solicitation policy",
            "type": "policy",
            "id": "53c242fd-2343-4770-9b30-474cda6ba590",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F53c242fd-2343-4770-9b30-474cda6ba590.pdf?alt=media&token=8670f2f5-8828-4cda-ae71-d1715d6d7074",
            "fileExt": ".pdf",
            "fileName": "4.56 Non-Solicitation policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.57 Overtime policy",
            "type": "policy",
            "id": "2d56a024-5d44-48fd-a7ef-479f279eab93",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2d56a024-5d44-48fd-a7ef-479f279eab93.pdf?alt=media&token=47bc6db1-46d6-468b-a529-4af168c5fcaf",
            "fileExt": ".pdf",
            "fileName": "4.57 Overtime policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.59 Smoking-Tobacco Use policy",
            "type": "policy",
            "id": "2e65bc20-bec0-4a56-b6b5-1099b1a0086d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2e65bc20-bec0-4a56-b6b5-1099b1a0086d.pdf?alt=media&token=1c09517d-12b0-4696-b05e-2f31f68cb635",
            "fileExt": ".pdf",
            "fileName": "4.59 Smoking-Tobacco Use policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.60 Social Media policy",
            "type": "policy",
            "id": "2e36366c-af40-434f-9fea-6525aba110b9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2e36366c-af40-434f-9fea-6525aba110b9.pdf?alt=media&token=22ae78e5-4d18-4641-bc6f-766ebe5b3a5e",
            "fileExt": ".pdf",
            "fileName": "4.60 Social Media policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.61 Time & Attendance policy",
            "type": "policy",
            "id": "6ee00db9-70e3-4266-922e-430e86dd855a",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F6ee00db9-70e3-4266-922e-430e86dd855a.pdf?alt=media&token=1ca7d475-2647-4716-bb1c-4099645ef7ec",
            "fileExt": ".pdf",
            "fileName": "4.61 Time & Attendance policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.62 Timecards Review policy",
            "type": "policy",
            "id": "c0ba13a5-f2bf-43ba-a44d-8482ffd67baa",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc0ba13a5-f2bf-43ba-a44d-8482ffd67baa.pdf?alt=media&token=5f83dcff-ff85-4b0c-a588-c5327607b65a",
            "fileExt": ".pdf",
            "fileName": "4.62 Timecards Review policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "4.65 Sample Letter - Termination",
            "type": "default",
            "id": "9d34c9b7-47ec-4690-9f53-78b8e0c6dff1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9d34c9b7-47ec-4690-9f53-78b8e0c6dff1.pdf?alt=media&token=23c07a5d-e62c-4679-a0ed-5176548fed8f",
            "fileExt": ".pdf",
            "fileName": "4.65 Sample Letter - Termination.pdf",
            "keywords": [],
            "isBright": true
          }
        ]
      },
      {
        "name": "Chapter 5",
        "type": "dir",
        "id": "0aa1854a-278f-4e8c-8bbe-728665945fe5",
        "path": "Policies and Procedures/Chapter 5",
        "items": [
          {
            "name": "5.01  Orientation of Staff and Supervisors & Content policy",
            "type": "policy",
            "id": "e183b9b5-bd56-4381-84f0-58f2b5b99df3",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe183b9b5-bd56-4381-84f0-58f2b5b99df3.pdf?alt=media&token=08a110cb-6657-4c49-90fd-e917c72c1c51",
            "fileExt": ".pdf",
            "fileName": "5.01  Orientation of Staff and Supervisors & Content policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.02 Competency Training Evaluations policy",
            "type": "policy",
            "id": "93b5cd54-c8db-429e-bec1-08222751a393",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F93b5cd54-c8db-429e-bec1-08222751a393.pdf?alt=media&token=42ade500-b21e-49d9-8dff-9020a5270734",
            "fileExt": ".pdf",
            "fileName": "5.02 Competency Training Evaluations policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.03 Dementia Training policy",
            "type": "policy",
            "id": "ccd3c57a-f273-4a30-acb5-9a453b3c51bc",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fccd3c57a-f273-4a30-acb5-9a453b3c51bc.pdf?alt=media&token=da6bc67f-5d33-4d89-aaa9-fb208828725e",
            "fileExt": ".pdf",
            "fileName": "5.03 Dementia Training policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.04 Assisted Living Licensed Facilities Resource Manual",
            "type": "default",
            "id": "2bb2ea60-9cb3-4629-bb3f-ba93755bcc51",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2bb2ea60-9cb3-4629-bb3f-ba93755bcc51.pdf?alt=media&token=7145924b-e83e-4903-90ae-ebdad462aa46",
            "fileExt": ".pdf",
            "fileName": "5.04 Assisted Living Licensed Facilities Resource Manual.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.04 Assisted Living with Dementia Care License Resource Manual",
            "type": "default",
            "id": "c429c09d-4264-4cf0-ad58-14f11059649a",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc429c09d-4264-4cf0-ad58-14f11059649a.pdf?alt=media&token=626dca31-a0bf-4d65-914b-cc671e1d5f16",
            "fileExt": ".pdf",
            "fileName": "5.04 Assisted Living with Dementia Care License Resource Manual.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.06 Annual Required Staff Training policy",
            "type": "policy",
            "id": "275ae87f-3a68-4ddf-99ca-1a0748217377",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F275ae87f-3a68-4ddf-99ca-1a0748217377.pdf?alt=media&token=8a7eb3b8-6212-4024-a3a2-c288362b338a",
            "fileExt": ".pdf",
            "fileName": "5.06 Annual Required Staff Training policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.07 Competency Retraining Evaluations policy",
            "type": "policy",
            "id": "871c1210-8d4c-4c2e-a265-f6e1304c2876",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F871c1210-8d4c-4c2e-a265-f6e1304c2876.pdf?alt=media&token=433abbba-12d9-48e1-8961-d59ba86db4e3",
            "fileExt": ".pdf",
            "fileName": "5.07 Competency Retraining Evaluations policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.08 Instructor & Competency Evaluation Requirements policy",
            "type": "policy",
            "id": "13d51121-09ec-4f7d-91c3-c8b49326313a",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F13d51121-09ec-4f7d-91c3-c8b49326313a.pdf?alt=media&token=e9b52fd4-08d9-42fb-9f5b-240d5819fb1f",
            "fileExt": ".pdf",
            "fileName": "5.08 Instructor & Competency Evaluation Requirements policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.09  Portability of Competency Training Records policy",
            "type": "policy",
            "id": "0be1e624-9526-48fe-a738-a1a7041d1276",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0be1e624-9526-48fe-a738-a1a7041d1276.pdf?alt=media&token=5fb3a5f6-e58b-44e8-bdff-70451076a87b",
            "fileExt": ".pdf",
            "fileName": "5.09  Portability of Competency Training Records policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.10  Training Records policy",
            "type": "policy",
            "id": "0bb4fbb9-042f-4c93-bba9-f868aa64b45d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0bb4fbb9-042f-4c93-bba9-f868aa64b45d.pdf?alt=media&token=720858b8-33c6-4452-83c4-5f685dd4b710",
            "fileExt": ".pdf",
            "fileName": "5.10  Training Records policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.11 Staff Supervision",
            "type": "default",
            "id": "2c814757-5019-4261-8654-cfd6f30781eb",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2c814757-5019-4261-8654-cfd6f30781eb.pdf?alt=media&token=432b73c9-b898-499f-b2d3-71e70a935fa1",
            "fileExt": ".pdf",
            "fileName": "5.11 Staff Supervision.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "5.05 HCBS Required Training  form",
            "type": "form",
            "id": "820a5ca7-225e-4db5-a5ea-b776725c049d",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F820a5ca7-225e-4db5-a5ea-b776725c049d.pdf?alt=media&token=b6560fc4-509c-46df-9178-1e4e68a922ad",
            "fileExt": ".pdf",
            "fileName": "5.05 HCBS Required Training  form.pdf"
          }
        ]
      },
      {
        "name": "Chapter 6",
        "type": "dir",
        "id": "c8963c36-4811-496e-b5c0-c58e7e0a7191",
        "path": "Policies and Procedures/Chapter 6",
        "items": [
          {
            "name": "6.01_AssessmentsReviewsMonitoringpolicy",
            "type": "default",
            "id": "7dc6790b-6d31-482f-925e-69005346bb83",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7dc6790b-6d31-482f-925e-69005346bb83.pdf?alt=media&token=8a2139bd-9034-41b7-81a5-89f38b0db1a6",
            "fileExt": ".pdf",
            "fileName": "6.01_AssessmentsReviewsMonitoringpolicy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.02 Assessment Schedules grid",
            "type": "default",
            "id": "b64528bc-6e4f-4f91-adff-4eb15dbeff6a",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb64528bc-6e4f-4f91-adff-4eb15dbeff6a.pdf?alt=media&token=edbcdbbb-36a9-46f9-978a-474bbf1b0c07",
            "fileExt": ".pdf",
            "fileName": "6.02 Assessment Schedules grid.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.03 Uniform Assessment Tool policy",
            "type": "policy",
            "id": "b3663f63-4cf5-44f7-8193-835d1de53497",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb3663f63-4cf5-44f7-8193-835d1de53497.pdf?alt=media&token=5ca60473-a022-4634-8803-519d411cef56",
            "fileExt": ".pdf",
            "fileName": "6.03 Uniform Assessment Tool policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.05 Individual Abuse Prevention Plan policy",
            "type": "policy",
            "id": "051dcec3-4041-4713-940c-5c604a23fd20",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F051dcec3-4041-4713-940c-5c604a23fd20.pdf?alt=media&token=1015e546-a5a7-4b9f-b16c-897f8a451bdb",
            "fileExt": ".pdf",
            "fileName": "6.05 Individual Abuse Prevention Plan policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.07  Service Plan – Temporary  policy",
            "type": "policy",
            "id": "86246aec-8fa0-47f2-9244-c99749de1a2e",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F86246aec-8fa0-47f2-9244-c99749de1a2e.pdf?alt=media&token=00a13d6d-9a6f-40cd-bb15-d5cca66f0d4f",
            "fileExt": ".pdf",
            "fileName": "6.07  Service Plan – Temporary  policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.08_ServicePlanpolicy",
            "type": "default",
            "id": "b21f70c3-a5d0-4593-8c4a-60e137deb177",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb21f70c3-a5d0-4593-8c4a-60e137deb177.pdf?alt=media&token=742170e0-1250-491f-b405-3fac27f7cc7a",
            "fileExt": ".pdf",
            "fileName": "6.08_ServicePlanpolicy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.10 Service Plan Modification policy",
            "type": "policy",
            "id": "85863f60-c1fa-4911-a3ed-f3d80806fc69",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F85863f60-c1fa-4911-a3ed-f3d80806fc69.pdf?alt=media&token=08810640-3c9f-46d9-bac3-d2717f83e6b7",
            "fileExt": ".pdf",
            "fileName": "6.10 Service Plan Modification policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.12 Availability of an RN for Staff policy",
            "type": "policy",
            "id": "d5d4f6b0-ca9e-4b47-b6d8-0b3060af881b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd5d4f6b0-ca9e-4b47-b6d8-0b3060af881b.pdf?alt=media&token=71091863-7d37-409a-8cf7-88141ed1ae91",
            "fileExt": ".pdf",
            "fileName": "6.12 Availability of an RN for Staff policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.13 Clinical Nurse Supervisor policy",
            "type": "policy",
            "id": "567e4080-9cf4-459c-bc73-0a484c8bd9fb",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F567e4080-9cf4-459c-bc73-0a484c8bd9fb.pdf?alt=media&token=269fa9be-e672-4364-8a57-291d717bfbd9",
            "fileExt": ".pdf",
            "fileName": "6.13 Clinical Nurse Supervisor policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.15 Staffing Requirements – Licensed Nurse & ULP policy",
            "type": "policy",
            "id": "7527f9cf-4c02-4899-a097-737eb7ac3a06",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7527f9cf-4c02-4899-a097-737eb7ac3a06.pdf?alt=media&token=f3a55a81-308c-4973-96bf-4d12a1180975",
            "fileExt": ".pdf",
            "fileName": "6.15 Staffing Requirements – Licensed Nurse & ULP policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.16 Supervision of Nurses and Licensed Health Professionals policy",
            "type": "policy",
            "id": "642cdceb-1f76-4bfa-a4b6-7a11129f704c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F642cdceb-1f76-4bfa-a4b6-7a11129f704c.pdf?alt=media&token=4c549357-5a88-46ce-8813-47c097eb56f5",
            "fileExt": ".pdf",
            "fileName": "6.16 Supervision of Nurses and Licensed Health Professionals policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.17 Supervision of Staff – Delegated Services policy",
            "type": "policy",
            "id": "89a6b22f-6558-4bb8-9a2a-a9f28f88d8cd",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F89a6b22f-6558-4bb8-9a2a-a9f28f88d8cd.pdf?alt=media&token=a4e9d1ea-85bf-406a-88e4-a07b43ab17a4",
            "fileExt": ".pdf",
            "fileName": "6.17 Supervision of Staff – Delegated Services policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.18 Supervision of Staff – Non-Delegated Services policy",
            "type": "policy",
            "id": "744cd66e-abaa-4c75-a57b-234fb076b651",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F744cd66e-abaa-4c75-a57b-234fb076b651.pdf?alt=media&token=60e6a5b6-25e5-4d45-a72a-632daa3ca903",
            "fileExt": ".pdf",
            "fileName": "6.18 Supervision of Staff – Non-Delegated Services policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.19  Resident Change in Condition or Need policy",
            "type": "policy",
            "id": "c115f853-50d7-4962-aa6c-2719fe64f10d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc115f853-50d7-4962-aa6c-2719fe64f10d.pdf?alt=media&token=b42c4a00-4a32-489d-91c1-81ca808d440b",
            "fileExt": ".pdf",
            "fileName": "6.19  Resident Change in Condition or Need policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.21 Bathing Assistance policy",
            "type": "policy",
            "id": "93ea9c06-3997-4323-85eb-30d6d4c2baad",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F93ea9c06-3997-4323-85eb-30d6d4c2baad.pdf?alt=media&token=f1b85d01-3b1b-4511-b6d2-2a8a27a01c52",
            "fileExt": ".pdf",
            "fileName": "6.21 Bathing Assistance policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.22 Catheter Care policy",
            "type": "policy",
            "id": "b97e0c69-986f-449f-93c0-1d20bfb823e8",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb97e0c69-986f-449f-93c0-1d20bfb823e8.pdf?alt=media&token=2a5baec5-53c6-4697-a7e7-033065f87b9c",
            "fileExt": ".pdf",
            "fileName": "6.22 Catheter Care policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.23 Denture Care policy",
            "type": "policy",
            "id": "7777a0b4-3eff-440c-b512-9b362a22b734",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7777a0b4-3eff-440c-b512-9b362a22b734.pdf?alt=media&token=32cb3867-657f-4af0-b9ce-57c0c47774b5",
            "fileExt": ".pdf",
            "fileName": "6.23 Denture Care policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.24 Dressing Assistance policy",
            "type": "policy",
            "id": "dd01a0d3-ceb1-417e-ac20-30e98b402a50",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdd01a0d3-ceb1-417e-ac20-30e98b402a50.pdf?alt=media&token=59baa7d6-4945-49bb-b241-c5031c87da97",
            "fileExt": ".pdf",
            "fileName": "6.24 Dressing Assistance policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.25 Hospice and Dialysis policy",
            "type": "policy",
            "id": "f6ae7e7f-9ca2-4363-b864-a7fce6cfd882",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff6ae7e7f-9ca2-4363-b864-a7fce6cfd882.pdf?alt=media&token=8936d918-266f-4ef2-b8d6-7fec41fa572e",
            "fileExt": ".pdf",
            "fileName": "6.25 Hospice and Dialysis policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.26  Mouth Care policy",
            "type": "policy",
            "id": "4bdebcdf-7236-4c60-a239-d941607c5de2",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4bdebcdf-7236-4c60-a239-d941607c5de2.pdf?alt=media&token=76a9e29a-dad1-4291-8083-2b9910f8026b",
            "fileExt": ".pdf",
            "fileName": "6.26  Mouth Care policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.27 Nail Care policy",
            "type": "policy",
            "id": "9a691d17-be5d-4dc7-92be-465d73800a9b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9a691d17-be5d-4dc7-92be-465d73800a9b.pdf?alt=media&token=245eb78f-fd42-4175-8e6b-3abea68e104b",
            "fileExt": ".pdf",
            "fileName": "6.27 Nail Care policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.28 Side rails policy updste march 22",
            "type": "policy",
            "id": "32ffa038-062a-4ab2-8dd3-00a131609d8c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F32ffa038-062a-4ab2-8dd3-00a131609d8c.pdf?alt=media&token=2d614ac6-3f1b-47eb-9cee-d60d8c922079",
            "fileExt": ".pdf",
            "fileName": "6.28 Side rails policy updste march 22.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.29 Toileting Assistance policy",
            "type": "policy",
            "id": "0f46879b-d283-4e35-a36f-75380ec03048",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0f46879b-d283-4e35-a36f-75380ec03048.pdf?alt=media&token=e7e2b8d1-da70-4ec3-83a6-cb9abf0c984f",
            "fileExt": ".pdf",
            "fileName": "6.29 Toileting Assistance policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "6.30 Support Stockings TED Hose policy",
            "type": "policy",
            "id": "ac1a1cc7-e90c-4fcf-8178-b07ca494f65d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fac1a1cc7-e90c-4fcf-8178-b07ca494f65d.pdf?alt=media&token=cf2b10a3-f8a5-42b3-a9b1-1ed1b9a6f7ab",
            "fileExt": ".pdf",
            "fileName": "6.30 Support Stockings TED Hose policy.pdf",
            "keywords": [],
            "isBright": true
          }
        ]
      },
      {
        "name": "Chapter 7",
        "type": "dir",
        "id": "7de96436-af8a-4e9a-a852-ebed72f43840",
        "path": "Policies and Procedures/Chapter 7",
        "items": [
          {
            "name": "7.00 Medication Management Services Required Policies",
            "type": "policy",
            "id": "f227b07f-c1db-4ace-aeec-34f9ee27648a",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff227b07f-c1db-4ace-aeec-34f9ee27648a.pdf?alt=media&token=94fcb7ac-7489-4f8f-bda1-c5e367a1cdcd",
            "fileExt": ".pdf",
            "fileName": "7.00 Medication Management Services Required Policies.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.001  Treatment & Therapy Management Services Required Policies",
            "type": "policy",
            "id": "a6e8040e-cf5b-4ef2-84e4-0a6d9e569ea1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa6e8040e-cf5b-4ef2-84e4-0a6d9e569ea1.pdf?alt=media&token=cee2f5e3-0c2e-4550-86e7-1d23b0b0d6c9",
            "fileExt": ".pdf",
            "fileName": "7.001  Treatment & Therapy Management Services Required Policies.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.01 Medication Management – Assessment, Monitoring & Reassessment policy",
            "type": "policy",
            "id": "ff960112-302d-4ebf-8e5b-c7d6e0768067",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fff960112-302d-4ebf-8e5b-c7d6e0768067.pdf?alt=media&token=aa2ecde6-696b-498e-9217-6816485dc384",
            "fileExt": ".pdf",
            "fileName": "7.01 Medication Management – Assessment, Monitoring & Reassessment policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.03 Medication Management Individualized Plan policy",
            "type": "policy",
            "id": "0096b23b-b624-4eef-a449-e0de16fbbedb",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0096b23b-b624-4eef-a449-e0de16fbbedb.pdf?alt=media&token=cd56be14-7c57-459b-9293-52e7ef7217fe",
            "fileExt": ".pdf",
            "fileName": "7.03 Medication Management Individualized Plan policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.05 Treatment & Therapy Management Plan policy",
            "type": "policy",
            "id": "968387e2-71cf-499d-8c39-146c53ef1f55",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F968387e2-71cf-499d-8c39-146c53ef1f55.pdf?alt=media&token=5d52e79b-b95e-4fbd-9da3-2e0b841b1028",
            "fileExt": ".pdf",
            "fileName": "7.05 Treatment & Therapy Management Plan policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.07 Medication Loss or Spillage policy",
            "type": "policy",
            "id": "79ec7fd0-56c2-4c3b-a808-b87b9d975a21",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F79ec7fd0-56c2-4c3b-a808-b87b9d975a21.pdf?alt=media&token=bb11c9a1-4dcd-434c-8ac7-f149671a56d4",
            "fileExt": ".pdf",
            "fileName": "7.07 Medication Loss or Spillage policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.08 Medication Management - Administration & Setup policy",
            "type": "policy",
            "id": "87b3fedb-265a-4ba0-aaf2-186a8eac0fae",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F87b3fedb-265a-4ba0-aaf2-186a8eac0fae.pdf?alt=media&token=7a956e97-75fc-4879-aa85-c347833b7460",
            "fileExt": ".pdf",
            "fileName": "7.08 Medication Management - Administration & Setup policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.09 Medication Management – Dosage Box Setup policy",
            "type": "policy",
            "id": "fd31aa0c-a8c5-4e78-91ab-99495e537c4d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ffd31aa0c-a8c5-4e78-91ab-99495e537c4d.pdf?alt=media&token=bd0ea18f-3865-442d-a74f-81f636971e71",
            "fileExt": ".pdf",
            "fileName": "7.09 Medication Management – Dosage Box Setup policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.10 Medication Management –  Planned & Unplanned Time Away policy",
            "type": "policy",
            "id": "04db3610-7174-4250-8a6d-041c1f3f4961",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F04db3610-7174-4250-8a6d-041c1f3f4961.pdf?alt=media&token=e8dc71bf-f8f2-4d00-bbcf-3e1d5a55e0ec",
            "fileExt": ".pdf",
            "fileName": "7.10 Medication Management –  Planned & Unplanned Time Away policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.11 Medication Storage policy",
            "type": "policy",
            "id": "00332833-842a-4b68-982c-fc527b3ff36a",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F00332833-842a-4b68-982c-fc527b3ff36a.pdf?alt=media&token=cd3b3e57-01cb-46c1-b07e-cacd7397d71c",
            "fileExt": ".pdf",
            "fileName": "7.11 Medication Storage policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.12 Medications – Prescribed, Not Prescribed & OTC policy",
            "type": "policy",
            "id": "938fddec-fdf2-4f27-90cd-96f25ae3309f",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F938fddec-fdf2-4f27-90cd-96f25ae3309f.pdf?alt=media&token=b7fe850c-074d-45b0-b027-403444f8527d",
            "fileExt": ".pdf",
            "fileName": "7.12 Medications – Prescribed, Not Prescribed & OTC policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.13  Medications – Prescription Drugs & Prohibition policy",
            "type": "policy",
            "id": "21a12384-de85-4eaf-b17c-4d5ff1b498c5",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F21a12384-de85-4eaf-b17c-4d5ff1b498c5.pdf?alt=media&token=7304dea6-ee37-470b-ac4c-5f97fedfd48e",
            "fileExt": ".pdf",
            "fileName": "7.13  Medications – Prescription Drugs & Prohibition policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.14  Medications - Provided by Resident or Others policy",
            "type": "policy",
            "id": "8ab20479-87f2-4c53-bb41-6ee1452b0706",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8ab20479-87f2-4c53-bb41-6ee1452b0706.pdf?alt=media&token=b2486d1a-4de7-4677-ba1b-dc53d2453b0c",
            "fileExt": ".pdf",
            "fileName": "7.14  Medications - Provided by Resident or Others policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.15 Medication & Treatment Administration & Delegation policy",
            "type": "policy",
            "id": "8d781bc3-584a-4db5-a3af-18f12ee194dc",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8d781bc3-584a-4db5-a3af-18f12ee194dc.pdf?alt=media&token=67874554-2af4-4b51-83a6-0410e3567a60",
            "fileExt": ".pdf",
            "fileName": "7.15 Medication & Treatment Administration & Delegation policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.16 Medication & Treatment Orders - Implementing policy",
            "type": "policy",
            "id": "3451c9c0-0e48-44ee-b4ac-53a160390270",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3451c9c0-0e48-44ee-b4ac-53a160390270.pdf?alt=media&token=de779ec5-925a-4e15-923f-1980a9417ec3",
            "fileExt": ".pdf",
            "fileName": "7.16 Medication & Treatment Orders - Implementing policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.17 Medication & Treatment Orders - Receiving policy",
            "type": "policy",
            "id": "0e61382a-9f11-4f30-83d8-104658a0a2f9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0e61382a-9f11-4f30-83d8-104658a0a2f9.pdf?alt=media&token=a6529ba3-bc8c-4242-856f-7ed7f2e8d6cc",
            "fileExt": ".pdf",
            "fileName": "7.17 Medication & Treatment Orders - Receiving policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.18 Medication & Treatment Orders - Renewal policy",
            "type": "policy",
            "id": "a9c7c09a-1ad7-4fa7-98ff-c1bb1669e545",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa9c7c09a-1ad7-4fa7-98ff-c1bb1669e545.pdf?alt=media&token=d0a4aa03-836e-4a9c-b348-1311f75a2d0a",
            "fileExt": ".pdf",
            "fileName": "7.18 Medication & Treatment Orders - Renewal policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.19 Medication & Supplies - Reordering policy",
            "type": "policy",
            "id": "7e6e267b-a96e-4425-b926-039cd0263ed7",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7e6e267b-a96e-4425-b926-039cd0263ed7.pdf?alt=media&token=dac26ec6-ce62-47c4-9962-48d7fa59d580",
            "fileExt": ".pdf",
            "fileName": "7.19 Medication & Supplies - Reordering policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.20 Medication & Treatment Orders policy",
            "type": "policy",
            "id": "345418a9-18c0-47cb-9467-0d8b01d45bb7",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F345418a9-18c0-47cb-9467-0d8b01d45bb7.pdf?alt=media&token=8522292e-2c86-4dd3-96bc-390393bb8782",
            "fileExt": ".pdf",
            "fileName": "7.20 Medication & Treatment Orders policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.23 Medication Disposal policy",
            "type": "policy",
            "id": "f2c47ff7-f770-4f32-96ee-ec1cd5e14fa5",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff2c47ff7-f770-4f32-96ee-ec1cd5e14fa5.pdf?alt=media&token=f7b9bea8-33bd-4d6d-b605-eb2cd9c2693b",
            "fileExt": ".pdf",
            "fileName": "7.23 Medication Disposal policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.24 Medication Error policy",
            "type": "policy",
            "id": "4858ddc5-3559-4fce-baf2-1a1b416282b0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4858ddc5-3559-4fce-baf2-1a1b416282b0.pdf?alt=media&token=715bb4e2-0702-417b-af74-799324cd764e",
            "fileExt": ".pdf",
            "fileName": "7.24 Medication Error policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.26 Narcotic Log policy",
            "type": "policy",
            "id": "25703d15-0836-44ef-a8e4-5222baa43101",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F25703d15-0836-44ef-a8e4-5222baa43101.pdf?alt=media&token=dc4d86d6-6681-473c-bf9b-55ad7cba2b6f",
            "fileExt": ".pdf",
            "fileName": "7.26 Narcotic Log policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.27 PRN Medication policy",
            "type": "policy",
            "id": "8860a05d-81a8-48e1-a14a-417af6dda382",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8860a05d-81a8-48e1-a14a-417af6dda382.pdf?alt=media&token=eee7337e-10aa-4836-9f0f-b5c6f78105fd",
            "fileExt": ".pdf",
            "fileName": "7.27 PRN Medication policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.30 Medical Cannabis policy",
            "type": "policy",
            "id": "7bb19ea4-e398-4c03-b226-57dc2f7b7efd",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7bb19ea4-e398-4c03-b226-57dc2f7b7efd.pdf?alt=media&token=41053b09-1956-4c28-bb61-7a495d184172",
            "fileExt": ".pdf",
            "fileName": "7.30 Medical Cannabis policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.31 Blood Sugar Testing - Single Equipment Use policy",
            "type": "policy",
            "id": "810b259d-880e-42d4-8043-35c40c64a057",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F810b259d-880e-42d4-8043-35c40c64a057.pdf?alt=media&token=8f12d6e4-0d1f-413e-9e55-12a3a68bd519",
            "fileExt": ".pdf",
            "fileName": "7.31 Blood Sugar Testing - Single Equipment Use policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.32 Blood Sugar Testing - Shared Equipment Use policy",
            "type": "policy",
            "id": "205c3b96-9b67-4512-b943-63cf7e920e7c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F205c3b96-9b67-4512-b943-63cf7e920e7c.pdf?alt=media&token=ffcbd029-5985-490b-9c8f-430fa949b54c",
            "fileExt": ".pdf",
            "fileName": "7.32 Blood Sugar Testing - Shared Equipment Use policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.33 Ear Drops policy",
            "type": "policy",
            "id": "51e2de1c-5999-4530-a8e1-4967073be44f",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F51e2de1c-5999-4530-a8e1-4967073be44f.pdf?alt=media&token=c733567d-2786-4e8e-8fd9-f6b90ab6f2d3",
            "fileExt": ".pdf",
            "fileName": "7.33 Ear Drops policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.34  Eye Drops & Ointment policy",
            "type": "policy",
            "id": "34db9300-7c24-47e2-bfa9-448bd91e9e12",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F34db9300-7c24-47e2-bfa9-448bd91e9e12.pdf?alt=media&token=8897f970-86ff-41d1-a80f-dc0130fe55c5",
            "fileExt": ".pdf",
            "fileName": "7.34  Eye Drops & Ointment policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.35 Inhaler policy",
            "type": "policy",
            "id": "ed4d7540-8a12-444d-b199-7e65263d2457",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fed4d7540-8a12-444d-b199-7e65263d2457.pdf?alt=media&token=77923983-174c-4b52-aa36-18baede13bf4",
            "fileExt": ".pdf",
            "fileName": "7.35 Inhaler policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.36  Insulin policy",
            "type": "policy",
            "id": "ad01d9fd-aea5-4fd4-ac48-e39f16e691b9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fad01d9fd-aea5-4fd4-ac48-e39f16e691b9.pdf?alt=media&token=8a33c7f4-fd94-442b-a09a-90385535c1eb",
            "fileExt": ".pdf",
            "fileName": "7.36  Insulin policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.37  Nebulizer Treatment policy",
            "type": "policy",
            "id": "89b14204-c812-439c-a631-ff4b1b85212d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F89b14204-c812-439c-a631-ff4b1b85212d.pdf?alt=media&token=8a1eed02-774d-4887-95f1-70a306d8a267",
            "fileExt": ".pdf",
            "fileName": "7.37  Nebulizer Treatment policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.38  Nitro Patch Administration policy",
            "type": "policy",
            "id": "04b263fa-34d4-4d4f-a998-38dc5d3849e5",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F04b263fa-34d4-4d4f-a998-38dc5d3849e5.pdf?alt=media&token=b17735a2-5e19-45e1-aa7a-87ce227a16e3",
            "fileExt": ".pdf",
            "fileName": "7.38  Nitro Patch Administration policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.39 Nose Drops & Nasal Spray policy",
            "type": "policy",
            "id": "b1e01ecb-0c0a-4bde-899f-a33f247360ac",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb1e01ecb-0c0a-4bde-899f-a33f247360ac.pdf?alt=media&token=9cba2b8f-de7b-4f90-a920-eb5c336af8de",
            "fileExt": ".pdf",
            "fileName": "7.39 Nose Drops & Nasal Spray policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.40 Oxygen policy",
            "type": "policy",
            "id": "21cd5fd4-59d2-436a-9344-7d5d4e02a6a0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F21cd5fd4-59d2-436a-9344-7d5d4e02a6a0.pdf?alt=media&token=cf45ed52-545a-46ee-b2b0-1e82fdc17a42",
            "fileExt": ".pdf",
            "fileName": "7.40 Oxygen policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "7.41 Rectal Medication policy",
            "type": "policy",
            "id": "3eb73c7c-ea93-4482-a79a-300ab492d511",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3eb73c7c-ea93-4482-a79a-300ab492d511.pdf?alt=media&token=fa3a3535-0013-4feb-beb0-5b504dc006e8",
            "fileExt": ".pdf",
            "fileName": "7.41 Rectal Medication policy.pdf",
            "keywords": [],
            "isBright": true
          }
        ]
      },
      {
        "name": "Chapter 8",
        "type": "dir",
        "id": "fc1675fb-b247-4146-8495-330fec44dba7",
        "path": "Policies and Procedures/Chapter 8",
        "items": [
          {
            "name": "8.01 Infection Control policy",
            "type": "policy",
            "id": "0fa574a6-4113-4b17-b2ee-bace9139457a",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0fa574a6-4113-4b17-b2ee-bace9139457a.pdf?alt=media&token=9c63a187-2666-4108-8272-d988dc18d6ef",
            "fileExt": ".pdf",
            "fileName": "8.01 Infection Control policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.02 Bloodborne Pathogens policy",
            "type": "policy",
            "id": "e45cf1f1-f869-43b3-a59d-439c8598793e",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe45cf1f1-f869-43b3-a59d-439c8598793e.pdf?alt=media&token=b7c0a89d-dbd5-426a-92f1-357efff95ae7",
            "fileExt": ".pdf",
            "fileName": "8.02 Bloodborne Pathogens policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.03 Cleaning of Shared Medical Equipment policy",
            "type": "policy",
            "id": "1d09a3db-8cc7-4f33-a4d1-8bca2b95992f",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1d09a3db-8cc7-4f33-a4d1-8bca2b95992f.pdf?alt=media&token=b5b429fa-d530-4aae-ae9c-f228b2b549d4",
            "fileExt": ".pdf",
            "fileName": "8.03 Cleaning of Shared Medical Equipment policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.04  Communicable Diseases policy",
            "type": "policy",
            "id": "3206ad7f-ecb2-4264-94c6-d5f5c539df97",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3206ad7f-ecb2-4264-94c6-d5f5c539df97.pdf?alt=media&token=8f677777-b45c-4628-ab59-0e95dc8a7218",
            "fileExt": ".pdf",
            "fileName": "8.04  Communicable Diseases policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.05  Disinfecting Environmental Surfaces policy",
            "type": "policy",
            "id": "585addb1-817d-4888-bbab-045417ea615d",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F585addb1-817d-4888-bbab-045417ea615d.pdf?alt=media&token=248d67a3-ba06-4926-a1c9-e51b8df16ea1",
            "fileExt": ".pdf",
            "fileName": "8.05  Disinfecting Environmental Surfaces policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.06 Disposal of Contaminated Materials policy",
            "type": "policy",
            "id": "1e173775-ff6a-4ff3-b5b9-7e8655222afc",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1e173775-ff6a-4ff3-b5b9-7e8655222afc.pdf?alt=media&token=facb4a4f-812e-45ee-9eb2-5d64bfa15f63",
            "fileExt": ".pdf",
            "fileName": "8.06 Disposal of Contaminated Materials policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.07 Gloves policy",
            "type": "policy",
            "id": "b9bd0ac8-945e-4d2f-a79b-90a85a771107",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb9bd0ac8-945e-4d2f-a79b-90a85a771107.pdf?alt=media&token=8cf405d7-bdf6-4b88-a04b-32168f83435f",
            "fileExt": ".pdf",
            "fileName": "8.07 Gloves policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.08  Gowns policy",
            "type": "policy",
            "id": "c4ef9502-29f1-4bd5-afd1-f49284117fe6",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc4ef9502-29f1-4bd5-afd1-f49284117fe6.pdf?alt=media&token=dfcf3c67-59c0-4604-906c-ec700caaae45",
            "fileExt": ".pdf",
            "fileName": "8.08  Gowns policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.09 Hand washing policy",
            "type": "policy",
            "id": "f90f7e87-b1fb-4f24-aaed-f90622a1c4af",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff90f7e87-b1fb-4f24-aaed-f90622a1c4af.pdf?alt=media&token=6db4837b-4fc4-4d5d-8ab9-eb2171419d64",
            "fileExt": ".pdf",
            "fileName": "8.09 Hand washing policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.10 Hepatitis B Vaccination & Post-Exposure Evaluation policy",
            "type": "policy",
            "id": "8ffc9d7d-2cb5-46c4-89c1-bced0cf62cc0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8ffc9d7d-2cb5-46c4-89c1-bced0cf62cc0.pdf?alt=media&token=953fcc2b-8d31-4f50-bdc5-027977bb71d6",
            "fileExt": ".pdf",
            "fileName": "8.10 Hepatitis B Vaccination & Post-Exposure Evaluation policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.12 Masks policy",
            "type": "policy",
            "id": "9fc02dc8-a9d9-402e-af9d-a29844168381",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9fc02dc8-a9d9-402e-af9d-a29844168381.pdf?alt=media&token=f937e038-d65d-41f5-9a42-986b65f7fa74",
            "fileExt": ".pdf",
            "fileName": "8.12 Masks policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.13 Pathogen Exposure Control Plan policy",
            "type": "policy",
            "id": "e12ff024-027c-4b6f-9c86-478fe1d1ebd6",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe12ff024-027c-4b6f-9c86-478fe1d1ebd6.pdf?alt=media&token=a4afaec6-d0ff-4916-ad39-11626e76a8a9",
            "fileExt": ".pdf",
            "fileName": "8.13 Pathogen Exposure Control Plan policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.14 Standard Precautions policy",
            "type": "policy",
            "id": "a47f721d-3b5a-4597-850a-8758ae38781f",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa47f721d-3b5a-4597-850a-8758ae38781f.pdf?alt=media&token=adc8f499-d52a-49ad-a733-87d97c7da7ba",
            "fileExt": ".pdf",
            "fileName": "8.14 Standard Precautions policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.16 Tuberculosis Screening policy",
            "type": "policy",
            "id": "0da426e6-4f84-49bb-a1e6-998e48403ec9",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0da426e6-4f84-49bb-a1e6-998e48403ec9.pdf?alt=media&token=0b759f1a-2986-416f-ace5-b4511829e3f7",
            "fileExt": ".pdf",
            "fileName": "8.16 Tuberculosis Screening policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.17 Tuberculosis Skin Testing Protocol",
            "type": "default",
            "id": "e323a668-6bc6-4f78-a840-4edcfd64ed8b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe323a668-6bc6-4f78-a840-4edcfd64ed8b.pdf?alt=media&token=73889e58-45b2-4b5c-8c97-eca0bcfbeb58",
            "fileExt": ".pdf",
            "fileName": "8.17 Tuberculosis Skin Testing Protocol.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "8.11 Hepatitis B Vaccination Consent sample form",
            "type": "form",
            "id": "c9e54bcc-8597-4548-a559-ec19baa09496",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc9e54bcc-8597-4548-a559-ec19baa09496.pdf?alt=media&token=1eba7123-051d-4c70-817a-9d36795b2813",
            "fileExt": ".pdf",
            "fileName": "8.11 Hepatitis B Vaccination Consent sample form.pdf"
          },
          {
            "name": "8.15 Tuberculosis Screening form",
            "type": "form",
            "id": "ee5154e0-9293-4dbd-b189-6b0538c3fe76",
            "keywords": [],
            "isBright": true,
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fee5154e0-9293-4dbd-b189-6b0538c3fe76.pdf?alt=media&token=46082809-6ce2-4546-a39d-f36859e6a057",
            "fileExt": ".pdf",
            "fileName": "8.15 Tuberculosis Screening form.pdf"
          }
        ]
      },
      {
        "name": "Chapter 9",
        "type": "dir",
        "id": "7ba3318e-5be1-49e5-9b18-95c5eed195bc",
        "path": "Policies and Procedures/Chapter 9",
        "items": [
          {
            "name": "9.01 Emergency Preparedness Plan – Appendix Z Compliance policy",
            "type": "policy",
            "id": "c8c224c1-2724-431a-9010-0980e9477523",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc8c224c1-2724-431a-9010-0980e9477523.pdf?alt=media&token=997d3fb8-c495-4c3e-8aa6-85b69d53053e",
            "fileExt": ".pdf",
            "fileName": "9.01 Emergency Preparedness Plan – Appendix Z Compliance policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.02 Disaster Planning and Emergency Preparedness policy",
            "type": "policy",
            "id": "c5f8a2e3-6b12-47a4-8c3a-9d72fb1cc612",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc5f8a2e3-6b12-47a4-8c3a-9d72fb1cc612.pdf?alt=media&token=9f5fca37-d130-4e18-8c2a-abf32076b174",
            "fileExt": ".pdf",
            "fileName": "9.02 Disaster Planning and Emergency Preparedness policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.03 Physical Plant Requirements policy",
            "type": "policy",
            "id": "065fd881-ddfd-49f9-8bca-00bc2409144e",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F065fd881-ddfd-49f9-8bca-00bc2409144e.pdf?alt=media&token=007fa8e2-e694-4f5b-9bc5-510857e4b4ec",
            "fileExt": ".pdf",
            "fileName": "9.03 Physical Plant Requirements policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.04 Bomb Threat policy",
            "type": "policy",
            "id": "aa1b482e-fda6-43e1-811a-5d39117a41c5",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Faa1b482e-fda6-43e1-811a-5d39117a41c5.pdf?alt=media&token=f0616b61-c23b-47e9-88db-dd772d6b8c2b",
            "fileExt": ".pdf",
            "fileName": "9.04 Bomb Threat policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.05  FGI Residental Care Code policy",
            "type": "policy",
            "id": "0926951b-96e4-433c-9c58-8c8f8201ad09",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0926951b-96e4-433c-9c58-8c8f8201ad09.pdf?alt=media&token=27d1bef5-ad31-45a7-a32e-c6e25ceec985",
            "fileExt": ".pdf",
            "fileName": "9.05  FGI Residental Care Code policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.06 Elysian Fire policy",
            "type": "policy",
            "id": "11d4899f-e7f9-4fd9-a778-c42ad6ca1aa1",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F11d4899f-e7f9-4fd9-a778-c42ad6ca1aa1.pdf?alt=media&token=5f86b005-0e1e-4132-a010-acd1ac645765",
            "fileExt": ".pdf",
            "fileName": "9.06 Elysian Fire policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.07 Fire Safety, Evacuation Plan, Fire Drills policy",
            "type": "policy",
            "id": "d7c704a8-94a6-455c-9130-f4a804df48f0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd7c704a8-94a6-455c-9130-f4a804df48f0.pdf?alt=media&token=d793c4a5-169f-4a8b-b9a7-f2dbb910e5a2",
            "fileExt": ".pdf",
            "fileName": "9.07 Fire Safety, Evacuation Plan, Fire Drills policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.08 Heat and Humidity policy",
            "type": "policy",
            "id": "4c89b63a-b0a2-4760-96dd-dd3a70c017e8",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4c89b63a-b0a2-4760-96dd-dd3a70c017e8.pdf?alt=media&token=d6d62087-1df3-489c-b6b9-2409def14113",
            "fileExt": ".pdf",
            "fileName": "9.08 Heat and Humidity policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.09  Life Safety Code policy",
            "type": "policy",
            "id": "ca9d0c58-18a9-4762-ad6b-6524063f3edf",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fca9d0c58-18a9-4762-ad6b-6524063f3edf.pdf?alt=media&token=cb75cca2-02e2-4429-ac3d-31c52f432092",
            "fileExt": ".pdf",
            "fileName": "9.09  Life Safety Code policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.10  Severe Weather policy",
            "type": "policy",
            "id": "ced4c58e-946e-4e8b-92a6-6192e399896b",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fced4c58e-946e-4e8b-92a6-6192e399896b.pdf?alt=media&token=597485e6-6b2f-488a-be77-020e404148b7",
            "fileExt": ".pdf",
            "fileName": "9.10  Severe Weather policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.11 Water Shortage policy",
            "type": "policy",
            "id": "1b5dcf7e-61bf-4772-bb29-6ed208d66530",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1b5dcf7e-61bf-4772-bb29-6ed208d66530.pdf?alt=media&token=ddb77341-9c7e-4031-80f8-9fc180a04838",
            "fileExt": ".pdf",
            "fileName": "9.11 Water Shortage policy.pdf",
            "keywords": [],
            "isBright": true
          },
          {
            "name": "9.12 Winter Storms policy",
            "type": "policy",
            "id": "853f822f-450c-4b8c-b530-059815fdffa0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F853f822f-450c-4b8c-b530-059815fdffa0.pdf?alt=media&token=8486785a-a1ea-40c1-a512-6e0957f6508e",
            "fileExt": ".pdf",
            "fileName": "9.12 Winter Storms policy.pdf",
            "keywords": [],
            "isBright": true
          }
        ]
      }
    ]
}

const EmergencyManual = {name: 'Emergency Manual', type:"dir", open: false,  items: [
  {
    "name": "AppendixB_EvacuationChecklistandForms",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4413eedc-84bc-45ce-ad55-ded775623c9e.pdf?alt=media&token=02ff1c2f-9f0a-4b90-bb7c-a24bfb611806",
    "fileExt": ".pdf",
    "fileName": "AppendixB_EvacuationChecklistandForms.pdf"
  },
  {
    "name": "AppendixC_1_EmergencyPreparednessVolunteerQuestionnaire",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fac5329f3-ddbf-46f0-873c-8bbef8cd8786.pdf?alt=media&token=e14bafbe-1486-4caa-8d41-28d2be861145",
    "fileExt": ".pdf",
    "fileName": "AppendixC_1_EmergencyPreparednessVolunteerQuestionnaire.pdf"
  },
  {
    "name": "AppendixC_2_StaffEvacuationTrackingForm",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F218a060e-cb3d-42d0-b693-dbcfa7537380.pdf?alt=media&token=e0faab35-9638-4399-a081-bc88414d98a3",
    "fileExt": ".pdf",
    "fileName": "AppendixC_2_StaffEvacuationTrackingForm.pdf"
  },
  {
    "name": "AppendixC_3_VolunteerEvacuationTrackingForm",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdb46745e-4751-471d-9e07-b9cda3ea71ba.pdf?alt=media&token=c94b6a55-e41a-425c-8639-aa8f9a3ce888",
    "fileExt": ".pdf",
    "fileName": "AppendixC_3_VolunteerEvacuationTrackingForm.pdf"
  },
  {
    "name": "AppendixC_4_VolunteerSkills",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb963ce7a-4864-4536-ba1c-23b9306ef076.pdf?alt=media&token=df04e4ba-ce1c-43c8-9730-192336b8900e",
    "fileExt": ".pdf",
    "fileName": "AppendixC_4_VolunteerSkills.pdf"
  },
  {
    "name": "AppendixD_1_ResEvacTrackingForm",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff149ad80-9db2-4a05-b1a6-af4fc706bb34.pdf?alt=media&token=e9f230c9-6066-481e-b386-aebbd8551192",
    "fileExt": ".pdf",
    "fileName": "AppendixD_1_ResEvacTrackingForm.pdf"
  },
  {
    "name": "AppendixD_2_SampleResidentProfile",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fca03a7e9-fae3-43d4-9674-d2f781dfeca1.pdf?alt=media&token=1441c812-6ac9-4232-b68d-db2b0efdfd86",
    "fileExt": ".pdf",
    "fileName": "AppendixD_2_SampleResidentProfile.pdf"
  },
  {
    "name": "AppendixE_MapandEvacuationDiagrams",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8477c16d-e796-4360-a137-86bacf17b68e.pdf?alt=media&token=9229b4c9-45ed-4450-968f-568f1b4bfbd5",
    "fileExt": ".pdf",
    "fileName": "AppendixE_MapandEvacuationDiagrams.pdf"
  },
  {
    "name": "AppendixF_KeyVendorContactList",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe8641660-9b38-475f-a65e-0e67e18ee147.pdf?alt=media&token=59eaa2ab-1558-4c65-b7ec-5585dac34ba2",
    "fileExt": ".pdf",
    "fileName": "AppendixF_KeyVendorContactList.pdf"
  },
  {
    "name": "AppendixG_1_Sample_AL_FireDrill_Report",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F216005bb-6cab-4cc1-bf0e-3591454837fc.pdf?alt=media&token=974c1b4d-2c4c-4678-92d0-559af26a9d83",
    "fileExt": ".pdf",
    "fileName": "AppendixG_1_Sample_AL_FireDrill_Report.pdf"
  },
  {
    "name": "AppendixG_2_Sample_AL_FireDrill_Scheduling",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F172a8e85-7041-498d-9f6b-ba2d59eac6e2.pdf?alt=media&token=ca74eb75-233a-4caf-898c-020bda3aad16",
    "fileExt": ".pdf",
    "fileName": "AppendixG_2_Sample_AL_FireDrill_Scheduling.pdf"
  },
  {
    "name": "AppendixH_1_FacilityDrillsDocumentation",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F36d954aa-be21-4ccd-afa2-0ee5eba2699d.pdf?alt=media&token=96df2516-0712-4b95-bc7f-b4bbc4b7d018",
    "fileExt": ".pdf",
    "fileName": "AppendixH_1_FacilityDrillsDocumentation.pdf"
  },
  {
    "name": "AppendixH_2_TabletopExerciseBasics",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F97386571-856d-4538-a420-9d179f5e4a4f.pdf?alt=media&token=5729d252-526b-427e-b808-09ba8cd81fb7",
    "fileExt": ".pdf",
    "fileName": "AppendixH_2_TabletopExerciseBasics.pdf"
  },
  {
    "name": "AppendixI_1_AHCAHVAtoolinstructions",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8a9ba863-f0a8-428b-b78d-95de423a21f6.pdf?alt=media&token=cbcb23a7-a223-40dc-9bf5-4b2465dabf52",
    "fileExt": ".pdf",
    "fileName": "AppendixI_1_AHCAHVAtoolinstructions.pdf"
  },
  {
    "name": "AppendixL_AppendixZLTCRequirements_032621",
    "type": "reg",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F645e179e-9d0d-43dc-8aec-db7208fdef74.pdf?alt=media&token=167579fb-e15f-4583-b364-34edec225e3b",
    "fileExt": ".pdf",
    "fileName": "AppendixL_AppendixZLTCRequirements_032621.pdf"
  },
  {
    "name": "AppendixM_MDHEPSurveyChecklist",
    "type": "form",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F15a3a46d-64d2-4bfc-aafc-0e0da30f430f.pdf?alt=media&token=52e05192-cb27-4539-8a03-d698a1823db4",
    "fileExt": ".pdf",
    "fileName": "AppendixM_MDHEPSurveyChecklist.pdf"
  },
  {
    "name": "AppendixN_AdditionalRequirementstoAppendixZ",
    "type": "reg",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff1e0b4bb-c95c-4853-80e5-2cf35dedef1a.pdf?alt=media&token=3da637eb-2305-44e5-b96d-58eacd71755c",
    "fileExt": ".pdf",
    "fileName": "AppendixN_AdditionalRequirementstoAppendixZ.pdf"
  }
]} // EmergencyManual

const EmploymentDocs = {name: 'Employment Documents', type:"dir", open: false,  items: [
  {
    "name": "All Staff",
    "type": "dir",
    "open": false,
    "id": "7b30463a-b432-48e1-b36d-d301af82f7a1",
    "path": "Employment Documents/All Staff",
    items: [
      {
        "name": "2.16 Expense Report form",
        "type": "form",
        "id": "ca44f15a-948a-45fe-a370-71a4d06c0dd2",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fca44f15a-948a-45fe-a370-71a4d06c0dd2.pdf?alt=media&token=d394a4b8-085f-4944-b412-97e7ade69a09",
        "fileExt": ".pdf",
        "fileName": "2.16 Expense Report form.pdf"
      },
      {
        "name": "2023-2024 Benefit Forms",
        "type": "form",
        "id": "44cb8883-bf86-46dd-a389-f1b207d8f9f7",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F44cb8883-bf86-46dd-a389-f1b207d8f9f7.pdf?alt=media&token=bdd719fd-91aa-4072-a823-7f7d97ed6b1d",
        "fileExt": ".pdf",
        "fileName": "2023-2024 Benefit Forms.pdf"
      },
      {
        "name": "2023-2024 Benefit Guide - Full Time",
        "type": "form",
        "id": "bd683809-d4a7-474f-ad93-c81c3f105889",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fbd683809-d4a7-474f-ad93-c81c3f105889.pdf?alt=media&token=7a4554dc-ac50-42fe-9b29-09318053a3c2",
        "fileExt": ".pdf",
        "fileName": "2023-2024 Benefit Guide - Full Time.pdf"
      },
      {
        "name": "2023-2024 Benefit Guide - Part Time",
        "type": "form",
        "id": "6e78b347-a1f0-479b-bad4-34663ac2bc0c",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F6e78b347-a1f0-479b-bad4-34663ac2bc0c.pdf?alt=media&token=1d3c8b9e-5516-4a3d-81a8-c6dded549ce5",
        "fileExt": ".pdf",
        "fileName": "2023-2024 Benefit Guide - Part Time.pdf"
      },
      {
        "name": "3.05 Leave of Absence Request",
        "type": "form",
        "id": "8d485a42-3736-4b56-b05a-6a059bc37d1a",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8d485a42-3736-4b56-b05a-6a059bc37d1a.pdf?alt=media&token=f7753c84-c256-4da6-ac98-80fda2cb2f9d",
        "fileExt": ".pdf",
        "fileName": "3.05 Leave of Absence Request.pdf"
      },
      {
        "name": "3.06 First Report of Injury",
        "type": "form",
        "id": "52b99f2c-6e2f-4678-b923-1bb206de8f6c",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F52b99f2c-6e2f-4678-b923-1bb206de8f6c.pdf?alt=media&token=2e852af2-871c-4efb-b4dc-fa76b81c326d",
        "fileExt": ".pdf",
        "fileName": "3.06 First Report of Injury.pdf"
      },
      {
        "name": "Elysian Employee Handbook - Updated 2024.01",
        isBright: true,
        "type": "form",
        "id": "f98394a3-b766-4393-8ae0-c4dc10afdf5f",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff98394a3-b766-4393-8ae0-c4dc10afdf5f.pdf?alt=media&token=436ba68e-4229-4e4c-a309-5fb319e24533",
        "fileExt": ".pdf",
        "fileName": "Elysian Employee Handbook - Updated 2024.01.pdf"
      },
      {
        "name": "PTO Payout Request",
        "type": "form",
        "id": "614a9924-e416-4be4-8903-f2d194ac5290",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F614a9924-e416-4be4-8903-f2d194ac5290.pdf?alt=media&token=7fcc0385-2091-416f-8375-4a11d0f45dd8",
        "fileExt": ".pdf",
        "fileName": "PTO Payout Request.pdf"
      },
      {
        "name": "Resignation Letter",
        "type": "form",
        "id": "86837f78-ea52-4ed2-b4b9-c82ce709ae0c",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F86837f78-ea52-4ed2-b4b9-c82ce709ae0c.pdf?alt=media&token=bfa8a0de-90fd-4aea-a7a2-3685f886b339",
        "fileExt": ".pdf",
        "fileName": "Resignation Letter.pdf"
      }
    ]
  },
  {
    "name": "MN Employment Docs",
    "type": "dir",
    "open": false,
    "id": "0979c84c-baca-452b-907f-9d5272dd5dfa",
    "path": "Employment Documents/MN Employment Docs",
    items: [
      {
        "name": "MN Labor Laws",
        "type": "form",
        "id": "db9b8dcb-43b7-42e3-b064-aeccb0b3328e",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdb9b8dcb-43b7-42e3-b064-aeccb0b3328e.pdf?alt=media&token=145e4c25-7ca7-46e2-b6a4-b39ad101c4fb",
        "fileExt": ".pdf",
        "fileName": "MN Labor Laws.pdf"
      }
    ]
  }
]} // EmploymentDocs

const Instructions = {name: 'Instructions', type:"dir", open: false,  items: [
  {
    "name": "INSTRUCTIONS - Posting a deposit in RTasks",
    "type": "form",
    "id": "cf5afaf0-0e77-4e66-bbcc-6d36568ad309",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fcf5afaf0-0e77-4e66-bbcc-6d36568ad309.pdf?alt=media&token=9ffa4640-db1d-4648-8ba0-9e18eaa27136",
    "fileExt": ".pdf",
    "fileName": "INSTRUCTIONS - Posting a deposit in RTasks.pdf"
  },
  {
    "name": "INSTRUCTIONS - RTasks Billing Information",
    "type": "form",
    "id": "8c8252d7-0eb8-4007-bada-f1e0261e1bb3",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8c8252d7-0eb8-4007-bada-f1e0261e1bb3.pdf?alt=media&token=26a595a0-dc2c-4b17-bfa2-18b96bbea013",
    "fileExt": ".pdf",
    "fileName": "INSTRUCTIONS - RTasks Billing Information.pdf"
  },
  {
    "name": "INSTRUCTIONS - Uploading a picutre in RTasks",
    "type": "form",
    "id": "6a003c3d-4d72-43bd-870a-6d963557a6dd",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F6a003c3d-4d72-43bd-870a-6d963557a6dd.pdf?alt=media&token=5a650a92-39c3-474a-98d4-06a4c64b8391",
    "fileExt": ".pdf",
    "fileName": "INSTRUCTIONS - Uploading a picutre in RTasks.pdf"
  }
]}// Instructions

const Regulations2 = {name: 'Regulations', type:"dir", open: false,  items: [ 
  { name:"Assisted Living Statutes 144G", type:"reg" },
    { name:"Assisted Living Rules 4659", type:"reg" },
 ]} // Regulations

 const Competencies = {name: 'Competencies', type:"dir", open: false,  "items": [
  {
    "name": "Chapter 1 Introduction",
    "type": "dir",
    "id": "865b9104-532c-47cf-898e-612dfbc17d56",
    "path": "Competencies/Chapter 1 Introduction",
    "items": [
      {
        "name": "1.1 Policy Procedure Review",
        "type": "POLICY", isBright: true,
        "id": "b944c116-d3ae-4823-a8f0-0913b4ae6296",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb944c116-d3ae-4823-a8f0-0913b4ae6296.docx?alt=media&token=7077190b-3fd8-4ad2-9b00-78a394a62d90",
        "fileExt": ".docx",
        "fileName": "1.1 Policy Procedure Review.docx"
      },
      {
        "name": "1.2 Orientation to Plan of Care",
        "type": "POLICY", isBright: true,
        "id": "d51e88da-3576-4ed4-8b34-176e0e6e6329",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd51e88da-3576-4ed4-8b34-176e0e6e6329.docx?alt=media&token=6a55bbb2-b4bb-4bb7-83fc-a26f17d8d5e1",
        "fileExt": ".docx",
        "fileName": "1.2 Orientation to Plan of Care.docx"
      },
      {
        "name": "1.3 CPR and Heimlich Manuever",
        "type": "POLICY", isBright: true,
        "id": "892d8be3-d5b2-4c3f-b0af-757d5d96bb28",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F892d8be3-d5b2-4c3f-b0af-757d5d96bb28.docx?alt=media&token=6c25173a-434f-43fc-be6c-63c7f07916cc",
        "fileExt": ".docx",
        "fileName": "1.3 CPR and Heimlich Manuever.docx"
      },
      {
        "name": "1.4 Alarm Use",
        "type": "POLICY", isBright: true,
        "id": "437882c7-afb3-4a6e-8bda-2ef1563df9b6",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F437882c7-afb3-4a6e-8bda-2ef1563df9b6.docx?alt=media&token=acb418dd-44f8-45b0-9e10-6729c7107d89",
        "fileExt": ".docx",
        "fileName": "1.4 Alarm Use.docx"
      },
    ]
  },
  {
    "name": "Chapter 10 Diabetes Management",
    "type": "dir",
    "id": "81f2bec0-a413-45ca-9358-6d96b84bf0bc",
    "path": "Competencies/Chapter 10 Diabetes Management",
    "items": [
      {
        "name": "10.1 Diabetes Management Symptoms",
        "type": "POLICY", isBright: true,
        "id": "bf184830-9fec-4aa8-a93d-c4aa7f1304ef",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fbf184830-9fec-4aa8-a93d-c4aa7f1304ef.docx?alt=media&token=b75acb45-ea15-4729-b12a-c4f8d7f6c6e2",
        "fileExt": ".docx",
        "fileName": "10.1 Diabetes Management Symptoms.docx"
      },
      {
        "name": "10.2 Diabetes Maangement Blood Glucose",
        "type": "POLICY", isBright: true,
        "id": "1f9b5782-6e01-48c1-b239-c05582b21d09",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1f9b5782-6e01-48c1-b239-c05582b21d09.docx?alt=media&token=f83194ea-0a8c-47c9-a2f5-432b3a42ae37",
        "fileExt": ".docx",
        "fileName": "10.2 Diabetes Maangement Blood Glucose.docx"
      },
      {
        "name": "10.3  Diabetes Management Insulin Adminstration",
        "type": "POLICY", isBright: true,
        "id": "076ae19d-e3e1-431f-8649-d9487b208e54",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F076ae19d-e3e1-431f-8649-d9487b208e54.docx?alt=media&token=97e09349-69d3-4ee3-8471-ee7efa5612a7",
        "fileExt": ".docx",
        "fileName": "10.3  Diabetes Management Insulin Adminstration.docx"
      }
    ]
  },
  {
    "name": "Chapter 2 Personal Care",
    "type": "dir",
    "id": "de4284fe-8ab3-4489-bef2-5c4c863e4520",
    "path": "Competencies/Chapter 2 Personal Care",
    "items": [
      {
        "name": "2.1 Personal Care Hand Washing",
        "type": "POLICY", isBright: true,
        "id": "b54650b2-d55e-4f4f-9c7b-d799484f16e8",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fb54650b2-d55e-4f4f-9c7b-d799484f16e8.docx?alt=media&token=5ce5a5b4-9fc7-4106-9629-8be57374eb13",
        "fileExt": ".docx",
        "fileName": "2.1 Personal Care Hand Washing.docx"
      },
      {
        "name": "2.10 Personal Care Shaving Assistance",
        "type": "POLICY", isBright: true,
        "id": "a1da76f8-6d4f-43f9-88a8-58b50f6c1611",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa1da76f8-6d4f-43f9-88a8-58b50f6c1611.docx?alt=media&token=a6b80d9c-962e-4827-a23e-b1744db40002",
        "fileExt": ".docx",
        "fileName": "2.10 Personal Care Shaving Assistance.docx"
      },
      {
        "name": "2.11 Personal Care Hearing Aid Use",
        "type": "POLICY", isBright: true,
        "id": "7d19bcc5-7207-41ab-9d41-1a63bd804ee2",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7d19bcc5-7207-41ab-9d41-1a63bd804ee2.docx?alt=media&token=4d8a1964-026d-4246-9ac1-72f7cf7cd6ae",
        "fileExt": ".docx",
        "fileName": "2.11 Personal Care Hearing Aid Use.docx"
      },
      {
        "name": "2.12 Personal Care Nail Care Assistance",
        "type": "POLICY", isBright: true,
        "id": "2a93e202-3400-4518-8908-269b298e4ee4",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2a93e202-3400-4518-8908-269b298e4ee4.docx?alt=media&token=4d88a99f-2b7a-4ac0-8528-37e677e93279",
        "fileExt": ".docx",
        "fileName": "2.12 Personal Care Nail Care Assistance.docx"
      },
      {
        "name": "2.13 Personal Care Toileting Assistance",
        "type": "POLICY", isBright: true,
        "id": "327996f2-9a68-4177-8ffa-bf9d0fad857d",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F327996f2-9a68-4177-8ffa-bf9d0fad857d.docx?alt=media&token=77d16311-5d9a-4eab-81dd-29589cd972bc",
        "fileExt": ".docx",
        "fileName": "2.13 Personal Care Toileting Assistance.docx"
      },
      {
        "name": "2.14 Personal Care Bandage Wrap Application",
        "type": "POLICY", isBright: true,
        "id": "30753ea3-f75a-469f-829c-be20a4fe2a69",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F30753ea3-f75a-469f-829c-be20a4fe2a69.docx?alt=media&token=f5662b61-f4ec-4bee-96b6-3522790f30c0",
        "fileExt": ".docx",
        "fileName": "2.14 Personal Care Bandage Wrap Application.docx"
      },
      {
        "name": "2.15 Personal Care Splint and Brace Application",
        "type": "POLICY", isBright: true,
        "id": "d9b4d63b-c9db-49e3-b9c4-97d75c790b3e",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd9b4d63b-c9db-49e3-b9c4-97d75c790b3e.docx?alt=media&token=ca5dcbef-ec98-4256-aeb3-7047ed7c536e",
        "fileExt": ".docx",
        "fileName": "2.15 Personal Care Splint and Brace Application.docx"
      },
      {
        "name": "2.16 Personal Care Catheter Care",
        "type": "POLICY", isBright: true,
        "id": "cc338cc3-92f8-43a0-8988-a76c0960538b",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fcc338cc3-92f8-43a0-8988-a76c0960538b.docx?alt=media&token=b068cdfa-eace-4f07-9abd-2731396beeb1",
        "fileExt": ".docx",
        "fileName": "2.16 Personal Care Catheter Care.docx"
      },
      {
        "name": "2.17 Personal Care Commode Use",
        "type": "POLICY", isBright: true,
        "id": "8cbe04e3-0049-4dac-8984-20c2efba030a",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8cbe04e3-0049-4dac-8984-20c2efba030a.docx?alt=media&token=830ef498-fbcf-4308-bfc8-59bf37ef4789",
        "fileExt": ".docx",
        "fileName": "2.17 Personal Care Commode Use.docx"
      },
      {
        "name": "2.18 Personal Care Shower Chair Use",
        "type": "POLICY", isBright: true,
        "id": "0be63a73-2199-4e5b-84b0-55b350e71596",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F0be63a73-2199-4e5b-84b0-55b350e71596.docx?alt=media&token=5a2c16f4-a36c-4749-91e8-854b0b5d3b7d",
        "fileExt": ".docx",
        "fileName": "2.18 Personal Care Shower Chair Use.docx"
      },
      {
        "name": "2.2 Personal Care Change in Condition",
        "type": "POLICY", isBright: true,
        "id": "ec94b59b-7593-4de2-b6de-427c943efba4",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fec94b59b-7593-4de2-b6de-427c943efba4.docx?alt=media&token=c12a7bb8-17ad-47f1-b568-d0d474c72254",
        "fileExt": ".docx",
        "fileName": "2.2 Personal Care Change in Condition.docx"
      },
      {
        "name": "2.3 Personal Care General Procedure",
        "type": "POLICY", isBright: true,
        "id": "d0fbfca9-019f-49b2-ba6b-b67e7cb830df",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd0fbfca9-019f-49b2-ba6b-b67e7cb830df.docx?alt=media&token=5cde0736-6d60-4247-ad44-bd6940a05095",
        "fileExt": ".docx",
        "fileName": "2.3 Personal Care General Procedure.docx"
      },
      {
        "name": "2.4 Personal Care Bathing Assistance",
        "type": "POLICY", isBright: true,
        "id": "2005df75-f5b0-4a57-8977-44683fe9eb2a",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F2005df75-f5b0-4a57-8977-44683fe9eb2a.docx?alt=media&token=1c75ba7c-cb11-425c-9919-cd5402ee0be1",
        "fileExt": ".docx",
        "fileName": "2.4 Personal Care Bathing Assistance.docx"
      },
      {
        "name": "2.5 Personal Care Hair Care Assistance",
        "type": "POLICY", isBright: true,
        "id": "9d7aa95e-46a8-4b1f-b021-f117604dfe1b",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9d7aa95e-46a8-4b1f-b021-f117604dfe1b.docx?alt=media&token=6fcead6f-6e16-44eb-a441-9a21e62c874c",
        "fileExt": ".docx",
        "fileName": "2.5 Personal Care Hair Care Assistance.docx"
      },
      {
        "name": "2.6 Personal Care Skin Care Assistacne",
        "type": "POLICY", isBright: true,
        "id": "3edde45f-c3c1-48f0-994a-1ed3a6076c77",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3edde45f-c3c1-48f0-994a-1ed3a6076c77.docx?alt=media&token=0fe73218-f59c-48e2-b6db-5fcf067a8c56",
        "fileExt": ".docx",
        "fileName": "2.6 Personal Care Skin Care Assistacne.docx"
      },
      {
        "name": "2.7 Personal Care Dressing Assistance",
        "type": "POLICY", isBright: true,
        "id": "33e8c5e2-21bf-4572-bd27-4b70e4a369e8",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F33e8c5e2-21bf-4572-bd27-4b70e4a369e8.docx?alt=media&token=8965eb56-e8df-4076-b9cc-1538acfb60af",
        "fileExt": ".docx",
        "fileName": "2.7 Personal Care Dressing Assistance.docx"
      },
      {
        "name": "2.8 Personal Care  Ted Hose Compression Stocking",
        "type": "POLICY", isBright: true,
        "id": "230d6f42-7ba7-477a-ac74-d92775ea94ec",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F230d6f42-7ba7-477a-ac74-d92775ea94ec.docx?alt=media&token=7590f65e-1d94-49ef-83dd-bd3c974ba934",
        "fileExt": ".docx",
        "fileName": "2.8 Personal Care  Ted Hose Compression Stocking.docx"
      },
      {
        "name": "2.9 Personal Care Oral Care Assistance",
        "type": "POLICY", isBright: true,
        "id": "597239a6-14e0-460b-9de7-49436971e5ba",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F597239a6-14e0-460b-9de7-49436971e5ba.docx?alt=media&token=2d46acdb-c626-498a-b470-56a8878ac915",
        "fileExt": ".docx",
        "fileName": "2.9 Personal Care Oral Care Assistance.docx"
      }
    ]
  },
  {
    "name": "Chapter 3 Vital Signs",
    "type": "dir",
    "id": "238dfcd8-2003-42a8-97fd-699c1d399511",
    "path": "Competencies/Chapter 3 Vital Signs",
    "items": [
      {
        "name": "3.1 Vitals  Digital Blood Pressure Pulse Monitor",
        "type": "POLICY", isBright: true,
        "id": "8bcfbba0-42d9-4294-b425-095c79e3b540",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8bcfbba0-42d9-4294-b425-095c79e3b540.docx?alt=media&token=d35e28f0-d44b-46c2-91a5-84796f6d885e",
        "fileExt": ".docx",
        "fileName": "3.1 Vitals  Digital Blood Pressure Pulse Monitor.docx"
      },
      {
        "name": "3.2 Vitals Automatic Blood Pressure Monitors",
        "type": "POLICY", isBright: true,
        "id": "276b1125-da06-443c-800c-bf5e3b3a8a1f",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F276b1125-da06-443c-800c-bf5e3b3a8a1f.docx?alt=media&token=4b8021da-883d-4f86-a709-7e4093682519",
        "fileExt": ".docx",
        "fileName": "3.2 Vitals Automatic Blood Pressure Monitors.docx"
      },
      {
        "name": "3.3 Vitals  Manual Blood Pressure Monitoring",
        "type": "POLICY", isBright: true,
        "id": "f8404693-3cd3-4663-9a00-fbc93906c649",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff8404693-3cd3-4663-9a00-fbc93906c649.docx?alt=media&token=7de2a18a-38d7-440b-af7a-09760816870f",
        "fileExt": ".docx",
        "fileName": "3.3 Vitals  Manual Blood Pressure Monitoring.docx"
      },
      {
        "name": "3.4 Vitals Pulse amd Respirations",
        "type": "POLICY", isBright: true,
        "id": "f51bae28-5ca4-4cbe-b259-0bf33bcf6f1c",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff51bae28-5ca4-4cbe-b259-0bf33bcf6f1c.docx?alt=media&token=4a4e0dc3-c468-41f5-861e-bdee9a40aed8",
        "fileExt": ".docx",
        "fileName": "3.4 Vitals Pulse amd Respirations.docx"
      },
      {
        "name": "3.5 Vitals Oral Temperature",
        "type": "POLICY", isBright: true,
        "id": "7887afac-ff76-419a-a0c5-ab969e575a66",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7887afac-ff76-419a-a0c5-ab969e575a66.docx?alt=media&token=307c101a-d721-4344-93b0-f1e6911fb2b6",
        "fileExt": ".docx",
        "fileName": "3.5 Vitals Oral Temperature.docx"
      },
      {
        "name": "3.6 Vitals Temporal Temperature",
        "type": "POLICY", isBright: true,
        "id": "3990488a-3aeb-4b7e-8bd7-8d96bfdd190b",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3990488a-3aeb-4b7e-8bd7-8d96bfdd190b.docx?alt=media&token=c44e9211-483d-47e6-9248-f1dc931078e9",
        "fileExt": ".docx",
        "fileName": "3.6 Vitals Temporal Temperature.docx"
      },
      {
        "name": "3.7 Vitals Tympanic Temperature",
        "type": "POLICY", isBright: true,
        "id": "271e957b-2c59-4898-ad1a-734838039e64",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F271e957b-2c59-4898-ad1a-734838039e64.docx?alt=media&token=7ec1d5b0-0b17-4164-b663-df43fe742c66",
        "fileExt": ".docx",
        "fileName": "3.7 Vitals Tympanic Temperature.docx"
      },
      {
        "name": "3.8 Vitals Weight",
        "type": "POLICY", isBright: true,
        "id": "5eaa9139-e174-416c-a355-176b4550f355",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F5eaa9139-e174-416c-a355-176b4550f355.docx?alt=media&token=88b1eb14-2065-48cb-8e9f-393ea5ce795f",
        "fileExt": ".docx",
        "fileName": "3.8 Vitals Weight.docx"
      }
    ]
  },
  {
    "name": "Chapter 4 Client Mobility",
    "type": "dir",
    "id": "aed02dd6-ca9b-4ae7-9d0b-87a9ecfbaebd",
    "path": "Competencies/Chapter 4 Client Mobility",
    "items": [
      {
        "name": "4.1 Client Mobility Assistive Devices",
        "type": "POLICY", isBright: true,
        "id": "c717cdc0-660d-469f-8bc8-275e84b7b439",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc717cdc0-660d-469f-8bc8-275e84b7b439.docx?alt=media&token=d972e5b4-0903-4b13-8046-975b7be12192",
        "fileExt": ".docx",
        "fileName": "4.1 Client Mobility Assistive Devices.docx"
      },
      {
        "name": "4.10 Client Mobility Tilt Chair",
        "type": "POLICY", isBright: true,
        "id": "012d420e-3064-42d8-ad34-423b8dd87a25",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F012d420e-3064-42d8-ad34-423b8dd87a25.docx?alt=media&token=2da1ce24-eba5-4227-b696-ca1fbbc93f74",
        "fileExt": ".docx",
        "fileName": "4.10 Client Mobility Tilt Chair.docx"
      },
      {
        "name": "4.11 Sara Steady Mechanical Lift",
        "type": "POLICY", isBright: true,
        "id": "afbf51d3-2ea5-45e9-b807-6b18b2ba07f9",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fafbf51d3-2ea5-45e9-b807-6b18b2ba07f9.docx?alt=media&token=152a6047-6ca6-4ee7-b316-35e478f1d377",
        "fileExt": ".docx",
        "fileName": "4.11 Sara Steady Mechanical Lift.docx"
      },
      {
        "name": "4.2 Client Mobility Transfer Ambulation Teqniques",
        "type": "POLICY", isBright: true,
        "id": "8e6ff685-c7ab-4122-8cd5-6588dbbad975",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8e6ff685-c7ab-4122-8cd5-6588dbbad975.docx?alt=media&token=85134cb1-a825-4535-8f10-98d102f258d7",
        "fileExt": ".docx",
        "fileName": "4.2 Client Mobility Transfer Ambulation Teqniques.docx"
      },
      {
        "name": "4.3 Client mobility ROM and Positioning",
        "type": "POLICY", isBright: true,
        "id": "20c2bc39-7d3c-4acf-8adf-11a140e5ca33",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F20c2bc39-7d3c-4acf-8adf-11a140e5ca33.docx?alt=media&token=ecc2b363-83bb-4334-a949-c54d1d993627",
        "fileExt": ".docx",
        "fileName": "4.3 Client mobility ROM and Positioning.docx"
      },
      {
        "name": "4.4 Client Mobility Mechanical Lifts",
        "type": "POLICY", isBright: true,
        "id": "09ff8f66-5d4b-45d3-8759-2562a700a1c1",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F09ff8f66-5d4b-45d3-8759-2562a700a1c1.docx?alt=media&token=120c1140-d43b-48fb-8f30-c59422ec2fa1",
        "fileExt": ".docx",
        "fileName": "4.4 Client Mobility Mechanical Lifts.docx"
      },
      {
        "name": "4.5 Client Mobility Lifting and Safe Transfers",
        "type": "POLICY", isBright: true,
        "id": "477fd210-1d0d-43f9-afde-46a846bdc03c",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F477fd210-1d0d-43f9-afde-46a846bdc03c.docx?alt=media&token=e502c813-0358-4410-ba3f-678c7f20dc0c",
        "fileExt": ".docx",
        "fileName": "4.5 Client Mobility Lifting and Safe Transfers.docx"
      },
      {
        "name": "4.6 Client Mobility Transfer Pivot Disc",
        "type": "POLICY", isBright: true,
        "id": "ca30d2c6-82e4-4f1b-8b31-b26031621de9",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fca30d2c6-82e4-4f1b-8b31-b26031621de9.docx?alt=media&token=aa50178a-630c-4e6f-9ebf-a34341d6b632",
        "fileExt": ".docx",
        "fileName": "4.6 Client Mobility Transfer Pivot Disc.docx"
      },
      {
        "name": "4.7 Client Mobility Hospital Bed operation",
        "type": "POLICY", isBright: true,
        "id": "a543a963-7b0c-45e2-b56b-4a30609418c1",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa543a963-7b0c-45e2-b56b-4a30609418c1.docx?alt=media&token=b41f5258-e722-4530-a016-0c2bb0afd7a7",
        "fileExt": ".docx",
        "fileName": "4.7 Client Mobility Hospital Bed operation.docx"
      },
      {
        "name": "4.8 Client Mobility Broda Chair",
        "type": "POLICY", isBright: true,
        "id": "f9020388-bf21-480b-996c-4c4938772a64",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff9020388-bf21-480b-996c-4c4938772a64.docx?alt=media&token=68c2c865-21ca-4d47-a3ef-b77d54f6ebbb",
        "fileExt": ".docx",
        "fileName": "4.8 Client Mobility Broda Chair.docx"
      },
      {
        "name": "4.9 Client Mobility Floor mat",
        "type": "POLICY", isBright: true,
        "id": "454353f7-2f21-4262-9426-65d96c26097f",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F454353f7-2f21-4262-9426-65d96c26097f.docx?alt=media&token=8ed7f7df-902a-4378-b447-ad7f9e5669d1",
        "fileExt": ".docx",
        "fileName": "4.9 Client Mobility Floor mat.docx"
      }
    ]
  },
  {
    "name": "Chapter 5 Infection Control",
    "type": "dir",
    "id": "3b7f05ce-a919-488c-bd6e-43cf8943f8f8",
    "path": "Competencies/Chapter 5 Infection Control",
    "items": [
      {
        "name": "5.1 Infection Control Standard Precautions and Handwashing",
        "type": "POLICY", isBright: true,
        "id": "3667ebea-48c5-491c-ac44-74bd8305251c",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3667ebea-48c5-491c-ac44-74bd8305251c.docx?alt=media&token=d3fa4458-4fe5-448d-b757-d67af083dc2f",
        "fileExt": ".docx",
        "fileName": "5.1 Infection Control Standard Precautions and Handwashing.docx"
      },
      {
        "name": "5.2 Infection Control Glove Use",
        "type": "POLICY", isBright: true,
        "id": "d5407510-397c-425f-9f9d-2dbd1b124006",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd5407510-397c-425f-9f9d-2dbd1b124006.docx?alt=media&token=1bb11239-c5e5-47ed-9127-9e5fbf7ea1df",
        "fileExt": ".docx",
        "fileName": "5.2 Infection Control Glove Use.docx"
      },
      {
        "name": "5.3 Infection Control Gown Use",
        "type": "POLICY", isBright: true,
        "id": "e8bbb4fa-b0f9-4940-8bf6-fbff67d6af89",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe8bbb4fa-b0f9-4940-8bf6-fbff67d6af89.docx?alt=media&token=8b591762-ad5b-47e1-8053-1daf25285128",
        "fileExt": ".docx",
        "fileName": "5.3 Infection Control Gown Use.docx"
      },
      {
        "name": "5.4 Infection Control Mask and Eye Protection",
        "type": "POLICY", isBright: true,
        "id": "eb1dd74d-d0f8-482b-85d3-5e3b9b5b84e2",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Feb1dd74d-d0f8-482b-85d3-5e3b9b5b84e2.docx?alt=media&token=d8f8c597-2880-4191-92e9-2066b0899fc2",
        "fileExt": ".docx",
        "fileName": "5.4 Infection Control Mask and Eye Protection.docx"
      },
      {
        "name": "5.5 Infection Control Donning and Doffing PPE Sequence",
        "type": "POLICY", isBright: true,
        "id": "e5fa4f51-7443-40be-9e1c-33f3df272d4e",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe5fa4f51-7443-40be-9e1c-33f3df272d4e.docx?alt=media&token=dad38ab4-b746-417e-81da-51ce74be1e4c",
        "fileExt": ".docx",
        "fileName": "5.5 Infection Control Donning and Doffing PPE Sequence.docx"
      },
      {
        "name": "PPE Sequence Poster",
        "type": "POLICY", isBright: true,
        "id": "9026b9c3-7a94-4fdf-8859-4d6c66c514cd",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9026b9c3-7a94-4fdf-8859-4d6c66c514cd.pdf?alt=media&token=a1003555-af09-494b-9385-1d5f01cdea7c",
        "fileExt": ".pdf",
        "fileName": "PPE Sequence Poster.pdf"
      }
    ]
  },
  {
    "name": "Chapter 6 Dining",
    "type": "dir",
    "id": "d33ef2a2-84cf-428a-a605-4decbaba7b9d",
    "path": "Competencies/Chapter 6 Dining",
    "items": [
      {
        "name": "6.1 Dining Intake and Output Tracking",
        "type": "POLICY", isBright: true,
        "id": "138e5152-2127-41e8-8ce2-748d95962b6e",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F138e5152-2127-41e8-8ce2-748d95962b6e.docx?alt=media&token=76656762-34c1-4cbe-8c67-664283f66457",
        "fileExt": ".docx",
        "fileName": "6.1 Dining Intake and Output Tracking.docx"
      },
      {
        "name": "6.2 Dining Eating Assistacnce",
        "type": "POLICY", isBright: true,
        "id": "1730a6ee-b203-474a-858e-ed560dd3a40f",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1730a6ee-b203-474a-858e-ed560dd3a40f.docx?alt=media&token=428c4b35-4ca2-4f70-8ed2-91c317783279",
        "fileExt": ".docx",
        "fileName": "6.2 Dining Eating Assistacnce.docx"
      },
      {
        "name": "6.3 Dining  Basic Nutrition and Food Safety",
        "type": "POLICY", isBright: true,
        "id": "5aa31f11-f9d9-4a29-8b30-cb13a9b56248",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F5aa31f11-f9d9-4a29-8b30-cb13a9b56248.docx?alt=media&token=d870178b-c9c0-4629-af19-2519a2955365",
        "fileExt": ".docx",
        "fileName": "6.3 Dining  Basic Nutrition and Food Safety.docx"
      },
      {
        "name": "6.4 Dining Modified Diets and Thickened Liquids",
        "type": "POLICY", isBright: true,
        "id": "1db0769a-9e01-4190-90d9-3539956f9d0f",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1db0769a-9e01-4190-90d9-3539956f9d0f.docx?alt=media&token=c1344601-f4a1-4271-9edd-e109c8d01acf",
        "fileExt": ".docx",
        "fileName": "6.4 Dining Modified Diets and Thickened Liquids.docx"
      }
    ]
  },
  {
    "name": "Chapter 7 Treatments and Other",
    "type": "dir",
    "id": "f029e312-f880-4284-ba62-c1948dc9cd89",
    "path": "Competencies/Chapter 7 Treatments and Other",
    "items": [
      {
        "name": "7.1 Treatments and Other Oxygen Use and Saturation Monitoring",
        "type": "POLICY", isBright: true,
        "id": "3b937f59-e722-4ec2-80ac-8c8b0882cc1e",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F3b937f59-e722-4ec2-80ac-8c8b0882cc1e.docx?alt=media&token=d4da3d96-9707-4d94-b90b-4d7e63e7d460",
        "fileExt": ".docx",
        "fileName": "7.1 Treatments and Other Oxygen Use and Saturation Monitoring.docx"
      },
      {
        "name": "7.15 Treatments and Other Devibliss iFill Oxygen System",
        "type": "POLICY", isBright: true,
        "id": "ed945d0a-43a1-4776-8818-02503a6e72ee",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fed945d0a-43a1-4776-8818-02503a6e72ee.docx?alt=media&token=85505427-d0ed-44df-92aa-0071c26b9b9b",
        "fileExt": ".docx",
        "fileName": "7.15 Treatments and Other Devibliss iFill Oxygen System.docx"
      },
      {
        "name": "7.2 Treatments and Other  CPAP Care",
        "type": "POLICY", isBright: true,
        "id": "ae3c2d5f-2263-40bf-a3f1-edb9b54d9b1f",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fae3c2d5f-2263-40bf-a3f1-edb9b54d9b1f.docx?alt=media&token=738bba28-5dcc-46d4-b081-43b62515ddaf",
        "fileExt": ".docx",
        "fileName": "7.2 Treatments and Other  CPAP Care.docx"
      },
      {
        "name": "7.3 Treatments and Other  Peak Flow Meter",
        "type": "POLICY", isBright: true,
        "id": "f3023261-4d91-4425-b25a-5783f707a813",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff3023261-4d91-4425-b25a-5783f707a813.docx?alt=media&token=cede8aa1-1708-4f11-acbc-e420b1048d70",
        "fileExt": ".docx",
        "fileName": "7.3 Treatments and Other  Peak Flow Meter.docx"
      },
      {
        "name": "7.4 Treatments and Other  Feeding Tubes",
        "type": "POLICY", isBright: true,
        "id": "e312b238-115a-4ffe-9de5-cb90a2f77fa5",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe312b238-115a-4ffe-9de5-cb90a2f77fa5.docx?alt=media&token=41f0ba7e-3bee-46e1-85b3-66d2a7ca5251",
        "fileExt": ".docx",
        "fileName": "7.4 Treatments and Other  Feeding Tubes.docx"
      },
      {
        "name": "7.5 Treatments and Other  Wound Care and Dressing Changes",
        "type": "POLICY", isBright: true,
        "id": "ec92606b-d141-4157-bb6f-f74956b357a5",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fec92606b-d141-4157-bb6f-f74956b357a5.docx?alt=media&token=4083fcc3-161e-4218-b1e8-08f157a56420",
        "fileExt": ".docx",
        "fileName": "7.5 Treatments and Other  Wound Care and Dressing Changes.docx"
      },
      {
        "name": "7.6 Treatements and Other Colostomy and Urostomy Care",
        "type": "POLICY", isBright: true,
        "id": "7f58f890-4a9c-40c5-ace3-d8ca78737bbf",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7f58f890-4a9c-40c5-ace3-d8ca78737bbf.docx?alt=media&token=4401c13a-7a0b-410b-8111-8c74d0e91c89",
        "fileExt": ".docx",
        "fileName": "7.6 Treatements and Other Colostomy and Urostomy Care.docx"
      }
    ]
  },
  {
    "name": "Chapter 8 Medication Training",
    "type": "dir",
    "id": "cffeccd2-fb5a-4c8c-b090-12530d4f221a",
    "path": "Competencies/Chapter 8 Medication Training",
    "items": [
      {
        "name": "6 Rights of Medication Administration Sheet",
        "type": "POLICY", isBright: true,
        "id": "546674b0-0109-43f3-8df1-daa83fff92ea",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F546674b0-0109-43f3-8df1-daa83fff92ea.docx?alt=media&token=29e1797c-a0ca-427a-9685-168ba0675c85",
        "fileExt": ".docx",
        "fileName": "6 Rights of Medication Administration Sheet.docx"
      },
      {
        "name": "8.1 Medication Training Introduction",
        "type": "POLICY", isBright: true,
        "id": "6402bee0-c520-4538-a094-88d1475cc096",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F6402bee0-c520-4538-a094-88d1475cc096.docx?alt=media&token=4bed8145-b96d-4dfa-83f5-d929f0ac8d17",
        "fileExt": ".docx",
        "fileName": "8.1 Medication Training Introduction.docx"
      },
      {
        "name": "8.10 Medication Training  Rights of Medication Administration",
        "type": "POLICY", isBright: true,
        "id": "f40c1ec7-094a-485f-b762-11925875f4ff",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ff40c1ec7-094a-485f-b762-11925875f4ff.docx?alt=media&token=0f3e7c66-48a2-47f7-81fe-1d2bbaf20955",
        "fileExt": ".docx",
        "fileName": "8.10 Medication Training  Rights of Medication Administration.docx"
      },
      {
        "name": "8.11 RTasks EMAR Use ",
        "type": "POLICY", isBright: true,
        "id": "951d554d-8138-4d88-a702-d4a72f9429a9",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F951d554d-8138-4d88-a702-d4a72f9429a9.docx?alt=media&token=27d1b02a-6b7f-4c18-8a96-92a1a7ee303c",
        "fileExt": ".docx",
        "fileName": "8.11 RTasks EMAR Use .docx"
      },
      {
        "name": "8.2 Medication Training Delegation",
        "type": "POLICY", isBright: true,
        "id": "a4b90d44-b0e7-487e-9c32-252c0f9d85cf",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa4b90d44-b0e7-487e-9c32-252c0f9d85cf.docx?alt=media&token=3d405ab4-2637-4f31-8fe1-ff1a503d3a0a",
        "fileExt": ".docx",
        "fileName": "8.2 Medication Training Delegation.docx"
      },
      {
        "name": "8.3 Medication Training Communication",
        "type": "POLICY", isBright: true,
        "id": "9b79fd5b-b820-4ef1-bcfe-5083257ad918",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F9b79fd5b-b820-4ef1-bcfe-5083257ad918.docx?alt=media&token=c0922014-8f39-49a3-9de6-13174972ae0f",
        "fileExt": ".docx",
        "fileName": "8.3 Medication Training Communication.docx"
      },
      {
        "name": "8.4 Medication Training  Effects and Purposes of Medication",
        "type": "POLICY", isBright: true,
        "id": "31743883-ad8d-4b9f-bdf0-23df4f2fe1b1",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F31743883-ad8d-4b9f-bdf0-23df4f2fe1b1.docx?alt=media&token=ccbe11a9-690c-422e-a414-710b970ded6f",
        "fileExt": ".docx",
        "fileName": "8.4 Medication Training  Effects and Purposes of Medication.docx"
      },
      {
        "name": "8.5 Medication Training Common Classifications of Medications",
        "type": "POLICY", isBright: true,
        "id": "a708750f-9164-437f-9d55-0b911609bb9d",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fa708750f-9164-437f-9d55-0b911609bb9d.docx?alt=media&token=85d8acad-1fbb-4739-9d28-2c5ae569be18",
        "fileExt": ".docx",
        "fileName": "8.5 Medication Training Common Classifications of Medications.docx"
      },
      {
        "name": "8.6 Medication Training Generic and Trade Names of Medications",
        "type": "POLICY", isBright: true,
        "id": "7e2ed6b6-b980-4f26-b843-4bf8d9762167",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7e2ed6b6-b980-4f26-b843-4bf8d9762167.docx?alt=media&token=d30c4eee-0997-44a6-a973-aa7e4ad98ec8",
        "fileExt": ".docx",
        "fileName": "8.6 Medication Training Generic and Trade Names of Medications.docx"
      },
      {
        "name": "8.7 Medication Training Routes of Medication Adminstration",
        "type": "POLICY", isBright: true,
        "id": "da927cbe-36ea-4215-9f67-637fe5750f96",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fda927cbe-36ea-4215-9f67-637fe5750f96.docx?alt=media&token=cb1aa862-1d2e-4b43-a88e-864558157f83",
        "fileExt": ".docx",
        "fileName": "8.7 Medication Training Routes of Medication Adminstration.docx"
      },
      {
        "name": "8.8 Medication Training Controlled Medications",
        "type": "POLICY", isBright: true,
        "id": "8bf90820-8ebc-43e7-8144-dc7a73fcdd3a",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F8bf90820-8ebc-43e7-8144-dc7a73fcdd3a.docx?alt=media&token=4656320e-0722-4f46-88dd-79da13fa20e2",
        "fileExt": ".docx",
        "fileName": "8.8 Medication Training Controlled Medications.docx"
      },
      {
        "name": "8.9 Medication Training Medication errors",
        "type": "POLICY", isBright: true,
        "id": "efa12676-ef1e-495b-aae4-4caa4625d956",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fefa12676-ef1e-495b-aae4-4caa4625d956.docx?alt=media&token=2d5e4641-b9a6-421a-a08c-f856be11ec62",
        "fileExt": ".docx",
        "fileName": "8.9 Medication Training Medication errors.docx"
      }
    ]
  },
  {
    "name": "Chapter 9 Medication Competency",
    "type": "dir",
    "id": "a4b1fb0a-129f-4149-90b5-717937b07bbc",
    "path": "Competencies/Chapter 9 Medication Competency",
    "items": [
      {
        "name": "9.1 Medication Comp  Oral Bubble Pack",
        "type": "POLICY", isBright: true,
        "id": "dbd7b73c-d502-4210-9055-012399af1846",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fdbd7b73c-d502-4210-9055-012399af1846.docx?alt=media&token=f7e65af9-2810-4fca-88d3-e94dbbf08191",
        "fileExt": ".docx",
        "fileName": "9.1 Medication Comp  Oral Bubble Pack.docx"
      },
      {
        "name": "9.10 Medication Comp Dry Powder Inhaler",
        "type": "POLICY", isBright: true,
        "id": "4d24d953-5f01-4a09-a0a4-1a0c4ac17780",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F4d24d953-5f01-4a09-a0a4-1a0c4ac17780.docx?alt=media&token=6c9c50df-26bc-4343-a93b-687bc44a7a3d",
        "fileExt": ".docx",
        "fileName": "9.10 Medication Comp Dry Powder Inhaler.docx"
      },
      {
        "name": "9.11 Medication Comp Nasal Spray",
        "type": "POLICY", isBright: true,
        "id": "36ab17ae-1b1f-40f4-a04e-672e0f7fdb89",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F36ab17ae-1b1f-40f4-a04e-672e0f7fdb89.docx?alt=media&token=ee26f2df-a470-4991-8600-74cd46e2c9e3",
        "fileExt": ".docx",
        "fileName": "9.11 Medication Comp Nasal Spray.docx"
      },
      {
        "name": "9.12  Medication Comp Eye Drops Ointment",
        "type": "POLICY", isBright: true,
        "id": "13584041-00ec-4fe6-83af-033fb1f5caeb",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F13584041-00ec-4fe6-83af-033fb1f5caeb.docx?alt=media&token=71d15d4a-54da-4fa5-a493-603b8d4af2ce",
        "fileExt": ".docx",
        "fileName": "9.12  Medication Comp Eye Drops Ointment.docx"
      },
      {
        "name": "9.13 Medication Comp Ear Drops",
        "type": "POLICY", isBright: true,
        "id": "111365b8-ff92-4f9f-b11a-8ed2b36cc1fb",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F111365b8-ff92-4f9f-b11a-8ed2b36cc1fb.docx?alt=media&token=75012df6-658a-49dc-9de3-059dc7058f8e",
        "fileExt": ".docx",
        "fileName": "9.13 Medication Comp Ear Drops.docx"
      },
      {
        "name": "9.14 Medication Comp Rectal Medication",
        "type": "POLICY", isBright: true,
        "id": "64b0dc19-91bb-4cc0-8e3f-fac7b0b2ceda",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F64b0dc19-91bb-4cc0-8e3f-fac7b0b2ceda.docx?alt=media&token=0e750aaa-6f8d-4260-9b3c-e7c9bb1a72e9",
        "fileExt": ".docx",
        "fileName": "9.14 Medication Comp Rectal Medication.docx"
      },
      {
        "name": "9.15  Medication Comp  Topical Medication",
        "type": "POLICY", isBright: true,
        "id": "fb08d43f-18bd-47ee-acab-acc038bf6e71",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ffb08d43f-18bd-47ee-acab-acc038bf6e71.docx?alt=media&token=8389a15e-fb8e-44a6-8c85-75963d3165e7",
        "fileExt": ".docx",
        "fileName": "9.15  Medication Comp  Topical Medication.docx"
      },
      {
        "name": "9.16 Medication Comp Topical Patches",
        "type": "POLICY", isBright: true,
        "id": "97011bf5-51ac-44c3-974d-5d61e2da58d1",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F97011bf5-51ac-44c3-974d-5d61e2da58d1.docx?alt=media&token=0461bfd8-5fd1-41fe-85c7-bb8614972024",
        "fileExt": ".docx",
        "fileName": "9.16 Medication Comp Topical Patches.docx"
      },
      {
        "name": "9.17 Medication Comp Reminders",
        "type": "POLICY", isBright: true,
        "id": "1fba6484-1c81-44c7-8ac4-5b0e04328cb9",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F1fba6484-1c81-44c7-8ac4-5b0e04328cb9.docx?alt=media&token=1efd4d93-87b9-4be5-b01f-1dc274944e84",
        "fileExt": ".docx",
        "fileName": "9.17 Medication Comp Reminders.docx"
      },
      {
        "name": "9.18 Medication Comp Planned LOA",
        "type": "POLICY", isBright: true,
        "id": "6653845a-6c70-4fea-bb09-23c9155b892e",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F6653845a-6c70-4fea-bb09-23c9155b892e.docx?alt=media&token=dd3fe255-e878-4b44-ac5f-bffa176e6923",
        "fileExt": ".docx",
        "fileName": "9.18 Medication Comp Planned LOA.docx"
      },
      {
        "name": "9.19 Medication Comp G Tube",
        "type": "POLICY", isBright: true,
        "id": "d8d359bb-1163-4e2c-a3e8-07c68796ebf6",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd8d359bb-1163-4e2c-a3e8-07c68796ebf6.docx?alt=media&token=a7138bfb-8d8a-4a2f-a478-862276f0d466",
        "fileExt": ".docx",
        "fileName": "9.19 Medication Comp G Tube.docx"
      },
      {
        "name": "9.2 Medication Comp  Oral Med Strip",
        "type": "POLICY", isBright: true,
        "id": "93d239b0-973c-4b72-8d70-159c824d87ea",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F93d239b0-973c-4b72-8d70-159c824d87ea.docx?alt=media&token=4b8c6277-578e-4759-9423-2c883008ed69",
        "fileExt": ".docx",
        "fileName": "9.2 Medication Comp  Oral Med Strip.docx"
      },
      {
        "name": "9.20 Medication Comp PRN Medications",
        "type": "POLICY", isBright: true,
        "id": "c65a0ee1-49a2-4cfc-8b94-0b213a937172",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc65a0ee1-49a2-4cfc-8b94-0b213a937172.docx?alt=media&token=aa5bf15d-2166-4b40-8416-010ddf60132f",
        "fileExt": ".docx",
        "fileName": "9.20 Medication Comp PRN Medications.docx"
      },
      {
        "name": "9.3 Medication Comp  Oral Bottle",
        "type": "POLICY", isBright: true,
        "id": "44b83d8b-f90a-4c39-9604-3264bb87cdfd",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F44b83d8b-f90a-4c39-9604-3264bb87cdfd.docx?alt=media&token=8967bdc0-e164-4c81-b2f7-d5467b2e3d73",
        "fileExt": ".docx",
        "fileName": "9.3 Medication Comp  Oral Bottle.docx"
      },
      {
        "name": "9.4 Medication Comp Sublingual",
        "type": "POLICY", isBright: true,
        "id": "730ea554-a133-4632-ad43-7a2f78c0c2e3",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F730ea554-a133-4632-ad43-7a2f78c0c2e3.docx?alt=media&token=e3158c3e-057c-46ff-b7f5-9ac6682fc382",
        "fileExt": ".docx",
        "fileName": "9.4 Medication Comp Sublingual.docx"
      },
      {
        "name": "9.5 Medication Comp Buccal",
        "type": "POLICY", isBright: true,
        "id": "e0db05f9-1e89-4b5c-bebc-d55125c6d3b3",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fe0db05f9-1e89-4b5c-bebc-d55125c6d3b3.docx?alt=media&token=c43b7026-2146-4eb8-9539-941de8e31e52",
        "fileExt": ".docx",
        "fileName": "9.5 Medication Comp Buccal.docx"
      },
      {
        "name": "9.6 Medication Comp Narcotics",
        "type": "POLICY", isBright: true,
        "id": "11f2d33a-fcc4-4486-9d17-161ec27bb31a",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F11f2d33a-fcc4-4486-9d17-161ec27bb31a.docx?alt=media&token=3ec6a4f0-1909-4ee6-a464-2b12410a0fb4",
        "fileExt": ".docx",
        "fileName": "9.6 Medication Comp Narcotics.docx"
      },
      {
        "name": "9.7 Medication Comp Liquid Medication",
        "type": "POLICY", isBright: true,
        "id": "756a8cfb-03ea-4244-bdce-060c8f42537a",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F756a8cfb-03ea-4244-bdce-060c8f42537a.docx?alt=media&token=37b2deb0-4c44-44af-a8a6-08378a34db78",
        "fileExt": ".docx",
        "fileName": "9.7 Medication Comp Liquid Medication.docx"
      },
      {
        "name": "9.8 Medication Comp Nebulizer",
        "type": "POLICY", isBright: true,
        "id": "de1a053b-7eec-40e9-8d79-b7636217ab09",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fde1a053b-7eec-40e9-8d79-b7636217ab09.docx?alt=media&token=acf53000-b08d-4470-a18e-5d11093cf662",
        "fileExt": ".docx",
        "fileName": "9.8 Medication Comp Nebulizer.docx"
      },
      {
        "name": "9.9 Medication Comp Meter Dose Inhaler",
        "type": "POLICY", isBright: true,
        "id": "d75e1df8-f9a4-412b-9fd5-21375ecb7d1e",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fd75e1df8-f9a4-412b-9fd5-21375ecb7d1e.docx?alt=media&token=6c9d0ff5-f5d6-435d-99f6-4648d810656f",
        "fileExt": ".docx",
        "fileName": "9.9 Medication Comp Meter Dose Inhaler.docx"
      }
    ]
  },
  {
    "name": "Competency Checklist CNA",
    "id": "28785ddd-6cf5-4707-a63f-d632ccd7ca8b",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F28785ddd-6cf5-4707-a63f-d632ccd7ca8b.docx?alt=media&token=a55d616c-3e30-4610-905c-414efaf26de3",
    "fileExt": ".docx",
    "fileName": "Competency Checklist CNA.docx"
  },
  {
    "name": "Competency Checklist Master",
    "id": "fc4fe2f8-df23-422d-b796-b919291540db",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Ffc4fe2f8-df23-422d-b796-b919291540db.docx?alt=media&token=9fe10d7e-375d-488a-a455-d68ad324c3f3",
    "fileExt": ".docx",
    "fileName": "Competency Checklist Master.docx"
  },
  {
    "name": "Competency Checklist Non CNA",
    "id": "c079d904-6535-449a-9ce6-ab04bbbe8b29",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2Fc079d904-6535-449a-9ce6-ab04bbbe8b29.docx?alt=media&token=a9efb201-3aaa-4b2d-8df2-c6f40365398d",
    "fileExt": ".docx",
    "fileName": "Competency Checklist Non CNA.docx"
  },
  {
    "name": "Competency Checklist",
    "id": "7c8f174d-5780-4ca6-b9fd-f7073d6e357f",
    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F7c8f174d-5780-4ca6-b9fd-f7073d6e357f.docx?alt=media&token=2e89c544-edf8-4c81-b799-b4bfc4b9df7e",
    "fileExt": ".docx",
    "fileName": "Competency Checklist.docx"
  }
]} // Competencies

 /*
 {
            "name": "3.06 First Report of Injury",
            "type": "form",
            "id": "52b99f2c-6e2f-4678-b923-1bb206de8f6c",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Felysianseniorhomes%2Fbrightsources%2F52b99f2c-6e2f-4678-b923-1bb206de8f6c.pdf?alt=media&token=2e852af2-871c-4efb-b4dc-fa76b81c326d",
            "fileExt": ".pdf",
            "fileName": "3.06 First Report of Injury.pdf"
          },
 */

const All = [
  {...Competencies},
  {...EmergencyManual},  
  {...EmploymentDocs},
  {...Instructions},
  {...Policies},
  {...Regulations2}  
]


export default { Regulations, All };