import { injectReducer } from 'app/store/index';

// eslint-disable-next-line
const withReducer = (key, reducer) => (WrappedComponent) => {
  injectReducer(key, reducer);

  // eslint-disable-next-line
  return (props) => <WrappedComponent {...props} />;
};

export default withReducer;
