import { config } from "dotenv"; 
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
import { getFirestore } from 'firebase/firestore'

config();

/*
console.log(`Environment: ${process.env.REACT_APP_ENV}`);
console.log(`Project: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`);
console.log(`Measurment: ${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`);
*/

let secondInstance = initializeApp({
    apiKey:             process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain:         process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, 
    projectId:          process.env.REACT_APP_FIREBASE_PROJECT_ID, 
    storageBucket:      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, 
    messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, 
    appId:              process.env.REACT_APP_FIREBASE_APP_ID, 
    measurementId:      process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}, "Secondary")

export const firebase2 = secondInstance;
export const auth2 = getAuth(secondInstance);
export const storage2 = getStorage(secondInstance);
export const functions2 = getFunctions(secondInstance);
export const db2 = getDatabase(secondInstance);
export const firestore2 = getFirestore(secondInstance);

export default secondInstance;