
import parse from 'html-react-parser';
import OpenAI from 'openai';
import { config } from "dotenv";


import {
  encode,
  encodeChat,
  decode,
  isWithinTokenLimit,
  encodeGenerator,
  decodeGenerator,
  decodeAsyncGenerator,
} from 'gpt-tokenizer/model/gpt-4-32k-0314'    // https://github.com/niieani/gpt-tokenizer

// By default, importing from gpt-tokenizer uses cl100k_base encoding, used by gpt-3.5-turbo and gpt-4.

config();

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  organization: process.env.REACT_APP_OPENAI_ORG_ID,
  dangerouslyAllowBrowser: true
});



/*
   Reference
  -------------------------------

    This class is responsible for grabbing the vector and converting it's text into something that will render will in the UI.  
    By phase II, it should only aks AI to convert the text if it can't get the results from the  database to contineously spending tokens on the same "ask".
*/


class Reference {

  constructor(vector){
    this.vector = vector;
    this.id = vector.id;
    this.html = ''; // = vector.medata.text; // default but ugly    

    this.test();

    //this.__prepare_html();
  }

  async test() {
    
    const text = 'Hello, world!'
    const tokenLimit = 10

    // Encode text into tokens
    const tokens = encode(text)

    // Decode tokens back into text
    const decodedText = decode(tokens)

    // Check if text is within the token limit
    // returns false if the limit is exceeded, otherwise returns the actual number of tokens (truthy value)
    const withinTokenLimit = isWithinTokenLimit(text, tokenLimit)

    // Example chat:
    const chat = [
      { role: 'system', content: 'You are a helpful assistant.' },
      { role: 'assistant', content: 'gpt-tokenizer is awesome.' },
    ]

    // Encode chat into tokens
    const chatTokens = encodeChat(chat)

    // Check if chat is within the token limit
    const chatWithinTokenLimit = isWithinTokenLimit(chat, tokenLimit)

    // Encode text using generator
    for (const tokenChunk of encodeGenerator(text)) {
      console.log(tokenChunk)
    }

    // Decode tokens using generator
    for (const textChunk of decodeGenerator(tokens)) {
      console.log(textChunk)
    }

    // Decode tokens using async generator
    // (assuming `asyncTokens` is an AsyncIterableIterator<number>)
  }

  async getHtml() {
    try {
      let markup = parse(this.html);
      return markup;
    }
    catch(ex){
      console.warn(`Error in Reference.GetHTML: ${ex.message}.`)      
    }
    return null;
  }

  //
  // "Private" methods
  // 
  async __prepare_html() {

    let html = null;

    // get from db
    html = await this.__get_html_db();
    if(html != null){
      this.html = html;
      return;
    }

    // get from chat GPT
    html = await this.__get_html_chat();    
    this.html = html;
    this.__set_html_db();      
  }


  async __get_html_chat() {

    let sysPrompt = `You are a helpful AI agent working as a part of a larger multi-agent framework to process text for Q&A citations. The text presented to you will be a raw extract from a vector retrieval system. Your mission is to re-write this text in HTML so that it can be cleanly displayed in a website pop-up window. Insert line and paragraph breaks as well as headers and bold formatting where appropriate so that it will be easy to read and understand for a human reader when displayed in a browser.  Be sure to use inline CSS styles defined within a <style> block to dictate the visual appearance of the content in the <body> section.`;
    let userPrompt = '';

    // max length: 16385 

    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-3.5-turbo-16k",
        messages: [
            {role: "system", content: sysPrompt},
            {role: "user", content: userPrompt}
        ],
        temperature: .2,
        max_tokens: 500,
        top_p: 1
      });
  
      
      let result = completion.choices[0].message.content;
      return result;
    } 
    catch(ex) {
      console.log(ex.message);
    }
  }

  async __get_html_db() {
    // TODO
    /*
      This should probably save the HTML on Cloud Storage
      and add a reference to Firebase that includes the path to Cloud Storage
    */
    return null; 
  }

  async __set_html_db() {
    // TODO
  }
}

export default Reference;