import { useEffect, useRef, useState } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import Toolbar from './toolbar';
import LandingPage from './landing';
import DataModelContext from './context';
import PromptSettings from './PromptSettings';

import {getPrompts} from './data';


const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
}));

function Layout(props) {

  const routeParams = useParams();
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [mainSidebarOpen, setMainSidebarOpen] = useState(true);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [currentPrompt, setCurrentPrompt] = useState();
  const [prompts, setPrompts] = useState();
  const [showTestShell, setShowTestShell] = useState(false);

  useEffect(() => {
    //setRightSidebarOpen(Boolean(routeParams.id));
    if(prompts == null){
      getPrompts().then(prompts => {
        setPrompts(prompts);
      })
    }
  }, [routeParams, prompts]);
  
  let providerValues = ({prompts, setPrompts, currentPrompt, setCurrentPrompt, mainSidebarOpen, setMainSidebarOpen, rightSidebarOpen, setRightSidebarOpen, showTestShell, setShowTestShell})

  return (
    <DataModelContext.Provider value={providerValues}>
      <Root
          header={<Toolbar  />}
          content={<LandingPage />}
          rightSidebarContent={<PromptSettings />}
          rightSidebarOpen={rightSidebarOpen}
          rightSidebarOnClose={() => setRightSidebarOpen(false)}
          rightSidebarWidth={400}
          scroll={isMobile ? 'normal' : 'content'}
      />
    </DataModelContext.Provider>
);

}

export default Layout;