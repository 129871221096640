
import React, {useRef, useContext} from 'react';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { Box } from '@mui/system';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import ContactAvatar from './ContactAvatar';
import elapsed from './elapsedTime';
import clickTrackerHook from '../../../shared-components/click-tracker-hook';
import { useNavigate, useParams } from "react-router-dom";
import LumberjackContext from '../context'

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  '&.active': {
    backgroundColor: theme.palette.background.default,
  },
}));

function ContactListItem(props) {
  const { chat, contact } = props;
  const routeParams = useParams();
  const listItemRef = useRef();
  const navigate = useNavigate();
  const { setShowReferralsPanel } = useContext(LumberjackContext);

  let time = null;
  if(contact?.time != null){
    time = contact.time;
  }
  else if(contact?.createdOn != null){
    time = elapsed(contact.createdOn);
  }


  function getUrlSegment(){
    let pathname = window.location.pathname.toLowerCase();
    if(pathname.indexOf('clinical') >= 0){     
      return 'Clinical';
    }
    else if( pathname.indexOf('financial') >= 0){              
      return 'Financial';
    }
  }

  clickTrackerHook({
    onSingleClick: e => {
      navigate(`/Lumberjack/${getUrlSegment()}/${contact.id}`);
    },
    onDoubleClick: e => {        
      navigate(`/Lumberjack/${getUrlSegment()}/${contact.id}`);
      setShowReferralsPanel(false);
    },
    ref: listItemRef,
    latency: 200
  })
  
  return (
    <StyledListItem
      button
      ref={listItemRef}
      className={`px-32 py-12 min-h-80 ${(routeParams.id === contact.id)? 'active': ''}`}
      active={routeParams.id === contact.id ? 1 : 0}      
      end="true"
      activeClassName="active"
    >
      <ContactAvatar contact={contact} />

      <ListItemText
        classes={{
          root: 'min-w-px px-16',
          primary: 'font-medium text-14',
          secondary: 'truncate',
        }}
        primary={contact?.PatientName}
        secondary={contact?.ProviderName }
      />

{/* format(new Date(contact.lastMessageAt), 'PP') */}
{/* elapsed(contact?.createdOn) */}

        <div className="flex flex-col justify-center items-end">
      
          <Typography
            className="whitespace-nowrap mt-8 font-medium text-12"
            color="text.secondary"
          >
            {time != null && time.line1}
          </Typography>

          <Typography
            className="whitespace-nowrap mb-8 font-medium text-12"
            color="text.secondary"
          >
            {time != null && time.line2}
          </Typography>
        

         {/*
          <div className="items-center">
            {contact.muted && (
              <FuseSvgIcon size={20} color="disabled">
                heroicons-solid:volume-off
              </FuseSvgIcon>
            )}
            {Boolean(contact.unreadCount) && (
              <Box
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'secondary.contrastText',
                }}
                className="flex items-center justify-center min-w-20 h-20 rounded-full font-medium text-10 text-center"
              >
                {contact.unreadCount}
              </Box>
            )}
          </div>
              */}
        </div>
    </StyledListItem>
  );
}

export default ContactListItem;
