import {Layout, Model, Actions, FlexLayout} from 'flexlayout-react';
import 'flexlayout-react/style/light.css';  
import 'flexlayout-react/style/underline.css';  
import './tabs.css';
import useChatContext from "../ChatContext";
import DirectoryTab from './DirectoryTab';
import ChatTab from './ChatTab';
import DocTab from './DocTab';
import { useRef, useState, useEffect } from 'react';

export const TAB_TYPE = {
  CONTENT: 'Content',
  DIR: "DIRECTORY",
  CHAT: "CHAT",
  FORM: "FORM",
  POLICY: "POLICY",
  REGULATION: "REG",
  DOC: "UNKNOWN",
}

function TabLayout() {

  const { chatSession } = useChatContext();
  const [question, setQuestion] = useState();
  let _layoutRef = useRef(null);

  if(chatSession == null){
    return (<div>{chatSession?.namespace}</div>)
  }

  if(chatSession.layoutRef == null )
    chatSession.layoutRef = _layoutRef;
  
  const openDocTab = (form, tabPrefix) => {
   
    // https://github.com/caplin/FlexLayout?tab=readme-ov-file#examples
    //let tabName = `${tabPrefix} ${(openFiles.length + 1)}`

    if(form?.type != null)
      tabPrefix = form.type; // tabPrefix was for backwards compatibility with v3
    

    let tabCount = '';
    tabPrefix = (form?.type != undefined)? form.type.toUpperCase(): tabPrefix;

    if(tabPrefix == TAB_TYPE.POLICY){
      tabCount = chatSession.tabs.policies.length + 1;
    } 
    else if(tabPrefix == TAB_TYPE.FORM) {
      tabCount = chatSession.tabs.forms.length + 1;
    }
    else if(tabPrefix == TAB_TYPE.REGULATION) {
      tabCount = chatSession.tabs.regulations.length + 1;
    }
    else if(tabPrefix == TAB_TYPE.DOC) {
      tabCount = chatSession.tabs.docs.length + 1;
    }

    let tabName = `${tabPrefix} ${tabCount}`
    let tabId = tabName.replace(' ', ''); 
    let tab = chatSession.layoutRef.current.addTabToTabSet("main", {type:"tab", component:TAB_TYPE.CONTENT, name: tabName, id: tabId, helpText: form.name, config: form});

    if(tabPrefix == TAB_TYPE.POLICY){
      chatSession.tabs.policies.push(tab);
    } 
    else if(tabPrefix == TAB_TYPE.FORM) {
      chatSession.tabs.forms.push(tab);
    }
    else if(tabPrefix == TAB_TYPE.REGULATION) {
      chatSession.tabs.regulations.push(tab);
    }
    else if(tabPrefix == TAB_TYPE.DOC) {
      chatSession.tabs.docs.push(tab);
    }
  }

  chatSession.on('onOpenDocTab', openDocTab);

  const onQuestion = (text) => {
    chatSession.model.doAction(Actions.selectTab(`chat-${chatSession?.namespace}`));
    setQuestion(text);
  }

  const Factory = (node) => {
    var component = node.getComponent();
    let name = node.getName();

    if (component === "button") {
      return <button>{node.getName()}</button>;
    }
    else if (component === TAB_TYPE.CONTENT) {
      if(name == TAB_TYPE.DIR)
        return <DirectoryTab onOpenDocTab={openDocTab} onQuestion={onQuestion}  />;
      else if(name == TAB_TYPE.CHAT)      
        return <ChatTab onQuestion={onQuestion} question={question} setQuestion={setQuestion} namespace={chatSession?.namespace} />;
      else if(name.indexOf(TAB_TYPE.FORM) > -1){
        return <DocTab form={node._attributes.config} />;
      } else if(name.indexOf(TAB_TYPE.POLICY) > -1){
        return <DocTab form={node._attributes.config} />;
      } else if(name.indexOf(TAB_TYPE.REGULATION) > -1){
        return <DocTab form={node._attributes.config} />;
      } else if(name.indexOf(TAB_TYPE.DOC) > -1){
        return <DocTab form={node._attributes.config} />;
      }
    }
  }

  // 
  return (<Layout  
    ref={chatSession.layoutRef}
    model={chatSession.model}  
    factory={Factory}
  ></Layout>)
}



export default TabLayout;