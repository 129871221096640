import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ConfirmedEmailPage = lazy(() => import('./ConfirmedEmailPage'));
const TestPage = lazy(() => import('../auth-test/AuthTestPage'));

const ConirmedEmailRoutes = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'email-confirmed',
      element: <ConfirmedEmailPage />,
    },
    {
      path: '_test',
      element: <TestPage />,
    },
  ],
};

export default ConirmedEmailRoutes;
