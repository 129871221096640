import { TOOL_TYPES, CATEGORIES } from '../../configs/toolsConfig';
import { temperatureConfig } from './configs/temperature';
import { volumeConfig } from './configs/volume';
import { weightConfig } from './configs/weight';
import { lengthConfig } from './configs/length';
import UnitConverter from './components/UnitConverter';

const unitConverterConfig = {
  id: 'unit-converter',
  name: 'Unit Converter',
  description: 'Convert between different units of measurement',
  instructions: 'Enter the value to convert and the units to convert from and to.',
  type: TOOL_TYPES.CONVERTER,
  category: CATEGORIES.CONVERSION,
  conversionTypes: ['temperature', 'volume', 'weight', 'length'],
  configs: {
    temperature: temperatureConfig,
    volume: volumeConfig,
    weight: weightConfig,
    length: lengthConfig
  },
  parameters: {
    conversionType: {
      type: 'string',
      enum: ['temperature', 'volume', 'weight', 'length'],
      description: 'Type of conversion'
    },
    fromUnit: {
      type: 'string',
      description: 'Unit to convert from',
      dependsOn: {
        conversionType: true
      }
    },
    toUnit: {
      type: 'string',
      description: 'Unit to convert to',
      dependsOn: {
        conversionType: true
      }
    },
    value: {
      type: 'number',
      description: 'Value to convert'
    }
  }
};

export default unitConverterConfig;