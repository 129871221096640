import FusePageSimple from '@fuse/core/FusePageSimple';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import Toolbar from './toolbar';
import LandingPage from './landing';
import DataModelContext from './context';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Layout(props) {

  const routeParams = useParams();
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [mainSidebarOpen, setMainSidebarOpen] = useState(true);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [currentModel, setCurrentModel] = useState();

  useEffect(() => {
      setRightSidebarOpen(Boolean(routeParams.id));
  }, [routeParams]);

  // rightSidebarContent={<KeyDetailsSidebar />}
  
  let providerValues = ({mainSidebarOpen, setMainSidebarOpen, rightSidebarOpen, setRightSidebarOpen, currentModel, setCurrentModel})

  return (
    <DataModelContext.Provider value={providerValues}>
      <Root
          header={<Toolbar  />}
          content={<LandingPage />}
          
          rightSidebarOpen={rightSidebarOpen}
          rightSidebarOnClose={() => setRightSidebarOpen(false)}
          rightSidebarWidth={400}
          scroll={isMobile ? 'normal' : 'content'}
      />
    </DataModelContext.Provider>
);

}

export default Layout;