import { TOOL_TYPES, CATEGORIES } from '../../configs/toolsConfig';

const ppdConfig = {
  id: 'ppd-calculator',
  name: 'PPD Calculator',
  description: 'Calculate Hours Per Patient Day (PPD) for staffing metrics',
  instructions: 'Enter staffing hours and patient days. "Average census" is not as accurate as patient days.',
  type: TOOL_TYPES.CALCULATOR,
  category: CATEGORIES.STAFFING,
  parameters: {
    staffingHours: {
      type: 'number',
      description: 'Total staffing hours scheduled'
    },
    metricType: {
      type: 'string',
      enum: ['patientDays', 'averageCensus'],
      description: 'Type of patient metric to use',
      default: 'patientDays'
    },
    metricValue: {
      type: 'number',
      description: 'Value for the selected metric'
    }
  }
};


export default ppdConfig;
