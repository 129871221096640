import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

function Error404Page() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="w-full max-w-3xl text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
        >
           <img
            src="assets/images/dogfood.png"
            alt="We eat dog food"
            style={{height: '500px', margin: 'auto'}}
            
          />
        </motion.div>
       
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography
            variant="h1"
            className="mt-28 sm:mt-48 text-3xl md:text-6xl font-extrabold tracking-tight leading-tight md:leading-none text-center"
          >
            Ooops... That page doesn't exist !
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            className="mt-12 text-lg md:text-xl font-medium tracking-tight text-center"
          >
            While we're here, we want you to know we eat our own dog food.  
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            className="mt-12 text-lg md:text-xl font-medium tracking-tight text-center"
          >
            Said plainly, we use our  technology for all sorts of things like our internal Policies & Procedures, step-by-step guides, directories, FAQs, and so much more! 
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            className="mt-12 text-lg md:text-xl font-medium tracking-tight text-center"
          >            
            Our organization is getting brighter every day!
          </Typography>
        </motion.div>

        <Link className="block font-normal mt-48" to="/">
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
}

export default Error404Page;
