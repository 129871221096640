import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useAuthContext from 'src/app/AuthContext';
import Divider from '@mui/material/Divider';
import { LockReset } from '@mui/icons-material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';

function UserMenu(props) {
  const [userMenu, setUserMenu] = useState(null);  
  const { isAuthenticated, currentUser, signOut, sendPasswordReset } = useAuthContext();
  const dispatch = useDispatch();
 
  function passwordReset() {

    try {

      let email = currentUser?.email || currentUser?.Email;
      if(email == null) {
        console.log(`Email was null and can't be reset. Use phone?`);
        return;
      }

      sendPasswordReset(email);

      let message = `An email was sent to '${email}' to reset your password.`
      dispatch(showMessage({ message, 
        autoHideDuration: 2500,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        }}
      ));

      
    }
    catch(ex){
      console.log(`An error occured on passwordReset_click. ${ex.message}`);
    }
    
  }

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  let userRole = getRole(currentUser);
  let photoURL = currentUser?.data?.PhotoURL;
  let displayName = currentUser?.data?.DisplayName;
  let abv = getAbv(currentUser);

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {displayName}
          </Typography>
          <Typography className="text-11 font-medium capitalize" color="text.secondary">            
            {displayName && userRole}
            
          </Typography>
        </div>

        
        {currentUser?.data?.PhotoURL ? (
          <Avatar className="md:mx-4" alt="user photo" src={photoURL} />
        ) : (
          <Avatar className="md:mx-4">{abv}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        { currentUser?.data?.Roles?.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            <MenuItem component={Link} to="/sign-up" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem>
          </>
        ) : (
          <>
          {/* 
            <MenuItem component={Link} to="/apps/profile" onClick={userMenuClose} role="button">
              
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem component={Link} to="/apps/mailbox" onClick={userMenuClose} role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </MenuItem>
            */}
            <MenuItem component={Link} to="/myprofile" onClick={userMenuClose} role="button">
            <ListItemIcon className="min-w-40">
              <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
            </ListItemIcon>
            <ListItemText primary="My Profile" />
            </MenuItem>

            <Divider  />

            {/* Password Reset  */}
            <MenuItem
              onClick={() => {
                userMenuClose();
                passwordReset();
              }}
            >
              <ListItemIcon className="min-w-40">
                <LockReset />
              </ListItemIcon>
              <ListItemText primary="Reset Password" />
            </MenuItem>
            <Divider  />

            {/* Logout  */}
            <MenuItem
              onClick={() => {
                userMenuClose();
                signOut();
              }}
            >
              
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
} // UserMenu

const getAbv = (user) => {
  let displayName = user?.data?.DisplayName;
  if(displayName == null || displayName.trim() == '') return '';

  let lFirst = user.data.FirstName[0];
  let lLast = user.data.LastName[0];

  return `${lFirst}${lLast}`;

}

const getRole = (user) => {

  let roles = user?.data?.Roles;
  if(user == null || roles == null) return 'Regulation User';
  
  let orgName = user?.data?.Organization;
  if(orgName != null){
    orgName += ' ';
  }
  else {
    orgName = '';
  }

  if(Array.isArray(roles)){  
    if(roles.includes('admin')){
      return `${orgName}Administrator`
    }
    else if(roles.includes('free')){
      return `${orgName}Regulation User`
    }
  }
  
  return 'User';
};

export default UserMenu;
