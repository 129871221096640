import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CookieManager from 'src/app/CookieManager';
import useAuthContext from 'src/app/AuthContext';
import { Link, useSearchParams } from 'react-router-dom';

// TODO: For machines with multiple users, the cid-email should match

const useFirebaseAuth = true;

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(6, 'Password must be at least 6 characters.'), // dropped for shared accounts
});

function SignInPage() {
  const [ remember, setRemember] = useState(true);
  const [ email, setEmail] = useState('');
  const [ password, setPassword] = useState('');
  const { signInWithEmailAndPassword } = useAuthContext();
  const [ searchParams ] = useSearchParams();
  const [ emailSet, setEmailSet ] = useState(false);

  const defaultValues = {
    email: '',
    password: '',
    remember,
  };

 
  useEffect(() => {
    let Email = searchParams.get('email');
    if(Email == null){
      Email = searchParams.get('Email');
    }
    if(Email != email && Email != null && Email.trim() != '' && emailSet==false){
      setEmailSet(true);
      //setEmail(Email);
      setValue('email', Email, { shouldDirty: true, shouldValidate: true });
    }

  }, [searchParams, email]);
 

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  

  function onSubmit({ email, password }) {
    

    signInWithEmailAndPassword(email, password)
      .then( user => {
        // everything should be handled already 
        // console.log(`User ${user?.displayName} has authenticated. Email ${(user?.emailVeried? 'is': ' is not')} verified`);
      })
      .catch(errors => {
        handleErrors(errors);
      }
    )
    
  }

  function onRememberClick() {
    setRemember(!remember);
  }

  function onEmailChanged(event) {
    let mail = event.target.value;
    
    if(mail != email){  // update state
      CookieManager.set('email', mail);
      //setEmail(mail);
      // TODO BE SURE TO SET CID
      setValue('email', mail, { shouldDirty: true, shouldValidate: true });
    }
  }

  function handleErrors(errors){
    if(Array.isArray(errors)){
      errors.forEach((error) => {
        handleError(error);              
      });
    } else {
      handleError(errors); 
    }
  }

  function handleError(error){

    if(error.code == "auth/user-not-found"){
      setError('email', {
        type: 'manual',
        message: <span>User not found. Please <a href="https://www.dovaxis.com/offer" target="_blank">sign up.</a></span>,
      });
    }
    else if(error.code == "auth/invalid-login-credentials"){
      setError('email', {
        type: 'manual',
        message: <span>Invalid credentials. <br/>Please correct or <a href="https://www.dovaxis.com/offer" target="_blank">sign up.</a></span>,
      });
    }
    else if(error.code == "auth/wrong-password"){
      setError('password', {
        type: 'manual',
        message: <span>Invalid password. <br/>Please try again or <a href="./forgot-password" target="_blank">reset.</a></span>,
      });
    }
    
  }

 

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img src="assets/logo/logo.svg" alt="logo" style={{marginLeft: '-40px'}}/>

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Sign in
          </Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography>Don't have an account?</Typography>
            <Link className="ml-4" to="https://www.dovaxis.com/offer">
              Sign up
            </Link>
          </div>

          <form
            id="LoginForm"
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoComplete='off'
                  autoFocus={true}
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  autoComplete='off'
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
              <Controller
                name="remember"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormControlLabel
                      label="Remember me"
                      onClick={onRememberClick}
                      control={<Checkbox  size="small" checked={remember} {...field} />}
                    />
                  </FormControl>
                )}
              />

              <Link className="text-md font-medium" to="/forgot-password">
                Forgot password?
              </Link>
            </div>
                {/* 
                autoComplete='email'
                autoComplete='current-password'

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="AJAX"
              onClick={onTestAJAX}              
              type="button"
              size="large"
            >
              Test
            </Button>
*/}
            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Sign in
            </Button>

            {/* 
            <div className="flex items-center mt-32">
              <div className="flex-auto mt-px border-t" />
              <Typography className="mx-8" color="text.secondary">
                Or continue with
              </Typography>
              <div className="flex-auto mt-px border-t" />
            </div>

            <div className="flex items-center mt-32 space-x-16">
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:facebook
                </FuseSvgIcon>
              </Button>
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:twitter
                </FuseSvgIcon>
              </Button>
              <Button variant="outlined" className="flex-auto">
                <FuseSvgIcon size={20} color="action">
                  feather:github
                </FuseSvgIcon>
              </Button>
            </div>
                */}
          </form>
        </div>
      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{ backgroundColor: 'primary.main' }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: 'primary.light' }}
            className="opacity-10"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: 'primary.light' }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-6xl font-bold leading-none text-gray-100">            
            <div>Human Powered. </div>
            <div style={{marginTop: '2px'}}>AI Enhanced.</div>
          </div>
          <div className="mt-24 text-3xl tracking-tight leading-6 text-gray-400">
            {/* Dovaxis solutions redefine conventional healthcare. */}
            DOVAXIS is Healthcare AI
          </div>
          <div className="mt-8 text-xl tracking-tight leading-6 text-gray-400">            
          Solutions that redefine conventional care.
          </div>
          <div className="flex items-center mt-32">
            <AvatarGroup
              sx={{
                '& .MuiAvatar-root': {
                  borderColor: 'primary.main',
                },
              }}
            >
              <Avatar src="assets/images/avatars/female-18.jpg" />
              <Avatar src="assets/images/avatars/female-11.jpg" />
              <Avatar src="assets/images/avatars/male-09.jpg" />
              <Avatar src="assets/images/avatars/male-16.jpg" />
            </AvatarGroup>

            <div className="ml-16 font-medium tracking-tight text-gray-400">
              50 organizations are elevating care.  It's your turn. 
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default SignInPage;