import format from 'date-fns/format';

export default function elapsed(date){

    const delta = Math.round((+new Date() - new Date(date)) / 1000);

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const fuzzy = {lines: 1, line1: '', line2: ''};

    if (delta < 30) {        
        fuzzy.line1 = 'just then';
    } else if (delta < minute) {        
        fuzzy.line1 = `${delta} seconds ago`;
    } else if (delta < 2 * minute) {        
        fuzzy.line1 = 'a minute '
    } else if (delta < hour) {        
        fuzzy.line1 = `${Math.floor(delta / minute)} minutes `;
    } else if (Math.floor(delta / hour) === 1) {        
        const deltaMinutes = delta - hour;
        fuzzy.lines = 2;
        fuzzy.line1 = '1 hour ';
        fuzzy.line2 = `${Math.floor(deltaMinutes / minute)} minutes `;
    } else if (delta < day) {        
        const deltaHours = Math.floor(delta / hour);        
        let deltaMinutes = delta - (deltaHours * hour);        
        deltaMinutes = Math.floor(deltaMinutes / minute);
        fuzzy.lines = 2;
        fuzzy.line1 = `${deltaHours} hours `;
        fuzzy.line2 = `${deltaMinutes} minutes `;
    } else if (delta < day * 2) {
        fuzzy.line1 = 'yesterday';
    }
    else {
        fuzzy.line1 = format(new Date(date), 'PP');
    }

    return fuzzy;
}

/*
<div className="items-center">



let MS_PER_MINUTE = 60000;
let  durationInMinutes = 12;
//let now = new Date("2023-07-14T03:00:30.176Z");
let now = new Date();
var fiveMinutesAgo = new Date(now - durationInMinutes * MS_PER_MINUTE);
console.log(fiveMinutesAgo.toISOString());


console.log(elapsed(fiveMinutesAgo));
*/