
import ChatProvider from './ChatProvider';
import TabLayout from './tabs/TabLayout';
import FeedbackPanel from './components/FeedbackPanel';

function ChatTabs({namespace}) {

  return (
    <ChatProvider namespace={namespace}>
      <TabLayout />      
      <FeedbackPanel />
    </ChatProvider>
  )
}



export default ChatTabs;