import * as dotenv from "dotenv"; 
import OpenAI from "openai";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { de } from "date-fns/locale";
dotenv.config();


let data = {
  script: 'AuthUser',
  name: "Authenticate User Account",
  description: 'Test Firestore access using User Account',
  enabled: true
}

let email = 'dev@dovaxis.com';
let password = "AhiPassword1$";

async function test(RESULT_TYPE) {

  return new Promise((resolve, reject) => {

    let message = `Firebase Config: ${JSON.stringify(firebaseConfig)}\n\n`;    

    try {
      let firebaseApp = initializeApp(firebaseConfig);
      
      const auth = getAuth(firebaseApp);      
      signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => { // Signed in             
            const user = userCredential.user;
            resolve({
              status: RESULT_TYPE.PASSED, 
              message: message += `User: ${JSON.stringify(user)}\n\n`
            })
          })
          .catch((ex) => {          
            reject({
              status: RESULT_TYPE.ERROR, 
              message: message += ex.message
            })
        }
      );
    }
    catch(ex){
      reject({
        status: RESULT_TYPE.ERROR, 
        message: message += ex.message
      })
    }
  });

  
}

let firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export default {test: test, data};