import { lazy } from 'react';

//import FastPassPage from './FastPassPage';
import authRoles from '../../../auth/authRoles';
const FastPassPage = lazy(() => import('./FastPassPage'));
const FastPassLogin = lazy(() => import('./FastPassLogin'));
const FastPassLoginFailedSignUp = lazy(() => import('./FastPassLoginFailedSignUp'));
const FastPassLoginFailedSignIn = lazy(() => import('./FastPassLoginFailedSignIn'));

const FastPassRoutes = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'fastpass',
      children: [     
        { path: '', element: <FastPassPage /> },
      ]
    },
    {
      path: 'fpin',
      children: [     
        { path: '', element: <FastPassLogin /> },
      ]
    },
    {
      path: 'fpoutsu',
      children: [     
        { path: '', element: <FastPassLoginFailedSignUp /> },
      ]
    },
    {
      path: 'fpoutsi',
      children: [     
        { path: '', element: <FastPassLoginFailedSignIn /> },
      ]
    },
  ],
};

export default FastPassRoutes;
