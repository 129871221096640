import React, {lazy} from 'react';
//import Layout from './layout';
//import ChatApp from './ChatApp';
// import ChatFirstScreen from './ChatFirstScreen';

const Layout = lazy(() => import('./layout'));
const ChatApp = lazy(() => import('./ChatApp'));


const ModuleRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'BrightReg',
      element: <Layout />,
      auth: ['admin', 'staff', 'ahi', 'demo', 'free', 'sholom'],
      children: [
        {
          path: '',
          auth: ['admin', 'staff', 'ahi', 'demo', 'free', 'sholom'],
          element: <ChatApp 
            namespace="AL-MN-V2" 
            pageTitle="BrightReg AL"
            pageDescription="Assisted Living Statutes 144G and Rules 4659 Demo"
            pageDescriptionMobile="MN 144G & 4659 Demo"
            chatHeader={<span>Ask <strong><i>BrightReg AL</i></strong> any question about <strong>Minnesota Assisted Living</strong> - <strong><i>Statutes 144G</i></strong> and <strong><i>Rules 4659</i></strong> and your AI Assistant will answer.</span>}
          />,
        },
        {
          path: 'AL',
          auth: ['admin', 'staff', 'ahi', 'demo', 'free'],
          element: <ChatApp 
            namespace="AL-MN-V2"
            pageTitle="BrightReg AL"
            pageDescription="Assisted Living Statutes 144G and Rules 4659 Demo"
            pageDescriptionMobile="MN 144G & 4659 Demo"
            chatHeader={<span>Ask <strong><i>BrightReg AL</i></strong> any question about <strong>Minnesota Assisted Living</strong> - <strong><i>Statutes 144G</i></strong> and <strong><i>Rules 4659</i></strong> and your AI Assistant will answer.</span>}
          />,
        },
        {
          path: 'SNF',
          auth: ['admin', 'staff', 'ahi', 'demo', 'sholom'],
          element: <ChatApp 
            namespace="SNF-CMS_V1" 
            pageTitle="BrightReg SNFL"
            pageDescription="CMS Rules of Participation for Skilled Nursing Demo"
            pageDescriptionMobile="CMS SNF Rules Demo"
            chatHeader={<span>Ask <strong><i>BrightReg SNF</i></strong> any question about <strong><i>Rules of Participation for Skilled Nursing Regulation</i></strong> and your AI Assistant answer.</span>}
          />, 
        }
      ],
    }    
      
  ]
};
  
export default ModuleRoutes;

  /*
  ,
        children: [
          { path: '', element: <Chat /> },
          { path: ':id', element: <Chat /> }
        ]
        */
