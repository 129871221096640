import React, {lazy} from 'react';

const Layout = lazy(() => import('./Layout'));
const HomePage = lazy(() => import('./dashboards/HomePage/index.js'));
const UsageReport = lazy(() => import('./dashboards/UsageReport/UsageReport.js'));
const SearchHistoryReport = lazy(() => import('./SearchHistory/Report'));
const SearchHistoryHomePage = lazy(() => import('./SearchHistory/pages/HomePage'));

const SessionHistoryReport = lazy(() => import('./SessionHistory/Report'));
const SessionHistoryHomePage = lazy(() => import('./SessionHistory/pages/HomePage'));

const ReportRoutes = {
  routes: [
    {
      path: 'reports',
      children: [     
        { path: '', element: <HomePage /> },
        { 
          path: 'usage',
          element: <UsageReport />
        }
      ]
    },
    {
      path: 'reports/SearchHistory',
      element: <SearchHistoryReport />,
      children: [     
        { path: '', element: <SearchHistoryHomePage /> },        
      ]
    }    ,
    {
      path: 'reports/SessionHistory',
      element: <SessionHistoryReport />,
      children: [     
        { path: '', element: <SessionHistoryHomePage /> },        
      ]
    }
  ]
}

export default ReportRoutes;