import {useState} from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Tooltip from '@mui/material/Tooltip';
import useChatContext from '../ChatContext';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { saveFeedback } from '../../data';

function Feedback(){
  const [upClicked, setUpClicked] = useState(false);
  const [downClicked, setDownClicked] = useState(false);
  const { setShowFeedback, currentUser, chatSession  } = useChatContext();
  const dispatch = useDispatch();

  function onUpClicked(){
    
    savePositiveFeedback();
    setUpClicked(!upClicked);
    setDownClicked(false);
  }

  function savePositiveFeedback(){

    let feedback = {
      isInaccurate: false,
      isIncomplete: false,
      type: 'Positive',
      comments: ''
    };

    saveFeedback(chatSession, currentUser, feedback)
      .then(() => {
        showFeedbackSaved();
      })
  }

  function showFeedbackSaved() {
    dispatch(showMessage(
      { 
      message: 'Thank you for your feedback',
      autoHideDuration: 1000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      }})
    );
  }

  function onDownClicked(){
    setUpClicked(false);
    let checked = !downClicked
    setDownClicked(checked);

    if(checked)
      setShowFeedback(true);
  }

  return (
    <div style={{display:'flex', position: 'relative', top: '-33px', right: '-75px'}} >
      <div onClick={onUpClicked} style={{marginRight: '8px'}}>
        <Tooltip title="Record that you like this answer." >
          {upClicked ? 
            <FuseSvgIcon  color="action" >
              heroicons-solid:thumb-up
            </FuseSvgIcon>
              :
            <FuseSvgIcon   color="action" >
              heroicons-outline:thumb-up 
            </FuseSvgIcon>
          }
        </Tooltip>
      </div>
      <div onClick={onDownClicked} >
        <Tooltip title="Record this answer does not meet your expectation." >
          {downClicked ? 
            <FuseSvgIcon color="action" >
              heroicons-solid:thumb-down
            </FuseSvgIcon>
              :
            <FuseSvgIcon   color="action" >
              heroicons-outline:thumb-down 
            </FuseSvgIcon>
          }
        </Tooltip>
      </div>

  </div>
  )
}

/*
<div>
    <Tooltip title="Your Microphone has not been configured for use.">
      <FuseSvgIcon className="" color="action" disabled >
        heroicons-outline:hand-thumb-up
      </FuseSvgIcon>
    </Tooltip>
    <Tooltip title="Your Microphone has not been configured for use.">
      <FuseSvgIcon className="" color="action" disabled >
        heroicons-outline:hand-thumb-down
      </FuseSvgIcon>
    </Tooltip>
</div>
*/
export default Feedback