import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { RegulationItems, ContentItems } from './data';

import  deepClone  from 'clone-deep';
import FuseUtils from '@fuse/utils';
import getDocIcon from './DocIcons';
import useChatContext from '../ChatContext';

function DocMessageType(props) {
  const { searchTerm } = props;
  const { chatSession } = useChatContext();

  if(searchTerm?.trim() == '') return;

  
  function openLink(docInfo) {
    chatSession.openDocTab(docInfo);
  }
  
  function getFilteredArray(arr, _searchText) {
    if (_searchText.length === 0) {
      return arr;
    }

    _searchText = _searchText.toLowerCase();

    let results = [];
    for(let i = 0; i<arr.length;i++){
      let resource = arr[i];
      
      for(let x = 0; x < resource?.data?.length; x++){
        let keywords = resource.data[x]?.keywords;
        if(!Array.isArray(keywords)) continue;
        if(keywords.includes(_searchText)){
          results.push(resource.data[x])
        }
      }

      /*
      resource.data = FuseUtils.filterArrayByString(resource.data, _searchText);
      results = results.concat(resource.data);=
      if(resource.data.length > 0){
        // resource.open = true;
      }
      */
    }

    return results;
  }

  let resources = [];
  if(chatSession?.namespace == 'elysian-pp'){
    resources = ContentItems;
  }
  else if(chatSession?.namespace == 'elysian-regulations'){
    resources = RegulationItems;
  }
  else if(chatSession?.namespace == 'AL-MN-V2'){
    resources = RegulationItems;
  }
  else if(chatSession?.namespace == 'free'){
    resources = [];
  }
  else if(chatSession?.namespace == 'elysianseniorhomes'){
    resources = [];
  }
  else {
    resources = [];
    console.warn('UNKNOWN NAMESPACE in ChatDocMessage!!!');
  }

  const match = getFilteredArray(deepClone(resources), searchTerm);
  if(match.length == 0) return;

  return (
    <DocMessage className="max-w-full" >

      <Typography
        className="description leading-tight wrap pb-10 doclist"
        color="text.secondary"
      >                                
        Resources related to "{<span style={{fontWeight:500}}>{searchTerm}</span>}":
      </Typography>   

      {
        match.map((item, idx) => (
          <div key={idx} className='pt-10'>
            <div onClick={() => openLink(item)}>
            <h4>{getDocIcon(item)} {item.name}</h4>
            </div>
            <Typography
              className="description leading-tight wrap"
              color="text.secondary"
            >                                
              Document description will be displayed here...
            </Typography>  
          </div>
        ))
      }
      
      
    </DocMessage>
  )
}



// to merge left:
// marginLeft: 'auto',

const DocMessage = styled('div')(({ theme }) => ({

  marginLeft: 0,
  minWidth: '200px',  
  paddingTop: 30,
  paddingBottom: 0,
  marginBottom: 0,
  display: 'flex',
  paddingRight: '80px',  
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  '& h4': {
    color: '#297d8b',
    cursor: 'pointer',
    
  },
  '& .description': {
    fontSize: '12px',
  },
  '& .doclist': {
    color: '#111827'
  }
}));

export default DocMessageType;

