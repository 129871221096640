import { useState } from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import useChatContext from '../ChatContext';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { saveFeedback } from '../../data';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';

const drawerWidth = 400;

/*
 * Feedback Panel
*/
function FeedbackPanel() {
  const [ comments, setComments ] = useState('');
  const [ isInaccurate, setIsInaccurate ] = useState(false);
  const [ isIncomplete, setIsIncomplete ] = useState(false);
  const { showFeedback, setShowFeedback, currentUser, chatSession} = useChatContext();
  const dispatch = useDispatch();

  function onSave(){

    let feedback = {
      isInaccurate,
      isIncomplete,
      type: 'Negative',
      comments
    };

    saveFeedback(chatSession, currentUser, feedback)
      .then(() => {
        showFeedbackSaved();
        resetState();    
        setShowFeedback(false);
      })
  }

  function resetState(){
    setIsInaccurate(false);
    setIsIncomplete(false);
    setComments('');
  }

  function showFeedbackSaved() {

    dispatch(showMessage(
      { 
      message: 'Thank you for your feedback',
      autoHideDuration: 1500,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      }})
    );
  }

  if(showFeedback == null || showFeedback == false)
    return (null);

  return (<StyledSwipeableDrawer
      className="h-full absolute z-9999"
      variant="temporary"
      anchor="left"
      open={true}
      onOpen={(ev) => {}}
      
      classes={{
        paper: 'absolute left-0',
      }}
      style={{ position: 'absolute' }}
      ModalProps={{
        keepMounted: false,
        disablePortal: true,
        BackdropProps: {
          classes: {
            root: 'absolute',
          },
        },
      }}
    >
      <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
        <div className="flex items-center justify-between border-b-1 w-full py-24 mt-16 mb-24">
          <span className="mx-8">
            <Typography sx={{ fontSize: 18, paddingBottom: '4px', color: '#297987' }}
                className=" leading-tight whitespace-pre-wrap nowrap"
                color="text.secondary">
            Submit Feedback
            </Typography>
          </span>
          <div className="flex items-center" onClick={() => setShowFeedback(false)}>
            <IconButton className=""  size="large">
              <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
            </IconButton>
          </div>
        </div>
        <div className='flex self-start ml-20'>
          <FormGroup>
            <FormControlLabel label="Inaccurate response" control={<Checkbox checked={isInaccurate} onClick={() => setIsInaccurate(!isInaccurate)} />}  />
            <FormControlLabel label="Incomplete response" control={<Checkbox checked={isIncomplete} onClick={() => setIsIncomplete(!isIncomplete)} />}  />            
          </FormGroup>
        </div>
        <div className="mt-32 max-h-auto self-start p-10 pb-4" style={{color: '#297987'}}>Comments:</div>
 
          <TextareaAutosize
            className='p-10'
            label={`Comments`}
            placeholder="Please share additional details that could have made your results better."
            id="comments"
            value={comments}
            onChange={e => setComments(e.target.value)}
            style={{width: '100%'}}
            minRows={3}
            maxRows={20}
          />
    
      <div className="flex items-center justify-end mt-32" style={{width: '100%'}}>
          <Button className="mx-8" onClick={() => setShowFeedback(false)}>Cancel</Button>
          <Button
            className="mx-8"
            variant="contained"
            color="secondary"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
    </StyledSwipeableDrawer>
    
  );
}
//disabled={_.isEmpty(dirtyFields) || !isValid}


const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-content': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    height: '100%',
  },
}));

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    maxWidth: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
}));

export default FeedbackPanel;

