import React, {useEffect, useState, useContext, useMemo } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FilteredIcon from '@mui/icons-material/VisibilityOff';
import AppContext from 'src/app/AppContext';
import { matchRoutes } from 'react-router-dom';
import useChatContext from '../ChatContext';
import { TAB_TYPE } from '../tabs/TabLayout';
import FuseUtils from '@fuse/utils';
import DOCS from '../../data/index.js';
import deepClone  from 'clone-deep';
import DocumentItem from './DocumentItem';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { fontFamily, fontWeight, textTransform } from '@mui/system';

// this renders content before or after
function ShowTools(){
  return (<FuseSvgIcon size={18} style={{ marginLeft: '12px', display:'inline-block', marginRight: '6px', color: '#4c6f7d'}} >heroicons-solid:ellipsis-horizontal</FuseSvgIcon>)
}

function filterByKeyword(data, keyword){
  // loop through all levels, showing and hiding directories that have a match
  let showDirectory = false;
  let files = data.items.filter(item => item.type != 'dir');
  let directories = data.items.filter(item => item.type == 'dir');

  // loop through local level files
  for(let i=0; i < files.length; i++){ // filter files
    let file = files[i];
    let found = hasKeyword(file, keyword);
    if(found){
      file.show = true;
      showDirectory = true;
    }
    else {
      file.show = false;
    }
  }


  // loop through subfolders
  for(let i=0; i < directories.length; i++){ // filter subfolders
    let directory = directories[i];
    let show = filterByKeyword(directory, keyword);
    
    if(show){
      showDirectory = true;
      directory.show = true;
      showDirectory = true;
     
      let shouldOpen = true; 
      let showCount = 0;      
      let directoryFiles = directory.items.filter(item => item.type != 'dir');

      if(directories.length < 2){
        shouldOpen = true;
      }
      else if(directoryFiles.length > 0){
        shouldOpen = false;
      }
      directory.open = shouldOpen;
    }
    else {
      directory.open = false;
      directory.show = false;
    }
  }

  // set local ("resource") directory settings
  if(showDirectory){    
    data.show = true;

    // determine if should open
    let shouldOpen = true;     

    if(files.length > 1){
      shouldOpen = false;
    }
    
    data.open = shouldOpen;
  }
  else {
    data.open = false;
    data.show = false;
  }

  return showDirectory;

}

function hasKeyword(file, keyword) {
  if (!file && !file$.keywords) {
    return false;
  }

  return file.keywords.includes(keyword);
}


function UpdateFolderCounts(items){
  for(let i=0; i<items.length; i++){
    let item = items[i];
    if(item.type == 'dir'){
      let files = item.items.filter(doc => doc.type != 'dir');
      let directories = item.items.filter(doc => doc.type == 'dir');
    
      if(item?.fileCount == null) item.fileCount = 0;
      let nestedFileCount = getNestedFileCount(directories);
      item.fileCount = files.length + nestedFileCount;
      item.dirCount = directories.length;      
      item.count = item.fileCount + item.dirCount;
      UpdateFolderCounts(directories);

    }
  }
}

function getNestedFileCount(items){
  let count = 0;
  for(let i=0;i<items.length;i++){
    let item = items[i];
    let files = item.items.filter(doc => doc.type != 'dir');
    let directories = item.items.filter(doc => doc.type == 'dir');

    if(directories.length > 0){
      count += getNestedFileCount(directories);
    }
    count += files.length;
  }
  return count;
}

function CombineFileCounts(items){
  
  for(let i=0; i<items.length; i++){
    let item = items[i];
    if(item.type == 'dir'){
      if(item?.fileCount == null) item.fileCount = 0;

      let files = item.items.filter(doc => doc.type != 'dir');
      let directories = item.items.filter(doc => doc.type == 'dir');
            
      let childCount = CombineFileCounts(directories);
      item.fileCount = files.length + childCount;
      item.dirCount = directories.length;      
      item.count = item.fileCount + item.dirCount;      
      return item.fileCount;
    }
  }
}



UpdateFolderCounts(DOCS.Elysian.All);
UpdateFolderCounts(DOCS.Sholom.All);
// RenderInitialCounts(DOCS.Elysian.All);
// CombineFileCounts(DOCS.Sholom.All);


// CombineFileCounts(DOCS.Sholom.All);

function DirectoryList(props){
  const [ open, setOpen ] = useState(true);
  const [ data, setData ] = useState([]);
  const [ currentNamespace, setNamespace ] = useState();
  //const [ pathname, setPathname ] = useState(location.pathname);
  const { onOpenDocTab } = props;
  const { chatSession } = useChatContext();
  const [ searchText, setSearchText ] = useState('');

  const { routes } = useContext(AppContext);
  //const { pathname } = location;


  chatSession.on('onPreparing', (namespace, text) => {    // apply filter
    if(searchText != text){
      setSearchText(text, namespace);
    }
  })

  chatSession.on('onSubmit', (namespace, text) => {       // clear filter
    if(searchText != text){
      setSearchText('');
    }
  })

  useEffect(() => {

    if(currentNamespace != chatSession?.namespace){
      setNamespace(chatSession?.namespace);
      if(chatSession?.namespace == 'elysian-pp'){
        setData(DOCS.Elysian.All);
      }
      else if(chatSession?.namespace == 'elysian-regulations'){
        setData(DOCS.Elysian.Regulations);
      }
      else if(chatSession?.namespace == 'AL-MN-V2'){
        setData(DOCS.Elysian.Regulations);
      }
      else if(chatSession?.namespace?.toLowerCase() == 'sholom'){
        setData(DOCS.Sholom.All);
      }    
      else {        
        console.warn('UNKNOWN NAMESPACE in Directory List!!!');
        setData([]);
      }
    }
  }, [chatSession])

  /*
  useEffect(() => {

    let pathname = location.pathname.toLowerCase();

    if(chatSession?.namespace == 'elysian-pp') {
      let filteredData = DOCS.Elysian.All; // deepClone(DOCS.Elysian.All);
      if(location.pathname == '/Elysian/EmergencyManual'){
        filteredData = filteredData.filter(c => c.name == 'Emergency Manual') 
      }
      else if(location.pathname == '/Elysian/PoliciesAndProcedures'){
        filteredData = filteredData.filter(c => c.name == 'Policies & Procedures') 
      }
      else if(location.pathname == '/Elysian/EmploymentDocuments'){
        filteredData = filteredData.filter(c => c.name == 'Employment Documents') 
      }
      else if(location.pathname == '/Elysian/Instructions'){
        filteredData = filteredData.filter(c => c.name == 'Instructions') 
      }
      else if(location.pathname == '/Elysian/Regulations'){
        filteredData = filteredData.filter(c => c.name == 'Regulations') 
      }
      
      if(filteredData.length == 1){
        filteredData[0].open = true;
      }
  
      setData(filteredData);
    } // elysian
    else if(chatSession?.namespace == 'sholom') {
      let filteredData = DOCS.Sholom.All; // deepClone(DOCS.Sholom.All);
      
      if(pathname.indexOf('/sholom/policiesandprocedures/ackerbergcampus') > -1){
        let policyData = filteredData.filter(c => c.name == 'Policies and Procedures')[0].items
        let vdData = policyData.filter(c => c.name == 'Vulnerable Adult')[0].items;
        filteredData = vdData.filter(c => c.name == 'Ackerberg Campus') 
      }
      else if(pathname.indexOf('/sholom/policiesandprocedures/shallercampus') > -1){      
        let policyData = filteredData.filter(c => c.name == 'Policies and Procedures')[0].items
        let vdData = policyData.filter(c => c.name == 'Vulnerable Adult')[0].items;
        filteredData = vdData.filter(c => c.name == 'Challer Campus')
      }
      else if(pathname.indexOf('/sholom/policiesandprocedures') > -1){
        filteredData = filteredData.filter(c => c.name == 'Policies and Procedures') 
      }
      else if(pathname.indexOf('/sholom/regulations') > -1){
        filteredData = filteredData.filter(c => c.name == 'Regulations') 
      }      
      else if(pathname.indexOf('/sholom/regulations/federal') > -1){
        let regData = filteredData.filter(c => c.name == 'Regulations')[0].items
        filteredData = regData.filter(c => c.name == 'Federal') 
      }
      else if(pathname.indexOf('/sholom/regulations/state') > -1){
        let regData = filteredData.filter(c => c.name == 'Regulations')[0].items
        filteredData = regData.filter(c => c.name == 'State') 
      }
      else if(pathname.indexOf('/sholom/regulations/state/mn') > -1){      
        let regData = filteredData.filter(c => c.name == 'Regulations')[0].items 
        let stateData = regData.filter(c => c.name == 'State')[0].items
        filteredData = stateData.filter(c => c.name == 'MN')         
      }
      else if(pathname.indexOf('/sholom/services') > -1){
        filteredData = filteredData.filter(c => c.name == 'Services')[0].items; 
      }
      else if(pathname.indexOf('/sholom/campus') > -1){      
        filteredData = filteredData.filter(c => c.name == 'Campus')[0].items; 
      }      
      else if(pathname.indexOf('/sholom/buildings') > -1){
        filteredData = filteredData.filter(c => c.name == 'Buildings')[0].items; 
      }
      if(filteredData.length == 1){
        filteredData[0].open = true;
      }
  
      setData(filteredData);
    }

  }, [location.pathname])
  */

  useEffect(() => {

    let pathname = location.pathname.toLowerCase();

    if(chatSession?.namespace.indexOf('elysian') > -1) {
      let filteredData = DOCS.Elysian.All; // deepClone(DOCS.Elysian.All);
      if(pathname == '/elysian/emergencymanual'){
        filteredData = filteredData.filter(c => c.name == 'Emergency Manual') 
      }      
      else if(pathname == '/elysian/competencies'){
        filteredData = filteredData.filter(c => c.name == 'Competencies') 
      }
      else if(pathname == '/elysian/policiesandprocedures'){
        filteredData = filteredData.filter(c => c.name == 'Policies & Procedures') 
      }
      else if(pathname == '/elysian/employmentdocuments'){
        filteredData = filteredData.filter(c => c.name == 'Employment Documents') 
      }
      else if(pathname == '/elysian/instructions'){
        filteredData = filteredData.filter(c => c.name == 'Instructions') 
      }
      else if(pathname == '/elysian/regulations'){
        filteredData = filteredData.filter(c => c.name == 'Regulations') 
      }
      
      if(filteredData.length == 1){
        filteredData[0].open = true;
      }
  
      setData(filteredData);
    } // elysian
    else if(chatSession?.namespace == 'sholom') {
      let filteredData = DOCS.Sholom.All; // deepClone(DOCS.Sholom.All);
      if(pathname == '/sholom/policiesandprocedures/ackerbergcampus'){
        let policyData = filteredData.filter(c => c.name == 'Policies and Procedures')[0].items
        let vdData = policyData.filter(c => c.name == 'Vulnerable Adult')[0].items;
        filteredData = vdData.filter(c => c.name == 'Ackerberg Campus') 
      }
      else if(pathname == '/sholom/policiesandprocedures/shallercampus'){        
        let policyData = filteredData.filter(c => c.name == 'Policies and Procedures')[0].items
        let vdData = policyData.filter(c => c.name == 'Vulnerable Adult')[0].items;
        filteredData = vdData.filter(c => c.name == 'Challer Campus')
      }
      else if(pathname.indexOf('/sholom/policiesandprocedures') > -1){
        filteredData = filteredData.filter(c => c.name == 'Policies and Procedures') 
      }
      else if(pathname == '/sholom/regulations'){
        filteredData = filteredData.filter(c => c.name == 'Regulations') 
      }
      else if(pathname == '/sholom/regulations/federal'){
        let regData = filteredData.filter(c => c.name == 'Regulations')[0].items
        filteredData = regData.filter(c => c.name == 'Federal') 
      }
      else if(pathname == '/sholom/regulations/state'){
        let regData = filteredData.filter(c => c.name == 'Regulations')[0].items
        filteredData = regData.filter(c => c.name == 'State') 
      }
      else if(pathname == '/sholom/regulations/state/mn'){
        let regData = filteredData.filter(c => c.name == 'Regulations')[0].items 
        let stateData = regData.filter(c => c.name == 'State')[0].items
        filteredData = stateData.filter(c => c.name == 'MN')         
      }
      else if(pathname == '/sholom/services'){
        filteredData = filteredData.filter(c => c.name == 'Services')[0].items; 
      }
      else if(pathname == '/sholom/campus'){
        filteredData = filteredData.filter(c => c.name == 'Campus')[0].items; 
      }
      else if(pathname == '/sholom/buildings'){
        filteredData = filteredData.filter(c => c.name == 'Buildings')[0].items; 
      }
      if(filteredData.length == 1){
        filteredData[0].open = true;
      }
  
      setData(filteredData);
    } // end Sholom
    else if(chatSession?.namespace == 'free') {
      let filteredData = deepClone(DOCS.Free.All);
      filteredData = filteredData.filter(c => c.name == 'Regulations')[0]; 
      if(pathname == '/free/snf'){
        filterByKeyword(filteredData, 'snf');
        filteredData = filteredData.items; 
      }
      else if(pathname == '/free/al'){

        filterByKeyword(filteredData, 'al');
        filteredData = filteredData.items; 
      }
      else {
        filteredData = filteredData.items; 
      }

      setData(filteredData);
    } // end free

  }, [location.pathname])

  const matchedRoutes = matchRoutes(routes, location.pathname);    
  let navProps = getProps(location.pathname, matchedRoutes);
  
  const handleNestedClick = (item) => {

    try {
      item.open = !item.open;
      setData([...data]);
    }
    catch(ex){
      console.log(`Failed to toggle content ${ex.message}`)
    }
  };

  const handleClick = (idx) => {

    let open = data[idx].open;
    data[idx].open = !open;
    setData([...data]);
  };

  function onOpenForm(form){

    // To Track onCloseTab see: https://github.com/caplin/FlexLayout/issues/22

    if(onOpenDocTab)
      onOpenDocTab(form, TAB_TYPE.FORM);
  }

  function onOpenReg(reg){

    if(reg?.link == null) return;

    // e.preventDefault
    // alert('onOpenForm');
    //reg.type = 'form';
    if(onOpenDocTab)
      onOpenDocTab(reg, TAB_TYPE.REGULATION);
  }

  function onOpenPolicy(policy){

    if(onOpenDocTab)
      onOpenDocTab(policy, TAB_TYPE.POLICY);
  } // onOpenPolicy

  
  function getItemMarkup(item){

    if(item?.show == false) return (null);

    let clickHandler = null;
    if(item?.type == 'form'){
      clickHandler = onOpenForm;
    }
    else if(item?.type == 'reg'){
      clickHandler = onOpenReg;
    }
    else if(item?.type == 'policy'){
      clickHandler = onOpenPolicy;
    }

    // console.log(`${item.id} ${item.name} in getItemMarkup`);
    return (<DocumentItem key={item.id} item={item} onOpenItem={onOpenForm} />)
    /*
    let docIcon = null;

    let key = createKey();
    if(item?.type == 'form'){
      return <ListItemButton key={`${key}-form`} sx={{ pl: 4 }} onClick={() => onOpenForm(item)}>                           
        <ListItemIcon>
          <FormIcon  />
          <WordIcon />
        </ListItemIcon>  
        <ListItemText primary={item.name} sx={{ pl: '4px' }} />
        </ListItemButton>
    }
    if(item?.type == 'reg'){
      return <ListItemButton key={`${key}-reg`} sx={{ pl: 4 }} onClick={() => onOpenReg(item)} >                           
        <ListItemIcon>
          <RegIcon  />
        </ListItemIcon>  
        <ListItemText primary={item.name} sx={{ pl: '4px' }} />
        </ListItemButton>
    }
    else {
      return <ListItemButton key={`${key}-policy`} sx={{ pl: 4 }} onClick={() => onOpenPolicy(item)}>                           
      <ListItemIcon>
        <PolicyIcon   />
        <PdfIcon />
      </ListItemIcon>  
      <ListItemText primary={item.name} sx={{ pl: '4px' }} />
      </ListItemButton>
    }

  */

  } // getItemMarkup

  function getDirMarkup(item, level=1){
    
    if(item?.show == false) return (null);

    // expand state
    /*
    if(item.type == 'dir' && item.items.length == 1){
      if(item.items[0].type == 'dir'){
        item.open = true;
      }      
    }
    */
    
    let textSettings = {
      fontWeight: 700, 
      fontSize: '15px',
      //textTransform: 'uppercase'
    }

    if(level == 2){
      textSettings.color = '#396f7d'; // #065664 // original: 808688
      textSettings.fontWeight=700;
      textSettings.fontSize = '12.5px';  // MN
    } 
    else if(level == 3){
      //textSettings.color = '#878484';  // original: 878484
      textSettings.fontWeight=600;
      textSettings.fontSize = '13px';      
    }
    else if(level == 4){
      textSettings.color = '#878484'; // original: #878484
      textSettings.fontWeight=600;
      textSettings.fontSize = '11px';      
    }

    let key = createKey();

    let folderText = '';
    if(item.dirCount == 0){
      folderText = ""
    } else if(item.dirCount == 1){
      folderText = "in one folder"
    } else {
      folderText = `in ${item.dirCount} folders`
    }

    let fileText = '';
    if(item.fileCount == 0){
      fileText = "no files"
    } else if(item.fileCount == 1){
      fileText = "1 file"
    } else {
      fileText = `${item.fileCount} files`
    }
    
    
    // console.log(`${item.id} ${item.name} in getDirMarkup`);
    return <div key={key}>          
        <ListItemButton key={`${key}-header`} onClick={(e) => {handleNestedClick(item)}}>
          {/* <FilteredIcon /> or <HiddenIcon />eye-slash dark-flag-brightsource-color:#4c6f7d brightsource-green: #347e8b */}          
          {item?.userIgnore == true? <FuseSvgIcon size={18} style={{ marginLeft: '2px', marginRight: '6px', color: '#4c6f7d'}} >heroicons-solid:eye-slash</FuseSvgIcon> : null}

          {/* <ShowTools /> */}
          <ListItemText primary={<><span >{item.name}</span><span style={styleFileDirText}>{` ( ${fileText} ${folderText} )`}</span></>} primaryTypographyProps={textSettings} />
          {item.open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={item.open} timeout="auto" unmountOnExit>
        <List component="div" dense >
          { item?.items.map((subItem, index) => {          
              if(subItem?.show == false) return (null);    
              if(subItem.type == 'dir'){
                //if(subItem.items.length == 1){ // auto expand
                  //subItem.open = true;  
                //}
                return getDirMarkup(subItem, (level + 1));
              }
              else {
                return getItemMarkup(subItem);
              }              
            })
          }
        </List>
      </Collapse>
      
    </div>
  }

/*
     {useMemo(() => {

            // if(!chatSession.filterDirectory) return;



            function getFilteredArray(arr, _searchText) {
              
              if (_searchText.length === 0) {
                for(let i = 0; i<arr.length;i++){
                  let resource = arr[i];
                  resource.open = false;
                  resource.hide = false
                }
                return arr;
              }
              
              for(let i = 0; i<arr.length;i++){
                let resource = arr[i];
                resource.items = FuseUtils.filterArrayByString(resource.items, _searchText);

                let hasSubData = false;
                for(let s=0; s<resource?.items?.length; s++){
               
                  let item = resource.items[s];
                  if(item?.items != null){
                    item.items = FuseUtils.filterArrayByString(item.items, _searchText);
                    if(item.items.length > 0){
                      item.open = true;
                      item.hide = false;
                    } else {
                      item.hide = true;
                    }

                    hasSubData = true;
                  }
                }


                if(resource.items.length > 0){
                  resource.open = true;
                  resource.hide = false;
                } else {
                  resource.hide = true;
                }
              }
              
              return arr;
            }
            
            let filteredData = [];

            if (searchText.length === 0 || chatSession.filterDirectory == false) {
              filteredData = data;
            }
            else {
              filteredData = getFilteredArray(deepClone(data), searchText);              
            }
                        
            let reset = filteredData.filter(fd => fd.hide == false);            
            if(reset.length == 0){
              filteredData = data;
            }

            return ( filteredData.map((section, idx) => {
              if(section == null) return;
              if(section?.hide == true) return;

              return (<div key={idx}>
                <ListItemButton key={idx} onClick={(e) => {handleClick(idx)}} >
                  <ListItemText primary={section.name} primaryTypographyProps={{fontWeight: 700, fontSize: '16px', color: '#297d8b'}} />
                  {section.open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={section.open} timeout="auto" unmountOnExit>
                  <List component="div" dense >
                    { section.items.map((item, index) => {

                        if(item?.type == 'dir') { 
                        
                        return getDirMarkup(item);
                      }

                      return getItemMarkup(item);
                  })}
                </List>              
              </Collapse>
            </div>)
          })
        );
        }, [data, searchText])}
        */

  return (
    
    <div style={{width: '100%', height: '100%', backgroundColor: 'white', flexGrow: 1 }}>
      <div style={{marginLeft: '10px'}}>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          
        >
          {useMemo(() => {

            // if(!chatSession.filterDirectory) return;

            // returns true if there's data to show
            function filterData(resource, searchText){
              
              let files = resource.items.filter(item => item.type != 'dir');
              let directories = resource.items.filter(item => item.type == 'dir');

              //let fileMatch = FuseUtils.filterArrayByString(files, _searchText);
              //let hasFileMatch = (fileMatch.length > 0)? true : false;  // filter at directory root

              let showDirectory = false;

              for(let i=0; i < files.length; i++){ // filter files
                let file = files[i];

                let found = searchObject(file, searchText);
                if(found){
                  file.show = true;
                  showDirectory = true;
                }
                else {
                  file.show = false;
                }
              }

              for(let i=0; i < directories.length; i++){ // filter subfolders
                let directory = directories[i];
                
                let found = filterData(directory, searchText);

                // set subfolder directory settings
                if(found){
                  directory.open = true;
                  directory.show = true;
                  showDirectory = true;
                }
                else {
                  directory.open = false;
                  directory.show = false;
                }                
              }

              // set local ("resource") directory settings
              if(showDirectory){
                resource.open = true;
                resource.show = true;
              }
              else {
                resource.open = false;
                resource.show = false;
              }

              return showDirectory;  // return to parent
            }

            
            function filterRoot(arr, searchText) {


              for(let i = 0; i<arr.length;i++){
                let resource = arr[i];
                filterData(resource, searchText);                
              }

              return arr;
            }

           
            
            let filteredData = [];
            if (searchText.length === 0 || chatSession.filterDirectory == false) {
              filteredData = data;
            }
            else {
              
              filteredData = filterRoot(deepClone(data), searchText);              
            }
            

            let visibileDirs = filteredData.filter(fd => fd.show == true);            
            if(visibileDirs.length == 0){ // nothing is visible, reset
              filteredData = data;
            }

            UpdateFolderCounts(filteredData);
            SortItems(filteredData);
            return renderDirectoryList(filteredData);
            
          }, [data, searchText])}
          
          
        </List>
      </div>
    </div>    
  );

  function renderDirectoryList(filteredData){

    return ( filteredData.map((section, idx) => {
      if(section == null) return;
      if(section?.show == false) return;
  
      
      return (<div key={idx}>
        <ListItemButton  onClick={(e) => {handleClick(idx)}} >
          <ListItemText primary={section.name} primaryTypographyProps={{fontWeight: 700, fontSize: '16px', color: '#297d8b'}} />
          {section.open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={section.open} timeout="auto" unmountOnExit>
          <List component="div" dense >
            { section.items.map((item, index) => {
  
                if(item?.type == 'dir') { 
                  return getDirMarkup(item);
                }
  
                return getItemMarkup(item);
            })}
          </List>              
        </Collapse>
      </div>)
    })          
  );
    
  /*
  
      return ( filteredData.map((section, idx) => {
        if(section == null) return;
        if(section?.hide == true) return;

        return (<div key={idx}>
          <ListItemButton key={idx} onClick={(e) => {handleClick(idx)}} >
            <ListItemText primary={section.name} primaryTypographyProps={{fontWeight: 700, fontSize: '16px', color: '#297d8b'}} />
            {section.open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={section.open} timeout="auto" unmountOnExit>
            <List component="div" dense >
              { section.data.map((item, index) => {

                  if(item?.type == 'dir') { 
                  
                    return getDirMarkup(item);
                  }

                  return getItemMarkup(item);
              })}
            </List>              
          </Collapse>
        </div>)
      })          
      );
    */
}

} // Directory List


function searchObject(itemObj, searchText) {

  if (!itemObj) {
    return false;
  }

  const propArray = Object.keys(itemObj);

  for (let i = 0; i < propArray.length; i += 1) {
    const prop = propArray[i];
    const value = itemObj[prop];

    if (typeof value === 'string') {
      if (searchInString(value, searchText)) {
        return true;
      }
    } else if (Array.isArray(value)) {
      if (searchInArray(value, searchText)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (searchObject(value, searchText)) {
        return true;
      }
    }
  }
  return false;
}

function searchInArray(arr, searchText) {
  arr.forEach((value) => {
    if (typeof value === 'string') {
      if (searchInString(value, searchText)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (searchObject(value, searchText)) {
        return true;
      }
    }
    return false;
  });
  return false;
}

function searchInString(value, searchText) {
  return value.toLowerCase().includes(searchText);
}

function getProps(pathname, matchedRoutes){

  if(matchedRoutes == null || Array.isArray(matchedRoutes) == false) return;

  let matchedRoute = null;
  for(let i=0; i<matchedRoutes.length;i++){
    if(matchedRoutes[i].pathname == pathname){
      matchedRoute = matchedRoutes[i];
      break;
    }
  }
  
  if(matchedRoute){
    return matchedRoute?.route?.element?.props;
  }
}

function createKey() {
  function S4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return S4() + S4();
}

const styleFileDirText = {
  paddingLeft: '4px',
  fontFamily: 'Inter var, Roboto, "Helvetica", Arial, sans-serif',
  fontWeight: 400,
  color: '#979494'
}

function SortItems(items, level=0){
  let spacer = " ".repeat(level)
  items = items.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base' }));
  for(var i=0; i<items.length; i++){
    let item = items[i];
    //console.log(`${spacer}${item.name}`)
    if(item.type == 'dir'){      
      SortItems(item.items, level + 1);
    }
  }
  return items;
}

export default DirectoryList;