

import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';

function SendButton() {
//placement={props.variant === 'horizontal' ? 'bottom' : 'left'}
//
  return (
    <Tooltip title='Ask Dove' placement='top-end'>
      <>
      <SvgIcon style={{height:'35px', width: '35px', position:'relative', float: 'left', left:'-4px'}}>
        <svg id="chat-left" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
        </svg>
      </SvgIcon>  
      <SvgIcon style={{height:'18px', width: '18px', position:'relative', float: 'left',  top: '-3px', left: '-31px'}}>
        <svg id="dove" fontSize='8px'  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
          <path fill="#347e8b"  d="m529.94,373.33s-.04.02-.07.04l-.47.26c-.32.18-.65.37-.99.57h0c-.33.19-.68.4-1.04.6-.36.21-.72.42-1.09.64-10.56,6.2-26.83,16.62-26.93,16.68-37.55,23.37-87.38,60.32-132.94,94.17,0,0,0,0-.01,0-1.78,1.32-3.56,2.64-5.32,3.96,0,0-.01,0-.01,0-1.77,1.31-3.54,2.62-5.29,3.92-6.15,4.56-12.19,9.05-18.1,13.41-1.68,1.24-3.36,2.48-5.02,3.7-2.68-3.39-5.04-7.33-7.67-10.88-.07-.05-.09-.09-.11-.16-3.69-4.99-7.26-10.17-10.88-15.45-41.06-61.28-82.8-111.49-82.8-111.49L381.43,40.13l148.51,333.2Z"/>
          <path fill="#347e8b"  d="m776.09,417.74h-49.87s-56.47,87-98.32,148.52c-12.18,17.88-34.51,49.71-62.12,67.25-173.45,110.09-462.62-25.47-541.86-58.23,115.56,11.15,200.9,15.02,275.25-39.68,10.26-7.28,21.54-15.52,33.5-24.29,58.39,79.13,74.42,132,161.42,117.76.26-.04.52-.09.78-.13.53-.09,1.07-.19,1.61-.31.54-.11,1.09-.23,1.65-.35,23.24-5.24,59.87-24.71,80.94-57.67,23.03-35.28,28.66-85.81-18.45-150.13-4.51-7.28-23.96-36.83-29.34-45-.03-.04-.04-.07-.07-.1l-1.25-2.05c106.93-60.18,175.21-55,225.8,15.27,10.42,14.47,20.36,29.14,20.36,29.14Z"/>
          <path fill="#347e8b"  d="m579.05,570.61c-21.41,32.81-57.86,52.42-80.94,57.67-.56.13-1.11.24-1.65.35-.55.11-1.08.21-1.61.31-.26.04-.52.09-.78.13-87.01,14.24-103.04-38.63-161.42-117.76,1.66-1.22,3.33-2.46,5.02-3.7,5.9-4.36,11.94-8.84,18.1-13.41,1.75-1.3,3.52-2.61,5.29-3.92,0,0,0,0,.01,0,1.76-1.31,3.54-2.63,5.32-3.96,0,0,.01,0,.01,0,45.58-33.84,95.44-70.73,132.99-94.1.1-.06,16.33-10.53,26.88-16.75.37-.22.73-.43,1.09-.64.35-.2.7-.4,1.04-.6h0c.33-.19.67-.38.99-.57l.47-.26s.48.73,1.32,2c.03.03.04.06.07.1,5.38,8.17,24.83,37.72,29.34,45,47.12,64.31,41.48,114.85,18.45,150.13Z"/>
        </svg>
      </SvgIcon> 
      </>
    </Tooltip>
  )
}

export default SendButton;