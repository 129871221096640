import React, { useContext, useEffect, useRef, useState } from 'react';
import { lighten, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getChat, selectChat, sendMessage } from '../referrals/store/chatSlice';
import { selectContactById } from '../referrals/store/contactsSlice';
import ContactAvatar from './ContactAvatar';
import ChatMoreMenu from './ChatMoreMenu';
import ChatAppContext from './context';
import InputAdornment from '@mui/material/InputAdornment';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import Recommendations from './Recommendations';
import References from './References';
import ResourceList from './ResourceList';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import DocChat from './docChat';
import { saveSearch } from './data.js';
import { selectUser } from 'app/store/userSlice';
import  deepClone  from 'clone-deep';
import { selectCurrentLanguage, selectLanguages } from 'app/store/i18nSlice';
//import ChatTabs from './v2/tabs/ChatTabs';
import ChatTabsV3 from './v3/ChatTabs';
import ChatTabsV4 from './v4/ChatTabs';
//import ChatV4 from './v4/ChatV4';
import useAuthContext from 'src/app/AuthContext';
import './ChatApp.css';

function DoveSvg() {
  return (<svg id="dove" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
  <defs>
      <style>{`
      .cls-1 {
          fill: #d3edf4;
      }

      .cls-1, .cls-2 {
          stroke-width: 0px;
      }

      .cls-2 {
          fill: #80c8db;
      }
      `}</style>
  </defs>
  <path className="cls-1" d="m529.94,373.33s-.04.02-.07.04l-.47.26c-.32.18-.65.37-.99.57h0c-.33.19-.68.4-1.04.6-.36.21-.72.42-1.09.64-10.56,6.2-26.83,16.62-26.93,16.68-37.55,23.37-87.38,60.32-132.94,94.17,0,0,0,0-.01,0-1.78,1.32-3.56,2.64-5.32,3.96,0,0-.01,0-.01,0-1.77,1.31-3.54,2.62-5.29,3.92-6.15,4.56-12.19,9.05-18.1,13.41-1.68,1.24-3.36,2.48-5.02,3.7-2.68-3.39-5.04-7.33-7.67-10.88-.07-.05-.09-.09-.11-.16-3.69-4.99-7.26-10.17-10.88-15.45-41.06-61.28-82.8-111.49-82.8-111.49L381.43,40.13l148.51,333.2Z"/>
  <path className="cls-1" d="m776.09,417.74h-49.87s-56.47,87-98.32,148.52c-12.18,17.88-34.51,49.71-62.12,67.25-173.45,110.09-462.62-25.47-541.86-58.23,115.56,11.15,200.9,15.02,275.25-39.68,10.26-7.28,21.54-15.52,33.5-24.29,58.39,79.13,74.42,132,161.42,117.76.26-.04.52-.09.78-.13.53-.09,1.07-.19,1.61-.31.54-.11,1.09-.23,1.65-.35,23.24-5.24,59.87-24.71,80.94-57.67,23.03-35.28,28.66-85.81-18.45-150.13-4.51-7.28-23.96-36.83-29.34-45-.03-.04-.04-.07-.07-.1l-1.25-2.05c106.93-60.18,175.21-55,225.8,15.27,10.42,14.47,20.36,29.14,20.36,29.14Z"/>
  <path className="cls-2" d="m579.05,570.61c-21.41,32.81-57.86,52.42-80.94,57.67-.56.13-1.11.24-1.65.35-.55.11-1.08.21-1.61.31-.26.04-.52.09-.78.13-87.01,14.24-103.04-38.63-161.42-117.76,1.66-1.22,3.33-2.46,5.02-3.7,5.9-4.36,11.94-8.84,18.1-13.41,1.75-1.3,3.52-2.61,5.29-3.92,0,0,0,0,.01,0,1.76-1.31,3.54-2.63,5.32-3.96,0,0,.01,0,.01,0,45.58-33.84,95.44-70.73,132.99-94.1.1-.06,16.33-10.53,26.88-16.75.37-.22.73-.43,1.09-.64.35-.2.7-.4,1.04-.6h0c.33-.19.67-.38.99-.57l.47-.26s.48.73,1.32,2c.03.03.04.06.07.1,5.38,8.17,24.83,37.72,29.34,45,47.12,64.31,41.48,114.85,18.45,150.13Z"/>
  </svg>)
}

function LoadingDove() {
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleFabClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 3000);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Fab
          aria-label="save"
          color="primary"
          size="small"
          sx={{marginRight: '4px'}}
          onClick={handleFabClick}
        >
          {success ? <DoveSvg /> : <DoveSvg />}
        </Fab>
        {loading && (
          <CircularProgress
            size={48}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -4,
              left: -4,
              zIndex: 1,
            }}
          />
        )}
      </Box>

    </Box>
  );
} // dove component


let userId = 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df';
let AI_ID = '9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf'


function Chat(props) {
  const { namespace } = props;
  const { setMainSidebarOpen, setContactSidebarOpen } = useContext(ChatAppContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  // const chat = useSelector(selectChat);
  // const user = useSelector(selectUser);
  const routeParams = useParams();
  const contactId = "9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf"; //routeParams.id;
  // const selectedContact = useSelector((state) => selectContactById(state, contactId));
  const chatRef = useRef(null);
  const [ questionText, setQuestionText] = useState('');
  const [ chat, setChat] = useState([]);
  const [ currentPath, setCurrentPath ] = useState();
  const [ vectorCount, setVectorCount ] = useState(2);
  const [ readback, setReadback] = useState(false);
  const [ showRecommendations, setShowRecommendations] = useState(false);
  const [ recommendations, setRecommendations ] = useState([]);
  const [ vectors, setVectors ] = useState([]);
  //const [currentUser, setCurrentUser] = useState(null);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const userTemp = useSelector(selectUser); 
  const { currentUser } = useAuthContext();

  const handleVectorChange = (event) => {
    setVectorCount(event.target.value );
  };

  useEffect(() => {
    if(currentPath == null){
      setCurrentPath(window.location.pathname.toLowerCase())
    }else if(currentPath != window.location.pathname.toLowerCase()){
      setCurrentPath(window.location.pathname.toLowerCase()); // update
      setChat([]); // reset Chat
      clearRecommendations();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (chat) {
      setTimeout(scrollToBottom);
    }
  }, [chat]);

  function createEmptyQuestions(examples) {
    setRecommendations(examples);
    setShowRecommendations(true);
  }

  function applyRecommendation(question) {
    clearRecommendations();
    postQuestion(question);
  }

  function clearRecommendations(){
    setRecommendations([]);
    setQuestionText("");
    setShowRecommendations(false);
  }

  // this cleans out customer names since we're demonstrating some of their content
  function CleanAnswer(response, namespace){

    if(namespace == 'cornerstone-policy'){
      response.answer = response.answer.replace(/Cornerstone Nursing and Rehab Center/gi, 'The Facility'); // case insensitive
      response.answer = response.answer.replace(/Cornerstone/gi, 'The Facility');
      response.answer = response.answer.replace('(CNRC)', '');
      response.answer = response.answer.replace('CNRC', 'The Facility');
      response.answer = response.answer.replace(/Nursing and Rehab Center/gi, '');
      response.answer = response.answer.replace('  ', ' ');
    }
  }



  function postQuestion(question) {

    let ask = (typeof question == "string")? question : questionText;

    if(ask == '') return;
    

    // push ask
    chat.push({
      "id": chat.length + 1,
      "chatId": "",
      "contactId": userId, // currentUser.uuid,
      "value": ask,
      "createdAt": new Date()
    })

    // push thinking prompt
    chat.push({
      "id": chat.length + 1,
      "chatId": AI_ID,
      "type": "loading"
    })

    setChat([...chat]);

    //showRecommendations(false);    
    DocChat.ask(ask, namespace, vectorCount, currentLanguage)
      .then( response => 
        {
          chat.pop();  // remove thinking
          saveSearch(ask, currentUser, response?.answered, response.questionEmbedding, currentLanguage, namespace).then( document => {
            //alert(document.id);
          });

          CleanAnswer(response, namespace);

          // push answer
          chat.push({
            "id": chat.length + 1,
            "question": ask,
            "namespace": namespace,
            "chatId": "",
            "contactId": AI_ID,
            "value": response.answer,
            "response": response,
            "createdAt": new Date()
          })

          setChat([...chat])
          // setVectors(response.vectors)

          // "I'm sorry, but the provided context does not contain any information that would allow me to form an opinion or provide a response to your query. The context provided is related to maintaining an effective pest control program in a facility. If you have any specific questions related to that topic, I would be happy to try and help."
          //if(response.answer == "No relevant results." || response.answer.indexOf("provided context does not contain") >= 0){ // Handle "No Response"

          if(response?.answered == false) {

            // let examples = getRandomExamples(array, 3);
            let examples = response.examples;
            setRecommendations(examples);
            setShowRecommendations(true);
          }
        })      
      .catch( response =>
      {
        saveSearch(ask, currentUser, false, response.questionEmbedding, currentLanguage, namespace).then( document => {
          //alert(document.id);
        });
      }); // DocChat.ask
     
  } // postQuestion

  function FetchingAnswer() {
    return (<>
      <LoadingDove />
      <div className="leading-tight whitespace-pre-wrap">Your AI Assistant is thinking...
      </div>
    </>)
  }
  function getRandomExamples(array, count) {
    const randomStrings = [];
    for (let i = 0; i < count; i++) {
      const randomIndex = Math.floor(Math.random() * array.length);
      randomStrings.push(array[randomIndex]);
    }
    return randomStrings;
  }

  function scrollToBottom() {
    if (!chatRef.current) {
      return;
    }
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: 'smooth'
    })
  }

  function isFirstMessageOfGroup(item, i) {
    return i === 0 || (chat[i - 1] && chat[i - 1].contactId !== item.contactId);
  }

  function isLastMessageOfGroup(item, i) {
    return i === chat.length - 1 || (chat[i + 1] && chat[i + 1].contactId !== item.contactId);
  }

  function onMessageChange(e) {
    setQuestionText(e.target.value);
  }

  function onKeyPress(e){
    if(e.key == 'Enter'){
      // postQuestion() ;
    }
  }

  function onReadbackClicked(ev){
    setReadback(!readback);
  }

  function onMessageSubmit(ev) {
    ev.preventDefault();
    if (questionText === '') {
      return;
    }

    setQuestionText('');
  }

  if (!user || !chat ) {
    return null;
  }

  let chatWidth = {
    width: '60%',
    alignSelf: 'center',
    backgroundColor: 'white'
  }

  if(isMobile)
    chatWidth.width = '100%';

  if(location.pathname.indexOf('/Elysian') > -1){
    return (<ChatTabsV4 namespace={namespace} />)
  }
  else if(location.pathname.toLocaleLowerCase().indexOf('/sholom') > -1 ){
    return (<ChatTabsV4 namespace={namespace} />)
  }
  else if(location.pathname.indexOf('/Free') > -1 ){
    return (<ChatTabsV4 namespace={namespace} />)
  }
  
  return (
    <>
      <div className="flex flex-auto h-full min-h-0 w-full " >
        <div className={clsx('flex flex-1 z-10 flex-col relative', props.className)}>
          {/* empty background */}
          {chat?.length == 0 && (
            <div className="overflow-y-auto">
            <ResourceList namespace={namespace} onCreateEmptyQuestions={createEmptyQuestions} />
            </div>
            )
          }
          {/* chat messages */}
          <div ref={chatRef} className="flex flex-1 flex-col overflow-y-auto" style={{marginBottom: '90px'}}>
            {chat?.length > 0 && (
              <div className="flex flex-col pt-16 px-16 pb-10" style={chatWidth}>
                {chat.map((item, i) => {
                  return (
                    <StyledMessageRow
                      key={i}
                      className={clsx(
                        'flex flex-col grow-0 shrink-0 items-start justify-end relative px-16 pb-4',
                        item.contactId === user.id ? 'me' : 'contact',
                        { 'first-of-group': isFirstMessageOfGroup(item, i) },
                        { 'last-of-group': isLastMessageOfGroup(item, i) },
                        i + 1 === chat.length && 'pb-64'
                      )}
                    >
                      <div className="bubble flex relative items-center justify-center p-12 max-w-full">
                        {(item.contactId != user.id && item?.type == 'loading')?
                          <FetchingAnswer />
                          :
                          <>
                          <div className="leading-tight whitespace-pre-wrap">{item.value}</div>

                            <div className=" absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 " style={{display:'flex'}}>
                              <Typography

                                style={{ flexGrow: 1}}
                                className="time leading-tight whitespace-pre-wrap nowrap"
                                color="text.secondary"
                              >                                
                                {
                                  item?.createdAt != null &&
                                  formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })
                                }
                              </Typography>
                              {item.contactId != user.id && <References chat={item} />}
                            </div>
                          </>
                        }
                      </div>
                    </StyledMessageRow>
                  );
                })}


              </div>

            )}
            <Recommendations namespace={namespace} chatWidth={chatWidth} show={showRecommendations} setShow={setShowRecommendations} questions={recommendations} applyHandler={applyRecommendation} />


          </div>

          { chat && (
            <Paper
              square
              component="form"
              onSubmit={onMessageSubmit}
              className="absolute border-t-1 bottom-0 right-0 left-0 py-16 px-16"

            >
              <div className="flex items-center relative">

            {/* 
            {namespace != 'pharmacy-rx-policies' && namespace != 'cornerstone-policy' &&
            <FormControl style={{width:'100px'}} >
              <InputLabel id="demo-simple-select-label">Vectors</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vectorCount}
                    label="Vectors"
                    onChange={handleVectorChange}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
            </FormControl>
            }
            */}
                {/* Message Imput Control 
                Type or speak a question
                */}
                <InputBase
                  id="ask-question"
                  className="flex-1 flex grow shrink-0 h-44 mx-8 px-16 border-2 rounded-full"
                  placeholder="Type your question here."
                  autoComplete="off"
                  onChange={onMessageChange}
                  onKeyDown={onKeyPress}
                  value={questionText}
                  sx={{ backgroundColor: 'white' }}

                  startAdornment={
                    <InputAdornment position="start" disabled>
                      <Tooltip title="Your Microphone has not been configured for use.">
                        <FuseSvgIcon className="" color="action" disabled >
                          heroicons-outline:pencil
                        </FuseSvgIcon>
                      </Tooltip>
                    </InputAdornment>
                  }

                />

                {/* Submit Message  Control */}
                <IconButton className="" type="submit" size="large" onClick={postQuestion}>
                  <FuseSvgIcon className="rotate-90" color="action">
                    heroicons-outline:paper-airplane
                  </FuseSvgIcon>
                </IconButton>

                {/* Readback Settings Control */}
                {/*                 
                  <Tooltip title="Readback response settings">
                    <IconButton  onClick={onReadbackClicked}>
                      {(readback == true)?
                        <FuseSvgIcon className="text-24" color="action">
                          heroicons-outline:speaker-wave
                        </FuseSvgIcon>
                        :
                        <FuseSvgIcon className="text-24" color="action">
                        heroicons-outline:speaker-x-mark
                        </FuseSvgIcon>
                      }
                    </IconButton>
                  </Tooltip>
                */}

              </div>
            </Paper>
          )}
        </div>
      </div>
    </>
  );
}


// className="flex-1 flex grow shrink-0 h-44 mx-8 px-16 border-2 rounded-full"

const StyledInputBase = styled('input')(({ theme }) => ({

  '&:-internal-autofill-selected': {
    backgroundColor: 'white'
  }
}));
// input:-internal-autofill-selected

const StyledMessageRow = styled('div')(({ theme }) => ({
  '&.contact': {
    '& .bubble': {
      backgroundColor: '#dceff4', // theme.palette.secondary.light,# dceff4 #4AAEC7  ebebeb
      color: 'black', // theme.palette.secondary.contrastText,
      fontWeight: '480',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      minWidth: '300px',

      '& .time': {
        marginLeft: 12,
      },
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 10,
      },
    },
    '&.last-of-group': {
      '& .bubble': {
        borderBottomLeftRadius: 10,
      },
    },
  },
  '&.me': {
    paddingLeft: 40,
    '& .bubble': {
      marginLeft: 'auto',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      minWidth: '200px',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      '& .time': {
        justifyContent: 'flex-end',
        right: 0,
        marginRight: 12,
      },
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopRightRadius: 10,
      },
    },

    '&.last-of-group': {
      '& .bubble': {
        borderBottomRightRadius: 10,
      },
    },
  },
  '&.contact + .me, &.me + .contact': {
    paddingTop: 20,
    marginTop: 20,
  },
  '&.first-of-group': {
    '& .bubble': {
      borderTopLeftRadius: 10,
      paddingTop: 13,
    },
  },
  '&.last-of-group': {
    '& .bubble': {
      borderBottomLeftRadius: 20,
      paddingBottom: 13,
      '& .time': {
        display: 'flex',
      },
    },
  },
}));


const user = {
  "id": "cfaad35d-07a3-4447-a6c3-d8c3d54fd5df",
  "name": "Brian Hughes",
  "email": "hughes.brian@company.com",
  "status": "online",
  "avatar": "assets/images/avatars/brian-hughes.jpg",
  "about": "Hi there! I'm using FuseChat."
}


export default Chat;
