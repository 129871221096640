import ChatBar from '../components/ChatBar';
import ChatEmptyLanding from '../components/ChatEmptyLanding';
import Messages from '../components/Messages';
import clsx from 'clsx';

function ChatTab(props) {
  const { className } = props;

  return (
    <div className="flex flex-auto h-full min-h-0 w-full">
				<div className={clsx('flex flex-1 z-10 flex-col relative', className)}>
					<div className="flex flex-1 flex-col overflow-y-auto">
            <ChatEmptyLanding />
            <Messages {...props}  />
            <ChatBar {...props} />
          </div>
      </div>
    </div>
  )

}

export default ChatTab;