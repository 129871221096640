import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardMedia } from "@mui/material";
import { Link } from "react-router-dom";

function Support() {
  // support landing page
  return (
    <div
      style={{ height: "100%", width: "100%", margin: "auto", paddingTop: 40 }}
    >
      <div className="flex flex-col sm:flex-row item-center sm:items-start space-y-8 sm:space-y-0 sm:p-14 w-full items-center justify-center">
        <Link to="/support/submit-ticket" style={{ textDecoration: "none" }}>
          <Card sx={{ maxWidth: 445 }} className="p-10 me-12">
            <CardActionArea>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  className="text-center"
                >
                  Get Support
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="text-center mb-15"
                >
                  Submit a ticket to DOVAXIS to get help?
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                width="100"
                image="assets/images/pages/support/DALL·E 2024-06-11 10.29.01 - A friendly robot holding a ticket or a piece of paper with a question mark on it, indicating that the robot is ready to assist with any inquiries. The.webp"
                alt="green iguana"
                style={{}}
              />
            </CardActionArea>
          </Card>
        </Link>

        <Link to="/support/Training" style={{ textDecoration: "none" }}>
          <Card sx={{ maxWidth: 445 }} className="p-10 ms-12">
            <CardActionArea>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  className="text-center"
                >
                  Get Training
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="text-center mb-15"
                >
                  Learn best practices using Dovaxis
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                width="270"
                image="assets/images/pages/support/DALL·E 2024-06-11 10.34.58 - A friendly robot wearing a graduation cap, holding a book, and standing in front of a screen or whiteboard, indicating educational content and trainin.webp"
                alt="green iguana"
              />
            </CardActionArea>
          </Card>
        </Link>
      </div>
    </div>
  );
}

export default Support;