import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { IconButton, List, Typography } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NewKeyword from './NewKeyword';
import KeywordItem from './KeywordItem';

const KeywordDialog = ({ open, item, onClose }) => {
  const [keywords, setKeywords] = useState([]);

  useEffect (() => {
    const itemKeywords = item.keywords || [];
    const keywordMap = itemKeywords.map((keyword) => ({title: keyword, id: Math.random()}));

    setKeywords(keywordMap);
  }, [])

  const addKeyword = (newKeyword) => {
    const newKeywords = [...keywords, newKeyword];
    setKeywords(newKeywords);
  }

  const removeKeyword = (id) => {
    const newKeywords = keywords.filter(keyword => keyword.id !== id)
    setKeywords(newKeywords);
  }

  const handleClose = () => { 
    onClose();
  }

  return (
    <Dialog
      classes={{
        paper: 'w-full max-w-320 p-24 md:p-40 m-24',
      }}
      onClose={handleClose}
      open={open}
    >
        <Typography> Keywords assigned to </Typography>
        <Typography className="text-20 mb-24 font-semibold">{item.name}</Typography>
      <IconButton 
        onClick={handleClose}
        sx = {{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <FuseSvgIcon color="action">material-outline:close</FuseSvgIcon>
      </IconButton>
      <List dense>
        <NewKeyword addKeyword = {addKeyword}/>

        {keywords.map((item) => (
          <KeywordItem keyword={item} key={item.id} removeKeyword = {removeKeyword} />
        ))}
      </List>
    </Dialog>
  );
};

export default KeywordDialog;