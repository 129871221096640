

const Services = {
  "name": "Services",
  type: "dir", open: false, count: -1,
  "id": "svc0ea9-8b2a-45cf-bce7-6e86ac501c412",
  "path": "TODO",
  items: [
    {
      name: "Home Care",
      type: "dir", open: false, count: -1,
      id: "c201bea9-8b2a-45cf-bce7-6e86ac501c59",
      path: "HomeCare",
      items: [
        {
          name: "AL and Comprehensive Home Care - Vulnerable Adult Policy",
          type: "unknown",
          id: "b2afa0b9-4342-4d04-b112-8bafd3b93896",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ftodelete%2FAL%20and%20Comprehensive%20Home%20Care%20%20-%20Vunerable%20Adult%20Policy.docx?alt=media&token=432da870-0b75-4eb5-bad1-97c6f08a4488",
          fileExt: ".docx",
          fileName: "AL and Comprehensive Home Care - Vulnerable Adult Policy.docx",
          keywords: ['Vulnerable Adult', 'v2'],
          tags: [{id: "al01bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Assisted Living'},{ id: "c201bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Home Care'}]
        }, 
        {
          name: "AL and Comprehensive Home Care Instructions - Vulnerable Adult",
          type: "unknown",
          id: "bxyfa0b9-4342-4d04-b112-8bafd3b93896",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2Fr2%2FAL%20and%20Comprehensive%20Home%20Care%20instructions%20-%20Vulnerable%20Adult.pdf?alt=media&token=e9f0d18c-09eb-4734-a49f-65adeaf165c9",
          fileExt: ".pdf",
          fileName: "AL and Comprehensive Home Care Instructions - Vulnerable Adult.pdf",
          keywords: ['Vulnerable Adult', 'v3'],
          tags: [{id: "al01bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Assisted Living'},{ id: "c201bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Home Care'}]
        }
      ]
    },

    {
      name: "Assisted Living",
      type: "dir", open: false, count: -1,
      id: "al01bea9-8b2a-45cf-bce7-6e86ac501c59",
      path: "AssistedLiving",
      items: []
    },

    {
      name: "Senior Living",
      type: "dir", open: false, count: -1,
      id: "sl01bea9-8b2a-45cf-bce7-6e86ac501c59",
      path: "SeniorLiving",
      items: [
        {
          name: "Adult Day Centers - Vulnerable Adult Policy",
          type: "unknown",
          id: "b2afa0b9-4342-4d04-b112-8bafd3b93896",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ftodelete%2FAdult%20Day%20Centers%20-%20Vunerable%20Adult%20Policy.docx?alt=media&token=c7f90abb-6750-47d7-aaf3-43cd3ce4b612",
          fileExt: ".docx",
          fileName: "Adult Day Centers - Vulnerable Adult Policy.docx",
          keywords: ['Vulnerable Adult', 'v2'],
          tags: [{id: "sl01bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Senior Living'}]
        },
        {
          name: "Adult Day Centers - Instructions - Vulnerable Adult",
          type: "unknown",
          id: "b3afa0b9-4342-4d04-b112-8bafd3b93896",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2Fr2%2FAdult%20Day%20Centers%20-%20instructions%20-%20Vulnerable%20Adult.pdf?alt=media&token=6c709dd3-811a-450e-90a4-7d755fa35e28",
          fileExt: ".pdf",
          fileName: "Adult Day Centers - Instructions - Vulnerable Adult.pdf",
          keywords: ['Vulnerable Adult', 'v3'],
          tags: [{id: "sl01bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Senior Living'}]
        }
      ]
    },

    {
      name: "Hospice Care",
      type: "dir", open: false, count: -1,
      id: "hc01bea9-8b2a-45cf-bce7-6e86ac501c59",
      path: "HospiceCare",
      items: [
        {
          name: "Medicare Certified Home Care and Hospice - Vulnerable Adult Policy",
          type: "unknown",
          id: "b2afa0b9-4342-4d04-b112-8bafd3b93896",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ftodelete%2FMedicare%20Certified%20Home%20Care%20and%20Hospice%20-%20Vunerable%20Adult%20Policy.docx?alt=media&token=256226ea-50f9-42a6-a018-c906af68adcb",
          fileExt: ".docx",
          fileName: "Medicare Certified Home Care and Hospice - Vulnerable Adult Policy.docx",
          keywords: ['Vulnerable Adult', 'v2'],
          tags: [{id: "hc01bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Hospice Care'},{ id: "c201bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Home Care'}]
        }, 

        {
          name: "Medicare Certified Home Care and Hospice Instructions - Vulnerable Adult",
          type: "unknown",
          id: "brnfa0b9-4342-4d04-b112-8bafd3b93896",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2Fr2%2FMedicare%20Certified%20Home%20Care%20and%20Hospice%20instructions%20-%20Vulnerable%20Adult.pdf?alt=media&token=b2e9fe56-0268-4de3-9043-0f12f47ff8a7",
          fileExt: ".pdf",
          fileName: "Medicare Certified Home Care and Hospice instructions - Vulnerable Adult.docx",
          keywords: ['Vulnerable Adult', 'v3'],
          tags: [{id: "hc01bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Hospice Care'},{ id: "c201bea9-8b2a-45cf-bce7-6e86ac501c59", name: 'Home Care'}]
        }
      ]
    }

  ]
}

const Buildings = {
  name: "Buildings",
  type: "dir", open: false, count: -1,
  id: "c2020ea9-8b2a-45cf-bce7-6e86ac501c412",
  path: "Buildings",
  items: [
    {
      "name": "Bentson Family Assisted Living",
      type: "dir", open: false, count: -1,
      "id": "6b655419-8432-4173-a54e-cdd468411a02j",
      "path": "TODO",
      items: []
    },

    {
      "name": "David Feinberg Vitality & Aquatics Center",
      type: "dir", open: false, count: -1,
      "id": "6b655419-8432-4173-a54e-cdd468411a03i",
      "path": "TODO",
      items: []
    },

    {
      "name": "Harry & Jeanette Weinberg Apartments",
      type: "dir", open: false,
      "id": "6b655419-8432-4173-a54e-cdd468411a04h",
      "path": "TODO",
      items: []
    },

    {
      "name": "Knollwood Place Apartments",
      type: "dir", open: false,
      "id": "6b655419-8432-4173-a54e-cdd468411a05g",
      "path": "TODO",
      items: []
    },

    {
      "name": "Leo and Doris Hodroff Pavilion for Memory Care",
      type: "dir", open: false,
      "id": "6b655419-8432-4173-a54e-cdd468411a06f",
      "path": "TODO",
      items: []
    },

    {
      "name": "Menorah West",
      type: "dir", open: false,
      "id": "6b655419-8432-4173-a54e-cdd468411a07e",
      "path": "TODO",
      items: []
    },

    {
      "name": "Phillips Center for Memory Care",
      type: "dir", open: false,
      "id": "6b655419-8432-4173-a54e-cdd468411a22d",
      "path": "TODO",
      items: []
    },

    {
      "name": "Roitenberg Family Assisted Living",
      type: "dir", open: false,
      "id": "6b655419-8432-4173-a54e-cdd468411a09c",
      "path": "TODO",
      items: []
    },

    

    {
      "name": "Shirley Chapman Sholom Home East",
      type: "dir", open: false,
      "id": "6b655419-8432-4173-a54e-cdd468411a11a",
      "path": "TODO",
      items: []
    },

    {
      "name": "Sholom Home West",
      type: "dir", open: false,
      "id": "6b655419-8432-4173-a54e-cdd468411a12z",
      "path": "TODO",
      items: []
    },
  ]
}

const Regulations = {
  "name": "Regulations",
  type: "dir", open: false, count: -1,
  "id": "c2020ea9-8b2a-45cf-bce7-6e86ac501c59",
  "path": "Regulations",
  "items": [
      {
        "name": "Federal",
        "type": "dir",
        "id": "6b655419-8432-4173-a54e-cdd4684117d4",
        "path": "Regulations/Federal",
        "items": [
          {
            "name": "CMS 2012 Life Safety Code",
            "type": "reg",
            "id": "01afa0b9-4342-4d04-b112-8bafd3b93896",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F01afa0b9-4342-4d04-b112-8bafd3b93896.pdf?alt=media&token=2fc16e6d-3b30-4557-bafe-27d1c2e98ac3",
            "fileExt": ".pdf",
            "fileName": "CMS 2012 Life Safety Code.pdf",
            "keywords": [
              "regulations"
            ],
            "isBright": true
          },
          {
            "name": "State Ops Manual Appendix 1 - Life Safety",
            "type": "reg",
            "id": "5f35b40d-f3ca-4751-b571-9ba6c12f39e0",
            "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5f35b40d-f3ca-4751-b571-9ba6c12f39e0.pdf?alt=media&token=dfea6242-e8a5-4b71-9871-b0f720e8071c",
            "fileExt": ".pdf",
            "fileName": "State Ops Manual Appendix 1 - Life Safety.pdf",
            "keywords": [
              "regulations"
            ],
            "isBright": true
          }
        ]
      },
      {
        "name": "State",
        "type": "dir",
        "id": "6b655419-8432-4173-a54e-cdd4684117d2",
        "path": "Regulations/State",
        "items": [
          {
            "name": "MN",
            "type": "dir",
            "id": "92188dd2-085a-4add-8797-9fa3c2bce66e",
            "path": "Regulations/State/MN",
            "items": [
              {
                "name": "144A. NURSING HOMES AND HOME CARE",
                "type": "dir",
                "id": "2d9026f5-77bb-47cc-b3bb-fa92b99b2079",
                "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE",
                "items": [
                  {
                    "name": "Board of Executives for Long Term Services and Supports",
                    "type": "dir",
                    "id": "2785b3cc-72a4-406a-adde-8e9682b5779e",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Board of Executives for Long Term Services and Supports",
                    "items": [
                      {
                        "name": "144A.19 BOARD OF EXECUTIVES FOR LONG TERM SERVICES AND SUPPORTS",
                        "type": "reg",
                        "id": "51ac0760-372e-410d-80e0-10acbaab7542",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F51ac0760-372e-410d-80e0-10acbaab7542.pdf?alt=media&token=72556349-6582-418e-8b7d-862a28dc603e",
                        "fileExt": ".pdf",
                        "fileName": "144A.19 BOARD OF EXECUTIVES FOR LONG TERM SERVICES AND SUPPORTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.20 ADMINISTRATOR QUALIFICATIONS",
                        "type": "reg",
                        "id": "02fdb8d7-517d-4df3-ba64-a060278c4339",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F02fdb8d7-517d-4df3-ba64-a060278c4339.pdf?alt=media&token=25958503-e468-414b-aef2-c9c8cd14959a",
                        "fileExt": ".pdf",
                        "fileName": "144A.20 ADMINISTRATOR QUALIFICATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.21 ADMINISTRATOR AND DIRECTOR LICENSES",
                        "type": "reg",
                        "id": "b869cbb5-e5ed-4aea-b0fd-0f8dd0c703e1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb869cbb5-e5ed-4aea-b0fd-0f8dd0c703e1.pdf?alt=media&token=be6c0ad0-6a75-4b2b-b443-8d9ec0de5ff4",
                        "fileExt": ".pdf",
                        "fileName": "144A.21 ADMINISTRATOR AND DIRECTOR LICENSES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.22 ORGANIZATION OF BOARD",
                        "type": "reg",
                        "id": "bbe06df4-0819-406a-9386-9b93eedbd3a7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbbe06df4-0819-406a-9386-9b93eedbd3a7.pdf?alt=media&token=e9f15d02-93cc-4096-940c-72116e2c9aed",
                        "fileExt": ".pdf",
                        "fileName": "144A.22 ORGANIZATION OF BOARD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.23 JURISDICTION OF BOARD",
                        "type": "reg",
                        "id": "588359a8-8b74-4e7c-bdd0-d378c9778d3a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F588359a8-8b74-4e7c-bdd0-d378c9778d3a.pdf?alt=media&token=cd3cddac-3176-416e-9ec6-822027bfafe2",
                        "fileExt": ".pdf",
                        "fileName": "144A.23 JURISDICTION OF BOARD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.24 DUTIES OF THE BOARD",
                        "type": "reg",
                        "id": "c44b64f6-449a-4050-83b4-f03df49fb12f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc44b64f6-449a-4050-83b4-f03df49fb12f.pdf?alt=media&token=b34ed1f4-8742-4828-b745-13f14ccde133",
                        "fileExt": ".pdf",
                        "fileName": "144A.24 DUTIES OF THE BOARD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.251 MANDATORY PROCEEDINGS",
                        "type": "reg",
                        "id": "b380347f-80d9-4147-943b-82d548d3f76b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb380347f-80d9-4147-943b-82d548d3f76b.pdf?alt=media&token=338354f4-638e-4a36-8a61-c27b06b43e1b",
                        "fileExt": ".pdf",
                        "fileName": "144A.251 MANDATORY PROCEEDINGS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.2511 COSTS; PENALTIES",
                        "type": "reg",
                        "id": "31a4332f-a0de-4554-90b6-8f170b3fc0b0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F31a4332f-a0de-4554-90b6-8f170b3fc0b0.pdf?alt=media&token=48a6ec2c-f39d-44c2-8763-fecae7260d7a",
                        "fileExt": ".pdf",
                        "fileName": "144A.2511 COSTS; PENALTIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.252 IMMUNITY",
                        "type": "reg",
                        "id": "02ab62c2-af80-4835-84da-45e5a12bb637",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F02ab62c2-af80-4835-84da-45e5a12bb637.pdf?alt=media&token=8ec11918-2688-4406-ae2f-fd499d900aee",
                        "fileExt": ".pdf",
                        "fileName": "144A.252 IMMUNITY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.26 RECIPROCITY WITH OTHER STATES AND EQUIVALENCY OF HEALTH SERVICES",
                        "type": "reg",
                        "id": "b6011aea-29e8-4466-8834-cc4115e43296",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb6011aea-29e8-4466-8834-cc4115e43296.pdf?alt=media&token=e7222900-0f07-4407-9884-5b3c491fa79c",
                        "fileExt": ".pdf",
                        "fileName": "144A.26 RECIPROCITY WITH OTHER STATES AND EQUIVALENCY OF HEALTH SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.27 ACTING ADMINISTRATORS",
                        "type": "reg",
                        "id": "8c0e4765-ca01-40fe-9916-040a28b0950f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8c0e4765-ca01-40fe-9916-040a28b0950f.pdf?alt=media&token=05aa900f-457f-467d-a74e-faa35de0637b",
                        "fileExt": ".pdf",
                        "fileName": "144A.27 ACTING ADMINISTRATORS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.28 SEVERABILITY",
                        "type": "reg",
                        "id": "931a9131-9e52-4ed6-aac2-910721bdbc95",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F931a9131-9e52-4ed6-aac2-910721bdbc95.pdf?alt=media&token=2e2852a0-cca0-48ca-a637-a52d839631ec",
                        "fileExt": ".pdf",
                        "fileName": "144A.28 SEVERABILITY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.291 FEES",
                        "type": "reg",
                        "id": "e2be3ca1-e92e-4f82-9249-e3ee54b2c0d2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe2be3ca1-e92e-4f82-9249-e3ee54b2c0d2.pdf?alt=media&token=68a643a8-da84-49d1-8e92-18cef0a3f652",
                        "fileExt": ".pdf",
                        "fileName": "144A.291 FEES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Health Care Facility Grievances",
                    "type": "dir",
                    "id": "a6d443d0-aa68-4a6d-9791-4ceb14b6ea07",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Health Care Facility Grievances",
                    "items": [
                      {
                        "name": "144A.51 DEFINITIONS",
                        "type": "reg",
                        "id": "d0888ffd-c8cf-4d24-8dcc-3f40d62b52c9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd0888ffd-c8cf-4d24-8dcc-3f40d62b52c9.pdf?alt=media&token=da50aacc-9cdc-40b9-b01f-860c2998f725",
                        "fileExt": ".pdf",
                        "fileName": "144A.51 DEFINITIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.52 OFFICE OF HEALTH FACILITY COMPLAINTS",
                        "type": "reg",
                        "id": "4c289542-8be7-4e96-8367-c0f73c3ef986",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4c289542-8be7-4e96-8367-c0f73c3ef986.pdf?alt=media&token=0c84dbf0-38f4-4d8f-a094-cc348df24ae4",
                        "fileExt": ".pdf",
                        "fileName": "144A.52 OFFICE OF HEALTH FACILITY COMPLAINTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.53 DIRECTOR; POWERS AND DUTIES",
                        "type": "reg",
                        "id": "7dc8a55a-18d4-4486-bdd4-1fe2de8d1235",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7dc8a55a-18d4-4486-bdd4-1fe2de8d1235.pdf?alt=media&token=5d45d449-4911-4373-9b23-e199328b48f3",
                        "fileExt": ".pdf",
                        "fileName": "144A.53 DIRECTOR; POWERS AND DUTIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.54 PUBLICATION OF RECOMMENDATIONS; REPORTS",
                        "type": "reg",
                        "id": "d2cc7f85-6733-474f-b92e-fcdc242fba55",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd2cc7f85-6733-474f-b92e-fcdc242fba55.pdf?alt=media&token=a91bfa85-ace2-479d-85fb-c1a9812ef9c5",
                        "fileExt": ".pdf",
                        "fileName": "144A.54 PUBLICATION OF RECOMMENDATIONS; REPORTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Home and Community-Based Services Designation",
                    "type": "dir",
                    "id": "4c19ee9b-3c48-457e-b1ab-01e74c5ad790",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Home and Community-Based Services Designation",
                    "items": [
                      {
                        "name": "144A.484 INTEGRATED LICENSURE; HOME AND COMMUNITY-BASED SERVICES",
                        "type": "reg",
                        "id": "3e4150cb-a97d-4958-81e6-8e045be27d34",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3e4150cb-a97d-4958-81e6-8e045be27d34.pdf?alt=media&token=cc4cdfa3-d72a-47ec-beca-4b4324c65e2f",
                        "fileExt": ".pdf",
                        "fileName": "144A.484 INTEGRATED LICENSURE; HOME AND COMMUNITY-BASED SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Home Care Licensing",
                    "type": "dir",
                    "id": "1c33136f-2a30-41c4-a3c6-a3859c396682",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Home Care Licensing",
                    "items": [
                      {
                        "name": "144A.471 HOME CARE PROVIDER AND HOME CARE SERVICES",
                        "type": "reg",
                        "id": "ffd8fcef-bfa0-445b-8e1b-59644f7c5d40",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fffd8fcef-bfa0-445b-8e1b-59644f7c5d40.pdf?alt=media&token=2e64d6ac-ecbc-4afd-ad85-920fa75fd0ad",
                        "fileExt": ".pdf",
                        "fileName": "144A.471 HOME CARE PROVIDER AND HOME CARE SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.472 HOME CARE PROVIDER LICENSE; APPLICATION AND RENEWAL",
                        "type": "reg",
                        "id": "130024c9-2c26-4172-819f-1681859d1d83",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F130024c9-2c26-4172-819f-1681859d1d83.pdf?alt=media&token=fc5a0eef-ed53-4263-9417-cffb200ad602",
                        "fileExt": ".pdf",
                        "fileName": "144A.472 HOME CARE PROVIDER LICENSE; APPLICATION AND RENEWAL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.473 ISSUANCE OF TEMPORARY LICENSE AND LICENSE RENEWAL",
                        "type": "reg",
                        "id": "d151be2e-7913-4f05-992c-b0046a82800a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd151be2e-7913-4f05-992c-b0046a82800a.pdf?alt=media&token=d6a91acc-1801-44e3-871a-26f6ab313786",
                        "fileExt": ".pdf",
                        "fileName": "144A.473 ISSUANCE OF TEMPORARY LICENSE AND LICENSE RENEWAL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.474 SURVEYS AND INVESTIGATIONS",
                        "type": "reg",
                        "id": "58cc464f-1f89-42a0-91fa-5973bb914602",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F58cc464f-1f89-42a0-91fa-5973bb914602.pdf?alt=media&token=0cc24cba-80a3-4f10-bc94-29b781eada35",
                        "fileExt": ".pdf",
                        "fileName": "144A.474 SURVEYS AND INVESTIGATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.475 ENFORCEMENT",
                        "type": "reg",
                        "id": "803c776f-f013-42ea-9320-2567dd74b49d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F803c776f-f013-42ea-9320-2567dd74b49d.pdf?alt=media&token=854b34f6-8053-4543-b652-97f89ca270eb",
                        "fileExt": ".pdf",
                        "fileName": "144A.475 ENFORCEMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.476 BACKGROUND STUDIES",
                        "type": "reg",
                        "id": "73cf2988-455a-49ed-a047-4dbab72ec381",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F73cf2988-455a-49ed-a047-4dbab72ec381.pdf?alt=media&token=56c0c3e5-f734-4dc3-a6e6-4a8f18a68a6d",
                        "fileExt": ".pdf",
                        "fileName": "144A.476 BACKGROUND STUDIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.477 COMPLIANCE",
                        "type": "reg",
                        "id": "af56c69c-ea32-45e5-b500-4bd2edb5dac7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Faf56c69c-ea32-45e5-b500-4bd2edb5dac7.pdf?alt=media&token=af814018-fe51-4d85-9fba-0a393ca0f88b",
                        "fileExt": ".pdf",
                        "fileName": "144A.477 COMPLIANCE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.478 INNOVATION VARIANCE",
                        "type": "reg",
                        "id": "956e1131-014c-44dc-9096-673f0f97a913",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F956e1131-014c-44dc-9096-673f0f97a913.pdf?alt=media&token=6242b101-aa74-48b9-bdff-6918b0428095",
                        "fileExt": ".pdf",
                        "fileName": "144A.478 INNOVATION VARIANCE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.479 HOME CARE PROVIDER RESPONSIBILITIES; BUSINESS OPERATION",
                        "type": "reg",
                        "id": "ccad3e0a-0ef2-4595-8e15-69d9623c26df",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fccad3e0a-0ef2-4595-8e15-69d9623c26df.pdf?alt=media&token=fb624c94-c5b5-45e1-8c5b-51608d5b6bee",
                        "fileExt": ".pdf",
                        "fileName": "144A.479 HOME CARE PROVIDER RESPONSIBILITIES; BUSINESS OPERATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4791 HOME CARE PROVIDER RESPONSIBILITIES WITH RESPECT TO CLIENTS",
                        "type": "reg",
                        "id": "506ea49d-df38-43ff-96a7-5bae714990af",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F506ea49d-df38-43ff-96a7-5bae714990af.pdf?alt=media&token=4e8d810b-0aec-4fde-acfc-3fc23bc5ab6e",
                        "fileExt": ".pdf",
                        "fileName": "144A.4791 HOME CARE PROVIDER RESPONSIBILITIES WITH RESPECT TO CLIENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4792 MEDICATION MANAGEMENT",
                        "type": "reg",
                        "id": "951028fc-4a23-4314-a6b5-d9b99fc14f76",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F951028fc-4a23-4314-a6b5-d9b99fc14f76.pdf?alt=media&token=c7debcb3-641c-4bae-a51a-57f452abaf41",
                        "fileExt": ".pdf",
                        "fileName": "144A.4792 MEDICATION MANAGEMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4793 TREATMENT AND THERAPY MANAGEMENT SERVICES",
                        "type": "reg",
                        "id": "c88476f8-8fe9-487c-a4d1-18de1529d8c1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc88476f8-8fe9-487c-a4d1-18de1529d8c1.pdf?alt=media&token=d9f196f2-f7fc-4b6d-8452-5f555008e666",
                        "fileExt": ".pdf",
                        "fileName": "144A.4793 TREATMENT AND THERAPY MANAGEMENT SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4794 CLIENT RECORD REQUIREMENTS",
                        "type": "reg",
                        "id": "b33238f2-94c6-45e1-afbd-e2e402456a52",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb33238f2-94c6-45e1-afbd-e2e402456a52.pdf?alt=media&token=ba821aa0-5499-445f-a7a7-0b284c4939d6",
                        "fileExt": ".pdf",
                        "fileName": "144A.4794 CLIENT RECORD REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4795 HOME CARE PROVIDER RESPONSIBILITIES; STAFF",
                        "type": "reg",
                        "id": "32890bb3-2967-43fe-aca4-75099db3250e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F32890bb3-2967-43fe-aca4-75099db3250e.pdf?alt=media&token=47ca1a6d-35b6-4fc8-b415-b102f6c2482e",
                        "fileExt": ".pdf",
                        "fileName": "144A.4795 HOME CARE PROVIDER RESPONSIBILITIES; STAFF.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4796 ORIENTATION AND ANNUAL TRAINING REQUIREMENTS",
                        "type": "reg",
                        "id": "5618cf33-a7f5-4603-aa52-e0b5a316d4e4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5618cf33-a7f5-4603-aa52-e0b5a316d4e4.pdf?alt=media&token=64e81c35-aa95-417a-a251-c7bc4027b1dd",
                        "fileExt": ".pdf",
                        "fileName": "144A.4796 ORIENTATION AND ANNUAL TRAINING REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4797 PROVISION OF SERVICES",
                        "type": "reg",
                        "id": "cfa80533-064d-49e2-91f5-24e5eeac951a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcfa80533-064d-49e2-91f5-24e5eeac951a.pdf?alt=media&token=66678e6f-7a44-4d7f-b310-80f92fc4cc85",
                        "fileExt": ".pdf",
                        "fileName": "144A.4797 PROVISION OF SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4798 DISEASE PREVENTION AND INFECTION CONTROL",
                        "type": "reg",
                        "id": "721a9c5c-0921-43ad-b7f1-8e1e41255f1d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F721a9c5c-0921-43ad-b7f1-8e1e41255f1d.pdf?alt=media&token=9147fa86-8389-41f6-a658-7229e3f6dacd",
                        "fileExt": ".pdf",
                        "fileName": "144A.4798 DISEASE PREVENTION AND INFECTION CONTROL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.4799 DEPARTMENT OF HEALTH LICENSED HOME CARE PROVIDER ADVISORY",
                        "type": "reg",
                        "id": "46169546-dd25-4ea1-b18a-9f227ee52d3a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F46169546-dd25-4ea1-b18a-9f227ee52d3a.pdf?alt=media&token=dec2dc65-07dc-44a5-b239-75c9bc4650be",
                        "fileExt": ".pdf",
                        "fileName": "144A.4799 DEPARTMENT OF HEALTH LICENSED HOME CARE PROVIDER ADVISORY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.482 REGISTRATION OF HOME MANAGEMENT PROVIDERS",
                        "type": "reg",
                        "id": "c51cf09a-d155-447d-bb8d-9a86a1f2befc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc51cf09a-d155-447d-bb8d-9a86a1f2befc.pdf?alt=media&token=0b504640-e00d-4c47-a92b-8668b1811a7c",
                        "fileExt": ".pdf",
                        "fileName": "144A.482 REGISTRATION OF HOME MANAGEMENT PROVIDERS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.483 AGENCY QUALITY IMPROVEMENT PROGRAM",
                        "type": "reg",
                        "id": "f572ef22-b371-462e-8951-4aa4369f0204",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff572ef22-b371-462e-8951-4aa4369f0204.pdf?alt=media&token=b176f8a2-fd9a-4a57-8e81-6725720c521b",
                        "fileExt": ".pdf",
                        "fileName": "144A.483 AGENCY QUALITY IMPROVEMENT PROGRAM.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Home Care Program",
                    "type": "dir",
                    "id": "94d35b4d-b1fe-4f14-8754-5a1cf5f279ef",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Home Care Program",
                    "items": [
                      {
                        "name": "144A.43 DEFINITIONS",
                        "type": "reg",
                        "id": "7ee224f9-7c4c-43bd-9d70-8e5789d1b5e7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7ee224f9-7c4c-43bd-9d70-8e5789d1b5e7.pdf?alt=media&token=be5dbfcd-bf17-405f-9531-09d7b5f8ce38",
                        "fileExt": ".pdf",
                        "fileName": "144A.43 DEFINITIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.44 HOME CARE BILL OF RIGHTS",
                        "type": "reg",
                        "id": "53eb0fc9-85cd-487d-b83f-e7ec155ad807",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F53eb0fc9-85cd-487d-b83f-e7ec155ad807.pdf?alt=media&token=5e93f6e7-1f12-4281-b7da-f066b80cc39d",
                        "fileExt": ".pdf",
                        "fileName": "144A.44 HOME CARE BILL OF RIGHTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.441 MS 2020 [Repealed, 2019 c 60 art 4 s 35]",
                        "type": "reg",
                        "id": "ecc30761-10ea-4367-8f91-9454224b092d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fecc30761-10ea-4367-8f91-9454224b092d.pdf?alt=media&token=d515697e-5e87-4843-af5b-0e15e0ddf799",
                        "fileExt": ".pdf",
                        "fileName": "144A.441 MS 2020 [Repealed, 2019 c 60 art 4 s 35].pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.442 MS 2020 [Repealed, 2019 c 60 art 4 s 35]",
                        "type": "reg",
                        "id": "04b7ea96-41c1-4f4e-b582-fd12460be1a7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F04b7ea96-41c1-4f4e-b582-fd12460be1a7.pdf?alt=media&token=ead1f741-f16c-4eb2-9e48-207c4fba388b",
                        "fileExt": ".pdf",
                        "fileName": "144A.442 MS 2020 [Repealed, 2019 c 60 art 4 s 35].pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.45 REGULATION OF HOME CARE SERVICES",
                        "type": "reg",
                        "id": "baa1315d-7508-4330-b41d-a02b32a415b8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbaa1315d-7508-4330-b41d-a02b32a415b8.pdf?alt=media&token=ab0b3028-6c19-4ca5-966b-fa63294488d7",
                        "fileExt": ".pdf",
                        "fileName": "144A.45 REGULATION OF HOME CARE SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.47 INFORMATION AND REFERRAL SERVICES",
                        "type": "reg",
                        "id": "299fd46c-9244-4964-b1f4-71d84382afff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F299fd46c-9244-4964-b1f4-71d84382afff.pdf?alt=media&token=d5e6f805-45f1-4cd4-b922-6e9cb7dc8e8b",
                        "fileExt": ".pdf",
                        "fileName": "144A.47 INFORMATION AND REFERRAL SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Hospice Care Licensing",
                    "type": "dir",
                    "id": "71885070-818b-4196-a85b-f4b33e3c6a25",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Hospice Care Licensing",
                    "items": [
                      {
                        "name": "144A.75 DEFINITIONS; SERVICE REQUIREMENTS",
                        "type": "reg",
                        "id": "5fca4e97-3ff8-457a-80ee-eec2a64723c4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5fca4e97-3ff8-457a-80ee-eec2a64723c4.pdf?alt=media&token=0f03b68d-3b72-4a01-be0e-4229493adfd1",
                        "fileExt": ".pdf",
                        "fileName": "144A.75 DEFINITIONS; SERVICE REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.751 HOSPICE BILL OF RIGHTS",
                        "type": "reg",
                        "id": "e0c11b4e-8f7d-44ab-a27d-9e90bff5b9c8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe0c11b4e-8f7d-44ab-a27d-9e90bff5b9c8.pdf?alt=media&token=77a3e0c6-903d-4afe-bd0e-b1dfcd76c2dc",
                        "fileExt": ".pdf",
                        "fileName": "144A.751 HOSPICE BILL OF RIGHTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.752 REGULATION OF HOSPICE CARE",
                        "type": "reg",
                        "id": "b00fc346-d908-47f3-9597-6cbb4ffa9f40",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb00fc346-d908-47f3-9597-6cbb4ffa9f40.pdf?alt=media&token=4de51e0e-c850-4725-b28c-9003f42cd128",
                        "fileExt": ".pdf",
                        "fileName": "144A.752 REGULATION OF HOSPICE CARE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.753 LICENSURE",
                        "type": "reg",
                        "id": "1ddb3a9d-d12e-4813-827d-ccf916f93363",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1ddb3a9d-d12e-4813-827d-ccf916f93363.pdf?alt=media&token=52f0a59f-4626-48f8-a77b-0111c79c1f69",
                        "fileExt": ".pdf",
                        "fileName": "144A.753 LICENSURE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.754 ENFORCEMENT",
                        "type": "reg",
                        "id": "03934426-e1b8-48ba-addb-a67207127588",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F03934426-e1b8-48ba-addb-a67207127588.pdf?alt=media&token=b985bbe4-f4b1-4dd3-8e66-14b58ef51ee8",
                        "fileExt": ".pdf",
                        "fileName": "144A.754 ENFORCEMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.755 INFORMATION AND REFERRAL SERVICES",
                        "type": "reg",
                        "id": "9c40a660-57f0-484b-8339-58b4d6cf1526",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9c40a660-57f0-484b-8339-58b4d6cf1526.pdf?alt=media&token=980fdba1-be26-4c78-a78f-dffd0cfd7d60",
                        "fileExt": ".pdf",
                        "fileName": "144A.755 INFORMATION AND REFERRAL SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.756 PENALTY",
                        "type": "reg",
                        "id": "5245cd6d-d7c9-40a3-892c-f4775e958ff9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5245cd6d-d7c9-40a3-892c-f4775e958ff9.pdf?alt=media&token=3bf31d42-deb4-457e-9279-7396ed7b02f9",
                        "fileExt": ".pdf",
                        "fileName": "144A.756 PENALTY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Nursing Assistants",
                    "type": "dir",
                    "id": "2897e2af-05c0-45a4-bfbd-531863ec9a81",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Nursing Assistants",
                    "items": [
                      {
                        "name": "144A.61 NURSING ASSISTANT TRAINING",
                        "type": "reg",
                        "id": "63054662-38ea-41bc-83e7-891988e5e43c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F63054662-38ea-41bc-83e7-891988e5e43c.pdf?alt=media&token=f918e543-bc67-4ae1-99be-e33dc94c7598",
                        "fileExt": ".pdf",
                        "fileName": "144A.61 NURSING ASSISTANT TRAINING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.611 REIMBURSABLE EXPENSES FOR NURSING ASSISTANT TRAINING AND",
                        "type": "reg",
                        "id": "f7ebced1-a8f9-4f58-9d12-4a7d1b0ea716",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff7ebced1-a8f9-4f58-9d12-4a7d1b0ea716.pdf?alt=media&token=7f336535-779d-48e5-93d4-207d83fa7d41",
                        "fileExt": ".pdf",
                        "fileName": "144A.611 REIMBURSABLE EXPENSES FOR NURSING ASSISTANT TRAINING AND.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.62 RESIDENT ATTENDANTS",
                        "type": "reg",
                        "id": "01f3da6b-a978-457a-9bfb-4b5aef0101b4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F01f3da6b-a978-457a-9bfb-4b5aef0101b4.pdf?alt=media&token=b13fb00f-d7cd-4d34-b11d-6995131003c4",
                        "fileExt": ".pdf",
                        "fileName": "144A.62 RESIDENT ATTENDANTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Nursing Homes and Home Care",
                    "type": "dir",
                    "id": "c5161f8a-c9a2-4c16-a24a-3caa5a48de9f",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Nursing Homes and Home Care",
                    "items": [
                      {
                        "name": "144A.01 DEFINITIONS",
                        "type": "reg",
                        "id": "e5c560a0-8d5e-4ca9-a04c-a005dedb8ee5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe5c560a0-8d5e-4ca9-a04c-a005dedb8ee5.pdf?alt=media&token=1258e192-38f3-4aac-be18-6c9750b4a1b4",
                        "fileExt": ".pdf",
                        "fileName": "144A.01 DEFINITIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.02 LICENSURE; PENALTY",
                        "type": "reg",
                        "id": "a1303577-c6fa-4933-bd5e-14c98e9a4f8a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa1303577-c6fa-4933-bd5e-14c98e9a4f8a.pdf?alt=media&token=47ee2c9c-7b71-4253-b196-dcc349313a87",
                        "fileExt": ".pdf",
                        "fileName": "144A.02 LICENSURE; PENALTY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.03 LICENSE APPLICATION",
                        "type": "reg",
                        "id": "dd434f84-8d95-40a4-afa6-b2d8d56f7228",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdd434f84-8d95-40a4-afa6-b2d8d56f7228.pdf?alt=media&token=eda05e1d-0958-4b2b-aac2-b6b5b8b3cb69",
                        "fileExt": ".pdf",
                        "fileName": "144A.03 LICENSE APPLICATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.031 CONSIDERATION OF APPLICATIONS",
                        "type": "reg",
                        "id": "7db671a9-1d58-4a8e-943e-5ac01f16a5fb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7db671a9-1d58-4a8e-943e-5ac01f16a5fb.pdf?alt=media&token=a706b7c3-602b-48be-8d95-58aa0b9d5315",
                        "fileExt": ".pdf",
                        "fileName": "144A.031 CONSIDERATION OF APPLICATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.04 QUALIFICATIONS FOR LICENSE",
                        "type": "reg",
                        "id": "d1b8d972-bde0-4de3-8508-71f6dc9bfe73",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd1b8d972-bde0-4de3-8508-71f6dc9bfe73.pdf?alt=media&token=483a716c-fbfe-4ff7-9050-5459acae96c7",
                        "fileExt": ".pdf",
                        "fileName": "144A.04 QUALIFICATIONS FOR LICENSE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.05 LICENSE RENEWAL",
                        "type": "reg",
                        "id": "a4cd3d77-1786-42b8-a8b6-58889f40eb2b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa4cd3d77-1786-42b8-a8b6-58889f40eb2b.pdf?alt=media&token=2cba3b38-b62b-4627-9b55-b6ba283ea029",
                        "fileExt": ".pdf",
                        "fileName": "144A.05 LICENSE RENEWAL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.06 TRANSFER OF LICENSE PROHIBITED",
                        "type": "reg",
                        "id": "7382dbe8-ffa5-459e-8563-b1166870d1d3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7382dbe8-ffa5-459e-8563-b1166870d1d3.pdf?alt=media&token=765cb39f-fb16-4af9-8c79-79a44270aa1b",
                        "fileExt": ".pdf",
                        "fileName": "144A.06 TRANSFER OF LICENSE PROHIBITED.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.07 FEES",
                        "type": "reg",
                        "id": "3993c26e-b4ed-4fcf-b4e0-0a3e9cd87ac1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3993c26e-b4ed-4fcf-b4e0-0a3e9cd87ac1.pdf?alt=media&token=13a40ec7-ec93-4284-a678-99258f62ad27",
                        "fileExt": ".pdf",
                        "fileName": "144A.07 FEES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.071 MORATORIUM ON CERTIFICATION OF NURSING HOME BEDS",
                        "type": "reg",
                        "id": "76232752-2790-4297-b8de-b9107fc72855",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F76232752-2790-4297-b8de-b9107fc72855.pdf?alt=media&token=0e5ffa6e-7287-40aa-acd1-249f8845e635",
                        "fileExt": ".pdf",
                        "fileName": "144A.071 MORATORIUM ON CERTIFICATION OF NURSING HOME BEDS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.073 EXCEPTIONS TO MORATORIUM; REVIEW",
                        "type": "reg",
                        "id": "80b6b0ea-2804-42fa-b95f-8b0ec8c09c83",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F80b6b0ea-2804-42fa-b95f-8b0ec8c09c83.pdf?alt=media&token=b4f4b83e-78dc-4a65-8e46-89cdebc7eafc",
                        "fileExt": ".pdf",
                        "fileName": "144A.073 EXCEPTIONS TO MORATORIUM; REVIEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.08 PHYSICAL STANDARDS; PENALTY",
                        "type": "reg",
                        "id": "2608694f-bbf6-406f-a6a8-011fe72eabf2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2608694f-bbf6-406f-a6a8-011fe72eabf2.pdf?alt=media&token=06757ebf-a0d0-4df3-b266-e2030bbe08d0",
                        "fileExt": ".pdf",
                        "fileName": "144A.08 PHYSICAL STANDARDS; PENALTY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.09 FACILITIES EXCLUDED",
                        "type": "reg",
                        "id": "8eaae42a-5807-4e2d-87c8-73dd8bd9fb42",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8eaae42a-5807-4e2d-87c8-73dd8bd9fb42.pdf?alt=media&token=1e9320f5-f687-446e-b549-d98be542a882",
                        "fileExt": ".pdf",
                        "fileName": "144A.09 FACILITIES EXCLUDED.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.10 INSPECTION; COMMISSIONER OF HEALTH; FINES",
                        "type": "reg",
                        "id": "0c851c32-5650-4924-ba79-0bbd0cdaef4d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0c851c32-5650-4924-ba79-0bbd0cdaef4d.pdf?alt=media&token=14966353-ee56-4920-9d83-ccd5ee85b5d6",
                        "fileExt": ".pdf",
                        "fileName": "144A.10 INSPECTION; COMMISSIONER OF HEALTH; FINES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.101 PROCEDURES FOR FEDERALLY REQUIRED SURVEY PROCESS",
                        "type": "reg",
                        "id": "f8a36fec-7518-45f3-92de-47ab484e056a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff8a36fec-7518-45f3-92de-47ab484e056a.pdf?alt=media&token=0b33cce5-0dc6-4fba-87c5-c93c58aec894",
                        "fileExt": ".pdf",
                        "fileName": "144A.101 PROCEDURES FOR FEDERALLY REQUIRED SURVEY PROCESS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.102 WAIVER FROM FEDERAL RULES AND REGULATIONS; PENALTIES",
                        "type": "reg",
                        "id": "ef3ca07a-f310-4f3a-9314-4eb9e3d4e310",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fef3ca07a-f310-4f3a-9314-4eb9e3d4e310.pdf?alt=media&token=b97d7653-dea6-47a3-beb4-3dd891657cfa",
                        "fileExt": ".pdf",
                        "fileName": "144A.102 WAIVER FROM FEDERAL RULES AND REGULATIONS; PENALTIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.105 SUSPENSION OF ADMISSIONS",
                        "type": "reg",
                        "id": "e8b49296-b756-4687-b28a-b7212e2d8cb0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe8b49296-b756-4687-b28a-b7212e2d8cb0.pdf?alt=media&token=b4fa279d-00f7-4474-a14f-6e8b8a799b11",
                        "fileExt": ".pdf",
                        "fileName": "144A.105 SUSPENSION OF ADMISSIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.11 LICENSE SUSPENSION OR REVOCATION; HEARING; RELICENSING",
                        "type": "reg",
                        "id": "1917a70b-42e3-452e-bf59-00909730f7fe",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1917a70b-42e3-452e-bf59-00909730f7fe.pdf?alt=media&token=431a9341-1691-486b-8e16-edf551b9c810",
                        "fileExt": ".pdf",
                        "fileName": "144A.11 LICENSE SUSPENSION OR REVOCATION; HEARING; RELICENSING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.115 VIOLATIONS; PENALTIES",
                        "type": "reg",
                        "id": "43cd0c36-10e4-4e06-b463-da02caedd2e3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F43cd0c36-10e4-4e06-b463-da02caedd2e3.pdf?alt=media&token=619ddd88-4595-4cf2-8474-03984236c4b0",
                        "fileExt": ".pdf",
                        "fileName": "144A.115 VIOLATIONS; PENALTIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.12 INJUNCTIVE RELIEF; SUBPOENAS",
                        "type": "reg",
                        "id": "42c666c9-79f0-485c-82fb-9c9b78fe283c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F42c666c9-79f0-485c-82fb-9c9b78fe283c.pdf?alt=media&token=9556fdd0-1199-4d49-b00f-128749427ffb",
                        "fileExt": ".pdf",
                        "fileName": "144A.12 INJUNCTIVE RELIEF; SUBPOENAS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.13 COMPLAINTS; RESIDENT'S RIGHTS",
                        "type": "reg",
                        "id": "7a298387-bd60-4637-8ae8-2c5085b60a53",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7a298387-bd60-4637-8ae8-2c5085b60a53.pdf?alt=media&token=4dfd8863-a98c-4600-bbef-1a59a169985f",
                        "fileExt": ".pdf",
                        "fileName": "144A.13 COMPLAINTS; RESIDENT'S RIGHTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.135 TRANSFER AND DISCHARGE APPEALS",
                        "type": "reg",
                        "id": "a398c0fb-49b2-46b7-a72f-8ca4ec3f717a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa398c0fb-49b2-46b7-a72f-8ca4ec3f717a.pdf?alt=media&token=7eea74fb-2107-42dc-9b64-5764e47d629d",
                        "fileExt": ".pdf",
                        "fileName": "144A.135 TRANSFER AND DISCHARGE APPEALS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.15 STATE RECEIVERSHIP",
                        "type": "reg",
                        "id": "80c47bb0-82d3-4c7b-9a99-6b909ddaece9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F80c47bb0-82d3-4c7b-9a99-6b909ddaece9.pdf?alt=media&token=43abd0af-af58-4628-95fc-cc6db98e0433",
                        "fileExt": ".pdf",
                        "fileName": "144A.15 STATE RECEIVERSHIP.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.154 RATE RECOMMENDATION",
                        "type": "reg",
                        "id": "4f55901a-fb01-4f78-bba9-a6ff73ea6ff7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4f55901a-fb01-4f78-bba9-a6ff73ea6ff7.pdf?alt=media&token=3f371727-b086-444d-a203-1bd9f048ae8a",
                        "fileExt": ".pdf",
                        "fileName": "144A.154 RATE RECOMMENDATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.155 PLACEMENT OF MONITOR",
                        "type": "reg",
                        "id": "8fed1ff8-47cf-4871-8e47-49be5172cf40",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8fed1ff8-47cf-4871-8e47-49be5172cf40.pdf?alt=media&token=e1a71b0e-8fe5-4a7d-a251-af3fa3878c5d",
                        "fileExt": ".pdf",
                        "fileName": "144A.155 PLACEMENT OF MONITOR.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.161 NURSING HOME AND BOARDING CARE HOME RESIDENT RELOCATION",
                        "type": "reg",
                        "id": "38812e90-7909-453b-b810-3d319fd418c1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F38812e90-7909-453b-b810-3d319fd418c1.pdf?alt=media&token=97fa0b3b-9f26-4ef7-a201-f0c93d09ed54",
                        "fileExt": ".pdf",
                        "fileName": "144A.161 NURSING HOME AND BOARDING CARE HOME RESIDENT RELOCATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.162 TRANSFER OF RESIDENTS WITHIN FACILITIES",
                        "type": "reg",
                        "id": "bbdd30dc-bdb2-494f-92f1-eca0ba32848e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbbdd30dc-bdb2-494f-92f1-eca0ba32848e.pdf?alt=media&token=84d8f801-11b9-4044-9f64-0e22ea659ddf",
                        "fileExt": ".pdf",
                        "fileName": "144A.162 TRANSFER OF RESIDENTS WITHIN FACILITIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.18 ADMINISTRATOR'S LICENSES; PENALTY",
                        "type": "reg",
                        "id": "140bdc2c-a99d-489c-89b5-bd555d33b0e7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F140bdc2c-a99d-489c-89b5-bd555d33b0e7.pdf?alt=media&token=3f1c9bb2-41b1-45b5-9914-b1281bfc4fc7",
                        "fileExt": ".pdf",
                        "fileName": "144A.18 ADMINISTRATOR'S LICENSES; PENALTY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.1888 REUSE OF FACILITIES",
                        "type": "reg",
                        "id": "ac63eecb-1fe5-4703-ba9b-9c3b3ccef027",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fac63eecb-1fe5-4703-ba9b-9c3b3ccef027.pdf?alt=media&token=2c405e38-5371-482f-a7f2-f10b158f8ff8",
                        "fileExt": ".pdf",
                        "fileName": "144A.1888 REUSE OF FACILITIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Nursing Homes; Long Term Care Services Report",
                    "type": "dir",
                    "id": "6d0b3254-63f6-47e2-a429-fb88e7f8ce38",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Nursing Homes; Long Term Care Services Report",
                    "items": [
                      {
                        "name": "144A.30 PETS IN NURSING HOMES",
                        "type": "reg",
                        "id": "2412c204-0ec2-4e72-8102-665646450702",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2412c204-0ec2-4e72-8102-665646450702.pdf?alt=media&token=40b8dbfd-3264-4bd2-91b4-0d14676d825e",
                        "fileExt": ".pdf",
                        "fileName": "144A.30 PETS IN NURSING HOMES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.33 RESIDENT AND FAMILY ADVISORY COUNCIL EDUCATION",
                        "type": "reg",
                        "id": "15ea08ec-726c-4f2f-b749-cc19211b1978",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F15ea08ec-726c-4f2f-b749-cc19211b1978.pdf?alt=media&token=b87462a6-6421-4585-a143-d355c31458aa",
                        "fileExt": ".pdf",
                        "fileName": "144A.33 RESIDENT AND FAMILY ADVISORY COUNCIL EDUCATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.351 LONG-TERM CARE SERVICES AND SUPPORTS REPORT REQUIRED",
                        "type": "reg",
                        "id": "160f1614-966a-4a31-93fd-9ff40e0df486",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F160f1614-966a-4a31-93fd-9ff40e0df486.pdf?alt=media&token=28e4235b-8e3d-4b40-969a-d6da9cb39d59",
                        "fileExt": ".pdf",
                        "fileName": "144A.351 LONG-TERM CARE SERVICES AND SUPPORTS REPORT REQUIRED.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.37 ALTERNATIVE NURSING HOME SURVEY PROCESS",
                        "type": "reg",
                        "id": "88bc3a57-54a4-494c-9ebb-04be4360bb5d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F88bc3a57-54a4-494c-9ebb-04be4360bb5d.pdf?alt=media&token=44b6093b-e8e0-4b13-942b-3f7147a0053a",
                        "fileExt": ".pdf",
                        "fileName": "144A.37 ALTERNATIVE NURSING HOME SURVEY PROCESS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Supplemental Nursing Services Agency",
                    "type": "dir",
                    "id": "a045eaa4-2ba5-4311-8d37-fe8a8767e32f",
                    "path": "Regulations/State/MN/144A. NURSING HOMES AND HOME CARE/Supplemental Nursing Services Agency",
                    "items": [
                      {
                        "name": "144A.70 REGISTRATION OF SUPPLEMENTAL NURSING SERVICES AGENCIES",
                        "type": "reg",
                        "id": "db28947b-f893-4707-96f7-e15b248ae8a1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdb28947b-f893-4707-96f7-e15b248ae8a1.pdf?alt=media&token=47022e33-f4dc-4642-aaf7-29cfa30d69fc",
                        "fileExt": ".pdf",
                        "fileName": "144A.70 REGISTRATION OF SUPPLEMENTAL NURSING SERVICES AGENCIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.71 SUPPLEMENTAL NURSING SERVICES AGENCY REGISTRATION",
                        "type": "reg",
                        "id": "9782cb5f-d31f-4400-b481-205f36c98c0e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9782cb5f-d31f-4400-b481-205f36c98c0e.pdf?alt=media&token=01ec0c95-5be6-434c-83f0-4c3a6e475da7",
                        "fileExt": ".pdf",
                        "fileName": "144A.71 SUPPLEMENTAL NURSING SERVICES AGENCY REGISTRATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.72 REGISTRATION REQUIREMENTS; PENALTIES",
                        "type": "reg",
                        "id": "94a5200c-0cf3-4e60-8925-feb07bba0be3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F94a5200c-0cf3-4e60-8925-feb07bba0be3.pdf?alt=media&token=6615deed-2c39-478b-83bd-949a2ac42c4d",
                        "fileExt": ".pdf",
                        "fileName": "144A.72 REGISTRATION REQUIREMENTS; PENALTIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.73 COMPLAINT SYSTEM",
                        "type": "reg",
                        "id": "7db5756d-978f-4a29-9d61-443882c8d962",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7db5756d-978f-4a29-9d61-443882c8d962.pdf?alt=media&token=abe90c72-af37-4429-abe4-5fb597b30dec",
                        "fileExt": ".pdf",
                        "fileName": "144A.73 COMPLAINT SYSTEM.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144A.74 MAXIMUM CHARGES",
                        "type": "reg",
                        "id": "3520c19a-6a9a-4b79-8a4c-64ab77edc90d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3520c19a-6a9a-4b79-8a4c-64ab77edc90d.pdf?alt=media&token=50333c8f-11ec-4266-8f19-8b25860d5d9d",
                        "fileExt": ".pdf",
                        "fileName": "144A.74 MAXIMUM CHARGES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  }
                ]
              },
              {
                "name": "Chapter 4658. NURSING HOMES",
                "type": "dir",
                "id": "95d40734-7b13-4bdd-bdfe-5aa2f46dd16e",
                "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES",
                "items": [
                  {
                    "name": "Administration and Operations",
                    "type": "dir",
                    "id": "9953894a-891d-4376-9655-59cdd53e5c20",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Administration and Operations",
                    "items": [
                      {
                        "name": "4658.0050 LICENSEE",
                        "type": "reg",
                        "id": "3e6ec3e5-86dc-482d-9916-0c0d5abe3505",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3e6ec3e5-86dc-482d-9916-0c0d5abe3505.pdf?alt=media&token=caf1a04c-1229-41ad-8146-19d777597a1f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0050 LICENSEE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0055 ADMINISTRATOR",
                        "type": "reg",
                        "id": "1637c3bc-5fdb-4b75-9597-ee21f18eaf26",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1637c3bc-5fdb-4b75-9597-ee21f18eaf26.pdf?alt=media&token=5c5a7c58-6eb1-488b-b699-38f6722d804c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0055 ADMINISTRATOR.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0060 RESPONSIBILITIES OF ADMINISTRATOR",
                        "type": "reg",
                        "id": "5bd29b6c-8b94-4103-9bf0-13f394ff9a52",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5bd29b6c-8b94-4103-9bf0-13f394ff9a52.pdf?alt=media&token=fe10223c-236b-4961-81d4-208b2a8d7808",
                        "fileExt": ".pdf",
                        "fileName": "4658.0060 RESPONSIBILITIES OF ADMINISTRATOR.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0065 RESIDENT SAFETY AND DISASTER PLANNING",
                        "type": "reg",
                        "id": "9e551356-8f37-46ce-a2be-68bc4f00159d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9e551356-8f37-46ce-a2be-68bc4f00159d.pdf?alt=media&token=be597583-93fe-4d5c-af1c-f9fd5161a152",
                        "fileExt": ".pdf",
                        "fileName": "4658.0065 RESIDENT SAFETY AND DISASTER PLANNING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0070 QUALITY ASSESSMENT AND ASSURANCE COMMITTEE",
                        "type": "reg",
                        "id": "8325589c-b2a8-4efc-9706-5efc11efa16d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8325589c-b2a8-4efc-9706-5efc11efa16d.pdf?alt=media&token=5414a3f3-541a-4983-b0d5-a98b03754283",
                        "fileExt": ".pdf",
                        "fileName": "4658.0070 QUALITY ASSESSMENT AND ASSURANCE COMMITTEE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0075 OUTSIDE RESOURCES",
                        "type": "reg",
                        "id": "636d596c-9912-4b20-997f-010f1d54beff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F636d596c-9912-4b20-997f-010f1d54beff.pdf?alt=media&token=f83b82f4-4c53-4183-8254-e64bbfd00c32",
                        "fileExt": ".pdf",
                        "fileName": "4658.0075 OUTSIDE RESOURCES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0085 NOTIFICATION OF CHANGE IN RESIDENT HEALTH STATUS",
                        "type": "reg",
                        "id": "39fb0cc7-ae9b-43b3-859b-ffec684614a8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F39fb0cc7-ae9b-43b3-859b-ffec684614a8.pdf?alt=media&token=959e8871-2f16-45fa-8b00-f808961a8430",
                        "fileExt": ".pdf",
                        "fileName": "4658.0085 NOTIFICATION OF CHANGE IN RESIDENT HEALTH STATUS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0090 USE OF OXYGEN",
                        "type": "reg",
                        "id": "59578681-9dd9-43c7-aec6-b4083b33ebc3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F59578681-9dd9-43c7-aec6-b4083b33ebc3.pdf?alt=media&token=ff7f8173-e7d3-4aa0-b1bb-7874136322de",
                        "fileExt": ".pdf",
                        "fileName": "4658.0090 USE OF OXYGEN.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0095 AVAILABILITY OF LICENSING RULES",
                        "type": "reg",
                        "id": "23dd376b-1acf-450c-80ea-67d25f15a27a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F23dd376b-1acf-450c-80ea-67d25f15a27a.pdf?alt=media&token=ac0671cd-181f-45c4-9b07-835ea45af817",
                        "fileExt": ".pdf",
                        "fileName": "4658.0095 AVAILABILITY OF LICENSING RULES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0100 EMPLOYEE ORIENTATION AND IN-SERVICE EDUCATION",
                        "type": "reg",
                        "id": "c86d785c-9e10-4de9-b4f8-b405e6f81144",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc86d785c-9e10-4de9-b4f8-b405e6f81144.pdf?alt=media&token=2fd412d3-0ee9-458f-83af-f9c4f5c59d93",
                        "fileExt": ".pdf",
                        "fileName": "4658.0100 EMPLOYEE ORIENTATION AND IN-SERVICE EDUCATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0105 COMPETENCY",
                        "type": "reg",
                        "id": "d3e2ea43-a0f3-4c0b-8753-f0f55b4b11eb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd3e2ea43-a0f3-4c0b-8753-f0f55b4b11eb.pdf?alt=media&token=43fff65f-9478-4b52-a1ba-279e9e9a2662",
                        "fileExt": ".pdf",
                        "fileName": "4658.0105 COMPETENCY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0110 INCIDENT AND ACCIDENT REPORTING",
                        "type": "reg",
                        "id": "7040e17f-2c98-43cd-bd5f-2a2fbd099f78",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7040e17f-2c98-43cd-bd5f-2a2fbd099f78.pdf?alt=media&token=defa85f5-381f-4870-822d-9f60c0a05db3",
                        "fileExt": ".pdf",
                        "fileName": "4658.0110 INCIDENT AND ACCIDENT REPORTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0115 WORK PERIOD",
                        "type": "reg",
                        "id": "9e66808c-6f1a-4083-8f41-1585c4794ea2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9e66808c-6f1a-4083-8f41-1585c4794ea2.pdf?alt=media&token=82bb13cc-c357-484e-b51d-b6a6ec5d6cde",
                        "fileExt": ".pdf",
                        "fileName": "4658.0115 WORK PERIOD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0120 EMPLOYEE POLICIES",
                        "type": "reg",
                        "id": "1f7ee865-de93-4bde-9902-8b1d2dc3a48d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1f7ee865-de93-4bde-9902-8b1d2dc3a48d.pdf?alt=media&token=bb059ba8-01c0-4d9f-b01d-ed06a9acffcb",
                        "fileExt": ".pdf",
                        "fileName": "4658.0120 EMPLOYEE POLICIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0125 PERSONAL BELONGINGS",
                        "type": "reg",
                        "id": "00b3e7c5-9dc0-4f43-9c3f-7456da49b8f8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F00b3e7c5-9dc0-4f43-9c3f-7456da49b8f8.pdf?alt=media&token=92348d9a-cc50-4f9d-a9e1-5820a47f8d1f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0125 PERSONAL BELONGINGS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0130 EMPLOYEES' PERSONNEL RECORDS",
                        "type": "reg",
                        "id": "20730155-29e4-4ae0-84f8-58f48e133fe1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F20730155-29e4-4ae0-84f8-58f48e133fe1.pdf?alt=media&token=d8440db5-6515-4a0c-8f1e-4ee7aa608454",
                        "fileExt": ".pdf",
                        "fileName": "4658.0130 EMPLOYEES' PERSONNEL RECORDS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0135 POLICY RECORDS",
                        "type": "reg",
                        "id": "ca36288f-8669-459b-b53b-8bb1556b9ab3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fca36288f-8669-459b-b53b-8bb1556b9ab3.pdf?alt=media&token=593a3bdc-1fb8-4db8-baee-a587db5d5b37",
                        "fileExt": ".pdf",
                        "fileName": "4658.0135 POLICY RECORDS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0140 TYPE OF ADMISSIONS",
                        "type": "reg",
                        "id": "c2b8fde6-8fe1-4582-b3b0-68315591ec08",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc2b8fde6-8fe1-4582-b3b0-68315591ec08.pdf?alt=media&token=7ee75f52-b28e-4d4b-a522-ea0fea37b746",
                        "fileExt": ".pdf",
                        "fileName": "4658.0140 TYPE OF ADMISSIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0145 AGREEMENT AS TO RATES AND CHARGES",
                        "type": "reg",
                        "id": "17f1f604-f104-4b7e-b7a8-5af6f7afb410",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F17f1f604-f104-4b7e-b7a8-5af6f7afb410.pdf?alt=media&token=8ddfe153-68cb-4c30-adc1-91fee0df48fa",
                        "fileExt": ".pdf",
                        "fileName": "4658.0145 AGREEMENT AS TO RATES AND CHARGES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0150 INSPECTION BY DEPARTMENT",
                        "type": "reg",
                        "id": "63de52fa-a213-4da3-9ac4-49dd97ab5582",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F63de52fa-a213-4da3-9ac4-49dd97ab5582.pdf?alt=media&token=3091a87c-efa6-4d4e-bda3-0bd265a6aa41",
                        "fileExt": ".pdf",
                        "fileName": "4658.0150 INSPECTION BY DEPARTMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0155 REPORTS TO DEPARTMENT",
                        "type": "reg",
                        "id": "4fb3f37a-d83e-4212-b73b-b917bfe770e2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4fb3f37a-d83e-4212-b73b-b917bfe770e2.pdf?alt=media&token=8229a3be-9563-476c-a900-782f56cd03d2",
                        "fileExt": ".pdf",
                        "fileName": "4658.0155 REPORTS TO DEPARTMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Barber and Beauty Shop Services",
                    "type": "dir",
                    "id": "22b7c6e3-f8e3-439b-af20-ea30c9d93908",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Barber and Beauty Shop Services",
                    "items": [
                      {
                        "name": "4658.1100 BARBER AND BEAUTY SHOP SERVICES",
                        "type": "reg",
                        "id": "018646bd-1868-4dca-b565-90e464b33f49",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F018646bd-1868-4dca-b565-90e464b33f49.pdf?alt=media&token=463f7c1a-7946-493b-aa56-1c5b03fe2c36",
                        "fileExt": ".pdf",
                        "fileName": "4658.1100 BARBER AND BEAUTY SHOP SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1190 PENALTIES FOR BARBER AND BEAUTY SHOP SERVICES RULE",
                        "type": "reg",
                        "id": "f9860250-2ecb-4a14-ba1f-2e81f99a44a1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff9860250-2ecb-4a14-ba1f-2e81f99a44a1.pdf?alt=media&token=f2afaa9b-ff45-4367-b385-8d5bb3481810",
                        "fileExt": ".pdf",
                        "fileName": "4658.1190 PENALTIES FOR BARBER AND BEAUTY SHOP SERVICES RULE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Clinical Records",
                    "type": "dir",
                    "id": "29fe97ab-f394-4aad-bf30-69f43dc6e51f",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Clinical Records",
                    "items": [
                      {
                        "name": "4658.0430 HEALTH INFORMATION MANAGEMENT SERVICE",
                        "type": "reg",
                        "id": "cd18a307-a9e4-4249-b889-6bf4b671d12f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcd18a307-a9e4-4249-b889-6bf4b671d12f.pdf?alt=media&token=5b0815a7-3f91-42b0-a02c-861c2e5c75e7",
                        "fileExt": ".pdf",
                        "fileName": "4658.0430 HEALTH INFORMATION MANAGEMENT SERVICE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0435 CONFIDENTIALITY OF CLINICAL RECORDS AND",
                        "type": "reg",
                        "id": "edb30bcb-1366-42c0-a42b-e2c3c4c39405",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fedb30bcb-1366-42c0-a42b-e2c3c4c39405.pdf?alt=media&token=baa00893-e4f3-409e-b313-74a07e1c68ad",
                        "fileExt": ".pdf",
                        "fileName": "4658.0435 CONFIDENTIALITY OF CLINICAL RECORDS AND.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0440 ABBREVIATIONS",
                        "type": "reg",
                        "id": "d69f787f-0798-4b97-86ee-1b12b20cc820",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd69f787f-0798-4b97-86ee-1b12b20cc820.pdf?alt=media&token=aa81d24b-a6f4-4088-b55d-92f8c3b47319",
                        "fileExt": ".pdf",
                        "fileName": "4658.0440 ABBREVIATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0445 CLINICAL RECORD",
                        "type": "reg",
                        "id": "fc8303ed-3dd6-4102-88d9-8327531d85e9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffc8303ed-3dd6-4102-88d9-8327531d85e9.pdf?alt=media&token=b8ada3e2-e649-4c6f-b8eb-d6e7f69d0d0e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0445 CLINICAL RECORD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0450 CLINICAL RECORD CONTENTS",
                        "type": "reg",
                        "id": "9e6f1e4a-6032-4246-9ab0-7986b127d57b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9e6f1e4a-6032-4246-9ab0-7986b127d57b.pdf?alt=media&token=9f771d57-f902-4e08-a770-e7f087a63c9d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0450 CLINICAL RECORD CONTENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0455 TELEPHONE AND ELECTRONIC ORDERS",
                        "type": "reg",
                        "id": "89b081b8-7ecb-4e1e-9669-37d7886210fe",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F89b081b8-7ecb-4e1e-9669-37d7886210fe.pdf?alt=media&token=ca42bcd9-9cca-4efd-9e25-a1126869448d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0455 TELEPHONE AND ELECTRONIC ORDERS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0460 MASTER RESIDENT RECORD",
                        "type": "reg",
                        "id": "94109d29-8502-42a6-bd64-0f49541c6a3a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F94109d29-8502-42a6-bd64-0f49541c6a3a.pdf?alt=media&token=3c00857c-547a-4e51-b1aa-c77aecb899c5",
                        "fileExt": ".pdf",
                        "fileName": "4658.0460 MASTER RESIDENT RECORD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0465 TRANSFER, DISCHARGE, AND DEATH",
                        "type": "reg",
                        "id": "05fa2f45-1255-4324-8c54-577808f7a912",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F05fa2f45-1255-4324-8c54-577808f7a912.pdf?alt=media&token=2a1b7005-19e3-4a1d-a88e-2f96d7e132d2",
                        "fileExt": ".pdf",
                        "fileName": "4658.0465 TRANSFER, DISCHARGE, AND DEATH.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0470 RETENTION, STORAGE, AND RETRIEVAL",
                        "type": "reg",
                        "id": "d397ea20-d0a7-4863-b788-1a9d4b843b02",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd397ea20-d0a7-4863-b788-1a9d4b843b02.pdf?alt=media&token=2b6ae512-9378-4300-aeb4-6663923b516e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0470 RETENTION, STORAGE, AND RETRIEVAL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0475 COMPUTERIZATION",
                        "type": "reg",
                        "id": "fd0e2fd9-08c5-4240-8f32-419b34b01bf3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffd0e2fd9-08c5-4240-8f32-419b34b01bf3.pdf?alt=media&token=511826b9-2ca2-48c9-b0e4-0cb20fa16222",
                        "fileExt": ".pdf",
                        "fileName": "4658.0475 COMPUTERIZATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0490 PENALTIES FOR CLINICAL RECORDS RULE VIOLATIONS",
                        "type": "reg",
                        "id": "16b15391-5459-4525-9210-896c60aed422",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F16b15391-5459-4525-9210-896c60aed422.pdf?alt=media&token=0216c46f-dbd4-4379-82af-b48f86eab66d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0490 PENALTIES FOR CLINICAL RECORDS RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Construction Details",
                    "type": "dir",
                    "id": "d4c92e08-9eb6-44bf-9f85-71745193d78f",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Construction Details",
                    "items": [
                      {
                        "name": "4658.4400 AREA HEAT PROTECTION; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "4f171fbd-7823-49ee-944d-9852b3ebf605",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4f171fbd-7823-49ee-944d-9852b3ebf605.pdf?alt=media&token=d3b7d5a5-a705-420b-a840-cd5edeaecdb7",
                        "fileExt": ".pdf",
                        "fileName": "4658.4400 AREA HEAT PROTECTION; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4405 DOOR HANDLES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "3f2dc3dd-6b86-4f32-9c1a-103a8628a874",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3f2dc3dd-6b86-4f32-9c1a-103a8628a874.pdf?alt=media&token=4fde3f40-bdc4-43f3-93fe-482dddf8d7f6",
                        "fileExt": ".pdf",
                        "fileName": "4658.4405 DOOR HANDLES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4410 DUMBWAITERS AND CONVEYORS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "84e5e9a1-b57a-4a85-ab4f-282ee397172a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F84e5e9a1-b57a-4a85-ab4f-282ee397172a.pdf?alt=media&token=9c1f16bd-a63c-4664-ad04-2851d9f6e594",
                        "fileExt": ".pdf",
                        "fileName": "4658.4410 DUMBWAITERS AND CONVEYORS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4415 ELEVATORS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "71344725-3f91-469f-a75c-c6b98e22b25e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F71344725-3f91-469f-a75c-c6b98e22b25e.pdf?alt=media&token=74e809fe-3b66-4167-a628-9eb251edf424",
                        "fileExt": ".pdf",
                        "fileName": "4658.4415 ELEVATORS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4420 EXTERIOR MECHANICAL SHAFTS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "e0104cb8-6940-40f1-a772-2f5e44c05532",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe0104cb8-6940-40f1-a772-2f5e44c05532.pdf?alt=media&token=291149c7-672a-4e2a-8533-476814a3e990",
                        "fileExt": ".pdf",
                        "fileName": "4658.4420 EXTERIOR MECHANICAL SHAFTS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4425 FLOOR JOINTS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "75b1e612-748f-4ab0-8062-7cdccccd78fa",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F75b1e612-748f-4ab0-8062-7cdccccd78fa.pdf?alt=media&token=664c482b-0d17-4ab3-9806-af169914f039",
                        "fileExt": ".pdf",
                        "fileName": "4658.4425 FLOOR JOINTS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4430 NONSKID SURFACES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "21c2a105-9821-482b-8bd0-c19f8df2e66b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F21c2a105-9821-482b-8bd0-c19f8df2e66b.pdf?alt=media&token=3e6a0a02-d5e4-46b1-bf44-678525194b3b",
                        "fileExt": ".pdf",
                        "fileName": "4658.4430 NONSKID SURFACES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4435 GLASS PROTECTION; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "47c71a56-8fc5-41ec-969a-9acd3a84688c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F47c71a56-8fc5-41ec-969a-9acd3a84688c.pdf?alt=media&token=6ec90d9d-6ed6-44bb-8058-91c48233713d",
                        "fileExt": ".pdf",
                        "fileName": "4658.4435 GLASS PROTECTION; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4440 LINEN AND TRASH CHUTES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "a5483225-6911-4cb4-91a3-d6bebf1e1523",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa5483225-6911-4cb4-91a3-d6bebf1e1523.pdf?alt=media&token=e0639ee4-6689-46aa-b46e-c47772a013fc",
                        "fileExt": ".pdf",
                        "fileName": "4658.4440 LINEN AND TRASH CHUTES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4445 OVERHEAD PIPING; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "9afbbbf5-bc7c-48a6-bdd4-a5c6972a55c3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9afbbbf5-bc7c-48a6-bdd4-a5c6972a55c3.pdf?alt=media&token=7273d6cf-3b8c-4859-887e-5e66959b73da",
                        "fileExt": ".pdf",
                        "fileName": "4658.4445 OVERHEAD PIPING; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4450 PROTECTION RAILINGS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "31bb9bce-d64e-4d81-a965-77d41549f66b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F31bb9bce-d64e-4d81-a965-77d41549f66b.pdf?alt=media&token=36cf4f34-2954-4e99-b943-83326d3e9436",
                        "fileExt": ".pdf",
                        "fileName": "4658.4450 PROTECTION RAILINGS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4455 CEILING HEIGHTS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "7c8088f6-aa50-40cf-b668-b786d8a84737",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7c8088f6-aa50-40cf-b668-b786d8a84737.pdf?alt=media&token=37420f45-82b6-4a7b-b854-4d17977bf164",
                        "fileExt": ".pdf",
                        "fileName": "4658.4455 CEILING HEIGHTS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4460 CEILINGS, WALLS, AND FLOORS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "69b7e262-d55a-43e9-8fcb-9d97b85afc4d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F69b7e262-d55a-43e9-8fcb-9d97b85afc4d.pdf?alt=media&token=d266e419-6be3-4d1f-8e91-3ff68534655f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4460 CEILINGS, WALLS, AND FLOORS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4490 PENALTIES FOR CONSTRUCTION DETAILS, CHUTES, AND",
                        "type": "reg",
                        "id": "da493600-2149-4db0-ada4-5490bea4b36d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fda493600-2149-4db0-ada4-5490bea4b36d.pdf?alt=media&token=f89d1640-0f24-47cf-be65-64173685bf63",
                        "fileExt": ".pdf",
                        "fileName": "4658.4490 PENALTIES FOR CONSTRUCTION DETAILS, CHUTES, AND.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Construction Details - Existing Construction",
                    "type": "dir",
                    "id": "708fac6d-155a-4dc7-82e5-98ea4fa3b67e",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Construction Details - Existing Construction",
                    "items": [
                      {
                        "name": "4658.5300 AREA HEAT PROTECTION; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "e801a620-e873-4e9a-a6fd-72b5bb36ae63",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe801a620-e873-4e9a-a6fd-72b5bb36ae63.pdf?alt=media&token=d9183ac3-d3d6-4549-ade2-d8a8dadf487a",
                        "fileExt": ".pdf",
                        "fileName": "4658.5300 AREA HEAT PROTECTION; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5305 NONSKID SURFACES; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "109a19ff-e6f5-4313-a044-c818ecf40126",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F109a19ff-e6f5-4313-a044-c818ecf40126.pdf?alt=media&token=6f88527e-88c4-4f81-9e5e-070034f2e8f3",
                        "fileExt": ".pdf",
                        "fileName": "4658.5305 NONSKID SURFACES; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5310 GLASS PROTECTION; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "13fcd2f3-bced-4398-8af3-e2c0fd9d997d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F13fcd2f3-bced-4398-8af3-e2c0fd9d997d.pdf?alt=media&token=ec4a8eba-1128-4cf9-b321-53024bd36e32",
                        "fileExt": ".pdf",
                        "fileName": "4658.5310 GLASS PROTECTION; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5315 CEILINGS, WALLS, AND FLOORS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "003cd678-6faf-4857-9974-0dcf50989c6e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F003cd678-6faf-4857-9974-0dcf50989c6e.pdf?alt=media&token=d681a13e-d63b-499b-a598-11938802e7e8",
                        "fileExt": ".pdf",
                        "fileName": "4658.5315 CEILINGS, WALLS, AND FLOORS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5390 PENALTIES FOR CONSTRUCTION DETAILS; EXISTING",
                        "type": "reg",
                        "id": "83cd7c30-73f4-4735-98a0-15bd806ffad4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F83cd7c30-73f4-4735-98a0-15bd806ffad4.pdf?alt=media&token=37c4f6a8-be66-4ef3-ae2c-a7ab19141be0",
                        "fileExt": ".pdf",
                        "fileName": "4658.5390 PENALTIES FOR CONSTRUCTION DETAILS; EXISTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Dietary Laundry and Other Facilities - Existing Construction",
                    "type": "dir",
                    "id": "ebe77f18-e15f-4727-b477-0d24435a07f7",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Dietary Laundry and Other Facilities - Existing Construction",
                    "items": [
                      {
                        "name": "4658.5200 FOOD SERVICE EQUIPMENT; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "01036e2f-0b50-43d0-979d-fc93a3beb455",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F01036e2f-0b50-43d0-979d-fc93a3beb455.pdf?alt=media&token=6d3002f9-a7f1-4d7f-9a49-597a56dc77fe",
                        "fileExt": ".pdf",
                        "fileName": "4658.5200 FOOD SERVICE EQUIPMENT; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5205 LAUNDRY; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "5618ccbe-92d0-44a9-babc-198400c294e3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5618ccbe-92d0-44a9-babc-198400c294e3.pdf?alt=media&token=fa953e35-5e04-43df-b18b-44a136cd09bf",
                        "fileExt": ".pdf",
                        "fileName": "4658.5205 LAUNDRY; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5210 SOILED LINEN COLLECTION ROOM; EXISTING",
                        "type": "reg",
                        "id": "00e96a65-7483-488a-869b-a80caffced0f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F00e96a65-7483-488a-869b-a80caffced0f.pdf?alt=media&token=25fc8278-028b-4807-a04d-a980d0f89734",
                        "fileExt": ".pdf",
                        "fileName": "4658.5210 SOILED LINEN COLLECTION ROOM; EXISTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5215 LAUNDRY EQUIPMENT; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "84f6ac32-f5df-4624-b95f-b01cadbd515a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F84f6ac32-f5df-4624-b95f-b01cadbd515a.pdf?alt=media&token=53d1b16e-edaf-43bb-b983-8f7da095ae89",
                        "fileExt": ".pdf",
                        "fileName": "4658.5215 LAUNDRY EQUIPMENT; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5220 CLEAN LINEN STORAGE; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "fa387103-2ff0-4546-80ff-7d7dc1bf2cf6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffa387103-2ff0-4546-80ff-7d7dc1bf2cf6.pdf?alt=media&token=de986a97-3418-4dc4-8aa7-e0008c26f4a5",
                        "fileExt": ".pdf",
                        "fileName": "4658.5220 CLEAN LINEN STORAGE; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5225 LAUNDRY FOR PERSONAL CLOTHING; EXISTING",
                        "type": "reg",
                        "id": "b36b5e70-0dc2-4ef2-97b9-679a36c9b270",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb36b5e70-0dc2-4ef2-97b9-679a36c9b270.pdf?alt=media&token=60d5a9db-7425-4365-858d-6c523549bedd",
                        "fileExt": ".pdf",
                        "fileName": "4658.5225 LAUNDRY FOR PERSONAL CLOTHING; EXISTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5230 REFUSE; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "5bcc228e-42d3-452f-b134-e4633ac66871",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5bcc228e-42d3-452f-b134-e4633ac66871.pdf?alt=media&token=ff347d3d-200b-4917-b764-22f07095eeb9",
                        "fileExt": ".pdf",
                        "fileName": "4658.5230 REFUSE; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5235 FACILITIES FOR PERSONNEL; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "64964a50-432c-44f3-8f25-f44aaacc6d9e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F64964a50-432c-44f3-8f25-f44aaacc6d9e.pdf?alt=media&token=09f81459-7d82-4ac6-a2a5-717be5573097",
                        "fileExt": ".pdf",
                        "fileName": "4658.5235 FACILITIES FOR PERSONNEL; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5240 REHABILITATIVESERVICESAREAS;EXISTINGCONSTRUCTION",
                        "type": "reg",
                        "id": "cbff4615-9501-4969-858b-669a17216bab",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcbff4615-9501-4969-858b-669a17216bab.pdf?alt=media&token=38221d55-4872-47e7-8380-19ba153a5dda",
                        "fileExt": ".pdf",
                        "fileName": "4658.5240 REHABILITATIVESERVICESAREAS;EXISTINGCONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5245 BARBER AND BEAUTY SHOP SERVICES ROOM; EXISTING",
                        "type": "reg",
                        "id": "f76a1204-539e-4a8e-b8a4-2ee00de34364",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff76a1204-539e-4a8e-b8a4-2ee00de34364.pdf?alt=media&token=b7d53fe3-3384-4f7d-8034-89c19fa1addb",
                        "fileExt": ".pdf",
                        "fileName": "4658.5245 BARBER AND BEAUTY SHOP SERVICES ROOM; EXISTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5290 PENALTIES FOR DIETARY, LAUNDRY, AND OTHER SERVICES;",
                        "type": "reg",
                        "id": "621fd5a8-3800-4365-9c76-a6f4926b02b8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F621fd5a8-3800-4365-9c76-a6f4926b02b8.pdf?alt=media&token=77dcd36e-f410-48e3-b238-2c2283d87c96",
                        "fileExt": ".pdf",
                        "fileName": "4658.5290 PENALTIES FOR DIETARY, LAUNDRY, AND OTHER SERVICES;.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Dietary Laundry and Other Facilities - New Construction",
                    "type": "dir",
                    "id": "c501b345-8760-41da-8d99-a15a0cf12503",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Dietary Laundry and Other Facilities - New Construction",
                    "items": [
                      {
                        "name": "4658.4300 KITCHEN AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "6ae53682-ca1c-483f-811d-2891d5e3a625",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6ae53682-ca1c-483f-811d-2891d5e3a625.pdf?alt=media&token=7662dd2e-52e1-4c61-8e17-5e6bc5d25687",
                        "fileExt": ".pdf",
                        "fileName": "4658.4300 KITCHEN AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4305 FOOD SERVICE EQUIPMENT; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "531d25c7-02b2-4bf8-b561-d84d4be1c52a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F531d25c7-02b2-4bf8-b561-d84d4be1c52a.pdf?alt=media&token=785abbb5-fad8-4ee9-902b-4adf5e17c727",
                        "fileExt": ".pdf",
                        "fileName": "4658.4305 FOOD SERVICE EQUIPMENT; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4310 DISHWASHING AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "e9953645-071e-4fec-9bd2-83fa34209ef0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe9953645-071e-4fec-9bd2-83fa34209ef0.pdf?alt=media&token=0204fc8f-32d6-4fa8-a50e-4e7d03f2211a",
                        "fileExt": ".pdf",
                        "fileName": "4658.4310 DISHWASHING AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4315 WASHING OF FOOD CARTS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "e1bf4f13-701a-4315-9fe2-3dd26e66389f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe1bf4f13-701a-4315-9fe2-3dd26e66389f.pdf?alt=media&token=8d1a1b62-7324-4b1a-84fb-ff0e5526888a",
                        "fileExt": ".pdf",
                        "fileName": "4658.4315 WASHING OF FOOD CARTS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4320 WASHING OF GARBAGE CANS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "64c192d7-8655-4c51-ae01-578c8287eb2d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F64c192d7-8655-4c51-ae01-578c8287eb2d.pdf?alt=media&token=7d4f6e1a-9ca2-4e48-b263-488a21be4b3e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4320 WASHING OF GARBAGE CANS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4325 LAUNDRY, SIZE AND LOCATION; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "8698d50d-550f-4535-afd1-fd8f1154fa80",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8698d50d-550f-4535-afd1-fd8f1154fa80.pdf?alt=media&token=45101964-4e94-49fd-b734-c786c40d31b9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4325 LAUNDRY, SIZE AND LOCATION; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4330 SOILED LINEN COLLECTION ROOM; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "c60cbc86-8c81-4f07-9ce1-4d426948000e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc60cbc86-8c81-4f07-9ce1-4d426948000e.pdf?alt=media&token=9a7626ed-59eb-40ac-87f7-983e9b441f03",
                        "fileExt": ".pdf",
                        "fileName": "4658.4330 SOILED LINEN COLLECTION ROOM; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4335 LAUNDRY PROCESSING ROOM; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "a63352b7-d0f7-430c-8ac9-2bd95365b8ad",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa63352b7-d0f7-430c-8ac9-2bd95365b8ad.pdf?alt=media&token=341ebf33-99e6-40dd-8222-a439be902655",
                        "fileExt": ".pdf",
                        "fileName": "4658.4335 LAUNDRY PROCESSING ROOM; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4340 LAUNDRY EQUIPMENT; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "76e6f7f3-f78a-44ef-8154-4237ba77f12d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F76e6f7f3-f78a-44ef-8154-4237ba77f12d.pdf?alt=media&token=f4fc6698-fc4f-401e-8ab3-61b78b09415a",
                        "fileExt": ".pdf",
                        "fileName": "4658.4340 LAUNDRY EQUIPMENT; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4345 CLEAN LINEN STORAGE; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "0eab3612-0bd5-428a-9b97-485a7c3add49",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0eab3612-0bd5-428a-9b97-485a7c3add49.pdf?alt=media&token=8346870c-ab98-4a9b-ac61-c4c37be4d906",
                        "fileExt": ".pdf",
                        "fileName": "4658.4345 CLEAN LINEN STORAGE; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4350 LAUNDRY FOR PERSONAL CLOTHING; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "411ab72d-887e-4a0c-960e-6343ff094cb3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F411ab72d-887e-4a0c-960e-6343ff094cb3.pdf?alt=media&token=a01661dd-f0b8-4f2f-b3f0-b1c96f81c8d5",
                        "fileExt": ".pdf",
                        "fileName": "4658.4350 LAUNDRY FOR PERSONAL CLOTHING; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4355 REFUSE; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "083959f5-7c8f-4af3-9ce5-dbace86fdb9c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F083959f5-7c8f-4af3-9ce5-dbace86fdb9c.pdf?alt=media&token=09ba7074-5e56-4594-91a4-51bc1915751d",
                        "fileExt": ".pdf",
                        "fileName": "4658.4355 REFUSE; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4360 COVERED ENTRANCE AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "5f1654b9-26d8-4203-a405-af977f47a8a2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5f1654b9-26d8-4203-a405-af977f47a8a2.pdf?alt=media&token=abe807ac-0c6c-4966-bf6e-71a8fca606c4",
                        "fileExt": ".pdf",
                        "fileName": "4658.4360 COVERED ENTRANCE AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4365 FACILITIES FOR PERSONNEL; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "a999fe64-68b5-4aca-8783-e99ed78bff7b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa999fe64-68b5-4aca-8783-e99ed78bff7b.pdf?alt=media&token=2cf164d9-ebdc-4f72-a656-501ada1cbddb",
                        "fileExt": ".pdf",
                        "fileName": "4658.4365 FACILITIES FOR PERSONNEL; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4370 REHABILITATIVE SERVICES AREAS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "c4f0bcf0-142d-4223-8467-e40c15798d7a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc4f0bcf0-142d-4223-8467-e40c15798d7a.pdf?alt=media&token=d2b0e85e-353f-4aed-8b47-a7c06e4909a0",
                        "fileExt": ".pdf",
                        "fileName": "4658.4370 REHABILITATIVE SERVICES AREAS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4375 BARBER AND BEAUTY SHOP SERVICES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "e038316e-f00e-4741-bafd-b994ef3e140d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe038316e-f00e-4741-bafd-b994ef3e140d.pdf?alt=media&token=3d61dd96-0c0c-4281-8f39-efddae964072",
                        "fileExt": ".pdf",
                        "fileName": "4658.4375 BARBER AND BEAUTY SHOP SERVICES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4390 PENALTIES FOR DIETARY, LAUNDRY, AND OTHER SERVICES;",
                        "type": "reg",
                        "id": "f4a15f13-9e72-49d2-b17e-a3894dfcaf45",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff4a15f13-9e72-49d2-b17e-a3894dfcaf45.pdf?alt=media&token=e63c3ce6-0333-4e6a-b7e5-ac06783e5fcf",
                        "fileExt": ".pdf",
                        "fileName": "4658.4390 PENALTIES FOR DIETARY, LAUNDRY, AND OTHER SERVICES;.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Dietary Service",
                    "type": "dir",
                    "id": "0eb2cc89-cedc-4e75-ba5a-4e4b64958caf",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Dietary Service",
                    "items": [
                      {
                        "name": "4658.0600 DIETARY SERVICE",
                        "type": "reg",
                        "id": "caa6a3b8-e0f0-43f0-a15e-cbe99979c256",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcaa6a3b8-e0f0-43f0-a15e-cbe99979c256.pdf?alt=media&token=94dc17db-32c0-41e1-96a5-7351908c8728",
                        "fileExt": ".pdf",
                        "fileName": "4658.0600 DIETARY SERVICE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0605 DIRECTION OF DIETARY DEPARTMENT",
                        "type": "reg",
                        "id": "fc4cb571-d53c-4d24-be17-52139dca3623",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffc4cb571-d53c-4d24-be17-52139dca3623.pdf?alt=media&token=0d3a011a-aafb-4583-8253-ac5169b1d169",
                        "fileExt": ".pdf",
                        "fileName": "4658.0605 DIRECTION OF DIETARY DEPARTMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0610 DIETARY STAFF REQUIREMENTS",
                        "type": "reg",
                        "id": "673c5c68-723b-4f54-9c91-8ef92df9454e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F673c5c68-723b-4f54-9c91-8ef92df9454e.pdf?alt=media&token=a2ecece7-92d6-4977-934d-b901bb3229e1",
                        "fileExt": ".pdf",
                        "fileName": "4658.0610 DIETARY STAFF REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0615 FOOD TEMPERATURES",
                        "type": "reg",
                        "id": "c7f23c4e-dd14-449f-877b-9c886e8437be",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc7f23c4e-dd14-449f-877b-9c886e8437be.pdf?alt=media&token=cdb12938-9cdd-476b-8c92-8602a47507ec",
                        "fileExt": ".pdf",
                        "fileName": "4658.0615 FOOD TEMPERATURES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0620 FREQUENCY OF MEALS",
                        "type": "reg",
                        "id": "f2e82115-b863-4312-adca-9c2b3defe3d7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff2e82115-b863-4312-adca-9c2b3defe3d7.pdf?alt=media&token=c38e7338-8628-4364-888c-6f382c3b45b6",
                        "fileExt": ".pdf",
                        "fileName": "4658.0620 FREQUENCY OF MEALS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0625 MENUS",
                        "type": "reg",
                        "id": "8df03223-3e48-4956-9bd9-36da3954adf8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8df03223-3e48-4956-9bd9-36da3954adf8.pdf?alt=media&token=676978d3-e72a-4535-b69f-8e6132a9586e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0625 MENUS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0630 RETURNED FOOD",
                        "type": "reg",
                        "id": "e6c41458-edd8-4008-ba60-3cf449a4e285",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe6c41458-edd8-4008-ba60-3cf449a4e285.pdf?alt=media&token=feb18917-5acf-47b6-b3ea-ae0bce9b582c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0630 RETURNED FOOD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0635 CONDIMENTS",
                        "type": "reg",
                        "id": "1513b30a-9738-4261-9694-158129a1a0be",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1513b30a-9738-4261-9694-158129a1a0be.pdf?alt=media&token=e6a3f029-b546-442a-a404-10dcfea03486",
                        "fileExt": ".pdf",
                        "fileName": "4658.0635 CONDIMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0640 MILK",
                        "type": "reg",
                        "id": "b4ea9279-9fcd-4e9f-b26f-e696bc9c8ec0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb4ea9279-9fcd-4e9f-b26f-e696bc9c8ec0.pdf?alt=media&token=c95c148b-afab-4129-8d99-ba6e64927f0e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0640 MILK.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0645 ICE",
                        "type": "reg",
                        "id": "27166410-f22d-4b67-b5ff-d0fd7ef2994b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F27166410-f22d-4b67-b5ff-d0fd7ef2994b.pdf?alt=media&token=a7335f99-8f1f-4612-9c1f-f4d48ca8b91a",
                        "fileExt": ".pdf",
                        "fileName": "4658.0645 ICE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0650 FOOD SUPPLIES",
                        "type": "reg",
                        "id": "70c2b4f8-e330-4513-bdb5-0b225f8291c5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F70c2b4f8-e330-4513-bdb5-0b225f8291c5.pdf?alt=media&token=4c99ce91-9091-4b17-aa09-ad036bba0679",
                        "fileExt": ".pdf",
                        "fileName": "4658.0650 FOOD SUPPLIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0655 TRANSPORT OF FOOD",
                        "type": "reg",
                        "id": "ccc17eb4-d5f9-4b53-90f0-ba524ce62dc0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fccc17eb4-d5f9-4b53-90f0-ba524ce62dc0.pdf?alt=media&token=63e0f72a-8495-4c90-ba19-68b3b14b79e2",
                        "fileExt": ".pdf",
                        "fileName": "4658.0655 TRANSPORT OF FOOD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0660 FLOOR CLEANING AND TRASH",
                        "type": "reg",
                        "id": "a8b5d390-ff95-4dd7-bd10-270f68c4b25d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa8b5d390-ff95-4dd7-bd10-270f68c4b25d.pdf?alt=media&token=b868611f-d7eb-454b-bbe9-c22581c3a205",
                        "fileExt": ".pdf",
                        "fileName": "4658.0660 FLOOR CLEANING AND TRASH.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0665 DISHES AND UTENSILS REQUIREMENTS",
                        "type": "reg",
                        "id": "bf4a3359-fca7-46e9-9ed5-12394319589b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbf4a3359-fca7-46e9-9ed5-12394319589b.pdf?alt=media&token=f14c396f-cfbd-4700-a0fc-4276c49e1aee",
                        "fileExt": ".pdf",
                        "fileName": "4658.0665 DISHES AND UTENSILS REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0670 DISHWASHING",
                        "type": "reg",
                        "id": "faad964b-632c-4735-b7d2-dd89e6d43e25",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ffaad964b-632c-4735-b7d2-dd89e6d43e25.pdf?alt=media&token=dfe58052-8443-4384-8fbf-cca975b85d3f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0670 DISHWASHING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0675 MECHANICAL CLEANING AND SANITIZING",
                        "type": "reg",
                        "id": "6bd912ab-6b65-4f14-92df-b1ef13a7d030",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6bd912ab-6b65-4f14-92df-b1ef13a7d030.pdf?alt=media&token=59433624-9a28-4750-8e83-82f637110ebc",
                        "fileExt": ".pdf",
                        "fileName": "4658.0675 MECHANICAL CLEANING AND SANITIZING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0680 MANUAL CLEANING AND SANITIZING",
                        "type": "reg",
                        "id": "9c7220cd-38e9-4aa6-b330-0957484fda25",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9c7220cd-38e9-4aa6-b330-0957484fda25.pdf?alt=media&token=c5a37389-53a2-4911-a08c-5a4f3e533bdf",
                        "fileExt": ".pdf",
                        "fileName": "4658.0680 MANUAL CLEANING AND SANITIZING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0685 PENALTIES FOR DIETARY AND FOOD SERVICES AND",
                        "type": "reg",
                        "id": "35c169f2-b2b7-4f34-b461-f93b25752429",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F35c169f2-b2b7-4f34-b461-f93b25752429.pdf?alt=media&token=dd6326ea-d49c-44ce-a601-090d07749b7c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0685 PENALTIES FOR DIETARY AND FOOD SERVICES AND.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Electrical Systems - Existing Construction",
                    "type": "dir",
                    "id": "54e65545-40f0-4fe8-ba96-7af62c85e612",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Electrical Systems - Existing Construction",
                    "items": [
                      {
                        "name": "4658.5500 DISTRIBUTION PANEL BOARDS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "d7814c88-59b1-46ca-8034-7320b3aa5b6a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd7814c88-59b1-46ca-8034-7320b3aa5b6a.pdf?alt=media&token=e98dfe66-6e65-494e-905b-5611f4ab8b71",
                        "fileExt": ".pdf",
                        "fileName": "4658.5500 DISTRIBUTION PANEL BOARDS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5505 INTERIOR LIGHTING; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "0b0b1790-cbf8-4c77-82dd-4f7d9b8a1416",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0b0b1790-cbf8-4c77-82dd-4f7d9b8a1416.pdf?alt=media&token=c6f09652-bed9-4f4d-a6a0-2371ade835a4",
                        "fileExt": ".pdf",
                        "fileName": "4658.5505 INTERIOR LIGHTING; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5510 FIRE ALARM SYSTEMS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "5bf3d6a4-22d0-420b-af79-d7357e932512",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5bf3d6a4-22d0-420b-af79-d7357e932512.pdf?alt=media&token=b79241f4-866e-4111-9c55-39a5be78fbbd",
                        "fileExt": ".pdf",
                        "fileName": "4658.5510 FIRE ALARM SYSTEMS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5515 NURSE CALL SYSTEM; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "435f6946-5d36-469c-be1a-92bd7ca94440",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F435f6946-5d36-469c-be1a-92bd7ca94440.pdf?alt=media&token=b1b03a20-335c-4a2e-b73f-e462bfbf1a4f",
                        "fileExt": ".pdf",
                        "fileName": "4658.5515 NURSE CALL SYSTEM; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5520 EMERGENCY ELECTRIC SERVICE; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "dc7d7096-6ab0-41f8-ab54-3c2b50c16472",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdc7d7096-6ab0-41f8-ab54-3c2b50c16472.pdf?alt=media&token=026750b8-1504-4604-bb50-43b05cf917a7",
                        "fileExt": ".pdf",
                        "fileName": "4658.5520 EMERGENCY ELECTRIC SERVICE; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5590 PENALTIES FOR ELECTRICAL SYSTEMS; EXISTING",
                        "type": "reg",
                        "id": "161a8d16-916c-46cc-887c-a95492c3a80a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F161a8d16-916c-46cc-887c-a95492c3a80a.pdf?alt=media&token=34898f69-119f-40b9-bb5f-9efedbc4722c",
                        "fileExt": ".pdf",
                        "fileName": "4658.5590 PENALTIES FOR ELECTRICAL SYSTEMS; EXISTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Electrical Systems - New Construction",
                    "type": "dir",
                    "id": "17da6d88-9513-4f5d-aae3-0bb73ddb3e06",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Electrical Systems - New Construction",
                    "items": [
                      {
                        "name": "4658.4600 DISTRIBUTION PANEL BOARDS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "548f5d1e-f872-4343-a5ea-7199e36ad8b6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F548f5d1e-f872-4343-a5ea-7199e36ad8b6.pdf?alt=media&token=985e637f-9beb-4f4c-a9c0-db49f39e86ec",
                        "fileExt": ".pdf",
                        "fileName": "4658.4600 DISTRIBUTION PANEL BOARDS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4605 CORRIDOR RECEPTACLES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "0e5bd68c-e414-42e8-97c1-29b8a6e955d5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0e5bd68c-e414-42e8-97c1-29b8a6e955d5.pdf?alt=media&token=c47f7ce8-36f5-4b5b-96df-6ba429a88228",
                        "fileExt": ".pdf",
                        "fileName": "4658.4605 CORRIDOR RECEPTACLES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4610 SWITCHES AND RECEPTACLES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "2bc160f8-8ab1-4fa3-9515-abb11c340a10",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2bc160f8-8ab1-4fa3-9515-abb11c340a10.pdf?alt=media&token=ae957447-df03-466c-9e4f-e72c0d708e28",
                        "fileExt": ".pdf",
                        "fileName": "4658.4610 SWITCHES AND RECEPTACLES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4615 INTERIOR LIGHTING; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "904e6e18-99c8-452b-94d2-93250ec2beff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F904e6e18-99c8-452b-94d2-93250ec2beff.pdf?alt=media&token=093c7f2b-41a6-454e-b48e-936dee629909",
                        "fileExt": ".pdf",
                        "fileName": "4658.4615 INTERIOR LIGHTING; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4620 FIRE ALARM SYSTEMS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "063d024d-6a2e-4c15-83fe-45449e1ae251",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F063d024d-6a2e-4c15-83fe-45449e1ae251.pdf?alt=media&token=79835713-59ad-4f23-a86d-6dcf98ca5c32",
                        "fileExt": ".pdf",
                        "fileName": "4658.4620 FIRE ALARM SYSTEMS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4625 BEDROOM RECEPTACLES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "cc2e9b7e-7b1a-44b7-9d7e-5c7d6868f3d7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcc2e9b7e-7b1a-44b7-9d7e-5c7d6868f3d7.pdf?alt=media&token=92cbbfe5-8013-4e8c-9363-776418f2df5e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4625 BEDROOM RECEPTACLES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4630 NIGHT LIGHTS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "c868e408-177b-41c9-81bb-74913505fc48",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc868e408-177b-41c9-81bb-74913505fc48.pdf?alt=media&token=103f37f4-84fb-40f3-9613-b7e4cabfaf0e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4630 NIGHT LIGHTS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4635 NURSE CALL SYSTEM; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "0880e234-7810-4d53-82eb-2ce1763e39b5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0880e234-7810-4d53-82eb-2ce1763e39b5.pdf?alt=media&token=85d8e884-014a-468d-ad81-78cd99581e3f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4635 NURSE CALL SYSTEM; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4640 EMERGENCY ELECTRIC SERVICE; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "9c3ed846-98fe-43a7-9c21-f72bc2950186",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9c3ed846-98fe-43a7-9c21-f72bc2950186.pdf?alt=media&token=f60da8da-0dc7-4867-80f9-f1bef42c20d9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4640 EMERGENCY ELECTRIC SERVICE; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4690 PENALTIES FOR ELECTRICAL SYSTEMS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "09e9cda5-4e91-4086-a49b-49da0e811dae",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F09e9cda5-4e91-4086-a49b-49da0e811dae.pdf?alt=media&token=678cd684-64ee-44f0-a2b6-f2d3ac88411f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4690 PENALTIES FOR ELECTRICAL SYSTEMS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Environmental Services",
                    "type": "dir",
                    "id": "47eb365b-863b-49ea-b882-26969ac8e4ba",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Environmental Services",
                    "items": [
                      {
                        "name": "4658.1400 PHYSICAL ENVIRONMENT",
                        "type": "reg",
                        "id": "cca74ebc-0942-4cd2-b0ff-d3c32e8b7a78",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcca74ebc-0942-4cd2-b0ff-d3c32e8b7a78.pdf?alt=media&token=646a3a6d-4bc8-4b70-83cd-d9e8dd3216da",
                        "fileExt": ".pdf",
                        "fileName": "4658.1400 PHYSICAL ENVIRONMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1405 RESIDENT UNITS",
                        "type": "reg",
                        "id": "989d5855-5177-4c1d-8770-2c053a528e35",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F989d5855-5177-4c1d-8770-2c053a528e35.pdf?alt=media&token=043acbdd-b0df-4106-bd1c-3c07e10e5a28",
                        "fileExt": ".pdf",
                        "fileName": "4658.1405 RESIDENT UNITS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1410 LINEN",
                        "type": "reg",
                        "id": "73a92ecf-46bc-466a-826c-6206003f0f6d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F73a92ecf-46bc-466a-826c-6206003f0f6d.pdf?alt=media&token=354aa4d2-c89f-4ba8-90e7-d6ad87929fe8",
                        "fileExt": ".pdf",
                        "fileName": "4658.1410 LINEN.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1415 PLANT HOUSEKEEPING, OPERATION, AND MAINTENANCE",
                        "type": "reg",
                        "id": "8231868a-0b4c-4d3d-8197-27bb56ed487e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8231868a-0b4c-4d3d-8197-27bb56ed487e.pdf?alt=media&token=f8913f4e-cdc1-4e06-a963-8c34ed3ac077",
                        "fileExt": ".pdf",
                        "fileName": "4658.1415 PLANT HOUSEKEEPING, OPERATION, AND MAINTENANCE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1420 SOLID WASTE DISPOSAL",
                        "type": "reg",
                        "id": "ec438c12-f952-4d9d-8c14-5bfb5bea9c5b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fec438c12-f952-4d9d-8c14-5bfb5bea9c5b.pdf?alt=media&token=bffaa8f4-9cac-47f8-9b38-5a607138ce16",
                        "fileExt": ".pdf",
                        "fileName": "4658.1420 SOLID WASTE DISPOSAL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1425 OZONE GENERATORS",
                        "type": "reg",
                        "id": "de829ddc-cf7c-4da3-ab07-5603a269bd44",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fde829ddc-cf7c-4da3-ab07-5603a269bd44.pdf?alt=media&token=9a4d869a-8335-48e6-ad71-c4720f0ec6da",
                        "fileExt": ".pdf",
                        "fileName": "4658.1425 OZONE GENERATORS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1490 PENALTIES FOR ENVIRONMENTAL SERVICES RULE",
                        "type": "reg",
                        "id": "d094303a-3617-4849-b0b1-12428b8e62bf",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd094303a-3617-4849-b0b1-12428b8e62bf.pdf?alt=media&token=bcebd207-12ac-46d9-a9cc-7d8d20ec1566",
                        "fileExt": ".pdf",
                        "fileName": "4658.1490 PENALTIES FOR ENVIRONMENTAL SERVICES RULE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Infection Control",
                    "type": "dir",
                    "id": "28e1aea5-7f42-43b6-873b-a93b1c163686",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Infection Control",
                    "items": [
                      {
                        "name": "4658.0800 INFECTION CONTROL",
                        "type": "reg",
                        "id": "3ea02ff5-70ac-4486-8284-2a02cfc58fcc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3ea02ff5-70ac-4486-8284-2a02cfc58fcc.pdf?alt=media&token=32c5df91-089c-4757-894f-6e8a1029eb5b",
                        "fileExt": ".pdf",
                        "fileName": "4658.0800 INFECTION CONTROL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0810",
                        "type": "reg",
                        "id": "ba3c2ba3-79d4-4b04-ac6d-30833969e16f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fba3c2ba3-79d4-4b04-ac6d-30833969e16f.pdf?alt=media&token=622b9ca9-1f8c-4b1d-82d2-79c870e5c46b",
                        "fileExt": ".pdf",
                        "fileName": "4658.0810.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0820 FOOD POISONING AND DISEASE REPORTING",
                        "type": "reg",
                        "id": "356e80e3-99e1-4f9f-ab90-9370c076273f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F356e80e3-99e1-4f9f-ab90-9370c076273f.pdf?alt=media&token=fcaa91b0-cce1-4e7b-ad98-ca4072a1eff2",
                        "fileExt": ".pdf",
                        "fileName": "4658.0820 FOOD POISONING AND DISEASE REPORTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0850 PENALTIES FOR INFECTION CONTROL RULE VIOLATIONS",
                        "type": "reg",
                        "id": "7aa5e5e8-65b1-4b6f-b28b-778d8344b625",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7aa5e5e8-65b1-4b6f-b28b-778d8344b625.pdf?alt=media&token=17fc25e1-e242-4ab1-aeec-24b1000cff04",
                        "fileExt": ".pdf",
                        "fileName": "4658.0850 PENALTIES FOR INFECTION CONTROL RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Licensing",
                    "type": "dir",
                    "id": "3c77f08f-ebbf-4e28-92d3-8a052a5409eb",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Licensing",
                    "items": [
                      {
                        "name": "4658.0010 DEFINITIONS",
                        "type": "reg",
                        "id": "a6a2341a-3058-4ea7-bb64-bd5dae88c38b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa6a2341a-3058-4ea7-bb64-bd5dae88c38b.pdf?alt=media&token=670313fd-91d1-4755-ace0-a51eb0f64254",
                        "fileExt": ".pdf",
                        "fileName": "4658.0010 DEFINITIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0015 COMPLIANCE WITH REGULATIONS AND STANDARDS",
                        "type": "reg",
                        "id": "d50d7b84-864c-4d56-ba70-441c8ad06a52",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd50d7b84-864c-4d56-ba70-441c8ad06a52.pdf?alt=media&token=9737dcbb-3dab-4ed3-b0cf-6855b37c7269",
                        "fileExt": ".pdf",
                        "fileName": "4658.0015 COMPLIANCE WITH REGULATIONS AND STANDARDS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0020 LICENSING IN GENERAL",
                        "type": "reg",
                        "id": "63312089-8db4-4c73-aa09-2f7fb0df2d51",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F63312089-8db4-4c73-aa09-2f7fb0df2d51.pdf?alt=media&token=77b9dfd0-933f-4ad0-9a83-088f120e28c3",
                        "fileExt": ".pdf",
                        "fileName": "4658.0020 LICENSING IN GENERAL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0025 PROCEDURES FOR LICENSING NURSING HOMES",
                        "type": "reg",
                        "id": "3847be54-e5c1-401e-84c5-74a1751655fc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3847be54-e5c1-401e-84c5-74a1751655fc.pdf?alt=media&token=1bbcba51-8600-44cd-862d-5b9f5899e0d8",
                        "fileExt": ".pdf",
                        "fileName": "4658.0025 PROCEDURES FOR LICENSING NURSING HOMES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0030 CAPACITY PRESCRIBED",
                        "type": "reg",
                        "id": "648c3eaf-ba88-4034-848e-5c189f785f4d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F648c3eaf-ba88-4034-848e-5c189f785f4d.pdf?alt=media&token=653c8df2-bce1-4160-86e9-634be97334e3",
                        "fileExt": ".pdf",
                        "fileName": "4658.0030 CAPACITY PRESCRIBED.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0035 EVALUATION",
                        "type": "reg",
                        "id": "cdc2ed47-8cdc-4c34-906e-fb2f378d6561",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcdc2ed47-8cdc-4c34-906e-fb2f378d6561.pdf?alt=media&token=ad42b09c-f82e-44f6-8ec1-3cee23fdf50e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0035 EVALUATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0040 VARIANCE AND WAIVER",
                        "type": "reg",
                        "id": "b2cc70cd-b59f-40ec-8b9c-b6edf4ae0e8d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb2cc70cd-b59f-40ec-8b9c-b6edf4ae0e8d.pdf?alt=media&token=cabe21ce-7e64-4eab-a36d-f568e74e29e5",
                        "fileExt": ".pdf",
                        "fileName": "4658.0040 VARIANCE AND WAIVER.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0045 PENALTIES FOR LICENSING RULE VIOLATIONS",
                        "type": "reg",
                        "id": "831ee8e2-c2fa-4c10-8c7f-2183b56d3f30",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F831ee8e2-c2fa-4c10-8c7f-2183b56d3f30.pdf?alt=media&token=bd14cfed-8a49-4fd5-8e46-fecc163bbf95",
                        "fileExt": ".pdf",
                        "fileName": "4658.0045 PENALTIES FOR LICENSING RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Mechanical Systems - Existing Construction",
                    "type": "dir",
                    "id": "be6d0441-39fc-4f6f-8ad6-bd4bdc20bd38",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Mechanical Systems - Existing Construction",
                    "items": [
                      {
                        "name": "4658.5400 HEATING SYSTEM; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "85a519e2-8c65-4182-98e5-36377cec67e8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F85a519e2-8c65-4182-98e5-36377cec67e8.pdf?alt=media&token=9200f8ea-18d1-4ee4-b957-d95f0e2232dc",
                        "fileExt": ".pdf",
                        "fileName": "4658.5400 HEATING SYSTEM; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5405 VENTILATION REQUIREMENTS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "d8a5f883-7b38-415c-b654-22e145ed3291",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd8a5f883-7b38-415c-b654-22e145ed3291.pdf?alt=media&token=5e44d28b-2795-4ca5-a08b-624530b518a8",
                        "fileExt": ".pdf",
                        "fileName": "4658.5405 VENTILATION REQUIREMENTS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5410 MECHANICAL ROOMS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "1c578ab8-8b26-4ade-ab83-3f1f251914be",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1c578ab8-8b26-4ade-ab83-3f1f251914be.pdf?alt=media&token=06a84930-957c-466f-80e1-e41bcfb80a5e",
                        "fileExt": ".pdf",
                        "fileName": "4658.5410 MECHANICAL ROOMS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5415 FILTERS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "f1272f47-147d-425d-a21e-bab2ed2de102",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff1272f47-147d-425d-a21e-bab2ed2de102.pdf?alt=media&token=5682e6d6-a02d-4319-bce6-3640f2473278",
                        "fileExt": ".pdf",
                        "fileName": "4658.5415 FILTERS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5490 PENALTIES FOR HEATING AND VENTILATION SYSTEMS;",
                        "type": "reg",
                        "id": "5d94fc87-45a0-458f-a19c-f0e883bff8b8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5d94fc87-45a0-458f-a19c-f0e883bff8b8.pdf?alt=media&token=18f410dd-1a96-4cf9-b3a0-7b2029c7f079",
                        "fileExt": ".pdf",
                        "fileName": "4658.5490 PENALTIES FOR HEATING AND VENTILATION SYSTEMS;.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Mechanical Systems - New Construction",
                    "type": "dir",
                    "id": "c0fefa84-50c6-41d8-a288-7cca53b01f49",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Mechanical Systems - New Construction",
                    "items": [
                      {
                        "name": "4658.4500 PLUMBING SYSTEMS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "33b2a135-9f7d-4719-9ef4-b3f823bfdcff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F33b2a135-9f7d-4719-9ef4-b3f823bfdcff.pdf?alt=media&token=02d5c5a3-d0ad-4a78-9bb1-6bbcc8287f0f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4500 PLUMBING SYSTEMS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4505 PLUMBING; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "22945f31-456a-4ea6-b39a-01e07f307838",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F22945f31-456a-4ea6-b39a-01e07f307838.pdf?alt=media&token=9e04dfb9-c097-46f5-a89c-7f79506b3eac",
                        "fileExt": ".pdf",
                        "fileName": "4658.4505 PLUMBING; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4510 HEATING AND COOLING; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "c2a7a1b1-1301-4479-b5f6-caf2990878eb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc2a7a1b1-1301-4479-b5f6-caf2990878eb.pdf?alt=media&token=cac19ea7-b5fc-4865-9985-f2cc8da734b9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4510 HEATING AND COOLING; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4515 VENTILATION REQUIREMENTS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "9f8886dd-20a1-4a72-8326-6693658b01cc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9f8886dd-20a1-4a72-8326-6693658b01cc.pdf?alt=media&token=1075da9d-b5b4-429a-9e01-41a8ca3ad7d9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4515 VENTILATION REQUIREMENTS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4520 VENTILATION PRESSURE RELATIONSHIPS AND VENTILATION",
                        "type": "reg",
                        "id": "c8bf50ea-8b37-433b-bfe5-ce0f1397cb7d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc8bf50ea-8b37-433b-bfe5-ce0f1397cb7d.pdf?alt=media&token=366ce016-065d-4699-93e0-c06f55c2e778",
                        "fileExt": ".pdf",
                        "fileName": "4658.4520 VENTILATION PRESSURE RELATIONSHIPS AND VENTILATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4525 FRESH AIR INTAKES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "9ea6507f-a88e-4f26-b10a-12a9c598d8b1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9ea6507f-a88e-4f26-b10a-12a9c598d8b1.pdf?alt=media&token=948f8c86-a9fc-40d4-ab18-db1e4a42db07",
                        "fileExt": ".pdf",
                        "fileName": "4658.4525 FRESH AIR INTAKES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4530 HEIGHT OF REGISTERS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "7cf10298-b9b7-407e-a531-b606966fbb6e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7cf10298-b9b7-407e-a531-b606966fbb6e.pdf?alt=media&token=50ac714c-590b-459e-93d2-90c8c636f9e9",
                        "fileExt": ".pdf",
                        "fileName": "4658.4530 HEIGHT OF REGISTERS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4535 DIETARY AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "66891703-f45c-40e6-ba6c-6987de72fa0d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F66891703-f45c-40e6-ba6c-6987de72fa0d.pdf?alt=media&token=5a1f032e-afe0-421e-a098-66f9aa7b7a91",
                        "fileExt": ".pdf",
                        "fileName": "4658.4535 DIETARY AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4540 LAUNDRY AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "7aafc2bf-aeca-4c15-8f2b-2944149c06ff",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7aafc2bf-aeca-4c15-8f2b-2944149c06ff.pdf?alt=media&token=5a3b747e-8164-44b0-b3d3-f33e75d7aaac",
                        "fileExt": ".pdf",
                        "fileName": "4658.4540 LAUNDRY AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4545 MECHANICAL ROOMS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "6da10490-63d6-42ca-8786-37714fc667d9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6da10490-63d6-42ca-8786-37714fc667d9.pdf?alt=media&token=9e17a820-fbf9-429d-bb4e-30c4f939ee48",
                        "fileExt": ".pdf",
                        "fileName": "4658.4545 MECHANICAL ROOMS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4550 FILTERS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "78fabd61-c21f-44a5-ae06-dc9f76237ca8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F78fabd61-c21f-44a5-ae06-dc9f76237ca8.pdf?alt=media&token=eca3646a-a251-4004-b34b-22ac7db5f5d0",
                        "fileExt": ".pdf",
                        "fileName": "4658.4550 FILTERS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4590 PENALTIES FOR MECHANICAL SYSTEMS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "858e27fb-d5ad-418c-957f-4830a25a3b99",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F858e27fb-d5ad-418c-957f-4830a25a3b99.pdf?alt=media&token=4d287e5b-209c-4358-b854-f9bdefff2370",
                        "fileExt": ".pdf",
                        "fileName": "4658.4590 PENALTIES FOR MECHANICAL SYSTEMS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Medical and Dental Services",
                    "type": "dir",
                    "id": "62beb0bd-4e87-431f-9025-cf8941910037",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Medical and Dental Services",
                    "items": [
                      {
                        "name": "4658.0700 MEDICAL DIRECTOR",
                        "type": "reg",
                        "id": "6ffaa34c-c21f-4f70-84cc-b03b0e7051a0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6ffaa34c-c21f-4f70-84cc-b03b0e7051a0.pdf?alt=media&token=5fadbb1e-d6e0-4fe7-8bd3-1ab5cf94244e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0700 MEDICAL DIRECTOR.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0705 MEDICAL CARE AND TREATMENT",
                        "type": "reg",
                        "id": "bdde2a8a-e98a-41a2-ae52-4c6789f62c0c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbdde2a8a-e98a-41a2-ae52-4c6789f62c0c.pdf?alt=media&token=de560cd1-8bc4-463c-b3ba-876d3a3da78c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0705 MEDICAL CARE AND TREATMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0710 ADMISSION ORDERS AND PHYSICIAN EVALUATIONS",
                        "type": "reg",
                        "id": "c4d8fe8f-7c44-4922-8b14-0194af1fa68c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc4d8fe8f-7c44-4922-8b14-0194af1fa68c.pdf?alt=media&token=2cfad324-5ac1-4b20-a1d4-4567ace00fc8",
                        "fileExt": ".pdf",
                        "fileName": "4658.0710 ADMISSION ORDERS AND PHYSICIAN EVALUATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0715 MEDICAL INFORMATION FOR CLINICAL RECORD",
                        "type": "reg",
                        "id": "7d0033dc-7df4-4650-8be7-1dd7b3c58715",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7d0033dc-7df4-4650-8be7-1dd7b3c58715.pdf?alt=media&token=2d29cf12-ea6c-4f6f-9c78-1498c14ac012",
                        "fileExt": ".pdf",
                        "fileName": "4658.0715 MEDICAL INFORMATION FOR CLINICAL RECORD.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0720 PROVIDING DAILY ORAL CARE",
                        "type": "reg",
                        "id": "2675beae-8798-4a09-853f-a2527bbf0566",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2675beae-8798-4a09-853f-a2527bbf0566.pdf?alt=media&token=5c66828b-cea3-4ca8-b879-b314150c2b8c",
                        "fileExt": ".pdf",
                        "fileName": "4658.0720 PROVIDING DAILY ORAL CARE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0725 PROVIDING ROUTINE AND EMERGENCY ORAL HEALTH",
                        "type": "reg",
                        "id": "e390dcf0-d8a8-4616-bff4-77d2a6cac902",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe390dcf0-d8a8-4616-bff4-77d2a6cac902.pdf?alt=media&token=f5080037-84e6-43bc-98d8-0fab7cf20e06",
                        "fileExt": ".pdf",
                        "fileName": "4658.0725 PROVIDING ROUTINE AND EMERGENCY ORAL HEALTH.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0730 NURSING HOME REQUIREMENTS",
                        "type": "reg",
                        "id": "4c4a0983-df0e-43ae-8116-1e7859f66717",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4c4a0983-df0e-43ae-8116-1e7859f66717.pdf?alt=media&token=1fead029-daba-4df6-9471-b288c4ee7f1e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0730 NURSING HOME REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0750 PENALTIES FOR PHYSICIAN AND DENTAL SERVICES RULE",
                        "type": "reg",
                        "id": "d6fc0c82-82ef-47dc-b0ae-d9664a566457",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd6fc0c82-82ef-47dc-b0ae-d9664a566457.pdf?alt=media&token=a4731a2c-2002-4268-907d-d26eafd83712",
                        "fileExt": ".pdf",
                        "fileName": "4658.0750 PENALTIES FOR PHYSICIAN AND DENTAL SERVICES RULE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Medications",
                    "type": "dir",
                    "id": "8203c808-a4ff-4589-8442-576e233ddecb",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Medications",
                    "items": [
                      {
                        "name": "4658.1300 MEDICATIONS AND PHARMACY SERVICES; DEFINITIONS",
                        "type": "reg",
                        "id": "23723bb4-5d22-4444-97a7-90538f9ff97b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F23723bb4-5d22-4444-97a7-90538f9ff97b.pdf?alt=media&token=20eb3642-2ae2-4ccb-96e8-f253cdbd50a4",
                        "fileExt": ".pdf",
                        "fileName": "4658.1300 MEDICATIONS AND PHARMACY SERVICES; DEFINITIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1305 PHARMACIST SERVICE CONSULTATION",
                        "type": "reg",
                        "id": "b7e45da8-4468-4e70-bc39-cf73a75004c0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb7e45da8-4468-4e70-bc39-cf73a75004c0.pdf?alt=media&token=ced945c8-5cb4-4bc9-bede-7fb1a1712671",
                        "fileExt": ".pdf",
                        "fileName": "4658.1305 PHARMACIST SERVICE CONSULTATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1310 DRUG REGIMEN REVIEW",
                        "type": "reg",
                        "id": "ab3f212c-5f3a-4107-8cd5-727f17a8605d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fab3f212c-5f3a-4107-8cd5-727f17a8605d.pdf?alt=media&token=75619455-2926-41cd-baf1-372722ca955c",
                        "fileExt": ".pdf",
                        "fileName": "4658.1310 DRUG REGIMEN REVIEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1315 UNNECESSARY DRUG USAGE",
                        "type": "reg",
                        "id": "196176ea-fcdb-4c13-a580-9bfe53097ac5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F196176ea-fcdb-4c13-a580-9bfe53097ac5.pdf?alt=media&token=81960104-de2b-407c-8d78-02360f3073db",
                        "fileExt": ".pdf",
                        "fileName": "4658.1315 UNNECESSARY DRUG USAGE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1320 MEDICATION ERRORS",
                        "type": "reg",
                        "id": "81487862-21a1-4567-9632-97da2e3b1ccc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F81487862-21a1-4567-9632-97da2e3b1ccc.pdf?alt=media&token=0e22a37e-e99f-491b-a375-9e713acf074f",
                        "fileExt": ".pdf",
                        "fileName": "4658.1320 MEDICATION ERRORS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1325 ADMINISTRATION OF MEDICATIONS",
                        "type": "reg",
                        "id": "b4255e11-bc74-48cd-b22c-5ea303c3cfb4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb4255e11-bc74-48cd-b22c-5ea303c3cfb4.pdf?alt=media&token=ad283c77-cebc-4882-a72e-8e8aae4e13b8",
                        "fileExt": ".pdf",
                        "fileName": "4658.1325 ADMINISTRATION OF MEDICATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1330 WRITTEN AUTHORIZATION FOR ADMINISTERING DRUGS",
                        "type": "reg",
                        "id": "2d97666f-3010-47a6-b1f9-c7f61a537486",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2d97666f-3010-47a6-b1f9-c7f61a537486.pdf?alt=media&token=4344f182-196c-44eb-855a-e83d787a47bc",
                        "fileExt": ".pdf",
                        "fileName": "4658.1330 WRITTEN AUTHORIZATION FOR ADMINISTERING DRUGS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1335 STOCK MEDICATIONS",
                        "type": "reg",
                        "id": "1b4152de-7b7a-4ca5-9d42-82b650056add",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1b4152de-7b7a-4ca5-9d42-82b650056add.pdf?alt=media&token=311b79bf-00ef-4938-a7c5-3bfa0f7c5f26",
                        "fileExt": ".pdf",
                        "fileName": "4658.1335 STOCK MEDICATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1340 MEDICINE CABINET AND PREPARATION AREA",
                        "type": "reg",
                        "id": "056947e8-bc18-4f5e-a1e3-07c1eb6ef632",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F056947e8-bc18-4f5e-a1e3-07c1eb6ef632.pdf?alt=media&token=cd539fa1-22d0-46e9-ae77-b390138da217",
                        "fileExt": ".pdf",
                        "fileName": "4658.1340 MEDICINE CABINET AND PREPARATION AREA.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1345 LABELING OF DRUGS",
                        "type": "reg",
                        "id": "35dc1646-3d38-4d16-9a9f-a5319f7e1ec4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F35dc1646-3d38-4d16-9a9f-a5319f7e1ec4.pdf?alt=media&token=27e77fcf-3f44-46af-94df-049dd372f059",
                        "fileExt": ".pdf",
                        "fileName": "4658.1345 LABELING OF DRUGS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1350 DISPOSITION OF MEDICATIONS",
                        "type": "reg",
                        "id": "64211e97-df55-4421-b5c4-50192936c2c3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F64211e97-df55-4421-b5c4-50192936c2c3.pdf?alt=media&token=99996ed6-59ad-467c-aabe-795ca18fc688",
                        "fileExt": ".pdf",
                        "fileName": "4658.1350 DISPOSITION OF MEDICATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1355 MEDICATION REFERENCE BOOK",
                        "type": "reg",
                        "id": "1cecd388-8396-4f95-b324-22f5f02cdf3b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1cecd388-8396-4f95-b324-22f5f02cdf3b.pdf?alt=media&token=76459a95-c877-46bc-aa6c-d5f966c7e090",
                        "fileExt": ".pdf",
                        "fileName": "4658.1355 MEDICATION REFERENCE BOOK.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1360 ADMINISTRATION OF MEDICATIONS BY UNLICENSED",
                        "type": "reg",
                        "id": "f120172d-7567-4888-99dd-07e36fb52ade",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff120172d-7567-4888-99dd-07e36fb52ade.pdf?alt=media&token=5156fae4-210b-4f85-96a8-58d82b66f503",
                        "fileExt": ".pdf",
                        "fileName": "4658.1360 ADMINISTRATION OF MEDICATIONS BY UNLICENSED.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1365 PENALTIES FOR MEDICATIONS AND PHARMACY SERVICES",
                        "type": "reg",
                        "id": "f4a34c57-a5fc-44b2-96e1-ee8a48847a46",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff4a34c57-a5fc-44b2-96e1-ee8a48847a46.pdf?alt=media&token=2c79629a-77d9-4420-857c-3611c605f3f8",
                        "fileExt": ".pdf",
                        "fileName": "4658.1365 PENALTIES FOR MEDICATIONS AND PHARMACY SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "New Construction - Plans",
                    "type": "dir",
                    "id": "55519475-baae-4108-8388-a472608f7580",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/New Construction - Plans",
                    "items": [
                      {
                        "name": "4658.3500 INCORPORATION BY REFERENCE; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "51d4b9a6-b3c2-419d-aa4f-2d9ae58ddd17",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F51d4b9a6-b3c2-419d-aa4f-2d9ae58ddd17.pdf?alt=media&token=098ac90d-c18b-4462-8824-343260651115",
                        "fileExt": ".pdf",
                        "fileName": "4658.3500 INCORPORATION BY REFERENCE; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.3590 PENALTIES FOR INCORPORATIONS BY REFERENCE; NEW",
                        "type": "reg",
                        "id": "6244649d-5c7c-4a3c-8359-6ff4328d6c46",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6244649d-5c7c-4a3c-8359-6ff4328d6c46.pdf?alt=media&token=ecad3419-c86f-4fe7-9dc9-44c54239d82e",
                        "fileExt": ".pdf",
                        "fileName": "4658.3590 PENALTIES FOR INCORPORATIONS BY REFERENCE; NEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4000 PREPARATION OF PLANS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "29eb87fb-2a3b-4c30-b998-bae93a63c855",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F29eb87fb-2a3b-4c30-b998-bae93a63c855.pdf?alt=media&token=2ce00814-2597-490a-9c72-b2ae312ba37a",
                        "fileExt": ".pdf",
                        "fileName": "4658.4000 PREPARATION OF PLANS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4005 APPROVAL OF PLANS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "af05aba9-bdf5-4313-a333-b6db288d59ea",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Faf05aba9-bdf5-4313-a333-b6db288d59ea.pdf?alt=media&token=465b447d-6db8-4a95-8792-bd4b6bd4a148",
                        "fileExt": ".pdf",
                        "fileName": "4658.4005 APPROVAL OF PLANS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4010 PRELIMINARY PLANS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "57096373-ada4-40f9-82ef-b1bb157fa0e8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F57096373-ada4-40f9-82ef-b1bb157fa0e8.pdf?alt=media&token=2f25dd08-e67f-4c76-b11c-f381dc186bec",
                        "fileExt": ".pdf",
                        "fileName": "4658.4010 PRELIMINARY PLANS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4015 FINAL PLANS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "c038602a-614d-46e6-bfa5-c980b72db3c2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc038602a-614d-46e6-bfa5-c980b72db3c2.pdf?alt=media&token=5f3d1df8-5a12-420d-bbea-38cf9be6a49b",
                        "fileExt": ".pdf",
                        "fileName": "4658.4015 FINAL PLANS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4020 FINAL MECHANICAL AND ELECTRICAL PLANS; NEW",
                        "type": "reg",
                        "id": "5c39c309-6c56-4c80-af3e-76365e0755d4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5c39c309-6c56-4c80-af3e-76365e0755d4.pdf?alt=media&token=8e11d8d0-d819-4848-a13b-2790536787d4",
                        "fileExt": ".pdf",
                        "fileName": "4658.4020 FINAL MECHANICAL AND ELECTRICAL PLANS; NEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4025 START OF CONSTRUCTION; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "b05e8393-9872-44af-b3be-fd4de2a501c5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb05e8393-9872-44af-b3be-fd4de2a501c5.pdf?alt=media&token=de54f812-3a38-44e3-9bbe-8c511a82489e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4025 START OF CONSTRUCTION; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4030 FINAL INSPECTION; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "1c3c2f49-36d0-47c3-be06-5c238b3bf0c4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1c3c2f49-36d0-47c3-be06-5c238b3bf0c4.pdf?alt=media&token=6b311ac8-3b7d-4597-9f2d-3bbac712eefd",
                        "fileExt": ".pdf",
                        "fileName": "4658.4030 FINAL INSPECTION; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4035 PLAN SAFEKEEPING; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "e10bcfd4-6d1d-48c1-8ec0-a3c9df14a628",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe10bcfd4-6d1d-48c1-8ec0-a3c9df14a628.pdf?alt=media&token=bf10c30b-0785-4458-9900-b0a9e669833d",
                        "fileExt": ".pdf",
                        "fileName": "4658.4035 PLAN SAFEKEEPING; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4040 SITE; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "274abacc-8a4a-46ce-a442-6163672ce991",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F274abacc-8a4a-46ce-a442-6163672ce991.pdf?alt=media&token=02715519-b99a-4da4-86fb-6e852b80dac4",
                        "fileExt": ".pdf",
                        "fileName": "4658.4040 SITE; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4090 PENALTIES FOR PLANS; NEW CONSTRUCTION RULE",
                        "type": "reg",
                        "id": "52afef14-72ea-41ca-be6f-438d598e0576",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F52afef14-72ea-41ca-be6f-438d598e0576.pdf?alt=media&token=2da6c0ed-ef66-41dc-9946-395daa3e02db",
                        "fileExt": ".pdf",
                        "fileName": "4658.4090 PENALTIES FOR PLANS; NEW CONSTRUCTION RULE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Nursing Services",
                    "type": "dir",
                    "id": "2d187998-eaf7-4ea1-8192-bc1ec8af9b7e",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Nursing Services",
                    "items": [
                      {
                        "name": "4658.0500 DIRECTOR OF NURSING SERVICES",
                        "type": "reg",
                        "id": "c0188e2c-f9df-40f7-b225-80a65f45f291",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc0188e2c-f9df-40f7-b225-80a65f45f291.pdf?alt=media&token=e11e331b-fb70-49c2-886b-5d0aa6406651",
                        "fileExt": ".pdf",
                        "fileName": "4658.0500 DIRECTOR OF NURSING SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0505 RESPONSIBILITIES; DIRECTOR OF NURSING SERVICES",
                        "type": "reg",
                        "id": "217e1280-867d-4cec-a191-3b4b1b223567",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F217e1280-867d-4cec-a191-3b4b1b223567.pdf?alt=media&token=b1395c78-6968-48f9-917d-1c8fa5c84544",
                        "fileExt": ".pdf",
                        "fileName": "4658.0505 RESPONSIBILITIES; DIRECTOR OF NURSING SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0510 NURSING PERSONNEL",
                        "type": "reg",
                        "id": "c86817cf-8e13-4b8b-9b9d-4fc911472992",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc86817cf-8e13-4b8b-9b9d-4fc911472992.pdf?alt=media&token=7b69d179-d6d4-4b46-ac2a-3bd840fb30f1",
                        "fileExt": ".pdf",
                        "fileName": "4658.0510 NURSING PERSONNEL.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0520 ADEQUATE AND PROPER NURSING CARE",
                        "type": "reg",
                        "id": "c9a5c4cb-969f-448c-9f1b-7afefbdecf59",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc9a5c4cb-969f-448c-9f1b-7afefbdecf59.pdf?alt=media&token=2ae73ec3-46c4-468a-bad0-2ae55a672290",
                        "fileExt": ".pdf",
                        "fileName": "4658.0520 ADEQUATE AND PROPER NURSING CARE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0525 REHABILITATION NURSING CARE",
                        "type": "reg",
                        "id": "826fae7e-03d7-4d11-896f-56b0c42b23f7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F826fae7e-03d7-4d11-896f-56b0c42b23f7.pdf?alt=media&token=58471f78-7f3c-4b95-917a-d1400822a998",
                        "fileExt": ".pdf",
                        "fileName": "4658.0525 REHABILITATION NURSING CARE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0530 ASSISTANCE WITH EATING",
                        "type": "reg",
                        "id": "45c0275b-cd02-4f13-939e-7f5de922bbb3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F45c0275b-cd02-4f13-939e-7f5de922bbb3.pdf?alt=media&token=f6a58ce7-b813-402b-a18e-71f3c575bf92",
                        "fileExt": ".pdf",
                        "fileName": "4658.0530 ASSISTANCE WITH EATING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0580 PENALTIES FOR NURSING SERVICES RULE VIOLATIONS",
                        "type": "reg",
                        "id": "adce98fa-be7e-4017-859a-022e6dacaf05",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fadce98fa-be7e-4017-859a-022e6dacaf05.pdf?alt=media&token=f1c79684-cd01-4d79-ac59-d8ee41559948",
                        "fileExt": ".pdf",
                        "fileName": "4658.0580 PENALTIES FOR NURSING SERVICES RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Penalties",
                    "type": "dir",
                    "id": "5530d782-8f9a-4c97-a929-8eebb2f77670",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Penalties",
                    "items": [
                      {
                        "name": "4658.0190 PENALTIES FOR ADMINISTRATION AND OPERATIONS RULE",
                        "type": "reg",
                        "id": "9b96d4cc-0967-4fe7-996f-094855174b8d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9b96d4cc-0967-4fe7-996f-094855174b8d.pdf?alt=media&token=e21923c8-9abd-4f30-b32b-4ec0bf34b736",
                        "fileExt": ".pdf",
                        "fileName": "4658.0190 PENALTIES FOR ADMINISTRATION AND OPERATIONS RULE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0191 PENALTIES FOR VIOLATIONS OF RESIDENTS' BILL OF RIGHTS",
                        "type": "reg",
                        "id": "06f6ece2-0073-4eb7-8333-02ae700d2062",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F06f6ece2-0073-4eb7-8333-02ae700d2062.pdf?alt=media&token=2251aff2-1e5f-434b-9c4c-4aa079d88170",
                        "fileExt": ".pdf",
                        "fileName": "4658.0191 PENALTIES FOR VIOLATIONS OF RESIDENTS' BILL OF RIGHTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0192 NURSING HOME STATUTES; FINES",
                        "type": "reg",
                        "id": "b4e1b083-8e45-47bc-9c98-483784aeff1e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb4e1b083-8e45-47bc-9c98-483784aeff1e.pdf?alt=media&token=4e389d25-087b-44d6-8572-73f7dd59597a",
                        "fileExt": ".pdf",
                        "fileName": "4658.0192 NURSING HOME STATUTES; FINES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0193 REPORTINGMALTREATMENTOFVULNERABLEADULTS;FINES",
                        "type": "reg",
                        "id": "e9f45caf-7fe4-4fb8-ad3e-324ede9af68e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe9f45caf-7fe4-4fb8-ad3e-324ede9af68e.pdf?alt=media&token=0ee516dd-dd98-4659-bc39-34398963d323",
                        "fileExt": ".pdf",
                        "fileName": "4658.0193 REPORTINGMALTREATMENTOFVULNERABLEADULTS;FINES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Physical Plant Licensure - Existing and New",
                    "type": "dir",
                    "id": "4b2aec5a-74c6-462e-bc71-8e5dc9e2e609",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Physical Plant Licensure - Existing and New",
                    "items": [
                      {
                        "name": "4658.3000 LICENSURE",
                        "type": "reg",
                        "id": "330ffa1d-bf03-4003-aeb6-19610357c0b3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F330ffa1d-bf03-4003-aeb6-19610357c0b3.pdf?alt=media&token=bcf4b0cf-c6bc-4766-a45f-775321b6fa0c",
                        "fileExt": ".pdf",
                        "fileName": "4658.3000 LICENSURE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.3005 COMPLIANCE WITH RULES",
                        "type": "reg",
                        "id": "97981e26-7ad0-4993-9bb5-88c6b57027d6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F97981e26-7ad0-4993-9bb5-88c6b57027d6.pdf?alt=media&token=17a004ef-ce08-47dc-b88c-62c45570bc40",
                        "fileExt": ".pdf",
                        "fileName": "4658.3005 COMPLIANCE WITH RULES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.3090 PENALTIES; RULE VIOLATIONS",
                        "type": "reg",
                        "id": "037cb5e9-47d4-44a1-aca2-8e3e5427c667",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F037cb5e9-47d4-44a1-aca2-8e3e5427c667.pdf?alt=media&token=cd322c74-e4c8-4d92-8ea3-db63a6b3edc9",
                        "fileExt": ".pdf",
                        "fileName": "4658.3090 PENALTIES; RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Recreational Program",
                    "type": "dir",
                    "id": "163d6c69-cac5-47b6-8b6b-3aa684bc2b22",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Recreational Program",
                    "items": [
                      {
                        "name": "4658.0900 ACTIVITY AND RECREATION PROGRAM",
                        "type": "reg",
                        "id": "efafede8-4947-40f7-a85a-4b737aea9f2e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fefafede8-4947-40f7-a85a-4b737aea9f2e.pdf?alt=media&token=826020dd-6bec-4345-a50a-630fec30600f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0900 ACTIVITY AND RECREATION PROGRAM.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0950 PENALTIES FOR ACTIVITY AND RECREATION PROGRAM RULE",
                        "type": "reg",
                        "id": "d30bab28-b274-4363-9cdc-83107fbdcdec",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd30bab28-b274-4363-9cdc-83107fbdcdec.pdf?alt=media&token=cd4ad326-0c42-4489-aaaf-ddd9b818edac",
                        "fileExt": ".pdf",
                        "fileName": "4658.0950 PENALTIES FOR ACTIVITY AND RECREATION PROGRAM RULE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Rehabilitative Services",
                    "type": "dir",
                    "id": "46238016-a3a3-446e-b5fc-f2809c4030f4",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Rehabilitative Services",
                    "items": [
                      {
                        "name": "4658.1200 SPECIALIZED REHABILITATIVE SERVICES",
                        "type": "reg",
                        "id": "cdb1b491-4179-4316-b8a7-c7208194997e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcdb1b491-4179-4316-b8a7-c7208194997e.pdf?alt=media&token=0d6e63f4-fea6-4d13-a05a-f62bd61d9c67",
                        "fileExt": ".pdf",
                        "fileName": "4658.1200 SPECIALIZED REHABILITATIVE SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1900",
                        "type": "reg",
                        "id": "99be492f-c841-48e8-8658-47801541aff8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F99be492f-c841-48e8-8658-47801541aff8.pdf?alt=media&token=01f3b978-7c4a-4573-b852-0865b2da37a8",
                        "fileExt": ".pdf",
                        "fileName": "4658.1900.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Resident Areas - Existing Construction",
                    "type": "dir",
                    "id": "a674b02d-f894-4cb0-8aa2-33f599443d67",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Resident Areas - Existing Construction",
                    "items": [
                      {
                        "name": "4658.5000 BEDROOM DESIGN, EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "ee69a041-ce73-47ea-a3d5-1152fd99369b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fee69a041-ce73-47ea-a3d5-1152fd99369b.pdf?alt=media&token=4061ea4e-a633-4635-90a2-b7b5b3cff5e2",
                        "fileExt": ".pdf",
                        "fileName": "4658.5000 BEDROOM DESIGN, EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5005 BEDROOM DOOR LOCKS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "8953bc91-693d-4f74-aa7b-7c06aa148aef",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8953bc91-693d-4f74-aa7b-7c06aa148aef.pdf?alt=media&token=37f20944-cabd-4f41-af0e-01467b67140c",
                        "fileExt": ".pdf",
                        "fileName": "4658.5005 BEDROOM DOOR LOCKS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5010 CLOTHES WARDROBE OR CLOSET; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "b3b6f5a4-c2ea-451e-8361-d9a5b03d3a1c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb3b6f5a4-c2ea-451e-8361-d9a5b03d3a1c.pdf?alt=media&token=ebfb4c05-e704-4ad6-ac0c-a1e5892f730d",
                        "fileExt": ".pdf",
                        "fileName": "4658.5010 CLOTHES WARDROBE OR CLOSET; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5015 CORRIDOR HANDRAILS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "b2745c71-5604-4c1e-b1b5-f3ac262f4845",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb2745c71-5604-4c1e-b1b5-f3ac262f4845.pdf?alt=media&token=09d1f8ba-38d9-4d7e-8e1f-ac17b6fc4ade",
                        "fileExt": ".pdf",
                        "fileName": "4658.5015 CORRIDOR HANDRAILS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5020 NURSING AREA; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "38992e49-b336-466f-b3cf-d195691bcc24",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F38992e49-b336-466f-b3cf-d195691bcc24.pdf?alt=media&token=19f678ed-0935-4663-b744-2d9c40aefc32",
                        "fileExt": ".pdf",
                        "fileName": "4658.5020 NURSING AREA; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5025 TOILET ROOMS AND SANITARY FIXTURES; EXISTING",
                        "type": "reg",
                        "id": "8ff0c1c6-a98c-4f00-ae71-45dd1b6b5cbc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8ff0c1c6-a98c-4f00-ae71-45dd1b6b5cbc.pdf?alt=media&token=b4e9b7c9-f0fc-4032-8eae-abefa1291cae",
                        "fileExt": ".pdf",
                        "fileName": "4658.5025 TOILET ROOMS AND SANITARY FIXTURES; EXISTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5030 CENTRAL BATHING AREA; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "715bc7ff-8e08-4c47-ae2b-c0885ddd379f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F715bc7ff-8e08-4c47-ae2b-c0885ddd379f.pdf?alt=media&token=0b10ba93-1174-4aa5-b27c-55b9e0034470",
                        "fileExt": ".pdf",
                        "fileName": "4658.5030 CENTRAL BATHING AREA; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5035 HANDWASHING FACILITIES; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "4cbce82e-b85b-42d8-acb8-407aff70531d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4cbce82e-b85b-42d8-acb8-407aff70531d.pdf?alt=media&token=873503ef-2706-449c-875b-bf450c41666c",
                        "fileExt": ".pdf",
                        "fileName": "4658.5035 HANDWASHING FACILITIES; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5040 ROOM LABELING; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "51a4c87d-684b-4338-85f3-6af8c5319a62",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F51a4c87d-684b-4338-85f3-6af8c5319a62.pdf?alt=media&token=0bf4f548-00c5-4847-bb34-950dfae3b768",
                        "fileExt": ".pdf",
                        "fileName": "4658.5040 ROOM LABELING; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5090 PENALTIES FOR RESIDENT AREAS; EXISTING CONSTRUCTION",
                        "type": "reg",
                        "id": "a8c457d6-c876-4df5-9edf-ed7996c503a3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa8c457d6-c876-4df5-9edf-ed7996c503a3.pdf?alt=media&token=3514fce2-d2c7-4da7-abc5-07e47f5d7e0b",
                        "fileExt": ".pdf",
                        "fileName": "4658.5090 PENALTIES FOR RESIDENT AREAS; EXISTING CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Resident Areas - New Construction",
                    "type": "dir",
                    "id": "1c39f6d3-bb87-4cc9-997d-e7fd2af028f6",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Resident Areas - New Construction",
                    "items": [
                      {
                        "name": "4658.4100 RESIDENTS' BEDROOM REQUIREMENTS, CAPACITIES; NEW",
                        "type": "reg",
                        "id": "c60444bf-f4ee-45a0-be99-1dea15d8fbca",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc60444bf-f4ee-45a0-be99-1dea15d8fbca.pdf?alt=media&token=eb84f23e-125f-4e9d-b5dd-255b92681516",
                        "fileExt": ".pdf",
                        "fileName": "4658.4100 RESIDENTS' BEDROOM REQUIREMENTS, CAPACITIES; NEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4105 BEDROOM DESIGN; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "1e31c43b-49d7-42ea-a441-302875c72653",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1e31c43b-49d7-42ea-a441-302875c72653.pdf?alt=media&token=e5f9e16b-a9eb-415f-b25f-49d3289a0f32",
                        "fileExt": ".pdf",
                        "fileName": "4658.4105 BEDROOM DESIGN; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4110 BEDROOM DOORS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "867d91dd-3baa-422b-b41a-ae4d1d54d66c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F867d91dd-3baa-422b-b41a-ae4d1d54d66c.pdf?alt=media&token=3788e389-e80d-4780-a14e-2cf803fec48f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4110 BEDROOM DOORS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4115 CLOTHES WARDROBE OR CLOSET; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "730cc770-61b8-4ec1-8eb8-42edab5f0f8a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F730cc770-61b8-4ec1-8eb8-42edab5f0f8a.pdf?alt=media&token=98019b9c-c8a0-4243-b104-b5c11a6c9cd5",
                        "fileExt": ".pdf",
                        "fileName": "4658.4115 CLOTHES WARDROBE OR CLOSET; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4120 HANDRAILS AND CORRIDORS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "707366de-2bbe-4e99-95a7-055a7243a1f1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F707366de-2bbe-4e99-95a7-055a7243a1f1.pdf?alt=media&token=7536c7a5-7bf5-4be9-aab7-d6f99a612e40",
                        "fileExt": ".pdf",
                        "fileName": "4658.4120 HANDRAILS AND CORRIDORS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4125 NURSING AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "f89cb97f-26cc-4dd7-b857-31fa5fcbacc7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff89cb97f-26cc-4dd7-b857-31fa5fcbacc7.pdf?alt=media&token=7b978741-62f1-41b4-8986-9edf3c41f380",
                        "fileExt": ".pdf",
                        "fileName": "4658.4125 NURSING AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4130 NOURISHMENT AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "12082761-9045-465b-88f4-c19dea0bc4de",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F12082761-9045-465b-88f4-c19dea0bc4de.pdf?alt=media&token=82693131-79c4-40f7-9531-d0c3b4cfebd4",
                        "fileExt": ".pdf",
                        "fileName": "4658.4130 NOURISHMENT AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4135 TOILET ROOMS AND SANITARY FIXTURES; NEW",
                        "type": "reg",
                        "id": "83b797b5-a658-476c-bb8e-4ec87e3cbb18",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F83b797b5-a658-476c-bb8e-4ec87e3cbb18.pdf?alt=media&token=e3abcf47-7b28-473f-bdde-8d735ee9f082",
                        "fileExt": ".pdf",
                        "fileName": "4658.4135 TOILET ROOMS AND SANITARY FIXTURES; NEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4140 PROVISION OF RESIDENT TOILET ROOMS; NEW",
                        "type": "reg",
                        "id": "dac0959a-98ae-4400-bb62-1cc5d65d6784",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdac0959a-98ae-4400-bb62-1cc5d65d6784.pdf?alt=media&token=ea69ec89-3e2c-40f0-b6a6-596a87167657",
                        "fileExt": ".pdf",
                        "fileName": "4658.4140 PROVISION OF RESIDENT TOILET ROOMS; NEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4145 TOILET ROOM LAYOUT; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "1ee819e4-6d06-42e5-8997-4eaa488f36b1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1ee819e4-6d06-42e5-8997-4eaa488f36b1.pdf?alt=media&token=124a56d9-46c3-4d7e-ae35-a5c00bd7b7cf",
                        "fileExt": ".pdf",
                        "fileName": "4658.4145 TOILET ROOM LAYOUT; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4150 PHYSICALLY ACCESSIBLE TOILET ROOMS; NEW",
                        "type": "reg",
                        "id": "1886577d-797d-4408-a27e-9542bbccbf79",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1886577d-797d-4408-a27e-9542bbccbf79.pdf?alt=media&token=a06351af-4a3d-446d-abf3-d9f10e448c16",
                        "fileExt": ".pdf",
                        "fileName": "4658.4150 PHYSICALLY ACCESSIBLE TOILET ROOMS; NEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4155 CENTRAL BATHING AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "c7e8f62f-8129-47fd-a307-b3fabf6d6ba3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc7e8f62f-8129-47fd-a307-b3fabf6d6ba3.pdf?alt=media&token=0a6692b6-5ad7-4f5a-8055-7fae79bfe145",
                        "fileExt": ".pdf",
                        "fileName": "4658.4155 CENTRAL BATHING AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4160 DRINKING FOUNTAINS; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "be244887-e63b-4811-9c43-44d4f6576a18",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbe244887-e63b-4811-9c43-44d4f6576a18.pdf?alt=media&token=bf8db975-2ba6-4b88-afc6-d01b682917bc",
                        "fileExt": ".pdf",
                        "fileName": "4658.4160 DRINKING FOUNTAINS; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4165 HANDWASHING FACILITIES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "e6461f19-6b37-46c8-a81f-437c72adfee8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe6461f19-6b37-46c8-a81f-437c72adfee8.pdf?alt=media&token=3edf189c-e891-4d47-be84-62730a9d2c89",
                        "fileExt": ".pdf",
                        "fileName": "4658.4165 HANDWASHING FACILITIES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4170 STORAGE; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "9cce6f83-c8c1-4a03-8a92-6ee975ebbd88",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9cce6f83-c8c1-4a03-8a92-6ee975ebbd88.pdf?alt=media&token=ab56c7d1-bfdd-4471-99e1-07e114f4e217",
                        "fileExt": ".pdf",
                        "fileName": "4658.4170 STORAGE; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4175 JANITOR'S CLOSET; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "04e5fd8d-c26c-4b22-9ea2-3b7fdb9ee16d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F04e5fd8d-c26c-4b22-9ea2-3b7fdb9ee16d.pdf?alt=media&token=5f105fbb-4a39-49a1-9d7d-1f36bc2027a0",
                        "fileExt": ".pdf",
                        "fileName": "4658.4175 JANITOR'S CLOSET; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4180 ROOM LABELING; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "16f392f2-77c4-4685-b890-5db9790afa91",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F16f392f2-77c4-4685-b890-5db9790afa91.pdf?alt=media&token=0def00f8-8b30-4bfa-b8ac-f1385d76529e",
                        "fileExt": ".pdf",
                        "fileName": "4658.4180 ROOM LABELING; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4190 PENALTIES FOR RESIDENT AREAS; NEW CONSTRUCTION RULE",
                        "type": "reg",
                        "id": "d5549786-9ca0-4ccd-85f9-b3cb977077ef",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd5549786-9ca0-4ccd-85f9-b3cb977077ef.pdf?alt=media&token=bd066afc-b9d9-4d17-9134-20578f948467",
                        "fileExt": ".pdf",
                        "fileName": "4658.4190 PENALTIES FOR RESIDENT AREAS; NEW CONSTRUCTION RULE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Resident Assessment and Plan of Care",
                    "type": "dir",
                    "id": "54dbb26f-6e4f-457e-a2d7-2d052870bc3b",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Resident Assessment and Plan of Care",
                    "items": [
                      {
                        "name": "4658.0400 COMPREHENSIVE RESIDENT ASSESSMENT",
                        "type": "reg",
                        "id": "6a7e79b3-e187-4be2-88c0-5667f5f245b9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6a7e79b3-e187-4be2-88c0-5667f5f245b9.pdf?alt=media&token=fbf16d46-bbe9-4afe-9935-beb657a78392",
                        "fileExt": ".pdf",
                        "fileName": "4658.0400 COMPREHENSIVE RESIDENT ASSESSMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0405 COMPREHENSIVE PLAN OF CARE",
                        "type": "reg",
                        "id": "bdd839fa-c044-45a4-b8f9-06f651f772cf",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbdd839fa-c044-45a4-b8f9-06f651f772cf.pdf?alt=media&token=20c2b387-178b-402c-98ad-ab653f69820a",
                        "fileExt": ".pdf",
                        "fileName": "4658.0405 COMPREHENSIVE PLAN OF CARE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0420 PENALTIES FOR COMPREHENSIVE ASSESSMENT AND PLAN OF",
                        "type": "reg",
                        "id": "20269394-7d7b-43f7-9250-409031c32529",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F20269394-7d7b-43f7-9250-409031c32529.pdf?alt=media&token=62e733fc-0b14-4fb9-b74d-c546399e996d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0420 PENALTIES FOR COMPREHENSIVE ASSESSMENT AND PLAN OF.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Resident Personal Funds Account",
                    "type": "dir",
                    "id": "ff759247-be3e-4bcf-a38e-b4c6f81ce4d5",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Resident Personal Funds Account",
                    "items": [
                      {
                        "name": "4658.0250 ADMISSION POLICIES",
                        "type": "reg",
                        "id": "d5379f20-0c88-44e2-800f-694a0a10aa57",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd5379f20-0c88-44e2-800f-694a0a10aa57.pdf?alt=media&token=f6f7d598-a52d-443f-b138-a55d1539164b",
                        "fileExt": ".pdf",
                        "fileName": "4658.0250 ADMISSION POLICIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0255 AUTHORIZATION",
                        "type": "reg",
                        "id": "8e376e24-85c2-456a-833d-fa77b37a5cb9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8e376e24-85c2-456a-833d-fa77b37a5cb9.pdf?alt=media&token=12ee57aa-fd1d-4f6e-b8a1-4de44a68c0ea",
                        "fileExt": ".pdf",
                        "fileName": "4658.0255 AUTHORIZATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0260 PERSONAL FUND ACCOUNTING AND RECORDS",
                        "type": "reg",
                        "id": "f403fb0f-329c-4313-928d-84883dcf0c93",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff403fb0f-329c-4313-928d-84883dcf0c93.pdf?alt=media&token=2d9ddb53-740b-453a-a306-8ca01abfb297",
                        "fileExt": ".pdf",
                        "fileName": "4658.0260 PERSONAL FUND ACCOUNTING AND RECORDS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0265 DEPOSIT OF PERSONAL FUNDS",
                        "type": "reg",
                        "id": "c68e26f7-7e15-45f2-bc96-110750533a68",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc68e26f7-7e15-45f2-bc96-110750533a68.pdf?alt=media&token=ed51598c-c380-4c8c-9cbc-d60a983a9b92",
                        "fileExt": ".pdf",
                        "fileName": "4658.0265 DEPOSIT OF PERSONAL FUNDS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0270 WITHDRAWAL OF FUNDS FROM THE ACCOUNT",
                        "type": "reg",
                        "id": "70c40d8e-860a-4da6-a816-5c0ace4ffbdf",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F70c40d8e-860a-4da6-a816-5c0ace4ffbdf.pdf?alt=media&token=d3e0345b-74e1-4af0-9d51-2e2884e99def",
                        "fileExt": ".pdf",
                        "fileName": "4658.0270 WITHDRAWAL OF FUNDS FROM THE ACCOUNT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0275 RETURN OF FUNDS AFTER DISCHARGE OR DEATH",
                        "type": "reg",
                        "id": "e1ffa5d9-c49a-4c86-b027-ea6b4a41a641",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe1ffa5d9-c49a-4c86-b027-ea6b4a41a641.pdf?alt=media&token=18f9a331-cdb4-49c5-ae2e-66bb26528482",
                        "fileExt": ".pdf",
                        "fileName": "4658.0275 RETURN OF FUNDS AFTER DISCHARGE OR DEATH.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0290 PENALTIES FOR RESIDENT RIGHTS RULE VIOLATIONS",
                        "type": "reg",
                        "id": "6871059b-e801-47c5-b7b4-3a2eac519fb3",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6871059b-e801-47c5-b7b4-3a2eac519fb3.pdf?alt=media&token=3a8cd28a-9428-42f9-aef1-66896ba5db0d",
                        "fileExt": ".pdf",
                        "fileName": "4658.0290 PENALTIES FOR RESIDENT RIGHTS RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Resident Rights",
                    "type": "dir",
                    "id": "b413885a-8292-4ac1-8e8b-fb7a7f6be685",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Resident Rights",
                    "items": [
                      {
                        "name": "4658.0200 POLICIES CONCERNING RESIDENTS",
                        "type": "reg",
                        "id": "e13fe841-b116-4b2a-b3af-aca7f753f1b0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe13fe841-b116-4b2a-b3af-aca7f753f1b0.pdf?alt=media&token=eba152c3-d04d-40ef-bf56-de1dc89e2c6e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0200 POLICIES CONCERNING RESIDENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0205 PROCEDURE AT DEATH",
                        "type": "reg",
                        "id": "d8da528d-71a0-42a1-a345-4bb782ed37ce",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd8da528d-71a0-42a1-a345-4bb782ed37ce.pdf?alt=media&token=b00d6e0c-dd71-41ce-8f1c-2ac3a5c2626b",
                        "fileExt": ".pdf",
                        "fileName": "4658.0205 PROCEDURE AT DEATH.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0210 ROOM ASSIGNMENTS",
                        "type": "reg",
                        "id": "26735225-c5a3-49ef-accd-f3142ee0e51a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F26735225-c5a3-49ef-accd-f3142ee0e51a.pdf?alt=media&token=9a9bdae4-c205-4242-af81-e5336b9c2236",
                        "fileExt": ".pdf",
                        "fileName": "4658.0210 ROOM ASSIGNMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0215 ADMINISTRATION OF MEDICATIONS",
                        "type": "reg",
                        "id": "d549c7a0-a2e4-4a6e-ab57-dc1821e797a2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd549c7a0-a2e4-4a6e-ab57-dc1821e797a2.pdf?alt=media&token=e9865217-98ae-4519-bf70-b95c78ef7e45",
                        "fileExt": ".pdf",
                        "fileName": "4658.0215 ADMINISTRATION OF MEDICATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0220 FREEDOM FROM CORPORAL PUNISHMENT AND INVOLUNTARY",
                        "type": "reg",
                        "id": "95f06e17-2763-4329-938d-e88f4c267736",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F95f06e17-2763-4329-938d-e88f4c267736.pdf?alt=media&token=59e26d18-128f-424c-9a7b-e5b95f0dade6",
                        "fileExt": ".pdf",
                        "fileName": "4658.0220 FREEDOM FROM CORPORAL PUNISHMENT AND INVOLUNTARY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Restraints",
                    "type": "dir",
                    "id": "21872881-c8f3-4790-874e-3933e24f8972",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Restraints",
                    "items": [
                      {
                        "name": "4658.0300 USE OF RESTRAINTS",
                        "type": "reg",
                        "id": "896d0f2c-f0c0-4151-b977-f172d63a0082",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F896d0f2c-f0c0-4151-b977-f172d63a0082.pdf?alt=media&token=d1f8292c-d544-4964-99b4-46b2c9c1e3e9",
                        "fileExt": ".pdf",
                        "fileName": "4658.0300 USE OF RESTRAINTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0350 PENALTIES FOR RESTRAINTS RULE VIOLATIONS",
                        "type": "reg",
                        "id": "77062fbd-6a42-4e7f-9fff-7f9c244efc42",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F77062fbd-6a42-4e7f-9fff-7f9c244efc42.pdf?alt=media&token=18a0f33f-996e-43d7-af58-2302e7f0e05f",
                        "fileExt": ".pdf",
                        "fileName": "4658.0350 PENALTIES FOR RESTRAINTS RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Social Services",
                    "type": "dir",
                    "id": "0ce49a64-6bc7-46ee-a850-3e5ca5df5b96",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Social Services",
                    "items": [
                      {
                        "name": "4658.1000 DEFINITIONS",
                        "type": "reg",
                        "id": "5dae232f-35d2-41f9-b6d7-a079fc333021",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5dae232f-35d2-41f9-b6d7-a079fc333021.pdf?alt=media&token=26dce670-bbb5-4cc7-9682-a18c0d7b14e4",
                        "fileExt": ".pdf",
                        "fileName": "4658.1000 DEFINITIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1005 SOCIAL SERVICES",
                        "type": "reg",
                        "id": "69a2fe10-41c6-4f99-a75d-ca149de3a365",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F69a2fe10-41c6-4f99-a75d-ca149de3a365.pdf?alt=media&token=24d6a975-9891-4354-be29-60ed689efd7e",
                        "fileExt": ".pdf",
                        "fileName": "4658.1005 SOCIAL SERVICES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.1090 PENALTIES FOR SOCIAL SERVICES RULE VIOLATIONS",
                        "type": "reg",
                        "id": "122b9afd-75b8-4dee-9024-11dba02150e7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F122b9afd-75b8-4dee-9024-11dba02150e7.pdf?alt=media&token=4c357804-b402-4ec4-a7ef-57af0616082c",
                        "fileExt": ".pdf",
                        "fileName": "4658.1090 PENALTIES FOR SOCIAL SERVICES RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Specialized Units",
                    "type": "dir",
                    "id": "b6374680-12a0-4cc8-a091-70b77bfc268a",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Specialized Units",
                    "items": [
                      {
                        "name": "4658.2000 SECURED UNITS",
                        "type": "reg",
                        "id": "bda7935a-d5ad-4ee3-93f8-8a5f47fb8db5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbda7935a-d5ad-4ee3-93f8-8a5f47fb8db5.pdf?alt=media&token=e3d14c30-c5bb-4334-a04b-d8900f6b6669",
                        "fileExt": ".pdf",
                        "fileName": "4658.2000 SECURED UNITS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.2010 PHYSICAL PLANT REQUIREMENTS",
                        "type": "reg",
                        "id": "6c989542-40f2-4bc2-bc66-1540a64d1d5a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6c989542-40f2-4bc2-bc66-1540a64d1d5a.pdf?alt=media&token=cd2f7906-7a22-426f-832a-a257d4ffbb11",
                        "fileExt": ".pdf",
                        "fileName": "4658.2010 PHYSICAL PLANT REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.2020 STATEMENT OF OPERATIONS",
                        "type": "reg",
                        "id": "e1e5b944-a507-4050-8e84-dd781924db03",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe1e5b944-a507-4050-8e84-dd781924db03.pdf?alt=media&token=79d5d73c-7a08-476a-82aa-e8d627882203",
                        "fileExt": ".pdf",
                        "fileName": "4658.2020 STATEMENT OF OPERATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.2030 SPECIALIZED CARE UNIT",
                        "type": "reg",
                        "id": "332844fe-b048-4a9a-84c0-2fd4b9defa64",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F332844fe-b048-4a9a-84c0-2fd4b9defa64.pdf?alt=media&token=03174791-0806-458b-8c24-232e7fdcc23b",
                        "fileExt": ".pdf",
                        "fileName": "4658.2030 SPECIALIZED CARE UNIT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.2090 PENALTIES FOR SPECIALIZED UNITS RULE VIOLATIONS",
                        "type": "reg",
                        "id": "7f860463-432b-49cc-8305-0d760b329f8d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7f860463-432b-49cc-8305-0d760b329f8d.pdf?alt=media&token=50acda37-e609-4d26-978d-b1e9d60a41b9",
                        "fileExt": ".pdf",
                        "fileName": "4658.2090 PENALTIES FOR SPECIALIZED UNITS RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Spiritual Needs",
                    "type": "dir",
                    "id": "7d5b9a10-1fdb-46ad-bbe4-d4f050dbfb42",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Spiritual Needs",
                    "items": [
                      {
                        "name": "4658.0960 SPIRITUAL NEEDS",
                        "type": "reg",
                        "id": "ec59c2f5-8278-4cb1-b4e2-3482f376d61b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fec59c2f5-8278-4cb1-b4e2-3482f376d61b.pdf?alt=media&token=f9fc806e-f493-44b3-89a7-ad47ad2fe6a5",
                        "fileExt": ".pdf",
                        "fileName": "4658.0960 SPIRITUAL NEEDS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.0990 PENALTIES FOR SPIRITUAL NEEDS RULE VIOLATIONS",
                        "type": "reg",
                        "id": "5bcc7be3-cc1f-46e3-9fbd-f197d6f5fca6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5bcc7be3-cc1f-46e3-9fbd-f197d6f5fca6.pdf?alt=media&token=8233ba2b-f31c-4abe-b526-f8161dd0441e",
                        "fileExt": ".pdf",
                        "fileName": "4658.0990 PENALTIES FOR SPIRITUAL NEEDS RULE VIOLATIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Supportive Services - Exiting Construction",
                    "type": "dir",
                    "id": "514a590f-0304-4d27-942f-c1e4d16d98c7",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Supportive Services - Exiting Construction",
                    "items": [
                      {
                        "name": "4658.5100 DINING, DAYROOM, AND ACTIVITY AREAS; EXISTING",
                        "type": "reg",
                        "id": "d7b978a4-1075-4a08-bef0-c0b3a4bc711f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd7b978a4-1075-4a08-bef0-c0b3a4bc711f.pdf?alt=media&token=3b303c1e-4ddb-443f-98c2-726c42514375",
                        "fileExt": ".pdf",
                        "fileName": "4658.5100 DINING, DAYROOM, AND ACTIVITY AREAS; EXISTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.5190 PENALTIES FOR SUPPORTIVE SERVICES; EXISTING",
                        "type": "reg",
                        "id": "cac184ad-4fa8-42c3-8717-a8b578cdc83e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcac184ad-4fa8-42c3-8717-a8b578cdc83e.pdf?alt=media&token=95e48ffd-3e5b-418a-bb77-cbc444bcc03b",
                        "fileExt": ".pdf",
                        "fileName": "4658.5190 PENALTIES FOR SUPPORTIVE SERVICES; EXISTING.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Supportive Services - New Construction",
                    "type": "dir",
                    "id": "ac7c4c46-117d-4f9f-9b60-4b6ba9a0d828",
                    "path": "Regulations/State/MN/Chapter 4658. NURSING HOMES/Supportive Services - New Construction",
                    "items": [
                      {
                        "name": "4658.4200 DINING, DAYROOM, AND ACTIVITY AREAS, REQUIRED FLOOR",
                        "type": "reg",
                        "id": "b7bc8841-4a3b-4e2c-a1da-3610a084c69e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb7bc8841-4a3b-4e2c-a1da-3610a084c69e.pdf?alt=media&token=d44717c6-92f0-4799-b515-83ede5187e21",
                        "fileExt": ".pdf",
                        "fileName": "4658.4200 DINING, DAYROOM, AND ACTIVITY AREAS, REQUIRED FLOOR.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4205 DAYROOM; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "05e9eed0-9ba7-415a-b0f5-1bf62d63d13a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F05e9eed0-9ba7-415a-b0f5-1bf62d63d13a.pdf?alt=media&token=0cfb51ef-a9c4-4d38-8758-7716439da624",
                        "fileExt": ".pdf",
                        "fileName": "4658.4205 DAYROOM; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4210 DINING AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "0507e1fb-724e-4b99-978f-a8c85a17e610",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0507e1fb-724e-4b99-978f-a8c85a17e610.pdf?alt=media&token=a5aec936-5ee7-4eb0-a24c-8d05df5b6f76",
                        "fileExt": ".pdf",
                        "fileName": "4658.4210 DINING AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4215 ACTIVITIES AREA; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "6268279b-df3b-42b4-ba83-5839c8322c18",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6268279b-df3b-42b4-ba83-5839c8322c18.pdf?alt=media&token=e7619327-f01c-4211-8c7b-2c55ba26284b",
                        "fileExt": ".pdf",
                        "fileName": "4658.4215 ACTIVITIES AREA; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "4658.4290 PENALTIES FOR SUPPORTIVE SERVICES; NEW CONSTRUCTION",
                        "type": "reg",
                        "id": "cc4b00fb-7a32-48a5-8be5-fffdf3d04fc7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fcc4b00fb-7a32-48a5-8be5-fffdf3d04fc7.pdf?alt=media&token=742cb452-a21f-464d-8cc9-dd4f16af5c6f",
                        "fileExt": ".pdf",
                        "fileName": "4658.4290 PENALTIES FOR SUPPORTIVE SERVICES; NEW CONSTRUCTION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  }
                ]
              },
              {
                "name": "Chapter 6400. LICENSING OF NURSING HOME ADMINISTRATORS",
                "type": "dir",
                "id": "b2c6fac0-a5cf-4722-89a1-6b7824978b88",
                "path": "Regulations/State/MN/Chapter 6400. LICENSING OF NURSING HOME ADMINISTRATORS",
                "items": [
                  {
                    "name": "Continuing Education",
                    "type": "dir",
                    "id": "447fa35f-810b-4037-a66d-8d8429d773ef",
                    "path": "Regulations/State/MN/Chapter 6400. LICENSING OF NURSING HOME ADMINISTRATORS/Continuing Education",
                    "items": [
                      {
                        "name": "6400.6800 CONTINUING EDUCATION REQUIREMENTS",
                        "type": "reg",
                        "id": "d6ae2d93-53c3-454b-9827-8ff41e048547",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fd6ae2d93-53c3-454b-9827-8ff41e048547.pdf?alt=media&token=5858a902-3183-4d61-8bb2-85823a3173b0",
                        "fileExt": ".pdf",
                        "fileName": "6400.6800 CONTINUING EDUCATION REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6850 NUMBER OF CE CREDITS FOR ACTIVITIES",
                        "type": "reg",
                        "id": "baab2616-aa49-4c1a-a554-18e45c9b269b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbaab2616-aa49-4c1a-a554-18e45c9b269b.pdf?alt=media&token=290f43f2-eaed-4d3b-8426-a047dc19324a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6850 NUMBER OF CE CREDITS FOR ACTIVITIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6870 SPONSORING CONTINUING EDUCATION",
                        "type": "reg",
                        "id": "71ba901c-d2da-4a79-bbaf-f3ea2fcb195e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F71ba901c-d2da-4a79-bbaf-f3ea2fcb195e.pdf?alt=media&token=c15ed893-fd8a-4829-a7eb-090e8769d096",
                        "fileExt": ".pdf",
                        "fileName": "6400.6870 SPONSORING CONTINUING EDUCATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7090 CONTINUING EDUCATION REQUIREMENTS",
                        "type": "reg",
                        "id": "c817633d-f6c0-4796-9634-a776f48f25c2",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc817633d-f6c0-4796-9634-a776f48f25c2.pdf?alt=media&token=4c1d5036-c9bf-4205-bf52-ac15a9c3581d",
                        "fileExt": ".pdf",
                        "fileName": "6400.7090 CONTINUING EDUCATION REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7091 NUMBER OF CE CREDITS FOR ACTIVITIES",
                        "type": "reg",
                        "id": "e6b98bc6-f9a3-4de1-b15c-3e21ec1c0e1a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe6b98bc6-f9a3-4de1-b15c-3e21ec1c0e1a.pdf?alt=media&token=ce6a3a73-7938-4de7-ae10-c684ca21670e",
                        "fileExt": ".pdf",
                        "fileName": "6400.7091 NUMBER OF CE CREDITS FOR ACTIVITIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7092 SPONSORING CONTINUING EDUCATION",
                        "type": "reg",
                        "id": "af8337bc-58aa-48c3-a567-322e1b213531",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Faf8337bc-58aa-48c3-a567-322e1b213531.pdf?alt=media&token=2089d233-6cff-4539-86e3-a8f27137c5c0",
                        "fileExt": ".pdf",
                        "fileName": "6400.7092 SPONSORING CONTINUING EDUCATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Course Requirements",
                    "type": "dir",
                    "id": "0e342578-4ee7-4dd7-a91c-2ee23b8676ed",
                    "path": "Regulations/State/MN/Chapter 6400. LICENSING OF NURSING HOME ADMINISTRATORS/Course Requirements",
                    "items": [
                      {
                        "name": "6400.6400 GENERAL COURSE REQUIREMENTS",
                        "type": "reg",
                        "id": "563701f3-2728-4d86-908c-7de5ecb63a95",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F563701f3-2728-4d86-908c-7de5ecb63a95.pdf?alt=media&token=7743055e-f4d6-446e-b47c-42c263531f81",
                        "fileExt": ".pdf",
                        "fileName": "6400.6400 GENERAL COURSE REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6550 SPECIFIC COURSE REQUIREMENTS",
                        "type": "reg",
                        "id": "6ac84e9e-ac65-4731-9ca1-f311463f845d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6ac84e9e-ac65-4731-9ca1-f311463f845d.pdf?alt=media&token=54cc81d1-a7dd-4f1b-8764-054c249b39c2",
                        "fileExt": ".pdf",
                        "fileName": "6400.6550 SPECIFIC COURSE REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6560 WAIVER OF ALL COURSE REQUIREMENTS",
                        "type": "reg",
                        "id": "7a1e01a6-9980-4fe4-b193-84fc267b72d8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7a1e01a6-9980-4fe4-b193-84fc267b72d8.pdf?alt=media&token=2b3c0c4a-aeb4-4f99-a265-0ea7b3a9172b",
                        "fileExt": ".pdf",
                        "fileName": "6400.6560 WAIVER OF ALL COURSE REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6570 EVIDENCE OF COURSE COMPLETION",
                        "type": "reg",
                        "id": "15016d3a-98da-40a6-a309-1c3cba9c9fe9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F15016d3a-98da-40a6-a309-1c3cba9c9fe9.pdf?alt=media&token=176819e5-4239-4ee0-b7cc-08976e17cea9",
                        "fileExt": ".pdf",
                        "fileName": "6400.6570 EVIDENCE OF COURSE COMPLETION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6600 PRACTICUM COURSE",
                        "type": "reg",
                        "id": "1f22ed09-9dd6-4b6d-ab5c-f5cc0ea50e73",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F1f22ed09-9dd6-4b6d-ab5c-f5cc0ea50e73.pdf?alt=media&token=86e62e18-5df2-4fba-9b06-5789c8740520",
                        "fileExt": ".pdf",
                        "fileName": "6400.6600 PRACTICUM COURSE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6655 PRACTICUM DURATION",
                        "type": "reg",
                        "id": "0636bec4-382f-4f66-9440-2eeca89e7c2c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F0636bec4-382f-4f66-9440-2eeca89e7c2c.pdf?alt=media&token=baf35e3a-3cc8-4a26-8e12-853c33c5b8d8",
                        "fileExt": ".pdf",
                        "fileName": "6400.6655 PRACTICUM DURATION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6660 ACADEMIC PROGRAM REVIEW",
                        "type": "reg",
                        "id": "05f2d1ab-41c3-452e-8e2a-4bf37052a7a6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F05f2d1ab-41c3-452e-8e2a-4bf37052a7a6.pdf?alt=media&token=edaa4c0d-080f-48af-8286-404e03ade9aa",
                        "fileExt": ".pdf",
                        "fileName": "6400.6660 ACADEMIC PROGRAM REVIEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7015 CORE COURSE REQUIREMENTS",
                        "type": "reg",
                        "id": "ab904d50-4766-423a-bf12-6a9e067e75f1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fab904d50-4766-423a-bf12-6a9e067e75f1.pdf?alt=media&token=54898294-02a5-425d-9cfb-af2ce6278f62",
                        "fileExt": ".pdf",
                        "fileName": "6400.7015 CORE COURSE REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7020 MINNESOTA COURSE REQUIREMENTS",
                        "type": "reg",
                        "id": "7e1f39f3-eb63-4ef5-be20-49a4348bc8ad",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7e1f39f3-eb63-4ef5-be20-49a4348bc8ad.pdf?alt=media&token=df02a301-91cd-43dd-978d-c30cc1b6fe4c",
                        "fileExt": ".pdf",
                        "fileName": "6400.7020 MINNESOTA COURSE REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7025 EVIDENCE OF COURSE COMPLETION",
                        "type": "reg",
                        "id": "bc030b21-9334-4884-bc69-f178914440e4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fbc030b21-9334-4884-bc69-f178914440e4.pdf?alt=media&token=fe09230a-4b60-4fae-a8f7-faf3ab3a8794",
                        "fileExt": ".pdf",
                        "fileName": "6400.7025 EVIDENCE OF COURSE COMPLETION.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7030 ASSISTED LIVING DIRECTOR IN RESIDENCE; FIELD EXPERIENCE",
                        "type": "reg",
                        "id": "a3b1e744-3062-4fb0-bec4-d9fc8d725e1c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa3b1e744-3062-4fb0-bec4-d9fc8d725e1c.pdf?alt=media&token=340e8e81-93e9-477b-be33-ad81cbcf4f63",
                        "fileExt": ".pdf",
                        "fileName": "6400.7030 ASSISTED LIVING DIRECTOR IN RESIDENCE; FIELD EXPERIENCE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7040 COURSE PROVIDER REVIEW",
                        "type": "reg",
                        "id": "673415b6-5465-40ee-81ca-f711239d5773",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F673415b6-5465-40ee-81ca-f711239d5773.pdf?alt=media&token=c4897fab-73af-4279-9c89-52ec0b85c47c",
                        "fileExt": ".pdf",
                        "fileName": "6400.7040 COURSE PROVIDER REVIEW.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Discipline",
                    "type": "dir",
                    "id": "47549337-bc4f-41ab-b647-ab15ab81d147",
                    "path": "Regulations/State/MN/Chapter 6400. LICENSING OF NURSING HOME ADMINISTRATORS/Discipline",
                    "items": [
                      {
                        "name": "6400.6900 GROUNDS FOR DISCIPLINE",
                        "type": "reg",
                        "id": "5595f50b-597f-4504-95c0-692aab997acb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F5595f50b-597f-4504-95c0-692aab997acb.pdf?alt=media&token=cf40bd01-71b0-42c8-8106-abc8017f4727",
                        "fileExt": ".pdf",
                        "fileName": "6400.6900 GROUNDS FOR DISCIPLINE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6950 APPLICABILITY",
                        "type": "reg",
                        "id": "347f8bf3-55e7-4047-b677-44ea9d66346d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F347f8bf3-55e7-4047-b677-44ea9d66346d.pdf?alt=media&token=89545fff-16c1-4a1e-948c-775f725a5e0b",
                        "fileExt": ".pdf",
                        "fileName": "6400.6950 APPLICABILITY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7000 USE OF TITLE",
                        "type": "reg",
                        "id": "3d47e227-073a-4428-8ded-dcf5c9c93853",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F3d47e227-073a-4428-8ded-dcf5c9c93853.pdf?alt=media&token=63ceb835-a4b3-4069-a549-2dfaeede4506",
                        "fileExt": ".pdf",
                        "fileName": "6400.7000 USE OF TITLE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7005 LICENSURE REQUIREMENTS",
                        "type": "reg",
                        "id": "c5ad9000-4049-4c17-b42d-a190a9677284",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc5ad9000-4049-4c17-b42d-a190a9677284.pdf?alt=media&token=06461e24-fc36-40d3-adbb-78e1a352f67f",
                        "fileExt": ".pdf",
                        "fileName": "6400.7005 LICENSURE REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7010 APPLYING FOR LICENSURE",
                        "type": "reg",
                        "id": "b706aca8-d6d1-4c11-b672-2ec1a6810290",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fb706aca8-d6d1-4c11-b672-2ec1a6810290.pdf?alt=media&token=c9248bcb-82d9-40e1-b55b-04c4512aafb9",
                        "fileExt": ".pdf",
                        "fileName": "6400.7010 APPLYING FOR LICENSURE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "General",
                    "type": "dir",
                    "id": "a2041ae0-ae2b-4a17-8a4b-b7c0dd5e4d30",
                    "path": "Regulations/State/MN/Chapter 6400. LICENSING OF NURSING HOME ADMINISTRATORS/General",
                    "items": [
                      {
                        "name": "6400.5000 SCOPE",
                        "type": "reg",
                        "id": "f70d1ca2-5133-4799-8f6e-9516c8375cda",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Ff70d1ca2-5133-4799-8f6e-9516c8375cda.pdf?alt=media&token=7debdbd9-e1cd-4eea-a265-4d03825edc4d",
                        "fileExt": ".pdf",
                        "fileName": "6400.5000 SCOPE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.5100 DEFINITIONS",
                        "type": "reg",
                        "id": "46d01436-b0f5-4a29-aea8-c65e4b444901",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F46d01436-b0f5-4a29-aea8-c65e4b444901.pdf?alt=media&token=4d4fa56a-ea9f-435f-ab20-26f4f09baee5",
                        "fileExt": ".pdf",
                        "fileName": "6400.5100 DEFINITIONS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.5150 APPLICABILITY",
                        "type": "reg",
                        "id": "de10ef3c-5b8a-448d-ab69-17e408873b02",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fde10ef3c-5b8a-448d-ab69-17e408873b02.pdf?alt=media&token=b906210d-1db3-4772-b137-d10181c3a187",
                        "fileExt": ".pdf",
                        "fileName": "6400.5150 APPLICABILITY.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.5200 USE OF TITLE",
                        "type": "reg",
                        "id": "7b61fcf3-b12d-4d98-a958-4ae9c7ca9e2c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7b61fcf3-b12d-4d98-a958-4ae9c7ca9e2c.pdf?alt=media&token=4414a57d-459a-4c9d-910c-d14ebcba3f9a",
                        "fileExt": ".pdf",
                        "fileName": "6400.5200 USE OF TITLE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6000 LICENSURE REQUIREMENTS",
                        "type": "reg",
                        "id": "dc604d30-9a31-4e46-87e9-52d6fd358b70",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fdc604d30-9a31-4e46-87e9-52d6fd358b70.pdf?alt=media&token=85596dd2-3391-46b5-b98b-b284caf6bb34",
                        "fileExt": ".pdf",
                        "fileName": "6400.6000 LICENSURE REQUIREMENTS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6100 APPLYING FOR LICENSURE",
                        "type": "reg",
                        "id": "c3af182c-f13d-4bab-adf7-c9cb135b2aa5",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fc3af182c-f13d-4bab-adf7-c9cb135b2aa5.pdf?alt=media&token=a824caf9-7981-4f2e-87b2-ce0658c824a6",
                        "fileExt": ".pdf",
                        "fileName": "6400.6100 APPLYING FOR LICENSURE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Licenses and Permits",
                    "type": "dir",
                    "id": "d60be5d9-e0e5-43de-a0db-0364344c32d1",
                    "path": "Regulations/State/MN/Chapter 6400. LICENSING OF NURSING HOME ADMINISTRATORS/Licenses and Permits",
                    "items": [
                      {
                        "name": "6400.6700 ENDORSEMENT",
                        "type": "reg",
                        "id": "ce3c5be0-3d26-4ee7-aef0-61f5d7ace5f1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fce3c5be0-3d26-4ee7-aef0-61f5d7ace5f1.pdf?alt=media&token=003c5e35-71db-4e52-bee5-75d351492f0a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6700 ENDORSEMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6710 LICENSEE RESPONSIBILITIES",
                        "type": "reg",
                        "id": "05ca5dd4-814f-40b4-af3a-3b995a84104a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F05ca5dd4-814f-40b4-af3a-3b995a84104a.pdf?alt=media&token=d6f9b15e-dc2d-482e-b8ca-5586bcd1addb",
                        "fileExt": ".pdf",
                        "fileName": "6400.6710 LICENSEE RESPONSIBILITIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6720 DISPLAYING LICENSES",
                        "type": "reg",
                        "id": "a5f134da-cb96-430d-a6db-710cff76828e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fa5f134da-cb96-430d-a6db-710cff76828e.pdf?alt=media&token=9917afa0-005a-4bbe-bd4f-6377c94dfa2f",
                        "fileExt": ".pdf",
                        "fileName": "6400.6720 DISPLAYING LICENSES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6730 DUPLICATE LICENSES",
                        "type": "reg",
                        "id": "8c5856c3-4f4c-4a9e-bdbc-9dc8b9eba394",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F8c5856c3-4f4c-4a9e-bdbc-9dc8b9eba394.pdf?alt=media&token=fec2de8e-c887-4057-96dc-490d1bc40f9a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6730 DUPLICATE LICENSES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6740 RENEWING LICENSES",
                        "type": "reg",
                        "id": "71634eaa-9681-44cb-ba65-3dc91b2b82c4",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F71634eaa-9681-44cb-ba65-3dc91b2b82c4.pdf?alt=media&token=a922636a-4d59-48ef-95c5-ab767e87a699",
                        "fileExt": ".pdf",
                        "fileName": "6400.6740 RENEWING LICENSES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6750 LICENSE REINSTATEMENT",
                        "type": "reg",
                        "id": "e7e7b37d-e5f7-4dd3-90d2-aa0f55dd532d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fe7e7b37d-e5f7-4dd3-90d2-aa0f55dd532d.pdf?alt=media&token=e36b529c-b3dd-40a1-9566-8ed835ac400f",
                        "fileExt": ".pdf",
                        "fileName": "6400.6750 LICENSE REINSTATEMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6760 VERIFICATION OF MINNESOTA LICENSE",
                        "type": "reg",
                        "id": "6e2cda25-a683-4888-920d-6306fbe3e08c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F6e2cda25-a683-4888-920d-6306fbe3e08c.pdf?alt=media&token=460dff28-c1ad-4a19-b60b-85c553406b9a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6760 VERIFICATION OF MINNESOTA LICENSE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.6770 ACTING ADMINISTRATOR PERMITS",
                        "type": "reg",
                        "id": "82d132c2-cfb8-4a11-aa0d-57aca5b33123",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F82d132c2-cfb8-4a11-aa0d-57aca5b33123.pdf?alt=media&token=38e3b1b3-f371-4ac9-aa36-840c319c778a",
                        "fileExt": ".pdf",
                        "fileName": "6400.6770 ACTING ADMINISTRATOR PERMITS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7045 ENDORSEMENT",
                        "type": "reg",
                        "id": "49ee98ad-da2b-42f1-bfb1-5e04ceb6c81f",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F49ee98ad-da2b-42f1-bfb1-5e04ceb6c81f.pdf?alt=media&token=2bdcc7fd-8edc-40b0-9283-aacc4cdb34a0",
                        "fileExt": ".pdf",
                        "fileName": "6400.7045 ENDORSEMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7050 LICENSEE RESPONSIBILITIES",
                        "type": "reg",
                        "id": "9e935236-c791-4cb4-bd7d-551ed94a6792",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9e935236-c791-4cb4-bd7d-551ed94a6792.pdf?alt=media&token=b51085b7-4ec0-4d37-8f2f-01e83af82781",
                        "fileExt": ".pdf",
                        "fileName": "6400.7050 LICENSEE RESPONSIBILITIES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7055 DISPLAYING LICENSES",
                        "type": "reg",
                        "id": "12539fac-4568-44a0-87c5-3283e5cb1906",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F12539fac-4568-44a0-87c5-3283e5cb1906.pdf?alt=media&token=8c4c22b6-b961-42a7-9d4b-9b6c959c6508",
                        "fileExt": ".pdf",
                        "fileName": "6400.7055 DISPLAYING LICENSES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7060 DUPLICATE LICENSES",
                        "type": "reg",
                        "id": "2a5607e7-4817-4325-bfdf-4660fe3cd751",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F2a5607e7-4817-4325-bfdf-4660fe3cd751.pdf?alt=media&token=78059128-f684-4122-9f22-fcb9b51beabe",
                        "fileExt": ".pdf",
                        "fileName": "6400.7060 DUPLICATE LICENSES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7065 RENEWING LICENSES",
                        "type": "reg",
                        "id": "7031c0d1-2c8a-4b65-88de-64665368c6ed",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F7031c0d1-2c8a-4b65-88de-64665368c6ed.pdf?alt=media&token=18709752-b2f2-4094-aeeb-9e5e315af0d3",
                        "fileExt": ".pdf",
                        "fileName": "6400.7065 RENEWING LICENSES.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7070 LICENSE REINSTATEMENT",
                        "type": "reg",
                        "id": "4f208a8a-75ba-4401-a9de-0a0308b26475",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F4f208a8a-75ba-4401-a9de-0a0308b26475.pdf?alt=media&token=306320d6-747c-4c76-a177-bd5c1e68d29d",
                        "fileExt": ".pdf",
                        "fileName": "6400.7070 LICENSE REINSTATEMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7075 VERIFICATION OF MINNESOTA LICENSE",
                        "type": "reg",
                        "id": "9d6e9fea-ae69-4a75-bb6b-ddd0cd0a33eb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F9d6e9fea-ae69-4a75-bb6b-ddd0cd0a33eb.pdf?alt=media&token=0574ec6a-bc91-4f7a-9802-345868f46c96",
                        "fileExt": ".pdf",
                        "fileName": "6400.7075 VERIFICATION OF MINNESOTA LICENSE.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7080 ASSISTED LIVING DIRECTOR IN RESIDENCE PERMITS",
                        "type": "reg",
                        "id": "64afa3e1-86f4-4e8b-8701-d8973674b3e9",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F64afa3e1-86f4-4e8b-8701-d8973674b3e9.pdf?alt=media&token=7e8dd6e9-6ef2-4d5e-a13a-b271edad8abe",
                        "fileExt": ".pdf",
                        "fileName": "6400.7080 ASSISTED LIVING DIRECTOR IN RESIDENCE PERMITS.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "6400.7085 SHARED DIRECTOR",
                        "type": "reg",
                        "id": "ea02f2ec-1302-46ab-9ca8-ceb9dcb514bc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fea02f2ec-1302-46ab-9ca8-ceb9dcb514bc.pdf?alt=media&token=2cd2161c-dc5a-49d4-bcb8-bd0acdb74799",
                        "fileExt": ".pdf",
                        "fileName": "6400.7085 SHARED DIRECTOR.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Standards of Practice",
                    "type": "dir",
                    "id": "7cecec69-cb0d-458f-a0c1-73eb3da17d25",
                    "path": "Regulations/State/MN/Chapter 6400. LICENSING OF NURSING HOME ADMINISTRATORS/Standards of Practice",
                    "items": [
                      {
                        "name": "6400.7095 STANDARDS OF PRACTICE; ENFORCEMENT",
                        "type": "reg",
                        "id": "ef07daa1-493f-449c-90dc-afac17a86339",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2Fef07daa1-493f-449c-90dc-afac17a86339.pdf?alt=media&token=bc0cbf7b-a78e-4c5b-88f1-76e36705dc26",
                        "fileExt": ".pdf",
                        "fileName": "6400.7095 STANDARDS OF PRACTICE; ENFORCEMENT.pdf",
                        "keywords": [
                          "regulations"
                        ],
                        "isBright": true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
}

const Policies = {
  name: "Policies and Procedures", type: "dir", open: false,
  "id": "3979863d-3322-4378-afa7-bb9146c108b9",
  "path": "Policies and Procedures",
  "storage": "PoliciesAndProcedures",
  "items": [
      {
        "name": "DRAFTV3 Vulnerable Adult Policy and Procedure 2017 5 23 Updated 4.8.22 Final",
        "type": "policy",
        "id": "747d9cce-3dfe-4bd8-b1f4-26dd13db367c",
        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F747d9cce-3dfe-4bd8-b1f4-26dd13db367c.pdf?alt=media&token=47a2b401-5d53-4f8a-9595-04f05ef821f6",
        "fileExt": ".pdf",
        "fileName": "DRAFTV3 Vulnerable Adult Policy and Procedure 2017 5 23 Updated 4.8.22 Final.pdf"
      }
    ]
}

const Campus = {
  "name": "Campus",
  type: "dir", 
  open: false,
  "id": "c2020ea9-8b2a-45cf-bce7-6e86ac661c412",
  "path": "Campus",
  "storage": "Campus",
  items: [
    {
      name: "Ackerberg Family Sholom West Campus",
      type: "dir", 
      isHidden: true,
      open: false, 
      id: "6b655419-8432-4173-a54e-cdd468411a01k",
      path: "WestCampus",
      items: [
        {
          name: "Care Center West - Vulnerable Adult Policy",
          type: "policy",
          id: "747d9cce-3dfe-4bd8-b1f4-26dd13db487c",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ftodelete%2FCare%20Center%20West%20-%20Vunerable%20Adult%20Policy.docx?alt=media&token=a858daef-1a4c-4ecb-840e-2642939185c9",
          fileExt: ".docx",
          fileName: "Care Center West - Vulnerable Adult Policy.docx",
          keywords: ['v2', 'vulnerable adult']
        }, 

        {
          name: "Care Center West - SNF instructions - Vulnerable Adult",
          type: "policy",
          id: "cder9cce-3dfe-4bd8-b1f4-26dd13db487c",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2Fr2%2FCare%20Center%20West%20-%20SNF%20instructions%20-%20Vulnerable%20Adult.pdf?alt=media&token=98214127-9581-45b9-bb83-9e6da65df74d",
          fileExt: ".pdf",
          fileName: "Care Center West - SNF instructions - Vulnerable Adult.pdf",
          keywords: ['v3', 'vulnerable adult']
        }
      ]
    },
    {
      name: "Shaller Family Sholom East Campus",
      type: "dir", 
      open: false,
      id: "6b655419-8432-4173-a54e-cdd491111a10b",
      path: "TODO",
      items: [
        {
          name: "Care Center East - Vulnerable Adult Policy",
          type: "policy",
          id: "747d9cce-3dfe-4bd8-b1f6-26dd13db487c",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ftodelete%2FCare%20Center%20East%20-%20Vunerable%20Adult%20Policy.docx?alt=media&token=605e3bc4-8afc-495f-9c7f-43c8651aa413",
          fileExt: ".docx",
          fileName: "Care Center East - Vulnerable Adult Policy.docx",
          keywords: ['v2', 'vulnerable adult'],
          isBright: true          
        },

        {
          name: "Care Center East - SNF Instructions - Vulnerable Adult",
          type: "policy",
          id: "7sed9cce-3dfe-4bd8-b1f6-26dd13db487c",
          link: "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2Fr2%2FCare%20Center%20East%20-%20SNF%20Instructions%20-%20Vulnerable%20Adult.pdf?alt=media&token=f5722712-9f84-413a-83ed-ea5fd8657a30",
          fileExt: ".pdf",
          fileName: "Care Center East - SNF Instructions - Vulnerable Adult.pdf",
          keywords: ['v3', 'vulnerable adult'],
          isBright: true          
        }
      ]
    },
  ]
}

// {...Services},
// {...Campus},
// {...Buildings}

const All = [  
  {...Policies},
  {...Regulations}
]

export default { All, Policies } 
