
function OfficeTab(){

  let file = 'gs://lumberjack-396203.appspot.com/ElysianSeniorHomes/WordTest.docx';

  file = "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FPolicies%20and%20Procedures%2FChapter%201%2F1.01%20Admissions%20policy.pdf?alt=media&token=910a173b-35be-4df9-b90f-d53731f61711";

  file = "https://storage.cloud.google.com/axis-b4487.appspot.com/WordTest.docx";
  // https://view.officeapps.live.com/op/embed.aspx?src=http://remote.url.tld/path/to/document.doc

//docs.google.com/gview?url=ht

  // file = "https://storage.googleapis.com/axis-b4487.appspot.com/WordTest.docx";
  // file = "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FWordTest.docx?alt=media&token=d716fa8f-5ad4-4e44-852a-504b1b718d73";
  
  //file = "https://storage.googleapis.com/axis-b4487.appspot.com/WordTest.docx";
  file = "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/ElysianSeniorHomes%2FWordTest.docx?alt=media&token=d716fa8f-5ad4-4e44-852a-504b1b718d73";

  file = encodeURIComponent(file);
  //"https://docs.google.com/gview?url=http://remote.url.tld/path/to/document.doc&embedded=true
  let path = `https://docs.google.com/gview?embedded=true&url=${file}`;

  return (
  <div>
    <iframe src={path} width='1366px' height='623px' frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
  </div>)
}

export default OfficeTab;