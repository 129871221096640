import React, { lazy } from "react";
import Layout from "./layout";
import MyProfile from "./panels/MyProfile";
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const Clinical = lazy(() => import("./pages/clinical/clinical"));
const Financial = lazy(() => import("./pages/financial/financial"));
const Notes = lazy(() => import("./pages/notes/notes"));
const Activity = lazy(() => import("./pages/activity/activity"));
const NewReferral = lazy(() => import("./pages/new/New"));

// const reload = () => window.location.reload();

// import Chat from './chat/Chat';
// import Details from './details/Details';
// import LandingPage from './LandingPage';
// const NewReferral  = lazy(() => import('./new/New'));
// const ProcessingReferral = lazy(() => import('./processing/Process'));
// https://github.com/remix-run/react-router/issues/7416

//i18next.addResourceBundle('en', 'ReferralModule', en);
//i18next.addResourceBundle('mx', 'ReferralModule', mx);

const ModuleRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "lumberjack",
      element: <Layout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        {
          path: "clinical",
          element: <Clinical />,
          children: [{ path: ":id", element: <Clinical /> }],
        },
        {
          path: "financial",
          element: <Financial />,
          children: [{ path: ":id", element: <Clinical /> }],
        },
      ],
    },
    {
      path: "lumberjack/new",
      element: <NewReferral />,
    },
    {
      path: "myprofile",
      element: <MyProfile />,
    },
  ],
};

export default ModuleRoutes;
