import { TOOL_TYPES, CATEGORIES } from '../../configs/toolsConfig';

const doseCalculatorConfig = {
  id: 'dose-calculator',
  name: 'Dose Calculator',
  description: 'Calculate medication doses for liquids and tablets',
  instructions: 'Enter the concentration of the medicine and the dose to be delivered.',
  type: TOOL_TYPES.CALCULATOR,
  category: CATEGORIES.MEDICAL,
  medicationTypes: ['liquid', 'tablet'],
  parameters: {
    medicationType: {
      type: 'string',
      enum: ['liquid', 'tablet'],
      description: 'Type of medication (liquid or tablet)'
    },
    concentration: {
      type: 'number',
      description: 'Stock concentration value'
    },
    concentrationUnit: {
      type: 'string',
      enum: ['mg', 'mcg'],
      description: 'Concentration unit'
    },
    volume: {
      type: 'number',
      description: 'Volume in mL (for liquid medications only)',
      required: false,
      dependsOn: {
        medicationType: 'liquid'
      }
    },
    dose: {
      type: 'number',
      description: 'Dose to be delivered'
    }
  }
};

export default doseCalculatorConfig;