import Stack from '@mui/material/Stack';
import { ReactComponent as UsersIcon } from '../../icons/users.svg';
import { ReactComponent as GroupIcon } from '../../icons/groups.svg';
import Button from '@mui/material/Button';

function Tabs() {
  
  return (<div className="p-6"> 

      <Stack direction="row" spacing={2}>
        <Button  startIcon={<UsersIcon />}>
          <span style={{fontWeight:700}}>Users</span>
        </Button>
        <Button disabled  startIcon={<GroupIcon />}>
          Groups
        </Button>
      </Stack>
    </div>
  )

}


export default Tabs;