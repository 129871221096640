import { useContext } from 'react';
import { createContext} from 'react';


export const Context = createContext({});

export default function IamContext() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('IamContext must be used within the IamProvider');
  }
  return context;
}