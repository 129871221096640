import { lazy } from 'react';

const SurveyPage = lazy(() => import('./SurveyPage')); 
const SpeechPage = lazy(() => import('./SpeechPage')); 

const SurveyRoutes = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'Survey',
      children: [     
        { path: '', element: <SurveyPage /> },
      ]
    },
    {
      path: 'Speech',
      children: [     
        { path: '', element: <SpeechPage /> },
      ]
    },
  ],
};

export default SurveyRoutes;
