import { useRef, useState, useEffect } from 'react';
import { ChatContext } from "./ChatContext";
import ChatSession from './ChatSession';
import useAuthContext from 'src/app/AuthContext';

function ChatProvider({namespace, children}) {
  const [ chatSessions, setChatSessions ] = useState([]);  // see: https://react.dev/learn/updating-arrays-in-state
  const [ chatSession, setChatSession ] = useState();
  const { currentUser } = useAuthContext();
  const [ showFeedback, setShowFeedback ] = useState(false);

  useEffect(() => {
    const sessions = chatSessions.filter(s => s.namespace == namespace);
    if(sessions.length == 0){               // create new session
      setChatSessions([
        ...chatSessions,
        new ChatSession(namespace)
      ])
    } 

    const filterSessions2 = chatSessions.filter(s => s.namespace == namespace);
    const match2 = (filterSessions2.length > 0)? filterSessions2[0] : null;
    if(match2?.namespace != chatSession?.namespace){
      setChatSession(match2);
    }  

  }, [namespace, chatSessions, chatSession]);

  const filterSessions = chatSessions.filter(s => s.namespace == namespace);
  const match = (filterSessions.length > 0)? filterSessions[0] : null;
  if(match == null){
    return (null)
  }

  
  if(chatSession == null) return (null);

  chatSession.on('onChange', (newSession) => {
    if(namespace != newSession.namespace) {
      return;
    }
    
    // update current Session
    setChatSession(newSession);

    // update session collection
    let sessions = [...chatSessions];
    let idx = sessions.findIndex(s => s.namespace == namespace);
    sessions.splice(idx, 1);
    
    setChatSessions([
      ...sessions,
      newSession
    ])
  })

  const state = ({
    chatSession, chatSessions, currentUser, showFeedback, setShowFeedback
  })

  return (<ChatContext.Provider value={state}>{children}</ChatContext.Provider>)
}

export default ChatProvider;