import React, {lazy} from 'react';
import Module from './DataMap/module';
import authRoles from '../../auth/authRoles';

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: null,
  routes: [
    {
      path: 'automation',
      children: [
        {
          path: 'DataMap',
          element: <Module />
        }
      ]      
    }
  ]
}

export default Routes;