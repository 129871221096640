import { useContext } from 'react';
import { createContext} from 'react';

/* 
  ChatContext manages state across ChatSessions.
  Child components access state by referencing useChatContext to extract any of the following:

  ChatSession:        State mapped to a specific namespace with the following attributes:
    - namespace:      The namespace associated with the current state
    - chatMessages:   The sessions messages between AI and the user 
    - directoryItems: The resources associated with the session
    - model:          The tab layout for the session

  ChatSessions: - A collection of all chat sessions across the application.

*/ 

export const ChatContext = createContext({});

export default function useChatContext() {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
}