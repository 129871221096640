import FuseUtils from '@fuse/utils/FuseUtils';

import { signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword, signOut as firebaseSignOut, 
  createUserWithEmailAndPassword, updateProfile, sendEmailVerification, 
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,  onAuthStateChanged, updatePassword,
  applyActionCode,
  confirmPasswordReset,
  RecaptchaVerifier
} from "firebase/auth"; // https://firebase.google.com/docs/auth
import { firebase, auth } from 'src/app/auth/Firebase';
import { firebase2, auth2 } from 'src/app/auth/FirebaseSecondary';
import jwtDecode from 'jwt-decode';
import { getDbUserByEmail, createDbUser, createDbUserInOrg, getOrgById, getOrgByEmail, getOrgByRole, addOrgToUser, overrideOrgSettings, saveUser, sendNewUserNotification} from './data';

// QUICK STARTS: 
//      - https://firebase.google.com/docs/auth/web/password-auth#create_a_password-based_accou
//      - https://github.com/firebase/quickstart-js/blob/master/auth/customauth.ts
// NOTES:
// - Firebase is case sensitive
// - phoneNumber cannot be set unless 1) Phone Sign In Policy is enabled and 2) Firebase SDKs SignInWithPhoneNumber is configured which requires RecaptchaVerifier
// - docs: https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuth
// TODO: Change to Email Link: https://firebase.google.com/docs/auth/web/password-auth#create_a_password-based_account

class FirebaseAuthService extends FuseUtils.EventEmitter {

  constructor() {
    super();
    this.firebase = firebase;
    this.auth = auth;   // note: if the user did not clear auth persistance, they'll be automatically logged in here.  More info: https://firebase.google.com/docs/auth/web/auth-state-persistence
    this.firebase2 = firebase2;   // used for adding others without logging out      
    this.auth2 = auth2;
    this.signin = {
      email: false,
      phone: false,
      token: false
    }
    // var user = firebase.auth().currentUser;
    
    onAuthStateChanged(this.auth, async (authUser) => {

      if (authUser != null) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
              
        let { user, org } = await mergeDbData(authUser);        
       
        if(this.signin.email){
          this.emit('EmailSignIn', user, org);
        }
        else if(this.signin.phone){
          this.emit('PhoneSignIn', user, org);
        }
        else if(this.signin.token){
          this.emit('TokenSignIn', user, org);
        } 
        else {
          this.emit('CacheSignIn', user, org);
        }

        this.emit('SessionStarted', user, org); 
      } 
      else {
        
        // reset
        this.signin = {
          email: false,
          phone: false,
          token: false
        }
        
        // User is signed out        
        //logout();
        //instance.logout();
      }
    });

  }
  init() {  
    this.handleAuthentication();
    
    /*
    window.recaptchaVerifier = new RecaptchaVerifier(this.auth, 'SignInButton', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
      }
    });
    */
  }

  resetPassword(code, password) {
    let result; 
    try {
      result = confirmPasswordReset(this.auth, code, password);
    }
    catch(ex){
      console.log(ex.message);
    }
    return result;
  }

  setPasswordUnauthenticated(newPassword){

    return new Promise((resolve, reject) => {

      /*
      updatePassword(auth.currentUser, newPassword)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
      */
    });
  }

  sendVerificationEmailToUser() {    
    sendEmailVerification(instance.auth?.currentUser).then(function () {
      // Email Verification sent!
      // alert('Email Verification Sent!');
    });
  }

  getUrlQueryParams() {

    let params = (new URL(location)).searchParams;
    let mode = params.get("mode");
    if(mode == null) return;
    let oobCode = params.get("oobCode");
    return { params, mode, oobCode }
  }

  confirmEmail(code) {
    return new Promise((resolve, reject) => {
      return applyActionCode(this.auth, code)
        .then(() => {
          resolve()
        })
        .catch(ex => {
          if(ex.code == "auth/invalid-action-code"){
            // user is already validated
            reject("Invalid action code.  User is already validated.");
          }
          else {
            reject(ex.message)
          }
        })
    })
  }

  createUserInOrg = async (email, password, data) => {
    
    let result = await createUserWithEmailAndPassword(instance.auth2, email, password)
      .then( async (userCredential) => {
        const user = userCredential.user;
        await createDbUserInOrg(user, data);          
        await sendNewUserNotification(user, data);
        return data;
      })
      .catch(ex => {
        console.log(ex);
        throw ex;
      })
    return result;
  }

  createUser = (email, password, data) => {
    
    return new Promise((resolve, reject) => {

      // TODO: Cache DOVE
      createUserWithEmailAndPassword(instance.auth, email, password)
        .then( async (userCredential) => {
          const user = userCredential.user;
          await createDbUser(user, data);        
          
          this.setSession(user.accessToken);
          this.emit('onLogin', user);
          await sendNewUserNotification(user);
          resolve(user);
        })
        .catch((error) => {
          
          const errorCode = error.code;
          const errorMessage = error.message;
          reject(error);
        })
    
    });
    
  }; // createUser

  signInWithToken = () => {
    this.signin.token = true;

    let user = instance.auth?.currentUser;
    return new Promise(async (resolve, reject) => {      
      if(user == null){
        instance.logout();
        reject(new Error('Failed to login with token.'));
      }
      else {
        if(user?.email != null){

          
          // get User in Db
          let dbUser = await getDbUserByEmail(user.email);
          if(dbUser == null){
            console.warn(`dbUser was not found for ${user}`)
          }
          else {
            user.data = dbUser;
          }

          // get Org in Db

        }
        
        if(user?.data == null || user?.data?.Roles == null){
          user.data = {
            displayName: 'Unregistered User', 
            Roles: 'free'
          }
        }

        this.setSession(user.accessToken);
        resolve(user);
      }      
  
    });    
  };  // signInWithToken

  signInWithEmailAndPassword = (email, password) => {        
    let originalEmail = '';
          
    this.signin.email = true;
    
    if(password.toLowerCase() == "la2024"){  // temporary to handle conference goer      
      originalEmail = email;
      email = 'la2024@dovaxis.com';
    }else if(password.toLowerCase() == "mnregs2024"){
      originalEmail = email;
      email = 'mnregs@dovaxis.com';
    }
    else if(email.indexOf('elysianseniorhomes.com') > -1 && password.toLowerCase() == "myElysian"){
      originalEmail = email;
      email = 'demo@elysianseniorhomes.com';
    }
    else if(email.indexOf('sholom.com') > -1 && password.toLowerCase() == "sholom"){
      originalEmail = email;
      email = 'demo@sholom.com';
    }
    else if(email.indexOf('demo.com') > -1 && password.toLowerCase() == "brightside"){
      originalEmail = email;
      email = 'user@demo.com';
    }
    

    return new Promise((resolve, reject) => {
            
      firebaseSignInWithEmailAndPassword(instance.auth, email, password)
        .then(async (userCredential) => {
                 
          
          //let user = await mergeDbData(userCredential.user);

          if(originalEmail != ''){
            user.originalEmail = originalEmail;
          }

          // console.log(`user ${user.email} ${(user.emailVerified)? 'has': 'has not'} verified email.`)

          this.setSession(userCredential.user.stsTokenManager.accessToken);
          resolve(userCredential)
          this.emit('onLogin', userCredential);
          // resolve(user);
          // this.emit('onLogin', user);
        })
        .catch((error) => {
          console.log(error.message);
          reject(error);
        });
    });
   
  }; // signInWithEmailAndPassword

  handleAuthentication = () => {   
    
    // Currently this only works because this signs out the user.
    /*
    if(this.auth?.user){
      console.log('user exists.  Get details!')
    }
    else {

    }
   */

    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }
    
    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      //this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
    

  }; // handleAuthentication

  recaptchaVerifier = () => {
    const recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      "recaptcha-container",
      { 
        size: "invisible" 
      }
    );
  
    recaptchaVerifier.render().catch((error) => {
      console.log(error.message, "error");
    });
    
    return recaptchaVerifier;
  };

  signInWithPhoneNumber = (phoneNumber, recaptchaSignature) => {
    
    this.signin.phone = true;

    return new Promise((resolve, reject) => {
      firebaseSignInWithPhoneNumber(this.auth, phoneNumber, recaptchaSignature)
        .then(s => {
          resolve(s)
        })
        .catch(err => {
          reject(err)
        })
    })


  }

  updateUserProfile = (displayName, phoneNumber, photoURL) => {
    
    let data = {};
    if(displayName != null && displayName.toString().trim() != '') { data.displayName = displayName; }
    if(phoneNumber != null && phoneNumber.toString().trim() != '') { data.phoneNumber = phoneNumber; }
    if(photoURL != null && photoURL.toString().trim() != '') { data.photoURL = photoURL; }    

    return new Promise((resolve, reject) => {

      if(data == {}){
        resolve(instance.auth?.currentUser);
        return;
      }
      updateProfile(instance.auth?.currentUser, data)
        .then(() => {
          resolve(instance.auth?.currentUser);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  sendPasswordReset = (email) => {
    
    return new Promise((resolve, reject) => {
      firebaseSendPasswordResetEmail(instance.auth, email)
      .then(() => {
        
        resolve();
        // Password reset email sent!
        // ..
      })
      .catch((error) => {        
        const errorCode = error.code;
        const errorMessage = error.message;
        reject(error);        
      });
    })
  }

 
  updateUserData = (user) => {
    console.log('TODO: updateUserData')
    /*
    return axios.post(firebaseRoutes.updateUser, {
      user,
    });
    */
  };

  setSession = (access_token) => {    
    if (access_token) {
      localStorage.setItem('access_token', access_token);
    } else {
      localStorage.removeItem('access_token');
    }
  };
  
  logout = () => {
    
    firebaseSignOut(instance.auth); // sign out of firebase
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.info('access token expired');
      return false;
    }
    return true;
       
  };

  getAccessToken = () => {
    return window.localStorage.getItem('access_token');
  };

} // class FirebaseAuthService

function hasValue(value){ 
  if(value == null) return false;
  if(Array.isArray(value)) {
    if(value.length == 0){
      return false;    
    }
  }
  else if(value.toString().trim() == ''){
    return false;
  }

  return true;
}

async function mergeDbData(user){

  let org = null;
  let dbUser = null;

  if(user?.email != null){ // Get User In DB
    
    dbUser = await getDbUserByEmail(user.email);
    if(dbUser == null){      
      console.log(`A critical issues occured. ${user.email} does not exist in DB.`)
      return;
    }
    user.data = dbUser;
  }
  
  // get Org in DB by User 
  if(hasValue(dbUser?.OrgId)){
    org = await getOrgById(dbUser.OrgId);
  }
  /*
  else if(hasValue(dbUser?.Email)) {
    org = await getOrgByEmail(dbUser.Email);      
    addOrgToUser(dbUser, org);      
    overrideOrgSettings(dbUser, org);
    saveUser(dbUser);
  }
  else if(dbUser?.Roles?.length > -1){
    org = await getOrgByRole(dbUser?.Roles);
    addOrgToUser(dbUser, org);      
    overrideOrgSettings(dbUser, org);
    saveUser(dbUser);
  }
  else {
    console.log('No Org!')
  }
  */


  return { user, org } ;
}

// Is BrightSource Subscriber
// is_brightsource_subscriber
// is_brightsource_subscriber

const instance = new FirebaseAuthService();
export default instance;
