import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ResetPasswordPage = lazy(() => import('./ResetPasswordPage'));
const CancelPasswordResetPage = lazy(() => import('./CancelPasswordResetPage'));

/*
const ClassicResetPasswordPage = lazy(() => import('./ClassicResetPasswordPage'));
const ModernResetPasswordPage = lazy(() => import('./ModernResetPasswordPage'));
const ModernReversedResetPasswordPage = lazy(() => import('./ModernReversedResetPasswordPage'));
const SplitScreenResetPasswordPage = lazy(() => import('./SplitScreenResetPasswordPage'));
const SplitScreenReversedResetPasswordPage = lazy(() =>
  import('./SplitScreenReversedResetPasswordPage')
);
const FullScreenResetPasswordPage = lazy(() => import('./FullScreenResetPasswordPage'));
const FullScreenReversedResetPasswordPage = lazy(() =>
  import('./FullScreenReversedResetPasswordPage')
);
*/

const resetPasswordPagesConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: 'cancel-reset-password',
      element: <CancelPasswordResetPage />,
    },
  ],
};


export default resetPasswordPagesConfig;
