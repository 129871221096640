import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { RegulationItems, ContentItems } from './data';
import  deepClone  from 'clone-deep';
import FuseUtils from '@fuse/utils';
import getDocIcon from './DocIcons';
import useChatContext from '../ChatContext';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';


function DocMessageType(props) {
  const { searchTerm } = props;
  const { chatSession } = useChatContext();

  if(searchTerm?.trim() == '') return;

  
  function openLink(docInfo) {
    console.log(`openLink ${JSON.stringify(docInfo)}`);
    chatSession.openDocTab(docInfo);
  }
  
  function getFilteredArray(arr, _searchText) {
    if (_searchText.length === 0) {
      return arr;
    }

    _searchText = _searchText.toLowerCase();

    let results = [];
    for(let i = 0; i<arr.length;i++){
      let resource = arr[i];
      
      for(let x = 0; x < resource?.data?.length; x++){
        let keywords = resource.data[x]?.keywords;
        if(!Array.isArray(keywords)) continue;
        if(keywords.includes(_searchText)){
          results.push(resource.data[x])
        }
      }

      /*
      resource.data = FuseUtils.filterArrayByString(resource.data, _searchText);
      results = results.concat(resource.data);=
      if(resource.data.length > 0){
        // resource.open = true;
      }
      */
    }

    return results;
  }

  let match = [];
  if(chatSession?.namespace == 'elysian-pp'){
    // resources = ContentItems;
    match = getFilteredArray(deepClone(ContentItems), searchTerm);
  }
  else if(chatSession?.namespace == 'elysian-regulations'){
    match = getFilteredArray(deepClone(RegulationItems), searchTerm);
    // resources = RegulationItems;
  }
  else if(chatSession?.namespace == 'AL-MN-V2'){
    match = getFilteredArray(deepClone(RegulationItems), searchTerm);
    
  }
  else if(chatSession?.namespace.toLowerCase() == 'sholom.com'){
    // match = getFilteredArray(deepClone(RegulationItems), searchTerm);
    match = [{
      "name": "Vulnerable Adult Policy and Procedure 2017 5 23 Updated 4.8.22 Final",
      "type": "POLICY",
      "id": "747d9cce-3dfe-4bd8-b1f4-26dd13db367c",
      "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Fsholom.com%2Fbrightsources%2F747d9cce-3dfe-4bd8-b1f4-26dd13db367c.pdf?alt=media&token=47a2b401-5d53-4f8a-9595-04f05ef821f6",
      "fileExt": ".pdf",
      "fileName": "Vulnerable Adult Policy and Procedure 2017 5 23 Updated 4.8.22 Final.pdf",
      "keywords": ['v1', 'vulnerable adult']
    }];
  }
  else {
    match = [];
    console.warn('UNKNOWN NAMESPACE!!!');
  }

  
  if(match.length == 0) return;

  return (
    <DocMessage className="max-w-full" >

      
      <Typography
        className="description leading-tight wrap pb-10 doclist"
        color="text.secondary"
      >                                
        I found this resource related to your question:
      </Typography>   
      

      {/* ORIGINAL:
      match.map((item, idx) => (
          <div key={idx} className='pt-10'>
            <div onClick={() => openLink(item)}>
            <h4>{getDocIcon(item)} {item.name}</h4>
            </div>
            <Typography
              className="description leading-tight wrap"
              color="text.secondary"
            >                                
              Document description will be displayed here...
            </Typography>  
          </div>
        ))
      */}
      { (chatSession?.namespace.toLowerCase().indexOf('elysian') > -1) && match.map((item, idx) => (
          <div key={idx} className='pt-10'>
            <div onClick={() => openLink(item)}>
            <h4>{<DocIcon item={item} />} {item.name}</h4>
            </div>
            <Typography
              className="description leading-tight wrap"
              color="text.secondary"
            >                                
              Document description will be displayed here...
            </Typography>  
          </div>
        ))
       }
      
      { (chatSession?.namespace.toLowerCase().indexOf('sholom') > -1) && match.map((item, idx) => (
          <div key={idx} className='pt-10'>
            <div onClick={() => openLink(item)}>
            <h4>{<DocIcon item={item} />} {item.name}</h4>
            </div>
            <Typography
              className="description leading-tight wrap"
              color="text.secondary"
            >                                
              
            </Typography>  
          </div>
        ))
      }
      
      <Typography
        className="description leading-tight wrap pt-14  doclist"
        color="text.secondary"
      >                                
        I'm reviewing it now to respond to your question. 
      </Typography>
      <Typography
        className="description leading-tight wrap pb-14 doclist"
        color="text.secondary"
      >                                
        One moment please...
      </Typography>
      
    </DocMessage>
  )
}



function DocIcon({item}){

  let isBright = (item?.isBright == null)? false : item.isBright;

  if(item?.fileExt == '.pdf') {
    return <div style={{display: 'inline-block', position: 'relative', marginRight: '10px'}}>      
      <img 
        alt="pdf file" 
        src='/assets/icons/Acrobat.png' style={{width:'17px',  height: '17px', position:'relative', left: '2px', alignSelf: 'center'}} 
        />
        {isBright && <FuseSvgIcon size={14} style={{position: 'absolute', top: -3, left: -13}}>
          heroicons-outline:dove
        </FuseSvgIcon>
        }
    </div>
  }
  else if (item?.fileExt == '.doc' || item?.fileExt == '.docx') {
    return (
      <div style={{display: 'inline-block', position: 'relative'}}> 
      <img
        src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/word_48x1.svg"
        style={{  marginRight: '10px'}}
        width="20"
        height="20"
        alt="Word product icon"
      />
        {isBright && <FuseSvgIcon size={14} style={{position: 'absolute', top: -3, left: -13}}>
            heroicons-outline:dove
          </FuseSvgIcon>
        }
      </div>
    )
  }
  else {
    return;
  }

  

}

// to merge left:
// marginLeft: 'auto',

const DocMessage = styled('div')(({ theme }) => ({

  marginLeft: 0,
  minWidth: '200px',  
  paddingTop: 30,
  paddingBottom: 0,
  marginBottom: 0,
  display: 'flex',
  paddingRight: '80px',  
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  '& h4': {
    color: '#297d8b',
    cursor: 'pointer',
    
  },
  '& .description': {
    fontSize: '12px',
  },
  '& .doclist': {
    color: '#111827'
  }
}));

export default DocMessageType;

