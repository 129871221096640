import BMICalculator from "../../components/Tools/calculators/BMICalculator/config.js";
import DoseCalculator from '../../components/Tools/calculators/DoseCalculator/config.js';
import UnitConverter from "../../components/Tools/calculators/UnitConverter/config.js";
import PPDCalculator from "../../components/Tools/calculators/PPDCalculator/config.js";
import IVCalculator from "../../components/Tools/calculators/IVCalculator/config.js";

import hasUnitIntent from './intent_Unit.js';
import hasIntent from './intent_temp.js';

UnitConverter.hasIntent = hasUnitIntent;
BMICalculator.hasIntent = hasIntent;
DoseCalculator.hasIntent = hasIntent;
PPDCalculator.hasIntent = hasIntent;
IVCalculator.hasIntent = hasIntent;

let Tools = [
  BMICalculator,
  DoseCalculator,
  UnitConverter,
  PPDCalculator,
  IVCalculator
];


function filterArray(value, deep=true){
  
  let tools = this;
  let logic = '';

  // filter tools by switch
  if(value != null && value.length > 1 && value.startsWith('/')) {
    let logicStart = value.indexOf(' ');   
    let id = '';
    if(logicStart == -1){
      id = value.substr(1).toLowerCase();
    }
    else {
      id = value.substr(1, logicStart - 1).toLowerCase();
      logic = value.substr(logicStart).toLowerCase().trim();
    }

    tools = this._filter(tool => tool.id.toLowerCase().startsWith(id));      
  } 
  else if (value != null) {
    logic = value.trim();
  }

  if(!deep){
    tools._filter = this._filter;
    tools.filter = filterArray;
    return tools;
  }

  // filter tools by logic
  if(logic != ''){
    console.log(logic);
    tools = tools._filter(tool => tool.hasIntent(logic));
  }
  return tools;
}

Tools._filter = Tools.filter;
Tools.filter = filterArray;


export default Tools