import { useState, useEffect } from 'react';
import userflow from 'userflow.js'; 
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import useAuthContext from 'src/app/AuthContext';
import userflowLogger, { USERFLOW_EVENTS, FLOWS } from './components/UserflowLogger';
import firebaseAuth from '../../auth/services/firebase/firebaseAuthService';
import WowSurvey from "./Wow/WowSurvey";

const Surveys = {
  'Wow': 'WowSurvey'
};

const SurveySelector = {
  'WowSurvey': WowSurvey
};


export const TrainingAndSurveys = (props) => {

  const [ userflowConfigured, setUserflowConfigured ] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { currentUser } = useAuthContext();
  const [ survey, showSurvey ] = useState('');


  useEffect(() => {
    if(currentUser != null && userflowConfigured == false) {      
      // user logged in
      setUserflowConfigured(true);
      activateUserflow();
    }
    else if(currentUser == null && userflowConfigured == true){
      // userflow is reset below in resetUserflow() (below). This syncs state...yes, not pretty.
      setUserflowConfigured(false);
    }
  }, [currentUser]);

    
  

  function hideSurvey(){
    showSurvey(''); 
  }

  async function activateUserflow() {
    
    let config = {
      name: currentUser.displayName,
      email: currentUser.email,              
      signed_up_at: new Date().toISOString(),              
      device_type: isMobile ? 'mobile' : 'desktop'
    }

    if(currentUser?.data?.FirstName){
      config.first_name = currentUser?.data?.FirstName;              
    }
    if(currentUser?.data?.LastName){
      config.last_name = currentUser?.data?.LastName;
    }
    
    try { 
      let id = currentUser?.uid || currentUser?.data?.ID;
      await userflow.identify(id, config);
      // userflowLogger.monitor(userflow, currentUser);
    }catch(ex){
      console.log(`Failed to associate ("identify") currentUser with flow: ${ex.message}`);
    }
    
    try {

      userflow.on(USERFLOW_EVENTS.FLOW_ENDED, (event) => {
  
        let flowId = event.flow.id;
   
        if(flowId != FLOWS.FreeBrightSourceTourV2.id) return;

        let userId = currentUser?.uid || currentUser?.data?.ID || currentUser?.data?.id;
        let contactId = currentUser?.data?.HubSpotUserId || currentUser?.data?.HubspotContactId || currentUser?.HubspotContactId;
        let email = currentUser?.email || currentUser?.data?.Email || currentUser?.data?.email;

        userflowLogger.log(USERFLOW_EVENTS.FLOW_ENDED, flowId, contactId, email, userId)

        setTimeout(() => {
          showSurvey(Surveys.Wow);
        }, 200); // delay survey load
        // alert('display survey');
      })
      
    }catch(ex){
      console.log(`An error occured triggering Dovaxis Userflow API: ${ex.message}`);
    }
  } // activateUserflow

  
  const SurveyToRender = SurveySelector[survey]; // or SurveySelector[props.name];

 
  return ( SurveyToRender ? <SurveyToRender {...props } onClose={hideSurvey} user={currentUser} ></SurveyToRender> : null  );

}


// Handle Resetting Userflow
function resetUserflow() {
  try {
    userflow.reset();
  }catch(ex) {
    console.log(`An error occured ending userflow. ${ex.message}`);
  }
}

firebaseAuth.on('onAutoLogout', ()=> {
  resetUserflow();
});
firebaseAuth.on('onLogout', ()=> {
  resetUserflow();
});


export default TrainingAndSurveys;