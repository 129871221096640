import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ManageAccount = lazy(() => import('./ManageAccount'));

const CallbackHandlerRoutes = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'manage-account',
      element: <ManageAccount />,
    },
  ],
};

export default CallbackHandlerRoutes;
