// Tools: https://react-material.fusetheme.com/documentation/material-ui-components/steppers
// Design: https://www.figma.com/file/fBHJg1LnL37j0Pk0r7Flw3/Lumberjack?node-id=0%3A1&mode=dev

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

const steps = [{name: 'Clinical Review', status: 'Completed'}, {name: 'Financial Review', status: 'Pending'}, {name: 'Admission Pending', status: 'Pending'}];

export default function HorizontalStepperWithError() {
  const isStepFailed = (step) => {
    return false;
    // return step === 1;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={1}>
        {steps.map((step, index) => {
          const labelProps = {};

          if((step.status)){
            labelProps.optional = (
                <Typography variant="caption" >
                  {step.status}
                </Typography>
              );
          }

          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Alert message
              </Typography>
            );

            labelProps.error = true;
          }

          return (
            <Step key={index}>
              <StepLabel {...labelProps}>{step.name}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}