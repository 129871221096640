import { firestore as db } from 'src/app/auth/Firebase';
import {getFirestore, collection, getDocs, getDoc, doc, setDoc, addDoc, query, where, onSnapshot, Timestamp, updateDoc} from 'firebase/firestore';
import { sendNewChatNotification } from 'src/app/auth/services/firebase/data';

const CONTAINER_ROOT = 'DocChat Content Sources';
const CONTAINER_HTML = 'html';
const CONTAINER_SOURCE_INFO = 'sourceInfo';
const CONTAINER_SEARCH_HISTORY = 'Search History';
const CONTAINER_FEEDBACK = 'Feedback';
const CONTAINER_BRIGHTEN = 'BrightSource Requests';

// syntax: YYYMMDD-{lastname}-{RandomID}
function createDocId(user){

  var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if(month.length == 1)
    month = "0" + month;
  if(day.length == 1)
  day = "0" + day;

  let email = getEmail(user);
       
  let prefix = [year, month, day].join('');
  
  // let sufix = randomFixedInteger(8);
  let sufix = d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();

  let id = `${prefix}-${email}-${sufix}`;
  return id;
}

var randomFixedInteger = function (length) {
  return Math.floor(Math.pow(10, length-1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length-1) - 1));
}

function getEmail(user){
  let email = '';
 if(user?.originalEmail != null){
    email = user?.originalEmail;
  }
  else if(user?.email != null){
    email = user?.email;
  }
  else {
    console.log('email is unknown');
  } 
  return email;
}

export async function saveBrightenRequest(chatSession, user, docInfo){
  return new Promise(async (resolve, reject) => {
    
    try {  

      let id = createDocId(user); 
      let email = getEmail(user);

      let data = {
        SessionId: chatSession.id,
        Email: email,
        Namespace: chatSession.namespace,
        Document: docInfo.fileName,
        CreatedOn: new Date()
      }
      
      await setDoc(doc(db, CONTAINER_BRIGHTEN, id), data);
      resolve();

    }
    catch(ex){
      console.log(`An error occured in saveFeedback. ${ex.message}`);
      reject(ex.message);
    }

  }) // Promise 
}

//
// saveFeedback: Save Chat Feedback
// 
export async function saveFeedback(chatSession, user, feedback){
  return new Promise(async (resolve, reject) => {
    
    let id = createDocId(user); 

    let email = '', question = '', answer = '', now = new Date();

    try {
      
      // get email
      if(user?.originalEmail != null){
        email = user?.originalEmail;
      }
      else if(user?.email != null){
        email = user?.email;
      }
      else {
        console.log('email is unknown');
      } 

      let last = chatSession.chat.length - 1;      
      question = chatSession.chat[last].question;
      
      if(chatSession.chat[last]?.value != null){
        answer = chatSession.chat[last].value;
      }
      else if(chatSession.chat[last]?.html != null){
        answer = chatSession.chat[last].html;
      }

      let data = {
        SessionId: chatSession.id,
        Email: email,
        Type: (feedback?.type != null)? feedback.type : 'Negative',
        Namespace: chatSession.namespace,
        Question: question,
        Answer: answer, 
        CreatedOn: now,
        Feedback: feedback
      }
      
      await setDoc(doc(db, CONTAINER_FEEDBACK, id), data);

      if(data.Type == 'Negative'){
        sendNewChatNotification(user, data);
      }
      

      resolve();

    }
    catch(ex){
      console.log(`An error occured in saveFeedback. ${ex.message}`);
      reject(ex.message);
    }

  })
}

//
// saveSearch: Save Chat History
// 
export async function saveSearch(question, user, hasResults, questionEmbedding, language, namespace, response){
  return new Promise(async (resolve, reject) => {
    let id = createDocId(user);    

    try {

      let now = new Date();
      let email = '';

      if(user?.originalEmail != null){
        email = user?.originalEmail;
      }
      else if(user?.email != null){
        email = user?.email;
      }
      else {
        console.log('email is unknown');
      }      

      let answer = response?.answer;
      if(answer == null || answer == undefined){
        answer = '';
      }
      
      // QuestionEmbedding: questionEmbedding,
      let data = {
        Question: question,
        Email: email,
        HasResults: hasResults,
        Namespace: namespace,
        CreatedOn: now,
        Answer: answer, 
        Language: language.id,
        Organization: user?.organization == null ? 'Unknown' : user.organization,
      }
      
      await setDoc(doc(db, CONTAINER_SEARCH_HISTORY, id), data);
      resolve();
    }
    catch(ex){
      console.log(`An error occured in saveSearch. ${ex.message}`);
      reject(ex.message);
    }
  })
}

export async function getReferenceHtml(namespace, fileName, titleShort){


  return new Promise(async (resolve, reject) => {
    // remove file extension if it exists
    let ext = fileName.substr(fileName.length - 4);
    if(ext.startsWith('.')){
      fileName = fileName.replace(ext, '');
    }

    try {

      let data;
      let docName = `${titleShort}-${fileName.toLocaleLowerCase()}`;

      // try new way
      let docRef = doc(db, CONTAINER_ROOT, namespace, CONTAINER_HTML, docName);
      let docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        data = docSnap.data();
        data.id = docRef.id;
      }
      else {      // try old way
        docRef = doc(db, CONTAINER_ROOT, namespace, CONTAINER_HTML, fileName);
        docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          data = docSnap.data();
          data.id = docRef.id;
        } else {
          console.log(`Reference HTML ${namespace} ${fileName} was not found!`);
        }
      }

      resolve(data);
    }
    catch(ex){
      console.log(`An error occured in getReferenceHtml. ${ex.message}`);
      reject(ex.message);
    }

  })
}

export async function getReferenceList(namespace) {
  return new Promise(async (resolve, reject) => {
    let results = [];

    try {
      let col = collection(db, CONTAINER_ROOT, namespace, CONTAINER_SOURCE_INFO);

      const q = query(col); // , where("Show", "==", true)

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach( (doc) => {
        let data = doc.data();
        data.id = doc.id;
        results.push(data);
      });

    }catch(ex){
      console.log(ex.message);
      reject(ex.message);
    }
    resolve(results);

  })
}

// let html = getReferenceHtml('conerstone-policy', 'Respiratory Protection Plan CNRC.png');
// console.log(html);


