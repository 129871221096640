import { useState, useEffect } from 'react';
import { AuthContext }  from './AuthContext';
import firebaseAuth from './auth/services/firebase/firebaseAuthService';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { logoutUser, setUser } from 'app/store/userSlice'; // these updates redux state
import history from '@history';
import UserActivity from './UserActivity';
import ACT from './UserActivities';
/*
  AutoProvider 
  ------------------
  Wires Authorization functionality for the app, including the following:
  
    - isAuthenticated:                indicates if logged in
    - currentUser:                    authenticated user
    - createUser:                     creates new user
    - signOut:                        logs out user
    - signInWithEmailAndPassword:     log's in user by email and password
    - sendPasswordReset               send password reset link
    - setPassword                     resetPassword
  
  Docs / Resources:
    - https://github.com/firebase/quickstart-js/blob/master/auth/email-password.ts
*/

function AuthProvider({currentUser, setCurrentUser, currentOrg, setCurrentOrg, children}) {
  
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ waitAuthCheck, setWaitAuthCheck ] = useState(true);
  const [ userActivity, setUserActivity ] = useState(new UserActivity());
  const dispatch = useDispatch();
  const useFirebaseAuth = true;

  // userActivity.setContext(currentUser, currentOrg);

  useEffect(() => {
    
    let loginDetails = {};

    firebaseAuth.on('SessionStarted', (user, org) => {

      if(user) {
        userActivity.setContext(user, org);
        userActivity.log(ACT.AUTH.LOGIN, loginDetails)
        userActivity.log(ACT.SESSION.START);

        setCurrentUser(user);
        setCurrentOrg(org);
        success(user, 'Signed in');
      }
      else {
        pass('');        
        //userActivity.log(ACT.SESSION.STOP);
        //userActivity.flush();
        //userActivity.setContext();
        setCurrentUser(null);
        setCurrentOrg(null);
      }

    }); // onAutoLogin


    firebaseAuth.on('EmailSignIn', (user, org) => {

      loginDetails = {
        type: 'email',
        provider: 'firebase'
      }

    });

    firebaseAuth.on('PhoneSignIn', (user, org) => {

      loginDetails = {
        type: 'phone',
        provider: 'firebase'
      }

    });

    firebaseAuth.on('TokenSignIn', (user, org) => {

      loginDetails = {
        type: 'token',
        provider: 'firebase'
      }

    });
    firebaseAuth.on('CacheSignIn', (user, org) => {

      loginDetails = {
        type: 'cache',
        provider: 'firebase'
      }
      
    });

    firebaseAuth.on('onLogin', (user) => {
      success(user, 'Signed in');
    });


    firebaseAuth.on('onLogout', () => {      
      
      pass('Signed out');            
      dispatch(logoutUser());
    });


    firebaseAuth.on('onAutoLogout', (message) => {
      
      userActivity.log(ACT.AUTH.LOGOUT);
      userActivity.log(ACT.SESSION.CLOSED);
      userActivity.reset();

      pass(message);      
      dispatch(logoutUser());
    });

    firebaseAuth.on('onNoAccessToken', () => {
      pass();
    });

    firebaseAuth.init();

  }, []); // useEffect

  function signInWithEmailAndPassword(email, password) {

    return new Promise(async (resolve, reject) => {    
      if(useFirebaseAuth){

        firebaseAuth
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
                
            userActivity.setContext(user);

            userActivity.log(ACT.AUTH.LOGIN, {
              type: 'email'
            })
            
            //setTimeout(() => history.push('/'), 50); 
            resolve(user);

          })
          .catch((errors) => {
            reject(errors);
          }
      );
      }
      else {
  
        /*
        jwtService
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
            // No need to do anything, user data will be set at app/auth/AuthContext
           
          })
          .catch((errors) => {
            reject(errors);
        });
        */
      }
    }) // promise
  }

  function signOut() {
    
    userActivity.log(ACT.AUTH.LOGOUT);
    userActivity.log(ACT.SESSION.STOP);
    userActivity.reset();


    firebaseAuth.logout();
    setTimeout(() => history.push('/sign-in'), 1000); 
    setCurrentUser(null);
  }

  function passwordReset() {
    userActivity.log(ACT.AUTH.PASSWORD_RESET_REQUESTED);
    setTimeout(() => history.push('/reset-password'), 50); 
  }

  function success(user, message) {      

    if (message) {
      dispatch(showMessage({ message, 
        autoHideDuration: 1000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }}
      ));
    }
    
    Promise.all([
      
      dispatch(setUser(user)), 
      
    ]).then((values) => {
      
      setWaitAuthCheck(false);
      setIsAuthenticated(true);
    });

  }; // success

  function pass(message) {
    if (message) {
      // dispatch(showMessage({ message }));
    }

    setWaitAuthCheck(false);
    setIsAuthenticated(false);
  } // pass
  
  function sendPasswordReset(email){
    return new Promise(async (resolve, reject) => {    

      firebaseAuth
        .sendPasswordReset(email)
          .then(() => {
            resolve();
          })
          .catch(errors => {
            reject(errors);
          })      
      })
  }

  function resetPassword(code, password){
    return firebaseAuth
      .resetPassword(code, password)
  }

  async function createUserInOrg(email, password, data, org, notificationConfig={sendVerification: false, sendInvite: true}){
    
    // ensure user is mapped to org
    if(org != null){ 
      data.orgPrimaryKey = org.key;
      if(data?.orgRef == null){ // define org refs
        data.orgRefs = [{
          id: org.id,
          key: org.key
        }]
      }
      else { // add if missing        
        let orgFound = false;
        if(data.orgRef.forEach(entry => {
          if(entry.id == org.id){
            orgFound = true;          
          }
        }))
        if(!orgFound){
          data.orgRef.push({
            id: org.id,
            key: org.key
          })
        }
      }      
    } 

    return firebaseAuth.createUserInOrg(email, password, data)
      .then((user) => {
        return user;
      })
      .catch((errors) => {
        throw errors;
      });

  } // createUserInOrg

  function createUser(email, password, data, sendVerification=true){
    return new Promise(async (resolve, reject) => {    
      firebaseAuth
      .createUser(email, password, data)
        .then((user) => {
                    
          if(sendVerification){
            firebaseAuth.sendVerificationEmailToUser();
          }

          dispatch(setUser(user)),
          setCurrentUser(user);
          resolve(user);

          if(user?.data?.Homepage){
            //setTimeout(() => history.push(user?.data?.Homepage), 500); 
          }else {
            //setTimeout(() => history.push('/'), 500); 
          }
          
        })
        .catch((errors) => {
          reject(errors);
        });
      }
    );      
  } // createUser

  function confirmEmail(code){
    return firebaseAuth.confirmEmail(code);
  }

  function recaptchaVerifier() {
    return firebaseAuth.recaptchaVerifier();
  }

  function signInWithPhoneNumber(phone, recaptchaSignature) {
    return firebaseAuth.signInWithPhoneNumber(phone, recaptchaSignature);
  }

  const handlers = ({
    // user handlers
    currentUser, createUser, createUserInOrg, isAuthenticated,
    // authentication handlers
    signOut, signInWithEmailAndPassword, confirmEmail,
    // reset handlers
    passwordReset, sendPasswordReset, resetPassword,
    // recaptcha and phone handlers
    recaptchaVerifier, signInWithPhoneNumber,
    // user activity logger
    userActivity, ACT
  })

  if(waitAuthCheck){
    return (<FuseSplashScreen />)
  }

  return (<AuthContext.Provider value={handlers}>{children}</AuthContext.Provider>)
}

export default AuthProvider;