
// crazy approach: https://ntrs.nasa.gov/api/citations/19950010854/downloads/19950010854.pdf

// Note: If intent is valid, return true

/* Intent Conditions for Unit Calculator

SUPPORTED:
 - Convert 100 degrees Fahrenheit to Celsius.
 - What's 37°C in Fahrenheit?
 - The forecast says 95°F; how hot is that in Celsius?
 - Please convert 68°F

UNDER DEVELOPMENT:
  - 	32c in f?  

CURRENTLY NOT SUPPORTED:
  - What is 41 in celseus? (spelling errors)
  - All of the above, plus in a different language

*/


function hasIntent(expression){

  if(convertTo(expression)){
    return true;

  }
  if(xInY(expression)){
    return true;

  }
  return false;
}

function xInY(expression) {
  let exp = expression.toLowerCase();
  let hasTo = false;
  let hasIn = false;
  let hasF = false;
  let hasC = false;
  let hasNumber = false;

  if(exp.indexOf('to') > -1){
    hasTo = true;
  }
  if(exp.indexOf('in') > -1){
    hasIn = true;
  }
  if(exp.indexOf('f') > -1){
    hasF = true;
  }
  if(exp.indexOf('c') > -1){
    hasC = true;
  }

  if(hasF && hasC && hasTo){
    return true;
  }

  if(hasF && hasC && hasIn){
    return true;
  }

  return false;
}

function convertTo(expression){

  let exp = expression.toLowerCase();
  let hasFahrenheit = false;
  let hasCelsius = false;
  let hasTo = false;
  let hasDegree = false;

  if(exp.indexOf('fahrenheit') > -1){
    hasFahrenheit = true;
  }
  if(exp.indexOf('celsius') > -1){
    hasCelsius = true;
  }
  if(exp.indexOf('to') > -1){
    hasTo = true;
  }
  if(exp.indexOf('°') > -1){
    hasDegree = true;
  }
  if(exp.indexOf('in') > -1){
    hasIn = true;
  }

  // Fahrenheit to Celsius
  if(hasFahrenheit && hasCelsius) {
    return true;
  }
  // to Fahrenheit
  if(hasFahrenheit && hasTo) {
    return true;
  }
  // to Celsius
  if(hasCelsius && hasTo) {
    return true;
  }

  // to Celsius
  if(hasDegree) {
    return true;
  }

  return false;
}

export default hasIntent;