import React, {useState, useContext} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useParams} from 'react-router-dom';
import data from './data';
import DataModelContext from './context';

const columns = [
  { id: 'name', label: 'UPLOADED FILE', minWidth: 50 },      
  { id: 'patient', label: 'PATIENT', minWidth: 100},
  { id: 'provider', label: 'PROVIDER', minWidth: 100 },
  { id: 'id', label: 'REFERRAL ID', minWidth: 50 },  
  { id: 'pageCount', label: 'PAGE COUNT', minWidth: 15, align: 'center' },
];

const rows = data.keys;

export default function DataModels() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selected, setSelected] = useState([]); // https://react-material.fusetheme.com/documentation/material-ui-components/table
  const navigate = useNavigate();
  const { currentModel, setCurrentModel } = useContext(DataModelContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  const onRowClick = (event, name) => {

    const idx = rows.findIndex(item => item.name == name);
    if(idx == -1) return;

    let referral = rows[idx];
    setCurrentModel(referral); // lift up state for toolbar

    let results = [].concat(name);
    setSelected(results)
    // let item = rows[idx];
    // navigate(`/keys/${item.id}`);  // opens right panel

  };


  return (
    <Paper className="m-10 p-20 " sx={{ width: '100%' }}>
      <TableContainer  sx={{ maxHeight: '100%', width: '100%'  }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead >
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: 'transparent',  color: 'primary.main', fontWeight: 700}}
                  sx={{borderBottom: .1, borderColor: '#496e7d'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="m-10">
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {

                const isItemSelected = isSelected(row.name);

                return (
                  <TableRow 
                    hover 
                    role="checkbox" 
                    tabIndex={-1} 
                    key={row.id}
                    onClick={(event) => onRowClick(event, row.name)}
                    selected={isItemSelected}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* 
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      */}
    </Paper>
  );
}