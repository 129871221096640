// WARNING!!! eventNames should be that reflect the act and do not change!!
// They should as few words as possible, all lowercase, and words should be seperated by an underscore

const ACT = {
  AUTH: {
    LOGIN:                        {category: 'AUTH', eventName: 'login',                      name: 'Login'},  // DONE
    LOGOUT:                       {category: 'AUTH', eventName: 'logout',                     name: 'Logout'}, // DONE
    FASTPASS:                     {category: 'AUTH', eventName: 'fastpass',                   name: 'Launched FastPass'},
    PASSWORD_RESET_REQUESTED:     {category: 'AUTH', eventName: 'password_reset_requested',   name: 'Requested Reset Password'}, // DONE
    PASSWORD_RESET:               {category: 'AUTH', eventName: 'password_reset',             name: 'Reset Password'},
    EMAIL_CONFIRMED:              {category: 'AUTH', eventName: 'email_confirmed',            name: 'Email Confirmed'},
  },

  SESSION: {
    START:                        {category: 'SESSION', eventName: 'session_started',         name: 'Session Started'},  // DONE
    STOP:                         {category: 'SESSION', eventName: 'session_stopped',         name: 'Session Stopped'},  // DONE
    PAUSED:                       {category: 'SESSION', eventName: 'session_paused',          name: 'Session Paused'},   // DONE
    RESTART:                      {category: 'SESSION', eventName: 'session_restart',         name: 'Session Restarted'},// DONE
    TIMEOUT:                      {category: 'SESSION', eventName: 'session_timeout',         name: 'Session Timeout'},                    // When a configurable time period elapsed
    CLOSED:                       {category: 'SESSION', eventName: 'session_closed',          name: 'Session Closed'},  // DONE            // When Firebase Logged User Off
    LEFT:                         {category: 'SESSION', eventName: 'session_left',            name: 'Session Redirection Close'}, // DONE  // When Firebase Logged User Off
  },

  Userflow: {
    TODO:                         {category: 'UAC', eventName: 'add_user',                    name: 'Add user'}
  },

  UAC: {
      ADD_USER:                   {category: 'UAC', eventName: 'add_user',                    name: 'Add user'}
  },

  CHAT: {
      ASKED_QUESTION:             { category: 'CHAT', eventName: 'ask_question',              name: 'Asked Question'},      // DONE
      GENERATE_QUESTIONS:         { category: 'CHAT', eventName: 'generate_questions',        name: 'Generate Questions'},  // DONE
      GOT_ANSWER:                 { category: 'CHAT', eventName: 'got_answer',                name: 'Received Answer'},     // DONE
      GOT_ERROR:                  { category: 'CHAT', eventName: 'got_error',                 name: 'Received Error'},      // DONE
      GAVE_FEEDBACK_POSITIVE:     { category: 'CHAT', eventName: 'gave_negative_feedback',    name: 'Gave Negative Feedback'},
      GAVE_FEEDBACK_NEGATIVE:     { category: 'CHAT', eventName: 'gave_postive_feedback',     name: 'Gave Positive Feedback'},
  },
  TOOLS: {
    TOOL_SELECTOR_OPEN:           {category: 'TOOLS', eventName: 'toolselector_open',         name: 'Open Tool Selector'},
    CALC_BMI_OPEN:                {category: 'TOOLS', eventName: 'calc_bmi_open',             name: 'Open BMI Calculator'},
    CALC_BMI_RUN:                 {category: 'TOOLS', eventName: 'calc_bmi_run',              name: 'Calculate BMI'},
    CALC_PPD_OPEN:                {category: 'TOOLS', eventName: 'calc_ppd_open',             name: 'Open PPD Calculator'},
    CALC_PPD_RUN:                 {category: 'TOOLS', eventName: 'calc_ppd_run',              name: 'Calculate PPD'},
    CALC_IV_OPEN:                 {category: 'TOOLS', eventName: 'calc_iv_open',              name: 'Open IV Calculator'},
    CALC_IV_RUN:                  {category: 'TOOLS', eventName: 'calc_iv_run',               name: 'Calculate IV Rate'},
    CALC_DOSE_OPEN:               {category: 'TOOLS', eventName: 'calc_dose_open',            name: 'Open Dose Calculator'},
    CALC_DOSE_RUN:                {category: 'TOOLS', eventName: 'calc_dose_run',             name: 'Calculate Dose'},
    CALC_UNIT_OPEN:               {category: 'TOOLS', eventName: 'calc_unit_open',            name: 'Open Unit Converter'},
    CALC_UNIT_RUN:                {category: 'TOOLS', eventName: 'calc_unit_run',             name: 'Convert Units'},
  }
}

/* OTHERS
eventName/Action	Description
Department Assignment	Tracks when a user is assigned or changes departments/roles
Document Access	Logs when a user accesses a document in the system (opened/closed)
Document Download/Print
similar to access

Document Update/Upload	Records updates made to a document, including version and date
Help Desk/Tutorial Access	Logs when a user accesses help desk or tutorials
Language Change	Records when a user changes the language used for AI responses
Language Preference Update	Records when a user updates their language preference (in profile perhaps)
Login eventName	Logs when a user logs into the system
Logout eventName	Logs when a user logs out of the system
Question Answered	Records when a question is answered and the time taken
Question Asked	Logs when a user asks a question, including level and scope details
Role Assignment	Records when a user is assigned or changes roles
Scope Selection	Logs when a user selects or changes a scope/level for AI responses
Scope Used in Question	Tracks the scope used for a particular question
Session End	Marks the end time of a user session
Session Start	Marks the start time of a user session
Status Change	Tracks changes to a user's status (e.g., Active, Inactive)
Ticket Submission	Tracks ticket submission, including issue details and response time
User Profile Updates	Name/email/phone/communication preferences/notifications etc.
*/

export default ACT
