const data = {

  "Michael Jackson": {
    PatientName: 'Michael Jackson',
    Summary: [], 
    Demographics: [
      { id: '1',  source: 'Michael Jackson',  name: 'First Name', value: 'Michael', dataModel: 'Patient', search: 'Michael', pages: [1,17], variations: []},
      { id: '2',  source: 'Michael Jackson',  name: 'Last Name', value: 'Jackson', dataModel: 'Patient', search: 'Jackson', pages: [1,17], variations: []},
      { id: '3',  source: 'Michael Jackson',  name: 'Sex', value: 'Male', dataModel: 'Patient', search: 'Male', pages: [1], variations: []},
      { id: '4',  source: 'Michael Jackson',  name: 'Social Security', value: '123-45-6789', dataModel: 'Patient', search: '123-45-6789', pages: [1], variations: []},
      { id: '5',  source: 'Michael Jackson',  name: 'Date of Birth ', value: '16316', dataModel: 'Patient', search: '16316', pages: [1], variations: ["09/01/1944"]},
      { id: '6',  source: 'Michael Jackson',  name: 'Patient Address', value: '1234 Happy St Dr. N, Minnetonka, MN 55305', dataModel: 'Patient', search: '1234 Happy st', pages: [1], variations: ["Dr N MINNETONKA MN", "55305"]},
      { id: '7',  source: 'Michael Jackson',  name: 'Patient Phone', value: '612-111-1111', dataModel: 'Patient', search: '612-111-1111', pages: [1], variations: []},
      { id: '8',  source: 'Michael Jackson',  name: 'Language Preferred ', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '9',  source: 'Michael Jackson',  name: 'Admission Date', value: '45170', dataModel: 'Provider', search: '45170', pages: [1], variations: ["09/01/2023"]},
      { id: '10',  source: 'Michael Jackson',  name: 'Hospital Name', value: '', dataModel: 'Provider', search: '', pages: [], variations: []},
      { id: '11',  source: 'Michael Jackson',  name: 'MRN#', value: '300000013', dataModel: 'Provider', search: '300000013', pages: [], variations: []},
      { id: '12',  source: 'Michael Jackson',  name: 'Attending Physician(s)', value: 'Feelgood, Stanley A, MD', dataModel: 'Provider', search: 'Feelgood, Stanley A, MD', pages: [1], variations: []},
    ],   
    Medications: [
      { id: '1',  source: 'Michael Jackson', name: 'Atorvastatin', dosage: '40mg', frequency: 'Take 1 Tablet by mouth daily', ecost: '$ 30.00', search: 'Atorvastatin', pages: [3, 21, 25], variations: []},
      { id: '2',  source: 'Michael Jackson', name: 'Cholecalciferol', dosage: '1000 units', frequency: 'Take 1 Tablet by mouth daily', ecost: '$ 11.87', search: 'Cholecalciferol', pages: [3, 25], variations: []},
      { id: '3',  source: 'Michael Jackson', name: 'Glucosamine-Chondriotin', dosage: '', frequency: '', ecost: '$ 8.55', search: 'Glucosamine-Chondroitin', pages: [3, 21, 25], variations: ["GLUCOSAMINE CHONDR", "GLUCOSAMINE CHONDR COMPLEX"]},
      { id: '4',  source: 'Michael Jackson', name: 'Multiple Vitamins-Minerals', dosage: '', frequency: '', ecost: '$ 8.10', search: 'Multiple Vitamins-Minerals', pages: [], variations: []},
      { id: '5',  source: 'Michael Jackson', name: 'Omega-3 fatty acids', dosage: '1000mg', frequency: 'Take 2 capsules by mouth daily', ecost: '$ 43.48', search: 'Omega-3 fatty acids', pages: [], variations: []},
      { id: '6',  source: 'Michael Jackson', name: 'Zinc gluconate', dosage: '50mg', frequency: 'Take 1 Tablet by mouth daily', ecost: '$ 7.07', search: 'Zinc gluconate', pages: [], variations: []},
      { id: '7',  source: 'Michael Jackson', name: 'Aspirin', dosage: '81mg', frequency: 'STOP', ecost: '$ 7.16', search: 'Aspirin', pages: [], variations: []},
      { id: '8',  source: 'Michael Jackson', name: 'Lisinopril', dosage: '10mg', frequency: 'STOP', ecost: '$ 8.74', search: 'Lisinopril', pages: [], variations: []},
      { id: '9',  source: 'Michael Jackson', name: 'Mupirocin', dosage: '2% ointment', frequency: 'STOP', ecost: '$ 11.87', search: 'Mupirocin', pages: [], variations: []},
      { id: '10',  source: 'Michael Jackson', name: 'Asprin Rectal Suppository', dosage: '300mg', frequency: 'DISCONTINUED', ecost: '$ 7.16', search: 'Asprin Rectal Suppository', pages: [], variations: []},
      { id: '11',  source: 'Michael Jackson', name: 'Aspirin Tablet', dosage: '325mg', frequency: 'DISCONTINUED', ecost: '$ 7.16', search: 'Aspirin Tablet', pages: [], variations: []}
    ],
    Diagnosis: [
      { id: '1',  source: 'Michael Jackson',  name: 'S43.43', txt: 'Brainstem lesion', search: 'S43.43', pages: [1,17], variations: ["Brainstem lesion"]},
      { id: '2',  source: 'Michael Jackson',  name: 'C85.10', txt: 'History of B-cell lymphoma', search: 'C85.10', pages: [], variations: ["History of B-cell lymphoma"]},
      { id: '3',  source: 'Michael Jackson',  name: 'R26.9', txt: 'Gait Abnormality', search: 'R26.9', pages: [], variations: ["Gait Abnormality"]},
      { id: '4',  source: 'Michael Jackson',  name: 'W19', txt: 'Fall', search: 'W19', pages: [], variations: ["Fall"]},
      { id: '5',  source: 'Michael Jackson',  name: 'I10', txt: 'HTM (hypertension) (HRC)', search: 'I10', pages: [], variations: ["HTM (hypertension) (HRC)", "hypertension"]},
      { id: '6',  source: 'Michael Jackson',  name: 'E78.49', txt: 'HLD (hyperlipidemia) (HRC)', search: 'E78.49', pages: [], variations: ["HLD (hyperlipidemia) (HRC)", "hyperlipidemia"]},
      { id: '7',  source: 'Michael Jackson',  name: 'A27.81', txt: 'Aseptic meningitis', search: 'A27.81', pages: [], variations: ["Aseptic meningitis"]},
      { id: '8',  source: 'Michael Jackson',  name: 'E87.1', txt: 'Mild hyponatremia', search: 'E87.1', pages: [], variations: ["Mild hyponatremia"]},
    ],
    Competency: [

      { id: '1',  source: 'Michael Jackson',  skill: 'Long-Term Care Planning', category: 'Geriatric Care', rationale: "The consultation with social work and the anticipation of a need for placement signifies the necessity for long-term care planning.", search: "SW consult, anticipate patient may need placement", pages: [5], variations: []},
      { id: '2',  source: 'Michael Jackson',  skill: 'Assessment and Management of Geriatric Syndromes', category: 'Geriatric Care', rationale: "The combined symptoms and challenges in a geriatric patient with Alzheimer's dementia stress the need for specialized geriatric assessments and interventions.", search: "Patient with a history of advanced Alzheimer's dementia presents with weakness.", pages: [], variations: []},
      { id: '3',  source: 'Michael Jackson',  skill: 'Mobility assistance', category: 'Neurological Care', rationale: "Gait instability and dizziness are direct indicators of potential mobility issues, and the patient may need assistance to prevent falls or further injuries.", search: "ADMITTING COMPLAINT: Gait instability, dizziness.", pages: [], variations: ["Instructions with repeated cueing for keeping walker close and in front." , "Instructions on not picking walker up with turnings, big wide turns rather then small shuffle" , "Instructions with repeated cueing for keeping walker close and in front." , "He has been requiring assistance with ambulation"]},
      { id: '4',  source: 'Michael Jackson',  skill: 'Assistance with activities of daily living (ADLs)', category: 'Geriatric Care', rationale: "Impairment in performing daily activities indicates a need for assistance in those areas to ensure the patient's safety and well-being.", search: "5. Impaired activities of daily living.", pages: [], variations: ["Endurance: inadequate for household mobility" , " Strength: Generalized weakness" , "LE dressing: Min assist to don/doff socks. Decreased sitting balance with task." , "Recliner: CGA sitting/standing" , "Bed: SBA sit <> supine; CGA to approach bed and sit"]},
      { id: '5',  source: 'Michael Jackson',  skill: 'Infection identification and management', category: 'Infectious Disease Management', rationale: "Brain lesions and symptoms suggestive of meningitis require expertise in identifying and managing infections to ensure appropriate care.", search: "Brain lesions and aseptic meningitis NOS", pages: [], variations: ["This looks like a viral brain infection to me" , "viral meningitis"]},
      
    ], 
    DME: [
      { id: '1',  cost: 255.00, source: 'Michael Jackson',  equipment: 'Walker / 4 wheeled walker', category: 'Mobility Aid', search: 'Gait instability, Recurrent falls', pages: [5], variations: ["Current adaptive equipment: Grab bars: inside tub, single end cane and 4 wheeled walker , pill box" , " Equipment: front wheeled walker" , "keeping walker close and in front" , "using 4 wheeled walker", "Gait: , Equipment: front wheeled walker"]},
      { id: '2',  cost: 3.86, source: 'Michael Jackson',  equipment: 'Foley Catheter', category: 'Urinary Equipment', search: 'The patient had urinary retention. He did not report urinary issues on admission. He was noted to have severe retention on the CT. Foley was placed.', pages: [], variations: ["foley" , "Foley (Urinary Catheter)", "3 weeks for foley and urinary" , "able to walk slowly with walker" , "Special Equipment: Catheter (hematuria noted)"]},
      { id: '3',  cost: 43.75, source: 'Michael Jackson',  equipment: 'Grab bars', category: 'Bathroom safety', search: 'Current adaptive equipment: Grab bars: inside tub, single end cane and 4 wheeled walker , pill box', pages: [], variations: []},
      { id: '4',  cost: 35, source: 'Michael Jackson',  equipment: 'Single end cane', category: 'Mobility aids', search: 'Current adaptive equipment: Grab bars: inside tub, single end cane and 4 wheeled walker , pill box', pages: [], variations: []},
      { id: '5',  cost: 15.5, source: 'Michael Jackson',  equipment: 'Pill box', category: 'Medication management', search: 'Current adaptive equipment: Grab bars: inside tub, single end cane and 4 wheeled walker , pill box', pages: [], variations: []},
      { id: '6',  cost: '', source: 'Michael Jackson',  equipment: 'Therapy Equipment (as determined by therapist)', category: 'Therapy Equipment', search: 'Occupational Therapy Evaluation and Treatment', pages: [], variations: [" Occupational Therapy" , "Occupational Therapy Neuro Progress Note"]},
      { id: '7',  cost: '', source: 'Michael Jackson',  equipment: 'Physical Therapy Equipment (as determined by therapist)', category: 'Therapy Equipment', search: 'Physical Therapy Evaluation and Treatment', pages: [9], variations: ["Physical Therapy" , "Physical Therapy Daily Progress Note"]},
      { id: '8',  cost: '', source: 'Michael Jackson',  equipment: 'Speech Therapy Equipment (as determined by therapist)', category: 'Therapy Equipment', search: 'Speech Therapy Evaluation and Treatment', pages: [], variations: ["Speech Therapy ", ]},
    ],
  }, 


  "Tiny Tim":  {
    PatientName: 'Tiny Tim',
    Summary: [], 
    Demographics: [
      { id: '1',  source: 'Tiny Tim',  name: 'First Name', value: 'Tim', dataModel: 'Patient', search: 'Tim', pages: [], variations: []},
      { id: '2',  source: 'Tiny Tim',  name: 'Last Name', value: 'Tiny', dataModel: 'Patient', search: 'Tiny', pages: [], variations: []},
      { id: '3',  source: 'Tiny Tim',  name: 'Sex', value: 'Male', dataModel: 'Patient', search: 'Male', pages: [], variations: []},
      { id: '4',  source: 'Tiny Tim',  name: 'Social Security', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '5',  source: 'Tiny Tim',  name: 'Date of Birth ', value: '11511', dataModel: 'Patient', search: '11511', pages: [], variations: []},
      { id: '6',  source: 'Tiny Tim',  name: 'Patient Address', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '7',  source: 'Tiny Tim',  name: 'Patient Phone', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '8',  source: 'Tiny Tim',  name: 'Language Preferred ', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '9',  source: 'Tiny Tim',  name: 'Admission Date', value: '44468', dataModel: 'Provider', search: '44468', pages: [], variations: []},
      { id: '10',  source: 'Tiny Tim',  name: 'Hospital Name', value: '', dataModel: 'Provider', search: '', pages: [], variations: []},
      { id: '11',  source: 'Tiny Tim',  name: 'MRN#', value: '1000001130', dataModel: 'Provider', search: '1000001130', pages: [], variations: []},
      { id: '12',  source: 'Tiny Tim',  name: 'Attending Physician(s)', value: '', dataModel: 'Provider', search: '', pages: [], variations: []}
    ], 
    Medications: [
      { id: '1',  source: 'Tiny Tim', name: 'amiodarone', dosage: '200 mg tablet', frequency: 'once daily by mouth', ecost: '$ 32.47', search: 'amiodarone', pages: [], variations: []},
      { id: '2',  source: 'Tiny Tim', name: 'atorvastatin', dosage: '20 mg tablet', frequency: 'at bedtime by mouth', ecost: '$ 14.89', search: 'atorvastatin', pages: [], variations: []},
      { id: '3',  source: 'Tiny Tim', name: 'finasteride', dosage: '5 mg tablet', frequency: 'once daily by mouth', ecost: '$ 21.56', search: 'finasteride', pages: [], variations: []},
      { id: '4',  source: 'Tiny Tim', name: 'melatonin', dosage: '3 mg tablet', frequency: 'at bedtime by mouth', ecost: '$ 9.35', search: 'melatonin', pages: [], variations: []},
      { id: '5',  source: 'Tiny Tim', name: 'sennosides-docusate', dosage: 'tablet', frequency: '2 times daily if needed for constipation by mouth', ecost: '$ 12.78', search: 'sennosides-docusate', pages: [], variations: []},
      { id: '6',  source: 'Tiny Tim', name: 'tamsulosin', dosage: '0.4 mg capsule', frequency: 'once daily after a meal by mouth', ecost: '$ 27.43', search: 'tamsulosin', pages: [], variations: []},
    ],     
    Diagnosis: [
      { id: '1',  source: 'Tiny Tim',  name: 'I10', txt: "Unspecified essential hypertension ", search: 'I10', pages: [], variations: ["Unspecified essential hypertension "]},
      { id: '2',  source: 'Tiny Tim',  name: 'E78.5', txt: "Other and unspecified hyperlipidemia ", search: 'E78.5', pages: [], variations: ["Other and unspecified hyperlipidemia "]},
      { id: '3',  source: 'Tiny Tim',  name: 'K62.0', txt: "Anal polyp", search: 'K62.0', pages: [], variations: ["Anal polyp"]},
      { id: '4',  source: 'Tiny Tim',  name: 'I74.1', txt: "Paroxysmal supraventricular tachycardia (HC) ", search: 'I74.1', pages: [], variations: ["Paroxysmal supraventricular tachycardia (HC) "]},
      { id: '5',  source: 'Tiny Tim',  name: 'F10.2', txt: "alchohol use, possibly dependent", search: 'F10.2', pages: [], variations: ["alchohol use, possibly dependent"]},
      { id: '6',  source: 'Tiny Tim',  name: 'R53.1', txt: "weakness", search: 'R53.1', pages: [], variations: ["weakness"]},
      { id: '7',  source: 'Tiny Tim',  name: 'N40', txt: "IBPH (benign prostatic hyperlasia)", search: 'N40', pages: [], variations: ["IBPH (benign prostatic hyperlasia)"]},
      { id: '8',  source: 'Tiny Tim',  name: 'F02.80', txt: "Late onset Alzheimer's disease without behavioral disturbance (HC) ", search: 'F02.80', pages: [], variations: ["Late onset Alzheimer's disease without behavioral disturbance (HC) "]},
      { id: '9',  source: 'Tiny Tim',  name: 'K62.1', txt: "Rectal Polyp", search: 'K62.1', pages: [], variations: ["Rectal Polyp"]},
    ], 
    Competency: [
      { id: '1',  source: 'Tiny Tim',  skill: 'Dementia Management', category: 'Neurological Care', rationale: "The patient's age and history of advanced dementia require specialized neurological care tailored to manage the associated symptoms and complications.", search: "Mr. Tim Tiny is a 90 y.o. male with a history of advanced dementia", pages: [3, 21, 25], variations: ["advanced dementia" , "history of advanced Alzheimer's dementia"]},
      { id: '2',  source: 'Tiny Tim',  skill: 'Mobility Assistance', category: 'General Nursing Care', rationale: "The noted decline in the patient's mobility suggests a need for assistance and interventions to promote and maintain physical activity.", search: "Daughter states that patient previously walked using a walker but has recently not wanted to get out of bed.", pages: [3, 25], variations: ["advanced dementia" , "family stated they could not get him out of bed today"]},
      { id: '3',  source: 'Tiny Tim',  skill: 'Medication Administration', category: 'Medication Management', rationale: "The necessity of crushing medications and providing feeding assistance indicates challenges with oral intake and the need for proper medication administration techniques.", search: "Family crushes his medications", pages: [3, 21, 25], variations: ["history of advanced Alzheimer's dementia" , "advanced dementia"]},
      { id: '4',  source: 'Tiny Tim',  skill: 'Feeding Assistance', category: 'Nutritional Care', rationale: "The family's active involvement in feeding him suggests potential swallowing or intake challenges, necessitating support for feeding.", search: "assists with feeding him.", pages: [], variations: []},
      { id: '5',  source: 'Tiny Tim',  skill: 'Skin Monitoring and Rash Management', category: 'Dermatological Care', rationale: "The presence of incontinence-associated rashes indicates the need for ongoing skin care and monitoring to prevent complications.", search: "He is occasionally incontinent", pages: [], variations: ["family noted a rash on his back/ buttocks" , " left upper buttock wound not examined" , "3mm open wound without surrounding erythema"]},
      { id: '6',  source: 'Tiny Tim',  skill: 'Wound Assessment and Treatment', category: 'Wound Care', rationale: "The existence of a wound, especially one acquired during a previous stay, indicates the necessity of wound care and appropriate assessment and treatment.", search: "3mm open wound without surrounding erythema", pages: [], variations: ["drainage to left upper buttock noted on admission to ED" , "wound care consult" , "LEFT BUTTOCK WOUND" , " left upper buttock wound not examined"]},
      { id: '7',  source: 'Tiny Tim',  skill: 'Blood Pressure Monitoring', category: 'Cardiovascular Care', rationale: "The patient's medication and the decision to monitor its effects underscore the importance of consistent blood pressure tracking.", search: "Taking lisinopril PTA, Will hold on admission with weakness and watch blood pressure.", pages: [], variations: ["Hold lisinopril follow BP" , "BP 156/76" , "Unspecified essential hypertension"]},
      { id: '8',  source: 'Tiny Tim',  skill: 'Cholesterol Management', category: 'Cardiovascular Care', rationale: "Taking cholesterol-lowering medication, such as atorvastatin, highlights the necessity of cholesterol monitoring and management.", search: "PTA taking atorvastatin daily", pages: [], variations: ["tablet atorvastatin (LIPITOR) 20 mg"]},
      { id: '9',  source: 'Tiny Tim',  skill: 'Heart Rate Monitoring', category: 'Cardiovascular Care', rationale: "The use of amiodarone, a medication for irregular heartbeats, implies the importance of monitoring heart rate and rhythm for potential abnormalities.", search: "Taking amiodarone PTA", pages: [], variations: ["follow HR" , "Amiodarone (CORDARONE) 200 mg tabletTake 200 mg by mouth once daily"]},
      { id: '10',  source: 'Tiny Tim',  skill: 'Urinary Incontinence Management', category: 'Urological Care', rationale: "The patient's urinary incontinence, combined with the medications taken, highlights the requirement for specialized urological care.", search: "needing assistance with feeding and incontinence", pages: [], variations: ["Incontinent at times per family" , "He is occasionally incontinent"]},
    ], 
    DME: [
      { id: '1',  source: 'Tiny Tim',  equipment: 'Walker', category: 'Mobility Aid', search: 'Daughter states that patient previously walked using a walker but has recently not wanted to get out of bed.', pages: [], variations: []},
      { id: '2',  source: 'Tiny Tim',  equipment: 'Wound Care Supplies', category: 'Wound Care', search: 'Patient with a —3mm open wound without surrounding erythema or drainage to left upper buttock noted on admission to ED', pages: [], variations: ["wound care consult" , "left upper buttock wound not examined"]},
      { id: '3',  source: 'Tiny Tim',  equipment: 'Incontinence Supplies', category: 'Personal Care', search: 'Assessment: Taking flomax and proscar PTA. Incontinent at times per family', pages: [], variations: ["He is occasionally incontinent"]},
      { id: '4',  source: 'Tiny Tim',  equipment: 'Assistive Feeding Devices', category: 'Feeding Aid', search: 'Family crushes his medications and assists with feeding him.', pages: [], variations: []},
      { id: '5',  source: 'Tiny Tim',  equipment: 'Bed Protection Pads', category: 'Bedding', search: 'EMS called and found patient in urine soaked bed.', pages: [], variations: ["He is occasionally incontinent"]},
      { id: '6',  source: 'Tiny Tim',  equipment: 'Condom Catheter', category: 'Urinary Supplies', search: 'trial condom catheter', pages: [], variations: ["He is occasionally incontinent"]},
    ]
  },

 
  "Lois Lane":  {
    PatientName: 'Lois Lane',
    Summary: [], 
    Demographics: [{ id: '1',  source: 'Lois Lane',  name: 'First Name', value: 'Lois', dataModel: 'Patient', search: 'Lois', pages: [], variations: []},
      { id: '2',  source: 'Lois Lane',  name: 'Last Name', value: 'Lane', dataModel: 'Patient', search: 'Lane', pages: [], variations: []},
      { id: '3',  source: 'Lois Lane',  name: 'Sex', value: 'Female', dataModel: 'Patient', search: 'Female', pages: [], variations: []},
      { id: '4',  source: 'Lois Lane',  name: 'Social Security', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '5',  source: 'Lois Lane',  name: 'Date of Birth ', value: '10228', dataModel: 'Patient', search: '10228', pages: [], variations: []},
      { id: '6',  source: 'Lois Lane',  name: 'Patient Address', value: '740 Kay Ave St. Paul MN 55102', dataModel: 'Patient', search: '740 Kay Ave St. Paul MN 55102', pages: [], variations: []},
      { id: '7',  source: 'Lois Lane',  name: 'Patient Phone', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '8',  source: 'Lois Lane',  name: 'Language Preferred ', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '9',  source: 'Lois Lane',  name: 'Admission Date', value: '44978', dataModel: 'Provider', search: '44978', pages: [], variations: []},
      { id: '10',  source: 'Lois Lane',  name: 'Hospital Name', value: '', dataModel: 'Provider', search: '', pages: [], variations: []},
      { id: '11',  source: 'Lois Lane',  name: 'MRN#', value: '1004517284', dataModel: 'Provider', search: '1004517284', pages: [], variations: []},
      { id: '12',  source: 'Lois Lane',  name: 'Attending Physician(s)', value: '', dataModel: 'Provider', search: '', pages: [], variations: []}
    ],    
    Medications: [
      { id: '1',  source: 'Lois Lane', name: 'amlodipine 2.5 mg tablet', dosage: '2.5mg', frequency: '2.5 mg Take 1 tablet by mouth once daily', ecost: '8.77', search: 'amlodipine 2.5 mg tablet', pages: [], variations: []},
      { id: '2',  source: 'Lois Lane', name: 'levofloxacin 750 mg tablet', dosage: '750mg', frequency: '750 mg Take 1 tablet by mouth every 48 hours for 1 day.', ecost: '9.39', search: 'levofloxacin 750 mg tablet', pages: [], variations: []},
      { id: '3',  source: 'Lois Lane', name: 'losartan 100 mg tablet', dosage: '100mg', frequency: '100 mg Take 1 tablet by mouth once daily', ecost: '22.30', search: 'losartan 100 mg tablet', pages: [], variations: []},
      { id: '4',  source: 'Lois Lane', name: 'albuterol HFA 90 mcg/actuation inhaler 90 mcg/actuation', dosage: '90mcg', frequency: 'Inhale 2 puffs by mouth 4 times daily if needed.', ecost: '30.89', search: 'albuterol HFA 90 mcg/actuation inhaler 90 mcg/actuation', pages: [], variations: []},
      { id: '5',  source: 'Lois Lane', name: 'calcium carbonate 500 mg chewable tablet', dosage: '500mg', frequency: '500 mg Chew 1 tablet by mouth 3 times daily if needed for heartburn', ecost: '8.40', search: 'calcium carbonate 500 mg chewable tablet', pages: [], variations: []},
      { id: '6',  source: 'Lois Lane', name: 'diclofenac topical 1% gel', dosage: '0.01', frequency: 'Apply 2 g topically to affected area(s) 4 times daily if need', ecost: '20.93', search: 'diclofenac topical 1% gel', pages: [], variations: []},
      { id: '7',  source: 'Lois Lane', name: 'ferrous sulfate (65 mg elemental) tablet 325 mg', dosage: '325mg', frequency: 'Take 1 tablet by mouth every other day. (Mon, Wed, Fri) ', ecost: '7.64', search: 'ferrous sulfate (65 mg elemental) tablet 325 mg', pages: [], variations: []},
      { id: '8',  source: 'Lois Lane', name: 'Metamucil 3.4 gram/5.4 gram powder', dosage: '3.4g/5.4g', frequency: 'Mix 1 packet in liquid then take by mouth 2 times daily if needed.', ecost: '14.47', search: 'Metamucil 3.4 gram/5.4 gram powder', pages: [], variations: []},
      { id: '9',  source: 'Lois Lane', name: 'acetaminophen 325 mg tablet', dosage: '325mg', frequency: '650 mg Take 2 tablets by mouth every 4 hours. Max dose: 4000mg in 24 hrs', ecost: '6.99', search: 'acetaminophen 325 mg tablet', pages: [], variations: []},
      { id: '10',  source: 'Lois Lane', name: 'aspirin 81 mg enteric coated tablet ', dosage: '81mg', frequency: 'Take 1 tablet by mouth once daily with a meal', ecost: '7.16', search: 'aspirin 81 mg enteric coated tablet ', pages: [], variations: []},
      { id: '11',  source: 'Lois Lane', name: 'cholecalciferol 1,000 unit capsule', dosage: '1000 unit', frequency: 'Take 1 capsule by mouth daily.', ecost: '11.87', search: 'cholecalciferol 1,000 unit capsule', pages: [], variations: []},
      { id: '12',  source: 'Lois Lane', name: 'furosemide 20 mg tablet', dosage: '20mg', frequency: 'Take 1 tablet by mouth every morning', ecost: '7.61', search: 'furosemide 20 mg tablet', pages: [], variations: []},
    ],     
    Diagnosis: [
      { id: '1',  source: 'Lois Lane',  name: 'I10', txt: "HTN (hypertension) ", search: 'I10', pages: [], variations: ["HTN (hypertension)", "hypertension"]},
      { id: '2',  source: 'Lois Lane',  name: 'Q23.1', txt: "Aortic valve insufficiency ", search: 'Q23.1', pages: [], variations: ["Aortic valve insufficiency "]},
      { id: '3',  source: 'Lois Lane',  name: 'Z95.0', txt: "Pacemaker for SSS ", search: 'Z95.0', pages: [], variations: ["Pacemaker for SSS "]},
      { id: '4',  source: 'Lois Lane',  name: 'D64.9', txt: "Normocytic anemia ", search: 'D64.9', pages: [], variations: ["Normocytic anemia "]},
      { id: '5',  source: 'Lois Lane',  name: 'M19.91', txt: "Primary osteoarthritis involving multiple joints ", search: 'M19.91', pages: [], variations: ["Primary osteoarthritis involving multiple joints "]},
      { id: '6',  source: 'Lois Lane',  name: 'M99.67', txt: "Lumbar foraminal stenosis - L4-5 ", search: 'M99.67', pages: [], variations: ["Lumbar foraminal stenosis - L4-5 "]},
      { id: '7',  source: 'Lois Lane',  name: 'G31.84', txt: "MCI (mild cognitive impairment) ", search: 'G31.84', pages: [], variations: ["MCI (mild cognitive impairment) "]},
      { id: '8',  source: 'Lois Lane',  name: 'M54.5', txt: "Chronic bilateral low back pain with bilateral sciatica ", search: 'M54.5', pages: [], variations: ["Chronic bilateral low back pain with bilateral sciatica "]},
      { id: '9',  source: 'Lois Lane',  name: 'K22.5', txt: "Zenker's diverticulum ", search: 'K22.5', pages: [], variations: ["Zenker's diverticulum "]},
      { id: '10',  source: 'Lois Lane',  name: 'J96.01', txt: "Acute hypoxemic respiratory failure (HC) ", search: 'J96.01', pages: [], variations: ["Acute hypoxemic respiratory failure (HC) "]},
      { id: '11',  source: 'Lois Lane',  name: 'D69.6', txt: "Thrombocytopenia (HC) ", search: 'D69.6', pages: [], variations: ["Thrombocytopenia (HC) "]},
      { id: '12',  source: 'Lois Lane',  name: 'E87.6', txt: "Hypokalemia ", search: 'E87.6', pages: [], variations: ["Hypokalemia "]},
    ], 
    Competency: [
      { id: '1',  source: 'Lois Lane',  skill: 'Physical therapy, Occupational therapy, Speech language therapy', category: 'Rehabilitative Services', rationale: "The patient's discharge plan includes multiple therapy services, emphasizing the need for comprehensive rehabilitative care in the nursing home setting.", search: "Discharge Plan: She was discharged today to nursing home with PT/OT/SLP.", pages: [], variations: ["Treatment - Occupational Therapy Eval and Treat" , "Treatment - Physical Therapy Eval and Treat" , "Treatment Speech Therapy Eval and Treat"]},
      { id: '2',  source: 'Lois Lane',  skill: 'Pain Management', category: 'Musculoskeletal Care', rationale: "The patient's chronic bilateral pain symptoms indicate a requirement for specialized pain management interventions to alleviate discomfort.", search: "Chronic bilateral low back pain with bilateral sciatica", pages: [], variations: []},
      { id: '3',  source: 'Lois Lane',  skill: 'Medication Management for Infectious Disease', category: 'Infectious Disease Management', rationale: "The introduction of specific antibiotics due to a potential infectious condition signifies the necessity of proper medication management to treat infectious diseases.", search: "Patient started on ceftriaxone, doxycycline, and metronidazole due to concern for aspiration pneumonia.", pages: [], variations: ["Right lower lobe pneumonia" , "RLL pneumonia" , "may represent atelectasis or pneumonia" , "Pneumonia of right lower lobe" , "infectious"]},
      { id: '4',  source: 'Lois Lane',  skill: 'Management of Dysphagia Diet', category: 'Nutritional Care', rationale: "SLP's evaluation and recommendation for a dysphagia diet points to the patient's swallowing difficulties and the importance of specialized dietary management.", search: "Evaluated by SLP and placed on dysphagia diet", pages: [], variations: ["Diet Dysphagia" , "Level 4 Puree: Level 0 Thin Liquids" ]},
      { id: '5',  source: 'Lois Lane',  skill: 'Oxygen Support', category: 'Respiratory Care', rationale: "The patient's need for continued oxygen support post-discharge indicates underlying respiratory challenges and the importance of appropriate respiratory care.", search: "Patient was discharged back to nursing home with oxygen support (1-2 L NC)", pages: [], variations: ["Oxygen - Continuous wean as able Per nasal cannula." , "keep oxygen saturation greater than or equal to 90%" , "Current oxygen flow rates: 02 (LPM): 1-2"]},
    ], 
    DME: [      
      { id: '1',  source: 'Lois Lane',  equipment: 'Physical Therapy Equipment', category: 'Therapy and Rehabilitation', search: 'She was discharged today to nursing home with PT/OT/SLP.', pages: [], variations: []},
      { id: '2',  source: 'Lois Lane',  equipment: 'Oxygen Concentrator', category: 'Respiratory', search: 'Patient was discharged back to nursing home with oxygen support (1-2 L NC)', pages: [], variations: ["To keep oxygen saturation greater than or equal to 90%", " Current oxygen flow rates: 02 (LPM): 1-2 (02/25/23 0756)", "oxygen support"]},
      { id: '3',  source: 'Lois Lane',  equipment: 'Nasal Cannula', category: 'Respiratory', search: 'Oxygen - Continuous wean as able Per nasal cannula.', pages: [], variations: ["To keep oxygen saturation greater than or equal to 90%", " Current oxygen flow rates: 02 (LPM): 1-2 (02/25/23 0756)", "oxygen support"]},
      { id: '4',  source: 'Lois Lane',  equipment: 'Occupational Therapy Equipment', category: 'Therapy and Rehabilitation', search: 'Treatment - Occupational Therapy Eval and Treat', pages: [], variations: []},
      { id: '5',  source: 'Lois Lane',  equipment: 'Physical Therapy Equipment', category: 'Therapy and Rehabilitation', search: 'Treatment - Physical Therapy Eval and Treat', pages: [], variations: []},
      { id: '6',  source: 'Lois Lane',  equipment: 'Speech Therapy Equipment', category: 'Therapy and Rehabilitation', search: 'Treatment Speech Therapy Eval and Treat', pages: [], variations: []},
      { id: '7',  source: 'Lois Lane',  equipment: 'Blender for Pureed Diet', category: 'Nutrition', search: 'Diet Dysphagia Level 4 Puree: Level 0 Thin Liquids', pages: [], variations: ["Diet Dysphagia" , "dysphagia diet"]},
    ]
  },


  "Donald Duck":  {
    PatientName: 'Donald Duck',
    Summary: [], 
    Demographics: [
      { id: '1',  source: 'Donald Duck',  name: 'First Name', value: 'Donald', dataModel: 'Patient', search: 'Donald', pages: [], variations: []}, 
      { id: '2',  source: 'Donald Duck',  name: 'Last Name', value: 'Duck', dataModel: 'Patient', search: 'Duck', pages: [], variations: []},
      { id: '3',  source: 'Donald Duck',  name: 'Sex', value: 'Male', dataModel: 'Patient', search: 'Male', pages: [], variations: []},
      { id: '4',  source: 'Donald Duck',  name: 'Social Security', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '5',  source: 'Donald Duck',  name: 'Date of Birth ', value: '9863', dataModel: 'Patient', search: '9863', pages: [], variations: []},
      { id: '6',  source: 'Donald Duck',  name: 'Patient Address', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '7',  source: 'Donald Duck',  name: 'Patient Phone', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '8',  source: 'Donald Duck',  name: 'Language Preferred ', value: '', dataModel: 'Patient', search: '', pages: [], variations: []},
      { id: '9',  source: 'Donald Duck',  name: 'Admission Date', value: '44468', dataModel: 'Provider', search: '44468', pages: [], variations: []},
      { id: '10',  source: 'Donald Duck',  name: 'Hospital Name', value: 'Abbott Northwestern Hospital', dataModel: 'Provider', search: 'Abbott Northwestern Hospital', pages: [], variations: []},
      { id: '11',  source: 'Donald Duck',  name: 'MRN#', value: '4444444', dataModel: 'Provider', search: '4444444', pages: [], variations: []},
      { id: '12',  source: 'Donald Duck',  name: 'Attending Physician(s)', value: 'Ran, Kim, MD', dataModel: 'Provider', search: 'Ran, Kim, MD', pages: [], variations: []},
    ], 
    Medications: [
      { id: '1',  source: 'Donald Duck', name: 'amiodarone ', dosage: '200mg', frequency: 'Take 1 by mouth daily', ecost: '18.86', search: 'amiodarone ', pages: [], variations: []},
      { id: '2',  source: 'Donald Duck', name: 'Finasteride', dosage: '5mg', frequency: 'Take 1 by mouth daily', ecost: '16.04', search: 'Finasteride', pages: [], variations: []},
      { id: '3',  source: 'Donald Duck', name: 'Melatonin', dosage: '3mg', frequency: 'Take 1 at bedtime', ecost: '7.65', search: 'Melatonin', pages: [], variations: []},
      { id: '4',  source: 'Donald Duck', name: 'Tamsulosin', dosage: '0.4mg', frequency: 'STOP', ecost: '37.43', search: 'Tamsulosin', pages: [], variations: []},
      { id: '5',  source: 'Donald Duck', name: 'Atorvastatin', dosage: '20mg', frequency: 'STOP', ecost: '30.00', search: 'Atorvastatin', pages: [], variations: []},
      { id: '6',  source: 'Donald Duck', name: 'Nystatin', dosage: '', frequency: 'twice daily', ecost: '10.65', search: 'Nystatin', pages: [], variations: []},
      { id: '7',  source: 'Donald Duck', name: 'Polyethylene Glycol', dosage: '17g', frequency: 'once daily', ecost: '16.18', search: 'Polyethylene Glycol', pages: [], variations: []},
      { id: '8',  source: 'Donald Duck', name: 'Rivaroxaban', dosage: '10mg', frequency: 'Take 1 Tablet by mouth daily', ecost: '581.00', search: 'Rivaroxaban', pages: [], variations: []},
      { id: '9',  source: 'Donald Duck', name: 'Senokot', dosage: '8.6-50mg', frequency: 'Take 1 tablet twice daily', ecost: '7.21', search: 'Senokot', pages: [], variations: []}
    ],
    Diagnosis: [
      { id: '1',  source: 'Donald Duck',  name: 'I10', txt: "Unspecified essential hypertension ", search: 'I10', pages: [], variations: ["Unspecified essential hypertension "]},
      { id: '2',  source: 'Donald Duck',  name: 'E78.49', txt: "Other and unspecified hyperlipidemia ", search: 'E78.49', pages: [], variations: ["Other and unspecified hyperlipidemia "]},
      { id: '3',  source: 'Donald Duck',  name: 'I47.1', txt: "Paroxysmal supraventricular tachycardia (HC) ", search: 'I47.1', pages: [], variations: ["Paroxysmal supraventricular tachycardia (HC) "]},
      { id: '4',  source: 'Donald Duck',  name: 'R53.1', txt: "Weakness", search: 'R53.1', pages: [], variations: ["Weakness"]},
      { id: '5',  source: 'Donald Duck',  name: 'N40', txt: "BPH (benign prostatic hyperplasia) ", search: 'N40', pages: [], variations: ["BPH (benign prostatic hyperplasia) "]},
      { id: '6',  source: 'Donald Duck',  name: 'F02.80', txt: "Late onset Alzheimer's disease without behavioral disturbance (HC) ", search: 'F02.80', pages: [], variations: ["Late onset Alzheimer's disease without behavioral disturbance (HC) "]},
      { id: '7',  source: 'Donald Duck',  name: 'K59.00', txt: "constipation", search: 'K59.00', pages: [], variations: ["constipation"]},
      { id: '8',  source: 'Donald Duck',  name: 'B37.2', txt: "Candidal skin infection", search: 'B37.2', pages: [], variations: ["Candidal skin infection"]},
    ], 
    Competency: [
      { id: '1',  source: 'Donald Duck',  skill: 'Management of advanced Dementia', category: 'Geriatric Care', rationale: "Given the patient's age and history of advanced dementia, there is a specialized need to manage the complications and unique challenges associated with dementia in elderly patients.", search: "Mr. Donald Duck is a 94 y.o. male with a history of advanced dementia", pages: [], variations: ["patient nonverbal at baseline and with advanced dementia"]},
      { id: '2',  source: 'Donald Duck',  skill: 'COVID-19 Management', category: 'Infectious Disease Management', rationale: "The presence of a confirmed COVID-19 infection, especially in a patient with communication limitations due to advanced dementia, demands specialized care and management to ensure safety and recovery.", search: "COVID-19 resulted positive, difficult to assess symptoms given patient nonverbal at baseline and with advanced dementia.", pages: [], variations: ["diagnoses: COVID-19" , "COVID-19: ++ POSITIVE" , "You were in the hospital for weakness likely related to COVID-19."]},
      { id: '3',  source: 'Donald Duck',  skill: 'Palliative care and end of life discussions', category: 'End-of-Life Care', rationale: "The mention of potential hospice qualification and the need for palliative care discussion indicates the patient is nearing end-of-life and requires support and discussions related to end-of-life decisions.", search: "Pt would benefit from a palliative care discussion and/or consultation.", pages: [], variations: ["The patient would likely qualify for hospice." , "The family is not ready to limit cares at this time other than DNR."]},
      { id: '4',  source: 'Donald Duck',  skill: 'Occupational and Physical Therapy', category: 'Rehabilitative Services', rationale: "The recommendation for both occupational and physical therapy evaluations and treatments indicates a need for rehabilitative services to address mobility, functional, or other related challenges.", search: "Treatment Occupational Therapy Eval and Treat", pages: [], variations: [ "Treatment - Physical Therapy Eval and Treat"]},
      { id: '5',  source: 'Donald Duck',  skill: 'Patient follow-up coordination', category: 'General Nursing Care', rationale: "The direction for a follow-up with a primary care provider within a specific timeframe indicates the importance of coordinating post-hospital care and ensuring continuity of medical attention.", search: "Patient was instructed to follow up with PCP within the next 5 days.", pages: [], variations: ["Specific recomnoendations to be addressed at the follow up visit:"]},
      { id: '6',  source: 'Donald Duck',  skill: 'Medication Management and Adjustment', category: 'Medication Management', rationale: "The introduction of new medications and discontinuation of others post-hospitalization demonstrates the need for careful medication management and adjustment to ensure therapeutic effectiveness and patient safety.", search: "Patient was started on nystatin powder BID, polyethylene glycol 17 g daily, rivaroxaban 10 mg daily, and patient was discontinued from atorvastatin and tamsulosin.", pages: [], variations: ["adjust medications as needed" , "Medications should be timed for -1 lam and crushed and given in applesauce" , "Patient does not take medications right away in the morning."]},

    ], 
    DME: [
      { id: '1',  source: 'Donald Duck',  equipment: 'Wheelchair', category: 'Mobility Aid', search: 'Mr. Donald Duck is a 94 y.o. male with a history of advanced dementia, H TN, HLD, BPH, SVT who presented to the ED via EMS 9/29/21 for evaluation of weakness.', pages: [3, 21, 25], variations: []},
      { id: '2',  source: 'Donald Duck',  equipment: 'Incontinence Pads', category: 'Personal Care', search: 'EMS called and found patient in urine soaked bed.', pages: [], variations: []},
      { id: '3',  source: 'Donald Duck',  equipment: 'Pill Crusher', category: 'Medication Management', search: 'Patient does not take medications right away in the morning. Medications should be timed for -1 lam and crushed and given in applesauce.', pages: [], variations: []},
      { id: '4',  source: 'Donald Duck',  equipment: 'Wound Care Supplies (Saline, Gauze, Sacral Mepilex)', category: 'Wound Care', search: 'Wound care instructions for the skilled nursing facility: Sacral Wound 1, Cleanse with saline and guaze. 2. Cover with sacral mepilex #305692 from materials. 3. Change Tues, Thursday, Saturday and as needed if soiled.', pages: [], variations: ["Sacral Wound"]},
      { id: '5',  source: 'Donald Duck',  equipment: 'Aloe Vesta Cleanser, Lotion', category: 'Skin Care', search: 'Dry flaking skin on heels, lower extremities 1) Wash body with Aloe Vesta Cleanser (Lawson #109619) and water once a day. 2) Rinse and pat dry. 3) Apply lotion', pages: [], variations: []},
    ]
  },
}

export default data;