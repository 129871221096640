import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp')); 

const SholomRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'Sholom',
      element: <Layout />,
      auth: ['admin', 'sholom'],
      element: <ChatApp 
            org={{key: "Sholom", name: "Sholom"}}
            collection='Sholom-1'
            pageTitle="Sholom Resources"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Sholom BrightSource</span>}
          />,
      children: [
        
        {
          path: '',
          auth: ['admin', 'sholom'],
          element: <ChatApp 
            org={{key: "Sholom", name: "Sholom"}}
            collection='Sholom-1'
            pageTitle="Sholom Resources"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Sholom BrightSource</span>}
          />
        },
        {
          path: 'PoliciesAndProcedures',
          auth: ['admin', 'sholom'],
          element: <ChatApp 
            org={{key: "Sholom", name: "Sholom"}}
            collection='Sholom-1'
            level1="Policies and Procedures"
            pageTitle="Sholom Policies and Procedures"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Policies and Procedures</span>}            
          />,
          children: [
            {
              path: '',
              auth: ['admin', 'sholom'],
              element: <ChatApp 
                org={{key: "Sholom", name: "Sholom"}}
                collection='Sholom-1'
                level1="Policies and Procedures"
                pageTitle="Sholom Policies and Procedures"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Policies and Procedures</span>}
              />,
            },
            
           
          ]
        },
        {
          path: 'Regulations',
          auth: ['admin', 'sholom'],
          element: <ChatApp 
            org={{key: "Sholom", name: "Sholom"}}
            collection='Sholom-1'
            level1="Regulations"
            pageTitle="Sholom Regulations"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Regulations</span>}
          />,
          children: [
            {
              path: '',
              auth: ['admin', 'sholom'],
              element: <ChatApp 
                org={{key: "Sholom", name: "Sholom"}}
                collection='Sholom-1'
                level1="Regulations"
                pageTitle="Sholom Regulations"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Regulations</span>}
              />,
            },
            {
              path: 'Federal',
              auth: ['admin', 'sholom'],
              element: <ChatApp 
                org={{key: "Sholom", name: "Sholom"}}
                collection='Sholom-1'
                level1="Regulations"
                level2="Federal"
                pageTitle="Federal Regulations"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Federal Regulations</span>}
              />,
            },
            {
              path: 'State',
              auth: ['admin', 'sholom'],
              element: <ChatApp 
              org={{key: "Sholom", name: "Sholom"}}
              collection='Sholom-1'
              level1="Regulations"
              level2="State"
              pageTitle="State Regulations"
              pageDescription="BrightSource AI Assistant"
              chatHeader={<span>State Regulations</span>}
              />,
              children: [
                {
                  path: '',
                  auth: ['admin', 'sholom'],
                  element: <ChatApp 
                    namespace="sholom" 
                    pageTitle="State Regulations"
                    pageDescription="AI Assistant"
                    chatHeader={<span>State Regulations</span>}
                  />
                },
                {
                  path: 'MN',
                  auth: ['admin', 'sholom'],
                  element: <ChatApp 
                    namespace="sholom" 
                    pageTitle="Minnesota State Regulations"
                    pageDescription="AI Assistant"
                    chatHeader={<span>Minnesota State Regulations</span>}
                  />
                },
              ]
            }

            
          ]
          
        },
      ],
    },
  ],
};

export default SholomRoutes;