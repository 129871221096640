import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp')); 

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'Demo',
      element: <Layout />,
      auth: ['admin', 'demo2'],      
      children: [
        {
          path: '',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            pageTitle='Regulation and Care Facility AI'
            pageDescription="Free Regulatory AI and facility demonstration AI for Care Providers"
            chatHeader={<span>Complementary Regulations and Organization Demo</span>}
          />,
        },
      ]
    },
    {
      path: 'Regulations',
      element: <Layout />,
      auth: ['admin', 'demo2'],      
      children: [
        {
          path: '',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Regulations'
            pageTitle='Regulations'
            pageDescription="Free Regulatory AI provided by DOVAXIS to elevant Long Term Care"
            chatHeader={<span>Complementary Regulations and Organization Demo</span>}
          />,
        },
        {
          path: 'SNF',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
          org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            keywords="snf"
            level1='Regulations'
            pageTitle="Skilled Nursing Regulations"
            pageDescription="Free SNF AI for Care Specialists"
            chatHeader={<span>Skilled Nursing</span>}
          />,
        },
        {
          path: 'AL',
          auth: ['admin', 'demo2'],
            element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Regulations'
            keywords="al"
            pageTitle="Assisted Living Regulations"
            pageDescription="Free AL AI for Care Specialists"
            chatHeader={<span>Assisted Living</span>}
          />,
        }
      ],
    },
    {
      path: 'BrightSide',
      element: <Layout />,
      auth: ['admin', 'demo2'],      
      children: [
        {
          path: '',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Facility Documents'
            pageTitle='Facility Documents - BrightSide Care Center'
            pageDescription='Sample AI at fictitious care facility'
            chatHeader={<span>This could be your facility's document experience.</span>}
          />,
        },
        {
          path: 'Clinical',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Facility Documents'
            level2='Clinical Skills & Competencies'
            pageTitle="Clinical Skills & Competencies - BrightSide Care Center"
            pageDescription="BrightSource example at fictitious care facility"
            chatHeader={<span>Instant access to your resources through conversations.</span>}
          />,
        },
        {
          path: 'Culinary',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Facility Documents'
            level2='Culinary Services'
            pageTitle="Culinary Services - BrightSide Care Center"
            pageDescription="BrightSource example at fictitious care facility"
            chatHeader={<span>Instant access to your resources through conversations.</span>}
          />,
        },

        {
          path: 'Emergency',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Facility Documents'
            level2='Emergency Preparation'
            pageTitle="Emergency Preparation - BrightSide Care Center"
            pageDescription="BrightSource example at fictitious care facility"
            chatHeader={<span>Empower your staff with AI.</span>}
          />,
        },

        {
          path: 'HR',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Facility Documents'
            level2='HR & Employment'
            pageTitle="HR & Employment - BrightSide Care Center"
            pageDescription="BrightSource example at fictitious care facility."
            chatHeader={<span>Get accurate up-to-date information as easily as chatting with a colleague.</span>}
          />,
        },

        {
          path: 'Tools',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Facility Documents'
            level2='Infection Control Toolkit'
            pageTitle="Infection Control Toolkit - BrightSide Care Center"
            pageDescription="BrightSource example at fictitious care facility"
            chatHeader={<span>BrightSource informs and reports on change, across the organization, the industry, and beyond.</span>}
          />,
        },
        
        {
          path: 'ProductDocs',
          auth: ['admin', 'demo2'],
          element: <ChatApp 
            org={{key: "demo", name: "BrightSource Demo"}}
            collection='demo-1'
            level1='Facility Documents'
            level2='Manufacturer’s Instructions'
            pageTitle="Manufacturer’s Instructions - BrightSide Care Center"
            pageDescription="BrightSource example at fictitious care facility"
            chatHeader={<span>BrightSource informs and reports on change, across the organization, the industry, and beyond.</span>}
          />,
        },

      ],
    },
  ],
};

export default Routes;