import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp')); 

const AhiRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'AHI',
      element: <Layout />,
      auth: ['admin', 'ahi'],
      children: [
        {
          path: '',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1"
            pageTitle="AHI BrightSources"
            pageDescription="Your AHI AI Assistant"
          />,
        },
        {
          path: 'Regulations',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1"
            level1='Regulations/AL'
            pageTitle="AHI AL Regulations"
            pageDescription="BrightSource AI Assistant"
          />,
          children: [
            {
              path: '',
              auth: ['admin', 'ahi'],
              element: <ChatApp 
                org={{key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-1"
                level1='AL Regulations'
                pageTitle="Regulations"
                pageDescription="AHI BrightSource AI Assistant"
              />,
            },
            {
              path: 'AL',
              auth: ['admin', 'ahi'],
              element: <ChatApp 
                org={{key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-1"
                level1='AL Regulations'
                pageTitle="AL Regulations"
                pageDescription="AHI BrightSource AI Assistant"
              />,
              children: [
                {
                  path: '',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='AL Regulations'                    
                    pageTitle="AL Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'AZ',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='AL Regulations'
                    level2='AZ'
                    pageTitle="Arizona AL Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'CO',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='AL Regulations'
                    level2='CO'
                    pageTitle="Colorado AL Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'MN',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='AL Regulations'
                    level2='MN'
                    pageTitle="Minnesota AL Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'TN',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='AL Regulations'
                    level2='TN'
                    pageTitle="Tennessee AL Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'WI',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='AL Regulations'
                    level2='WI'
                    pageTitle="Wisconsin AL Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
              ]
            },
            {
              path: 'SNF',
              auth: ['admin', 'ahi'],
              element: <ChatApp 
                org={{key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-1"
                level1='SNF Regulations'
                pageTitle="SNF Regulations"
                pageDescription="AHI BrightSource AI Assistant"
              />,
              children: [
                {
                  path: '',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='SNF Regulations'                    
                    pageTitle="SNF Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'FED',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='SNF Regulations'
                    level2='Federal'               
                    pageTitle="Federal/CMS SNF Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'CO',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='SNF Regulations'
                    level2='CO'               
                    pageTitle="Colorado SNF Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'MN',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='SNF Regulations'
                    level2='MN'               
                    pageTitle="Minnesota SNF Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'TN',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='SNF Regulations'
                    level2='TN'               
                    pageTitle="Tennessee SNF Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                },
                {
                  path: 'WI',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1"
                    level1='SNF Regulations'
                    level2='WI'               
                    pageTitle="Wisconsin SNF Regulations"
                    pageDescription="AHI BrightSource AI Assistant"
                  />
                }
              ]
            }
          ]
        },
        {
          path: 'Policies',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1"
            level1='Policies and Procedures'
            pageTitle="Policies and Procedures"
            pageDescription="AHI BrightSource AI Assistant"
          />
        },
        {
          path: 'Employment',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1"
            level1='Employment Documents'
            pageTitle="Employment Documents"
            pageDescription="AHI BrightSource AI Assistant"
          />
        },
        {
          path: 'Instructions',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1"
            level1='Instructions & Guides'
            pageTitle="Instructions & Guides"
            pageDescription="AHI BrightSource AI Assistant"
          />
        },
        {
          path: 'MDS',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1"
            level1='MDS'
            pageTitle="MDS Resources"
            pageDescription="AHI BrightSource AI Assistant"
          />
        },
        {
          path: 'Tools',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1"
            level1='Tools & Templates'
            pageTitle="Tools & Templates"
            pageDescription="AHI BrightSource AI Assistant"
          />
        }
      ]
    }
  ]
}; 

export default AhiRoutes;

