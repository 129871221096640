import React, { useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { IconButton } from '@mui/material';


function Recommendation(props){ 
  const { question, applyHandler } = props;

  function handle(){
    applyHandler(question);
  }
  return (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 12, paddingBottom: '4px' }} color="text.secondary" gutterBottom>
          Suggestion:
        </Typography>
        <Typography variant="body2">
          {question}          
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" style={{color: '#297d8b'}} onClick={handle}>Try this question {">"}</Button>
      </CardActions>
    </React.Fragment>
  )
};


const container = {
  show: {
    transition: {
      staggerChildren: 0.06,
    },
  }
}

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

function Recommendations(props){
  const { chatWidth, show, setShow, questions, applyHandler} = props;

  let containerStyle = {...chatWidth};
  containerStyle.backgroundColor = '#297d8b'; // light blue: #d5eef4, Dove blue: #297d8b
  containerStyle.position = 'relative';
  containerStyle.bottom = '10px';  
  containerStyle.borderTopLeftRadius = 10;
  containerStyle.borderBottomLeftRadius = 10;
  containerStyle.borderTopRightRadius = 10;
  containerStyle.borderBottomRightRadius = 10;

  if(!show) return (<></>);

 
  return (
    <div className="flex flex-col pt-16 px-16 pb-16" style={containerStyle}>
      <div className="flex px-4" style={{position:'relative'}}>
        <IconButton  color="text.secondary"  style={{position:'absolute', top: 0, right: 0, color: '#d5eef4'}} onClick={() => setShow(false)}>
          <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
        </IconButton>
      </div>

      <Typography sx={{ fontSize: 14, paddingLeft: '14px' }} color="#ddeff4" >
        It looks like your question returned a response that wasn't helpful.          
      </Typography>
      <Typography sx={{ fontSize: 16, paddingBottom: '10px', paddingLeft: '12px', fontWeight: 500 }} color="text.primary" >        
        Consider rephrasing your question like one of the following:        
      </Typography>

      <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 w-full p-6 md:p-6"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {
            questions.map((q, idx) => 
              <motion.div key={`div-${idx}`} variants={item} className="sm:col-span-2 lg:col-span-1 ">
                <Card key={`card-${idx}`} variant="outlined" style={{height: '100%'}}><Recommendation question={q} applyHandler={applyHandler} /></Card>
              </motion.div>
            ) 
          }
        
      </motion.div>
    </div>   
  )
}

export default Recommendations;