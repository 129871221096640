import { useContext } from 'react';
import { createContext} from 'react';

export const AuthContext = createContext({});

export default function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}