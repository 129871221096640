import { TOOL_TYPES, CATEGORIES } from '../../configs/toolsConfig';

const bmiConfig = {
  id: 'bmi-calculator',
  name: 'BMI Calculator',
  description: 'Calculate BMI and BSA',
  instructions: 'Enter weight and height to calculate BMI (Body Mass Index) and BSA (Body Surface Area).',
  type: TOOL_TYPES.CALCULATOR,
  category: CATEGORIES.MEDICAL,
  parameters: {
    weight: {
      type: 'number',
      description: 'Weight value'
    },
    weightUnit: {
      type: 'string',
      enum: ['kg', 'lbs'],
      description: 'Weight unit'
    },
    height: {
      type: 'number',
      description: 'Height value'
    },
    heightUnit: {
      type: 'string',
      enum: ['cm', 'in'],
      description: 'Height unit'
    }
  },
};

export default bmiConfig;