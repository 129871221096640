const Regulations = {
    "name": "Regulations",
    "type": "dir",
    "id": "16c319ff-68fb-4bcf-b4b5-644e8e2bd7d8",
    "path": "Regulations",
    open: true,
    "items": [
      {
        "name": "Federal",
        "type": "dir",
        open: true,
        "id": "7d218230-2a95-4e27-9d6d-e96df89867a4",
        "path": "Regulations/Federal",
        "items": [
          {
            "name": "State Operations Manual - Appendix PP",
            "type": "dir",
            "id": "0cf6b157-db9f-4442-8596-a328e7af3bb3",
            "path": "Regulations/Federal/State Operations Manual - Appendix PP",
            "items": [
              {
                "name": "483.10 Resident Rights",
                "type": "dir",
                "id": "8251789c-7129-44dc-b8e1-80203a34fd8c",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.10 Resident Rights",
                "items": [
                  {
                    "name": "F550 Resident RightsExercise of Rights",
                    "type": "policy",
                    "id": "dfc5c9e4-4e74-4809-9f1e-623e6ca4d29b",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fdfc5c9e4-4e74-4809-9f1e-623e6ca4d29b.docx?alt=media&token=b8d5110e-d5c1-44de-91c5-50a86485a5d3",
                    "fileExt": ".docx",
                    "fileName": "F550 Resident RightsExercise of Rights.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F551 Rights Exercised by Representative",
                    "type": "policy",
                    "id": "4ad1fe50-eecc-4aad-843e-c1dfaf2fa0de",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4ad1fe50-eecc-4aad-843e-c1dfaf2fa0de.docx?alt=media&token=71d310dd-5480-4372-b565-14d2e531da43",
                    "fileExt": ".docx",
                    "fileName": "F551 Rights Exercised by Representative.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F552 Right to be InformedMake Treatment Decisions",
                    "type": "policy",
                    "id": "a5c19172-542b-4c11-a901-cb9889e3304a",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fa5c19172-542b-4c11-a901-cb9889e3304a.docx?alt=media&token=f97f41b7-1647-42a5-b662-fa84d89b163e",
                    "fileExt": ".docx",
                    "fileName": "F552 Right to be InformedMake Treatment Decisions.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F553 Right to Participate in Planning Care",
                    "type": "policy",
                    "id": "4785ba83-8d70-41eb-81a3-1aa123617532",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4785ba83-8d70-41eb-81a3-1aa123617532.docx?alt=media&token=87c7b6e9-4eaf-4cc7-b8cc-5db3b8104abb",
                    "fileExt": ".docx",
                    "fileName": "F553 Right to Participate in Planning Care.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F554 Resident Self‐Admin Meds‐Clinically Appropriate",
                    "type": "policy",
                    "id": "9384f028-0f8b-4b08-aeca-4a35305282dd",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9384f028-0f8b-4b08-aeca-4a35305282dd.docx?alt=media&token=536bca40-2b41-45be-a0ce-f65772b9a451",
                    "fileExt": ".docx",
                    "fileName": "F554 Resident Self‐Admin Meds‐Clinically Appropriate.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F555 Right to ChooseBe Informed of Attending Physician",
                    "type": "policy",
                    "id": "698c4fec-ab51-4ae3-b1b9-1d18acf29ed5",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F698c4fec-ab51-4ae3-b1b9-1d18acf29ed5.docx?alt=media&token=ac66ff05-f457-414a-8c3b-0bc93456e408",
                    "fileExt": ".docx",
                    "fileName": "F555 Right to ChooseBe Informed of Attending Physician.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F557 Respect, DignityRight to have Personal Property",
                    "type": "policy",
                    "id": "9c4d079a-e42c-4cd9-a828-3eb388f1b2ee",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9c4d079a-e42c-4cd9-a828-3eb388f1b2ee.docx?alt=media&token=150396ab-dc2b-4192-bbd0-a05a44d932a4",
                    "fileExt": ".docx",
                    "fileName": "F557 Respect, DignityRight to have Personal Property.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F558 Reasonable Accommodations of NeedsPreferences",
                    "type": "policy",
                    "id": "71c64605-21df-4037-9984-99011b5fe582",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F71c64605-21df-4037-9984-99011b5fe582.docx?alt=media&token=f4371d7c-5323-4c0c-84a9-0ec54f25ba59",
                    "fileExt": ".docx",
                    "fileName": "F558 Reasonable Accommodations of NeedsPreferences.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F559 ChooseBe Notified of RoomRoommate Change",
                    "type": "policy",
                    "id": "de3c3f65-8973-43ae-a923-03b153ebc7c1",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fde3c3f65-8973-43ae-a923-03b153ebc7c1.docx?alt=media&token=012fad29-fd3a-43cc-a015-f854056e9931",
                    "fileExt": ".docx",
                    "fileName": "F559 ChooseBe Notified of RoomRoommate Change.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F560 Right to Refuse Certain Transfers",
                    "type": "policy",
                    "id": "93ea531e-9c88-429f-a483-9d4c617331be",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F93ea531e-9c88-429f-a483-9d4c617331be.docx?alt=media&token=a564ff66-269e-43cc-acf9-1a9190772d53",
                    "fileExt": ".docx",
                    "fileName": "F560 Right to Refuse Certain Transfers.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F561 Self Determination",
                    "type": "policy",
                    "id": "5c877b86-b993-4778-8c1b-901215615499",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5c877b86-b993-4778-8c1b-901215615499.docx?alt=media&token=0a39c1e6-0ab9-4a76-ae30-9f23c230f30b",
                    "fileExt": ".docx",
                    "fileName": "F561 Self Determination.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F562 Immediate Access to Resident",
                    "type": "policy",
                    "id": "3a2fcac9-f6b9-423e-8f42-791c407044f1",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F3a2fcac9-f6b9-423e-8f42-791c407044f1.docx?alt=media&token=d998ca13-fcdf-441d-b994-7471cb806bb8",
                    "fileExt": ".docx",
                    "fileName": "F562 Immediate Access to Resident.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F563 Right to ReceiveDeny Visitors",
                    "type": "policy",
                    "id": "e36b4a91-38ca-47f7-b975-dd8e403813d3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe36b4a91-38ca-47f7-b975-dd8e403813d3.docx?alt=media&token=360c240c-7ea4-42de-8053-1d5310f84842",
                    "fileExt": ".docx",
                    "fileName": "F563 Right to ReceiveDeny Visitors.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F564 Inform of Visitation RightsEqual Visitation Privileges",
                    "type": "policy",
                    "id": "a869a114-b7b5-4576-81b8-f4646973ad4e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fa869a114-b7b5-4576-81b8-f4646973ad4e.docx?alt=media&token=4ac98593-06c0-40e0-a858-e316fe311f0a",
                    "fileExt": ".docx",
                    "fileName": "F564 Inform of Visitation RightsEqual Visitation Privileges.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F565 ResidentFamily Group and Response",
                    "type": "policy",
                    "id": "47a65d2e-deca-4a6c-9f8e-f6e0499e3282",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F47a65d2e-deca-4a6c-9f8e-f6e0499e3282.docx?alt=media&token=ad1ebda6-0d63-46af-a625-61f581c8578f",
                    "fileExt": ".docx",
                    "fileName": "F565 ResidentFamily Group and Response.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F566 Right to Perform Facility Services or Refuse",
                    "type": "policy",
                    "id": "1510e9d6-7d34-40c4-90bd-82f40cb6e20d",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1510e9d6-7d34-40c4-90bd-82f40cb6e20d.docx?alt=media&token=c2bceac1-a938-4080-a63a-4973ab0ed59e",
                    "fileExt": ".docx",
                    "fileName": "F566 Right to Perform Facility Services or Refuse.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F567 ProtectionManagement of Personal Funds",
                    "type": "policy",
                    "id": "c695bc5d-c7f9-4290-9abb-42693497fe0f",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc695bc5d-c7f9-4290-9abb-42693497fe0f.docx?alt=media&token=17d325f4-ebf3-471b-9ac9-21056e66bd2d",
                    "fileExt": ".docx",
                    "fileName": "F567 ProtectionManagement of Personal Funds.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F568 Accounting and Records of Personal Funds",
                    "type": "policy",
                    "id": "2ac27150-60c0-435d-a784-a56277387e50",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F2ac27150-60c0-435d-a784-a56277387e50.docx?alt=media&token=827c62a4-e06c-4383-ac0c-dac9e303de29",
                    "fileExt": ".docx",
                    "fileName": "F568 Accounting and Records of Personal Funds.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F569 Notice and Conveyance of Personal Funds",
                    "type": "policy",
                    "id": "ede40d3d-c484-43b3-a0af-b4c0bddaf3d3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fede40d3d-c484-43b3-a0af-b4c0bddaf3d3.docx?alt=media&token=80864bc8-2aa4-42db-8691-965f1d297d0b",
                    "fileExt": ".docx",
                    "fileName": "F569 Notice and Conveyance of Personal Funds.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F570 Surety Bond ‐ Security of Personal Funds",
                    "type": "policy",
                    "id": "8b1a6e7d-67ea-4c82-ad58-5cd94cd03a8b",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F8b1a6e7d-67ea-4c82-ad58-5cd94cd03a8b.docx?alt=media&token=214baec2-9fc6-4ad5-9421-666c47f883f1",
                    "fileExt": ".docx",
                    "fileName": "F570 Surety Bond ‐ Security of Personal Funds.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F571 Limitations on Charges to Personal Funds",
                    "type": "policy",
                    "id": "4197740c-d688-47fc-8e26-1bbbebc38fb8",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4197740c-d688-47fc-8e26-1bbbebc38fb8.docx?alt=media&token=6a378b40-509e-4bee-af34-35ed015bff50",
                    "fileExt": ".docx",
                    "fileName": "F571 Limitations on Charges to Personal Funds.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F572 Notice of Rights and Rules",
                    "type": "reg",
                    "id": "6b40175f-0eca-4b4a-955e-abc866ab5212",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6b40175f-0eca-4b4a-955e-abc866ab5212.docx?alt=media&token=ec5409f4-587b-40ba-a906-7fcb4e8bb068",
                    "fileExt": ".docx",
                    "fileName": "F572 Notice of Rights and Rules.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F573 Right to AccessPurchase Copies of Records",
                    "type": "policy",
                    "id": "b6f49deb-ad7c-4f77-8fd4-4b8195719607",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb6f49deb-ad7c-4f77-8fd4-4b8195719607.docx?alt=media&token=af69fd50-f3be-4fbe-9efa-dd27433b6c48",
                    "fileExt": ".docx",
                    "fileName": "F573 Right to AccessPurchase Copies of Records.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F574 Required Notices and Contact Information",
                    "type": "policy",
                    "id": "7cccefc0-8572-4475-b0e7-c723cec351d7",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7cccefc0-8572-4475-b0e7-c723cec351d7.docx?alt=media&token=f29be52b-4dcf-4473-b947-52e8f702ab8a",
                    "fileExt": ".docx",
                    "fileName": "F574 Required Notices and Contact Information.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F575 Required Postings",
                    "type": "policy",
                    "id": "37537796-102b-492e-b333-b94e290023b6",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F37537796-102b-492e-b333-b94e290023b6.docx?alt=media&token=c3253212-9d36-4574-b8f5-513879ff63b0",
                    "fileExt": ".docx",
                    "fileName": "F575 Required Postings.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F576 Right to Forms of Communication with Privacy",
                    "type": "form",
                    "id": "ab248246-ade3-459f-bde2-edf0ed79665e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fab248246-ade3-459f-bde2-edf0ed79665e.docx?alt=media&token=a43682e8-78eb-4d3c-aecd-3a9cf4105605",
                    "fileExt": ".docx",
                    "fileName": "F576 Right to Forms of Communication with Privacy.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F577 Right to Survey ResultsAdvocate Agency Info",
                    "type": "policy",
                    "id": "d2e0d794-3071-40cc-8e3b-1d66d47a23cb",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd2e0d794-3071-40cc-8e3b-1d66d47a23cb.docx?alt=media&token=bd0247f1-666a-475f-82cd-3eadc172e975",
                    "fileExt": ".docx",
                    "fileName": "F577 Right to Survey ResultsAdvocate Agency Info.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F578 Request Refuse Discontinue Treatment;Formulate Adv Directive",
                    "type": "policy",
                    "id": "28a6f187-208f-446c-8478-166836e24901",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F28a6f187-208f-446c-8478-166836e24901.docx?alt=media&token=30018646-3743-4c7b-a750-33b57fff6738",
                    "fileExt": ".docx",
                    "fileName": "F578 Request Refuse Discontinue Treatment;Formulate Adv Directive.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F579 PostingNotice of MedicareMedicaid on Admission",
                    "type": "policy",
                    "id": "4041c59b-0242-4664-a05d-790fe60b20ee",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4041c59b-0242-4664-a05d-790fe60b20ee.docx?alt=media&token=a4ec1a64-79c4-46bf-b0d9-851864e79d55",
                    "fileExt": ".docx",
                    "fileName": "F579 PostingNotice of MedicareMedicaid on Admission.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F580 Notify of Changes (InjuryDeclineRoom, Etc.)",
                    "type": "policy",
                    "id": "7262220e-78b6-4fe4-a2c4-91939f60d295",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7262220e-78b6-4fe4-a2c4-91939f60d295.docx?alt=media&token=38dee227-415e-4dd4-ae27-9042d6b3def4",
                    "fileExt": ".docx",
                    "fileName": "F580 Notify of Changes (InjuryDeclineRoom, Etc.).docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F582 MedicaidMedicare CoverageLiability Notice",
                    "type": "policy",
                    "id": "da050df5-ca3f-4ca8-b42a-4844fadf76cb",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fda050df5-ca3f-4ca8-b42a-4844fadf76cb.docx?alt=media&token=fe3f8633-6959-45c4-af7d-1e78cae1dd4f",
                    "fileExt": ".docx",
                    "fileName": "F582 MedicaidMedicare CoverageLiability Notice.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F583 Personal PrivacyConfidentiality of Records",
                    "type": "policy",
                    "id": "4b6dc6f2-3e91-4fcd-be14-19d889d44f92",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4b6dc6f2-3e91-4fcd-be14-19d889d44f92.docx?alt=media&token=974851d4-bdc1-46e0-a808-756740dd4302",
                    "fileExt": ".docx",
                    "fileName": "F583 Personal PrivacyConfidentiality of Records.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F584 Safe Clean Comfortable Homelike Environment",
                    "type": "policy",
                    "id": "701971db-d60e-4650-b52d-831246517066",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F701971db-d60e-4650-b52d-831246517066.docx?alt=media&token=6d7c8b86-a984-4a45-95bd-e5672f8ec883",
                    "fileExt": ".docx",
                    "fileName": "F584 Safe Clean Comfortable Homelike Environment.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F585 Grievances",
                    "type": "policy",
                    "id": "4ba11e1f-e2dc-47ae-a76b-fb21f8fa40d7",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4ba11e1f-e2dc-47ae-a76b-fb21f8fa40d7.docx?alt=media&token=c2125384-21a5-4376-80e9-d32b3428b20d",
                    "fileExt": ".docx",
                    "fileName": "F585 Grievances.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F586 Resident Contact with External Entities",
                    "type": "policy",
                    "id": "a2e7c150-bf4c-4b46-aafd-62b321bdde7a",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fa2e7c150-bf4c-4b46-aafd-62b321bdde7a.docx?alt=media&token=c77ccd7c-ecf0-42dd-8bd7-03045617b144",
                    "fileExt": ".docx",
                    "fileName": "F586 Resident Contact with External Entities.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.12 Freedom from Abuse, Neglect, and Exploitation",
                "type": "dir",
                "id": "d448b534-dcfe-4464-b1f1-2f85aaa337a7",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.12 Freedom from Abuse, Neglect, and Exploitation",
                "items": [
                  {
                    "name": "F600 Free from Abuse and Neglect",
                    "type": "policy",
                    "id": "48140f43-439c-4cc6-9648-41569d5fefe3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F48140f43-439c-4cc6-9648-41569d5fefe3.docx?alt=media&token=17c6dbb7-1885-4374-b880-f60884f671ad",
                    "fileExt": ".docx",
                    "fileName": "F600 Free from Abuse and Neglect.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F602 Free from MisappropriationExploitation",
                    "type": "policy",
                    "id": "d3a38731-9ee8-430d-9c2f-62ccb5d534f6",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd3a38731-9ee8-430d-9c2f-62ccb5d534f6.docx?alt=media&token=dc506d98-c3a2-4067-8d18-b4b82f2f267f",
                    "fileExt": ".docx",
                    "fileName": "F602 Free from MisappropriationExploitation.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F603 Free from Involuntary Seclusion",
                    "type": "policy",
                    "id": "3f7070e1-8c06-4589-a9d7-c3947e593fb3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F3f7070e1-8c06-4589-a9d7-c3947e593fb3.docx?alt=media&token=d09d6850-c473-407b-b232-a0eb78e31983",
                    "fileExt": ".docx",
                    "fileName": "F603 Free from Involuntary Seclusion.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F604 Right to be Free from Physical Restraints",
                    "type": "policy",
                    "id": "63d5b469-b394-4ff6-96ab-a4c3f5bf956c",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F63d5b469-b394-4ff6-96ab-a4c3f5bf956c.docx?alt=media&token=a4f7efcf-4ea4-4141-9da6-485a09a6e7a5",
                    "fileExt": ".docx",
                    "fileName": "F604 Right to be Free from Physical Restraints.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F605 Right to be Free from Chemical Restraints",
                    "type": "policy",
                    "id": "ed4aaccd-299d-4dcd-aec1-1bb4e360b071",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fed4aaccd-299d-4dcd-aec1-1bb4e360b071.docx?alt=media&token=b8483626-d412-4d1c-a476-f11ab2479219",
                    "fileExt": ".docx",
                    "fileName": "F605 Right to be Free from Chemical Restraints.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F606 Not EmployEngage Staff with Adverse Actions",
                    "type": "policy",
                    "id": "b0ca585e-b807-467f-a638-4c6ad159ff57",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb0ca585e-b807-467f-a638-4c6ad159ff57.docx?alt=media&token=56c5963a-514d-46bc-9ded-d31db011966e",
                    "fileExt": ".docx",
                    "fileName": "F606 Not EmployEngage Staff with Adverse Actions.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F607 DevelopImplement AbuseNeglect, etc. Policies",
                    "type": "policy",
                    "id": "cb1eaa25-b057-4061-8cc1-72b1688b8944",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fcb1eaa25-b057-4061-8cc1-72b1688b8944.docx?alt=media&token=a69047c4-f536-40b9-a18b-9212f28478c9",
                    "fileExt": ".docx",
                    "fileName": "F607 DevelopImplement AbuseNeglect, etc. Policies.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F609 Reporting of Alleged Violations",
                    "type": "policy",
                    "id": "d13e6734-9edc-4db1-99d6-84cbdbebc5a0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd13e6734-9edc-4db1-99d6-84cbdbebc5a0.docx?alt=media&token=acb886b1-eaa8-4fd5-8190-5994321b550a",
                    "fileExt": ".docx",
                    "fileName": "F609 Reporting of Alleged Violations.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F610 Investigate Prevent Correct Alleged Violation",
                    "type": "policy",
                    "id": "f5fff94b-7270-4578-b635-6416ceaace21",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff5fff94b-7270-4578-b635-6416ceaace21.docx?alt=media&token=21e3ea9f-9d4f-4f71-9d8c-612c86e83491",
                    "fileExt": ".docx",
                    "fileName": "F610 Investigate Prevent Correct Alleged Violation.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.15 Admission, Transfer, and Discharge",
                "type": "dir",
                "id": "f5e0b45d-d1a7-44a2-ba6b-e74f0a63c989",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.15 Admission, Transfer, and Discharge",
                "items": [
                  {
                    "name": "F620 Admissions Policy",
                    "type": "policy",
                    "id": "815a7454-dd50-4d49-ae81-1ea5ad6160b0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F815a7454-dd50-4d49-ae81-1ea5ad6160b0.docx?alt=media&token=0d42900c-caa2-424c-afdd-637312f52f7c",
                    "fileExt": ".docx",
                    "fileName": "F620 Admissions Policy.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F621 Equal Practices Regardless of Payment Source",
                    "type": "policy",
                    "id": "d5f2a075-c18a-4394-850f-9b78cf341f3f",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd5f2a075-c18a-4394-850f-9b78cf341f3f.docx?alt=media&token=d1e09c3e-0f45-43fe-bb96-80298d6a5553",
                    "fileExt": ".docx",
                    "fileName": "F621 Equal Practices Regardless of Payment Source.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F622 Transfer and Discharge Requirements",
                    "type": "policy",
                    "id": "5cbac006-db0a-42b8-8193-61ec43b1ec0d",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5cbac006-db0a-42b8-8193-61ec43b1ec0d.docx?alt=media&token=2de0cf06-7c05-4f92-a202-38a8ff86806c",
                    "fileExt": ".docx",
                    "fileName": "F622 Transfer and Discharge Requirements.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F623 Notice Requirements Before TransferDischarge",
                    "type": "policy",
                    "id": "f7ccdf8e-720f-4213-9a96-3c0282424b73",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff7ccdf8e-720f-4213-9a96-3c0282424b73.docx?alt=media&token=c8676a52-4b3c-4991-b0f4-ea68edb970cc",
                    "fileExt": ".docx",
                    "fileName": "F623 Notice Requirements Before TransferDischarge.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F624 Preparation for SafeOrderly TransferDischarge",
                    "type": "policy",
                    "id": "5ad8d289-8188-4cdf-9ca4-76ca17a74415",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5ad8d289-8188-4cdf-9ca4-76ca17a74415.docx?alt=media&token=39d189d5-ee73-4878-80f1-ffa768d7ad3f",
                    "fileExt": ".docx",
                    "fileName": "F624 Preparation for SafeOrderly TransferDischarge.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F625 Notice of Bed Hold Policy BeforeUpon Transfer",
                    "type": "policy",
                    "id": "f7b0ca44-606a-472e-92dd-d85613f76e44",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff7b0ca44-606a-472e-92dd-d85613f76e44.docx?alt=media&token=06bc4e31-6932-46c1-9de3-819911bf0376",
                    "fileExt": ".docx",
                    "fileName": "F625 Notice of Bed Hold Policy BeforeUpon Transfer.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F626 Permitting Residents to Return to Facility",
                    "type": "policy",
                    "id": "7fdcd12b-2c13-4056-b9db-307a0125021a",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7fdcd12b-2c13-4056-b9db-307a0125021a.docx?alt=media&token=0ad2b927-2f53-4b7c-a6cd-69218c70303f",
                    "fileExt": ".docx",
                    "fileName": "F626 Permitting Residents to Return to Facility.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.20 Resident Assessments",
                "type": "dir",
                "id": "114a27b4-62fc-4ed5-8d92-0f3d4fce69be",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.20 Resident Assessments",
                "items": [
                  {
                    "name": "F635 Admission Physician Orders for Immediate Care",
                    "type": "policy",
                    "id": "6fc3ebc6-7b76-4b55-8c71-2d1dcd66e533",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6fc3ebc6-7b76-4b55-8c71-2d1dcd66e533.docx?alt=media&token=b15f1e09-cf7d-4d13-a1ab-41925b6c59d4",
                    "fileExt": ".docx",
                    "fileName": "F635 Admission Physician Orders for Immediate Care.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F636 Comprehensive Assessments & Timing",
                    "type": "policy",
                    "id": "8c1c2475-7b0b-40ee-b6c6-b0055255be22",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F8c1c2475-7b0b-40ee-b6c6-b0055255be22.docx?alt=media&token=5534ed6f-8203-4d9f-a654-bec3f06d0dd5",
                    "fileExt": ".docx",
                    "fileName": "F636 Comprehensive Assessments & Timing.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F637 Comprehensive Assmt After Significant Change",
                    "type": "policy",
                    "id": "83738a72-a897-49a6-ab96-be3fbc3cdb39",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F83738a72-a897-49a6-ab96-be3fbc3cdb39.docx?alt=media&token=25650f87-4b4c-4d2d-a026-9ae28a4d4e09",
                    "fileExt": ".docx",
                    "fileName": "F637 Comprehensive Assmt After Significant Change.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F638 Quarterly Assessment At Least Every 3 Months",
                    "type": "policy",
                    "id": "6b4381d5-b5c2-4430-9a0e-43700289b7ea",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6b4381d5-b5c2-4430-9a0e-43700289b7ea.docx?alt=media&token=ed0b5e40-3661-4503-9e18-08cff16d8bdc",
                    "fileExt": ".docx",
                    "fileName": "F638 Quarterly Assessment At Least Every 3 Months.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F639 Maintain 15 Months of Resident A ssessments",
                    "type": "policy",
                    "id": "c9f8f625-7a0f-4182-abb9-fec03f7de8c9",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc9f8f625-7a0f-4182-abb9-fec03f7de8c9.docx?alt=media&token=9260d235-e23d-478f-9735-8eb30af51d1a",
                    "fileExt": ".docx",
                    "fileName": "F639 Maintain 15 Months of Resident A ssessments.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F640 Encoding Transmitting Resident Assessment",
                    "type": "policy",
                    "id": "27b1df11-cef1-4da5-bd16-49d55b36ec9f",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F27b1df11-cef1-4da5-bd16-49d55b36ec9f.docx?alt=media&token=0980b456-7f40-4385-981e-3febf76c7f98",
                    "fileExt": ".docx",
                    "fileName": "F640 Encoding Transmitting Resident Assessment.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F641 Accuracy of Assessments",
                    "type": "policy",
                    "id": "d9dfeece-60db-4707-abb5-7f62e9cb5a3d",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd9dfeece-60db-4707-abb5-7f62e9cb5a3d.docx?alt=media&token=a0a1a57f-b850-46b8-9d20-a6fa5c905c68",
                    "fileExt": ".docx",
                    "fileName": "F641 Accuracy of Assessments.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F642 Coordination Certification of Assessment",
                    "type": "policy",
                    "id": "21ad12fc-e5bb-49f1-be9c-43817f8a8220",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F21ad12fc-e5bb-49f1-be9c-43817f8a8220.docx?alt=media&token=f1db66b2-a6f6-4958-8b29-c1a1908e0b45",
                    "fileExt": ".docx",
                    "fileName": "F642 Coordination Certification of Assessment.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F644 Coordination of PASARR and Assessments",
                    "type": "policy",
                    "id": "1ba0b74a-88f1-4307-9348-c1b94ddc34b0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1ba0b74a-88f1-4307-9348-c1b94ddc34b0.docx?alt=media&token=387e7a21-10ad-489e-8b42-326cf7ed47a9",
                    "fileExt": ".docx",
                    "fileName": "F644 Coordination of PASARR and Assessments.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F645 PASARR Screening for MD & ID",
                    "type": "policy",
                    "id": "b91b0c27-ea59-44c2-991d-d41d4b936642",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb91b0c27-ea59-44c2-991d-d41d4b936642.docx?alt=media&token=99cde2bd-ff6c-4e62-8786-ca953b59ad3a",
                    "fileExt": ".docx",
                    "fileName": "F645 PASARR Screening for MD & ID.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F646 MDID Significant Change Notification",
                    "type": "policy",
                    "id": "d4c963be-f5aa-4d5a-b62d-e7873c662ddd",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd4c963be-f5aa-4d5a-b62d-e7873c662ddd.docx?alt=media&token=c41be165-4bb3-40d0-8498-624881e40d7d",
                    "fileExt": ".docx",
                    "fileName": "F646 MDID Significant Change Notification.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.21 Comprehensive Resident Centered Care Plan",
                "type": "dir",
                "id": "dfac0586-90b9-4263-8941-11a2d567c179",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.21 Comprehensive Resident Centered Care Plan",
                "items": [
                  {
                    "name": "F655 Baseline Care Plan",
                    "type": "policy",
                    "id": "255ea4fb-9377-481e-a2ab-816da9e4e39c",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F255ea4fb-9377-481e-a2ab-816da9e4e39c.docx?alt=media&token=8ace357d-6e63-4234-a15a-536a50d1dee1",
                    "fileExt": ".docx",
                    "fileName": "F655 Baseline Care Plan.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F656 DevelopImplement Comprehensive Care Plan",
                    "type": "policy",
                    "id": "7b2eb030-c30e-4ab4-88f5-42fbcf91979d",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7b2eb030-c30e-4ab4-88f5-42fbcf91979d.docx?alt=media&token=11f47d0e-b70f-43ed-bf3e-76fe282b49c0",
                    "fileExt": ".docx",
                    "fileName": "F656 DevelopImplement Comprehensive Care Plan.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F657 Care Plan Timing and Revision",
                    "type": "policy",
                    "id": "81883346-bb95-4503-9342-1937d157d8cb",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F81883346-bb95-4503-9342-1937d157d8cb.docx?alt=media&token=fb9b92ad-8f5b-446e-a0eb-7bba1d84ec2d",
                    "fileExt": ".docx",
                    "fileName": "F657 Care Plan Timing and Revision.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F658 Services Provided Meet Professional Standards",
                    "type": "policy",
                    "id": "f2753fdc-0e2a-4b1c-8a76-9038d8d50471",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff2753fdc-0e2a-4b1c-8a76-9038d8d50471.docx?alt=media&token=e7b7d7f1-538f-4b7a-a9e4-915a591d0c26",
                    "fileExt": ".docx",
                    "fileName": "F658 Services Provided Meet Professional Standards.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F659 Qualified Persons",
                    "type": "policy",
                    "id": "0863af1a-59e3-4213-80dc-200c4ef311d4",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0863af1a-59e3-4213-80dc-200c4ef311d4.docx?alt=media&token=533af8e8-325f-4ef3-9c0d-0fd2928e0549",
                    "fileExt": ".docx",
                    "fileName": "F659 Qualified Persons.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F660 Discharge Planning Process",
                    "type": "policy",
                    "id": "5d8bddcb-864c-4fa2-9316-acfb2eff0f0e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5d8bddcb-864c-4fa2-9316-acfb2eff0f0e.docx?alt=media&token=984118da-4c0d-4446-8b8a-b00cc345ccf7",
                    "fileExt": ".docx",
                    "fileName": "F660 Discharge Planning Process.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F661 Discharge Summary",
                    "type": "policy",
                    "id": "e5ec016b-4ef1-405a-a2fb-cf26351a4b5e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe5ec016b-4ef1-405a-a2fb-cf26351a4b5e.docx?alt=media&token=7d42162a-1829-4d09-a3d6-4c4c68819cb4",
                    "fileExt": ".docx",
                    "fileName": "F661 Discharge Summary.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.24 Quality of Life",
                "type": "dir",
                "id": "5dc6ce24-7d7b-4e8b-9181-9f40515acf24",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.24 Quality of Life",
                "items": [
                  {
                    "name": "F675 Quality of Life",
                    "type": "policy",
                    "id": "5ed7faec-6f2e-4b6c-bb2b-4ecabd1d10d0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5ed7faec-6f2e-4b6c-bb2b-4ecabd1d10d0.docx?alt=media&token=3da1e364-d54d-41fc-8d90-4ab301a31384",
                    "fileExt": ".docx",
                    "fileName": "F675 Quality of Life.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F676 Activities of Daily Living (ADLs) Maintain Abilities",
                    "type": "policy",
                    "id": "f26a79c9-728e-4d6f-8d53-e62a3ca19880",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff26a79c9-728e-4d6f-8d53-e62a3ca19880.docx?alt=media&token=4981e6a5-23f3-41ed-a282-142f6bea3154",
                    "fileExt": ".docx",
                    "fileName": "F676 Activities of Daily Living (ADLs) Maintain Abilities.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F677 ADL Care Provided for Dependent Residents",
                    "type": "policy",
                    "id": "2aad9511-ed00-41e8-863f-9ad39b8ea42f",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F2aad9511-ed00-41e8-863f-9ad39b8ea42f.docx?alt=media&token=a4a6adfe-867a-4f97-a4a0-82a1bc7a9307",
                    "fileExt": ".docx",
                    "fileName": "F677 ADL Care Provided for Dependent Residents.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F678 Cardio‐Pulmonary Resuscitation (CPR)",
                    "type": "policy",
                    "id": "7d012379-fc79-4dfd-b2f8-3eec86c58a92",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7d012379-fc79-4dfd-b2f8-3eec86c58a92.docx?alt=media&token=7410c9bf-bee1-454f-a2d7-51a807b7992e",
                    "fileExt": ".docx",
                    "fileName": "F678 Cardio‐Pulmonary Resuscitation (CPR).docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F679 Activities Meet InterestNeeds of Each Resident",
                    "type": "policy",
                    "id": "9b085f9a-c232-4aaa-89cc-0b80af3a283b",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9b085f9a-c232-4aaa-89cc-0b80af3a283b.docx?alt=media&token=f9a4f4af-5d31-4970-a9ec-2d01fc28d28a",
                    "fileExt": ".docx",
                    "fileName": "F679 Activities Meet InterestNeeds of Each Resident.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F680 Qualifications of Activity Professional",
                    "type": "policy",
                    "id": "b8293912-a93a-4565-bb83-a8e4a7196154",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb8293912-a93a-4565-bb83-a8e4a7196154.docx?alt=media&token=3e921b6f-4148-4b31-8604-3b1b18f6b958",
                    "fileExt": ".docx",
                    "fileName": "F680 Qualifications of Activity Professional.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.25 Quality of Care",
                "type": "dir",
                "id": "72ecf8ee-8cdf-48c1-93cc-8ac407ed6f61",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.25 Quality of Care",
                "items": [
                  {
                    "name": "F684 Quality of Care",
                    "type": "policy",
                    "id": "8ba85caf-4491-4ad6-a81f-e12365b653a9",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F8ba85caf-4491-4ad6-a81f-e12365b653a9.docx?alt=media&token=2608a908-e6c0-45a0-83bc-2ef7e06e839f",
                    "fileExt": ".docx",
                    "fileName": "F684 Quality of Care.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F685 TreatmentDevices to Maintain HearingVision",
                    "type": "policy",
                    "id": "0643b9c1-dfb2-41d5-9e51-caf34931ea28",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0643b9c1-dfb2-41d5-9e51-caf34931ea28.docx?alt=media&token=d0191b9a-de64-43e6-a81d-9f07ebb4312d",
                    "fileExt": ".docx",
                    "fileName": "F685 TreatmentDevices to Maintain HearingVision.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F686 TreatmentSvcs to PreventHeal Pressure Ulcers",
                    "type": "policy",
                    "id": "9ae6873f-a738-47ea-857f-3ffe6c0b6cd3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9ae6873f-a738-47ea-857f-3ffe6c0b6cd3.docx?alt=media&token=7bfa3f5f-c072-498c-b0f8-80d6fe15e878",
                    "fileExt": ".docx",
                    "fileName": "F686 TreatmentSvcs to PreventHeal Pressure Ulcers.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F687 Foot Care",
                    "type": "policy",
                    "id": "6eb8a01b-b9a1-48cc-8bab-631818b2924c",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6eb8a01b-b9a1-48cc-8bab-631818b2924c.docx?alt=media&token=84deebb1-adff-4271-b148-0a4cf7054f33",
                    "fileExt": ".docx",
                    "fileName": "F687 Foot Care.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F688 IncreasePrevent Decrease in ROMMobility",
                    "type": "policy",
                    "id": "4a86521f-5eb5-4d87-964a-6bcb1ccb8f86",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4a86521f-5eb5-4d87-964a-6bcb1ccb8f86.docx?alt=media&token=698a4c3c-9f32-41bb-a2f0-ddc84dbce67f",
                    "fileExt": ".docx",
                    "fileName": "F688 IncreasePrevent Decrease in ROMMobility.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F689 Free of Accident HazardsSupervisionDevices",
                    "type": "policy",
                    "id": "da3b5dc5-1b41-4cb7-9a89-131307c05e93",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fda3b5dc5-1b41-4cb7-9a89-131307c05e93.docx?alt=media&token=b0ecdcfe-3676-44ba-ac09-e36d74e82f8d",
                    "fileExt": ".docx",
                    "fileName": "F689 Free of Accident HazardsSupervisionDevices.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F690 BowelBladder Incontinence, Catheter, UTI",
                    "type": "policy",
                    "id": "13920b03-d3e5-4c03-95fb-ccb631024acc",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F13920b03-d3e5-4c03-95fb-ccb631024acc.docx?alt=media&token=01ac23a2-1833-4a81-b8cf-bf212760ab36",
                    "fileExt": ".docx",
                    "fileName": "F690 BowelBladder Incontinence, Catheter, UTI.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F691 Colostomy, Urostomy, or Ileostomy Care",
                    "type": "policy",
                    "id": "b01fb93c-3965-40cb-b6de-0351e279fc94",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb01fb93c-3965-40cb-b6de-0351e279fc94.docx?alt=media&token=b82a6731-0d69-42d1-b91a-dd648fe369f0",
                    "fileExt": ".docx",
                    "fileName": "F691 Colostomy, Urostomy, or Ileostomy Care.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F692 NutritionHydration Status Maintenance",
                    "type": "policy",
                    "id": "f011495a-3465-4739-b236-24ed992d8be3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff011495a-3465-4739-b236-24ed992d8be3.docx?alt=media&token=5ebc2ad2-da31-4cd7-95e9-b2032455310a",
                    "fileExt": ".docx",
                    "fileName": "F692 NutritionHydration Status Maintenance.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F693 Tube Feeding ManagementRestore Eating Skills",
                    "type": "policy",
                    "id": "81e3a0ef-df00-4f41-bc86-a6a77166d9f4",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F81e3a0ef-df00-4f41-bc86-a6a77166d9f4.docx?alt=media&token=ab4ba118-2e5b-4338-b2da-2b4da5555f91",
                    "fileExt": ".docx",
                    "fileName": "F693 Tube Feeding ManagementRestore Eating Skills.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F694 Parenteral IV Fluids",
                    "type": "policy",
                    "id": "df78a10d-f63e-4d54-be95-9db04407160b",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fdf78a10d-f63e-4d54-be95-9db04407160b.docx?alt=media&token=2bc9d70f-a026-4090-a49e-20edd9ed68b9",
                    "fileExt": ".docx",
                    "fileName": "F694 Parenteral IV Fluids.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F695 RespiratoryTracheostomy care and Suctioning",
                    "type": "policy",
                    "id": "9d3212b9-7adf-495b-a47c-542b6b6e64f4",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9d3212b9-7adf-495b-a47c-542b6b6e64f4.docx?alt=media&token=dc46a736-387d-4ba2-9258-c19e8acf44f1",
                    "fileExt": ".docx",
                    "fileName": "F695 RespiratoryTracheostomy care and Suctioning.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F696 Prostheses",
                    "type": "policy",
                    "id": "e8173a2b-6027-4324-b3b6-703bdd373903",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe8173a2b-6027-4324-b3b6-703bdd373903.docx?alt=media&token=8282b601-4e8c-4f62-85c9-25767b8e52e1",
                    "fileExt": ".docx",
                    "fileName": "F696 Prostheses.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F697 Pain Management",
                    "type": "policy",
                    "id": "4ae2594d-73a8-4757-82d3-455913bf0976",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4ae2594d-73a8-4757-82d3-455913bf0976.docx?alt=media&token=7f0577e8-f685-4d10-a114-c35d35089082",
                    "fileExt": ".docx",
                    "fileName": "F697 Pain Management.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F698 Dialysis",
                    "type": "policy",
                    "id": "e508b8da-bc14-4df1-99ca-e65b0c554997",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe508b8da-bc14-4df1-99ca-e65b0c554997.docx?alt=media&token=a88bc01c-a024-4e7a-9f6a-d1e8c5695d24",
                    "fileExt": ".docx",
                    "fileName": "F698 Dialysis.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F699 Trauma Informed Care",
                    "type": "policy",
                    "id": "28b738e7-4af7-406d-a48a-53264a1bc173",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F28b738e7-4af7-406d-a48a-53264a1bc173.docx?alt=media&token=3c4096cd-5eba-4130-b6a3-d62c1e7bf450",
                    "fileExt": ".docx",
                    "fileName": "F699 Trauma Informed Care.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F700 Bedrails",
                    "type": "policy",
                    "id": "a8c39236-7fdf-44b4-935b-a659d6be326a",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fa8c39236-7fdf-44b4-935b-a659d6be326a.docx?alt=media&token=6507b6fc-b04d-4150-8d65-347f5f4d5bc0",
                    "fileExt": ".docx",
                    "fileName": "F700 Bedrails.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.30 Physician Services",
                "type": "dir",
                "id": "16b64a17-3a7b-4708-9c2c-7142a9b98313",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.30 Physician Services",
                "items": [
                  {
                    "name": "F710 Resident's Care Supervised by a Physician",
                    "type": "policy",
                    "id": "3cfb69d8-5cd6-47f8-8849-15a82b68a469",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F3cfb69d8-5cd6-47f8-8849-15a82b68a469.docx?alt=media&token=1222adde-e9d2-40d1-828e-e87bd6c4a644",
                    "fileExt": ".docx",
                    "fileName": "F710 Resident's Care Supervised by a Physician.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F711 Physician Visits Review Care Notes Order",
                    "type": "policy",
                    "id": "c5313b7f-52bd-4ca5-b00d-f434d2f2f9bb",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc5313b7f-52bd-4ca5-b00d-f434d2f2f9bb.docx?alt=media&token=8e3234bd-8aee-46b1-b163-e7952037ec24",
                    "fileExt": ".docx",
                    "fileName": "F711 Physician Visits Review Care Notes Order.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F712 Physician Visits Frequency Timeliness Alternate NPPs",
                    "type": "policy",
                    "id": "941563aa-3efe-4fc8-a61d-c50bb1065230",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F941563aa-3efe-4fc8-a61d-c50bb1065230.docx?alt=media&token=5b5f061f-121f-408d-80ea-1fbc6ff0bb26",
                    "fileExt": ".docx",
                    "fileName": "F712 Physician Visits Frequency Timeliness Alternate NPPs.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F713 Physician for Emergency Care, Available 24 Hours",
                    "type": "policy",
                    "id": "6f25bb37-ff64-40b0-9a91-cdc540509f55",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6f25bb37-ff64-40b0-9a91-cdc540509f55.docx?alt=media&token=4d4db8ca-659f-4b63-9eab-c2aa576d432a",
                    "fileExt": ".docx",
                    "fileName": "F713 Physician for Emergency Care, Available 24 Hours.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F714 Physician Delegation of Tasks to NPP",
                    "type": "policy",
                    "id": "7a7e670a-bba8-4a7b-895d-8487971f5e68",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7a7e670a-bba8-4a7b-895d-8487971f5e68.docx?alt=media&token=b2fe7344-4416-45cb-b3e6-4eaf772df734",
                    "fileExt": ".docx",
                    "fileName": "F714 Physician Delegation of Tasks to NPP.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F715 Physician Delegation to DietitianTherapist",
                    "type": "policy",
                    "id": "2170ea0c-1839-4685-9ee7-f5d40abd8d46",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F2170ea0c-1839-4685-9ee7-f5d40abd8d46.docx?alt=media&token=e61faee3-7dc2-4165-8822-bfcd890e631e",
                    "fileExt": ".docx",
                    "fileName": "F715 Physician Delegation to DietitianTherapist.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.35 Nursing Services",
                "type": "dir",
                "id": "9cff3ccf-0ac2-4cf7-a45e-50af05d6a3ea",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.35 Nursing Services",
                "items": [
                  {
                    "name": "F725 Sufficient Nursing Staff",
                    "type": "policy",
                    "id": "6b66b96b-7e4e-4263-ab3f-b7aef3fcfc3f",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6b66b96b-7e4e-4263-ab3f-b7aef3fcfc3f.docx?alt=media&token=17998259-7229-4bba-a11d-4045c9c07663",
                    "fileExt": ".docx",
                    "fileName": "F725 Sufficient Nursing Staff.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F726 Competent Nursing Staff",
                    "type": "policy",
                    "id": "16ddbdd5-9254-4115-bc5f-e83941296f29",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F16ddbdd5-9254-4115-bc5f-e83941296f29.docx?alt=media&token=209fa43a-90de-4c6f-ae89-21580ca090f3",
                    "fileExt": ".docx",
                    "fileName": "F726 Competent Nursing Staff.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F727 RN 8 Hrs7 days Wk, Full Time DON",
                    "type": "policy",
                    "id": "f737c5c8-3c31-4f6e-ae62-46387a27e7fb",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff737c5c8-3c31-4f6e-ae62-46387a27e7fb.docx?alt=media&token=ea0931e7-667e-4ae6-bdc5-d988826976ae",
                    "fileExt": ".docx",
                    "fileName": "F727 RN 8 Hrs7 days Wk, Full Time DON.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F728 Facility Hiring and Use of Nurse",
                    "type": "policy",
                    "id": "acce3ada-6356-4d2d-87a3-c6e24a4cfcf3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Facce3ada-6356-4d2d-87a3-c6e24a4cfcf3.docx?alt=media&token=b568f6d6-86a6-433d-856b-b8b0c855713b",
                    "fileExt": ".docx",
                    "fileName": "F728 Facility Hiring and Use of Nurse.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F729 Nurse Aide Registry Verification, Retraining",
                    "type": "policy",
                    "id": "e0bb5bb1-bfa8-45cf-a871-bc1310e43265",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe0bb5bb1-bfa8-45cf-a871-bc1310e43265.docx?alt=media&token=6c946495-691e-489f-be16-3a8163f75cfc",
                    "fileExt": ".docx",
                    "fileName": "F729 Nurse Aide Registry Verification, Retraining.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F730 Nurse Aide Perform Review – 12HrYear In‐ service",
                    "type": "policy",
                    "id": "d4cf2dd8-ccbc-4043-9c54-4125468a8e3a",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd4cf2dd8-ccbc-4043-9c54-4125468a8e3a.docx?alt=media&token=91187574-a776-4020-89d9-9dd4fd54d9b4",
                    "fileExt": ".docx",
                    "fileName": "F730 Nurse Aide Perform Review – 12HrYear In‐ service.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F731 Waiver‐Licensed Nurses 24HrDay and RN Coverage",
                    "type": "policy",
                    "id": "c18df4c9-2120-43fe-9b44-480201fa51e6",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc18df4c9-2120-43fe-9b44-480201fa51e6.docx?alt=media&token=32babdee-b307-4ee9-9832-a26bb2e64b06",
                    "fileExt": ".docx",
                    "fileName": "F731 Waiver‐Licensed Nurses 24HrDay and RN Coverage.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F732 Posted Nurse Staffing Information",
                    "type": "policy",
                    "id": "21b241f6-f65f-4428-8d80-2dc7db1f090a",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F21b241f6-f65f-4428-8d80-2dc7db1f090a.docx?alt=media&token=13837600-0639-4f00-bb84-a3989181a012",
                    "fileExt": ".docx",
                    "fileName": "F732 Posted Nurse Staffing Information.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.40 Behavioral Health",
                "type": "dir",
                "id": "9fb5e5d7-e440-4138-9ee6-bef601ffb631",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.40 Behavioral Health",
                "items": [
                  {
                    "name": "F740 Behavioral Health Services",
                    "type": "policy",
                    "id": "fb423805-30b2-4c5e-afc2-6c8e5b4e9682",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ffb423805-30b2-4c5e-afc2-6c8e5b4e9682.docx?alt=media&token=802de07b-6442-4e48-8fd4-c0acbc4f5092",
                    "fileExt": ".docx",
                    "fileName": "F740 Behavioral Health Services.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F741 Sufficient Competent Staff ‐ Behav Health Needs",
                    "type": "policy",
                    "id": "812edd95-427f-4983-8f82-95a8b343738d",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F812edd95-427f-4983-8f82-95a8b343738d.docx?alt=media&token=ac36c2d4-b30c-4181-80dc-884c8d6b5eab",
                    "fileExt": ".docx",
                    "fileName": "F741 Sufficient Competent Staff ‐ Behav Health Needs.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F742 TreatmentSvc for MentalPsychosocial Concerns",
                    "type": "policy",
                    "id": "89aca9e9-fca1-41ee-b099-8a25e2670881",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F89aca9e9-fca1-41ee-b099-8a25e2670881.docx?alt=media&token=a7213e96-7e44-4d84-a14e-ee44b8c0cd34",
                    "fileExt": ".docx",
                    "fileName": "F742 TreatmentSvc for MentalPsychosocial Concerns.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F743 No Pattern of Behavioral Difficulties Unless Unavoidable",
                    "type": "policy",
                    "id": "96dd63e4-9f0e-4f9e-98a7-69f1e5b903a4",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F96dd63e4-9f0e-4f9e-98a7-69f1e5b903a4.docx?alt=media&token=645caec9-8878-4c02-94ae-44182251fbdc",
                    "fileExt": ".docx",
                    "fileName": "F743 No Pattern of Behavioral Difficulties Unless Unavoidable.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F744 Treatment Service for Dementia",
                    "type": "policy",
                    "id": "8a82783c-0e69-47a8-adae-49e42c335f03",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F8a82783c-0e69-47a8-adae-49e42c335f03.docx?alt=media&token=ac6a539e-cdf3-4f2f-881e-56804d1774a7",
                    "fileExt": ".docx",
                    "fileName": "F744 Treatment Service for Dementia.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F745 Provision of Medically Related Social Services",
                    "type": "policy",
                    "id": "ad56c17c-9669-4b34-bc49-3a5b7205b451",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fad56c17c-9669-4b34-bc49-3a5b7205b451.docx?alt=media&token=3efb126a-d78a-4898-ab11-6829dc31d01d",
                    "fileExt": ".docx",
                    "fileName": "F745 Provision of Medically Related Social Services.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.45 Pharmacy Services",
                "type": "dir",
                "id": "2859b9c0-4037-4a8f-86c8-46aeadedc403",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.45 Pharmacy Services",
                "items": [
                  {
                    "name": "F755 Pharmacy SvcsProceduresPharmacist Records",
                    "type": "policy",
                    "id": "29c37b5b-e52f-4a4e-831a-318bfc96dae0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F29c37b5b-e52f-4a4e-831a-318bfc96dae0.docx?alt=media&token=4a2f91dc-537e-4986-9cee-faf5e4f8d72e",
                    "fileExt": ".docx",
                    "fileName": "F755 Pharmacy SvcsProceduresPharmacist Records.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F756 Drug Regimen Review, Report Irregular, Act On",
                    "type": "policy",
                    "id": "595a7124-30d4-4cd0-ac2c-30d348d4cc36",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F595a7124-30d4-4cd0-ac2c-30d348d4cc36.docx?alt=media&token=bee72740-c2e9-434b-8688-c02ba1ffb0ef",
                    "fileExt": ".docx",
                    "fileName": "F756 Drug Regimen Review, Report Irregular, Act On.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F757 Drug Regimen is Free From Unnecessary Drugs",
                    "type": "policy",
                    "id": "36b78b14-7dad-4d36-88dd-d493db0c0e38",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F36b78b14-7dad-4d36-88dd-d493db0c0e38.docx?alt=media&token=8700e928-ec60-4f82-b021-7513db1a014a",
                    "fileExt": ".docx",
                    "fileName": "F757 Drug Regimen is Free From Unnecessary Drugs.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F758 Free from Unnec Psychotropic MedsPRN Use",
                    "type": "policy",
                    "id": "5ae221b6-601c-4225-901f-16a25a187050",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5ae221b6-601c-4225-901f-16a25a187050.docx?alt=media&token=115812d5-b074-414a-bdd1-55f0b34eb71f",
                    "fileExt": ".docx",
                    "fileName": "F758 Free from Unnec Psychotropic MedsPRN Use.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F759 Free of Medication Error",
                    "type": "policy",
                    "id": "2b1f816c-e968-4bc9-a9f9-3c4f67718f75",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F2b1f816c-e968-4bc9-a9f9-3c4f67718f75.docx?alt=media&token=7b08858a-aa5b-4957-8a9f-73cad3460fed",
                    "fileExt": ".docx",
                    "fileName": "F759 Free of Medication Error.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F760 Residents Are Free of Significant Med Errors",
                    "type": "policy",
                    "id": "03fbbdd7-38e0-45db-be6c-e96fab042885",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F03fbbdd7-38e0-45db-be6c-e96fab042885.docx?alt=media&token=43818e4e-0456-45d5-9a8d-8ef079fe0066",
                    "fileExt": ".docx",
                    "fileName": "F760 Residents Are Free of Significant Med Errors.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F761 Label Store Drugs & Biologicals",
                    "type": "policy",
                    "id": "6d1ff9c5-0651-4997-a1af-b849d412aea7",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6d1ff9c5-0651-4997-a1af-b849d412aea7.docx?alt=media&token=20271a76-48f1-4d5e-b683-2f3755376921",
                    "fileExt": ".docx",
                    "fileName": "F761 Label Store Drugs & Biologicals.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.50 Laboratory, Radiology, and Other Diagnostic Services",
                "type": "dir",
                "id": "bb7692da-9dd7-47a9-9d74-0aa72241fcbf",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.50 Laboratory, Radiology, and Other Diagnostic Services",
                "items": [
                  {
                    "name": "F770 Laboratory Services",
                    "type": "policy",
                    "id": "d8525379-24ed-43f2-b089-6bb957b2fc8e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd8525379-24ed-43f2-b089-6bb957b2fc8e.docx?alt=media&token=9e1f3eb4-cf88-420b-a4b1-0e78738162f9",
                    "fileExt": ".docx",
                    "fileName": "F770 Laboratory Services.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F771 Blood Blank and Transfusion Services",
                    "type": "policy",
                    "id": "d3171191-e516-4e2a-9f85-ddc5e38bb3c2",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd3171191-e516-4e2a-9f85-ddc5e38bb3c2.docx?alt=media&token=636bcc7b-813a-4561-bd42-14dcfbe50c53",
                    "fileExt": ".docx",
                    "fileName": "F771 Blood Blank and Transfusion Services.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F772 Lab Services Not Provided On‐Site",
                    "type": "policy",
                    "id": "8f1dbae5-cee6-466c-a235-a047d8268c80",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F8f1dbae5-cee6-466c-a235-a047d8268c80.docx?alt=media&token=0cd7f189-1e4a-4ac6-8dcf-bee0cd0633bb",
                    "fileExt": ".docx",
                    "fileName": "F772 Lab Services Not Provided On‐Site.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F773 Lab Svs Physician OrderNotify of Results",
                    "type": "policy",
                    "id": "aea4825a-a4ea-4b41-b164-f53303e639c4",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Faea4825a-a4ea-4b41-b164-f53303e639c4.docx?alt=media&token=2ed66170-12d7-4a4b-99e6-9d6a0a4ad0cd",
                    "fileExt": ".docx",
                    "fileName": "F773 Lab Svs Physician OrderNotify of Results.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F774 Assist with Transport Arrangements to Lab Svcs",
                    "type": "policy",
                    "id": "4e624a27-b2f3-42b0-a286-95a11baea569",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4e624a27-b2f3-42b0-a286-95a11baea569.docx?alt=media&token=e81336ac-2b11-4b32-afe2-77c0cf3ac6a2",
                    "fileExt": ".docx",
                    "fileName": "F774 Assist with Transport Arrangements to Lab Svcs.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F775 Lab Reports in Record‐Lab NameAddress",
                    "type": "policy",
                    "id": "f5fd7c63-513c-4e3d-8989-ac3981b65a78",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff5fd7c63-513c-4e3d-8989-ac3981b65a78.docx?alt=media&token=6cb2e0c2-6016-42d0-8dec-3e090d9da486",
                    "fileExt": ".docx",
                    "fileName": "F775 Lab Reports in Record‐Lab NameAddress.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F776 RadiologyOther Diagnostic Services",
                    "type": "policy",
                    "id": "1ba15151-a954-4074-84ff-8955b9f22bd0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1ba15151-a954-4074-84ff-8955b9f22bd0.docx?alt=media&token=604fd6f6-8a76-4c47-937e-2c40df4f21e6",
                    "fileExt": ".docx",
                    "fileName": "F776 RadiologyOther Diagnostic Services.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F777 RadiologyDiag. Svcs OrderedNotify Results",
                    "type": "policy",
                    "id": "3b8fb485-efea-4714-b344-8515985e0249",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F3b8fb485-efea-4714-b344-8515985e0249.docx?alt=media&token=21dacf6a-ba33-4892-b7c3-2e83157e18b5",
                    "fileExt": ".docx",
                    "fileName": "F777 RadiologyDiag. Svcs OrderedNotify Results.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F778 Assist with Transport Arrangements to Radiology",
                    "type": "policy",
                    "id": "d1464874-db7a-4035-87c9-0820223c6b52",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd1464874-db7a-4035-87c9-0820223c6b52.docx?alt=media&token=47e5b0bf-b4b3-4aaf-b74a-e6ad6a810a96",
                    "fileExt": ".docx",
                    "fileName": "F778 Assist with Transport Arrangements to Radiology.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F779 X‐RayDiagnostic Report in Record‐SignDated",
                    "type": "policy",
                    "id": "1b57c993-8e46-42a4-9f26-25086787503d",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1b57c993-8e46-42a4-9f26-25086787503d.docx?alt=media&token=a5c02055-388c-4992-94dd-12144289c5e1",
                    "fileExt": ".docx",
                    "fileName": "F779 X‐RayDiagnostic Report in Record‐SignDated.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.55 Dental Services",
                "type": "dir",
                "id": "aec9afe1-0420-4383-a021-55574383c44b",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.55 Dental Services",
                "items": [
                  {
                    "name": "F790 RoutineEmergency Dental Services in SNFs",
                    "type": "policy",
                    "id": "dbb5a8b3-ba77-4c5f-b25a-87abc5a446ae",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fdbb5a8b3-ba77-4c5f-b25a-87abc5a446ae.docx?alt=media&token=f27a1024-be6c-42c8-bd0e-a801fa84999e",
                    "fileExt": ".docx",
                    "fileName": "F790 RoutineEmergency Dental Services in SNFs.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F791 RoutineEmergency Dental Services in NFs",
                    "type": "policy",
                    "id": "0c4918a2-e11f-45c7-83b8-3710af04ffa8",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0c4918a2-e11f-45c7-83b8-3710af04ffa8.docx?alt=media&token=7cd8a837-f450-475c-82e3-80bab425ea3c",
                    "fileExt": ".docx",
                    "fileName": "F791 RoutineEmergency Dental Services in NFs.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.60 Food and Nutrition Services",
                "type": "dir",
                "id": "caf3c7f6-5eec-4d7d-9c8b-f95304e1b6ef",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.60 Food and Nutrition Services",
                "items": [
                  {
                    "name": "F800 Provided Diet Meets Needs of Each Resident",
                    "type": "policy",
                    "id": "dac1fe59-b23e-47bf-970a-ca448d1a0920",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fdac1fe59-b23e-47bf-970a-ca448d1a0920.docx?alt=media&token=1d01b315-92a8-454a-a0b7-ee3749fea209",
                    "fileExt": ".docx",
                    "fileName": "F800 Provided Diet Meets Needs of Each Resident.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F801 Qualified Dietary Staff",
                    "type": "policy",
                    "id": "b1033e8b-0351-40c2-bf60-3d197f364a84",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb1033e8b-0351-40c2-bf60-3d197f364a84.docx?alt=media&token=a98f1a10-ef56-4824-97c1-11bf38f238da",
                    "fileExt": ".docx",
                    "fileName": "F801 Qualified Dietary Staff.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F802 Sufficient Dietary Support Personnel",
                    "type": "policy",
                    "id": "5e79b2fc-989d-43fd-97e6-1677d05f67d4",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5e79b2fc-989d-43fd-97e6-1677d05f67d4.docx?alt=media&token=b810257d-96da-46d6-9f1b-851542e653aa",
                    "fileExt": ".docx",
                    "fileName": "F802 Sufficient Dietary Support Personnel.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F803 Menus Meet Res NeedsPrep in AdvanceFollowed",
                    "type": "policy",
                    "id": "0cc31a8e-6175-4c68-b13f-65e4caed753c",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0cc31a8e-6175-4c68-b13f-65e4caed753c.docx?alt=media&token=74b961f7-93fb-4a16-bc60-c53d2c1af73d",
                    "fileExt": ".docx",
                    "fileName": "F803 Menus Meet Res NeedsPrep in AdvanceFollowed.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F805 Food in Form to Meet Individual Needs",
                    "type": "form",
                    "id": "914478af-cf11-454d-9664-ae18bc63cd11",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F914478af-cf11-454d-9664-ae18bc63cd11.docx?alt=media&token=c81afb42-e7ab-4e1b-bebd-feb3c7e08593",
                    "fileExt": ".docx",
                    "fileName": "F805 Food in Form to Meet Individual Needs.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F806 Resident Allergies, Preferences and Substitutes",
                    "type": "policy",
                    "id": "56b0fc64-7fc0-4282-a41a-0cfde9dea176",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F56b0fc64-7fc0-4282-a41a-0cfde9dea176.docx?alt=media&token=01381bb3-3992-4523-a062-9c91d8d54c9e",
                    "fileExt": ".docx",
                    "fileName": "F806 Resident Allergies, Preferences and Substitutes.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F807 Drinks Avail to Meet NeedsP references Hydration",
                    "type": "policy",
                    "id": "ff715062-fe6d-4cbd-9353-2424274707bd",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fff715062-fe6d-4cbd-9353-2424274707bd.docx?alt=media&token=f9c18631-b79b-4349-a293-91521d3e543b",
                    "fileExt": ".docx",
                    "fileName": "F807 Drinks Avail to Meet NeedsP references Hydration.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F808 Therapeutic Diet Prescribed by Physician",
                    "type": "policy",
                    "id": "c023e9fb-3586-4878-a37a-78cfa5b53eab",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc023e9fb-3586-4878-a37a-78cfa5b53eab.docx?alt=media&token=a6b91d4f-3ef1-42d7-a9d2-c15324999930",
                    "fileExt": ".docx",
                    "fileName": "F808 Therapeutic Diet Prescribed by Physician.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F809 Frequency of MealsSnacks at Bedtime",
                    "type": "policy",
                    "id": "9aff960c-1bc9-42b7-86a5-9f186d3a1b63",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9aff960c-1bc9-42b7-86a5-9f186d3a1b63.docx?alt=media&token=07628a69-aa3a-4266-9bac-20424e4f760e",
                    "fileExt": ".docx",
                    "fileName": "F809 Frequency of MealsSnacks at Bedtime.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F810 Assistive Devices ‐ Eating EquipmentUtensils",
                    "type": "policy",
                    "id": "17aa1838-6309-49c7-a4ff-0c4e1a81ec76",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F17aa1838-6309-49c7-a4ff-0c4e1a81ec76.docx?alt=media&token=8d17ac6f-eb85-4c41-b785-704b6b1542cb",
                    "fileExt": ".docx",
                    "fileName": "F810 Assistive Devices ‐ Eating EquipmentUtensils.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F811 Feeding Asst ‐TrainingSupervisionResident",
                    "type": "policy",
                    "id": "bfbf28d4-138c-485a-b009-af2bef71a81d",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fbfbf28d4-138c-485a-b009-af2bef71a81d.docx?alt=media&token=2c65dd32-113f-447a-942a-ba3b24b79efb",
                    "fileExt": ".docx",
                    "fileName": "F811 Feeding Asst ‐TrainingSupervisionResident.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F812 Food Procurement, StorePrepareServe ‐ Sanitary",
                    "type": "policy",
                    "id": "21523faa-d1f6-40f9-9566-8d80713c07dd",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F21523faa-d1f6-40f9-9566-8d80713c07dd.docx?alt=media&token=2323fb28-ecd2-4d4f-b1e7-4564dda49b36",
                    "fileExt": ".docx",
                    "fileName": "F812 Food Procurement, StorePrepareServe ‐ Sanitary.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F813 Personal Food Policy",
                    "type": "policy",
                    "id": "d4ca330c-838c-4186-b580-8dcc73d25263",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd4ca330c-838c-4186-b580-8dcc73d25263.docx?alt=media&token=8dd2d95a-f7aa-4fa7-bdcb-777417f91c44",
                    "fileExt": ".docx",
                    "fileName": "F813 Personal Food Policy.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F814 Dispose Garbage & Refuse Properly",
                    "type": "policy",
                    "id": "38ee97a6-9d94-422f-b09e-851a35509380",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F38ee97a6-9d94-422f-b09e-851a35509380.docx?alt=media&token=1e7f9ff2-9b67-492d-9564-7481518da2e7",
                    "fileExt": ".docx",
                    "fileName": "F814 Dispose Garbage & Refuse Properly.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.65 Specialized Rehabilitative Services",
                "type": "dir",
                "id": "d9d33ed3-7060-4cfb-bbc7-6493b0c1f3ef",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.65 Specialized Rehabilitative Services",
                "items": [
                  {
                    "name": "F825 ProvideObtain Specialized Rehab Services",
                    "type": "policy",
                    "id": "d3829427-5ab1-465c-8271-de28d14935c3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd3829427-5ab1-465c-8271-de28d14935c3.docx?alt=media&token=b9778a9c-4dba-401d-b8f6-694a3b3fc364",
                    "fileExt": ".docx",
                    "fileName": "F825 ProvideObtain Specialized Rehab Services.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F826 Rehab Services‐ Physician OrderQualified Person",
                    "type": "policy",
                    "id": "77a54a8f-895a-46c9-a649-1b96c055d6ad",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F77a54a8f-895a-46c9-a649-1b96c055d6ad.docx?alt=media&token=6156586a-4fe2-4295-a2de-263c607ad0a4",
                    "fileExt": ".docx",
                    "fileName": "F826 Rehab Services‐ Physician OrderQualified Person.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.70 Administration",
                "type": "dir",
                "id": "e01492de-849f-49a6-a4e0-34ad8fd41772",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.70 Administration",
                "items": [
                  {
                    "name": "F835 Administration",
                    "type": "policy",
                    "id": "6f535dc9-1b94-4de7-aad9-dc44a8df113b",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6f535dc9-1b94-4de7-aad9-dc44a8df113b.docx?alt=media&token=357f2e80-36f7-411d-a9df-7b13e26ae27c",
                    "fileExt": ".docx",
                    "fileName": "F835 Administration.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F836 LicenseComply wFedStateLocal LawProf Std",
                    "type": "reg",
                    "id": "748934db-96c6-4a24-adcf-da8bb4d15a7e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F748934db-96c6-4a24-adcf-da8bb4d15a7e.docx?alt=media&token=0dd340b3-0e9f-40f2-8bbc-c7d8525a86ab",
                    "fileExt": ".docx",
                    "fileName": "F836 LicenseComply wFedStateLocal LawProf Std.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F837 Governing Body",
                    "type": "policy",
                    "id": "b8663fc9-3475-41f7-b660-f93fb9af74bf",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb8663fc9-3475-41f7-b660-f93fb9af74bf.docx?alt=media&token=00e70f39-c84e-4794-a5e4-3a63e598289a",
                    "fileExt": ".docx",
                    "fileName": "F837 Governing Body.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F838 Facility Assessment",
                    "type": "policy",
                    "id": "649af49f-f70a-4aec-8bb2-b8c20d4962dc",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F649af49f-f70a-4aec-8bb2-b8c20d4962dc.docx?alt=media&token=4cd626aa-a6e8-47f7-9aee-72923bfb2f9b",
                    "fileExt": ".docx",
                    "fileName": "F838 Facility Assessment.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F839 Staff Qualifications",
                    "type": "policy",
                    "id": "286db0fe-9412-493b-917c-ec28526aa189",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F286db0fe-9412-493b-917c-ec28526aa189.docx?alt=media&token=93e96cc5-8dd8-4591-8c30-bdb9c484df1f",
                    "fileExt": ".docx",
                    "fileName": "F839 Staff Qualifications.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F840 Use of Outside Resources",
                    "type": "policy",
                    "id": "c540ec80-e067-4862-a1ef-3782685f9e2e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc540ec80-e067-4862-a1ef-3782685f9e2e.docx?alt=media&token=cf8c40c5-b88f-4b20-8719-1e1948588115",
                    "fileExt": ".docx",
                    "fileName": "F840 Use of Outside Resources.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F841 Responsibilities of Medical Director",
                    "type": "policy",
                    "id": "0f56f794-3ed8-4cb0-898f-01ff32ae799f",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0f56f794-3ed8-4cb0-898f-01ff32ae799f.docx?alt=media&token=baf2754b-306d-447b-9ac5-216eee997adc",
                    "fileExt": ".docx",
                    "fileName": "F841 Responsibilities of Medical Director.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F842 Resident Records ‐ Identifiable Information",
                    "type": "policy",
                    "id": "ef40a6a2-03e2-4651-8d6c-5337d8fe225f",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fef40a6a2-03e2-4651-8d6c-5337d8fe225f.docx?alt=media&token=6bce3922-3267-4f9d-b5ec-3e7907418e24",
                    "fileExt": ".docx",
                    "fileName": "F842 Resident Records ‐ Identifiable Information.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F843 Transfer Agreement",
                    "type": "policy",
                    "id": "a9a7ac77-ea64-460b-8341-dec22e78d238",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fa9a7ac77-ea64-460b-8341-dec22e78d238.docx?alt=media&token=8ddd7e5c-d514-4941-a156-575ff5730a4e",
                    "fileExt": ".docx",
                    "fileName": "F843 Transfer Agreement.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F844 Disclosure of Ownership Requirements",
                    "type": "policy",
                    "id": "368e294a-9fb3-4162-85d4-a5125b39e390",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F368e294a-9fb3-4162-85d4-a5125b39e390.docx?alt=media&token=0b117e22-6733-49ec-bc72-c72fc5b70ed8",
                    "fileExt": ".docx",
                    "fileName": "F844 Disclosure of Ownership Requirements.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F845 Facility closure‐Administrator",
                    "type": "policy",
                    "id": "9bc23c98-807e-4128-833c-03052e0f770c",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9bc23c98-807e-4128-833c-03052e0f770c.docx?alt=media&token=73535d67-0dab-4f4b-89ed-fc774402f67f",
                    "fileExt": ".docx",
                    "fileName": "F845 Facility closure‐Administrator.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F846 Facility closure",
                    "type": "policy",
                    "id": "8af1400c-eeff-48db-863e-630eafb62156",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F8af1400c-eeff-48db-863e-630eafb62156.docx?alt=media&token=58fe8af2-8b8c-4d02-be43-6fa001edafab",
                    "fileExt": ".docx",
                    "fileName": "F846 Facility closure.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F847 Enter into Binding Arbitration Agreements",
                    "type": "policy",
                    "id": "e0eb641e-6dfb-476c-9b93-0533ea4ff990",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe0eb641e-6dfb-476c-9b93-0533ea4ff990.docx?alt=media&token=76030cb1-247a-4d6b-9bd1-a57ea5d3dd42",
                    "fileExt": ".docx",
                    "fileName": "F847 Enter into Binding Arbitration Agreements.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F848 Select Arbitrator Venue, Retention of Agreements ",
                    "type": "policy",
                    "id": "cb88e837-92bc-4c51-8c0b-32d935a59f50",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fcb88e837-92bc-4c51-8c0b-32d935a59f50.docx?alt=media&token=b459dc74-d74d-42d0-a16f-6bab37a3b305",
                    "fileExt": ".docx",
                    "fileName": "F848 Select Arbitrator Venue, Retention of Agreements .docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F849 Hospice Services",
                    "type": "policy",
                    "id": "12e90bef-b797-477e-a294-ff600d1614d2",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F12e90bef-b797-477e-a294-ff600d1614d2.docx?alt=media&token=90b0ca20-636b-49f5-a2ce-653a35614e39",
                    "fileExt": ".docx",
                    "fileName": "F849 Hospice Services.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F850 Qualifications of Social Worker 120 Beds",
                    "type": "policy",
                    "id": "c3ad5525-18ff-48ae-89ed-e167ae0ceee3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc3ad5525-18ff-48ae-89ed-e167ae0ceee3.docx?alt=media&token=75185d3b-9c74-4048-8f67-5e7b850bd656",
                    "fileExt": ".docx",
                    "fileName": "F850 Qualifications of Social Worker 120 Beds.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F851 Payroll Based Journal",
                    "type": "policy",
                    "id": "40e92071-be95-4fce-9b24-923fbba3703b",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F40e92071-be95-4fce-9b24-923fbba3703b.docx?alt=media&token=792736da-58a2-46e8-9757-c10a69bf78c0",
                    "fileExt": ".docx",
                    "fileName": "F851 Payroll Based Journal.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F948 Training for Feeding Assistants",
                    "type": "policy",
                    "id": "d011b4a7-3975-42e3-8909-cc2db6cb6648",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd011b4a7-3975-42e3-8909-cc2db6cb6648.docx?alt=media&token=b37feba1-c8a3-4e56-bc41-41f8555412a9",
                    "fileExt": ".docx",
                    "fileName": "F948 Training for Feeding Assistants.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.75 Quality Assurance and Performance Improvement",
                "type": "dir",
                "id": "faa50a97-c179-42c1-bb11-a180507e9e8d",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.75 Quality Assurance and Performance Improvement",
                "items": [
                  {
                    "name": "F865 QAPI Program Plan, Disclosure Good Faith Attempt",
                    "type": "policy",
                    "id": "c8fd29bc-caca-474f-a819-615c77892861",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc8fd29bc-caca-474f-a819-615c77892861.docx?alt=media&token=cb6c9f18-094e-4771-9f0b-5c1265224531",
                    "fileExt": ".docx",
                    "fileName": "F865 QAPI Program Plan, Disclosure Good Faith Attempt.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F866 QAPI QAA Data Collection and Monitoring",
                    "type": "policy",
                    "id": "39647ab0-6006-42f6-84e3-791b9fb11477",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F39647ab0-6006-42f6-84e3-791b9fb11477.docx?alt=media&token=4b37d39b-4639-4d32-8310-b184941a11b3",
                    "fileExt": ".docx",
                    "fileName": "F866 QAPI QAA Data Collection and Monitoring.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F867 QAPI QAA Improvement Activities",
                    "type": "policy",
                    "id": "05f83672-50da-47e8-b194-0be571759255",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F05f83672-50da-47e8-b194-0be571759255.docx?alt=media&token=8794c765-645f-4591-ba52-47aaab8c0a37",
                    "fileExt": ".docx",
                    "fileName": "F867 QAPI QAA Improvement Activities.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F868 QAA Committee",
                    "type": "policy",
                    "id": "47c3f203-7d56-4436-9dc2-b91b870cd537",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F47c3f203-7d56-4436-9dc2-b91b870cd537.docx?alt=media&token=f41a2d19-b668-43de-b163-df2183474b7b",
                    "fileExt": ".docx",
                    "fileName": "F868 QAA Committee.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.80 Infection Control",
                "type": "dir",
                "id": "d271e776-0308-4d3b-b385-7d0eeda814a0",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.80 Infection Control",
                "items": [
                  {
                    "name": "F880 Infection Prevention & Control",
                    "type": "policy",
                    "id": "1fa40973-c5c7-4920-ac22-5b48edfe2a87",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1fa40973-c5c7-4920-ac22-5b48edfe2a87.docx?alt=media&token=37aa809c-7638-4e97-9fac-72ce41c914be",
                    "fileExt": ".docx",
                    "fileName": "F880 Infection Prevention & Control.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F881 Antibiotic Stewardship Program",
                    "type": "policy",
                    "id": "08763a4e-a7a6-4e17-a2ef-95e8675b5d8b",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F08763a4e-a7a6-4e17-a2ef-95e8675b5d8b.docx?alt=media&token=ab709a4d-36fe-4e8a-bd66-93a22bf2109b",
                    "fileExt": ".docx",
                    "fileName": "F881 Antibiotic Stewardship Program.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F882 Infection Preventionist QualificationsRole",
                    "type": "policy",
                    "id": "10309483-0610-4700-aa22-601b79e019f0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F10309483-0610-4700-aa22-601b79e019f0.docx?alt=media&token=1600b73e-952f-4ddb-a1e8-a28b76c7da17",
                    "fileExt": ".docx",
                    "fileName": "F882 Infection Preventionist QualificationsRole.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F883 Influenza and Pneumococcal Immunizations",
                    "type": "policy",
                    "id": "a7b881a5-cce5-4c79-bd52-4d08bf64fc03",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fa7b881a5-cce5-4c79-bd52-4d08bf64fc03.docx?alt=media&token=d02f5f35-1382-4a3e-85e3-6acd9670b756",
                    "fileExt": ".docx",
                    "fileName": "F883 Influenza and Pneumococcal Immunizations.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.85 Compliance and Ethics Program",
                "type": "dir",
                "id": "f819348e-ef32-4f9a-9b48-5da79d540bdc",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.85 Compliance and Ethics Program",
                "items": [
                  {
                    "name": "F895 Compliance and Ethics Program",
                    "type": "policy",
                    "id": "1e704bd1-72ec-46cf-b5d6-3e93fac3591f",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1e704bd1-72ec-46cf-b5d6-3e93fac3591f.docx?alt=media&token=66de57b4-5959-4cc4-aea2-0c26a44ff679",
                    "fileExt": ".docx",
                    "fileName": "F895 Compliance and Ethics Program.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.90 Physical Environment",
                "type": "dir",
                "id": "a439cb85-6cec-457c-93bd-72af2ab60494",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.90 Physical Environment",
                "items": [
                  {
                    "name": "F906 Emergency Electrical Power System",
                    "type": "policy",
                    "id": "1da5522b-1014-4860-af4c-a5d10b43b7db",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1da5522b-1014-4860-af4c-a5d10b43b7db.docx?alt=media&token=b3a9d6ab-4c61-4bea-b9ad-0e93ca436e80",
                    "fileExt": ".docx",
                    "fileName": "F906 Emergency Electrical Power System.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F907 Space and Equipment",
                    "type": "policy",
                    "id": "665186e4-df56-4518-b330-038ced92347d",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F665186e4-df56-4518-b330-038ced92347d.docx?alt=media&token=c5c0c0b1-b939-4ab4-848d-a6c6c2245bfa",
                    "fileExt": ".docx",
                    "fileName": "F907 Space and Equipment.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F908 Essential Equipment, Safe Operating Condition",
                    "type": "policy",
                    "id": "520665b1-d4e0-4f38-bcbc-db894e52a065",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F520665b1-d4e0-4f38-bcbc-db894e52a065.docx?alt=media&token=5b04207f-9dce-42f1-a7e8-441926496617",
                    "fileExt": ".docx",
                    "fileName": "F908 Essential Equipment, Safe Operating Condition.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F909 Resident Bed",
                    "type": "policy",
                    "id": "625b2970-c9bf-4394-b849-008dc2b0114e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F625b2970-c9bf-4394-b849-008dc2b0114e.docx?alt=media&token=e35314be-2607-464d-b0ad-0238aae673c5",
                    "fileExt": ".docx",
                    "fileName": "F909 Resident Bed.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F910 Resident Room",
                    "type": "policy",
                    "id": "0f20a06d-8828-4e9d-93d7-079aad0d5ad2",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0f20a06d-8828-4e9d-93d7-079aad0d5ad2.docx?alt=media&token=3b3ddbb8-1ffe-43a0-9f20-e74266279e15",
                    "fileExt": ".docx",
                    "fileName": "F910 Resident Room.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F911 Bedroom Number of Residents",
                    "type": "policy",
                    "id": "ec27c48d-57ae-48c9-bd13-c5cf32007257",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fec27c48d-57ae-48c9-bd13-c5cf32007257.docx?alt=media&token=00963de8-9668-4c90-8a25-a841bde87e67",
                    "fileExt": ".docx",
                    "fileName": "F911 Bedroom Number of Residents.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F912 Bedrooms Measure at Least 80 Square FtResident",
                    "type": "policy",
                    "id": "84b11d04-7764-41c9-ae92-defb01a98157",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F84b11d04-7764-41c9-ae92-defb01a98157.docx?alt=media&token=a6647d0d-c852-41b3-9eae-2feff20e21f8",
                    "fileExt": ".docx",
                    "fileName": "F912 Bedrooms Measure at Least 80 Square FtResident.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F913 Bedrooms Have Direct Access to Exit Corridor",
                    "type": "policy",
                    "id": "80feabad-72d9-47f0-bd8e-c341f956d4e2",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F80feabad-72d9-47f0-bd8e-c341f956d4e2.docx?alt=media&token=57d4322d-b72f-4d94-ac75-956383644908",
                    "fileExt": ".docx",
                    "fileName": "F913 Bedrooms Have Direct Access to Exit Corridor.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F914 Bedrooms Assure Full Visual Privacy",
                    "type": "policy",
                    "id": "c5e0993c-a515-49d6-bef4-cb08bb0b7221",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc5e0993c-a515-49d6-bef4-cb08bb0b7221.docx?alt=media&token=3b4b2d99-117d-45a4-9709-83400961f082",
                    "fileExt": ".docx",
                    "fileName": "F914 Bedrooms Assure Full Visual Privacy.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F915 Resident Room Window ",
                    "type": "policy",
                    "id": "46f6ba4d-1710-4fba-a5a7-e91547befcf2",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F46f6ba4d-1710-4fba-a5a7-e91547befcf2.docx?alt=media&token=e8ce6c7e-7cfe-4d59-9be6-6d018e002d07",
                    "fileExt": ".docx",
                    "fileName": "F915 Resident Room Window .docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F916 Resident Room Floor Above Grade",
                    "type": "policy",
                    "id": "977806fa-fc72-4e81-8b8e-02ed3087febc",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F977806fa-fc72-4e81-8b8e-02ed3087febc.docx?alt=media&token=af4c86e8-f15d-47fa-8597-0a13b06d1669",
                    "fileExt": ".docx",
                    "fileName": "F916 Resident Room Floor Above Grade.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F917 Resident Room BedFurnitureCloset",
                    "type": "policy",
                    "id": "ffe07361-74f3-4484-8ebf-7cd05c54dba2",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fffe07361-74f3-4484-8ebf-7cd05c54dba2.docx?alt=media&token=1127e3d9-1645-4071-84ea-d769a57b7798",
                    "fileExt": ".docx",
                    "fileName": "F917 Resident Room BedFurnitureCloset.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F918 Bedrooms Equipped Near Lavatory Toilet",
                    "type": "policy",
                    "id": "2493d394-89d8-4131-a3cd-db8966a4c735",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F2493d394-89d8-4131-a3cd-db8966a4c735.docx?alt=media&token=47b158c8-8f57-4f7b-a083-0d06448f7e51",
                    "fileExt": ".docx",
                    "fileName": "F918 Bedrooms Equipped Near Lavatory Toilet.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F919 Resident Call System",
                    "type": "policy",
                    "id": "72386915-8066-477a-9c66-c2a62e79e0b5",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F72386915-8066-477a-9c66-c2a62e79e0b5.docx?alt=media&token=3f64860d-6094-4fd3-8d64-27c666f38ccf",
                    "fileExt": ".docx",
                    "fileName": "F919 Resident Call System.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F920 Requirements for Dining and Activity Rooms",
                    "type": "policy",
                    "id": "49a7ccc6-eadc-43e5-991b-0757e2926740",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F49a7ccc6-eadc-43e5-991b-0757e2926740.docx?alt=media&token=5fe2f621-8de4-486a-a0eb-fb230163e9c7",
                    "fileExt": ".docx",
                    "fileName": "F920 Requirements for Dining and Activity Rooms.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F921 Safe Functional Sanitary Comfortable Environment",
                    "type": "policy",
                    "id": "f5795f42-3d80-4c40-8ab0-76f0ce4a4abb",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff5795f42-3d80-4c40-8ab0-76f0ce4a4abb.docx?alt=media&token=a24b7dd6-728d-41e2-8687-963bf548217c",
                    "fileExt": ".docx",
                    "fileName": "F921 Safe Functional Sanitary Comfortable Environment.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F922 Procedures to Ensure Water Availability",
                    "type": "policy",
                    "id": "f11d6a18-c1f1-4ce7-a2fc-02aad3e2fa23",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff11d6a18-c1f1-4ce7-a2fc-02aad3e2fa23.docx?alt=media&token=34c06c8e-93f3-473c-9d4b-10782547ebf9",
                    "fileExt": ".docx",
                    "fileName": "F922 Procedures to Ensure Water Availability.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F923 Ventilation",
                    "type": "policy",
                    "id": "7b365b07-97ad-4216-88f6-7d4d6794a8b8",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7b365b07-97ad-4216-88f6-7d4d6794a8b8.docx?alt=media&token=e30b7aa3-97ba-41ec-9ac9-b2ce90d8c145",
                    "fileExt": ".docx",
                    "fileName": "F923 Ventilation.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F924 Corridors Have Firmly Secured Handrails",
                    "type": "policy",
                    "id": "0f766ccb-603b-4fe2-81eb-36e320f17b48",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0f766ccb-603b-4fe2-81eb-36e320f17b48.docx?alt=media&token=a7e69ba3-a6eb-4569-8e3d-79053be25f02",
                    "fileExt": ".docx",
                    "fileName": "F924 Corridors Have Firmly Secured Handrails.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F925 Maintains Effective Pest Control Program",
                    "type": "policy",
                    "id": "7c0d9d36-c4fb-446e-85ff-750a46433fd7",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7c0d9d36-c4fb-446e-85ff-750a46433fd7.docx?alt=media&token=f537e863-a438-42cb-875c-f539c388c229",
                    "fileExt": ".docx",
                    "fileName": "F925 Maintains Effective Pest Control Program.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F926 Smoking Policies",
                    "type": "policy",
                    "id": "7920a580-b1c2-4ce9-ab0f-8786f843c2e3",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7920a580-b1c2-4ce9-ab0f-8786f843c2e3.docx?alt=media&token=86f43c47-fcf9-4cea-8f6c-8d83ed275cd1",
                    "fileExt": ".docx",
                    "fileName": "F926 Smoking Policies.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "483.95 Training Requirements",
                "type": "dir",
                "id": "aebed9de-b26b-4a25-9850-b700639f8e67",
                "path": "Regulations/Federal/State Operations Manual - Appendix PP/483.95 Training Requirements",
                "items": [
                  {
                    "name": "F940 Training Requirements ‐ General",
                    "type": "policy",
                    "id": "0f1bd800-444d-4435-8be8-a90089e473d8",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0f1bd800-444d-4435-8be8-a90089e473d8.docx?alt=media&token=057328f5-e22b-43ab-bb83-9200d6d15b24",
                    "fileExt": ".docx",
                    "fileName": "F940 Training Requirements ‐ General.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F941 Communication Training",
                    "type": "policy",
                    "id": "4b779079-37e5-4332-a6a8-abf220482400",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4b779079-37e5-4332-a6a8-abf220482400.docx?alt=media&token=5c8c500b-3385-4065-89bd-c7c4df85250c",
                    "fileExt": ".docx",
                    "fileName": "F941 Communication Training.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F942 Resident’s Rights Training",
                    "type": "policy",
                    "id": "d5dda558-f59b-4235-ad2a-05ade3984676",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd5dda558-f59b-4235-ad2a-05ade3984676.docx?alt=media&token=e4925627-f3ca-47e6-8ef6-138d8f415754",
                    "fileExt": ".docx",
                    "fileName": "F942 Resident’s Rights Training.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F943 Abuse, Neglect, and Exploitation Training",
                    "type": "policy",
                    "id": "e3089541-d0a5-42df-846a-9f13ceb31026",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe3089541-d0a5-42df-846a-9f13ceb31026.docx?alt=media&token=f6094d72-a7b3-416f-912f-251ebdd5c91c",
                    "fileExt": ".docx",
                    "fileName": "F943 Abuse, Neglect, and Exploitation Training.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F944 QAPI Training",
                    "type": "policy",
                    "id": "fea778ed-6692-4867-8bb5-4dad299a0e86",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ffea778ed-6692-4867-8bb5-4dad299a0e86.docx?alt=media&token=4826d2a7-c311-4fff-ace4-dd38fb99ba77",
                    "fileExt": ".docx",
                    "fileName": "F944 QAPI Training.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F945 Infection Control Training",
                    "type": "policy",
                    "id": "0386ac95-0fe2-4e8e-a263-c54a4a5b0ed8",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0386ac95-0fe2-4e8e-a263-c54a4a5b0ed8.docx?alt=media&token=f5e89847-a112-4ba0-8e53-c24a65927c6b",
                    "fileExt": ".docx",
                    "fileName": "F945 Infection Control Training.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F946 Compliance and Ethics Training",
                    "type": "policy",
                    "id": "54a40ca5-d397-436b-8716-420d652847ee",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F54a40ca5-d397-436b-8716-420d652847ee.docx?alt=media&token=52b12b45-175b-488a-8753-1330af675bce",
                    "fileExt": ".docx",
                    "fileName": "F946 Compliance and Ethics Training.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F947 Required In‐Service Training for Nurse Aides",
                    "type": "policy",
                    "id": "ad0eee52-7a30-499e-9e76-c82fb7f5c1b5",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fad0eee52-7a30-499e-9e76-c82fb7f5c1b5.docx?alt=media&token=2a9b2c79-35a9-4685-8911-2adbe7f9f071",
                    "fileExt": ".docx",
                    "fileName": "F947 Required In‐Service Training for Nurse Aides.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F948 Training for Feeding Assistants ",
                    "type": "policy",
                    "id": "75dc7a30-9bc4-4ba8-b830-34c05ad2c5dd",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F75dc7a30-9bc4-4ba8-b830-34c05ad2c5dd.docx?alt=media&token=0f62451a-d40e-4304-be1d-ed4430efd17c",
                    "fileExt": ".docx",
                    "fileName": "F948 Training for Feeding Assistants .docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "F949 Behavioral Health Training",
                    "type": "policy",
                    "id": "5e0674c2-1c39-4da7-bfb5-d9fc7bf6cbb7",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5e0674c2-1c39-4da7-bfb5-d9fc7bf6cbb7.docx?alt=media&token=cbfd14c2-f362-45e0-90b3-e081d888895d",
                    "fileExt": ".docx",
                    "fileName": "F949 Behavioral Health Training.docx",
                    "keywords": [
                      "snf"
                    ],
                    "isBright": true
                  }
                ]
              },
              {
                "name": "F540 Definitions",
                "type": "policy",
                "id": "ecee8d59-5a88-4bcc-9d01-7172b07cb618",
                "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fecee8d59-5a88-4bcc-9d01-7172b07cb618.docx?alt=media&token=8e43dcce-3c36-4bcf-b2ae-06fdd2080bef",
                "fileExt": ".docx",
                "fileName": "F540 Definitions.docx",
                "keywords": [
                  "snf"
                ],
                "isBright": true
              }
            ]
          }
        ]
      },
      {
        "name": "State",
        open: true,
        "type": "dir",
        "id": "d6550b8d-d9d3-4ef1-9c84-024e8c90945e",
        "path": "Regulations/State",
        "items": [
          {
            "name": "MN",
            "type": "dir",
            "open": true,
            "id": "617e33fc-275f-4b8b-b4e4-37b79202f354",
            "path": "Regulations/State/MN",
            "items": [
              {
                "name": "Chapter 144G ASSISTED LIVING LAW",
                "type": "dir",
                "id": "2681714c-7ec7-496c-8b2c-a703af2a6caa",
                "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW",
                "items": [
                  {
                    "name": "Assisted Living Facilities with Dementia Care",
                    "type": "dir",
                    "id": "d22b291c-8c2a-4bf4-8005-bc2b820b3536",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Assisted Living Facilities with Dementia Care",
                    "items": [
                      {
                        "name": "144G.80 ADDITIONAL LICENSING REQUIREMENTS FOR ASSISTED LIVING FACILITIES WITH DEMENTIA CARE",
                        "type": "policy",
                        "id": "84ace6f3-7788-4f38-87d9-57f5f8a0efae",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F84ace6f3-7788-4f38-87d9-57f5f8a0efae.pdf?alt=media&token=e1218839-d55f-4ddf-aae1-87ed0fcbedd6",
                        "fileExt": ".pdf",
                        "fileName": "144G.80 ADDITIONAL LICENSING REQUIREMENTS FOR ASSISTED LIVING FACILITIES WITH DEMENTIA CARE.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.81 ADDITIONAL REQUIREMENTS FOR ASSISTED LIVING FACILITIES WITH SECURED DEMENTIA CARE UNITS",
                        "type": "policy",
                        "id": "d06cba7d-1aee-4f53-9468-6ef8670f030d",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd06cba7d-1aee-4f53-9468-6ef8670f030d.pdf?alt=media&token=e57a342a-b11a-4a08-8044-2406303db4a2",
                        "fileExt": ".pdf",
                        "fileName": "144G.81 ADDITIONAL REQUIREMENTS FOR ASSISTED LIVING FACILITIES WITH SECURED DEMENTIA CARE UNITS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.82 ADDITIONAL RESPONSIBILITIES OF ADMINISTRATION FOR ASSISTED LIVING FACILITIES WITH DEMENTIA CARE",
                        "type": "policy",
                        "id": "e2150947-ac9e-486f-a2e4-9cac2c0117ec",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe2150947-ac9e-486f-a2e4-9cac2c0117ec.pdf?alt=media&token=46a806f5-d5a5-4388-873c-f0ed3a19a478",
                        "fileExt": ".pdf",
                        "fileName": "144G.82 ADDITIONAL RESPONSIBILITIES OF ADMINISTRATION FOR ASSISTED LIVING FACILITIES WITH DEMENTIA CARE.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.83 ADDITIONAL TRAINING REQUIREMENTS FOR ASSISTED LIVING FACILITIES WITH DEMENTIA CARE",
                        "type": "policy",
                        "id": "fdc8f50d-deef-4249-98a7-8811ebe8cd74",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ffdc8f50d-deef-4249-98a7-8811ebe8cd74.pdf?alt=media&token=7d4be1bc-94d0-4c9b-ae5c-8300ebee7dd2",
                        "fileExt": ".pdf",
                        "fileName": "144G.83 ADDITIONAL TRAINING REQUIREMENTS FOR ASSISTED LIVING FACILITIES WITH DEMENTIA CARE.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.84 SERVICES FOR RESIDENTS WITH DEMENTIA",
                        "type": "policy",
                        "id": "b1e6e2c4-b8fc-4564-8e54-c0a6916fb0eb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb1e6e2c4-b8fc-4564-8e54-c0a6916fb0eb.pdf?alt=media&token=3b336d86-bb0d-4df2-8d20-c0b08114c7ee",
                        "fileExt": ".pdf",
                        "fileName": "144G.84 SERVICES FOR RESIDENTS WITH DEMENTIA.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Assisted Living Licensure",
                    "type": "dir",
                    "id": "2d9a721b-d886-432e-9562-32e5418bb7b4",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Assisted Living Licensure",
                    "items": [
                      {
                        "name": "144G.08 DEFINITIONS",
                        "type": "policy",
                        "id": "abbd9064-49ab-4ab8-8854-49cc132caf51",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fabbd9064-49ab-4ab8-8854-49cc132caf51.pdf?alt=media&token=a8cec819-10c4-4c4d-a028-a4a87f8e603f",
                        "fileExt": ".pdf",
                        "fileName": "144G.08 DEFINITIONS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.09 COMMISSIONER OVERSIGHT AND AUTHORITY OVER ASSISTED LIVING FACILITIES",
                        "type": "policy",
                        "id": "c72ec8cf-dc42-49d3-ad46-20a31be50b1a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc72ec8cf-dc42-49d3-ad46-20a31be50b1a.pdf?alt=media&token=130d8aa5-eb19-41bf-9dfc-cae7d188bd9e",
                        "fileExt": ".pdf",
                        "fileName": "144G.09 COMMISSIONER OVERSIGHT AND AUTHORITY OVER ASSISTED LIVING FACILITIES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.10 ASSISTED LIVING FACILITY LICENSE",
                        "type": "policy",
                        "id": "247e76b7-dd9f-42f6-b67f-669c18cefde6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F247e76b7-dd9f-42f6-b67f-669c18cefde6.pdf?alt=media&token=548be2b2-1cb9-4b5b-a9c6-3135ea5cd0ff",
                        "fileExt": ".pdf",
                        "fileName": "144G.10 ASSISTED LIVING FACILITY LICENSE.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.11 APPLICABILITY OF OTHER LAWS",
                        "type": "reg",
                        "id": "5efc2989-65c1-4efa-b36b-4d88f96b15ac",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5efc2989-65c1-4efa-b36b-4d88f96b15ac.pdf?alt=media&token=306dbb9e-ec9a-40d5-abeb-d7dcb70bc13e",
                        "fileExt": ".pdf",
                        "fileName": "144G.11 APPLICABILITY OF OTHER LAWS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.12 APPLICATION FOR LICENSURE",
                        "type": "policy",
                        "id": "b766eb16-d5d8-4a7f-9da2-6407dbece9eb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb766eb16-d5d8-4a7f-9da2-6407dbece9eb.pdf?alt=media&token=14f6b491-d988-439f-907f-35d66b64ffab",
                        "fileExt": ".pdf",
                        "fileName": "144G.12 APPLICATION FOR LICENSURE.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.13 BACKGROUND STUDIES OF LICENSE APPLICANTS",
                        "type": "policy",
                        "id": "f48c1368-d882-4e56-a341-8d5bc840f66a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff48c1368-d882-4e56-a341-8d5bc840f66a.pdf?alt=media&token=3d64b81a-4337-479f-930d-ee40f7a58f77",
                        "fileExt": ".pdf",
                        "fileName": "144G.13 BACKGROUND STUDIES OF LICENSE APPLICANTS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.15 CONSIDERATION OF APPLICATIONS",
                        "type": "policy",
                        "id": "b431d178-ca1f-4ff1-94ab-af4d44fdf60e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb431d178-ca1f-4ff1-94ab-af4d44fdf60e.pdf?alt=media&token=1bbd82ad-15e9-41ae-97c0-31cd995139a0",
                        "fileExt": ".pdf",
                        "fileName": "144G.15 CONSIDERATION OF APPLICATIONS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.16 PROVISIONAL LICENSE",
                        "type": "policy",
                        "id": "7ff9ba2a-b2a0-4a91-9d23-21f2bc1f1e39",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7ff9ba2a-b2a0-4a91-9d23-21f2bc1f1e39.pdf?alt=media&token=05fab028-9557-472e-8a03-f0985416756c",
                        "fileExt": ".pdf",
                        "fileName": "144G.16 PROVISIONAL LICENSE.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.17 LICENSE RENEWAL",
                        "type": "policy",
                        "id": "d932c9ad-8503-4347-8b20-c35072755ae8",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd932c9ad-8503-4347-8b20-c35072755ae8.pdf?alt=media&token=602bae54-0ffb-4c3d-aaeb-f2589d40aab6",
                        "fileExt": ".pdf",
                        "fileName": "144G.17 LICENSE RENEWAL.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.18 NOTIFICATION OF CHANGES IN INFORMATION",
                        "type": "policy",
                        "id": "5f7264ec-e10a-423a-bdf7-e196a38c2257",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5f7264ec-e10a-423a-bdf7-e196a38c2257.pdf?alt=media&token=c0008bb4-69ec-404d-9970-8a48783ab36f",
                        "fileExt": ".pdf",
                        "fileName": "144G.18 NOTIFICATION OF CHANGES IN INFORMATION.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.19 TRANSFER OF LICENSE PROHIBITED",
                        "type": "policy",
                        "id": "336171d2-e536-46b6-ba73-6d72cdf7f86c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F336171d2-e536-46b6-ba73-6d72cdf7f86c.pdf?alt=media&token=024396ee-56d8-48a2-acf5-6977ae8196b6",
                        "fileExt": ".pdf",
                        "fileName": "144G.19 TRANSFER OF LICENSE PROHIBITED.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Contracts, Terminations, and Relocations",
                    "type": "dir",
                    "id": "c831b7ae-ecd3-4c7e-badb-01055388bc55",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Contracts, Terminations, and Relocations",
                    "items": [
                      {
                        "name": "144G.50 ASSISTED LIVING CONTRACT REQUIREMENTS",
                        "type": "policy",
                        "id": "acad753b-b9ab-422b-b02c-cc328ad5c997",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Facad753b-b9ab-422b-b02c-cc328ad5c997.pdf?alt=media&token=ff841483-2b0d-4aea-b5a4-892a83797b84",
                        "fileExt": ".pdf",
                        "fileName": "144G.50 ASSISTED LIVING CONTRACT REQUIREMENTS.pdf",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.51 ARBITRATION",
                        "type": "policy",
                        "id": "a3f0dca0-f8a1-4f19-adcf-ea639063a070",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fa3f0dca0-f8a1-4f19-adcf-ea639063a070.pdf?alt=media&token=0a458e95-2b0b-4fd7-bfb1-5f563e55093c",
                        "fileExt": ".pdf",
                        "fileName": "144G.51 ARBITRATION.pdf",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.52 ASSISTED LIVING CONTRACT TERMINATIONS",
                        "type": "policy",
                        "id": "1ddf074a-4b6c-49e7-add4-857b5aa5c030",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1ddf074a-4b6c-49e7-add4-857b5aa5c030.pdf?alt=media&token=cc992422-c770-40b3-a4ea-41ea39be8409",
                        "fileExt": ".pdf",
                        "fileName": "144G.52 ASSISTED LIVING CONTRACT TERMINATIONS.pdf",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.53 NONRENEWAL OF HOUSING",
                        "type": "policy",
                        "id": "12329450-5bc0-41b8-ae56-3743a0ed7ebc",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F12329450-5bc0-41b8-ae56-3743a0ed7ebc.pdf?alt=media&token=0a8e421f-946d-49d3-8a40-6f3f9c19d673",
                        "fileExt": ".pdf",
                        "fileName": "144G.53 NONRENEWAL OF HOUSING.pdf",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.54 APPEALS OF CONTRACT TERMINATIONS",
                        "type": "policy",
                        "id": "d2ce365c-f1a7-4984-8303-98d4c69971fa",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd2ce365c-f1a7-4984-8303-98d4c69971fa.pdf?alt=media&token=021d5f81-1c6a-4e05-a5a2-25f161658921",
                        "fileExt": ".pdf",
                        "fileName": "144G.54 APPEALS OF CONTRACT TERMINATIONS.pdf",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.55 COORDINATED MOVES",
                        "type": "policy",
                        "id": "acdfc3a6-785b-42ab-873d-991e4c3b1553",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Facdfc3a6-785b-42ab-873d-991e4c3b1553.pdf?alt=media&token=3db0e350-ac38-4938-b1df-bdf472daac7c",
                        "fileExt": ".pdf",
                        "fileName": "144G.55 COORDINATED MOVES.pdf",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.56 TRANSFER OF RESIDENTS WITHIN FACILITY",
                        "type": "policy",
                        "id": "ae89aa3c-b6ed-4d73-aaab-c455ea61f430",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fae89aa3c-b6ed-4d73-aaab-c455ea61f430.pdf?alt=media&token=a79fd6aa-3ce7-41e8-97e8-cfeeba7680e5",
                        "fileExt": ".pdf",
                        "fileName": "144G.56 TRANSFER OF RESIDENTS WITHIN FACILITY.pdf",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.57 PLANNED CLOSURES",
                        "type": "policy",
                        "id": "12ea45c5-5bbe-42b0-9d3d-5ab0dc8172e6",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F12ea45c5-5bbe-42b0-9d3d-5ab0dc8172e6.pdf?alt=media&token=4ea0d562-9db4-4a39-b836-afe236d7acab",
                        "fileExt": ".pdf",
                        "fileName": "144G.57 PLANNED CLOSURES.pdf",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Enforcement",
                    "type": "dir",
                    "id": "4678295d-1aee-4427-bdb6-de531198b64e",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Enforcement",
                    "items": [
                      {
                        "name": "144G.20 ENFORCEMENT",
                        "type": "policy",
                        "id": "40f26360-94a3-46c0-8be2-93c0658f14fd",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F40f26360-94a3-46c0-8be2-93c0658f14fd.pdf?alt=media&token=02041280-1c3b-4a9e-9843-795365dc0e1c",
                        "fileExt": ".pdf",
                        "fileName": "144G.20 ENFORCEMENT.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Operations and Physical Plant Requirements",
                    "type": "dir",
                    "id": "3c5dd2dd-341c-4f60-b4ba-081ba7f6b246",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Operations and Physical Plant Requirements",
                    "items": [
                      {
                        "name": "144G.40 HOUSING AND SERVICES",
                        "type": "policy",
                        "id": "211b33fa-8789-4bd2-9464-ec0de3ac69a7",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F211b33fa-8789-4bd2-9464-ec0de3ac69a7.pdf?alt=media&token=35a34612-1e53-4416-bf1b-fe43113e987d",
                        "fileExt": ".pdf",
                        "fileName": "144G.40 HOUSING AND SERVICES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.41 MINIMUM ASSISTED LIVING FACILITY REQUIREMENTS",
                        "type": "policy",
                        "id": "d1e62c64-fab6-4fb1-b95a-aea8f2f2f32a",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fd1e62c64-fab6-4fb1-b95a-aea8f2f2f32a.pdf?alt=media&token=18308e4b-a85f-49b9-99db-a193fd3c4e9a",
                        "fileExt": ".pdf",
                        "fileName": "144G.41 MINIMUM ASSISTED LIVING FACILITY REQUIREMENTS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.42 BUSINESS OPERATION",
                        "type": "policy",
                        "id": "4e042d40-ee6d-4cf0-8e9f-d07f9a909df0",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F4e042d40-ee6d-4cf0-8e9f-d07f9a909df0.pdf?alt=media&token=37b2b5ed-bd67-405d-8fdd-934d176eb4e6",
                        "fileExt": ".pdf",
                        "fileName": "144G.42 BUSINESS OPERATION.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.43 RESIDENT RECORD REQUIREMENTS",
                        "type": "policy",
                        "id": "2856969e-acab-421d-9a83-0209eb1ea132",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F2856969e-acab-421d-9a83-0209eb1ea132.pdf?alt=media&token=8f938ab3-648f-4ab4-86a8-f3646ffa2300",
                        "fileExt": ".pdf",
                        "fileName": "144G.43 RESIDENT RECORD REQUIREMENTS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.45 MINIMUM SITE, PHYSICAL ENVIRONMENT, AND FIRE SAFETY REQUIREMENTS",
                        "type": "policy",
                        "id": "11539cd6-5da7-46e7-aa37-27370a278783",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F11539cd6-5da7-46e7-aa37-27370a278783.pdf?alt=media&token=28e17de3-9f8c-4fd6-977e-0c26c649a82c",
                        "fileExt": ".pdf",
                        "fileName": "144G.45 MINIMUM SITE, PHYSICAL ENVIRONMENT, AND FIRE SAFETY REQUIREMENTS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Resident Rights and Protections",
                    "type": "dir",
                    "id": "ed215d2c-bb57-4e4d-8cb3-fa0d103a5d9d",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Resident Rights and Protections",
                    "items": [
                      {
                        "name": "144G.191 ASSISTED LIVING FACILITY LICENSING IMPLEMENTATION; TRANSITION PERIOD FOR CURRENT PROVIDERS",
                        "type": "policy",
                        "id": "9452aef5-5927-4df6-bfd9-808f301c29cb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9452aef5-5927-4df6-bfd9-808f301c29cb.pdf?alt=media&token=47ed19bf-e4f0-4e81-ac20-8a6f2f677a06",
                        "fileExt": ".pdf",
                        "fileName": "144G.191 ASSISTED LIVING FACILITY LICENSING IMPLEMENTATION; TRANSITION PERIOD FOR CURRENT PROVIDERS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.401 PAYMENT FOR SERVICES UNDER DISABILITY WAIVERS",
                        "type": "policy",
                        "id": "c16a257b-1835-4ce5-8338-fdcc56520d83",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc16a257b-1835-4ce5-8338-fdcc56520d83.pdf?alt=media&token=e69d16bd-b83d-40d0-a43a-5ac37e487f5b",
                        "fileExt": ".pdf",
                        "fileName": "144G.401 PAYMENT FOR SERVICES UNDER DISABILITY WAIVERS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.90 REQUIRED NOTICES",
                        "type": "policy",
                        "id": "9d997384-0356-4f79-b8ae-84509ef60531",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9d997384-0356-4f79-b8ae-84509ef60531.pdf?alt=media&token=23d7a58b-87e0-4bf5-9aef-772a242e9065",
                        "fileExt": ".pdf",
                        "fileName": "144G.90 REQUIRED NOTICES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.91 ASSISTED LIVING BILL OF RIGHTS",
                        "type": "policy",
                        "id": "75c6d4ac-9efc-4234-8a77-a0821a658c8c",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F75c6d4ac-9efc-4234-8a77-a0821a658c8c.pdf?alt=media&token=58ded79e-c763-4c2a-bb58-1d5a58db7f89",
                        "fileExt": ".pdf",
                        "fileName": "144G.91 ASSISTED LIVING BILL OF RIGHTS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.911 RESTRICTIONS UNDER HOME AND COMMUNITY-BASED WAIVERS",
                        "type": "policy",
                        "id": "46d85964-3e81-4162-8627-7fd0d03adb79",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F46d85964-3e81-4162-8627-7fd0d03adb79.pdf?alt=media&token=539d15ee-9fc0-4094-8a87-8f5a1c182f82",
                        "fileExt": ".pdf",
                        "fileName": "144G.911 RESTRICTIONS UNDER HOME AND COMMUNITY-BASED WAIVERS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.92 RETALIATION PROHIBITED",
                        "type": "policy",
                        "id": "1655445e-d99d-4db9-ae08-d0ae7f3fc077",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F1655445e-d99d-4db9-ae08-d0ae7f3fc077.pdf?alt=media&token=9bb94cc9-1e52-493d-a901-a5652f992a19",
                        "fileExt": ".pdf",
                        "fileName": "144G.92 RETALIATION PROHIBITED.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.93 CONSUMER ADVOCACY AND LEGAL SERVICES",
                        "type": "policy",
                        "id": "7a86d110-0d9f-4d97-9ef2-d3934a108b40",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F7a86d110-0d9f-4d97-9ef2-d3934a108b40.pdf?alt=media&token=0c72cf57-96d5-42db-8ec2-eca8b86d4d7d",
                        "fileExt": ".pdf",
                        "fileName": "144G.93 CONSUMER ADVOCACY AND LEGAL SERVICES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.95 OFFICE OF OMBUDSMAN FOR LONG-TERM CARE AND OFFICE OF OMBUDSMAN FOR MENTAL HEALTH AND DEVELOPMENTAL DISABILITIES",
                        "type": "policy",
                        "id": "8aecfbe9-4d81-4988-8c41-33c0fd3d8bfb",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F8aecfbe9-4d81-4988-8c41-33c0fd3d8bfb.pdf?alt=media&token=5d22c2a6-1bbd-45ae-99a1-a3129cbbdcef",
                        "fileExt": ".pdf",
                        "fileName": "144G.95 OFFICE OF OMBUDSMAN FOR LONG-TERM CARE AND OFFICE OF OMBUDSMAN FOR MENTAL HEALTH AND DEVELOPMENTAL DISABILITIES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.9999 RESIDENT QUALITY OF CARE AND OUTCOMES IMPROVEMENT TASK FORCE",
                        "type": "policy",
                        "id": "9cce8e08-357d-49fe-b483-d8bb6562d7ca",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9cce8e08-357d-49fe-b483-d8bb6562d7ca.pdf?alt=media&token=7eb9f825-f84b-406d-8900-54940816e397",
                        "fileExt": ".pdf",
                        "fileName": "144G.9999 RESIDENT QUALITY OF CARE AND OUTCOMES IMPROVEMENT TASK FORCE.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Services",
                    "type": "dir",
                    "id": "e1ac89f8-9be4-4a3a-9452-08f0be0637c2",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Services",
                    "items": [
                      {
                        "name": "144G.70 SERVICES",
                        "type": "policy",
                        "id": "fc1fcf46-91d2-444a-8139-96e88cc823de",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ffc1fcf46-91d2-444a-8139-96e88cc823de.pdf?alt=media&token=75f6cc50-2e0d-478b-a693-ef5a19040ba9",
                        "fileExt": ".pdf",
                        "fileName": "144G.70 SERVICES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.71 MEDICATION MANAGEMENT",
                        "type": "policy",
                        "id": "b994120a-9063-4556-b878-733db01b6f62",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb994120a-9063-4556-b878-733db01b6f62.pdf?alt=media&token=4183e745-b318-4725-8dc7-ee37ce5d133c",
                        "fileExt": ".pdf",
                        "fileName": "144G.71 MEDICATION MANAGEMENT.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.72 TREATMENT AND THERAPY MANAGEMENT SERVICES",
                        "type": "policy",
                        "id": "9328a6bb-daa1-46a9-8221-4511ef3ceba1",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9328a6bb-daa1-46a9-8221-4511ef3ceba1.pdf?alt=media&token=334b08b2-d6e9-4bb6-ab56-73df70bcd7ff",
                        "fileExt": ".pdf",
                        "fileName": "144G.72 TREATMENT AND THERAPY MANAGEMENT SERVICES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      }
                    ]
                  },
                  {
                    "name": "Staffing Requirements",
                    "type": "dir",
                    "id": "5635f41a-10a3-4723-909f-c58d74c9bc37",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Staffing Requirements",
                    "items": [
                      {
                        "name": "144G.60 STAFFING REQUIREMENTS",
                        "type": "policy",
                        "id": "ea1d1ce1-de34-4dce-bc3e-036623b58eee",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true,
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fea1d1ce1-de34-4dce-bc3e-036623b58eee.pdf?alt=media&token=c45c7097-4a29-433d-a264-f33ced384b69",
                        "fileExt": ".pdf",
                        "fileName": "144G.60 STAFFING REQUIREMENTS.pdf"
                      },
                      {
                        "name": "144G.61 STAFF COMPETENCY EVALUATIONS",
                        "type": "policy",
                        "id": "6e8de3f4-fafc-41e6-983b-0acbdb4f1f81",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true,
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F6e8de3f4-fafc-41e6-983b-0acbdb4f1f81.pdf?alt=media&token=6270c746-4094-4ea5-aa4a-1e89dbe7f16e",
                        "fileExt": ".pdf",
                        "fileName": "144G.61 STAFF COMPETENCY EVALUATIONS.pdf"
                      },
                      {
                        "name": "144G.62 DELEGATION AND SUPERVISION",
                        "type": "policy",
                        "id": "f57f80e4-3703-462a-87f3-d1be6676395a",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true,
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff57f80e4-3703-462a-87f3-d1be6676395a.pdf?alt=media&token=7ae61773-6c24-4bf5-9b9e-a8e6fb0cd9c7",
                        "fileExt": ".pdf",
                        "fileName": "144G.62 DELEGATION AND SUPERVISION.pdf"
                      },
                      {
                        "name": "144G.63 ORIENTATION AND ANNUAL TRAINING REQUIREMENTS",
                        "type": "policy",
                        "id": "717404ad-a19f-4dbf-952d-e48e0dafd7f3",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true,
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F717404ad-a19f-4dbf-952d-e48e0dafd7f3.pdf?alt=media&token=059b8cb4-8c15-4418-8a37-c7e852253375",
                        "fileExt": ".pdf",
                        "fileName": "144G.63 ORIENTATION AND ANNUAL TRAINING REQUIREMENTS.pdf"
                      },
                      {
                        "name": "144G.64 TRAINING IN DEMENTIA CARE REQUIRED",
                        "type": "policy",
                        "id": "f446fd2c-09a2-4538-a6e3-07227d3c996c",
                        "keywords": [
                          "AL"
                        ],
                        "isBright": true,
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff446fd2c-09a2-4538-a6e3-07227d3c996c.pdf?alt=media&token=bc3383fb-383a-4586-8bce-47c384413890",
                        "fileExt": ".pdf",
                        "fileName": "144G.64 TRAINING IN DEMENTIA CARE REQUIRED.pdf"
                      }
                    ]
                  },
                  {
                    "name": "Surveys, Correction Orders, and Fines",
                    "type": "dir",
                    "id": "a0d8d811-c9d6-4b54-b6fc-a0ee0d594eb2",
                    "path": "Regulations/State/MN/Chapter 144G ASSISTED LIVING LAW/Surveys, Correction Orders, and Fines",
                    "items": [
                      {
                        "name": "144G.30 SURVEYS AND INVESTIGATIONS",
                        "type": "policy",
                        "id": "5600a64a-763c-411d-8317-c1567015538e",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F5600a64a-763c-411d-8317-c1567015538e.pdf?alt=media&token=874fd805-670e-4cf0-9103-2df5f2d076a1",
                        "fileExt": ".pdf",
                        "fileName": "144G.30 SURVEYS AND INVESTIGATIONS.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.31 VIOLATIONS AND FINES",
                        "type": "policy",
                        "id": "f027dbf5-519f-4b79-a1fa-def8f3906617",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Ff027dbf5-519f-4b79-a1fa-def8f3906617.pdf?alt=media&token=333ec7f3-bdb1-4e07-8b5f-8a537a667b52",
                        "fileExt": ".pdf",
                        "fileName": "144G.31 VIOLATIONS AND FINES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.32 RECONSIDERATION OF CORRECTION ORDERS AND FINES",
                        "type": "policy",
                        "id": "c057390c-cdcd-4405-830c-f380ab982d23",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fc057390c-cdcd-4405-830c-f380ab982d23.pdf?alt=media&token=78e1eece-8dd0-4111-8240-7ecfc1bfc4ad",
                        "fileExt": ".pdf",
                        "fileName": "144G.32 RECONSIDERATION OF CORRECTION ORDERS AND FINES.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      },
                      {
                        "name": "144G.33 INNOVATION VARIANCE",
                        "type": "policy",
                        "id": "858dd8e3-3341-426a-918e-cf29d3c6972b",
                        "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F858dd8e3-3341-426a-918e-cf29d3c6972b.pdf?alt=media&token=f8c34aaa-50bb-4b31-8ef7-c907e024be82",
                        "fileExt": ".pdf",
                        "fileName": "144G.33 INNOVATION VARIANCE.pdf",
                        "keywords": [
                          "al"
                        ],
                        "isBright": true
                      }
                    ]
                  }
                ]
              },
              {
                "name": "Chapter 4659 ASSISTED LIVING FACILITIES",
                "type": "dir",
                "id": "aaa74f0d-066c-4681-b815-009d723a593a",
                "path": "Regulations/State/MN/Chapter 4659 ASSISTED LIVING FACILITIES",
                "items": [
                  {
                    "name": "4659.0010 APPLICABILITY AND PURPOSE",
                    "type": "policy",
                    "id": "98afc98f-cb77-4ca2-9573-e5c6c7034ccd",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F98afc98f-cb77-4ca2-9573-e5c6c7034ccd.pdf?alt=media&token=1a8f93cc-3bbb-43e3-9d0a-405a6fb4ab54",
                    "fileExt": ".pdf",
                    "fileName": "4659.0010 APPLICABILITY AND PURPOSE.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0020 DEFINITIONS",
                    "type": "policy",
                    "id": "b4b50ef1-aafb-4450-9a61-2bce2c69eb75",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb4b50ef1-aafb-4450-9a61-2bce2c69eb75.pdf?alt=media&token=ff24d650-6959-488a-a473-ccc9f31d9047",
                    "fileExt": ".pdf",
                    "fileName": "4659.0020 DEFINITIONS.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0030 RESPONSIBILITY TO MEET STANDARDS",
                    "type": "policy",
                    "id": "48bbed6b-fccc-41d8-bc52-7b2788989dde",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F48bbed6b-fccc-41d8-bc52-7b2788989dde.pdf?alt=media&token=395d4a9f-3dd4-4392-acce-67f1f6928bfa",
                    "fileExt": ".pdf",
                    "fileName": "4659.0030 RESPONSIBILITY TO MEET STANDARDS.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0040 LICENSING IN GENERAL",
                    "type": "policy",
                    "id": "98cc0cfb-fa44-49fb-b0eb-9f2ee1e785f0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F98cc0cfb-fa44-49fb-b0eb-9f2ee1e785f0.pdf?alt=media&token=6e21de18-0e1f-4774-b5a6-1af3d0199f47",
                    "fileExt": ".pdf",
                    "fileName": "4659.0040 LICENSING IN GENERAL.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0050 FINES FOR NONCOMPLIANCE",
                    "type": "policy",
                    "id": "98713515-2415-401b-a467-a59c07758dcf",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F98713515-2415-401b-a467-a59c07758dcf.pdf?alt=media&token=8232fd9c-52a1-4d1c-be2c-619ccc78fda2",
                    "fileExt": ".pdf",
                    "fileName": "4659.0050 FINES FOR NONCOMPLIANCE.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0060 ASSISTED LIVING LICENSURE; CONVERSION OF EXISTING ASSISTED LIVING PROVIDERS",
                    "type": "policy",
                    "id": "86328af3-4d43-4106-9014-21dfc4dcef29",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F86328af3-4d43-4106-9014-21dfc4dcef29.pdf?alt=media&token=c3a267a1-d2e6-49c4-ab69-fe7e17a46e26",
                    "fileExt": ".pdf",
                    "fileName": "4659.0060 ASSISTED LIVING LICENSURE; CONVERSION OF EXISTING ASSISTED LIVING PROVIDERS.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0090 UNIFORM CHECKLIST DISCLOSURE OF SERVICES",
                    "type": "policy",
                    "id": "e2bf4177-a095-4291-ae06-72f7a79ceced",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe2bf4177-a095-4291-ae06-72f7a79ceced.pdf?alt=media&token=9bf32d20-1e85-4311-86db-65a9934bd20c",
                    "fileExt": ".pdf",
                    "fileName": "4659.0090 UNIFORM CHECKLIST DISCLOSURE OF SERVICES.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0100 EMERGENCY DISASTER AND PREPAREDNESS PLAN; INCORPORATION BY REFERENCE",
                    "type": "policy",
                    "id": "0bc7183f-1ac4-4f6d-a467-76e059716dcd",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0bc7183f-1ac4-4f6d-a467-76e059716dcd.pdf?alt=media&token=99a2940f-be20-4b74-8460-7a3fc1f7f955",
                    "fileExt": ".pdf",
                    "fileName": "4659.0100 EMERGENCY DISASTER AND PREPAREDNESS PLAN; INCORPORATION BY REFERENCE.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0110 MISSING RESIDENT PLAN",
                    "type": "policy",
                    "id": "04c0680e-bb02-47e2-910a-8b0296d268ee",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F04c0680e-bb02-47e2-910a-8b0296d268ee.pdf?alt=media&token=fa781432-650f-4fcd-9d49-390bcb889d5c",
                    "fileExt": ".pdf",
                    "fileName": "4659.0110 MISSING RESIDENT PLAN.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0120 PROCEDURES FOR RESIDENT TERMINATION AND DISCHARGE PLANNING",
                    "type": "policy",
                    "id": "9c39d23e-10f5-41db-bcf7-ad775d27aeb4",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9c39d23e-10f5-41db-bcf7-ad775d27aeb4.pdf?alt=media&token=512872db-e6aa-4a14-92de-c88a762ab7e6",
                    "fileExt": ".pdf",
                    "fileName": "4659.0120 PROCEDURES FOR RESIDENT TERMINATION AND DISCHARGE PLANNING.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0130 CONDITIONS FOR PLANNED CLOSURES",
                    "type": "policy",
                    "id": "9eb333fc-87ea-47d5-9bd4-f4ca31872f07",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F9eb333fc-87ea-47d5-9bd4-f4ca31872f07.pdf?alt=media&token=9ffe64f6-0497-4400-ad60-b29cd51354de",
                    "fileExt": ".pdf",
                    "fileName": "4659.0130 CONDITIONS FOR PLANNED CLOSURES.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0140 INITIAL ASSESSMENTS AND CONTINUING ASSESSMENTS",
                    "type": "policy",
                    "id": "e25f5d3b-90f1-4aca-8ab5-5a8386a70a6e",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fe25f5d3b-90f1-4aca-8ab5-5a8386a70a6e.pdf?alt=media&token=ca1b9b09-4401-4cf4-ba51-cd6036b1009b",
                    "fileExt": ".pdf",
                    "fileName": "4659.0140 INITIAL ASSESSMENTS AND CONTINUING ASSESSMENTS.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0150 UNIFORM ASSESSMENT TOOL",
                    "type": "policy",
                    "id": "29d41f7c-9ac6-4108-adf9-7df7ea95250b",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F29d41f7c-9ac6-4108-adf9-7df7ea95250b.pdf?alt=media&token=f428f3e2-914a-407b-9651-a1c11b7e2276",
                    "fileExt": ".pdf",
                    "fileName": "4659.0150 UNIFORM ASSESSMENT TOOL.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0160 RELINQUISHING AN ASSISTED LIVING FACILITY WITH DEMENTIA CARE LICENSE",
                    "type": "policy",
                    "id": "0dcbfdd7-0083-4efd-b1de-1e2329fc83a8",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0dcbfdd7-0083-4efd-b1de-1e2329fc83a8.pdf?alt=media&token=dee536ae-f552-47cf-848e-df05147fc356",
                    "fileExt": ".pdf",
                    "fileName": "4659.0160 RELINQUISHING AN ASSISTED LIVING FACILITY WITH DEMENTIA CARE LICENSE.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0180 STAFFING",
                    "type": "policy",
                    "id": "0b4f4fd2-dca9-4578-9ca9-5407d6a4acc0",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F0b4f4fd2-dca9-4578-9ca9-5407d6a4acc0.pdf?alt=media&token=4b73d9e0-10b9-465b-acc0-27f086199c28",
                    "fileExt": ".pdf",
                    "fileName": "4659.0180 STAFFING.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0190 TRAINING REQUIREMENTS",
                    "type": "policy",
                    "id": "a9331ce9-6024-4412-abde-f96362b12073",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fa9331ce9-6024-4412-abde-f96362b12073.pdf?alt=media&token=0527ef32-190c-45ff-98a6-b949a41985e9",
                    "fileExt": ".pdf",
                    "fileName": "4659.0190 TRAINING REQUIREMENTS.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0200 NONRENEWAL OF HOUSING, REDUCTION IN SERVICES; REQUIRED NOTICES",
                    "type": "policy",
                    "id": "32dc69ce-09fa-46cc-9cd7-8b4e6b9e8d34",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2F32dc69ce-09fa-46cc-9cd7-8b4e6b9e8d34.pdf?alt=media&token=b0289964-0833-4020-befc-d535e890e7ec",
                    "fileExt": ".pdf",
                    "fileName": "4659.0200 NONRENEWAL OF HOUSING, REDUCTION IN SERVICES; REQUIRED NOTICES.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  },
                  {
                    "name": "4659.0210 TERMINATION APPEALS; PROCEDURES AND TIMELINES FOR APPEALS",
                    "type": "policy",
                    "id": "b307bcf0-c221-48a3-ab3f-7e6c62ca1785",
                    "link": "https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/clients%2Ffree%2Fbrightsources%2Fb307bcf0-c221-48a3-ab3f-7e6c62ca1785.pdf?alt=media&token=26e4f962-e906-4886-b028-722593ece177",
                    "fileExt": ".pdf",
                    "fileName": "4659.0210 TERMINATION APPEALS; PROCEDURES AND TIMELINES FOR APPEALS.pdf",
                    "keywords": [
                      "al"
                    ],
                    "isBright": true
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }

const All = [  
  {...Regulations}  
]


export default { All };